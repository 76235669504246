import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '../../../client/reusable_components/Accordion/Accordion';
import LazyImage from '../../../client/reusable_components/LazyImage';

import layout from '../../../../react/styles/global_ui/layout.css';
import typography from '../../../../react/styles/global_ui/typography.css';
import util from '../../../../react/styles/global_ui/util.css';
import styles from './leaderBoardAirframe.css';

const c = {
  summary: `${layout.flexJustifyStart} ${typography.textLeft} ${layout.gutter30} ${layout.margin0} ${layout.marginBottom10}`,
  title: `${typography.h2Responsive}`,
  text: `${typography.bodyL} ${layout.margin0}`,
  contents: `${layout.wrapper960} ${layout.flexColumnCenterCenter} ${layout.marginAuto} ${styles.contents}`,
  image: `${util.bgTransparent} ${layout.flexCenterCenter} ${styles.accordionIcon}`,
};

const Title = ({ title }) => (
  <div className={c.summary}>
    <LazyImage
      alt="icon for leaderboard"
      classList={{ root: c.image }}
      fit="fill"
      ratio="1:1"
      src="https://hackster.imgix.net/static/icons/trophy.png"
      width={73}
    />
    <div>
      <h2 className={c.title}>{title || 'Leaderboard'}</h2>
      <p className={c.text}>Wanna see the scoreboard for all entries? View them here!</p>
    </div>
  </div>
);

Title.propTypes = { title: PropTypes.string };

const LeaderBoardAirframe = ({ airtableUrl, title }) => (
  <Accordion
    classList={{ root: c.details }}
    group={`leaderboard-${airtableUrl}`}
    title={(
      <Title title={title} />
    )}
  >
    <div className={c.contents}>
      <iframe className="airtable-embed" frameBorder="0" height="533" src={airtableUrl} width="100%"></iframe>
    </div>
  </Accordion>
)
  ;
export default LeaderBoardAirframe;

LeaderBoardAirframe.propTypes = {
  airtableUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
};
