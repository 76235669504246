import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';
import TooltipTarget from '../../reusable_components/TooltipTarget';

import { getFormattedLocation } from '../../../services/country_data';
import { capitalize } from '../../../utility/formatters';

import layout from '../../../styles/global_ui/layout.css';
import tooltipStyles from '../../reusable_components/TooltipTarget/tooltip_target.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './event_card.css';

const ICON_MAP = {
  CONFERENCE: 'channels',
  HACKATHON: 'wip',
  MEETUP: 'location',
  WEBINAR: 'web',
  WORKSHOP: 'showcase',
};

const _getIconName = (type) => ICON_MAP[type.toUpperCase()] || '';

const EventTypeOrLocation = ({ item }) => {
  const { city, country_iso2, state, event_type } = item;

  return (
    <span>
      {country_iso2 ? getFormattedLocation({ city, country_iso2, state }) : 'Virtual'}
      <TooltipTarget
        classList={{ wrapper: `${layout.marginLeft10} ${styles.tooltipTarget} ${tooltipStyles.tooltipV2}` }}
        tooltip={capitalize(event_type)}
      >
        <Icon className={typography.iconBaselineFix} name={_getIconName(event_type)} />
      </TooltipTarget>
    </span>
  );
};

EventTypeOrLocation.propTypes = {
  item: PropTypes.shape({
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    event_type: PropTypes.string.isRequired,
    state: PropTypes.string,
  }).isRequired,
};

EventTypeOrLocation.defaultProps = {
  city: null,
  country_iso2: null,
  state: null,
};

export default EventTypeOrLocation;
