import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from '../styles/ImageEditor.css';

class ImageEditor extends Component {
  constructor(props) {
    super(props);

    this._getDimensions = this.getDimensions.bind(this);

    this.state = {
      dimensions: this.initialImageConstraint(props.image),
      range: this.initialRange(props.image)
    };
  }

  initialImageConstraint(image) {
    const maxWidth = 680;
    const maxHeight = 510;
    const width = image.iWidth ? image.iWidth : image.width;
    const height = image.iHeight ? image.iHeight : image.height;
    const ratio = Math.min(maxWidth/width, maxHeight/height);

    // We set the iDimensions meaning for them to be immutable, do not reset after this initial scaling.
    // When we re-calculate dimensions from a url or are resizing a previously resized image, we need the iDimensions
    // which are the actual image dimensions.
    return {
      width: image.iWidth ? image.width : Math.floor(width*ratio),
      height: image.iHeight ? image.height : Math.floor(height*ratio),
      iWidth: Math.floor(width*ratio),
      iHeight: Math.floor(height*ratio)
    };
  }

  initialRange(image) {
    const maxWidth = 680;
    const maxHeight = 510;
    const width = image.width ? image.width : image.iWidth;
    const height = image.height ? image.height : image.iHeight;
    const ratio = Math.min(maxWidth/width, maxHeight/height);

    return image.iWidth ? Math.ceil((width/Math.floor(width*ratio)) * 100) : 100;
  }

  scaleImageByRange(e) {
    const value = e.target.value;
    const {height, width, iWidth, iHeight} = this.state.dimensions;

    const min = Math.min(iWidth, iHeight);
    const ratio = (min * (value/100));

    this.setState({
      dimensions: {
        ...this.state.dimensions,
        height: Math.floor((ratio/min) * iHeight),
        width: Math.floor((ratio/min) * iWidth)
      },
      range: value
    });
  }

  getDimensions() {
    return this.state.dimensions;
  }

  render() {
    const {name, url} = this.props.image;
    const {height, width, iWidth} = this.state.dimensions;

    return (
      <div style={{minWidth: iWidth}} className={styles.root}>
        <div className={styles.resizeWrapper}>
          <small>Resize image</small>
          <input
            onChange={(e) => this.scaleImageByRange(e)}
            onKeyDown={(e) => {
              if (e.keyCode === 13 /*ENTER*/) {
                // Prevent form submission.
                e.preventDefault();
              }
            }}
            min="10"
            max="100"
            type="range"
            value={this.state.range}
          />
        </div>
        <div style={{minHeight: 150}}>
          <img
            style={{width: width, height: height}}
            alt={name || ""}
            src={url}
          />
        </div>
      </div>
    );
  }
}

ImageEditor.propTypes = {
  image: PropTypes.shape({
    height: PropTypes.number,
    name: PropTypes.string,
    width: PropTypes.number,
    url: PropTypes.string
  }).isRequired
};

ImageEditor.defaultProps = {
};

export default ImageEditor;