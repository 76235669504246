import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionsSection from '../../../client/form_components/templates/ActionsSection';
import PromotedContentForm from './promoted_content_form';
import getConfigByType from './config';

import AlgoliaPartsService from '../../../services/algolia/parts_service';
import AlgoliaTagsService from '../../../services/algolia/tags_service';

import errorHandler from '../../../services/error_handler';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './home_sections.css';

class EditPromotedContentView extends Component {
  constructor(props) {
    super(props);

    this.state = { isBusy: false };

    this.algoliaPartsService = new AlgoliaPartsService();
    this.algoliaTagsService = new AlgoliaTagsService();

    this.promotedContent;
  }

  _validateAndUpdateSection() {
    if (!this.promotedContent) return errorHandler('promotedContent ref does not exist!');

    const isValid = this.promotedContent.validate();

    if (isValid) {
      const config = getConfigByType('promotedContent');

      this.props.updateSection({
        index: this.props.section.index,
        data: config.toSection(this.promotedContent.getContent()),
      });
      this.props.toggleView('table');
    }
  }

  render() {
    const { meta, title } = this.props.section.data;

    return (
      <div className={styles.root}>
        <h2 className={`${typography.h2} ${layout.marginBottom30}`}>
          Edit promoted content section
        </h2>

        <PromotedContentForm
          ref={(el) => this.promotedContent = el}
          algoliaPartsService={this.algoliaPartsService}
          algoliaTagsService={this.algoliaTagsService}
          initData={meta.content}
          initTitle={title}
          propagateStatus={(bool) => this.setState({ isBusy: bool })}
        />

        <div>
          <ActionsSection
            isBusy={this.state.isBusy}
            primaryBtnConfig={{ onClick: () => this._validateAndUpdateSection(), text: 'Save changes' }}
            secondaryBtnConfig={{ onClick: () => this.props.toggleView('table') }}
          />
        </div>
      </div>
    );
  }
}

EditPromotedContentView.propTypes = {
  section: PropTypes.shape({
    index: PropTypes.number,
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      meta: PropTypes.shape({
        content: PropTypes.arrayOf(PropTypes.shape({
          contentType: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
          }),
          description: PropTypes.string,
          image11: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            url: PropTypes.string,
          }),
          image43: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            url: PropTypes.string,
          }),
          link: PropTypes.string,
          products: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
          })),
          tags: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
          })),
          title: PropTypes.string,
        })),
      }),
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  toggleView: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
};

export default EditPromotedContentView;
