import React from 'react';

const arrayToSentence = (items = []) => {
  if (items.length === 0) return null;

  const list = items.reduce((acc, item, i) => {
    if (i === items.length - 1) {
      return acc.concat([item]);
    } else if (i === items.length - 2) {
      return acc.concat([item, ' and ']);
    } else {
      return acc.concat([item, ', ']);
    }
  }, []);

  return React.Children.toArray(list);
};

export default arrayToSentence;
