// Note: Slimmed down version of FormLocationSelect modified for country only.
import React from 'react';
import PropTypes from 'prop-types';

import FormGroup from '../../wrappers/form_group';
import CountrySelect from '../country_select';

const FormCountrySelect = ({ errors, helperText, label, name, ...countrySelectProps }) => (
  <FormGroup
    errors={Array.isArray(errors) ? errors.join(', ') : errors}
    helperText={helperText}
    label={label}
    name={name}
    uuid="country-search"
    value=""
  >
    <CountrySelect id="country-search" {...countrySelectProps} hasErrors={errors && errors.length > 0} />
  </FormGroup>
);

FormCountrySelect.propTypes = {
  countryCode: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  helperText: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

FormCountrySelect.defaultProps = {
  countryCode: null,
  disabled: false,
  errors: null,
  helperText: null,
  label: 'Location',
  name: null,
  onChange: () => {},
  placeholder: 'Start typing to select a location!',
};

export default FormCountrySelect;
