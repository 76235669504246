import PropTypes from 'prop-types';
import React from 'react';
import styles from './messenger.css';

// TODO: Deprecate?
const Messenger = ({ dismiss, message, top }) => (
  <div className={styles[message.type]} style={{ top: top }}>
    <p className={styles.message}>
      {message.msg}
      {message.link && message.link.length
      && (
        <span>
          {message.action.length ? ' ' : ''}
          <a className={`${styles.link} ${styles['link-' + message.type]}`} href={message.link}>{message.action}</a>
          {message.msg2.length ? ' ' : ''}
        </span>
      )}
      {message.msg2.length ? message.msg2 : ''}
    </p>
    <button className={styles.dismiss} onClick={() => dismiss()} type="button">&times;</button>
  </div>
);

Messenger.propTypes = {
  dismiss: PropTypes.func.isRequired,
  message: PropTypes.shape({
    action: PropTypes.string,
    link: PropTypes.string,
    msg: PropTypes.string,
    msg2: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  top: PropTypes.number,
};

Messenger.defaultProps = { top: 0 };

export default Messenger;
