import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../reusable_components/LazyImage';
import BasicImage from './BasicImage';

const Image = (props) => (
  props.lazy ? <LazyImage {...props} /> : <BasicImage {...props} />
);

Image.propTypes = { lazy: PropTypes.bool };

Image.defaultProps = { lazy: false };

export default Image;
