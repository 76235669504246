import { v4 as uuidv4 } from 'uuid';

function getPageTypeCategory(pageType = '') {
  return pageType.split('#')[0] || null;
}

export default class BaseEvent {
  constructor({
    distinctId = null,
    sessionId = null,
    pageType = null,
    userId = null,
    utmParams = {},
    whitelabel = null,
  } = {}) {
    this.clientEventId = uuidv4();
    this.distinctId = distinctId;
    this.pageType = pageType;
    this.userId = userId;
    this.sessionId = sessionId;

    this.utm_campaign = utmParams.utm_campaign;
    this.utm_content = utmParams.utm_content;
    this.utm_medium = utmParams.utm_medium;
    this.utm_source = utmParams.utm_source;
    this.utm_term = utmParams.utm_term;

    this.whitelabel = whitelabel;
  }

  createRecord() {
    return {
      client_event_id: this.clientEventId,
      distinct_id: this.distinctId,
      ip: '${keen.ip}',
      logged_in: (this.userId && typeof this.userId === 'number'),
      page_url: document.location.toString(),
      page_type_category: getPageTypeCategory(this.pageType),
      page_type_full: this.pageType,
      referrer: { url: document.referrer || '' },
      session_id: this.sessionId,
      user_id: this.userId,
      utm_campaign: this.utm_campaign,
      utm_content: this.utm_content,
      utm_medium: this.utm_medium,
      utm_source: this.utm_source,
      utm_term: this.utm_term,
      whitelabel: this.whitelabel,
      keen: {
        addons: [
          {
            name: 'keen:url_parser',
            input: { url: 'page_url' },
            output: 'parsed_page_url',
          },
        ],
      },
    };
  }
}
