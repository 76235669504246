import React from 'react';
import PropTypes from 'prop-types';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const EMAIL_EXISTS_MSG = 'Looks like that email is registered. Please log in below if you’re already a member.';
const SIMPLIFIED_SIGNUP_MSG = 'Please join to continue';

const _getHeader = (props) => {
  const message = _getMessage(props);
  if (message) return <span className={typography.bodyM}>{message}</span>;
  if (props.blankHeader || props.showCTA) return null;

  return <h2 className={typography.h2}>{_getHeaderCopy(props)}</h2>;
};

const _getHeaderCopy = ({ currentPanel, whitelabelName }) => {
  if (currentPanel === 'login') return 'Welcome back';

  return whitelabelName ? `Welcome to ${whitelabelName}, powered by Hackster` : 'Welcome to Hackster';
};

const _getMessage = ({ currentPanel, showEmailMsg, simplified }) => {
  if (showEmailMsg && currentPanel === 'login') return EMAIL_EXISTS_MSG;
  if (simplified && currentPanel === 'signup') return SIMPLIFIED_SIGNUP_MSG;

  return null;
};

const AuthPanelHeader = (props) => {
  const header = _getHeader(props);
  if (!header) return null;

  return (
    <div className={`${layout.marginBottom30} ${typography.textCenter}`}>
      {header}
    </div>
  );
};

AuthPanelHeader.propTypes = {
  blankHeader: PropTypes.bool.isRequired,
  currentPanel: PropTypes.oneOf(['login', 'signup']).isRequired,
  showCTA: PropTypes.bool.isRequired,
  showEmailMsg: PropTypes.bool.isRequired,
  simplified: PropTypes.bool.isRequired,
  whitelabelName: PropTypes.string,
};

AuthPanelHeader.defaultProps = { whitelabelName: null };

export default AuthPanelHeader;
