import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import ContentPlaceholder from '../../reusable_components/ContentPlaceholder';
import GridList from '../../wrappers/grid_list';
import ProjectCard from '../../cards/project_card';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import styles from './projects_list.css';

function renderPlaceholderView({ canManage, placeholder }) {
  if (placeholder) return placeholder;

  return (
    <ContentPlaceholder
      classList={{
        content: styles.placeholderContent,
        placeholderLink: buttonStyles.md,
        placeholderText: styles.placeholderLabel,
      }}
      templateArgs={{ canManage }}
      type="no-projects"
    />
  );
}

// TODO take card-specific props as itemProps and pass them directly to GridList
const ProjectsList = forwardRef(({
  canManage,
  cardBorder,
  className,
  config,
  currentUser,
  horizontal,
  location,
  maxCols,
  placeholder,
  records,
  toggleRespect,
}, ref) => (
  <GridList
    ref={ref}
    ItemComponent={ProjectCard}
    className={className}
    horizontal={horizontal}
    itemKey="project"
    itemProps={{ cardBorder, config, currentUser, location, toggleRespect }}
    maxCols={maxCols}
    placeholder={renderPlaceholderView({ canManage, placeholder })}
    records={records}
  />
));

ProjectsList.propTypes = {
  canManage: PropTypes.bool,
  cardBorder: PropTypes.bool,
  className: PropTypes.string,
  config: PropTypes.shape({ controlledRespects: PropTypes.bool }), // Do we want a parent component to control the respect counter.
  currentUser: PropTypes.shape({ id: PropTypes.number }),
  horizontal: PropTypes.bool,
  location: PropTypes.string,
  maxCols: PropTypes.number,
  placeholder: PropTypes.node,
  records: PropTypes.array,
  toggleRespect: PropTypes.func,
};

ProjectsList.defaultProps = {
  canManage: false,
  cardBorder: false,
  className: '',
  config: { controlledRespects: false },
  currentUser: {},
  horizontal: false,
  location: null,
  maxCols: 4,
  placeholder: null,
  records: [],
  toggleRespect: () => {},
};

ProjectsList.displayName = 'ProjectsList';

export default ProjectsList;
