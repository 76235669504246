import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveIframe from '../../../client/wrappers/responsive_iframe';

import { getEmbedUrl } from '../../../utility/video';

const VideoViewer = ({ video }) => {
  const src = getEmbedUrl(video.url, true);

  return <ResponsiveIframe src={src} />;
};

VideoViewer.propTypes = { video: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired };

export default VideoViewer;
