import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Select from '../../select';
import { getErrorHelperView } from '../../templates';

import errorHandler from '../../../../services/error_handler';
import { graphMutate } from '../../../../requests/graphql';

const ERROR = 'ERROR';
const ERROR_MSG = 'Something went wrong. Please try again.';

class CollectionCategorySelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { status: null };
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(selected, stateToBe, currState) {
    return new Promise((resolve) => graphMutate({ t: 'update_project_collection' }, {
      category_ids: selected.map((option) => option.value),
      id: this.props.collection_id,
    })
      .then(() => {
        if (this.state.status === ERROR) this.setState({ status: null });
        resolve({ state: stateToBe });
      })
      .catch((err) => {
        this.setState({ status: ERROR });
        resolve({ state: { ...currState, openMenu: false } });
        errorHandler('CollectionCategorySelect onSelect', err);
      }));
  }

  render() {
    const { options, value } = this.props;

    return (
      <div>
        <Select
          hasErrors={this.state.status === ERROR}
          onSelectOpts={{ request: this.onSelect }}
          options={options}
          type="multi"
          value={value}
        />
        {this.state.status === ERROR
        && getErrorHelperView(ERROR_MSG)}
      </div>
    );
  }
}

CollectionCategorySelect.propTypes = {
  collection_id: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
};

CollectionCategorySelect.defaultProps = {
  options: [],
  value: [],
};

export default CollectionCategorySelect;
