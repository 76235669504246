import React from 'react';
import PropTypes from 'prop-types';
import Search from './Search';
import Thumbnail from './Thumbnail';

const Step = ({ actions, form, name, search, store, type }) => {
  const uniqueStore = store[type.toLowerCase()];
  const body = uniqueStore
    ? (
      <Thumbnail
        actions={actions}
        name={name}
        uniqueStore={uniqueStore}
      />
      )
    : (
      <Search
        actions={actions}
        form={form}
        name={name}
        search={search}
        store={store}
        type={type}
        value={uniqueStore}
      />
      );

  return (
    <div className="course-wizard-step">
      <label>{`What's the ${name}?`}</label>
      {body}
    </div>
  );
};

Step.propTypes = {
  actions: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  search: PropTypes.bool.isRequired,
  store: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default Step;
