import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';

import BasicFormInput from '../../../../client/form_components/inputs/basic_form_input';
import Form from './content_form';
import Dialog from '../../../../client/reusable_components/Dialog';
import PromotedContent from '../../../home_page/sections/promoted_content';
import TableView from './PromotedContentTableView';

import { getInObj } from '../../../../utility/accessors';

import formStyles from '../../../../styles/global_ui/forms.css';
import inputStyles from '../../../../styles/global_ui/inputs.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

const DEFAULT_DIALOG_STATE = {
  data: null, // {index, data}
  mode: 'default',
  open: false,
  title: '',
};

const MIN_SECTIONS_TO_SAVE = 2;
const TABLE_MIN_SECTIONS_MSG = 'You must have at least 2 items before publishing';

class PromotedContentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      dialog: DEFAULT_DIALOG_STATE,
      sections: props.initData,
      title: props.initTitle,
    };

    this.addSection = this.addSection.bind(this);
    this.getContent = this.getContent.bind(this);
    this.removeSection = this.removeSection.bind(this);
    this.updateSection = this.updateSection.bind(this);
    this.validate = this.validate.bind(this);
  }

  /**
   * Accessors
   */
  getContent() {
    return {
      content: this.state.sections,
      title: this.state.title,
    };
  }

  validate() {
    let errors = {};

    if (!this.state.title.length) {
      errors = { title: 'A title is required' };
    }
    if (this.state.sections.length < MIN_SECTIONS_TO_SAVE) {
      errors = { ...errors, table: TABLE_MIN_SECTIONS_MSG };
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });

      return false;
    } else {
      return true;
    }
  }

  /**
   * Methods
   */
  addSection(data) {
    this.setState((state) => ({
      errors: { ...state.errors, table: null },
      dialog: DEFAULT_DIALOG_STATE,
      sections: state.sections.concat({ ...data, key: uuidv1() }),
    }));
  }

  removeSection(section) {
    this.setState({ sections: this.state.sections.filter((sect, i) => section.index !== i) });
  }

  updateSection(section) {
    this.setState({
      dialog: DEFAULT_DIALOG_STATE,
      sections: this.state.sections.map((sect, i) => (section.index === i) ? { ...section.data, key: sect.key } : sect),
    });
  }

  /**
   * Helpers
   */
  _openDialogWithData({ data, mode, title }) {
    this.setState({ dialog: { data, mode, title, open: true } });
  }

  _closeDialogWithData(useDefault = true) {
    // Saves content in memory when useDefault is false.  This will cache new content if the dialog is dismissed mid creation
    // when the user either pressed ESC or clicked outside of the dialog.  If cancel is pressed we clear the form.
    // State is always cleared on dismiss if mode is "edit".
    this.setState({ dialog: useDefault ? DEFAULT_DIALOG_STATE : { ...this.state.dialog, open: false } });
  }

  /**
   * Views
   */

  _getTableErrorView() {
    return (
      <div className={`${getInObj(['table'], this.state.errors) ? inputStyles.error : typography.bodyS}`}>{TABLE_MIN_SECTIONS_MSG}</div>
    );
  }

  render() {
    return (
      <div>
        <div className={`${formStyles.container} ${layout.marginBottom60}`}>
          <BasicFormInput
            errors={this.state.errors.title}
            label="Section title"
            onChange={(e) => this.setState({ errors: { ...this.state.errors, title: null }, title: e.target.value })}
            placeholder="Type your title here"
            value={this.state.title}
          />
        </div>

        <TableView
          content={this.state.sections}
          openDialog={() => this._openDialogWithData({ data: this.state.dialog.data, mode: 'default', title: 'Add content item' })}
          removeSection={this.removeSection}
          updateSection={(section) => this._openDialogWithData({ data: section, mode: 'edit', title: 'Edit content item' })}
          updateSectionsOrder={(sections) => this.setState({ sections })}
        />

        <div className={layout.marginBottom30}>
          <div className={layout.marginBottom15}>
            <h3 className={typography.h3}>Preview</h3>
          </div>
          <PromotedContent
            content={this.state.sections}
            renderForPreview={true}
          />
        </div>

        {this._getTableErrorView()}

        <Dialog
          dismiss={() => this._closeDialogWithData(this.state.dialog.mode === 'edit')}
          dismissStyle={{ fontWeight: 100, opacity: 1 }}
          open={this.state.dialog.open}
          title={this.state.dialog.title}
          titleStyle={{ fontSize: 24, marginBottom: 40, textAlign: 'left' }}
          wrapperStyle={{ marginTop: 100, width: 870 }}
        >
          <Form
            algoliaPartsService={this.props.algoliaPartsService}
            algoliaTagsService={this.props.algoliaTagsService}
            dismiss={() => this._closeDialogWithData()}
            initData={this.state.dialog.data}
            mode={this.state.dialog.mode}
            propagateContent={(content) => this.setState({ dialog: { ...this.state.dialog, data: content } })}
            saveContent={(content) => content.index !== null ? this.updateSection(content) : this.addSection(content.data)}
          />
        </Dialog>
      </div>
    );
  }
}

PromotedContentForm.propTypes = {
  algoliaPartsService: PropTypes.object.isRequired,
  algoliaTagsService: PropTypes.object.isRequired,
  initData: PropTypes.arrayOf(PropTypes.shape({})),
  initTitle: PropTypes.string,
};

PromotedContentForm.defaultProps = {
  initData: [],
  initTitle: '',
};

export default PromotedContentForm;
