import postal from 'postal';
import { graphQueryWithUserNoSigninDialog } from '../../requests/graphql';

const allIds = [
  'channel_ids',
  'followed_user_ids',
  'part_ids',
  'respected_comment_ids',
  'respected_feed_post_ids',
  'respected_news_article_ids',
  'respected_project_ids',
];

class RelationsStore {
  constructor() {
    this.channel = postal.channel('user_relations');
    this.isFetching = [];
    this.store = {};
    this.userNotLoggedIn = false;
  }

  delete(resource) {
    const bucket = this.store[resource];
    if (this.store[resource]) {
      delete this.store[resource];
      this._storeChanged();

      return bucket;
    } else {
      return new Error('Resource is not in store!');
    }
  }

  deleteIn(resource, id) {
    if (this.store[resource]) {
      this.store[resource] = this.store[resource].filter((_id) => _id !== id);
      this._storeChanged();

      return id;
    } else {
      return new Error('Resource is not in store!');
    }
  }

  fetchAll() {
    if (this.userNotLoggedIn) {
      return Promise.resolve(this.store);
    } else if (!this.isFetching.includes('all')) {
      this.isFetching.push('all');

      return graphQueryWithUserNoSigninDialog({ t: 'get_user_relations' })
        .then((res) => {
          this.isFetching = this.isFetching.filter((r) => r !== 'all');

          if (!res.user) {
            this.userNotLoggedIn = true;

            return Promise.resolve(this.store);
          }

          const buckets = res.user;
          Object.keys(buckets).forEach((key) => {
            key === 'id' ? this.set('currentUser', buckets[key]) : this.set(key, buckets[key]);
          });

          this.channel.publish('initial.store', this.store);

          return Promise.resolve(this.store);
        })
        .catch((err) => {
          this.isFetching = this.isFetching.filter((r) => r === 'all');

          return Promise.reject(err);
        });
    } else {
      return this._pollForResource('all');
    }
  }

  fetchResource(resource) {
    return new Promise((resolve, reject) => {
      if (this.userNotLoggedIn) return resolve([]);

      return this.fetchAll()
        .then(() => resolve(this.get(resource)))
        .catch((err) => reject(err));
    });
  }

  get(resource) {
    return this.store[resource] || [];
  }

  getIn(resource, id) {
    return this.store[resource] && this.store[resource].find((_id) => _id === id);
  }

  getChannel() {
    return this.channel;
  }

  getStore() {
    return this.store;
  }

  has(resource) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return this.store.hasOwnProperty(resource);
  }

  hasAll() {
    const keys = Object.keys(this.store);

    return allIds.every((ai) => keys.includes(ai));
  }

  hasIn(resource, id) {
    return this.getIn(resource, id) !== undefined;
  }

  set(resource, value) {
    if (resource === 'currentUser' && value.id && this.userNotLoggedIn) this.userNotLoggedIn = false;

    this.store[resource] = value;
    this._storeChanged();
  }

  setIn(resource, id) {
    if (this.store[resource]) {
      this.store[resource].push(id);
    } else {
      this.store[resource] = [id];
    }

    this._storeChanged();
  }

  _pollForResource(resource, time = 1000) {
    return new Promise((resolve, reject) => {
      (function poll(resource, loop, time) {
        setTimeout(() => {
          if (this.userNotLoggedIn || loop === 5) { // Bail if request is taking more than 5 seconds.
            return resolve(resource === 'all' ? this.store : this.get(resource));
          } else if (this.has(resource === 'all' ? 'part_ids' : resource)) {
            return resolve(resource === 'all' ? this.store : this.get(resource));
          } else {
            return poll.call(this, resource, loop + 1, time);
          }
        }, time);
      }.bind(this)(resource, 0, time));
    });
  }

  // TODO: If we pass the record that was changed to the publish event, then we can reduce the amount of work in the listeners.
  _storeChanged() {
    this.channel.publish('store.changed', this.store);
  }

  /** Test helpers. */
  __refresh__() {
    this.isFetching = [];
    this.store = {};
    this.userNotLoggedIn = false;
  }

  __setProperty__(property, value) {
    this[property] = value;
  }
}

const userRelationsStore = new RelationsStore();
export default userRelationsStore;
