import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LazyComponent from '../wrappers/lazy_component';

import { processURL, getHeight } from './helpers';
import { windowDPR } from '../../services/window';

import utilStyles from '../../styles/global_ui/util.css';
import styles from '../reusable_components/LazyImage/lazy_image.css';

/**
 * For when a background image is more convenient. Should not be used when animated
 * gifs are possible, as it does not support the mp4 conversion and large files will result
 */
class LazyBackgroundImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initStyles: {
        height: getHeight(props.ratio, props.width),
        width: props.width,
      },
      fadeIn: false,
      src: '',
    };

    this.fetchImage = this.fetchImage.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleLoad = this.handleLoad.bind(this);

    this._isMounted;
    this._shadowImg;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._shadowImg = null;
  }

  /**
   * Methods
   */
  fetchImage() {
    const { fit, queryParams, ratio, src, width } = this.props;
    const processed = processURL({
      fit,
      forceImg: true,
      queryParams: { ...queryParams, dpr: windowDPR() },
      ratio,
      src,
      width,
    });

    this._shadowLoad(processed.src);
    this.setState({ src: processed.src });
  }

  handleError(err) {
    this._setStateIfMounted({ fadeIn: false, src: '' });
  }

  handleLoad() {
    this._setStateIfMounted({ fadeIn: true });
    this._shadowImg = null;
  }

  /**
   * Helpers
   */
  _getStyle() {
    return this.props.setStyle ? this.state.initStyles : {};
  }

  _setStateIfMounted(state, cb) {
    if (this._isMounted) this.setState(state, cb);
  }

  _shadowLoad(src) {
    this._shadowImg = new Image();
    this._shadowImg.onload = this.handleLoad;
    this._shadowImg.onerror = this.handleError;
    this._shadowImg.src = src;
  }

  render() {
    const { alt, classList, loadBuffer, onClick, onMouseLeave, onMouseOver } = this.props;

    return (
      <LazyComponent
        className={`${classList.root} ${styles.root}`}
        horizontalOffset={loadBuffer}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        onReveal={this.fetchImage}
        style={this._getStyle()}
        verticalOffset={loadBuffer}
      >
        <div
          className={`${classList.image} ${styles.imageBG} ${utilStyles.absolutePlaceholderChild} ${this.state.fadeIn ? styles.fadeIn : ''}`}
          style={this.state.src ? { backgroundImage: `url("${this.state.src}")` } : null}
          title={alt}
        />
      </LazyComponent>
    );
  }
}

LazyBackgroundImage.propTypes = {
  classList: PropTypes.shape({
    image: PropTypes.string,
    root: PropTypes.string,
  }),
  fit: PropTypes.string,
  loadBuffer: PropTypes.number,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  queryParams: PropTypes.object,
  ratio: PropTypes.string.isRequired,
  setStyle: PropTypes.bool, // Set inline width/height styles to calculated dimensions
  src: PropTypes.string,
  width: PropTypes.number.isRequired,
};

LazyBackgroundImage.defaultProps = {
  alt: '',
  classList: {},
  className: '',
  loadBuffer: 0,
  fit: 'min',
  onClick: () => {},
  onMouseLeave: () => {},
  onMouseOver: () => {},
  queryParams: {},
  setStyle: true,
  src: '',
};

export default LazyBackgroundImage;
