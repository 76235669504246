import { CharacterMetadata, EditorState } from 'draft-js';
import { OrderedSet } from 'immutable';

import { linkifyPreviousWord } from '../transactions/links';
import { insertMentionSymbolAtCursor, insertTextAtCursor } from '../transactions/inserts';
import { characterBeforeCursorHasEntity, getWordBeforeCursor } from '../draftHelpers.js';
import { isValidUrl } from '../../../../../utility/links';

/**
 * Operations
 */

// NOTE: Do not call this when selectionState.isCollapsed is false.
function _entifyPreviousLetter(character, editorState, previousLettersEntity) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selectionState.getAnchorKey());

  const characterMetadata = CharacterMetadata.create({
    entity: previousLettersEntity,
    style: OrderedSet(),
  });

  const anchorOffset = selectionState.getAnchorOffset();
  const currentText = currentBlock.getText();
  const updatedText = currentText.slice(0, anchorOffset).concat(character).concat(currentText.slice(anchorOffset));
  const updatedCharacterList = currentBlock.getCharacterList().insert(anchorOffset, characterMetadata);

  const updatedBlock = currentBlock.merge({
    text: updatedText,
    characterList: updatedCharacterList,
  });

  const updatedBlockMap = contentState.getBlockMap().set(updatedBlock.getKey(), updatedBlock);
  const updatedContentState = contentState.set('blockMap', updatedBlockMap);
  const updatedSelectionState = selectionState.merge({
    anchorOffset: anchorOffset + 1,
    focusOffset: anchorOffset + 1,
  });
  const updatedEditorState = EditorState.acceptSelection(
    EditorState.push(editorState, updatedContentState, 'applied-entity'),
    updatedSelectionState,
  );

  this.updateEditorState(updatedEditorState);

  return 'handled';
}

// Remove all entities from characters after the space insertion.
// NOTE: that there is still this issue when a mention is split via Return key.
function _deEntifyContentAfterSpaceInsert(editorState) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selectionState.getAnchorKey());

  const anchorOffset = selectionState.getAnchorOffset();
  const textArray = currentBlock.getText().split('');
  const characterList = currentBlock.getCharacterList().toJS().slice(0, anchorOffset);

  // We're at the end of the block, no ops needed.
  if (textArray.length === anchorOffset) return editorState;

  let knownEntity = null;
  for (let i = characterList.length - 1; i >= 0; i--) {
    if (i !== characterList.length - 1 && textArray[i] === ' ') {
      // Once we hit a empty space beyond the one we just inserted, kill the loop, we only care if the preceeding word has a entity.
      break;
    } else if (characterList[i].entity !== null) {
      knownEntity = characterList[i].entity;
      break;
    }
  }
  // Nothing to do, return early.
  if (!knownEntity) return editorState;

  const updatedCharacterList = currentBlock.getCharacterList().map((char, i) => (i >= anchorOffset && char.getEntity() === knownEntity) ? char.set('entity', null) : char);
  const updatedBlock = currentBlock.set('characterList', updatedCharacterList);
  const updatedBlockMap = contentState.getBlockMap().set(updatedBlock.getKey(), updatedBlock);
  const updatedContentState = contentState.set('blockMap', updatedBlockMap);

  return EditorState.push(editorState, updatedContentState, 'removed-entity');
}

/**
 * Handlers
 */
function handleSpace() {
  if (characterBeforeCursorHasEntity(this.state.editorState, ['LINK', 'MENTION', 'TAG'])) {
    // Insert a clean space when preceeding character has an entity.
    const updatedEditorState = insertTextAtCursor(this.state.editorState, ' ');
    this.updateEditorState(_deEntifyContentAfterSpaceInsert(updatedEditorState));

    return 'handled';
  } else if (!characterBeforeCursorHasEntity(this.state.editorState, 'LINK') && isValidUrl(getWordBeforeCursor(this.state.editorState))) {
    // TODO: For now we're ignoring the creating of Links, since they can be split apart with a space and the entity is held
    // true.  Once we fix that we can enable links.  The init parser need to know how to create Links as well.
    const { link/* , updatedEditorState */ } = linkifyPreviousWord(this.state.editorState);

    // this.setState({editorState: insertTextAtCursor(updatedEditorState, ' ')});
    this.props.handleEmbed(link);
    // return 'handled';
  }

  return 'not-handled';
}

function handleMention() {
  this.updateEditorState(insertMentionSymbolAtCursor(this.state.editorState));

  return 'handled';
}

function handleDefault(character, editorState) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  // Boot out early if text is selected
  if (!selectionState.isCollapsed()) return 'not-handled';

  const currentBlock = contentState.getBlockForKey(selectionState.getAnchorKey());
  const previousLettersEntity = currentBlock.getEntityAt(selectionState.getAnchorOffset() - 1);

  // Draft v0.11 does not chain entities anymore when typing. Mentions rely on this to display the user dropdown list.
  // This will manually create and bind the entity of a previous input.
  if (previousLettersEntity !== null) return _entifyPreviousLetter.call(this, character, editorState, previousLettersEntity);

  return 'not-handled';
}

export default function customBeforeInputHandler(character, editorState) {
  switch (character) {
    case ' ':
      return handleSpace.call(this);

    case '@':
      return handleMention.call(this);

    default:
      return handleDefault.call(this, character, editorState);
  }
}
