/* eslint-disable valid-typeof */

function getRecordFromList(list, key) {
  return Array.isArray(key)
    ? list.find((item) => item[key[0]] === key[1])
    : new Error(`Expected next in path to be a [key: value] list! Key: ${key}`);
}

function removeFromList(list, key) {
  return list.filter((item) => item[key[0]] !== key[1]);
}

function updateList(list, key, record) {
  let found = false;

  return list.reduce((acc, item, i) => {
    if (i === list.length - 1 && item[key[0]] !== key[1] && !found) {
      return acc.concat(record);
    } else if (item[key[0]] === key[1]) {
      found = true;

      return acc.concat({ ...item, ...record });
    } else {
      return acc.concat(item);
    }
  }, []);
}

export default class Store {
  constructor() {
    this.previous = {};
    this.store = {};
  }

  delete(key) {
    const bucket = this.store[key];
    if (this.store[key]) {
      this.previous[key] = bucket;
      delete this.store[key];

      return bucket;
    } else {
      return new Error('Resource is not in store!');
    }
  }

  deleteIn(path) {
    return path.reduce((acc, key, i) => {
      if (typeof acc === 'error') return acc;

      if (Array.isArray(acc)) {
        if (i === path.length - 1) {
          acc = removeFromList(acc, key);
          this.updateRecords(path.slice(0, -1), acc);
        } else {
          acc = getRecordFromList(acc, key);
        }
      } else if (i !== path.length - 1 && !acc[key]) {
        acc = new Error(`Incorrect path for setIn! Path: ${path}`);
      } else if (i === path.length - 1) {
        delete acc[key];
      } else {
        acc = acc[key];
      }

      return acc;
    }, this.store);
  }

  dispatch(request, key, refetch = false) {
    return new Promise((resolve, reject) => {
      if (this.has(key) && refetch === false) return resolve(this.get(key));

      return request()
        .then((result) => {
          this.set(key, result);

          return resolve(result);
        })
        .catch((err) => reject(err));
    });
  }

  get(key) {
    return this.store[key];
  }

  getIn(path) {
    if (!Array.isArray(path)) return new Error(`getIn argument must be an array!  Arg: ${path}`);

    return path.reduce((acc, key) => {
      if (typeof acc === 'error') return acc;

      if (Array.isArray(acc)) {
        acc = getRecordFromList(acc, key);
      } else if (acc[key]) {
        acc = acc[key];
      } else {
        acc = new Error(`Incorrect path for getIn! Path: ${path}, Key: ${key}`);
      }

      return acc;
    }, this.store);
  }

  getPrevious(key) {
    return this.previous[key];
  }

  getStore() {
    return this.store;
  }

  has(key) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return this.store.hasOwnProperty(key);
  }

  hasIn(path) {
    if (!Array.isArray(path)) return new Error(`hasIn argument must be an array!  Arg: ${path}`);

    let hasIn = true;
    path.reduce((acc, key) => {
      if (!hasIn) return acc;

      if (Array.isArray(acc)) {
        const record = getRecordFromList(acc, key);

        if (typeof record !== 'error' && typeof record !== 'undefined') {
          acc = record;
        } else {
          hasIn = false;
        }
      } else if (acc[key]) {
        acc = acc[key];
      } else {
        hasIn = false;
      }

      return acc;
    }, this.store);

    return hasIn;
  }

  set(key, value) {
    if (this.has(key)) {
      this.previous[key] = this.store[key];
    }
    this.store[key] = value;

    return this.store;
  }

  setAll(obj) {
    this.store = { ...this.store, ...obj };

    return this.store;
  }

  setIn(path, record) {
    return path.reduce((acc, key, i) => {
      if (typeof acc === 'error') return acc;

      if (Array.isArray(acc)) {
        if (i === path.length - 1) {
          acc = updateList(acc, key, record);
          this.updateRecords(path.slice(0, -1), acc);
        } else {
          acc = getRecordFromList(acc, key);
        }
      } else if (i !== path.length - 1 && !acc[key]) {
        acc = new Error(`Incorrect path for setIn! Path: ${path}`);
      } else if (i === path.length - 1) {
        acc[key] = record;
      } else {
        acc = acc[key];
      }

      return acc;
    }, this.store);
  }

  updateRecords(path, records) {
    path.reduce((acc, key, i) => {
      if (i === path.length - 1) {
        acc[key] = records;
      } else {
        acc = acc[key];
      }

      return acc;
    }, this.store);
  }
}
