import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TriangleDown from '../../reusable_components/icons/TriangleDown';
import styles from '../../../styles/global_ui/table.css';

class OrderableHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.props.header.orderable) this.props.setHeaderActive(this.props.header, this.props.position);
  }

  _getOrderableTitleStyle() {
    return this.props.header.status.isActive ? styles.thActive : this.props.header.orderable ? styles.thInteractive : '';
  }

  render() {
    return (
      <th className={`${this.props.position === 0 ? styles.thSelectableCell : styles.thCell}`} style={this.props.config}>
        <div className={`${styles.thTitleWrapper} ${this._getOrderableTitleStyle()}`} onClick={this.onClick}>
          <span className={styles.thTitle}>{this.props.header.title}</span>
          <span className={styles.triangleWrapper}>
            {this.props.header.orderable
            && <TriangleDown className={`${styles.triangle} ${this.props.header.status.state === 1 ? styles.triangleActive : ''}`} />}
          </span>
        </div>
      </th>
    );
  }
}

OrderableHeader.propTypes = {
  config: PropTypes.shape({ width: PropTypes.number }),
  header: PropTypes.shape({
    orderable: PropTypes.bool.isRequired,
    sort: PropTypes.string,
    status: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
      state: PropTypes.number.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }),
  position: PropTypes.number.isRequired,
  setHeaderActive: PropTypes.func.isRequired,
};

OrderableHeader.defaultProps = { config: {} };

export default OrderableHeader;
