import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import NewsArticleSponsoredCard from '../../../../../client/cards/news_article/sponsored_card';
import NewsArticleSponsoredCardDummy from '../../../../../client/cards/news_article/sponsored_card/dummy';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';

const _getCardsView = (articles) => articles.map((article, i) => (
  <Fragment key={article.id}>
    {i === 0 ? null : _getDivider()}
    <NewsArticleSponsoredCard article={article} isResponsive={false} location="sponsored_articles_side" />
  </Fragment>
));

const _getDivider = () => (
  <div className={`${layout.marginTop15} ${layout.marginBottom15} ${utilStyles.borderTop}`} />
);

const _getLoadingView = () => (
  <Fragment>
    <NewsArticleSponsoredCardDummy isResponsive={false} />
    {_getDivider()}
    <NewsArticleSponsoredCardDummy isResponsive={false} />
  </Fragment>
);

const SponsoredArticles = ({ articles }) => {
  if (articles && articles.length === 0) return null;

  return (
    <div className={`${layout.marginTop45} ${layout.fullWidth}`}>
      <h5 className={typography.h5}>Sponsored articles</h5>
      {_getDivider()}
      <div className={`${layout.padding15} ${utilStyles.bgFog}`}>
        {articles ? _getCardsView(articles) : _getLoadingView()}
      </div>
    </div>
  );
};

SponsoredArticles.propTypes = { articles: PropTypes.array };

SponsoredArticles.defaultProps = { articles: null };

export default SponsoredArticles;
