import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS, List, Map } from 'immutable';
import { CharacterMetadata, ContentState, convertToRaw, EditorState, genKey } from 'draft-js';

import Editor from '../components/Editor';
import Toolbar from '../components/Toolbar';
import Messenger from '../components/Messenger';

import processImageFiles from '../utils/processImageFiles';
import { isImageValid } from '../utils/Helpers';
import { splitBlockAndInsertCustomBlock, removeBlock, updateBlock } from '../utils/draftUtils';

import convertFromHTMLToContentBlocks from '../encoding/convertFromHTMLToContentBlocks';
import convertDataModelToDraftModel from '../encoding/convertDataModelToDraftModel';
import convertRawToDataModel from '../encoding/convertRawToDataModel';
import decorator from '../modifiers/decorator';
import setupBlockRestrictions from '../config/setupBlockRestrictions';

import {doesCurrentSelectionContainStyle} from '../utils/draft/conditions';
import {getInlineStylesAndEntity} from '../utils/draft/getters';
import {forceSelection, toggleInlineStyle} from '../utils/draft/modifiers';

export default class Draftster extends Component {
  constructor(props) {
    super(props);

    this.doesCurrentInstanceHaveFocus = this.doesCurrentInstanceHaveFocus.bind(this);
    this.fetchInitialContent = this.fetchInitialContent.bind(this);

    this.handleDraftOnChange = this.handleDraftOnChange.bind(this);
    this.handleHashChange = this.handleHashChange.bind(this);

    this.handleImages = this.handleImages.bind(this);
    this.handleImageUrl = this.handleImageUrl.bind(this);
    this.handleProcessedImages = this.handleProcessedImages.bind(this);

    this.setDraftState = this.setDraftState.bind(this);
    this.setMessengerState = this.setMessengerState.bind(this);
    this.setUnsavedChanges = this.setUnsavedChanges.bind(this);
    this.toggleReadOnly = this.toggleReadOnly.bind(this);

    // Public Methods.
    this.getEditorContent = this.getEditorContent.bind(this);
    this.hasUnsavedChanges = this.hasUnsavedChanges.bind(this);
    this.triggerMessenger = this.triggerMessenger.bind(this);

    this.state = {
      activeMetadata: Map({
        activeEntities: List(),
        activeStyles: List()
      }),
      draft: EditorState.set(EditorState.createEmpty(), {decorator, allowUndo: false}),
      editor: Map({
        activeEntities: List(),
        activeStyles: List(),
        blockRestrictions: List(setupBlockRestrictions(props.config.toolbar || {})),
        hasFocus: false,
        initialLoadComplete: false,
        instanceId: `draftster-${genKey()}`,
        isDialogOpen: false,
        hasUnsavedChanges: false,
        width: 0
      }),
      readOnly: false,
      imagesProcessing: Map({}),
      messenger: Map({
        open: false,
        msg: '',
        type: 'error'
      }),
    };

    // Refs.
    this.root;
    this.editor;
  }

  componentDidMount() {
    // Forces a render on hash change.  Mainly for hideEditor to be called.
    window.addEventListener('hashchange', this.handleHashChange);
    window.addEventListener('click', this.doesCurrentInstanceHaveFocus);
    this.fetchInitialContent();
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChange);
    window.removeEventListener('click', this.doesCurrentInstanceHaveFocus);
  }

  UNSAFE_componentWillUpdate(_nextProps, nextState) {
    if (this.state.editor.get('initialLoadComplete') && nextState.draft.getCurrentContent() !== this.state.draft.getCurrentContent()) {
      if (!this.state.editor.get('hasUnsavedChanges')) {
        this.setState({editor: nextState.editor.set('hasUnsavedChanges', true)}, this.props.config.editorWasUpdated());
      }
    }

    // Logic to tell the parent that we are processing image urls via paste or input.
    if (this.props.config.isEditorBusy && nextState.imagesProcessing.size) {
      this.props.config.isEditorBusy(true);
    } else if (this.props.config.isEditorBusy && this.state.imagesProcessing.size > 0 && nextState.imagesProcessing.size < 1) {
      this.props.config.isEditorBusy(false);
    }

    // Autosave
    if (this.props.config.editorWasUpdated && (this.state.imagesProcessing.size > 0 && nextState.imagesProcessing.size < 1)) {
      this.setState({editor: nextState.editor.set('hasUnsavedChanges', true)}, this.props.config.editorWasUpdated());
    }
  }

  /**
   * Hooks
   */
  __resetEditorState(content) {
    const editorState = Array.isArray(content) ? this._resolveContentForArray(content) : this._resolveContentForString(content);

    this.setState({
      draft: EditorState.set(editorState, {decorator, allowUndo: true}),
      editor: this.state.editor.set('hasUnsavedChanges', false)
    });
  }

  /**
   * Config Callbacks
   */

  // ATTN
  doesCurrentInstanceHaveFocus(e) {
    // Firefox and Safari don't have access to e.relatedTarget on blur and focus events.
    // This is a workaround to tell if the current instance is focused or not.
    // We need to make sure button clicks DO NOT bubble up to here!
    const editor = this.state.editor;
    if (editor.get('hasFocus') && this.root && !this.root.contains(e.target)) {
      this.setState({editor: editor.set('hasFocus', false)});
    }
  }

  fetchInitialContent() {
    const initContent = this.props.config.setInitialContent();
    return initContent && typeof initContent.then === 'function'
      ? this._resolveInitContentAsPromise(initContent)
      : this._resolveInitContent(initContent);
  }

  _resolveInitContent(content) {
    if (!Array.isArray(content) && typeof content !== 'string') {
      return this.setMessengerState({open: true, msg: 'Yikes, we had an issue getting your content! Invalid Content Type.', type: 'error'});
    }

    const editorState = Array.isArray(content) ? this._resolveContentForArray(content) : this._resolveContentForString(content);

    this.setState({
      draft: EditorState.moveFocusToEnd(EditorState.set(editorState, {decorator, allowUndo: true})),
      editor: this.state.editor.set('initialLoadComplete', true)
    }, () => {
      if (typeof this.props.config.initialized === 'function') this.props.config.initialized();
    });
  }

  _resolveContentForArray(content) {
    return convertDataModelToDraftModel(content, this.state.editor.get('blockRestrictions'));
  }

  _resolveContentForString(content) {
    const {contentBlocks, entityMap} = convertFromHTMLToContentBlocks(content, this.state.editor.get('blockRestrictions'));

    if (!contentBlocks.length) return EditorState.createEmpty();

    return EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap),
      decorator
    );
  }

  _resolveInitContentAsPromise(contentAsPromised) {
    return contentAsPromised
      .then(content => this._resolveInitContent(content))
      .catch(err => {
        this.setMessengerState({open: true, msg: 'Yikes, we had an issue getting your content!', type: 'error'});
        return Promise.reject(err);
      });
  }

  getEditorContent() {
    const currentContent = this.state.draft.getCurrentContent();

    this.setState({editor: this.state.editor.set('hasUnsavedChanges', false)});
    return convertRawToDataModel(convertToRaw(currentContent), currentContent.getBlockMap());
  }

  hasUnsavedChanges() {
    return this.state.editor.get('hasUnsavedChanges');
  }

  /**
   * Methods
   */

  /**
   * Draft's onChange callback updates on every event, frequently.  To avoid crazy race conditions, this callback is seperated
   * from when we update editor state manually.  This fn handles inline metadata for the cursor position as well.
   * The first if block in the fn is to ignore setting character metadata when the event was not triggered inside of the draft editor.
   * E.g. when a toolbar button is clicked, selection.hasFocus will be false.
   */
  handleDraftOnChange(draft) {
    if (!draft.getSelection().getHasFocus()) return this.setState({draft});

    const metadata = getInlineStylesAndEntity(draft);
    const entity = metadata.get('entity');
    const activeMetadata = this.state.activeMetadata.merge({
      activeEntities: entity ? List(entity.getType()) : List(),
      activeStyles: metadata.get('styles')
    });

    this.setState({activeMetadata, draft});
  }

  handleHashChange() {
    if (this.props.config.hideEditor && this.props.config.hideEditor() === false) {
      this.forceUpdate();
    }

    if (this.state.editor.get('hasUnsavedChanges')) {
      this.setState({editor: this.state.editor.set('hasUnsavedChanges', false)});
    }
  }

  _refetchEditorWidth() {
    if (this.editor) {
      this.editor._propagateEditorWidth();
    }
  }

  setDraftState(draft, readOnly) {
    const metadata = getInlineStylesAndEntity(draft);
    const entity = metadata.get('entity');
    const activeMetadata = this.state.activeMetadata.merge({
      activeEntities: entity ? List(entity.getType()) : List(),
      activeStyles: metadata.get('styles')
    });

    if (typeof readOnly === 'boolean') {
      this.setState({activeMetadata, draft, readOnly});
    } else {
      this.setState({activeMetadata, draft});
    }
  }

  setUnsavedChanges() {
    this.setState({editor: this.state.editor.set('hasUnsavedChanges', true)}, () => {
      if (this.props.config.editorWasUpdated) this.props.config.editorWasUpdated();
    });
  }

  // ATTN: Why fromJS here?
  setMessengerState(msg) {
    this.setState({messenger: fromJS(msg)});
  }

  toggleReadOnly(bool) {
    // Remove active styles when readOnly is true.
    const editor = this.state.editor.merge({
      activeStyles: bool ? List() : this.state.editor.get('activeStyles')
    });

    this.setState({
      editor,
      readOnly: bool
    });
  }

  triggerMessenger(msg, type) {
    this.setMessengerState({open: true, msg: msg, type: type || 'error'});
  }

  /**
   * Atomic Block helpers (Move logic to helper files!)
   */
  handleImages(files) {
    const filteredFiles = [].slice.call(files).filter(file => {
      const isValid = isImageValid(file.type);
      // Exclude html files as they will be pasted in no matter what.
      if (!isValid && file.type !== 'text/html') {
        this.setMessengerState({open: true, msg: `Sorry, ${file.name || 'that'} is not a valid image.`, type: 'error'});
      }

      return isValid;
    });

    if (!filteredFiles.length) return;

    return processImageFiles(filteredFiles)
      .then(images => this.handleProcessedImages(images, 'CAROUSEL'))
      .catch(err => {
        this.setMessengerState({open: true, msg: 'Woops, there was an error uploading your image!', type: 'error'});

        if (this.props.config && this.props.config.isEditorBusy) {
          this.props.config.isEditorBusy(false);
        }
      });
  }

  handleProcessedImages(images, blockType) {
    // Set the first image's show prop to true.
    images[0].show = true;

    const editorState = this.state.draft;
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const contentStateWithEntity = blockType === 'CAROUSEL'
      ? currentContent.createEntity('TOKEN', 'IMMUTABLE', { images: fromJS(images) })
      : currentContent.createEntity('TOKEN', 'IMMUTABLE', { image: fromJS(images[0]) });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const charData = CharacterMetadata.create({ entity: entityKey });

    const { newEditorState, newSelectionState, newBlock } = splitBlockAndInsertCustomBlock(editorState, currentContent, selectionState, charData, blockType);

    this.setDraftState(EditorState.forceSelection(
      newEditorState,
      newSelectionState
    ));

    if (this.props.config.handleImageUpload) {
      this._handleImageUpload(images, entityKey, newBlock.getKey());
    }
  }

  _handleImageUpload(images, entityKey, blockKey) {
    if (!this.props.config.handleImageUpload) return;

    images.forEach((image, index, list) => {
      // Trigger isEditorBusy.
      if (this.props.config.isEditorBusy && index === 0) {
        this.props.config.isEditorBusy(true);
      }

      this.props.config.handleImageUpload(image, (err, updatedImage) => {
        this._updateImageEntity(err, updatedImage, entityKey, blockKey);
        // Trigger isEditorBusy.
        if (this.props.config.isEditorBusy && index === list.length-1) {
          this.props.config.isEditorBusy(false);
        }
      });
    });
  }

  // Note: This is used for uploading images via paste.
  handleImageUrl(image, entityKey, blockKey) {
    if (this.props.config && this.props.config.processImage) {
      this.setState({imagesProcessing: this.state.imagesProcessing.set(image.uuid, image)});

      return this.props.config.processImage(image)
        .then(updatedImage => {
          // TODO: merge the result id and url and make the updatedImage
          this._updateImageEntity(null, {...updatedImage, pending: false}, entityKey, blockKey);
          this.setState({imagesProcessing: this.state.imagesProcessing.delete(updatedImage.uuid)});
        })
        .catch(err => {
          this._updateImageEntity(err, {...image, pending: false}, entityKey, blockKey);
        });
    } else {
      this._updateImageEntity(null, {...image, pending: false}, entityKey, blockKey);
    }
  }

  _updateImageEntity(err, image, entityKey, blockKey) {
    // Quick fix: This is saying the editor was likely reset, its no longer in the current view, ignore any
    // lingering image requests. Remove when all the requests become cancelable.
    if (this.props.config.hideEditor && this.props.config.hideEditor()) return;

    const currentEditorState = this.state.draft;
    const block = currentEditorState
      .getCurrentContent()
      .getBlockForKey(blockKey);

    // Another hack when the editor was likely reset. This would happen if a user navigated to a tab, reset the editors state,
    // then navigated back to correct tab where hideEditor would return false. Remove this hack when requests become cancelable.
    if (!block) return;

    const blockType = block.getType();
    const updatedContent = blockType === 'CAROUSEL'
      ? this._updateImagesInCarousel(err, image, entityKey, blockKey)
      : this._updateImageInImageLink(err, image, entityKey, blockKey);

    this.setState({
      draft: EditorState.forceSelection(
        EditorState.push(currentEditorState, updatedContent),
        currentEditorState.getSelection()
      ),
      editor: this.state.editor.set('hasUnsavedChanges', true)
    }, () => {
      if (this.props.config.editorWasUpdated) this.props.config.editorWasUpdated();
    });

    if (err) {
      this.setMessengerState({ open: true, msg: `Woops, there was an error uploading ${image.name || image.url || "your image"}!`, type: 'error' });
    }
  }

  _updateImagesInCarousel(err, image, entityKey, blockKey) {
    const currentEditorState = this.state.draft;
    const contentState = currentEditorState.getCurrentContent();
    const images = contentState.getEntity(entityKey).getData().images;
    const activeImage = images.find(i => i.get('show'));

    const updatedImages = err
      ? images.filter(i => i.get('uuid') !== image.uuid)
      : images.map(i => {
          if (i.get('uuid') === image.uuid) {
            if (!activeImage) return i.merge(image);
            return activeImage.get('uuid') === i.get('uuid') ? i.merge(image) : i.merge(image).set('show', false);
          }
          return i;
        });

    return updatedImages.size
     ? contentState.mergeEntityData(entityKey, {images: updatedImages})
     : removeBlock(currentEditorState, currentEditorState.getCurrentContent().getBlockForKey(blockKey));
  }

  _updateImageInImageLink(err, image, entityKey, blockKey) {
    const currentEditorState = this.state.draft;
    const contentState = currentEditorState.getCurrentContent();
    if (err) return removeBlock(currentEditorState, contentState.getBlockForKey(blockKey));

    return contentState.mergeEntityData(entityKey, { image: contentState.getEntity(entityKey).getData().image.merge(image) });
    return updateBlock(currentEditorState, currentEditorState.getCurrentContent().getBlockForKey(blockKey));
  }

  /**
   * Editor state helpers
   */
  _setActiveStyle(style) {
    const styles = this.state.activeMetadata.get('activeStyles');
    return styles.includes(style) ? this.state.activeMetadata : this.state.activeMetadata.set('activeStyles', styles.push(style));
  }

  /**
   * When selection is collapsed, add or remove the style in state.activeMetadata and forceSelection to refocus editor.
   * When selection has a range, let Draft do its thing and check if the startOffset contains the style arg.
   */
  _toggleActiveStyle(style) {
    if (this.state.draft.getSelection().isCollapsed()) {
      const draft = forceSelection(this.state.draft);
      const applyStyle = !this.state.activeMetadata.get('activeStyles').includes(style);
      const activeMetadata = applyStyle ? this._setActiveStyle(style) : this._removeActiveStyle(style);
      this.setState({activeMetadata, draft});
    } else {
      const draft = toggleInlineStyle(this.state.draft, style);
      const activeMetadata = doesCurrentSelectionContainStyle(draft, style) ? this._setActiveStyle(style) : this._removeActiveStyle(style);
      this.setState({activeMetadata, draft});
    }
  }

  _removeActiveStyle(style) {
    const styles = this.state.activeMetadata.get('activeStyles');
    return styles.includes(style) ? this.state.activeMetadata.set('activeStyles', styles.filter(s => s !== style)) : this.state.activeMetadata;
  }

  _removeAllActiveStyles() {
    this.setState({activeMetadata: this.state.activeMetadata.set('activeStyles', List())});
  }

  render() {
    if (this.props.config.hideEditor && this.props.config.hideEditor()) return null;

    return (
      <div
        ref={el => this.root = el}
        id={this.state.editor.get('instanceId')}
        className={`react-editor-wrapper ${this.props.config.className}`}
        >
        <Toolbar
          activeMetadata={this.state.activeMetadata}
          draft={this.state.draft}
          editor={this.state.editor}
          onImageUpload={this.handleImages}
          refetchEditorWidth={() => this._refetchEditorWidth()}
          scrollBindingElement={this.props.config.scrollBindingElement}
          setDraft={this.setDraftState}
          setMessenger={this.setMessengerState}
          toggleActiveStyle={style => this._toggleActiveStyle(style)}
          toggleReadOnly={this.toggleReadOnly}
          toolbarConfig={this.props.config.toolbar}
          toolbarSettings={this.props.config.toolbarSettings}
          uploadProcessedImage={this.handleProcessedImages}
        />
        <Editor
          ref={el => this.editor = el}
          activeMetadata={this.state.activeMetadata}
          draft={this.state.draft}
          editor={this.state.editor}
          onDraftChange={this.handleDraftOnChange}
          onDraftFocus={e => {
            if (!this.state.editor.get('hasFocus')) {
              this.setState({editor: this.state.editor.set('hasFocus', true)});
            }
          }}
          onImageUpload={this.handleImages}
          processImage={this.handleImageUrl}
          readOnly={this.state.readOnly}
          setDraft={this.setDraftState}
          setEditorWidth={width => this.setState({ editor: this.state.editor.set('width', width) })}
          setMessenger={this.setMessengerState}
          setUnsavedChanges={this.setUnsavedChanges}
          toggleActiveStyle={style => this._toggleActiveStyle(style)}
          toggleDialogStatus={status => this.setState({ editor: this.state.editor.set('isDialogOpen', status) })}
          toggleReadOnly={this.toggleReadOnly}
          uploadImages={(files, entityKey, blockKey) => this._handleImageUpload(files, entityKey, blockKey)}
        />
        {this.state.messenger.get('open') &&
          <Messenger messenger={this.state.messenger} setMessenger={this.setMessengerState} />
        }
      </div>
    );
  }
}

Draftster.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    editorWasUpdated: PropTypes.func,
    handleImageUpload: PropTypes.func,
    hideEditor: PropTypes.func,
    initialized: PropTypes.func,
    isEditorBusy: PropTypes.func,
    scrollBindingElement: PropTypes.object,
    setInitialContent: PropTypes.func,
    toolbar: PropTypes.object,
    toolbarSettings: PropTypes.shape({
      tooltips: PropTypes.arrayOf(PropTypes.string)
    })
  })
};

Draftster.defaultProps = {
  config: {
    className: '',
    hideEditor: () => {},
    initialized: () => {},
    setInitialContent: () => Promise.resolve('<p></p>'),
    toolbarSettings: {
      tooltips: []
    }
  }
};