import keenService from '../../../services/keen/main';
import { getInObj } from '../../../utility/accessors';
import { NOOP_HREF } from '../../../constants/links';

export const isEditable = (project = {}) => true;

export function getProjectLink(project, property) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!project.hasOwnProperty(property)) return NOOP_HREF;

  return project[property];
}

export function handleMainLinkClick(e, project, location, overrideTracking, itemIndex) {
  if (typeof overrideTracking === 'function') {
    overrideTracking(
      e,
      { external: project.url, internal: '' },
      { id: project.id, index: itemIndex, type: 'project_card' },
      [{
        eventName: 'Clicked link',
        customProps: { href: project.url, location: location, type: 'project_card', value: project.name },
      }],
    );
  } else {
    keenService.recordEventWithDelayedRedirect(
      { eventName: 'Clicked link' },
      { href: project.url, location: location, type: 'project_card', value: project.name },
      project.url,
      e,
    );
  }
}

export function normalizeProject(project) {
  const authors = getInObj(['team', 'members'], project) || project.authors || [];
  const teamName = getInObj(['team', 'name'], project) || (authors.length > 0 ? 'Multiple Authors' : '');
  const contentType = Array.isArray(project.content_type) ? project.content_type[0] : project.content_type;
  const contest_winner = Array.isArray(project.content_type) ? project.content_type.includes('contest_winner') : !!project.contest_winner;
  const respects = project.respects_count || getInObj(['stats', 'respects'], project) || 0;
  const views = project.impressions_count || getInObj(['stats', 'views'], project) || 0;

  return {
    cover_image_url: project.cover_image_url || '',
    contest_winner,
    difficulty: project.difficulty, // for HorizontalProjectCard only
    guest_name: project.guest_name || '',
    hid: project.hid,
    id: project.id,
    name: project.name,
    one_liner: project.one_liner || project.pitch, // for HorizontalProjectCard only
    published_state: project.published_state || '',
    stats: {
      respects,
      views,
    },
    team: {
      members: authors,
      name: teamName,
    },
    type_label: contentType, // Displayed on HorizontalProjectCard only, but used for type-checking elsewhere
    url: project.url,
  };
}
