import validator from 'validator';

const URL_EXTENSIONS = {
  autodesk360: /myhub\.autodesk360\.com\/([a-z0-9]+\/shares\/public\/[a-zA-Z0-9]+)/,
  bitbucket: /bitbucket\.org\/([0-9a-zA-Z_\-]+\/[0-9a-zA-Z_\-]+)/,
  circuits: /123d\.circuits\.io\/circuits\/([a-z0-9\-]+)/,
  channel9: /channel9\.msdn\.com\/([0-9a-zA-Z_\-\/]+)/,
  codebender: /codebender\.cc\/sketch:([0-9]+)/,
  fritzing: /fritzing\.org\/projects\/([0-9a-z-]+)/,
  gfycat: /gfycat\.com\/(?:ifr\/)?([a-zA-Z]+)/,
  giphy: /giphy.com\/(?:embed|gifs|media|clips)\/(?:[a-z\-]*\-)?([a-zA-Z0-9]+)/,
  gist: /gist\.github\.com\/(?:[0-9a-zA-Z_\-]+\/)?([0-9a-zA-Z_\-]+)/,
  github: /github\.com\/(?:downloads\/)?([0-9a-zA-Z_\-\.]+\/[0-9a-zA-Z_\-\.]+)/,
  instagram: /instagram\.com\/(?:p|reel|tv)\/([a-zA-Z\-0-9]+)/,
  kickstarter: /kickstarter\.com\/projects\/([0-9a-z\-]+\/[0-9a-z\-]+)/,
  oshpark: /oshpark\.com\/shared_projects\/([a-zA-Z0-9]+)/,
  sketchfab: /sketchfab\.com\/models\/([a-z0-9]+)/,
  snip2code: /snip2code\.com\/Snippet\/([0-9]+\/[0-9a-zA-Z]+)/,
  twitter : /twitter.com\/([a-zA-Z0-9_@]+\/status\/[0-9]+)/,
  upverter: /upverter\.com\/[^\/]+\/(?:embed\/)?(?:\#designId\=)?([a-z0-9]+)(?:\/)?(?:[^\/])*/,
  ustream: /ustream\.tv\/([a-z]+\/[0-9]+(\/[a-z]+\/[0-9]+)?)/,
  vimeo: /(?:player\.)?vimeo\.com\/(?:video\/)?([0-9]+)/,
  vine: /vine\.co\/v\/([a-zA-Z0-9]+)/,
  youtube: /(?:youtube\.com|youtu\.be)\/(?:watch\?v=|v\/|embed\/|shorts\/)?([a-zA-Z0-9\-_]+)/,
  youmagine: /youmagine\.com\/designs\/([a-zA-Z0-9\-]+)/,
  mp4: /(.+\.(?:mp4)(?:\?.*)?)$/i
};

export function isUrlValid(url) {
  // Disallow qutoes for XSS protection.
  if ((url.includes('"') || url.includes("'"))) return false;
  return validator.isURL(url);
}

export function doesUrlHaveProtocol(url) {
  return validator.isURL(url, { protocols: ['http', 'https'], require_protocol: true });
}

export function isImageValid(imgFileType) {
  const extensions = {
    'image/png': true,
    'image/jpg': true,
    'image/jpeg': true,
    'image/gif': true,
    'image/bmp': true,
    'image/tiff': true
  };

  return !!extensions[imgFileType];
}

export function isGistUrlValid(url) {
  const match = url.match(URL_EXTENSIONS['gist']);
  return match && match.length;
}

export function isTwitterUrlValid(url) {
  let match = url.match(URL_EXTENSIONS['twitter']);
  return match && match.length;
}

export function getTwitterIdFromUrl(url) {
  return url.match(URL_EXTENSIONS['twitter'])[1].split('/')[2];
}

export function getVideoData(url) {
  let urlToMatch = url.replace(/youtu\.be/, 'youtube');
  let type = urlToMatch.match(/(channel9|instagram|gfycat|giphy|kickstarter|mp4|vimeo|vine|ustream|youtube)/);
  let service = type ? type[0] : false;

  if (!service) { return false; }

  const Exts = URL_EXTENSIONS;
  let regExps = {
    'channel9': {
      regexp: Exts['channel9'],
      embed: id => `https://channel9.msdn.com/${id}/player`,
      index: 1
    },
    'gfycat': {
      regexp: Exts['gfycat'],
      embed: id => `https://gfycat.com/ifr/${id}`,
      index: 1
    },
    'giphy': {
      regexp: Exts['giphy'],
      embed: id => `https://giphy.com/embed/${id}`,
      index: 1
    },
    'instagram': {
      regexp: Exts['instagram'],
      embed: id => `https://instagram.com/p/${id}/embed/`,
      index: 1
    },
    'kickstarter': {
      regexp: Exts['kickstarter'],
      embed: id => `https://www.kickstarter.com/projects/${id}/widget/video.html`,
      index: 1
    },
    'mp4': {
      regexp: Exts['mp4'],
      embed: id => id,
      index: 0
    },
    'vimeo': {
      regexp: Exts['vimeo'],
      requestLink: id => `https://vimeo.com/api/v2/video/${id}.json`,
      embed: id => `https://player.vimeo.com/video/${id}`,
      index: 1
    },
    'vine': {
      regexp: Exts['vine'],
      requestLink: id => `https://vine.co/oembed.json?id=${id}`,
      embed: id => `https://vine.co/v/${id}/embed/simple`,
      index: 1
    },
    'ustream': {
      regexp: Exts['ustream'],
      embed: id => `http://www.ustream.tv/embed/${id}?html5ui`,
      index: 1
    },
    'youtube': {
      regexp: Exts['youtube'],
      requestLink: id => `https://img.youtube.com/vi/${id}/0.jpg`,
      embed: id => `https://www.youtube.com/embed/${id}`,
      index: 1
    }
  };

  let obj = regExps[service],
      match = url.match(obj.regexp);

  if(match && match.length) {
    let id = match[obj.index],
        embed = obj.embed(id);

    return { id: id, embed: embed, service: service, type: 'iframe' };
  } else {
    return null;
  }
}
