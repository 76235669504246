import React from 'react';
import PropTypes from 'prop-types';
import { unescape } from 'validator';

import CategoryBadge from '../templates/CategoryBadge';
import MarkdownViewer from '../../markdown/markdown_viewer';
import PostEmbed from '../post_editor/post_embed';
import RespectButton from '../../buttons/respect_button';

import keenService from '../../../services/keen/main';
import { getClickedViewMoreArgs } from '../../../services/keen/events/eventTypeTemplates';

import shouldTruncateEscapedText from '../../../services/markdown/shouldTruncateEscapedText';
import { getInObj } from '../../../utility/accessors';
import { isBlank } from '../../../utility/types';
import { objHasPropertyOfLength } from '../../../utility/predicates';
import { stripProtocol } from '../../../utility/links';

import { FEED_POST } from '../../../graphql/respects/enum.js';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const TRUNCATE_LIMIT = 217;

// Remove embed link from the body if there's an embed present and its link is the last word.
const _cleanEmbedLinkFromBody = (body, embed) => {
  if (isBlank(body) || !objHasPropertyOfLength(embed, 'link')) return body;

  const uBody = unescape(body);
  const lastWord = uBody.trim().split(' ').pop();

  return stripProtocol(lastWord) === stripProtocol(embed.link) ? uBody.replace(lastWord, '').trim() : uBody;
};

const fireViewMoreClickedAnalytics = (post) => (
  keenService.reportEventWithObj(getClickedViewMoreArgs({ entity_id: post.id, entity_type: 'FeedPost' }))
);

const getShouldTruncate = (post) => {
  const shouldTruncate = getInObj(['__meta', 'truncate'], post);

  return shouldTruncate === null ? shouldTruncateEscapedText(post.body, TRUNCATE_LIMIT) : { limit: TRUNCATE_LIMIT, truncate: shouldTruncate };
};

const PostCardBody = ({ basePath, categoryConfig, markdownService, onCategoryTagClick, post }) => {
  const embed = getInObj(['entities', 'embed'], post);
  const config = categoryConfig.find((c) => c.enum === post.category);

  return (
    <div>
      <div className={`${typography.bodyM} ${typography.breakWord} ${layout.marginBottom15}`}>
        <MarkdownViewer
          content={_cleanEmbedLinkFromBody(post.body, embed)}
          markdownService={markdownService}
          truncateHtml={getShouldTruncate(post)}
          viewMoreClicked={() => fireViewMoreClickedAnalytics(post)}
        />

        {embed && <PostEmbed embed={embed} />}
      </div>

      {config
      && (
        <div className={layout.marginBottom15}>
          <CategoryBadge basePath={basePath} categoryConfig={config} onCategoryTagClick={onCategoryTagClick} />
        </div>
      )}

      <RespectButton
        id={post.id}
        respects={post.respects_count}
        source="feed_post"
        theme="postCard"
        type={FEED_POST}
      />
    </div>
  );
};

PostCardBody.propTypes = {
  basePath: PropTypes.string.isRequired,
  categoryConfig: PropTypes.arrayOf(PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  markdownService: PropTypes.object.isRequired,
  onCategoryTagClick: PropTypes.func.isRequired,
  post: PropTypes.shape({
    __meta: PropTypes.shape({ // Added to records when creating or updating in the root app.
      truncate: PropTypes.bool,
    }),
    body: PropTypes.string,
    category: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    respects_count: PropTypes.number.isRequired,
  }).isRequired,
};

PostCardBody.defaultProps = {};

export default PostCardBody;
