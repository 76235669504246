import React from 'react';
import PropTypes from 'prop-types';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';

const NotFoundPage = ({ action, msg, viewRenderer }) => {
  if (typeof viewRenderer === 'function') return viewRenderer();

  return (
    <div className={layout.container}>
      <div className={layout.wrapper1170}>
        <p className={typography.bodyM}>
          {msg}
          {action && <a className={typography.linkBlue} href={action.href} onClick={action.onClick}>{action.text}</a>}
        </p>
      </div>
    </div>
  );
};

NotFoundPage.propTypes = {
  action: PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
  msg: PropTypes.string,
  viewRenderer: PropTypes.func,
};

NotFoundPage.defaultProps = {
  action: {
    href: '/',
    onClick: null,
    text: 'Click for the home page.',
  },
  msg: 'Sorry, we couldn\'t find what you\'re looking for. ',
};

export default NotFoundPage;
