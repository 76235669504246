// memoized flag
let _isTouchDevice = null;

export default function isTouchDevice() {
  if (_isTouchDevice === null) {
    _isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
  }

  return _isTouchDevice;
}
