import BaseAlgoliaService from '../base_service';
import queryBuilder from './query_builder';

import { getDependency, hasDependency } from '../lazy_filters';
import { reflectPromise } from '../../../utility/promises';

export default class AlgoliaProjectsService extends BaseAlgoliaService {
  constructor(history = {}) {
    super(history, 'projects', queryBuilder);
  }

  // FilterDeps: [{user: part_ids}].
  // Returns: {user: 'user'} if request is successful.
  initializeLazyFiltersDependencies(filterDeps) {
    return new Promise((resolve, reject) => {
      const dependencies = this._getDependencies(filterDeps);

      if (!dependencies.promises.length) return resolve({});

      Promise.all(dependencies.promises.map(reflectPromise))
        .then((results) => {
          resolve(
            results.reduce((acc, result, i) => {
              if (result.status === 'resolved' && result.data) {
                const dep = dependencies['map'][i];
                acc[dep] = dep;
              }

              return acc;
            }, {}),
          );
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * Creates a list of promises and a object map, which maps the index of the promises to their resource.
   * This is so that we can use reflectPromise where unresolved promises are ignored.
   * Note: There is a bug here if there are multiple filterDeps using the same key ([{user: 'part_ids'}, {user: '?'}]).
   *       If we need to host multiples of the same key later, use a compound_key instead.
   * map: {indexOfPromise: 'user'}
   */
  _getDependencies(filterDeps) {
    return filterDeps.reduce((acc, dep) => {
      if (hasDependency(dep)) {
        const key = Object.keys(dep)[0];
        const mapIndex = acc.promises.length;
        acc.promises.push(getDependency(dep)());
        acc.map[mapIndex] = key;
      }

      return acc;
    }, { promises: [], map: {} });
  }
}
