import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PaginatedList from '../paginated_list';

import { canUseDOM } from '../../../utility/execEnvironment';
import { getInObj } from '../../../utility/accessors';
import { doesObjectPropsMatch, doesValuesMatch } from '../../../utility/predicates';
import { windowScrollTo } from '../../../services/window';

import errorHandler from '../../../services/error_handler';
import smoothScroll from '../../utils/smoothScroll.js';

class GraphQLPaginatedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQuery: {
        metadata: {
          // Besides current_page, total_pages will be updated only via default pagination query, while next_page & prev_page only via simple pagination (ex. platform members)
          current_page: 0,
          next_page: null,
          prev_page: null,
          total_pages: 0,
        },
        records: [],
      },
      filters: props.filters,
      initialized: false,
      isLoading: true,
    };

    this.onFilterSelect = this.onFilterSelect.bind(this);
    this.onPaginatorClick = this.onPaginatorClick.bind(this);
    this._isMounted;
  }

  componentDidMount() {
    this._isMounted = true;

    if (canUseDOM()) {
      if (this.props.getPaginatorHook) this.props.getPaginatorHook(this.__onPaginatorHook.bind(this));
      this.props.reportPageView();
      this._initializeFromUrl();
      this._readyUIFilterState();
    }
  }

  // TODO: change to componentDidUpdate and check timing/race conditions
  UNSAFE_componentWillUpdate(nextProps) {
    this._onHistoryChange(nextProps);
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.props.getRequestPageHook) {
      this.props.getRequestPageHook(undefined);
    }
  }

  /**
   * Hooks
   */
  __onPaginatorHook(page) {
    return this.onPaginatorClick(page);
  }

  /**
   * Initializers
   */
  _initializeFromUrl(shouldPushToHistory = true) {
    return this.props.graphQLService.initializeFromUrl(this.props.graphQLArguments, shouldPushToHistory)
      .then((currentQuery) => {
        this._safelySetState(() => this.setState({ currentQuery, initialized: true, isLoading: false }));
        this.props.propagateQuery(currentQuery);
      })
      .catch((err) => errorHandler('GraphQLPaginatedList _initializeFromUrl', err));
  }

  _readyUIFilterState() {
    if (!this.state.filters) return;

    return this.props.graphQLService.mapifySearchQuery()
      .then((map) => this._getUpdatedFilterState(map))
      .then((filters) => this._safelySetState(() => this.setState({ filters })))
      .catch((err) => errorHandler('_readyUIFilterState', err));
  }

  _getUpdatedFilterState(filterMap) {
    return new Promise((resolve, reject) => {
      const filterMapKeys = Object.keys(filterMap);

      // NOTE: If this filter.type goes beyond normal filters and search, add the ability for an filter to
      // have its own updating function that we can call here. Then we can map the types to particular resolver methods
      // to bake it the current behaviors.
      const updatedFilters = this.state.filters.map((filter) => (
        filter.type === 'search'
          ? this._getUpdatedSearchFilter(filterMap, filterMapKeys, filter)
          : this._getUpdatedOptionsFilter(filterMap, filterMapKeys, filter)
      ));

      resolve(updatedFilters);
    });
  }

  _getUpdatedOptionsFilter(filterMap, filterMapKeys, filter) {
    if (filter.key && filterMapKeys.includes(filter.key)) {
      return { ...filter, options: this._updateFilterOptions(filter.options, filterMap[filter['key']]) };
    } else {
      return { ...filter, options: this._resetFilterToDefaultOptions(filter) };
    }
  }

  _getUpdatedSearchFilter(filterMap, filterMapKeys, filter) {
    if (filter.key && filterMapKeys.includes(filter.key)) {
      return { ...filter, value: filterMap[filter.key] };
    } else {
      return { ...filter, value: filter.default };
    }
  }

  _updateFilterOptions(options, newDefaultValue) {
    return options.reduce((acc, option) => {
      if (option.active && !doesValuesMatch(option.value, newDefaultValue)) {
        acc.push({ ...option, active: false });
      } else if (doesValuesMatch(option.value, newDefaultValue)) {
        acc.push({ ...option, active: true });
      } else {
        acc.push(option);
      }

      return acc;
    }, []);
  }

  _resetFilterToDefaultOptions(filter) {
    const defaultFilter = this.props.filters.filter((f) => f.key === filter.key)[0];

    return defaultFilter.options.map((option, i) => {
      if (option.default) {
        option.active = true;
      } else if (option.active) {
        option.active = false;
      }

      return option;
    });
  }

  /**
    * Methods
    */
  onFilterSelect(optionWithQuery) {
    this.setState({ isLoading: true });
    this.props.reportPageView();

    return this.props.graphQLService.searchWithFilterString(optionWithQuery.queryString, this.props.graphQLArguments, true)
      .then((currentQuery) => {
        this._safelySetState(() => this.setState({ currentQuery, isLoading: false }));
        this.props.propagateQuery(currentQuery);
        // Resets all filters from the queryParams.
        this._readyUIFilterState();
      })
      .catch((err) => this._safelySetState(() => this.setState({ isLoading: false }, () => errorHandler('onFilterSelect', err))));
  }

  onPaginatorClick(page) {
    this.setState({ isLoading: true });
    this.props.reportPageView();

    return this.props.graphQLService.searchWithFilterString(`page=${page}`, this.props.graphQLArguments)
      .then((currentQuery) => {
        this._safelySetState(() => this.setState({ currentQuery, isLoading: false }, () => this._scrollTo()));
        this.props.propagateQuery(currentQuery);
      })
      .catch((err) => this._safelySetState(() => this.setState({ isLoading: false }, () => errorHandler('onPaginatorClick', err))));
  }

  /**
   * Helpers
   */

  /**
    * When using browser navigation, we must reset app state entirely.
    * If the action prop is "POP", it was triggered using native browser navigation, we need to update state entirely.
    * However, we do not want to push state history, else we will lose the ability to go back and forth since it would overwrite itself.
    */
  _shouldRefetchOnHistoryChange(nextProps) {
    return nextProps.currentHistoryData && this.props.currentHistoryData
      && getInObj(['currentHistoryData', 'action'], nextProps) === 'POP'
      && !doesObjectPropsMatch(nextProps.currentHistoryData, this.props.currentHistoryData, 'search')
      && this.props.validateRefetch(this.props, nextProps);
  }

  _onHistoryChange(nextProps) {
    if (this._shouldRefetchOnHistoryChange(nextProps)) {
      this.props.reportPageView();
      this.setState({ isLoading: true });
      this._initializeFromUrl(false);
      this._readyUIFilterState();
    }
  }

  _safelySetState(fn = () => {}) {
    if (this._isMounted) fn();
  }

  _scrollTo() {
    if (this.props.scrollId) {
      const node = document.getElementById(this.props.scrollId);
      if (node) smoothScroll(node);
    } else {
      windowScrollTo(0, 0);
    }
  }

  /**
   * Views
   */
  _getListView() {
    return this.state.initialized ? this._renderListView() : this._renderLoaderView();
  }

  _renderFilters() {
    return this.state.initialized ? this.state.filters : null;
  }

  _renderListView() {
    return React.createElement(this.props.listComponent, {
      ...this.props.listProps,
      isLoading: this.state.isLoading,
      records: this.state.currentQuery.records,
      transition: this.props.transition,
    });
  }

  _renderLoaderView() {
    if (this.props.loaderComponent === null) return null;

    return React.createElement(this.props.loaderComponent, this.props.listProps);
  }

  render() {
    return (
      <PaginatedList
        filters={this._renderFilters()}
        onFilterSelect={this.onFilterSelect}
        onPaginatorClick={this.onPaginatorClick}
        paginator={{
          currentPage: this.state.currentQuery.metadata.current_page,
          nextPage: this.state.currentQuery.metadata.next_page,
          prevPage: this.state.currentQuery.metadata.prev_page,
          totalPages: this.state.currentQuery.metadata.total_pages,
        }}
        renderFilters={this.props.renderFilters}
      >
        {this._getListView()}
      </PaginatedList>
    );
  }
}

GraphQLPaginatedList.propTypes = {
  currentHistoryData: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  getPaginatorHook: PropTypes.func,
  graphQLArguments: PropTypes.object,
  graphQLService: PropTypes.object.isRequired,
  listComponent: PropTypes.func.isRequired,
  listProps: PropTypes.object,
  loaderComponent: PropTypes.func,
  propagateQuery: PropTypes.func,
  renderFilters: PropTypes.func, // Escape hatch to render custom filters. Receives args, props.filters && this.onFilterSelect.
  reportPageView: PropTypes.func,
  scrollId: PropTypes.string,
  transition: PropTypes.func,
  validateRefetch: PropTypes.func,
};

GraphQLPaginatedList.defaultProps = {
  currentHistoryData: null,
  filters: null,
  getPaginatorHook: null,
  graphQLArguments: {},
  listProps: {},
  loaderComponent: null,
  propagateQuery: () => {},
  renderFilters: null,
  reportPageView: () => {},
  scrollId: null,
  transition: () => {},
  validateRefetch: () => true,
};

export default GraphQLPaginatedList;
