import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styles from './messenger.css';
import transitions from './transitions.css';

const EVENT_NAME = 'react:messenger';

export default class GlobalMessenger extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initMsg: (props.initMsg || 'Changes saved!'),
      msg: 'Changes saved!',
      show: false,
      type: 'success',
    };

    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleExitedState = this.handleExitedState.bind(this);
    this.handleShow = this.handleShow.bind(this);

    this.timeout;
  }

  componentDidMount() {
    window.addEventListener(EVENT_NAME, this.handleShow);
  }

  componentWillUnmount() {
    this._clearTimeout();
    window.removeEventListener(EVENT_NAME, this.handleShow);
  }

  handleDismiss() {
    this._clearTimeout();
    this.setState({ show: false });
  }

  handleExitedState() {
    if (this.state.msg !== this.state.initMsg || this.state.type !== 'success') {
      this.setState({ msg: this.state.initMsg, type: 'success' });
    }
  }

  handleShow(e) {
    this.setState({ ...e.detail, show: true });

    this._clearTimeout();

    this.timeout = setTimeout(() => {
      this.handleDismiss();
      this.timeout = undefined;
    }, 3000);
  }

  _clearTimeout() {
    if (typeof this.timeout === 'number') window.clearTimeout(this.timeout);
  }

  render() {
    return (
      <CSSTransition
        classNames={transitions}
        in={this.state.show}
        onExited={this.handleExitedState}
        timeout={{
          enter: 500,
          exit: 300,
        }}
        unmountOnExit
      >
        <div className={styles.root}>
          <div className={styles[this.state.type]}>
            <div className={styles.body}>
              <span>{this.state.msg}</span>
              <button className={styles.closeBtn} onClick={this.handleDismiss}>×</button>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

GlobalMessenger.propTypes = { initMsg: PropTypes.string };
