import {isEmail, isURL} from 'validator';

export const INVALID_URL_MSG = 'Sorry, that\'s not a valid url.';

export function appendProtocolToUrlType(url, type) {
  if (type === 'email') {
    return doesEmailHaveProtocol(url) ? url : `mailto:${url}`;
  } else if (type === 'url') {
    return doesUrlHaveProtocol(url) ? url : `http://${url}`;
  }
  return url;
}

export function doesEmailHaveProtocol(email) {
  return email.indexOf('mailto:') !== -1;
}

export function doesUrlHaveProtocol(url) {
  return isURL(url, { protocols: ['http', 'https'], require_protocol: true });
}

export function isAnchor(url) {
  return url.slice(0, 1) === '#' && url.indexOf(' ') === -1;
}

export function isUrlAEmail(url) {
  const email = doesEmailHaveProtocol(url) ? url.replace(/mailto:/g, '') : url;
  return isEmail(email);
}

export function isUrlValid(url, protocol=false) {
  // Disallow qutoes for XSS protection.
  if ((url.includes('"') || url.includes("'"))) return false;
  return protocol ? isURL(url, { protocol: true }) : isURL(url);
}

// NOTE: Make sure isUrlAEmail comes beore isUrlValid.
export function isValidLinkable(url, protocol=false) {
  if (isUrlAEmail(url)) {
    return {isValid: true, type: 'email'};
  } else if (isUrlValid(url, protocol)) {
    return {isValid: true, type: 'url'};
  } else if (isAnchor(url)) {
    return {isValid: true, type: 'anchor'};
  } else {
    return {isValid: false, type: null};
  }
}
