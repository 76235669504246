import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier, SelectionState } from 'draft-js';

import DropdownList from '../DropdownList';
import getAlgoliaIndex from '../../../../../../services/algolia/getAlgoliaIndex';

import errorHandler from '../../../../../../services/error_handler';

import styles from './mention.css';

const userCardTemplate = (user) => (
  <span className={styles.userCard}>
    <img className={styles.userAvatar} src={user.avatar_url} />
    <span className={styles.userNames}>
      <strong>{user.name}</strong>
      <span>{` @${user.user_name}`}</span>
    </span>
  </span>
);

class Mention extends Component {
  constructor(props) {
    super(props);

    this.stuntEventInEditor = this.stuntEventInEditor.bind(this);
    this.updateEntity = this.updateEntity.bind(this);

    this.state = {
      queryCache: {},
      users: [],
    };

    // Kinda dirty, think of a better way.  Its to dismiss the dropdown after a selection.
    this._updatedBySelection = false;
  }

  componentDidMount() {
    // The init parser doesn't catch mentions that aren't mention tokens, so anything @name is ignored there.  We
    // create those entities on the fly here.
    if (this.props.entityKey === null) this._createEntity();
    // Fire _search on the first letter after @.
    if (this._isCursorInCurrentBlock() && this.props.editorCtx.state.editorState.getLastChangeType() !== 'insert-fragment') {
      this._search(this.props.decoratedText);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.decoratedText !== this.props.decoratedText && this._isCursorInCurrentBlock() && this._updatedBySelection === false) {
      this._search(this.props.decoratedText);
    }

    if (this._updatedBySelection) {
      this._updatedBySelection = false;
    }
  }

  _createEntity() {
    const contentState = this.props.contentState;
    const entifiedContentState = contentState.createEntity(
      'MENTION',
      'MUTABLE',
      {},
    );

    const start = this.props.children[0].props.start;
    const ranges = { start, end: start + this.props.decoratedText.length };
    const selectionState = SelectionState.createEmpty(this.props.children[0].props.block.get('key'));
    const updatedSelectionState = selectionState.merge({
      anchorOffset: ranges.start,
      focusOffset: ranges.end,
    });

    const updatedCS = Modifier.applyEntity(entifiedContentState, updatedSelectionState, entifiedContentState.getLastCreatedEntityKey());

    this.props.editorCtx.updateEditorState(
      EditorState.acceptSelection(
        EditorState.push(this.props.editorCtx.state.editorState, updatedCS, 'apply-entity'),
        this.props.editorCtx.state.editorState.getSelection(),
      ),
    );
  }

  _isCursorInCurrentBlock() {
    const blockKey = this.props.children[0].props.block.get('key');

    return this.props.contentState.getSelectionAfter().getAnchorKey() === blockKey;
  }

  _search(query) {
    return new Promise((resolve, reject) => {
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
      /* eslint-disable-next-line no-prototype-builtins */
      if (this.state.queryCache.hasOwnProperty(query)) {
        return this.setState({ users: this.state.queryCache[query] });
      }

      return getAlgoliaIndex('users').search(query, { hitsPerPage: 10 })
        .then((res) => this.setState({
          queryCache: { ...this.state.queryCache, [query]: res.hits },
          users: res.hits,
        }))
        .catch((err) => errorHandler('Mention _search', err));
    });
  }

  stuntEventInEditor(event) {
    if (this.props.editorCtx._stuntedEvent !== event) {
      this.props.editorCtx.__setStuntedEvent(event);
    }
  }

  updateEntity(user) {
    this._replaceText(user.user_name, () => {
      // TODO: Think of a better way than setting the below flag.
      this._updatedBySelection = true;
      this.setState({ users: [] });
    });
  }

  _replaceText(userName, callback) {
    const key = this.props.offsetKey.split('-')[0];
    const block = this.props.contentState.getBlockForKey(key);
    const ranges = {};

    block.findEntityRanges((char) => char.getEntity() === this.props.entityKey, (start, end) => {
      ranges.start = start;
      ranges.end = end;
    });

    const selectionState = this.props.contentState.getSelectionAfter().merge({
      anchorOffset: ranges.start,
      focusOffset: ranges.end,
    });

    const updatedContentState = Modifier.replaceText(
      this.props.contentState,
      selectionState,
      `@${userName}`,
      null,
      this.props.entityKey,
    );

    const updatedEditorState = EditorState.forceSelection(
      EditorState.set(this.props.editorCtx.state.editorState, { currentContent: updatedContentState }),
      selectionState.merge({
        anchorOffset: ranges.start + userName.length + 1,
        focusOffset: ranges.start + userName.length + 1,
      }),
    );

    this.props.editorCtx.updateEditorState(updatedEditorState);

    return callback();
  }

  _showDropdown() {
    return this.state.users.length > 0 && this._isCurrentEntity();
  }

  _isCurrentEntity() {
    if (this.props.entityKey === null) return false;

    return this.props.editorCtx.state.currentEntity === this.props.entityKey;
  }

  render() {
    return (
      <span
        ref={(el) => this.root = el}
        className={styles.root}
        data-offset-key={this.props.offsetKey}
      >
        {this.props.children}
        {this._showDropdown()
        && (
          <div className={styles.dropdownContainer} contentEditable={false}>
            <DropdownList
              currentQuery={this.props.decoratedText}
              dismiss={() => this.setState({ users: [] })}
              entityKey={this.props.entityKey}
              onSelect={this.updateEntity}
              options={this.state.users}
              stuntReturn={this.stuntEventInEditor}
              templateFn={userCardTemplate}
            />
          </div>
        )}
      </span>
    );
  }
}

Mention.propTypes = {
  contentState: PropTypes.object.isRequired,
  decoratedText: PropTypes.string.isRequired,
  entityKey: PropTypes.string,
  offsetKey: PropTypes.string.isRequired,
};

Mention.defaultProps = { entityKey: null };

export default Mention;
