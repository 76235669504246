/**
 * Filter functions
 */
const looseEquality = (opt, val) => (opt.label.toLowerCase().trim().indexOf(val) >= 0);
const strictEquality = (opt, val) => (opt.label.toLowerCase().trim().slice(0, val.length) === val);
const absoluteEquality = (opt, val) => (opt.label.toLowerCase().trim() === val);

const getFilterFn = (rule) => {
  switch (rule) {
    case 'absolute':
      return absoluteEquality;

    case 'strict':
      return strictEquality;

    default:
      return looseEquality;
  }
};

/**
 * We can use a package like https://www.npmjs.com/package/fuse.js to increase search results later.
 */
export default function filterOptions({ options = [], value = '', opts = {} } = {}) {
  if (value === '' || !options.length || (opts.rule && opts.rule === 'norule')) return options;

  const rule = opts.rule || 'default';
  const filterFn = getFilterFn(rule);
  const val = value.toLowerCase().trim();

  return options.filter((opt) => {
    const hasLabel = (opt.label && typeof opt.label === 'string' && opt.label.length > 0);

    if (!hasLabel) return false;

    return filterFn(opt, val);
  });
}
