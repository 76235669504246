import {EditorState, Modifier, RichUtils} from 'draft-js';
import {isURL} from 'validator';

export function deletePreviousWord(editorState) {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const block = contentState.getBlockForKey(anchorKey);

  const anchorOffset = selection.getAnchorOffset();
  const prevWord = block.getText().slice(0, anchorOffset).split(' ').pop();
  const startOfWordOffset = anchorOffset - prevWord.length;

  const wrappedSelection = selection.merge({
    anchorOffset: startOfWordOffset,
    focusOffset: anchorOffset
  });
  const updatedSelection = selection.merge({
    anchorOffset: startOfWordOffset,
    focusOffset: startOfWordOffset
  });
  const updatedContentState = Modifier.removeRange(contentState, wrappedSelection, 'backward');

  return EditorState.forceSelection(
    EditorState.push(editorState, updatedContentState, 'remove-range'),
    updatedSelection
  );
}

export function forceSelection(editorState) {
  return EditorState.forceSelection(editorState, editorState.getSelection());
}

export function insertEmptySpace(editorState, space=' ') {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const updatedContentState = Modifier.insertText(contentState, selection, space, null, null);
  const offset = selection.getAnchorOffset() + space.length;
  const updatedSelection = selection.merge({
    anchorOffset: offset,
    focusOffset: offset
  });

  return EditorState.forceSelection(
    EditorState.push(editorState, updatedContentState, 'insert-characters'),
    updatedSelection
  );
}

export function linkifyPreviousWord(editorState) {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const block = contentState.getBlockForKey(anchorKey);

  const anchorOffset = selection.getAnchorOffset();
  const prevWord = block.getText().slice(0, anchorOffset).split(' ').pop();
  const prevWordWithProtocol = isURL(prevWord, {require_protocol: true}) ? prevWord : `http://${prevWord}`;

  const wrappedSelection = selection.merge({
    anchorOffset: anchorOffset - prevWord.length,
    focusOffset: anchorOffset
  });

  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {href: prevWordWithProtocol});
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const updatedContentState = Modifier.applyEntity(contentStateWithEntity, wrappedSelection, entityKey);

  return EditorState.acceptSelection(
    EditorState.push(editorState, updatedContentState, 'apply-entity'),
    selection
  );
}

// export function toggleCurrentStyleForCharacter(editorState, style, applyStyle) {
//   if (!editorState.getSelection().isCollapsed()) return forceSelection(editorState);

//   const contentState = editorState.getCurrentContent();
//   const selection = editorState.getSelection();
//   const anchorKey = selection.getAnchorKey();
//   const block = contentState.getBlockForKey(anchorKey);
//   const chars = block.getCharacterList();
//   const startOffset = Math.max(selection.getStartOffset(), 0);
//   const currentChar = chars.get(startOffset);

//   if (!currentChar) return forceSelection(editorState);

//   const method = applyStyle ? 'applyStyle' : 'removeStyle';
//   const updatedChar = CharacterMetadata[method](currentChar, style);
//   const updatedChars = chars.set(startOffset, updatedChar);
//   const updatedBlock = block.set('characterList', updatedChars);
//   const updatedBlockMap = contentState.getBlockMap().set(updatedBlock.getKey(), updatedBlock);
//   const updatedContent = contentState.set('blockMap', updatedBlockMap);

//   return EditorState.forceSelection(
//     EditorState.push(editorState, updatedContent, 'change-inline-style'),
//     selection
//   );
// }

export function toggleInlineStyle(editorState, style) {
  const selection = editorState.getSelection();

  return EditorState.forceSelection(
    RichUtils.toggleInlineStyle(editorState, style),
    selection.set('hasFocus', true)
  );
}