import request from 'superagent';
import OAuth from '../../services/oauth';

export function getOptions(query) {
  return OAuth.apiRequest(
    request
      .get(`${OAuth.getApiPath()}/private/groups`)
      .query(query),
    false,
    false,
  )
    .then((res) => Promise.resolve(res.body))
    .catch((err) => Promise.reject(err));
}

export function postForm(form, endpoint) {
  const newForm = Object.assign({}, form);
  newForm[newForm.model_key] = newForm.model_data;
  delete newForm.model_key;
  delete newForm.model_data;

  return OAuth.apiRequest(
    request
      .post(endpoint)
      .send(newForm),
    true,
  );
}
