import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { isBlank } from '../../../utility/types';

import Button from '../../buttons/base';
import StickyWrapper from '../../wrappers/sticky_wrapper/index.js';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './local_storage_popover.css';

const STICKY_WRAPPER_ID = 'localStoragePopoverWrapper';

// When a new key is added to props to reshow the popover, track previously used keys here and uncomment cleanupOldLS call (line 43). Clear keys when a campaign is over.
// const OLD_KEYS = [];
// const cleanupOldLS = () => {
//   OLD_KEYS.forEach(key => {
//     if (!isBlank(window.localStorage.getItem(key))) {
//       window.localStorage.removeItem(key);
//     }
//   });
// };

const MainSiteContentWrapper = ({ children }) => (
  <div className={`${layout.flexJustifyCenter}`} id={STICKY_WRAPPER_ID}>
    <div className={`${styles.flexLargeRightSmallCenter} ${layout.wrapper1170} ${utilStyles.posRelative}`}>
      <div className={`${styles.flexLargeRightSmallCenter} ${utilStyles.posAbsolute}`}>
        {children}
      </div>
    </div>
  </div>
);

const LocalStoragePopover = ({ buttonText, ctaLink, mainText, storageKey }) => {
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    const localStorageRes = window.localStorage.getItem(storageKey);
    if (isBlank(localStorageRes)) setShowPopover(true);

    // Uncomment the below call after adding to OLD_KEYS.
    // cleanupOldLS();

  // Note: Disabled for onMount / no actual deps.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    window.localStorage.setItem(storageKey, '1');
    setShowPopover(false);
  };

  return (
    <div>
      {showPopover
      && (
        <MainSiteContentWrapper>
          <StickyWrapper
            className={`${styles.container} ${layout.padding15} ${utilStyles.borderRadiusLarge} ${utilStyles.boxShadow2}`}
            parentId={STICKY_WRAPPER_ID}
            startPos="top"
          >
            <div className={layout.paddingBottom22}>
              <h4 className={typography.h4}>{mainText.primary}</h4>
              <p className={typography.bodyM}>{mainText.secondary}</p>
            </div>

            <div>
              <a href={ctaLink} rel="noopener noreferrer" target="_blank" title="ctaLink">
                <Button onClick={handleButtonClick}>{buttonText.primary}</Button>
              </a>
              <Button className={layout.paddingLeft15} colorStyle="blank" onClick={handleButtonClick}>{buttonText.secondary}</Button>
            </div>
          </StickyWrapper>
        </MainSiteContentWrapper>
      )}
    </div>
  );
};

export default LocalStoragePopover;

LocalStoragePopover.propTypes = {
  buttonText: PropTypes.shape({
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
  }).isRequired,
  ctaLink: PropTypes.string.isRequired,
  mainText: PropTypes.shape({
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
  }).isRequired,
  storageKey: PropTypes.string.isRequired,
};
