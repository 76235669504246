/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { graphQuery } from '../../../requests/graphql';
import { mapifySearchQuery, stringifyQueryMap } from '../../algolia/converters';
import { windowLocationSearch } from '../../window';

export default class GraphQLBaseService {
  constructor({ history, queryBuilder, queryString, resolverKey } = {}) {
    this.graphQLQueryString = queryString;
    this.history = history;
    this.queryBuilder = queryBuilder;
    this.queryHistory = { currentQuery: '', nextQuery: '' };
    this.mapifySearchQuery = mapifySearchQuery;
    this.resolverKey = resolverKey || null;
    this.stringifyQueryMap = stringifyQueryMap;
  }

  /**
   * Initializers
   */
  initializeFromUrl(initArgs) {
    return new Promise((resolve, reject) => this.mapifySearchQuery()
      .then((map) => this.buildAndStoreNextHistory(map))
      .then((map) => this.queryBuilder({ ...initArgs, ...map }))
      .then((params) => this.search(this.graphQLQueryString(), { ...initArgs, ...params }))
      .then((currentQuery) => resolve(currentQuery))
      .catch((err) => reject(err)));
  }

  /**
   * History
   */
  buildAndStoreNextHistory(queryMap) {
    return new Promise((resolve, reject) => this.stringifyQueryMap(queryMap)
      .then((queryString) => {
        this.queryHistory.nextQuery = queryString;
        resolve(queryMap);
      })
      .catch((err) => reject(err)));
  }

  setCurrentHistoryAndClearNext(response) {
    const currentPath = windowLocationSearch.get();
    const query = currentPath.split('?')[1] || '';

    if ((query !== this.queryHistory.nextQuery)
      && (this.history.location.search !== this.queryHistory.nextQuery)) {
      this.queryHistory.currentQuery = this.queryHistory.nextQuery;
      this.queryHistory.nextQuery = '';
      this.history.push({ search: this.queryHistory.currentQuery });
    }

    return Promise.resolve(response);
  }

  /**
   * Search Methods
   */
  search(query, params) {
    return new Promise((resolve, reject) => graphQuery(query, params)
      .then((res) => this.setCurrentHistoryAndClearNext(this._resolveQLResponse(res)))
      .then((res) => resolve(res))
      .catch((err) => reject(err)));
  }

  // Digs into a QL request to dynamically grab a nested records bucket. Expects the first item in the response keys to be the correct
  // key. Otherwise use the resolverKey when constructing the instance.
  _resolveQLResponse(response, loop = 0) {
    if (this.resolverKey && response.hasOwnProperty(this.resolverKey)) return response[this.resolverKey];
    // Limit heap to 5 nested objects.
    if (loop >= 5) return response;
    if (response.hasOwnProperty('records')) return response;
    const key = Object.keys(response)[0];

    return this._resolveQLResponse(response[key], loop += 1);
  }

  searchWithFilterString(filterString, initArgs, removePage = false) {
    return new Promise((resolve, reject) => this.mapifySearchQuery()
      .then((map) => this._appendFilterStringToQueryMap(map, filterString, removePage))
      .then((updatedMap) => this.buildAndStoreNextHistory(updatedMap))
      .then((updatedMap) => this.queryBuilder({ ...initArgs, ...updatedMap }))
      .then((params) => this.search(this.graphQLQueryString(), { ...initArgs, ...params }))
      .then((currentQuery) => resolve(currentQuery))
      .catch((err) => reject(err)));
  }

  _appendFilterStringToQueryMap(queryMap, filterString, removePage) {
    const filter = filterString.split('=');

    // Filter will be null if its the default, we dont want to do anything with a null value, so remove it!
    if (filter[1] === 'null' && queryMap.hasOwnProperty(filter[0])) {
      delete queryMap[filter[0]];
    } else if ((filter[0] === 'page' && filter[1] === '1' && queryMap.hasOwnProperty('page'))) {
      delete queryMap.page;
    } else if (filter[1] !== 'null') {
      queryMap[filter[0]] = filter[1];
    }
    // removePage if a new filter is chosen.
    if (removePage && queryMap.page) {
      delete queryMap.page;
    }

    return Promise.resolve(queryMap);
  }
}
