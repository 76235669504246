import React, { Component } from 'react';
import PropTypes from 'prop-types';

import VideoInfo from '../video_info';
import VideoViewer from '../video_viewer';
import PushStateDialog from '../../wrappers/push_state_dialog';

import createImpression from '../../../requests/createImpression';
import seoConfig from '../../../scenes/videos_page/seoConfig';
import { createSimpleHistory } from '../../reusable_components/Router/history';

import layoutStyles from '../../../styles/global_ui/layout.css';
import styles from './video_dialog.css';

const DialogBody = ({ entity }) => (
  <div className={`${layoutStyles.container} ${styles.container}`}>
    <div className={layoutStyles.wrapper960}>
      {entity
      && (
        <div>
          <VideoViewer video={entity} />
          <VideoInfo inDialog={true} video={entity} />
        </div>
      )}
    </div>
  </div>
);

class VideoDialog extends Component {
  constructor(props) {
    super(props);

    this.buildPath = this.buildPath.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleOpen = this.handleOpen.bind(this);

    this.history = createSimpleHistory(this.props.pathHelpers.rootPath);

    // ref
    this._dialog;
  }

  // proxy method to be called by parent
  _summonDialog(video) {
    if (this._dialog) this._dialog._summonDialog(video);
  }

  buildPath(video) {
    return `${this.props.pathHelpers.videosPath}/${video.id}`;
  }

  handleDismiss(fromPopAction) {
    this.props.onDismiss();
  }

  handleOpen(fromPopAction, video) {
    this.props.seoHandler.reportView({
      config: seoConfig,
      data: video,
      path: '/*',
    });

    // create impression for popularity count
    createImpression({
      type: 'Video',
      id: video.id,
      a: 'modal',
      c: 'videos',
    });
  }

  render() {
    return (
      <PushStateDialog
        ref={(c) => this._dialog = c}
        BodyComponent={DialogBody}
        buildPath={this.buildPath}
        fullScreen={true}
        history={this.history}
        onDismiss={this.handleDismiss}
        onOpen={this.handleOpen}
        theme="dark"
        wrapperClassName={styles.dialogWrapper}
      />
    );
  }
}

VideoDialog.propTypes = {
  onDismiss: PropTypes.func,
  pathHelpers: PropTypes.shape({
    rootPath: PropTypes.string.isRequired,
    videosPath: PropTypes.string.isRequired,
  }).isRequired,
  seoHandler: PropTypes.shape({ reportView: PropTypes.func.isRequired }).isRequired,
};

VideoDialog.defaultProps = { onDismiss: () => {} };

export default VideoDialog;
