import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { windowPageYOffset } from '../../../services/window';

class ClientRectReporter extends PureComponent {
  constructor(props) {
    super(props);
    this._root;
  }

  componentDidMount() {
    this._reportDims();
  }

  _reportDims() {
    if (this._root) {
      const dims = this._root.getBoundingClientRect();

      this.props.reportDims({
        dims,
        offset: dims.top + windowPageYOffset(),
      });
    }
  }

  render() {
    return (
      <div ref={(el) => this._root = el}>
        {this.props.children}
      </div>
    );
  }
}

ClientRectReporter.propTypes = { reportDims: PropTypes.func.isRequired };

export default ClientRectReporter;
