import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Badge from '../../reusable_components/Badge';
import RepBadge from '../templates/RepBadge';
import Icon from '../../icon';
import OptionsMenuWithIcon from '../templates/OptionsMenuWithIcon';

import { timestampToPrettyDate, timestampToRelativeTime } from '../../../utility/time';

import layout from '../../../styles/global_ui/layout.css';
import menuStyles from '../../form_components/simple_select/simple_select.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './post_card.css';

const PinnedLabel = () => (
  <div className={layout.flex}>
    <Icon className={`${typography.hackster} ${layout.marginRight10}`} name="pin" size="16" />
    <h5 className={`${layout.marginBottom15} ${typography.h5} ${typography.hackster}`}>Pinned post</h5>
  </div>
);

const AdminMenuOpts = ({ isPinned, onPinClick }) => (
  <Fragment>
    <li
      className={menuStyles.option}
      onClick={() => onPinClick()}
    >
      {isPinned ? 'Unpin post' : 'Pin post'}
    </li>
  </Fragment>
);

const PostCardHeader = ({
  basePath,
  currentUser,
  isFlagged,
  isPinned,
  onCopyLinkClick,
  onCreatedAtClick,
  onDeleteClick,
  onEditClick,
  onPinClick,
  onReportClick,
  onSpamClick,
  openMenu,
  origin,
  post,
  toggleMenu,
}) => (
  <div className={`${layout.flexJustifySpaceBetween} ${layout.marginBottom10}`}>
    <div>
      {isPinned && <PinnedLabel />}

      <div className={layout.flex}>
        <a className={layout.marginRight10} href={post.user.url}>
          <img className={styles.avatar} src={post.user.avatar_url} />
        </a>

        <div className={layout.flexColumnWrap}>
          <div
            className={`${layout.flexCenterItems} ${layout.inlineFlex} ${layout.gutter5}`}
          >
            <a
              className={`${typography.bodyM} ${typography.bold} ${typography.linkCharcoal}`}
              href={post.user.url}
            >
              {post.user.name}
            </a>
            <Badge size="medium" user={post.user} />

            <RepBadge origin={origin} record={post} />
          </div>

          <a
            className={`${typography.bodyS} ${typography.linkPebble}`}
            href={`${basePath}/posts/${post.id}`}
            onClick={(e) => onCreatedAtClick(e, post.id)}
            title={timestampToPrettyDate(post.created_at)}
          >
            {timestampToRelativeTime(post.created_at)}
            {post.edited_at && <span> (edited)</span>}
          </a>
        </div>
      </div>
    </div>

    <OptionsMenuWithIcon
      copyLinkText="Copy link to post"
      currentUser={currentUser}
      extendedAdminOptions={() => AdminMenuOpts({ isPinned, onPinClick })}
      isFlagged={isFlagged}
      isPinned={isPinned}
      onCopyLinkClick={onCopyLinkClick}
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
      onPinClick={onPinClick}
      onReportClick={onReportClick}
      onSpamClick={onSpamClick}
      openMenu={openMenu}
      origin={origin}
      record={post}
      toggleMenu={toggleMenu}
    />
  </div>
);

PostCardHeader.propTypes = {
  basePath: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string.isRequired,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
    url: PropTypes.string,
  }),
  isFlagged: PropTypes.bool.isRequired,
  isPinned: PropTypes.bool.isRequired,
  onCopyLinkClick: PropTypes.func.isRequired,
  onCreatedAtClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onPinClick: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  onSpamClick: PropTypes.func.isRequired,
  openMenu: PropTypes.bool.isRequired,
  origin: PropTypes.shape({ admin_ids: PropTypes.arrayOf(PropTypes.number) }),
  post: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    edited_at: PropTypes.string,
    id: PropTypes.number.isRequired,
    user: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

PostCardHeader.defaultProps = {
  currentUser: null,
  origin: null,
};

export default PostCardHeader;
