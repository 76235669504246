import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { graphQuery } from '../../../requests/graphql';
import { getInObj } from '../../../utility/accessors';
import errorHandler from '../../../services/error_handler';

import BarView from './BarView';
import BottomView from './BottomView';
import SideView from './SideView';

class SimilarProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      records: [],
    };

    this.initialize = this.initialize.bind(this);
  }

  initialize() {
    return this.fetchRecords({ count: this.props.count, hid: this.props.project.hid, offset: this.props.offset });
  }

  fetchRecords({ count, hid, offset }) {
    return graphQuery({ t: 'get_related_projects_for_project' }, { hid, offset: offset, count: count })
      .then((res) => this.setState({
        initialized: true,
        records: (getInObj(['project', 'related_projects'], res) || []),
      }))
      .catch((err) => errorHandler('SimilarProjects initialize: ', err));
  }

  _renderBarView() {
    return (
      <BarView
        initialized={this.state.initialized}
        onReveal={this.initialize}
        records={this.state.records}
      />
    );
  }

  _renderBottomView() {
    return (
      <BottomView
        initialized={this.state.initialized}
        onReveal={this.initialize}
        records={this.state.records}
      />
    );
  }

  _renderSideView() {
    return (
      <SideView
        count={this.props.count}
        initialized={this.state.initialized}
        onReveal={this.initialize}
        records={this.state.records}
      />
    );
  }

  _renderView() {
    switch (this.props.view) {
      case 'bar':
        return this._renderBarView();
      case 'side':
        return this._renderSideView();
      default:
        return this._renderBottomView();
    }
  }

  render() {
    return this._renderView();
  }
}

SimilarProjects.propTypes = {
  count: PropTypes.number,
  offset: PropTypes.number,
  project: PropTypes.shape({ hid: PropTypes.string.isRequired }).isRequired,
  view: PropTypes.string,
};

SimilarProjects.defaultProps = {
  count: 8,
  offset: 0,
  view: 'bottom',
};

export default SimilarProjects;
