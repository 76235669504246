import React from 'react';

import ImagePlaceholder from '../../../../../client/image/ImagePlaceholder';
import StoryJSON from '../../../../../client/draftster/story_json_renderers/StoryJSON';

import { doesStoryHaveContent } from '../../../../../client/draftster/story_json_renderers/storyJSONHelpers';

import animation from '../../../../../styles/animation.css';
import colors from '../../../../../styles/global_ui/colors.css';
import layout from '../../../../../styles/global_ui/layout.css';
import styles from '../article_layout.css';

// TODO: move this and TextPlaceholder (and maybe ImagePlaceholder) into a common "placeholders" folder
export const ParagraphPlaceholder = ({
  animated = false,
  numLines = 5,
  backgroundColor = colors['v-fog'],
  textHeight = '1em',
  lastLineWidth = 'inherit',
  textSpacing = '0.2em',
  width = 'inherit',
}) => (
  React.Children.toArray([...new Array(numLines).keys()].map((i) => (
    <div
      key={i}
      className={animated ? animation.loader : null}
      style={{
        backgroundColor,
        height: textHeight,
        marginTop: textSpacing,
        width: i === (numLines - 1) ? lastLineWidth : width,
      }}
    />
  )))
);

const _getContentParagraphPlaceholder = (numLines) => (
  <ParagraphPlaceholder
    backgroundColor={colors['v-asphalt']}
    lastLineWidth="60%"
    numLines={numLines}
    textHeight={16}
    textSpacing={6}
  />
);

const _getContentPlaceholder = () => (
  <div className={layout.fullWith}>
    {_getContentParagraphPlaceholder(8)}
    <ImagePlaceholder
      aspectRatio={16 / 9}
      backgroundColor={colors['v-asphalt']}
      className={`${layout.marginTop30} ${layout.marginBottom30}`}
    />
    {_getContentParagraphPlaceholder(10)}
  </div>
);

const _getContent = (article) => (<StoryJSON className={styles.content} story={article.content} />);

const _getContentOrPlaceholder = (article) => (
  doesStoryHaveContent(article.content) ? _getContent(article) : _getContentPlaceholder()
);

export const renderContent = (article, isPreview) => (
  isPreview ? _getContentOrPlaceholder(article) : _getContent(article)
);
