import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import ButtonDropdown from '../buttons/button_dropdown';
import Icon from '../icon';

import { windowLocationHash } from '../../services/window';
import { dispatchEvent } from '../../utility/dispatchers';

import buttonStyles from '../../styles/global_ui/buttons.css';
import dropdownStyles from '../../styles/common-dropdown.css';
import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import styles from './story_editor.css';

const DOC_X_FILE_INPUT_ID = 'docXFileInput';
const HASH = '#story';
const MENU_OPTS = ['docX'];

class StoryEditorHelpBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autosaveEnabled: props.initAutoSaveEnabled,
      boundary: null,
      isEditorBusy: false,
      isFixed: false,
      show: false,
      showTips: false,
    };

    this.handleHashChange = this.handleHashChange.bind(this);
    this.handleStoryEditorRequest = this.handleStoryEditorRequest.bind(this);
    this.onAutoCompleteChange = this.onAutoCompleteChange.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.root;
  }

  componentDidMount() {
    this.throttledScroll = throttle(this.onScroll, 50);
    window.addEventListener('scroll', this.throttledScroll);
    window.addEventListener('hashchange', this.handleHashChange);
    window.addEventListener('StoryEditorRequest', this.handleStoryEditorRequest);
    this._verifyHash();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledScroll);
    window.removeEventListener('hashchange', this.handleHashChange);
    window.removeEventListener('StoryEditorRequest', this.handleStoryEditorRequest);
  }

  _getPrevSiblingDims() {
    if (this.state.boundary) return;

    if (this.root && this.root.parentNode && this.root.parentNode.parentNode) {
      const checklist = this.root.parentNode.parentNode.querySelector('.box');
      if (checklist) {
        const dims = checklist.getBoundingClientRect();
        const boundary = dims.top + dims.height + window.scrollY;
        this.setState({ boundary });
      }
    }
  }

  _verifyHash() {
    if (!this.state.show && windowLocationHash.get() === HASH) {
      this.setState({ show: true }, () => this._getPrevSiblingDims());
    } else if (this.state.show && windowLocationHash.get() !== HASH) {
      this.setState({ show: false });
    }
  }

  handleHashChange() {
    this._verifyHash();
  }

  handleStoryEditorRequest(e) {
    const isEditorBusy = e.detail.isEditorBusy;
    if (this.state.isEditorBusy !== isEditorBusy) {
      this.setState({ isEditorBusy });
    }
  }

  onAutoCompleteChange(e) {
    dispatchEvent('StoryHelpBox', { autosaveEnabled: e.target.checked });
    this.setState({ autosaveEnabled: e.target.checked });
  }

  onScroll() {
    if (!this.state.boundary) return;

    const scrollY = window.scrollY;
    if (scrollY <= this.state.boundary && this.state.isFixed) {
      this.setState({ isFixed: false });
    } else if (scrollY > this.state.boundary && !this.state.isFixed) {
      this.setState({ isFixed: true });
    }
  }

  _autocompleteCopy() {
    return this.state.autosaveEnabled ? 'Autosave on' : 'Autosave off';
  }

  handleDocXClick() {
    const input = document.getElementById(DOC_X_FILE_INPUT_ID);
    if (input) input.click();
  }

  _onOptClick(opt) {
    switch (opt) {
      case 'docX':
        return this.handleDocXClick();

      default:
        return;
    }
  }

  _statusCopy() {
    return this.state.isEditorBusy ? <span className={styles.saving}>Saving...</span> : 'Saved';
  }

  /**
   * Views
   */
  _getLabelForOpt(opt) {
    switch (opt) {
      case 'docX':
        return 'Upload docx file';

      default: '';
    }
  }

  render() {
    if (!this.state.show) return null;

    return (
      <div
        ref={(el) => this.root = el}
        className={`${styles.helpBox} ${this.state.isFixed ? styles.fixed : ''}`}
      >
        <div className={layout.flex10Auto}>
          <div>
            <label className={styles.autosaveLabel}>
              <input
                checked={this.state.autosaveEnabled}
                className={styles.autosaveInput}
                disabled={this.state.isEditorBusy}
                onChange={this.onAutoCompleteChange}
                type="checkbox"
              />
              {this._autocompleteCopy()}
            </label>
          </div>

          <div className={styles.statusWrapper}>
            <span>Status: </span>
            <strong>{this._statusCopy()}</strong>
          </div>

          {this.state.showTips
          && (
            <ul className={styles.tips}>
              <li>
                <strong>Undo</strong>
                : Ctrl or Cmd+Z
              </li>
              <li>
                <strong>Redo</strong>
                : Ctrl or Cmd+Shift+Y
              </li>
              <li>
                <strong>Save</strong>
                : Ctrl or Cmd+S
              </li>
            </ul>
          )}
          <button
            className={`${buttonStyles.blank}`}
            onClick={() => this.setState({ showTips: !this.state.showTips })}
          >
            <span className={`${typography.bodyS} ${typography.linkBlue}`}>
              {`${this.state.showTips ? 'Hide' : 'Show'} tips`}
            </span>
          </button>
        </div>

        <div>
          <ButtonDropdown
            alignRight={true}
            classList={{ button: buttonStyles.blank }}
            closeOnSelect={true}
            label={<Icon className={typography.iconClickable} name="view-more" size={12} />}
          >
            <ul className={dropdownStyles.list}>
              {MENU_OPTS.map((opt, i) => (
                <li key={i} className={dropdownStyles.listItem} onClick={() => this._onOptClick(opt)}>
                  {this._getLabelForOpt(opt)}
                </li>
              ))}
            </ul>
          </ButtonDropdown>
        </div>
      </div>
    );
  }
}

StoryEditorHelpBox.propTypes = { initAutoSaveEnabled: PropTypes.bool };

StoryEditorHelpBox.defaultProps = { initAutoSaveEnabled: true };

export default StoryEditorHelpBox;
