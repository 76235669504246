import algoliasearch from 'algoliasearch';

const KNOWN_PROJECTS_ALGOLIA_INDEXES = [
  'featured_date',
  'featured_platforms',
  'featured_platforms_last_updated',
  'featured_platforms_most_recent',
  'featured_platforms_most_respected',
  'featured_platforms_trending',
  'last_updated',
  'most_recent',
  'most_respected',
  'platforms_most_recent',
  'trending',
];

// When we need to extend for replicated indices.
const KNOWN_ALGOLIA_INDEXES = {
  channels: ['a_z', 'last_updated', 'most_projects'],
  contests: [],
  events: ['earliest_first', 'latest_first'],
  misc: ['a_z', 'most_projects'],
  news_articles: ['most_recent', 'most_respected'],
  parts: ['a_z', 'most_owned', 'name'],
  projects: KNOWN_PROJECTS_ALGOLIA_INDEXES,
  tags: [],
  topics: ['a_z', 'last_updated', 'most_projects'],
  videos: ['most_popular'],
};

const AlgoliaSearchIndexes = () => {
  if (!window || !window.jsk) return null;

  const jsk = window.jsk;
  const client = algoliasearch(jsk.aai, jsk.ask, { protocol: 'https:' });
  const indexes = {
    channels: client.initIndex(jsk.pli),
    contests: client.initIndex(jsk.cai),
    events: client.initIndex(jsk.evi),
    misc: client.initIndex(jsk.msc),
    news_articles: client.initIndex(jsk.nai),
    parts: client.initIndex(jsk.pai),
    projects: client.initIndex(jsk.pri),
    suggestions: client.initIndex(jsk.sqi),
    tags: client.initIndex(jsk.tai),
    topics: client.initIndex(jsk.tcai),
    users: client.initIndex(jsk.usi),
    videos: client.initIndex(jsk.vai),
  };

  return {
    getCustomIndex: (idx) => client.initIndex(idx),
    getIndex: (idx) => indexes[idx],
  };
};

const AlgoliaSearchIndexesNames = () => {
  if (!window || !window.jsk) return null;
  const jsk = window.jsk;

  return {
    channels: jsk.pli,
    contests: jsk.cai,
    events: jsk.evi,
    misc: jsk.msc,
    news_articles: jsk.nai,
    parts: jsk.pai,
    projects: jsk.pri,
    suggestions: jsk.sqi,
    tags: jsk.tai,
    topics: jsk.tcai,
    users: jsk.usi,
    videos: jsk.vai,
  };
};

export const initAlgoliaClient = () => {
  if (!window || !window.jsk) return null;

  const jsk = window.jsk;
  const client = algoliasearch(jsk.aai, jsk.ask, { protocol: 'https:' });
  return client;
};

export function validatedSortIndex(index, sortType) {
  return KNOWN_ALGOLIA_INDEXES[index].includes(sortType);
}

export default function getAlgoliaIndex(index, sortType) {
  const algoliaSearchIndexes = AlgoliaSearchIndexes();

  if (!window.jsk) return new Error('window.jsk does not exist!');
  if (typeof algoliaSearchIndexes.getIndex(index) === 'undefined') return new Error(`algoliaSearchIndexes does not have index ${index}!`);

  if (sortType && sortType.length) {
    if (validatedSortIndex(index, sortType) === false) return new Error(`Unknown index: ${index} with sort ${sortType}`);

    const map = {
      channels: 'pli',
      contests: 'cai',
      events: 'evi',
      misc: 'msc',
      news_articles: 'nai',
      parts: 'pai',
      projects: 'pri',
      suggestions: 'sqi',
      tags: 'tai',
      topics: 'tcai',
      users: 'usi',
      videos: 'vai',
    };

    return algoliaSearchIndexes.getCustomIndex(`${window.jsk[map[index]]}_${sortType}`);
  } else {
    return algoliaSearchIndexes.getIndex(index);
  }
}

export function getAlgoliaIndexName(index, sortType) {
  if (!window.jsk) return new Error('window.jsk does not exist.');

  const algoliaSearchIndexesNames = AlgoliaSearchIndexesNames();
  if (!algoliaSearchIndexesNames || !algoliaSearchIndexesNames[index]) return new Error(`Index name '${index}' does not exist.`);

  const indexName = algoliaSearchIndexesNames[index];

  if (sortType) {
    if (!validatedSortIndex(index, sortType)) return new Error(`Unknown sort: ${sortType} for index ${index}.`);
    return `${indexName}_${sortType}`;
  }

  return indexName;
}
