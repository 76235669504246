import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmbedText from './EmbedText';
import LazyBackgroundImage from '../../../image/LazyBackgroundImage';
import ResponsiveIframe from '../../../wrappers/responsive_iframe';
import VideoOverlay from '../../../cards/video_card/VideoOverlay';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './post_embed.css';

class VideoEmbed extends Component {
  constructor(props) {
    super(props);

    this.expand = this.expand.bind(this);

    const aspectRatio = parseInt(props.embed.type_props.width) / parseInt(props.embed.type_props.height) || undefined;

    this.state = {
      aspectRatio,
      expanded: false,
    };
  }

  expand(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expanded: true });
  }

  /**
   * Views
   */
  _getDefaultView({ embed }) {
    return (
      <div className={`${styles.image} ${utilStyles.posRelative}`} onClick={this.expand}>
        <LazyBackgroundImage
          ratio="1:1"
          setStyle={false}
          src={embed.image}
          width={158}
        />
        <VideoOverlay />
      </div>
    );
  }

  _getExpandedView({ aspectRatio, embed, mode }) {
    return (
      <ResponsiveIframe
        aspectRatio={aspectRatio}
        className={mode === 'show' ? '' : layout.marginTop30}
        src={embed.type_props.iframe_url}
      />
    );
  }

  render() {
    const { embed, mode } = this.props;
    const { aspectRatio, expanded } = this.state;

    return (
      <div className={expanded ? layout.flexColumn : `${layout.flex} ${styles.lockHeight}`}>
        {expanded ? this._getExpandedView({ aspectRatio, embed, mode }) : this._getDefaultView({ embed })}
        <EmbedText embed={embed} />
      </div>
    );
  }
}

VideoEmbed.propTypes = {
  embed: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

VideoEmbed.defaultProps = { mode: 'show' };

export default VideoEmbed;
