// TODO: move to utility/images.js
import { v4 as uuidv4 } from 'uuid';

/**
 * In-browser image conversion
 */
export function canvasToBlob(canvasElement, options = []) {
  return new Promise((resolve, reject) => {
    if (HTMLCanvasElement.prototype.toBlob) {
      canvasElement.toBlob((blob) => resolve(blob), ...options);
    } else {
      resolve(dataURIToBlob(canvasElement.toDataURL(...options)));
    }
  });
}

export function createDataURLFromURL(src, dimensions) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'use credentials';

    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');

    image.onload = () => {
      const width = dimensions ? dimensions.width : image.width;
      const height = dimensions ? dimensions.height : image.height;

      canvas.width = width;
      canvas.height = height;
      canvasContext.drawImage(image, 0, 0, width, height);

      resolve(canvas.toDataURL());
    };

    image.onerror = (err) => reject(err);
    image.src = src;
  });
}

export function dataURIToBlob(dataURI) {
  return new Promise((resolve, reject) => {
    const [header, data] = dataURI.split(',');
    const byteString = (header.indexOf('base64') !== -1) ? atob(data) : decodeURI(data);

    const mimestring = header.split(':')[1].split(';')[0];

    const content = [];
    for (let i = 0; i < byteString.length; i++) {
      content[i] = byteString.charCodeAt(i);
    }

    resolve(new Blob([new Uint8Array(content)], { type: mimestring }));
  });
}

export function promisifiedFileReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // TODO: check "actual" file type here? https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
    reader.onload = (upload) => resolve({ dataUrl: upload.target.result, hash: uuidv4(), rawFile: file });
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });
}
