import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PostCardBody from './PostCardBody';
import PostCardComments from '../post_card_comments';
import PostCardHeader from './PostCardHeader';
import PostEditor from '../post_editor';

import { summonLoginPanel } from '../../../utility/dispatchers';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './post_card.css';

class PostCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      openMenu: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);

    // Ref
    this._editor;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Methods
   */
  toggleMenu() {
    this.setState({ openMenu: !this.state.openMenu });
  }

  /**
   * Helpers
   */
  _summonLoginPanel() {
    summonLoginPanel({
      detail: {
        id: this.props.post.id,
        state: { currentPanel: 'signup', simplified: true },
        source: 'feed_post',
      },
    });
  }

  /**
   * Views
   */
  _getBody() {
    return this.state.isEditing ? this._getEditorBodyView() : this._getDefaultBodyView();
  }

  _getDefaultBodyView() {
    return (
      <PostCardBody
        basePath={this.props.basePath}
        categoryConfig={this.props.categoryConfig}
        markdownService={this.props.markdownServices.postOrComment}
        onCategoryTagClick={this.props.onCategoryTagClick}
        post={this.props.post}
      />
    );
  }

  _getEditorBodyView() {
    return (
      <PostEditor
        categorySelectOpts={this.props.categorySelectOpts}
        currentPath={this.props.currentPath}
        currentUser={this.props.currentUser}
        dismiss={() => (this._isMounted && this.setState({ isEditing: false }))}
        isBusy={this.props.isBusy}
        markdownService={this.props.markdownServices.editor}
        mode="edit"
        onPostOrUpdate={this.props.onPostUpdate}
        origin={this.props.origin}
        post={this.props.post}
      />
    );
  }

  _getMissingPostView() {
    return (
      <div className={`${typography.bodyM} ${layout.marginTop30}`}>
        Sorry, that post does not exist!
      </div>
    );
  }

  render() {
    if (!this.props.post) return this._getMissingPostView();

    return (
      <div className={styles.root}>
        <PostCardHeader
          basePath={this.props.basePath}
          currentUser={this.props.currentUser}
          isFlagged={this.props.flaggedIds.posts.includes(this.props.post.id)}
          isPinned={this.props.isPinned}
          onCopyLinkClick={() => this.props.onCopyLinkClick(
            { postId: this.props.post.id },
            'post',
            this.toggleMenu,
          )}
          onCreatedAtClick={this.props.onCreatedAtClick}
          onDeleteClick={() => this.props.onDeleteClick(this.props.post, this.toggleMenu)}
          onEditClick={() => this.setState({ isEditing: true, openMenu: false })}
          onPinClick={() => this.props.onPinClick(this.props.post, this.props.isPinned, this.toggleMenu)}
          onReportClick={() => this.props.currentUser.id === null
            ? this._summonLoginPanel()
            : this.props.onReportClick(this.props.post, 'feed_post', this.toggleMenu)}
          onSpamClick={() => this.props.onSpamClick(this.props.post, 'feed_post', this.toggleMenu)}
          openMenu={this.state.openMenu}
          origin={this.props.origin}
          post={this.props.post}
          toggleMenu={this.toggleMenu}
        />

        {this._getBody()}

        <PostCardComments
          comments={this.props.post.comments}
          config={this.props.config}
          currentUser={this.props.currentUser}
          flaggedCommentIds={this.props.flaggedIds.comments}
          isBusy={this.props.isBusy}
          markdownServices={this.props.markdownServices}
          onCopyLinkClick={this.props.onCopyLinkClick}
          onDelete={this.props.deleteComment}
          onPost={this.props.createOrUpdateComment}
          onReportClick={this.props.onReportClick}
          onShowMoreCommentRepliesClick={this.props.onShowMoreCommentRepliesClick}
          onShowPreviousCommentsClick={this.props.onShowPreviousCommentsClick}
          onSpamClick={this.props.onSpamClick}
          origin={this.props.origin}
          post={this.props.post}
          renderAllComments={this.props.renderAllComments}
          userCanComment={this.props.userCanComment}
          workers={this.props.workers}
        />
      </div>
    );
  }
}

PostCard.propTypes = {
  basePath: PropTypes.string.isRequired,
  categoryConfig: PropTypes.arrayOf(PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  categorySelectOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    value: PropTypes.string,
  })).isRequired,
  config: PropTypes.shape({ allowScrollToCommentOnMount: PropTypes.bool }),
  createOrUpdateComment: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  deleteComment: PropTypes.func.isRequired,
  flaggedIds: PropTypes.shape({
    comments: PropTypes.array.isRequired,
    posts: PropTypes.array.isRequired,
  }).isRequired,
  isBusy: PropTypes.bool.isRequired,
  isPinned: PropTypes.bool,
  markdownServices: PropTypes.shape({
    editor: PropTypes.object.isRequired,
    postOrComment: PropTypes.object.isRequired,
  }).isRequired,
  onCategoryTagClick: PropTypes.func.isRequired,
  onCopyLinkClick: PropTypes.func.isRequired,
  onCreatedAtClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onPinClick: PropTypes.func.isRequired,
  onPostUpdate: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  onShowMoreCommentRepliesClick: PropTypes.func.isRequired,
  onShowPreviousCommentsClick: PropTypes.func.isRequired,
  onSpamClick: PropTypes.func.isRequired,
  origin: PropTypes.shape({
    admin_ids: PropTypes.arrayOf(PropTypes.number),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  post: PropTypes.shape({
    body: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    comments: PropTypes.arrayOf(PropTypes.shape({
      body_with_relations: PropTypes.string,
      children: PropTypes.array, // Recursive comments shape, this may be null
      created_at: PropTypes.string.isRequired,
      edited_at: PropTypes.string,
      deleted: PropTypes.bool,
      id: PropTypes.number.isRequired,
      parent_id: PropTypes.number,
      raw_body: PropTypes.string.isRequired,
      relations: PropTypes.object,
      user: PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isAdmin: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string,
      }).isRequired,
    })).isRequired,
    created_at: PropTypes.string.isRequired,
    edited_at: PropTypes.string,
    entities: PropTypes.shape({ embed: PropTypes.object }),
    id: PropTypes.number.isRequired,
    relations: PropTypes.object,
    respects_count: PropTypes.number.isRequired,
    user: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    renderAllComments: PropTypes.bool,
  }),
  userCanComment: PropTypes.bool.isRequired,
  workers: PropTypes.object.isRequired,
};

PostCard.defaultProps = {
  config: { allowScrollToCommentOnMount: false },
  isPinned: false,
  origin: null,
  post: null,
  renderAllComments: false,
};

export default PostCard;
