import mammoth from 'mammoth';

const MAMMOTH_OPTS = {
  styleMap: [
    "p[style-name^='Heading'] => h3:fresh",
  ],
};

const convertFileToArrayBuffer = (file) => new Promise((resolve, reject) => {
  const fr = new FileReader();

  // Callbacks
  fr.addEventListener('error', (error) => {
    reject(error);
  });

  fr.addEventListener('loadend', (event) => {
    resolve(event.currentTarget.result);
  });

  // Execute.
  fr.readAsArrayBuffer(file);
});

export default function convertDocXToHtml(e) {
  return new Promise((resolve, reject) => {
    const file = e.target.files[0];

    return convertFileToArrayBuffer(file)
      .then((buffer) => mammoth.convertToHtml({ arrayBuffer: buffer }, MAMMOTH_OPTS))
      .then((results) => resolve(results.value))
      .catch((err) => reject(err));
  });
}
