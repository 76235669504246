export const SUMMON_GLOBAL_DIALOG = 'open:GlobalDialog';

export function closeGlobalPopover() {
  window.dispatchEvent(new CustomEvent('close:GlobalPopover', {
    detail: {},
    bubbles: true,
    cancelable: true,
  }));
}

export function dispatchEvent(event, detail = {}) {
  window.dispatchEvent(new CustomEvent(event, { detail }));
}

/**
  * Show an alert message
  * @param  {object} detail - event detail
  * @param  {string} detail.msg - the message to display
  * @param  {string} detail.type - type of message ("success"|"error")
  */
export function summonGlobalMessenger(detail = {}) {
  window.dispatchEvent(new CustomEvent('react:messenger', {
    detail: detail,
    bubbles: true,
    cancelable: true,
  }));
}

/**
 * Summon the bookmarks widget
 * @param  {object} detail - event detail
 * @param  {number} detail.dialogProps - props to pass to the Dialog component
 * @param  {function} detail.getChildren - function that will return the children (body) of the component. takes a 'dismiss' callback as an argument
 */
export function summonGlobalDialog(detail = {}) {
  window.dispatchEvent(new CustomEvent(SUMMON_GLOBAL_DIALOG, {
    detail: detail,
    bubbles: true,
    cancelable: true,
  }));
}

export function summonGlobalPopover(detail = {}) {
  window.dispatchEvent(new CustomEvent('open:GlobalPopover', {
    detail: detail,
    bubbles: true,
    cancelable: true,
  }));
}

export function summonLoginPanel({ detail = {} } = {}) {
  window.dispatchEvent(new CustomEvent('open:SigninDialog', {
    detail: detail,
    bubbles: true,
    cancelable: true,
  }));
}
