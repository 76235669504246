import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import Draft from './Draft';

// ATTN :: This class seems to be a gateway for A) Rendering in should update and B) getting a width for the toolbar.
// See if we can do that without this logic.  The shouldUpdate logic could be above or below if needed.
export default class Editor extends Component {
  constructor(props) {
    super(props);

    this.handleResize = this.handleResize.bind(this);
    this.debouncedResize = debounce(this.handleResize, 30);
    this.focusDraft = this.focusDraft.bind(this);

    // Refs
    this.draft;
    this.editor;
  }

  componentDidMount() {
    if (window && window.addEventListener) {
      window.addEventListener('resize', this.debouncedResize);
    }
    // Race condition in Parent.  Clean up the mounting calls, too much updates happening at once!
    setTimeout(() => {
      this._propagateEditorWidth();
    }, 20);
  }

  componentWillUnmount() {
    if (window) window.removeEventListener('resize', this.debouncedResize);
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.draft !== this.props.draft ||
      nextProps.editor !== this.props.editor ||
      nextProps.readOnly !== this.props.readOnly
    );
  }

  focusDraft() {
    this.draft.focus();
  }

  handleResize() {
    this._propagateEditorWidth();
  }

  /**
   * If the editor is hidden, set the width to 100% so the unfixed toolbar renders in properly.
   * The Toolbar component will fire a callback when its set to "100%" which attempts to update the width property on scroll.
   */
  _propagateEditorWidth() {
    if (this.editor) {
      const rects = this.editor.getBoundingClientRect();
      const width = rects.width > 0 ? rects.width : '100%';
      this.props.setEditorWidth(width);
    }
  }

  render() {
    const {draft, editor, onImageUpload} = this.props;

    return (
      <div
        ref={el => this.editor = el}
        className="draftster-wrapper"
        >
        <Draft
          ref={el => this.draft = el}
          activeMetadata={this.props.activeMetadata}
          blockRestrictions={this.props.editor.get('blockRestrictions')}
          draftState={this.props.draft}
          hasFocus={this.props.editor.get('hasFocus')}
          instanceId={this.props.editor.get('instanceId')}
          isDialogOpen={this.props.editor.get('isDialogOpen')}
          onDraftChange={this.props.onDraftChange}
          onDraftFocus={this.props.onDraftFocus}
          onImageUpload={onImageUpload}
          processImage={this.props.processImage}
          readOnly={this.props.readOnly}
          setDraft={this.props.setDraft}
          setMessenger={this.props.setMessenger}
          setUnsavedChanges={this.props.setUnsavedChanges}
          toggleActiveStyle={this.props.toggleActiveStyle}
          toggleDialogStatus={this.props.toggleDialogStatus}
          toggleReadOnly={this.props.toggleReadOnly}
          uploadImages={this.props.uploadImages}
        />
      </div>
    );
  }
}

Editor.propTypes = {
  draft: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  onDraftChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  processImage: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setDraft: PropTypes.func.isRequired,
  setEditorWidth: PropTypes.func.isRequired,
  setMessenger: PropTypes.func.isRequired,
  setUnsavedChanges: PropTypes.func.isRequired,
  toggleActiveStyle: PropTypes.func.isRequired,
  toggleDialogStatus: PropTypes.func.isRequired,
  toggleReadOnly: PropTypes.func.isRequired
};