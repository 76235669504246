import { MEMBERSHIP_TIERS_RANK_MAP } from '../scenes/contests_page/enum';

export const isSignedInUser = (user) => {
  const id = parseInt(user && user.id);

  return !isNaN(id) && id > 0;
};

export const isPendingVerification = (tier) => {
  if (!tier) return false;
  const formattedTier = tier.toUpperCase();
  return formattedTier === 'PENDING';
};

export const isPendingOrVerified = (tier) => {
  if (!tier) return false;
  const formattedTier = tier.toUpperCase();
  return (
    MEMBERSHIP_TIERS_RANK_MAP[formattedTier]
    >= MEMBERSHIP_TIERS_RANK_MAP['PENDING']
  );
};

export const isVerified = (tier) => {
  if (!tier) return false;
  const formattedTier = tier.toUpperCase();
  return MEMBERSHIP_TIERS_RANK_MAP[formattedTier]
    >= MEMBERSHIP_TIERS_RANK_MAP['VERIFIED'];
};
