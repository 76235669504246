import React from 'react';
import PropTypes from 'prop-types';

import Badge from '../../reusable_components/Badge';
import RepBadge from '../templates/RepBadge';
import OptionsMenuWithIcon from '../templates/OptionsMenuWithIcon';

import { timestampToPrettyDate, timestampToRelativeTime } from '../../../utility/time';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './post_card_comments.css';

const PostCardCommentHeader = ({
  comment,
  currentUser,
  isFlagged,
  onCopyLinkClick,
  onDeleteClick,
  onEditClick,
  onReportClick,
  onSpamClick,
  openMenu,
  origin,
  toggleMenu,
}) => (
  <div className={layout.flex}>
    <div className={`${layout.flexJustifySpaceBetween} ${layout.fullWidth}`}>
      <div
        className={`${layout.flexCenterItems} ${layout.inlineFlex} ${layout.gutter5}`}
      >
        <a
          className={`${typography.linkCharcoal} ${typography.bold} ${
            typography.bodyM
          } ${comment.user.url ? '' : typography.linkDisabled}`}
          href={comment.user.url}
        >
          {comment.user.name}
        </a>

        <Badge size="medium" user={comment.user} />

        <RepBadge origin={origin} record={comment} />

        <span
          className={`${typography.bodyS} ${typography.pebble} ${layout.marginLeft5}`}
          title={timestampToPrettyDate(comment.created_at)}
        >
          {timestampToRelativeTime(comment.created_at)}
          {comment.edited_at && <span> (edited)</span>}
        </span>
      </div>

      {!comment.deleted && (
        <div className={styles.menuWrapper}>
          <OptionsMenuWithIcon
            copyLinkText="Copy link to comment"
            currentUser={currentUser}
            isFlagged={isFlagged}
            onCopyLinkClick={onCopyLinkClick}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            onReportClick={onReportClick}
            onSpamClick={onSpamClick}
            openMenu={openMenu}
            record={comment}
            toggleMenu={toggleMenu}
          />
        </div>
      )}
    </div>
  </div>
);

PostCardCommentHeader.propTypes = {
  comment: PropTypes.shape({
    body_with_relations: PropTypes.string,
    children: PropTypes.array, // Recursive comments shape, this may be null
    created_at: PropTypes.string.isRequired,
    edited_at: PropTypes.string,
    deleted: PropTypes.bool,
    id: PropTypes.number.isRequired,
    parent_id: PropTypes.number,
    relations: PropTypes.object,
    user: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  isFlagged: PropTypes.bool.isRequired,
  onCopyLinkClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  onSpamClick: PropTypes.func.isRequired,
  openMenu: PropTypes.bool.isRequired,
  origin: PropTypes.shape({
    admin_ids: PropTypes.arrayOf(PropTypes.number),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  toggleMenu: PropTypes.func.isRequired,
};

PostCardCommentHeader.defaultProps = {};

export default PostCardCommentHeader;
