  import { EditorState, Modifier } from 'draft-js';

import {
  doesPreviousWordContainValidUrl,
  getCurrentBlock,
  insertNewOrSplitBlock,
  insertParagraph,
  isCursorAtEnd,
  linkifyPreviousWord,
  previousCharContainsEntityTypes
} from '../utils/draftUtils';

import {
  isCurrentBlockAType,
} from '../utils/draft/conditions';

import {insertEmbedlyComponent, insertGistComponent, insertVideoComponent, insertTweetComponent} from './customBlockTransactions';

const blocksToDuplicateOnEnter = {
  'unordered-list-item': true,
  'code-block': true,
  'unstyled': true
};

// Callback::Draft.onChange expects an updated EditorState.
export default function customReturnHandler(editorState, callback) {
  const currentContentState = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();
  const currentBlock = getCurrentBlock(currentContentState, currentSelection);

  // Duplicate blocks.
  if (currentSelection.isCollapsed() && isCursorAtEnd(currentBlock, currentSelection) && !blocksToDuplicateOnEnter[currentBlock.getType()]) {
    const { updatedContentState, updatedSelection } = insertParagraph(currentBlock, currentContentState, currentSelection);

    callback(EditorState.forceSelection(EditorState.push(editorState, updatedContentState), updatedSelection));
    return true;
  }

  // Break list and append paragraph if current line is empty.
  if (currentSelection.isCollapsed() && currentBlock.getType() === 'unordered-list-item' && currentBlock.getText().trim().length === 0) {
    const updatedContentState = Modifier.setBlockType(currentContentState, currentSelection, 'unstyled');

    callback(EditorState.push(editorState, updatedContentState));
    return true;
  }

  // Embedly
  if (doesPreviousWordContainValidUrl(currentBlock, currentSelection, 'EMBEDLY')) {
    const {newEditorState, newSelectionState} = insertEmbedlyComponent({currentBlock, currentSelection, currentContentState, editorState});

    callback(EditorState.forceSelection(newEditorState, newSelectionState));
    return true;
  }

  // Gist
  if (doesPreviousWordContainValidUrl(currentBlock, currentSelection, 'GIST')) {
    const { newEditorState, newSelectionState } = insertGistComponent({currentBlock, currentSelection, currentContentState, editorState});

    callback(EditorState.forceSelection(newEditorState, newSelectionState));
    return true;
  }

  // Video
  if (doesPreviousWordContainValidUrl(currentBlock, currentSelection, 'VIDEO')) {
    const { newEditorState, newSelectionState } = insertVideoComponent({currentBlock, currentSelection, currentContentState, editorState});

    callback(EditorState.forceSelection(newEditorState, newSelectionState));
    return true;
  }

  // Tweet
  if (doesPreviousWordContainValidUrl(currentBlock, currentSelection, 'TWEET')) {
    const { newEditorState, newSelectionState } = insertTweetComponent({currentBlock, currentSelection, currentContentState, editorState});

    callback(EditorState.forceSelection(newEditorState, newSelectionState));
    return true;
  }

  // Url
  if (!previousCharContainsEntityTypes(editorState, ['LINK']) && doesPreviousWordContainValidUrl(currentBlock, currentSelection, 'LINK') && !isCurrentBlockAType(currentBlock, ['code-block'])) {
    const linkifiedContentState = linkifyPreviousWord(currentBlock, currentSelection, currentContentState);
    const { updatedContentState, updatedSelection } = insertNewOrSplitBlock(linkifiedContentState, currentSelection);

    callback(EditorState.forceSelection(EditorState.push(editorState, updatedContentState), updatedSelection));
    return true;
  }

  return false;
}