import { Component } from 'react';
import PropTypes from 'prop-types';

import errorHandler from '../../../services/error_handler';
import { isAFunction } from '../../../utility/types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    errorHandler(error, info);
    this.props.onError(error, info);
  }

  _getFallbackView() {
    return isAFunction(this.props.fallbackView) ? this.props.fallbackView() : this.props.fallbackView;
  }

  render() {
    if (this.state.hasError) {
      return this._getFallbackView();
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  fallbackView: PropTypes.any,
  onError: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  fallbackView: null,
  onError: () => {},
};

export default ErrorBoundary;
