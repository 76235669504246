import { getInObj } from '../../utility/accessors';

const FOOTNOTES_US = {
  A: 'Corrected zip code.',
  B: 'Fixed city/state spelling.',
  C: 'Invalid city/state/zip.',
  F: 'Address not found.',
  H: 'Missing apartment, suite (etc) number.',
  I: 'Insufficient address data.',
  J: 'Dual address.',
  K: 'Invalid cardinal direction (North, South, East, West).',
  L: 'Changed part of address.',
  M: 'Fixed street spelling.',
  N: 'Fixed abbreviations (i.e. STEET to ST).',
  O: 'More than one zipcode found',
  S: 'Secondary information is bad (apartment, suite, etc).',
  V: 'Unverifiable city / state.',
  W: 'Invalid delivery address.',
};

// Ordered by level of importance.
const FOOTNOTES_INTL = {
  None: 'Address not known, possibly because this address is invalid.',
  AdministrativeArea: 'Address is only verified down to the state or province.',
  Locality: 'Address is only verified down to the city or town.',
  Thoroughfare: 'Address is only verified down to the street.',
  Premise: 'Address is only verified down to the property or building.',
};

const getMsgsForINTL = (record) => {
  const addressPrecision = getInObj(['analysis', 'address_precision'], record);

  if (!addressPrecision) return [];
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return FOOTNOTES_INTL.hasOwnProperty(addressPrecision) ? [FOOTNOTES_INTL[addressPrecision]] : [];
};

const getMsgsForUS = (record) => {
  const footnotes = getInObj(['analysis', 'footnotes'], record);
  // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
  // eslint-disable-next-line @stylistic/no-mixed-operators
  if (!footnotes || footnotes && !footnotes.length) return [];
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return footnotes.split('#').reduce((acc, n) => FOOTNOTES_US.hasOwnProperty(n) ? acc.concat(FOOTNOTES_US[n]) : acc, []);
};

/**
 * @param  {object} record - SmartyStreets api response.
 * @param  {bool} isUS - is USA?
 * @return {array}
 */
export default function getInvalidAddressMsgs(record, isUS = true) {
  if (!record) return [];

  return isUS ? getMsgsForUS(record) : getMsgsForINTL(record);
}
