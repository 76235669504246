import { timestampToPrettyDate, EVENT_DATE_FORMAT, YEAR_ONLY_FORMAT } from '../../../utility/time';

const getDayAndMonthString = (timestamp) => timestampToPrettyDate(timestamp, EVENT_DATE_FORMAT, false, null);

const getDedupedYears = ({ start_date, end_date }) => {
  const years = [start_date, end_date].filter((d) => d).map((timestamp) => timestampToPrettyDate(timestamp, YEAR_ONLY_FORMAT, false, null));

  return years[0] === years[1] ? [years[0]] : years;
};

export const getDateRangeAsText = (item) => (
  [
    item.start_date ? getDayAndMonthString(item.start_date) : null,
    item.end_date ? getDayAndMonthString(item.end_date) : null,
  ].filter((d) => d).join(' - ')
);

// If start date and end date both exist and have different years, display year after each.
// Otherwise, display year only at the end
export const getDateRangeWithYearAsText = (item) => {
  const years = getDedupedYears(item);

  return [
    item.start_date ? getDayAndMonthString(item.start_date) : null,
    years.length === 2 ? `, ${years[0]}` : null,
    (item.start_date && item.end_date) ? ' - ' : null,
    item.end_date ? getDayAndMonthString(item.end_date) : null,
    years.length === 2 ? `, ${years[1]}` : `, ${years[0]}`,
  ].filter((d) => d).join('');
};
