import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import ActionableButton from '../../../../client/buttons/actionable';
import AlgoliaMultiSelect from '../../../../client/algolia/multi_select';
import BasicFormInput from '../../../../client/form_components/inputs/basic_form_input';
import Breadcrumb from '../../../../client/nav_components/breadcrumb';
import Button from '../../../../client/buttons/base';
import FormDateInput from '../../../../client/form_components/inputs/datetime/form_date_input';
import FormImageUploader from '../../../../client/form_components/image_uploaders/form_image_uploader';
import FormSelect from '../../../../client/form_components/selects/form_select';
import LocationSelect from '../../../../client/form_components/selects/form_location_select';
import PreviewLayoutDefault from './PreviewLayoutDefault';
import PreviewLayoutSponsored from './PreviewLayoutSponsored';
import RadioGroup from '../../../../client/form_components/inputs/radio_group';
import StickyWrapper from '../../../../client/wrappers/sticky_wrapper';

import { capitalize } from '../../../../utility/formatters';
import { getInObj } from '../../../../utility/accessors';
import { getErrorForField, getFieldValuesAsObject, initFields, setIsBusy, setStateOrError, validateFields } from '../../../../utility/forms';
import { imageV, isDatetimeLocalValueInFuture, isUrlWithProtocol, maxLength, minLength } from '../../../../services/validation/validators';
import { recordsToOptions } from '../../home_sections/promoted_content_form/content_form/components';
import { timestampToDateInputFormat } from '../../../../utility/time';
import { windowScrollTo } from '../../../../services/window';

import { boolOrNullIn, boolOrNullOut, locationSelectOut, multiSelectIn, multiSelectToArrayOut, valueToSelectIn } from '../../../../utility/forms/formatters';
import { HACKSTER_FORM_EVENT_TYPE_OPTS } from '../../../../graphql/events/enums';
import { NO_URL } from '../../../../utility/links';

import formStyles from '../../../../styles/global_ui/forms.css';
import inputStyles from '../../../../styles/global_ui/inputs.css';
import layout from '../../../../styles/global_ui/layout.css';
import stickyStyles from '../../../news/admin_page/templates/news_admin_templates.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const EVENT_TYPES_OPTS = HACKSTER_FORM_EVENT_TYPE_OPTS.map((v) => valueToSelectIn(v, capitalize, capitalize));

const FIELDS_TEMPLATE = {
  custom_sponsors: { order: 2, validate: (value) => maxLength(5, value), value: [], notRequired: true, formatIn: (vals) => vals.map((v) => valueToSelectIn(v)), formatOut: (v) => multiSelectToArrayOut(v) },
  event_type: { order: 11, validate: (value) => minLength(1, value), value: '', formatIn: (v) => valueToSelectIn(v, capitalize, capitalize), formatOut: (v) => getInObj(['value'], v) },
  image: { order: 9, validate: (value) => imageV(value), value: null },
  link: { order: 5, validate: (value) => isUrlWithProtocol(value) || maxLength(500, value), value: '' },
  location: { order: 8, validate: (value) => null, value: {}, notRequired: true, formatOut: (v) => locationSelectOut(v, true) },
  mobile_image: { order: 10, validate: (value) => value !== null && Object.keys(value).length > 0 && imageV(value), value: null, notRequired: true },
  platforms: { order: 1, validate: (value) => maxLength(5, value), value: [], notRequired: true, formatIn: (v) => multiSelectIn(v, 'name'), formatOut: (v) => multiSelectToArrayOut(v) },
  sponsored: { order: 0, validate: () => null, value: 'false', formatIn: (v) => boolOrNullIn(v), formatOut: (v) => boolOrNullOut(v) },
  summary: { order: 3, validate: (value) => maxLength(255, value), value: '', notRequired: true },
  title: { order: 4, validate: (value) => maxLength(60, value), value: '' },
};

const FIELDS_ADMIN_DATES = {
  end_date: { order: 7, validate: (value) => minLength(1, value), value: '', notRequired: true, formatIn: (v) => timestampToDateInputFormat(v) },
  start_date: { order: 6, validate: (value) => minLength(1, value), value: '', formatIn: (v) => timestampToDateInputFormat(v) },
};

const FIELDS_DATES = {
  end_date: { order: 7, validate: (value) => (minLength(1, value) || isDatetimeLocalValueInFuture(value, false)), value: '', notRequired: true, formatIn: (v) => timestampToDateInputFormat(v) },
  start_date: { order: 6, validate: (value) => (minLength(1, value) || isDatetimeLocalValueInFuture(`${value}T23:59`)), value: '', formatIn: (v) => timestampToDateInputFormat(v) },
};

const SPONSORED_BUTTONS = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
const STICKY_FOOTER_ID = 'eventFormFooter';

class EventForm extends PureComponent {
  constructor(props) {
    super(props);

    const template = props.isAdmin ? { ...FIELDS_TEMPLATE, ...FIELDS_ADMIN_DATES } : { ...FIELDS_TEMPLATE, ...FIELDS_DATES };

    this.state = {
      errors: {},
      isBusy: false,
      fields: initFields(template, this._getInitRecord()),
      workers: [],
    };

    this.addOrUpdateContent = this.addOrUpdateContent.bind(this);
    // Form helpers
    this.getErrorForField = getErrorForField.bind(this);
    this.getFieldValuesAsObject = getFieldValuesAsObject.bind(this);
    this.setIsBusy = setIsBusy.bind(this);
    this.setStateOrError = setStateOrError.bind(this);
    this.validate = validateFields.bind(this);
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    windowScrollTo(0, 0);
  }

  /**
   * Initializers
   */
  _getInitRecord() {
    if (!this._isDefaultMode()) return this.props.initRecord; // in edit mode; don't mess with existing record
    if (this.props.isAdmin) return { sponsored: true }; // admins will most likely be creating a sponsored event. Default to this.

    return null;
  }

  /**
   * Methods
   */
  addOrUpdateContent() {
    const validated = this.validate();

    if (validated) {
      this.props.saveContent(this.getFieldValuesAsObject());
    }
  }

  /**
   * Helpers
   */
  _getPreviewObject() {
    const record = this.getFieldValuesAsObject();

    return {
      ...record,
      ...record.location,
      platforms: this.state.fields.platforms.value.map((p) => ({
        id: p.value,
        name: p.label,
        url: NO_URL,
      })),
    };
  }

  _isDefaultMode() {
    return this.props.mode === 'default';
  }

  _isSponsoredEvent() {
    return this.state.fields.sponsored.value === 'true';
  }

  /**
   * Views
   */
  _getAdminPanel() {
    return (
      <div className={`${formStyles.panel} ${formStyles.panelInner}`}>
        <fieldset className={formStyles.container675}>
          <div id="vfsponsored">
            <RadioGroup
              buttons={SPONSORED_BUTTONS}
              errors={this.state.errors.sponsored}
              label="Is this a sponsored event?"
              onChange={(e) => this.setStateOrError(null, 'sponsored', e.target.value)}
              value={this.state.fields.sponsored.value}
            />
          </div>
          {this._isSponsoredEvent() && this._getAdminSponsorInputs()}
        </fieldset>
      </div>
    );
  }

  _getAdminSponsorInputs() {
    return (
      <Fragment>
        {this._getSponsorInputs()}

        <div id="vfsummary">
          <BasicFormInput
            charCount={this.state.fields.summary.value.length}
            errors={this.state.errors.summary}
            helperText="This will display underneath the event name on the large event cards."
            label="Description (optional)"
            maxVal={255}
            onChange={(e) => this.setStateOrError(maxLength(255, e.target.value), 'summary', e.target.value)}
            placeholder="30+ Speakers - Hands-on Workshops - Free Hardware*"
            value={this.state.fields.summary.value}
          />
        </div>
      </Fragment>
    );
  }

  _getSponsorInputs() {
    return (
      <Fragment>
        <div id="vfplatforms">
          <AlgoliaMultiSelect
            algoliaParameters={{ hitsPerPage: 100, initFacet: ['model:Platform'] }}
            algoliaRecordsToOptions={recordsToOptions}
            algoliaService={this.props.algoliaPlatformsService}
            errors={this.state.errors.platforms}
            helperText="Limit 5 max."
            label="Sponsors with a platform (optional)"
            onSelect={(platforms) => this.setStateOrError(null, 'platforms', platforms)}
            placeholder="Arduino, Raspberry Pi"
            selectionLimit={5}
            value={this.state.fields.platforms.value}
          />
        </div>

        <div id="vfcustom_sponsors">
          <FormSelect
            key="custom_sponsors"
            creatableOpts={{ creatable: true }}
            errors={this.state.errors.custom_sponsors}
            helperText="Limit 5 max."
            label="Sponsors without a platform (optional)"
            onSelectedChange={(opt) => this.setStateOrError(null, 'custom_sponsors', opt)}
            options={this.state.fields.custom_sponsors.value || []}
            placeholder="Arm, Not Impossible"
            searchOpts={{
              multiLimit: 5,
              rule: 'default',
            }}
            type="multi"
            value={this.state.fields.custom_sponsors.value}
          />
        </div>
      </Fragment>
    );
  }

  _getEventUserPanel() {
    return (
      <div className={`${formStyles.panel} ${layout.padding30}`}>
        <div className={formStyles.container675}>
          <h4 className={typography.h4}>
            <span>Created by: </span>
            <a className={typography.charcoal} href={this.props.initRecord.user.url} rel="noopener noreferrer" target="_blank">{this.props.initRecord.user.name}</a>
          </h4>
        </div>
      </div>
    );
  }

  render() {
    const currentRecord = this._getPreviewObject();
    const isDefaultMode = this._isDefaultMode();
    const isSponsoredEvent = this._isSponsoredEvent();

    return (
      <Fragment>
        <div className={`${layout.container} ${utilStyles.bgFog}`}>
          <div
            className={`${layout.wrapper1170} ${layout.flexColumn} ${layout.flexCenterItems}`}
          >
            <form
              className={layout.maxWidth100P}
              onSubmit={(e) => e.preventDefault}
            >
              {this.props.isAdmin
              && this.props.mode === 'edit'
              && this._getEventUserPanel()}

              {this.props.isAdmin && this._getAdminPanel()}

              <div className={`${formStyles.panel} ${formStyles.panelInner}`}>
                <fieldset className={formStyles.container675}>
                  <p className={`alert alert-info ${layout.marginBottom30}`}>
                    We welcome events that express the core mission of Hackster
                    - connecting people interested in building, sharing and
                    learning about electronics. Events that do not clearly
                    involve hardware or relevant software may be held for
                    moderation or removed.
                  </p>
                  <div id="vftitle">
                    <BasicFormInput
                      charCount={this.state.fields.title.value.length}
                      errors={this.state.errors.title}
                      label="Event Title"
                      maxVal={60}
                      onChange={(e) => this.setStateOrError(
                        maxLength(60, e.target.value),
                        'title',
                        e.target.value,
                      )}
                      placeholder="AIOT Dev Summit"
                      value={this.state.fields.title.value}
                    />
                  </div>

                  <div id="vflink">
                    <BasicFormInput
                      charCount={this.state.fields.link.value.length}
                      errors={this.state.errors.link}
                      helperText="Make sure to add the link's protocol (https:// or http://)"
                      label="Link to external event page"
                      maxVal={500}
                      onChange={(e) => this.setStateOrError(maxLength(500, e.target.value), 'link', e.target.value)}
                      placeholder="https://www.eventbrite.com"
                      value={this.state.fields.link.value}
                    />
                  </div>

                  <div id="vfstart_date">
                    <FormDateInput
                      autoComplete="off"
                      errors={this.state.errors.start_date}
                      initValue={this.state.fields.start_date.value}
                      label="Start date"
                      onChange={(val) => this.setStateOrError(null, 'start_date', val)}
                    />
                  </div>

                  <div id="vfend_date">
                    <FormDateInput
                      autoComplete="off"
                      errors={this.state.errors.end_date}
                      initValue={this.state.fields.end_date.value}
                      label="End date (optional)"
                      onChange={(val) => this.setStateOrError(null, 'end_date', val)}
                    />
                  </div>

                  <div id="vflocation">
                    <LocationSelect
                      errors={this.state.errors.location}
                      label="Location (optional)"
                      onChange={(location) => this.setStateOrError(null, 'location', location)}
                      placeholder="San Francisco, California US"
                      renderDistanceInput={false}
                      value={this.state.fields.location.value}
                    />
                  </div>

                  <div id="vfimage">
                    <FormImageUploader
                      allowGifs={false}
                      aspectRatio={(3 / 2)}
                      dimensionMins={{ width: 1110 }}
                      errors={this.state.errors.image}
                      helperText="This image will display in large cards and is used as a fallback when a mobile image is not provided. This image should have a ratio of 3:2 and be 3330x2220 (minimum 1110x740px) for the best quality."
                      imageData={this.state.fields.image.value}
                      label="Image"
                      propagateStatus={(isBusy) => this.setIsBusy(isBusy, 'image')}
                      propagateUpload={(image) => this.setStateOrError(null, 'image', image)}
                    />
                  </div>

                  <div id="vfmobile_image">
                    <FormImageUploader
                      allowGifs={false}
                      aspectRatio={(1 / 1)}
                      attachmentType="MobileCoverImage"
                      dimensionMins={{ width: 120 }}
                      errors={this.state.errors.mobile_image}
                      helperText="This image will display in mobile cards and sections where the image is a square. This image should have a ratio of 1:1 and be 360x360px (minimum 120x120px) for the best quality."
                      imageData={this.state.fields.mobile_image.value}
                      label="Mobile image (optional)"
                      propagateStatus={(isBusy) => this.setIsBusy(isBusy, 'mobile_image')}
                      propagateUpload={(image) => this.setStateOrError(null, 'mobile_image', image)}
                    />
                  </div>

                  <div id="vfevent_type">
                    <FormSelect
                      key="event_type"
                      errors={this.state.errors.event_type}
                      label="Event type"
                      onSelectedChange={(opt) => this.setStateOrError(null, 'event_type', opt)}
                      options={EVENT_TYPES_OPTS}
                      placeholder="Select event type"
                      selectOpts={{ rule: 'norule' }}
                      value={this.state.fields.event_type.value}
                    />
                  </div>
                  {(!this.props.isAdmin || (this.props.isAdmin && !isSponsoredEvent)) && this._getSponsorInputs()}
                </fieldset>
              </div>

            </form>

            <section className={`${formStyles.panel} ${layout.fullWidth}`}>
              <div className={`${formStyles.panelHeader} ${utilStyles.borderBottom}`}>
                <p className={`${inputStyles.label} ${layout.margin0}`}>
                  {`${isSponsoredEvent ? 'Sponsored event' : 'Event'} card preview`}
                </p>
              </div>
              <div className={formStyles.panelInner}>
                {isSponsoredEvent
                  ? <PreviewLayoutSponsored item={currentRecord} />
                  : <PreviewLayoutDefault item={currentRecord} />}
              </div>
            </section>
            {this.props.renderRecaptchaBadge(`${layout.fullWidth} ${layout.flexJustifyEnd} ${layout.marginBottom30}`)}
          </div>
        </div>
        <div className={`${stickyStyles.actionsBarRoot} ${utilStyles.posRelative}`} id={STICKY_FOOTER_ID}>
          <StickyWrapper
            className={`${stickyStyles.actionsBar} ${utilStyles.borderTop} ${layout.flexJustifyCenter}`}
            parentId={STICKY_FOOTER_ID}
            startPos="bottom"
            stickToEnd={false}
          >
            <div className={`${layout.wrapper1170} ${stickyStyles.actionsBarInner}`}>
              <Breadcrumb
                classList={{ root: layout.margin0, text: layout.hiddenSmallDown }}
                href={this.props.breadcrumbProps.href}
                onClick={this.props.breadcrumbProps.onClick}
                text={this.props.breadcrumbProps.text}
              />
              <h1 className={`${typography.bodyM} ${layout.margin0}`}>
                {isDefaultMode ? 'Create event' : 'Edit event'}
              </h1>
              <div>
                {!isDefaultMode
                && (
                  <Button
                    className={layout.marginRight10}
                    colorStyle="danger"
                    disabled={this.state.isBusy || this.props.isBusy}
                    onClick={this.props.onDeleteClick}
                  >
                    Delete
                  </Button>
                )}
                <ActionableButton
                  disabled={this.state.isBusy}
                  isBusy={this.props.isBusy}
                  onClick={this.addOrUpdateContent}
                  text={isDefaultMode ? `Submit ${this.props.isAdmin ? '' : 'for approval'}` : 'Update'}
                />
              </div>
            </div>
          </StickyWrapper>
        </div>
      </Fragment>
    );
  }
}

EventForm.propTypes = {
  algoliaPlatformsService: PropTypes.object.isRequired,
  breadcrumbProps: PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
  initRecord: PropTypes.shape({
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string,
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    link: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
      countryCode: PropTypes.string,
      state: PropTypes.string,
    }),
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })),
    start_date: PropTypes.string,
    title: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }),
  isAdmin: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
  mode: PropTypes.string,
  onDeleteClick: PropTypes.func,
  renderRecaptchaBadge: PropTypes.func,
  saveContent: PropTypes.func.isRequired,
};

EventForm.defaultProps = {
  breadcrumbProps: {},
  initRecord: null,
  mode: 'default',
  onDeleteClick: null,
  renderRecaptchaBadge: () => null,
};

export default EventForm;
