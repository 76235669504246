import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../../../client/reusable_components/LazyImage';

import utilStyles from '../../../../styles/global_ui/util.css';
import viewAllStyles from '../../../../client/wrappers/view_all/view_all.css';
import styles from './cta_banner.css';

const CTABanner = ({ image_url, link, title }) => (
  <div className={viewAllStyles.container}>
    <div className={styles.banner}>
      <a className={styles.imageLink} href={link}>
        <LazyImage
          alt={title}
          captureScroll={true}
          classList={{ image: utilStyles.borderRadius }}
          className={styles.image}
          ratio="4:1"
          setStyle={false}
          src={image_url}
          width={1170}
        />
      </a>
    </div>
  </div>

);

CTABanner.propTypes = {
  image_url: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
};

CTABanner.defaultProps = {
  image_url: '',
  link: '',
  title: '',
};

export default CTABanner;
