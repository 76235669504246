import React from 'react';
import PropTypes from 'prop-types';

import Sections from './sections';

const HomePage = ({ pathHelpers, renderForPreview, sections }) => (
  <div>
    <Sections pathHelpers={pathHelpers} renderForPreview={renderForPreview} sections={sections} />
  </div>
);

HomePage.propTypes = {
  pathHelpers: PropTypes.shape({
    rootPath: PropTypes.string.isRequired,
    videosPath: PropTypes.string.isRequired,
  }).isRequired,
  renderForPreview: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.shape({}), // Variable per type
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  })).isRequired,
};

HomePage.defaultProps = { renderForPreview: false };

export default HomePage;
