import { canUseDOM } from './execEnvironment';

// memoized so we don't create a dom element every time this is called
let isSupported = null;

export default function videoTagSupported() {
  if (isSupported !== null) return isSupported;

  if (!canUseDOM()) return true; // Assume true for server rendering, fallback to false if not supported in browser
  const el = document.createElement('video');
  isSupported = !!el.canPlayType;

  return isSupported;
}
