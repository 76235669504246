import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CTABanner from './cta_banner';
import HomePageBannerAd from '../../../client/ads/home_page_banner/HomePageBannerAd';
import InfiniteScroll from '../../../client/reusable_components/InfiniteScroll';
import PromotedContent from './promoted_content';
import VideoDialog from '../../../client/videos/video_dialog';
import ViewAllBuilder from '../../../services/algolia/view_all_builder';

import AdsService from '../../../services/ads';
import SeoHandler from '../../../services/seo_handler';
import errorHandler from '../../../services/error_handler';
import seoConfig from '../seoConfig';
import { fireViewAllSectionClickAnalytics } from '../../../services/keen/mainServiceOperators';
import { getWindowProperty, windowScrollTo } from '../../../services/window';

import { HOME_PAGE } from '../../../graphql/ads/enums';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import styles from './sections.css';

const PAGE_SIZE = 10;
const SECTIONS_PER_SCROLL_LOAD = 2;
const TRACKING_PAGE_TYPE = 'pages#home';

class Sections extends Component {
  constructor(props) {
    super(props);

    const { homeSections, sections, totalSections } = this._initSections(props);

    this.state = {
      additionalSections: [], // Future feature.
      ads: { banner: null },
      dialog: {
        open: false,
        data: null,
      },
      homeSections,
      sections,
      totalSections,
    };

    this.seoHandler = new SeoHandler({ config: seoConfig });
    this.adsService = new AdsService(HOME_PAGE);

    this.handleVideoDismiss = this.handleVideoDismiss.bind(this);
    this.loadMoreSectionsOnClick = this.loadMoreSectionsOnClick.bind(this);
    this.loadMoreSectionsOnScroll = this.loadMoreSectionsOnScroll.bind(this);
    this.summonVideoDialog = this.summonVideoDialog.bind(this);

    this.viewAllBuilder = new ViewAllBuilder({ type: 'home', props: { doScroll: false } }, this);

    // Refs
    this._videoDialog;
  }

  /**
   * Initializers
   */
  _initSections(props) {
    const homeSections = props.renderForPreview ? [] : props.sections.slice(0, PAGE_SIZE);
    const sections = props.renderForPreview ? props.sections : props.sections.slice(0, SECTIONS_PER_SCROLL_LOAD);
    const totalSections = props.renderForPreview ? props.sections.length : homeSections.length; // Total for infinite scroll.

    return {
      homeSections,
      sections,
      totalSections,
    };
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._fetchAds();
  }

  loadMoreSectionsOnClick() {
    const updatedHomeSections = this.props.sections.slice(0, this.state.homeSections.length + PAGE_SIZE);

    this.setState({
      homeSections: updatedHomeSections,
      totalSections: updatedHomeSections.length,
    }, () => windowScrollTo(0, (parseInt(getWindowProperty('scrollY')) + 50))); // Invoke scroll event to load more.
  }

  loadMoreSectionsOnScroll() {
    return new Promise((resolve) => {
      this.setState({ sections: this.state.homeSections.slice(0, (this.state.sections.length + SECTIONS_PER_SCROLL_LOAD)) }, () => resolve());
    });
  }

  handleVideoDismiss() {
    this.seoHandler.reportView({ path: '/' });
  }

  /* TODO: I suspect that we are overriding pageType in all these events because
   * _videoDialog._summonDialog changes the global pageType to videos#modal.
   * if we report the event before calling _summonDialog, it is a logical order of events,
   * and it should negate the issue
   */
  summonVideoDialog(video, itemIndex, sectionIndex) {
    if (this._videoDialog) {
      this._videoDialog._summonDialog(video);

      const item = { id: video.id, index: itemIndex, type: 'video_card' };
      const section = { key: 'video', value: 'uuid' };
      fireViewAllSectionClickAnalytics({ delayRedirect: false, index: sectionIndex, item, pageType: TRACKING_PAGE_TYPE, section });
    }
  }

  /**
   * Helpers
   */
  _fetchAds() {
    return this.adsService.getAdsForPage()
      .then((ads) => this.setState({ ads }))
      .catch((err) => {
        errorHandler(`HomePage Sections _fetchAds: ${err}`);
      });
  }

  _injectBannerAdInSections(sections) {
    return sections.slice(0, 2).concat([{ key: 'home_page_banner_ad', value: 0 }]).concat(sections.slice(2));
  }

  /**
   * Views
   */
  _shouldRenderMoreButton() {
    return (
      (this.state.sections.length === this.state.totalSections)
      && ((this.state.sections.length < this.props.sections.length) || (this.state.additionalSections.length > 0))
    );
  }

  _getCTABanner(section, i) {
    return (
      <CTABanner
        key={`${section.key}_${section.value}`}
        id={section.value}
        image_url={section.meta.image_url}
        link={section.meta.link}
        onClick={(e) => {
          const item = { type: 'cta_banner' };
          fireViewAllSectionClickAnalytics({ delayRedirect: true, e, index: i, item, pageType: TRACKING_PAGE_TYPE, section, url: section.meta.link });
        }}
        title={section.title}
      />
    );
  }

  _getHomePageBannerAd(section) {
    if (this.state.ads.banner === null) return null;

    return (
      <HomePageBannerAd
        key={`${section.key}_${section.value}`}
        ad={this.state.ads.banner}
      />
    );
  }

  _getPromotedContent(section, i) {
    return (
      <PromotedContent
        key={`${section.key}_${section.value}`}
        content={section.meta.content}
        onClick={(e, link, itemIndex) => {
          const item = { index: itemIndex, type: 'cta_banner' };
          fireViewAllSectionClickAnalytics({ delayRedirect: true, e, index: i, item, pageType: TRACKING_PAGE_TYPE, section, url: link });
        }}
        title={section.title}
      />
    );
  }

  _getSections(sections) {
    const sectionsWithAd = this._injectBannerAdInSections(sections);

    return sectionsWithAd.map((section, i) => {
      switch (section.key) {
        case 'cta':
          return this._getCTABanner(section, i);
        case 'home_page_banner_ad':
          return this._getHomePageBannerAd(section);
        case 'promoted':
          return this._getPromotedContent(section, i);
        default:
          return this._getViewAllList(section, i);
      }
    });
  }

  _getViewAllList(section, i) {
    return this.viewAllBuilder.getComponent(section, i, {
      reportProjectsCount: () => {},
      loadMoreSectionsOnClickdex: i,
      transition: (e, links, item = {}, events = []) => {
        // Direct to the home page when the pinned card is clicked and to the projects page when View all is clicked.
        /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
        /* eslint-disable-next-line no-prototype-builtins */
        const url = ((item.type && item.type === 'view_all_link') && links.hasOwnProperty('view_all')) ? links.view_all : links.external;
        fireViewAllSectionClickAnalytics({ delayRedirect: true, e, events, index: i, item, pageType: TRACKING_PAGE_TYPE, section, url });
      },
    }, this.state.sections);
  }

  render() {
    return (
      <div className={layout.container}>
        <InfiniteScroll
          buffer={150}
          classList={{ container: layout.wrapper1170 }}
          fetchMore={this.loadMoreSectionsOnScroll}
          recordsCount={this.state.sections.length}
          totalRecordsCount={this.state.totalSections}
        >
          {this._getSections(this.state.sections)}
          {this._shouldRenderMoreButton()
          && (
            <div className={styles.button}>
              <button
                className={`${buttonStyles.outlineBlack} ${buttonStyles.md}`}
                onClick={this.loadMoreSectionsOnClick}
              >
                Load More
              </button>
            </div>
          )}
        </InfiniteScroll>

        {this.props.renderForPreview === false
        && (
          <VideoDialog
            ref={(el) => this._videoDialog = el}
            onDismiss={this.handleVideoDismiss}
            pathHelpers={this.props.pathHelpers}
            seoHandler={this.seoHandler}
          />
        )}
      </div>
    );
  }
}

Sections.propTypes = {
  pathHelpers: PropTypes.shape({
    rootPath: PropTypes.string.isRequired,
    videosPath: PropTypes.string.isRequired,
  }).isRequired,
  renderForPreview: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.shape({}),
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  })),
};

Sections.defaultProps = {
  renderForPreview: false,
  sections: [],
};

export default Sections;
