/* TODO: This can get removed, the only NewFeatureTooltip is for sticky footer */
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import markdown from '../../utils/Markdown';

import transitions from './transitions.css';
import styles from './NewFeatureTooltip.css';

const DefaultView = ({ arrowPosition, containerStyle, closeMsg, handleClick, isVisible, msg, position, showIcon, title }) => (
  <CSSTransition classNames={transitions} timeout={250}>
    <div>
      {isVisible
      && (
        <div
          className={`${styles.container} ${position && styles[position]}`}
          style={containerStyle}
        >
          {arrowPosition !== null && position !== null
          && <div className={styles.arrow} style={{ left: arrowPosition }} />}
          <div className={styles.inner}>
            <div className={styles.msg}>
              {showIcon === true && <i className="fa fa-bullhorn" />}

              {title && <h4>{title}</h4>}

              <div dangerouslySetInnerHTML={{ __html: markdown.render(msg) }} />
            </div>
            <button
              className={styles.dismissBtn}
              onClick={handleClick}
            >
              <span>{closeMsg}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  </CSSTransition>
);

DefaultView.propTypes = {
  arrowPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closeMsg: PropTypes.string,
  containerStyle: PropTypes.shape({}),
  handleClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  msg: PropTypes.string,
  position: PropTypes.string,
  showIcon: PropTypes.bool,
  title: PropTypes.string,
};

DefaultView.defaultProps = {};

export default DefaultView;
