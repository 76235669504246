import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactCropper from 'react-cropper';

import Button from '../../../buttons/base';
import RingSpinner from '../../../spinners/ring';

import { ENTER, LEFT, RIGHT } from '../../../../constants/keyCodes';
import { windowInnerHeight, windowInnerWidth } from '../../../../services/window';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

const CONTAINER_BUFFER = {
  height: 300, // 100px action buttons + (60*2)px dialog padding + (40*2)px dialog margin
  width: 120, // (60*2)px dialog padding
};

class Cropper extends Component {
  constructor(props) {
    super(props);

    this.crop = this.crop.bind(this);
    this.handleCropperReady = this.handleCropperReady.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.rotate = this.rotate.bind(this);

    this.state = { loading: true };

    this._cropper = React.createRef();
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    if (this._cropper && this._cropper.current) {
      this._cropper.current.onerror = this.handleError;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  /**
   * Methods
   */
  crop(e) {
    e.preventDefault();
    this.props.handleImageCrop(this._cropper.current.cropper.getCroppedCanvas());
  }

  handleCropperReady() {
    this._fitContainer();
    this.setState({ loading: false });
  }

  handleError(err) {
    this.props.onError(err);
  }

  handleKeyDown(e) {
    if (e.keyCode === LEFT) {
      e.stopPropagation();
      this.rotate(-90, e);
    } else if (e.keyCode === RIGHT) {
      e.stopPropagation();
      this.rotate(90, e);
    } else if (e.keyCode === ENTER) {
      e.stopPropagation();
      this.crop(e);
    }
  }

  rotate(deg, e) {
    e && e.preventDefault();
    this._cropper.current.cropper.clear();
    this._cropper.current.cropper.rotate(deg);
    this._fitCanvas();
    this._cropper.current.cropper.crop();
  }

  /**
   * Helpers
   */
  _fitCanvas() {
    const cropper = this._cropper.current.cropper;
    const container = cropper.getContainerData();
    const canvas = cropper.getCanvasData();

    if (canvas.height >= container.height) {
      cropper.setCanvasData({ top: 0, height: cropper.getContainerData().height });
      cropper.setCanvasData({ left: Math.floor((cropper.getContainerData().width - cropper.getCanvasData().width) / 2) });
    } else if (canvas.width >= container.width) {
      cropper.setCanvasData({ left: 0, width: cropper.getContainerData().width });
      cropper.setCanvasData({ top: Math.floor((cropper.getContainerData().height - cropper.getCanvasData().height) / 2) });
    }
  }

  _fitContainer() {
    const cropper = this._cropper.current.cropper;
    if (!cropper) return;

    const containerLimits = {
      height: windowInnerHeight() - CONTAINER_BUFFER.height,
      width: windowInnerWidth() - CONTAINER_BUFFER.width,
    };

    const containerData = cropper.getContainerData();

    // scale to fit algorithm: https://stackoverflow.com/a/1373879
    const scale = Math.min(containerLimits.width / containerData.width, containerLimits.height / containerData.height);

    if (scale < 1) {
      cropper.container.setAttribute(
        'style',
        `height: ${containerData.height * scale}px; width: ${containerData.width * scale}px;`,
      );
      cropper.resize();
      cropper.clear();
      this._fitCanvas();
      cropper.crop();
    }
  }

  /**
   * Views
   */
  _getActions(basic = false) {
    return (
      <div className={`${layout.marginTop45} ${typography.textCenter}`}>
        <Button disabled={this.state.loading} onClick={this.crop} size="lg">Crop image</Button>
        {!basic && this._getRotateButtons()}
        <Button colorStyle="cancel" disabled={this.state.loading} onClick={this.props.onCancel} size="lg">
          Cancel
        </Button>
      </div>
    );
  }

  _getRotateButtons() {
    return (
      <Fragment>
        <Button className={layout.marginLeft15} colorStyle="blank" disabled={this.state.loading} onClick={(e) => this.rotate(-90, e)} size="lg">
          <i className="fa fa-rotate-left" />
        </Button>
        <Button className={layout.marginLeft15} colorStyle="blank" disabled={this.state.loading} onClick={(e) => this.rotate(90, e)} size="lg">
          <i className="fa fa-rotate-right" />
        </Button>
      </Fragment>
    );
  }

  _getSpinner() {
    return (
      <div className={`${typography.textCenter}`}>
        <span className={typography.iconBaseLineFix}><RingSpinner size="16" /></span>
        {' Loading...'}
      </div>
    );
  }

  render() {
    const options = {
      src: this.props.src,
      aspectRatio: this.props.aspectRatio,
      initialAspectRatio: this.props.aspectRatio,
      background: this.props.background,
      guides: this.props.guides,
      movable: this.props.movable,
      zoomable: this.props.zoomable,
      rotatable: this.props.rotatable,
      scalable: this.props.scalable,
      ...this.props.options,
    };

    return (
      <div>
        <ReactCropper
          ref={this._cropper}
          autoCropArea={1}
          modal={false}
          ready={this.handleCropperReady}
          viewMode={2}
          {...options}
        />
        {this.state.loading && this._getSpinner()}
        {this._getActions(this.props.renderBasicActions)}
      </div>
    );
  }
}

Cropper.propTypes = {
  aspectRatio: PropTypes.number,
  background: PropTypes.bool,
  guides: PropTypes.bool,
  handleImageCrop: PropTypes.func.isRequired,
  movable: PropTypes.bool,
  onError: PropTypes.func,
  options: PropTypes.object,
  renderBasicActions: PropTypes.bool,
  rotatable: PropTypes.bool,
  scalable: PropTypes.bool,
  src: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  zoomable: PropTypes.bool,
};

Cropper.defaultProps = {
  aspectRatio: (4 / 3),
  background: false,
  guides: false,
  movable: false,
  onError: () => {},
  options: {},
  renderBasicActions: false,
  rotatable: true,
  scalable: false,
  title: 'Crop to fit',
  zoomable: false,
};

export default Cropper;
