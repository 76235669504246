import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';

import bookmarkStore from '../../../services/bookmark_store';
import currentUserService from '../../../services/current_user';
import errorHandler from '../../../services/error_handler';
import { showDropdownOrSummonDialog, getWidgetDropdown, getPortalWidgetDropdown } from '../../bookmarks/helpers';
import { summonLoginPanel } from '../../../utility/dispatchers';

import keenService from '../../../services/keen/main';
import { getOpenedBookmarkMenuArgs } from '../../../services/keen/events/eventTypeTemplates';

import { ESC } from '../../../constants/keyCodes';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import utilStyles from '../../../styles/global_ui/util.css';

class BookmarkButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUserId: null,
      showDropdown: false,
      initialized: false,
      isBookmarked: false,
    };

    this.getTarget = this.getTarget.bind(this);
    this.handleBookmarkChange = this.handleBookmarkChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    // Memoize the parse.
    this._analyticsSrc = this._parseSourceForAnalytics(props.source);
    this._button;
    this._isMounted;
    this._updateSub;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._isMounted = true;
    this._updateSub = bookmarkStore.getChannel().subscribe('bookmark.changed', this.handleBookmarkChange);
    this._init();
  }

  componentWillUnmount() {
    if (this._updateSub) this._updateSub.unsubscribe();
    this._isMounted = false;
  }

  /**
   * Initializers
   */
  _init() {
    return Promise.all([currentUserService.getAsync('id'), bookmarkStore.initialize()])
      .then(([currentUserId, isBookmarked]) => {
        if (this._isMounted) {
          this.setState({ currentUserId, initialized: true, isBookmarked: this._getIsBookmarked() });
        }
      })
      .catch((err) => errorHandler('BookmarkButton _init', err));
  }

  _parseSourceForAnalytics(source) {
    return { widget_src: source.split('_').filter((w) => w !== 'bookmark').join('_') };
  }

  /**
   * Methods
   */
  getTarget() {
    return this._button;
  }

  handleBookmarkChange({ projectId, addOrRemoveBool }) {
    if (projectId !== this.props.projectId) return;
    if (addOrRemoveBool && !this.state.isBookmarked) return this.setState({ isBookmarked: true });
    if (!addOrRemoveBool) return this.setState({ isBookmarked: this._getIsBookmarked() });
  }

  handleClick(e) {
    if (this.state.showDropdown) return this.handleDismiss();

    if (this.state.currentUserId === null) {
      summonLoginPanel({ detail: { state: { currentPanel: 'signup' }, source: this.props.source } });
    } else {
      this._summonWidget(e);
    }
  }

  handleDismiss() {
    this.setState({ showDropdown: false });
  }

  handleKeyDown(e) {
    if (e.keyCode === ESC && this.state.showDropdown) this.handleDismiss();
  }

  /**
   * Helpers
   */
  _getIsBookmarked() {
    const lists = bookmarkStore.getLists();

    return lists.some((list) => list.project_ids.includes(this.props.projectId));
  }

  _handleOpenMenuAnalytics() {
    keenService.reportEventWithObj(getOpenedBookmarkMenuArgs({
      project_id: this.props.projectId,
      ...this._analyticsSrc,
    }));
  }

  _summonWidget() {
    const showDropdown = showDropdownOrSummonDialog({ projectId: this.props.projectId });
    this._handleOpenMenuAnalytics();
    this.setState({ showDropdown });
  }

  _getDropdown() {
    const params = {
      analytics: this._analyticsSrc,
      dismiss: this.handleDismiss,
      projectId: this.props.projectId,
      getTarget: this.getTarget,
    };

    return this.props.usePortal ? getPortalWidgetDropdown(params) : getWidgetDropdown(params);
  }

  render() {
    const { showDropdown } = this.state;

    return (
      <div className={utilStyles.posRelative} onKeyDown={this.handleKeyDown}>
        <button
          ref={(el) => this._button = el}
          className={`${buttonStyles.icon} ${buttonStyles[this.props.size]} ${buttonStyles.outlineBlack}`}
          disabled={!this.state.initialized}
          onClick={this.handleClick}
          title="bookmark"
        >
          <Icon name={this.state.isBookmarked ? 'bookmark-filled' : 'bookmark'} />
        </button>
        {showDropdown && this._getDropdown()}
      </div>
    );
  }
}

BookmarkButton.propTypes = {
  projectId: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['sm', 'md']),
  source: PropTypes.string,
  usePortal: PropTypes.bool, // uses a portal to 'break out' of containers where overflow is invisible. use only when necessary
};

BookmarkButton.defaultProps = {
  size: 'sm',
  source: 'project_card_bookmark',
  usePortal: false,
};

export default BookmarkButton;
