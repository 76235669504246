import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HomePage from '../../channel_page/home';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from '../../home_edit/home_sections/home_sections.css';

class PreviewView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._toggleBodyScroll(true);
  }

  componentWillUnmount() {
    this._toggleBodyScroll(false);
  }

  _toggleBodyScroll(addClass) {
    if (document) {
      if (addClass && !document.body.classList.contains('no-scroll')) {
        document.body.classList.add('no-scroll');
      } else if (!addClass && document.body.classList.contains('no-scroll')) {
        document.body.classList.remove('no-scroll');
      }
    }
  }

  render() {
    const channelType = this.props.channel.type.toLowerCase();

    return (
      <div className={styles.previewRoot}>
        <div className={styles.previewWrapper}>
          <div className={styles.previewHeaderFixedContainer}>
            <div className={styles.previewHeaderWrapper}>
              <div className={styles.previewHeader}>
                <h3 className={`${typography.headerLNoMarginTop} ${typography.fontWeight300}`}>
                  Preview home page
                </h3>

                <div>
                  <button
                    className={buttonStyles.lg}
                    onClick={() => this.props.toggleView('table')}
                    type="button"
                  >
                    Close preview
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.previewHomePageWrapper}>
            <HomePage
              canManage={true}
              homeSections={this.props.sections}
              path="/"
              renderForPreview={true}
              seoHandler={() => {}}
              transition={() => {}}
              viewAllBuilderArg={() => ({ type: channelType, props: { [channelType]: this.props.channel } })}
            />
          </div>
        </div>
      </div>
    );
  }
}

PreviewView.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    user_name: PropTypes.string.isRequired,
  }).isRequired, // See parent for shape.
  sections: PropTypes.array.isRequired,
  toggleView: PropTypes.func.isRequired,
};

PreviewView.defaultProps = {};

export default PreviewView;
