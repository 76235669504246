import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RespectButton from '../../../../../client/buttons/respect_button';
import ButtonDropdown from '../../../../../client/buttons/button_dropdown';
import Icon from '../../../../../client/icon';

import { NEWS_ARTICLE } from '../../../../../graphql/respects/enum.js';

import buttonStyles from '../../../../../styles/global_ui/buttons.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import styles from './sticky_actions.css';

const getSocialLink = ({ label, service, url }) => (
  <a className={`${typography.link} ${typography.bodyS} ${typography.bold}`} href={url} rel="noopener nofollow">
    <div className={styles.socialIcon}>
      {service !== 'twitter'
        ? <i className={`fa fa-${service}`} />
        : <Icon name="x" size="12" />}
    </div>
    <span>{`Share on ${label}`}</span>
  </a>
);

const stylesForPosition = (position) => (
  position === 'side' ? `${layout.marginTop10} ${styles.stickyButton}` : layout.marginLeft10
);

const ActionButtons = ({ article, disabled, position, social_sharing_links }) => (
  <Fragment>
    <RespectButton
      disabled={disabled}
      id={article.id}
      respects={article.respects_count}
      theme={position === 'side' ? 'articleSide' : 'articleBottom'}
      type={NEWS_ARTICLE}
    />
    <ButtonDropdown
      alignTop={position === 'bottom'}
      classList={{
        button: `${buttonStyles.icon} ${buttonStyles.outlineBlack} ${buttonStyles.md} ${stylesForPosition(position)}`,
        dropdown: typography.whitespaceNowrap,
      }}
      disabled={disabled}
      label={<Icon name="share-project" />}
    >
      <div className={layout.padding15}>
        {social_sharing_links.map((link) => <div key={link.service}>{getSocialLink(link)}</div>)}
      </div>
    </ButtonDropdown>
  </Fragment>
);

ActionButtons.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    respects_count: PropTypes.number.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  position: PropTypes.oneOf(['bottom', 'side']),
  social_sharing_links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

ActionButtons.defaultProps = {
  disabled: false,
  position: 'side',
  social_sharing_links: [],
};

export default ActionButtons;
