import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'draft-js';

import MarkdownViewer from '../../../markdown/markdown_viewer';

import initializeEditorState from './initializer';
// Custom Handlers
import handleBeforeInput from './modifiers/handleBeforeInput';
import handlePaste from './modifiers/handlePaste';

import styles from './rich_text_editor.css';
import buttonStyles from '../../../../styles/global_ui/buttons.css';

import draftEventProxy from './events/draftEventProxy';

class DraftEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEntity: null,
      editorState: initializeEditorState(props.initText, this),
      rawText: '',
      readOnly: false,
      view: 'default', // 'default' || 'markdown'
    };

    this.handleReturn = this.handleReturn.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.updateEditorState = this.updateEditorState.bind(this);

    // Accessors
    this.__focus = this.__focus.bind(this);
    this.__getText = this.__getText.bind(this);
    this.__getToolbar = this.__getToolbar.bind(this);
    this.__reset = this.__reset.bind(this);
    this.__setReadOnly = this.__setReadOnly.bind(this);
    this.__setStuntedEvent = this.__setStuntedEvent.bind(this);
    this.__toggleViewState = this.__toggleViewState.bind(this);

    // Flags
    this._stuntedEvent = null;
    this._isMounted;

    // Refs
    this.editor;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._isMounted = true;
    this._propagateCallbacks();
    if (this.props.focusOnMount) this.__focus();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.view !== this.state.view
      || prevState.editorState.getCurrentContent() !== this.state.editorState.getCurrentContent()) {
      this._propagateCallbacks();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Initializers
   */
  _propagateCallbacks() {
    const currentText = this.__getText();
    this.props.propagateViewState(this.state.view);
    this.props.propagateToolbar(this.__getToolbar(currentText));
    this.props.propagateText(currentText);
  }

  /**
   * Accessors
   */
  __focus() {
    if (this._isMounted && this.editor) this.editor.focus();
  }

  __getText() {
    if (this._isMounted) return this.state.editorState.getCurrentContent().getPlainText();
  }

  __getToolbar(currentText) {
    currentText = currentText || this.__getText();
    const editorHasContent = currentText.length > 0;

    return (
      <div className={styles.toolbar}>
        <button
          className={this.props.classList.markdownBtn || buttonStyles.blank}
          disabled={!editorHasContent}
          onClick={() => this.__toggleViewState()}
        >
          {this.state.view === 'default' ? 'Markdown preview' : 'Click to edit'}
        </button>
      </div>
    );
  }

  __reset() {
    if (this._isMounted) {
      return this.setState({
        editorState: initializeEditorState(null, this),
        rawText: '',
        view: 'default',
      });
    }
  }

  __setReadOnly(bool, callback) {
    this.setState({ readOnly: bool }, callback);
  }

  __setStuntedEvent(event) {
    this._stuntedEvent = event;
  }

  __toggleViewState() {
    if (this._isMounted) {
      this.state.view === 'markdown'
        ? this.setState({ view: 'default' })
        : this.setState({
          rawText: this.state.editorState.getCurrentContent().getPlainText(),
          view: 'markdown',
        });
    }
  }

  /**
   * Methods
   */
  handleReturn(e) {
    draftEventProxy.pub('return', e);

    if (this._stuntedEvent === 'return') {
      return 'handled';
    }
  }

  onBlur(e) {
    draftEventProxy.pub('blur', e);
    const editorHasContent = this.state.editorState.getCurrentContent().getPlainText().length > 0;

    if (!editorHasContent) {
      this.props.onBlur(e);
    }
  }

  updateEditorState(editorState) {
    const currentEntity = this._getCurrentEntity(editorState);
    const shouldFireEvent = currentEntity !== this.state.currentEntity;
    const shouldFireStateUpdated = editorState.getCurrentContent() !== this.state.editorState.getCurrentContent();
    const entityData = { currentEntity, previousEntity: this.state.currentEntity };

    this.setState({ editorState, currentEntity }, () => {
      if (shouldFireEvent) draftEventProxy.pub('entityChanged', entityData);
      if (shouldFireStateUpdated) draftEventProxy.pub('stateUpdated', editorState);
    });
  }

  _getCurrentEntity(editorState) {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    if (selectionState.isCollapsed() === false) return null;
    const anchorOffset = selectionState.getAnchorOffset();
    const currentBlock = contentState.getBlockForKey(selectionState.getAnchorKey());

    return currentBlock.getEntityAt(anchorOffset - 1); // Entity at char behind cursor.
  }

  render() {
    return (
      <div
        className={`
          ${styles.resetDraftGlobals}
          ${styles.root}
          ${this.props.classList.root}
          ${styles[this.props.toolbarPlacement]}
        `.trim()}
      >
        {this.state.view === 'default'
          ? (
            <Editor
              ref={(el) => this.editor = el}
              editorState={this.state.editorState}
              handleBeforeInput={handleBeforeInput.bind(this)}
              handlePastedText={handlePaste.bind(this)}
              handleReturn={this.handleReturn}
              onBlur={this.onBlur}
              onChange={this.updateEditorState}
              onDownArrow={(e) => draftEventProxy.pub('arrow_down', e)}
              onEscape={(e) => draftEventProxy.pub('escape', e)}
              onFocus={this.props.onFocus}
              onUpArrow={(e) => draftEventProxy.pub('arrow_up', e)}
              placeholder={this.props.placeholder}
              readOnly={this.state.readOnly}
              stripPastedStyles={true}
            />
            )
          : <MarkdownViewer content={this.state.rawText} markdownService={this.props.markdownService} />}
      </div>
    );
  }
}

DraftEditor.propTypes = {
  classList: PropTypes.shape({
    root: PropTypes.string,
    markdownBtn: PropTypes.string,
  }),
  config: PropTypes.shape({
    mentions: PropTypes.bool,
    tags: PropTypes.bool,
  }),
  focusOnMount: PropTypes.bool,
  handleEmbed: PropTypes.func,
  initText: PropTypes.string,
  markdownService: PropTypes.object.isRequired,
  mode: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  propagateText: PropTypes.func,
  propagateToolbar: PropTypes.func,
  propagateViewState: PropTypes.func,
  renderToolbar: PropTypes.bool,
  toolbarPlacement: PropTypes.string,
};

DraftEditor.defaultProps = {
  classList: {
    root: '',
    markdownBtn: null, // Overrides if set
  },
  config: {
    mentions: false,
    tags: false,
  },
  focusOnMount: false,
  handleEmbed: () => {},
  initText: null,
  mode: 'default',
  onBlur: () => {},
  onFocus: () => {},
  placeholder: '',
  propagateText: () => {},
  propagateToolbar: () => {},
  propagateViewState: () => {},
  renderToolbar: true,
  toolbarPlacement: 'row', // row || column
};

export default DraftEditor;
