import { clamp } from './math';

/**
 * @callback pollFn
 * @param {Function} resolve resolves the wrapping promise returned by pollWithBackoff
 * @param {Function} reject  rejects the wrapping promise returned by pollWithBackoff
 * @param {Function} retry   calls pollFn function again with a delay specified by
 *                           pollWithBackoff, or rejects if pollMax has been reached
 */

/**
 * Promise-like interface that provides a "retry" callback along with "resolve" and "reject".
 * "retry" will delay based on a backoff strategy, then call given function again,
 * or reject if pollMax has been exceeded
 * NOTE: extend this with other backoff strategies and limiting parameters as needed
 *
 * @param  {Function} pollFn          does some async action, then calls resolve, reject, or retry
 * @param  {Number}   options.pollMax maximum number of times to poll before rejecting.
 *
 * @return {Promise}                  the resolution or rejection of the polling
 */
export function pollWithBackoff(pollFn, { pollMax = 10 } = {}) {
  return new Promise((resolve, reject) => {
    const retry = (tryCount) => {
      if (tryCount >= pollMax) return reject(new Error('Exceeded poll max'));

      setTimeout(() => {
        pollFn(resolve, reject, () => retry(tryCount + 1));
      }, _getDelay(tryCount));
    };

    pollFn(resolve, reject, () => retry(1));
  });
}

// simple exponential backoff. add random jitter, etc if necessary
// default behavior: 1..10 -> [500, 1000, 2000, 4000, 8000, 16000, 32000, 60000, 60000, 60000]
function _getDelay(n, { max = 60000, min = 500, multiplier = 250 } = {}) {
  return clamp(min, (Math.pow(2, n) * multiplier), max);
}
