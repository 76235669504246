import { EditorState, Modifier } from 'draft-js';
import { appendProtocol, isValidUrl } from '../../../../../utility/links';
import entifyContent from '../transactions/entifyContent';

const MENTION_REGEX = /@[\S]+/g;

function getLastLinkFromText(text) {
  return text.split('\n').reduceRight((acc, paragraphs) => {
    if (acc !== null) return acc;

    return paragraphs.split(' ').reduceRight((accum, word) => {
      if (acc !== null) return acc;

      return isValidUrl(word) ? appendProtocol(word) : acc;
    }, null);
  }, null);
}

export default function handlePaste(text, html) {
  const editorState = this.state.editorState;
  const currentContent = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const updatedContentState = selectionState.isCollapsed()
    ? Modifier.insertText(currentContent, selectionState, text)
    : Modifier.replaceText(currentContent, selectionState, text);

  const linkToScrape = getLastLinkFromText(text);
  if (linkToScrape !== null) this.props.handleEmbed(linkToScrape);

  this.updateEditorState(
    EditorState.forceSelection(
      EditorState.push(editorState, entifyContent(updatedContentState, MENTION_REGEX, 'MENTION'), 'insert-fragment'),
      updatedContentState.getSelectionAfter(),
    ),
  );

  return 'handled';
}
