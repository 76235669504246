import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';
import Link from '../../link';
import layout from '../../../styles/global_ui/layout.css';

// Single breadcrumb with an arrow
const Breadcrumb = ({ classList, color, direction, href, onClick, size, text }) => (
  <div className={classList.root ? classList.root : layout.marginBottom30}>
    <Link bold={true} className={classList.link} color={color} href={href} onClick={onClick} size={size}>
      <span className={layout.flexCenterItems}>
        {direction === 'left' && <Icon className={classList.iconLeft || layout.marginRight15} name="arrow-left" size={12} />}
        <span className={classList.text}>{text}</span>
        {direction === 'right' && <Icon className={layout.marginLeft15} name="arrow-right" size={12} />}
      </span>
    </Link>
  </div>
);

Breadcrumb.propTypes = {
  classList: PropTypes.shape({
    link: PropTypes.string,
    root: PropTypes.string,
    text: PropTypes.string,
  }),
  color: PropTypes.oneOf(['', 'Blue', 'Charcoal', 'Pebble']),
  direction: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['S', 'M', 'L']),
  text: PropTypes.string,
};

Breadcrumb.defaultProps = {
  classList: {
    link: '',
    root: null,
    text: '',
  },
  color: '',
  direction: 'left',
  href: '',
  onClick: () => {},
  size: 'S',
  text: '',
};

export default Breadcrumb;
