import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';

import { socialLinks } from '../../../constants/links';

import typography from '../../../styles/global_ui/typography.css';
import styles from './social_icons.css';

const icons = [
  {
    name: 'newsletter',
    title: 'Sign up for our Newsletter',
    url: socialLinks.newsletter,
  },
  {
    name: 'facebook',
    url: socialLinks.facebook,
  },
  {
    name: 'instagram',
    url: socialLinks.instagram,
  },
  {
    name: 'x',
    url: socialLinks.twitter,
  },
  {
    title: 'youtube',
    name: 'youtube-2',
    url: socialLinks.youtube,
  },
  {
    name: 'linkedin',
    url: socialLinks.linkedin,
  },
];

const SocialIcons = ({ className, trackLinkClick }) => (
  <div className={className}>
    {icons.map((icon, i) => (
      <a
        key={i}
        className={`${styles.icon} ${typography.charcoal}`}
        href={icon.url}
        onClick={(e) => trackLinkClick({
          delayRedirect: false,
          type: 'social',
          value: icon.name,
        })}
        rel="noopener noreferrer"
        target="_blank"
        title={icon.title || icon.name}
      >
        <Icon className={typography.iconClickable} name={icon.name} size="16" />
      </a>
    ))}
  </div>
);

SocialIcons.propTypes = {
  className: PropTypes.string,
  trackLinkClick: PropTypes.func,
};

SocialIcons.defaultProps = {
  className: '',
  trackLinkClick: () => {},
};

export default SocialIcons;
