/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BasicFormInput from '../../../../form_components/inputs/basic_form_input';
import FormDateInput from '../../../../form_components/inputs/datetime/form_date_input';
import { timestampToDateInputFormat } from '../../../../../utility/time';

import Icon from '../../../../icon';
import Prize from './Prize';
import RankedPrizeList from './RankedPrizeList';

import { doesRecordsMatch } from '../../../../../utility/predicates';
import { getIdOrUuid } from '../../../../../utility/accessors';
import { removeFromObject } from '../../../../../utility/filters';

import { getCategoryTypeForHeader } from './helpers/templates';
import { RANKED_DEFAULT } from './helpers/constants';

import inputStyles from '../../../../../styles/global_ui/inputs.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import styles from './prize_editor.css';

class Category extends PureComponent {
  constructor(props) {
    super(props);
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._autoFocusNameInput();
  }

  /**
   * Initializers
   */
  _autoFocusNameInput() {
    const el = document.getElementById(this._getNameId());
    if (el) el.focus();
  }

  /**
   * Helpers
   */
  _getErrorsForAttr(attr) {
    return this.props.errors && this.props.errors.hasOwnProperty(attr) ? this.props.errors[attr] : null;
  }

  _getErrorForSingularPrize(prize) {
    const prizeErrors = this._getErrorsForAttr('prizes');
    const id = getIdOrUuid(prize);

    return prizeErrors && prizeErrors.hasOwnProperty(id) ? prizeErrors[id] : null;
  }

  _getIdOrUuid() {
    return this.props.id || this.props.uuid;
  }

  _getNameId() {
    return `categoryName${this._getIdOrUuid()}`;
  }

  _hasErrors() {
    return Object.keys(this.props.errors).length > 0;
  }

  _updatePrizeInPrizes(key, value, prize) {
    const prizes = this.props.prizes.map((p) => doesRecordsMatch(prize, p) ? { ...prize, [key]: value } : p);
    this.props.updateCategoryValue('prizes', prizes, this._updatePrizeErrors(key, prize));
  }

  _updateCategoryValue(key, value) {
    this.props.updateCategoryValue(key, value, this._updateCategoryErrors(key));
  }

  _updateCategoryErrors(key) {
    if (!this.props.errors || (this.props.errors && !this.props.errors.hasOwnProperty(key))) return this.props.errors;

    return removeFromObject(this.props.errors, key);
  }

  _updatePrizeErrors(key, prize) {
    if (!this.props.errors || (this.props.errors && !this.props.errors.hasOwnProperty('prizes'))) return this.props.errors;

    const id = getIdOrUuid(prize);
    if (!this.props.errors.prizes.hasOwnProperty(id)) return this.props.errors;

    const prizeError = removeFromObject(this.props.errors.prizes[id], key);
    const prizesErrors = Object.keys(prizeError).length > 0
      ? { ...this.props.errors.prizes, [id]: prizeError }
      : removeFromObject(this.props.errors.prizes, id.toString());

    return Object.keys(prizesErrors).length > 0
      ? { ...this.props.errors, prizes: prizesErrors }
      : removeFromObject(this.props.errors, 'prizes');
  }

  /**
   * Views
   */
  _getBody() {
    if (this.props.isCollapsed) return;

    const categoryId = this._getIdOrUuid();

    return (
      <div>
        {this.props.type !== RANKED_DEFAULT
        && (
          <div className={layout.marginTop30}>
            <div data-category-name={categoryId}>
              <BasicFormInput
                errors={this._getErrorsForAttr('name')}
                id={this._getNameId()}
                label="Category name (is required)"
                onChange={(e) => this._updateCategoryValue('name', e.target.value)}
                value={this.props.name}
              />
            </div>
            <BasicFormInput
              element="textarea"
              label="Category description (optional)"
              onChange={(e) => this._updateCategoryValue('description', e.target.value)}
              value={this.props.description}
            />
            <div data-category-leaderboard_url={categoryId}>
              <BasicFormInput
                errors={this._getErrorsForAttr('leaderboard_url')}
                helperText="This must be the shareable url from Airtable."
                label="Leaderboard Url (optional)"
                onChange={(e) => this._updateCategoryValue('leaderboard_url', e.target.value)}
                value={this.props.leaderboard_url}
              />
            </div>
            <div data-category-leaderboard_live_date={categoryId}>
              <FormDateInput
                autoComplete="off"
                errors={this._getErrorsForAttr('leaderboard_live_date')}
                helperText="When should the leaderboard start showing on the Submissions page?"
                id="vf-leaderboard-start-date"
                initValue={this.props.leaderboard_live_date ? timestampToDateInputFormat(this.props.leaderboard_live_date) : ''}
                label="Activate Leaderboard Date"
                onChange={(value) => this._updateCategoryValue('leaderboard_live_date', (value || undefined))}
              />
            </div>
          </div>
        )}
        {this._getPrizes()}
      </div>
    );
  }

  _getPrizes() {
    return this.props.prizes.length > 1 ? this._getRankedPrizeList() : this._getPrize();
  }

  _getPrize() {
    const prize = this.props.prizes[0];

    return (
      <Prize
        {...prize}
        allowQuantity={true}
        errors={this._getErrorForSingularPrize(prize)}
        updateAttribute={(key, value) => this._updatePrizeInPrizes(key, value, prize)}
      />
    );
  }

  _getRankedPrizeList() {
    return (
      <RankedPrizeList
        errors={this._getErrorsForAttr('prizes')}
        prizes={this.props.prizes}
        updatePrize={(key, value, prize) => this._updatePrizeInPrizes(key, value, prize)}
      />
    );
  }

  render() {
    return (
      <div className={styles.category}>
        <div
          className={`${styles.categoryHeader} ${this._hasErrors() ? styles.categoryHeaderError : ''}`}
          data-category-header={this._getIdOrUuid()}
        >
          <div className={styles.categoryHeaderText} onClick={() => this._updateCategoryValue('isCollapsed', !this.props.isCollapsed)}>
            <h3 className={`${typography.h4} ${this._hasErrors() ? typography.error : ''}`}>
              {this.props.title}
              <span> </span>
              <span className={typography.bodyS}>{getCategoryTypeForHeader(this.props.type, this.props.name)}</span>
            </h3>
            {this._hasErrors() && <span className={`${inputStyles.error} ${layout.marginLeft15}`}> This category has errors.</span>}
          </div>

          <div className={layout.flex}>
            {this.props.canDelete && <Icon className={styles.trash} name="delete" onClick={this.props.onDelete} />}
            <Icon
              className={`${styles.arrow} ${!this.props.isCollapsed ? styles.arrowSelected : ''}`}
              name="arrow-down"
              onClick={() => this._updateCategoryValue('isCollapsed', !this.props.isCollapsed)}
            />
          </div>
        </div>

        {this._getBody()}
      </div>
    );
  }
}

Category.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  description: PropTypes.string,
  errors: PropTypes.shape({
    name: PropTypes.string,
    prizes: PropTypes.shape({ [PropTypes.string]: PropTypes.shape({ [PropTypes.string]: PropTypes.string }) }),
  }),
  id: PropTypes.number,
  is_ranked: PropTypes.bool.isRequired,
  is_runner_up: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  prizes: PropTypes.arrayOf(PropTypes.shape({
    cash_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
    link: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.number,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    requires_shipping: PropTypes.bool,
    uuid: PropTypes.string,
  })).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  updateCategoryValue: PropTypes.func.isRequired,
  uuid: PropTypes.string,
};

Category.defaultProps = {
  description: null,
  errors: {},
  id: null,
  name: null,
  uuid: null,
};

export default Category;
