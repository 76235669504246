import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Pencil from '../../../client/reusable_components/icons/Pencil';
import { graphQuery } from '../../../requests/graphql';
import { round } from '../../../utility/formatters';
import layout from '../../../styles/global_ui/layout.css';
import { UserType } from './types';

import style from './style.css';

const c = { container: `${layout.flexColumn} ${layout.gutter15} ${layout.padding1015} ${style.popover_container}` };

const AdminUserModerationExtendedView = ({ userRank }) => {
  const [projects, setProjects] = useState([]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    graphQuery(`query ($author_id: Int!) {
      projects: projects(author_id: $author_id, include_deleted: true) { records { hid, id, name, one_liner  } }
      comments: comments(user_id: $author_id) { id, body, commentable_type }
    }
    `, { author_id: userRank.id })
      .then(({ projects: { records }, comments }) => {
        setProjects(records);
        setComments(comments);
      });
  }, [userRank.id]);

  const rows = [
    ['Banned words (website)', userRank.website_banned_word_score],
    ['Banned words (bio)', userRank.bio_banned_word_score],
    ['Banned words (username)', userRank.user_name_banned_word_score],
    ['Banned words (email)', userRank.email_banned_word_score],
    ['Duplicate email score', userRank.duplicate_email?.score || 0],
    ['Duplicate website score', userRank.duplicate_website?.score || 0],
    ['Sign in score', userRank.sign_in_score],
    ['Project score', userRank.total_project_score],
    ['Spammer score', userRank.spammer_score],
    ['Bio with low sign in count score', userRank.bio_with_low_sign_in_score],
    ['Unknown website score', userRank.unknown_website_score],
    ['IP score', userRank.ip_score],
    ['Rank', userRank.rank],
  ].filter(([_, value]) => value);
  return (
    <div className={c.container}>
      <table>
        <thead>
          <tr>
            <th>
              User Stats
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {rows.map(([label, value]) => (
            <tr key={label} className={style.row}>
              <td>{label}</td>
              <td>{round(value, 1)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>
              Projects (
              {projects.length}
              )
            </th>
          </tr>
        </thead>
        <tbody>
          {projects.map(({ hid, id, name, one_liner }) => (
            <tr key={id} className={style.row}>
              <td>
                <a href={`/no-user-name/${hid}`} rel="noreferrer" target="_blank">
                  {id}
                </a>
              </td>
              <td>{name}</td>
              <td>{one_liner}</td>
              <td>
                <a href={`/admin/projects/${hid}/edit`} rel="noreferrer" target="_blank">
                  <Pencil className={style.pencilIcon} />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>
              Comments (
              {comments.length}
              )
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {comments.map(({ id, body, commentable_type }) => (
            <tr key={id} className={style.row}>
              <td><a href={`/admin/${commentable_type === 'Conversation' ? 'messages' : 'comments'}?filters[user_id]=${userRank.id}`} rel="noreferrer" target="_blank">{id}</a></td>
              <td>{commentable_type}</td>
              <td>{body}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

AdminUserModerationExtendedView.propTypes = { userRank: PropTypes.shape(UserType).isRequired };

export default AdminUserModerationExtendedView;
