import buildFirstLevelAlgoliaParams from '../../query_builders/buildFirstLevelAlgoliaParams';
import buildFacetsFromQueryMap from './buildFacetsFromQueryMap';
import getSortFromQueryMap from './getSortFromQueryMap';
import fetchDataForLazyFilterParameters from './fetchDataForLazyFilterParameters';
import { doesInitFacetHavePlatform, doesQueryMapContainFeatured } from './helpers';

function prependFeaturedToPlatformFacet(initFacet, facetKey) {
  return initFacet.reduce((acc, facet) => {
    const splitFacet = typeof facet === 'string' ? facet.split(':') : [];
    if (splitFacet[0] === 'platforms.id') {
      return acc.concat(`${facetKey}:${splitFacet[1]}`);
    }
    acc.push(facet); // Honor nested arrays.

    return acc;
  }, []);
}

function processInitFacetForSort(queryMap, initFacet, sort) {
  if (doesQueryMapContainFeatured(queryMap) && doesInitFacetHavePlatform(initFacet)) {
    return sort === 'featured_platforms'
      ? prependFeaturedToPlatformFacet(initFacet, 'featured_platform.id')
      : prependFeaturedToPlatformFacet(initFacet, 'featured_platform_ids');
  } else {
    return initFacet;
  }
}

function composeQueryParameters(queryMap, initFacet, lazyData, sort) {
  return new Promise((resolve) => {
    const facetsFiltersFromQuery = buildFacetsFromQueryMap(queryMap);
    // facetFilters: [[1 OR 2 OR 3] AND otherFacets AND initFacet]
    const facetFilters = [[...lazyData], ...facetsFiltersFromQuery, ...initFacet].filter((n) => n.length > 0);
    // ANCHOR: Leaving a trace note here.
    // Default to '*' below if things break in other apps. I changed it to return 0 facets unless asked for specifically in hopes
    // to speed up the requests.
    const facets = queryMap.facets || [];

    // Temp variable.  For the AlgoliaWrapper, we're going to need to pass an optional prop to extend request constants.
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    const queryMapForFirstLevel = queryMap.hasOwnProperty('hitsPerPage') ? queryMap : { ...queryMap, hitsPerPage: 24 };

    resolve({
      facets,
      facetFilters,
      sort,
      params: buildFirstLevelAlgoliaParams(queryMapForFirstLevel),
      query: '',
    });
  });
}

export default function queryBuilder(queryMap, initFacet = []) {
  return new Promise((resolve, reject) => {
    const sort = getSortFromQueryMap(queryMap, initFacet);
    const updatedInitFacet = processInitFacetForSort(queryMap, initFacet, sort);

    return fetchDataForLazyFilterParameters(queryMap)
      .then((lazyData) => composeQueryParameters(queryMap, updatedInitFacet, lazyData, sort))
      .then((queryParams) => resolve(queryParams))
      .catch((err) => reject(err));
  });
}
