import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import Draftster from '../../components/draftster_core';

import PromiseQueue from '../../utility/promises/PromiseQueue';
import convertToJSONModel from '../draftster/convertToJSONModel';
import convertDocXToHtml from '../../services/docx_uploader';
import { getStory, processRemoteImage, uploadImageToServer } from '../draftster/helpers';
import { dispatchEvent } from '../../utility/dispatchers';
import { patchStory } from './requests';

import './story_editor.css';

const SUCCESSFUL_SAVE_MSG = 'Saved successfully.';
const DOC_X_ACCEPT = '.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const DOC_X_MSG = 'Heads up, uploading a docx file can take a bit of time and resources (when image heavy). This will OVERWRITE your entire story, so please make sure this is what you want to do. Are you sure you want to continue?';

// TODO: Make the HelpBox a Portal. It'll make things easier when these two need to talk to eachother.
class StoryEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autosaveEnabled: props.initAutoSaveEnabled,
      saving: false,
      shouldRender: false,
      uploadingDoc: false,
    };

    this.autosave = this.autosave.bind(this);
    this.handleDocXUpload = this.handleDocXUpload.bind(this);
    this.handleHelpBoxEvent = this.handleHelpBoxEvent.bind(this);
    this.handleNavClick = this.handleNavClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitComplete = this.handleSubmitComplete.bind(this);

    this.promiseQueue = new PromiseQueue();

    const debouncedAutosave = debounce(this.autosave, 3000);

    this.config = {
      className: 'story-editor',
      editorWasUpdated: () => {
        if (this.state.autosaveEnabled) {
          // Toggle the UI to saving immediately, regardless of the debounce.  We'll do the save check in
          // handle submit to avoid doing this twice.
          if (!this.state.saving) this._toggleSaveState(true);
          debouncedAutosave();
        } else {
          this._showPePanel();
        }
      },
      handleImageUpload: (image, callback) => uploadImageToServer(image)
        .then((mergedImage) => callback(null, mergedImage))
        .catch((err) => callback(err, image)),
      hideEditor: () => {
        if (window && window.location.hash !== '#story') {
          return true;
        }

        return false;
      },
      isEditorBusy: (bool) => {
        // This function is only used on paste events and images need processing. It'll get slammed when theres alot
        // of images being pasted or uploaded from a file.
        if (bool && this._isEditorBusy) return;

        this._isEditorBusy = bool;

        const containerName = this.state.autosaveEnabled ? '#story .pe-save2' : '.pe-save';
        const container = document.querySelectorAll(containerName)[0];
        if (!container) return;

        const button = container.querySelector('.pe-submit');
        if (!button) return;

        if (bool && button.innerText !== 'Uploading image') {
          button.innerText = 'Uploading image';
          button.setAttribute('disabled', true);
        } else if (!bool && button.innerText !== 'Save changes') {
          button.innerText = 'Save changes';
          button.removeAttribute('disabled');
        }
      },
      processImage: (image) => new Promise((resolve, reject) => {
        this.promiseQueue.push(processRemoteImage, [image], resolve, reject);
      }),
      setInitialContent: () => new Promise((resolve, reject) => getStory(props.projectId)
        .then((story) => {
          this._lastSavedState = story;

          return resolve(story);
        })
        .catch((err) => reject(err))),
    };

    // Refs
    this.storyEditor;
    // Flags
    this._isEditorBusy = false;
    // Cache
    this._lastSavedState = '';
  }

  componentDidMount() {
    if (!window) return;
    window.addEventListener('pe:submit', this.handleSubmit);
    window.addEventListener('StoryHelpBox', this.handleHelpBoxEvent);

    this._deferRender();
  }

  componentWillUnmount() {
    if (!window) return;
    window.removeEventListener('pe:submit', this.handleSubmit);
    window.removeEventListener('StoryHelpBox', this.handleHelpBoxEvent);
    [].slice.call(document.querySelectorAll('.pe-nav li a')).forEach((el) => {
      el.removeEventListener('click', this.handleNavClick);
    });
  }

  /**
   * Dirty race condition hack.
   * We have to wait for application.js/jQuery to load since we're nested in the tabbed nav.
   * The jQuery code executes what tab to show determined by the url hash state (i.e. #story).
   * We render components quicker than that loads, so here we're forced to wait it out.
   */
  _deferRender() {
    if (typeof document !== 'undefined') {
      document.addEventListener('DOMContentLoaded', () => {
        this.setState({ shouldRender: true });

        [].slice.call(document.querySelectorAll('.pe-nav li a')).forEach((el) => {
          el.addEventListener('click', this.handleNavClick);
        });
      });
    }
  }

  autosave() {
    if (this.storyEditor && this.storyEditor.hasUnsavedChanges()) {
      if (!this.state.saving) this._toggleSaveState(true);
      this.handleSubmit(null, false);
    }
  }

  handleDocXUpload(e) {
    const okay = window.confirm(DOC_X_MSG);
    if (!okay) {
      this._docXInput.value = '';

      return;
    }

    this.setState({ uploadingDoc: true });

    return convertDocXToHtml(e)
      .then((html) => {
        this.storyEditor.__resetEditorState(html);
        this._docXInput.value = '';
        this.setState({ uploadingDoc: false });
      })
      .catch((err) => {
        this.storyEditor.triggerMessenger('There was an error trying to upload your file.', 'error');
        this._docXInput.value = '';
        this.setState({ uploadingDoc: false });
      });
  }

  handleHelpBoxEvent(e) {
    const autosaveEnabled = e.detail.autosaveEnabled;
    this.setState({ autosaveEnabled }, () => {
      if (autosaveEnabled) {
        // When autosaveEnabled toggles to true, go ahead and autosave if there are changes. We need this to happen
        // so that editorWasUpdated will fire on the next change in the editor.
        if (this.storyEditor.hasUnsavedChanges()) this.handleSubmit(null, false);
        this._dismissPePanel();
      }
    });
  }

  handleNavClick(e) {
    if (window.location.hash === '#story' && (this.state.saving || this.storyEditor.hasUnsavedChanges())) {
      const c = confirm('There are unsaved changes\nAre you sure you want to move away?');
      if (c) {
        this.handleSubmitComplete(false);
        this.storyEditor.__resetEditorState(this._lastSavedState);
      } else {
        e.preventDefault();
      }
    }
  }

  handleSubmit(evt, showMessage = true) {
    if (!this.state.saving) this._toggleSaveState(true);
    const content = this.storyEditor.getEditorContent();

    return convertToJSONModel(content)
      .then((json) => {
        if (document) {
          const panel = document.querySelector('.pe-panel');
          if (panel) panel.dispatchEvent(new CustomEvent('dismiss'));
        }

        return Promise.all([json, patchStory(this.props.projectHid, json)]);
      })
      .then(([json]) => {
        this._lastSavedState = json;
        this.handleSubmitComplete(showMessage);
      })
      .catch((err) => {
        this._toggleSaveState(false);
        this.storyEditor.triggerMessenger("Oops, your project didn't save correctly. Please check for errors and try again.", 'error');
      });
  }

  handleSubmitComplete(showMessage) {
    this._toggleSaveState(false);
    this._dismissPePanel();
    this._serializeJQueryForm();
    if (showMessage) this.storyEditor.triggerMessenger(SUCCESSFUL_SAVE_MSG, 'success');
  }

  _dismissPePanel() {
    if ((window && window.pe && window.pe.isSavePanelShown())
      && (window.location.hash === '#story')) {
      window.pe.hideSavePanel();
    }
  }

  _showPePanel() {
    if ((window && window.pe && window.pe.isSavePanelShown() === false)
      && (typeof window.$serializedForm !== 'undefined')
      && (window.location.hash === '#story')
      && (this.state.saving === false)) {
      window.$serializedForm += ' ';
      window.pe.showSavePanel();
    }
  }

  _serializeJQueryForm() {
    // Reserialize the form so pe && jQuery know we have NO unsaved changes (Unsaved changes popup).
    if ((window && window.pe)
      && (typeof window.$serializedForm !== 'undefined')
      && (window.location.hash === '#story')) {
      window.pe.serializeForm();
      window.pe.updateChecklist();
    }
  }

  _toggleSaveState(isSaving) {
    if (isSaving) {
      this._togglePeSaveState(true);
      this.setState({ saving: true }, () => dispatchEvent('StoryEditorRequest', { isEditorBusy: true }));
    } else {
      this._togglePeSaveState(false);
      this.setState({ saving: false }, () => dispatchEvent('StoryEditorRequest', { isEditorBusy: false }));
    }
  }

  _togglePeSaveState(bool) {
    if (this.state.autosaveEnabled) {
      const container = document.querySelectorAll('#story .pe-save2')[0];
      if (!container) return;

      const button = container.querySelector('.pe-submit');
      if (!button) return;

      if (bool) {
        button.innerText = 'Saving...';
        button.setAttribute('disabled', true);
      } else {
        button.innerText = 'Save changes';
        button.removeAttribute('disabled');
      }
    }
  }

  render() {
    if (!this.state.shouldRender) return null;

    return (
      <div>
        <input
          ref={(el) => this._docXInput = el}
          accept={DOC_X_ACCEPT}
          id="docXFileInput"
          onChange={this.handleDocXUpload}
          style={{ display: 'none' }}
          type="file"
        />
        <Draftster ref={(el) => this.storyEditor = el} config={this.config} />
      </div>
    );
  }
}

StoryEditor.propTypes = {
  initAutoSaveEnabled: PropTypes.bool,
  projectHid: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
};

StoryEditor.defaultProps = { initAutoSaveEnabled: true };

export default StoryEditor;
