import React from 'react';
import PropTypes from 'prop-types';

import ProjectThumb from './ProjectThumb';

import layout from '../../../styles/global_ui/layout.css';

const PROJECT_LIMIT = 3;

const ProjectThumbs = ({ count, images }) => (
  <div className={`${layout.flexJustifyCenter} ${layout.flex10Auto}`}>
    {Array.from(Array(PROJECT_LIMIT).keys()).map((i) => (
      <ProjectThumb key={i} isViewed={i < count} position={i + 1} src={images[i]} />
    ))}
  </div>
);

ProjectThumbs.propTypes = {
  count: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.string),
};

ProjectThumbs.defaultProps = {
  count: 0,
  images: [],
};

export default ProjectThumbs;
