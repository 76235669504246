import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormSelect from '../../../client/form_components/selects/form_select';

/**
 * Note:
 * This Component can be merged with AlogliaAsyncSelect. The only difference is that one does a request for projects count
 * for a facet. Instead on AlgoliaAsyncSelect, we can extact that logic to a request/resolver off a prop.
 */
class AlgoliaMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.fetchOptions = this.fetchOptions.bind(this);
    this._isMounted;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchOptions(queryString) {
    const { hitsPerPage, initFacet } = this.props.algoliaParameters;

    return new Promise((resolve, reject) => this.props.algoliaService.searchWithQueryString(queryString, { hitsPerPage }, initFacet)
      .then((algoliaServiceQuery) => {
        this.props.propagateCurrentQuery(algoliaServiceQuery);
        resolve({ options: this.props.algoliaRecordsToOptions(algoliaServiceQuery.hits) });
      })
      .catch((err) => reject(err)));
  }

  _getErrors(errors) {
    // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
    // eslint-disable-next-line @stylistic/no-mixed-operators
    if (!errors || errors && errors.length === 0) return null;
    if (Array.isArray(errors)) return errors.join(', ');

    return errors; // Should be a string
  }

  render() {
    return (
      <FormSelect
        asyncOpts={{
          initOnMount: true,
          request: this.fetchOptions,
        }}
        errors={this._getErrors(this.props.errors)}
        helperText={this.props.helperText}
        label={this.props.label}
        maxWidth={this.props.maxWidth}
        onSelectedChange={this.props.onSelect}
        placeholder={this.props.placeholder}
        searchOpts={{
          multiLimit: this.props.selectionLimit,
          rule: 'default',
        }}
        type="multi"
        value={this.props.value}
      />
    );
  }
}

AlgoliaMultiSelect.propTypes = {
  algoliaParameters: PropTypes.shape({
    hitsPerPage: PropTypes.number,
    initFacet: PropTypes.array,
  }),
  algoliaRecordsToOptions: PropTypes.func.isRequired,
  algoliaService: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  helperText: PropTypes.string,
  label: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  propagateCurrentQuery: PropTypes.func,
  selectionLimit: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })),
};

AlgoliaMultiSelect.defaultProps = {
  algoliaParameters: {
    hitsPerPage: 100,
    initFacet: [],
  },
  errors: null,
  helperText: '',
  label: '',
  maxWidth: '100%',
  placeholder: 'Select an option from the dropdown or type to search',
  propagateCurrentQuery: () => {},
  selectionLimit: 3,
  value: [],
};

export default AlgoliaMultiSelect;
