import React from 'react';
import PropTypes from 'prop-types';
import animation from '../../../styles/animation.css';

const ButtonLoader = ({ height, width }) => (
  <div className={animation.loader} style={{ height, width }} />
);

ButtonLoader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ButtonLoader.defaultProps = {
  height: 41,
  width: '100%',
};

export default ButtonLoader;
