import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmptyViewAll from '../../wrappers/view_all/EmptyViewAll';
import ViewAllWrapper from '../../wrappers/view_all';
import ViewAllList from '../../wrappers/view_all/ViewAllList';

import { buildPaginatedQuery } from '../../../services/algolia/view_all_builder/helpers';
import { setTimeoutPromise } from '../../../utility/promises';
import errorHandler from '../../../services/error_handler';

/**
 * Generic Algolia View All List
 * When we want to hit one algolia index and render a dynamic list of results for a view all section.
 */
class AlgoliaViewAllList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQuery: {},
      initialized: false,
      isFetching: true,
      records: [],
    };

    this.fetchMore = this.fetchMore.bind(this);
    this._isMounted;
  }

  componentDidMount() {
    this._isMounted = true;
    this._initialize();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _initialize() {
    return this.props.algoliaService.searchWithNoEffects(this.props.queryMap, this.props.initFacet)
      .then((currentQuery) => setTimeoutPromise(250, null, null, currentQuery))
      .then((currentQuery) => {
        if (!this._isMounted) return;

        this.setState({
          currentQuery,
          initialized: true,
          isFetching: false,
          records: this._orderRecordsBy(this.props.section, currentQuery.hits),
        });
        this.props.reportProjectsCount(currentQuery.hits.length, this.props.index);
      })
      .catch((err) => errorHandler('AlgoliaViewAllList _initialize', err));
  }

  _orderRecordsBy(section, hits) {
    switch (section.key) {
      case 'video':
        return this._orderVideoHitsBySection(section, hits);

      default:
        return hits;
    }
  }

  _orderVideoHitsBySection(section, hits) {
    const ids = section.meta.content;

    return ids.map((id) => hits.find((hit) => hit.id === id)).filter((hit) => hit);
  }

  _shouldRender() {
    return this.state.initialized && this.state.currentQuery.hits && this.state.currentQuery.hits.length > 0;
  }

  _shouldRenderLoader() {
    return !this.state.initialized && this.state.isFetching;
  }

  fetchMore() {
    const query = buildPaginatedQuery({ queryMap: this.props.queryMap, pagination: this.props.pagination, currentQuery: this.state.currentQuery });

    return this.props.algoliaService.searchWithNoEffects(query, this.props.initFacet, this.state.records.length)
      .then((currentQuery) => {
        if (!this._isMounted) return;

        const records = this.state.records.concat(currentQuery.hits);

        this.setState({
          currentQuery,
          records,
        });

        this.props.reportProjectsCount(records.length, this.props.index);
      })
      .catch((err) => errorHandler('AlgoliaViewAllList fetchMore', err));
  }

  /**
   * Views
   */
  _getLoaderView() {
    return (
      <ViewAllWrapper
        links={this.props.viewAllLinksComposer()}
        onClick={this.props.transition}
        title={this.props.title}
      >
        {this.props.loaderCardFn()}
      </ViewAllWrapper>
    );
  }

  _getNoHitsView() {
    return this.props.userCanManage ? this._managerView() : null;
  }

  _managerView() {
    return <EmptyViewAll title={this.props.title} />;
  }

  render() {
    if (this._shouldRenderLoader()) return this._getLoaderView();
    if (!this._shouldRender()) return this._getNoHitsView();

    return (
      <ViewAllList
        cardTemplateFn={this.props.cardTemplateFn}
        fetchMore={this.fetchMore}
        links={this.props.viewAllLinksComposer()}
        onClick={this.props.transition}
        records={this.state.records}
        scrollDistance={this.props.scrollDistance}
        sectionIndex={this.props.sectionIndex}
        title={this.props.title}
        totalRecordsCount={Math.min(this.state.currentQuery.nbHits || 0, this.props.pagination.maxCardCount)}
      />
    );
  }
}

AlgoliaViewAllList.propTypes = {
  algoliaService: PropTypes.object.isRequired,
  cardTemplateFn: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  initFacet: PropTypes.array,
  loaderCardFn: PropTypes.func,
  pagination: PropTypes.shape({
    maxCardCount: PropTypes.number,
    type: PropTypes.string,
    length: PropTypes.number,
  }).isRequired,
  queryMap: PropTypes.object,
  reportProjectsCount: PropTypes.func.isRequired, // TODO: take the "projects" out of this
  scrollDistance: PropTypes.number,
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number,
  title: PropTypes.string.isRequired,
  transition: PropTypes.func,
  userCanManage: PropTypes.bool,
  viewAllLinksComposer: PropTypes.func.isRequired,
};

AlgoliaViewAllList.defaultProps = {
  initFacet: [],
  queryMap: {},
  loaderCardFn: () => (<div />),
  scrollDistance: 540,
  sectionIndex: null,
  transition: () => {},
  userCanManage: null,
};

export default AlgoliaViewAllList;
