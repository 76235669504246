import React from 'react';

import BasicUploaderView from './BasicUploaderView';
import UploaderWrapper from '../UploaderWrapper';

const renderView = (props) => (<BasicUploaderView {...props} />);

const BasicImageUploader = (props) => (<UploaderWrapper {...props} renderView={renderView} />);

export default BasicImageUploader;
