import React from 'react';
import PropTypes from 'prop-types';

import DefaultView from './PromotedContentDefaultView';
import MobileView from './PromotedContentMobileView';

import viewAllStyles from '../../../../client/wrappers/view_all/view_all.css';
import styles from './promoted_content.css';

const getTitle = ({ renderForPreview, title }) => renderForPreview ? null : titleView(title);

const titleView = (title) => (
  <div className={`${viewAllStyles.header} ${styles.titleWrapper}`}>
    <p className={viewAllStyles.title}>{title}</p>
  </div>
);

const PromotedContent = ({ content, onClick, renderForPreview, title }) => (
  <div className={viewAllStyles.container}>
    {getTitle({ renderForPreview, title })}
    <div>
      <DefaultView content={content.slice(0, 4)} onClick={onClick} renderForPreview={renderForPreview} />
      <MobileView content={content.slice(0, 4)} onClick={onClick} renderForPreview={renderForPreview} />
    </div>
  </div>
);

PromotedContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func,
  renderForPreview: PropTypes.bool,
  title: PropTypes.string,
};

PromotedContent.defaultProps = {
  onClick: () => {},
  renderForPreview: false,
  title: null,
};

export default PromotedContent;
