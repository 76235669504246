import React from 'react';
import PropTypes from 'prop-types';
import { isBlank } from '../../../../utility/types';
import inputStyles from '../../../../styles/global_ui/inputs.css';

/**
 * Note: July 2018
 * Theres another Component thats called FormGroup which may be confusing. Use this wrapper for any new components and update
 * older stuff with this one. Remove this note when the other FormGroup is removed.
 */

const getCharCountView = (charCount, value, maxVal = 'You forgot to pass a maxVal prop!') => {
  if (typeof charCount !== 'number' && !charCount) return null;
  if (typeof charCount === 'function') return charCount({ value, maxVal });

  return (
    <div className={`${inputStyles.charCount} ${value.length > maxVal ? inputStyles.error : ''}`}>
      {`${value.length}/${maxVal}`}
    </div>
  );
};

const getErrorsView = (errors, errorsView) => {
  if (!errors) return null;
  if (typeof errorsView === 'function') return errorsView({ errors });

  return (
    <div className={inputStyles.error}>{errors}</div>
  );
};

const getUuid = ({ label, name, uuid }) => {
  if (typeof uuid === 'string' && uuid.length > 0) return uuid;

  const base = [label, name].filter((v) => v && v !== '').join('_');

  return base.toLowerCase().replace(/[^a-z0-9]/g, '_');
};

const getMsgView = ({ element, errors, errorsView, charCount, helperText, maxVal, msgView, value, warningText }) => {
  if (typeof msgView === 'function') return msgView({ errors, value, maxVal });

  return (
    <div className={inputStyles.msgWrapper}>
      <div>
        {getErrorsView(errors, errorsView)}
        {isBlank(errors) && warningText && <div className={inputStyles.warning}>{warningText}</div>}
        {helperText && <div className={inputStyles.help}>{helperText}</div>}
      </div>
      {getCharCountView(charCount, value, maxVal)}
    </div>
  );
};

const FormGroup = (props) => (
  <div className={`${inputStyles.inputWrapper} ${props.classList.root}`}>
    {props.label
    && (
      <label
        className={`${inputStyles.label} ${props.classList.label ? props.classList.label : ''}`}
        htmlFor={getUuid(props)}
      >
        {props.label}
      </label>
    )}
    {props.children}
    {getMsgView(props)}
  </div>
);

FormGroup.propTypes = {
  charCount: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  classList: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
  }),
  errors: PropTypes.string,
  errorsView: PropTypes.func,
  helperText: PropTypes.node,
  label: PropTypes.node,
  maxVal: PropTypes.number,
  msgView: PropTypes.func,
  name: PropTypes.string,
  uuid: PropTypes.string,
  value: PropTypes.string.isRequired,
  warningText: PropTypes.string,
};

FormGroup.defaultProps = {
  charCount: null,
  classList: {
    root: '',
    label: '',
  },
  errors: null,
  errorsView: null,
  helperText: null,
  label: null,
  maxVal: null,
  msgView: null,
  name: null,
  uuid: null,
  warningText: null,
};

export default FormGroup;
