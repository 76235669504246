import React from 'react';
import PropTypes from 'prop-types';

import AsyncCheckbox from '../../form_components/checkboxes/async';

import typography from '../../../styles/global_ui/typography.css';
import styles from '../bookmarks.css';

const BookmarkListToggles = ({ handleCheckboxChange, currentListId, lists, projectId }) => (
  <ul className={styles.listToggles}>
    {lists.map((list) => (
      <li key={list.id} className={styles.listToggle}>
        <AsyncCheckbox
          checkboxPosition="left"
          classList={{ label: `${typography.bold} ${list.id === currentListId ? typography.asphalt : ''}` }}
          disabled={list.id === currentListId}
          initChecked={list.project_ids.includes(projectId)}
          label={list.name}
          request={handleCheckboxChange}
          requestArgs={(checked) => ({ addOrRemoveBool: checked, list })}
        />
      </li>
    ))}
  </ul>
);

BookmarkListToggles.propTypes = {
  currentListId: PropTypes.number,
  handleCheckboxChange: PropTypes.func.isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    private: PropTypes.bool.isRequired,
    project_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  })),
  projectId: PropTypes.number.isRequired,
};

BookmarkListToggles.defaultProps = {
  currentListId: null,
  lists: [],
};

export default BookmarkListToggles;
