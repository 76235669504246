import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../form_components/checkboxes/custom';
import TriangleDown from '../../reusable_components/icons/TriangleDown';

import layout from '../../../styles/global_ui/layout.css';
import styles from '../../../styles/global_ui/table.css';

const SelectableHeader = ({ config, header, isSelected, onCheck, position, setHeaderActive }) => {
  const getOrderableTitleStyle = () => header.status.isActive ? styles.thActive : header.orderable ? styles.thInteractive : '';

  const handleClick = () => {
    if (header.orderable) setHeaderActive(header, position);
  };

  return (
    <th className={styles.thSelectableCell} style={config}>
      <div className={layout.flex}>
        <Checkbox isChecked={isSelected} onChange={onCheck} />

        <div
          className={`${styles.thTitleWrapper} ${getOrderableTitleStyle()} ${layout.marginLeft15}`}
          onClick={() => handleClick()}
        >
          <span className={styles.thTitle}>{header.title}</span>
          <span className={styles.triangleWrapper}>
            {header.orderable
            && <TriangleDown className={`${styles.triangle} ${header.status.state === 1 ? styles.triangleActive : ''}`} />}
          </span>
        </div>
      </div>
    </th>
  );
};

SelectableHeader.propTypes = {
  config: PropTypes.shape({ width: PropTypes.number }),
  header: PropTypes.shape({
    orderable: PropTypes.bool.isRequired,
    sort: PropTypes.string,
    status: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
      state: PropTypes.number.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  setHeaderActive: PropTypes.func.isRequired,
};

SelectableHeader.defaultProps = { config: {} };

export default SelectableHeader;
