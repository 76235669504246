import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class GenericPortal extends PureComponent {
  constructor(props) {
    super(props);
    this._el;
  }

  componentDidMount() {
    this._el = document.getElementById(this.props.id);
    this.forceUpdate();
  }

  render() {
    if (!this._el) return null;

    return ReactDOM.createPortal(this.props.children, this._el);
  }
}

GenericPortal.propTypes = { id: PropTypes.string.isRequired };

export default GenericPortal;
