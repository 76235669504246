import request from 'superagent';

import uploadFile from '../../services/file_upload';
import { dataURIToBlob } from '../utils/Images';
import { postRemoteURL, pollJob } from '../../requests/attachments';
import OAuth from '../../services/oauth';
import { parseDescription } from './parsers/description';
import { getAWSResources, getImageDimsFromUrl } from '../../utility/images';

const isUrlADataUrl = (image) => image.url.split(',')[0].indexOf('base64') !== -1;

const postAndProcessImageUrl = (image) => new Promise((resolve, reject) => postRemoteURL(image.url, 'image')
  .then((body) => Promise.all([pollJob(body['job_id']), Promise.resolve({ ...image, id: body.id })]))
  .then((results) => resolve(results[1]))
  .catch((err) => reject(err)));

const processDataUrlAndUpload = (image) => new Promise((resolve, reject) => dataURIToBlob(image.url)
  .then((a, b) => uploadImageToServer({ ...image, rawFile: a }))
  .then((image) => Promise.all([image, getImageDimsFromUrl(image.url)]))
  .then(([image, dims]) => ({ ...image, ...dims }))
  .then((image) => resolve(image))
  .catch((err) => reject(err)));

export function getStory(projectId) {
  return OAuth.apiRequest(
    request(`${OAuth.getApiPath()}/private/projects/${projectId}/description`),
    true,
  )
    .then((body) => {
      if (!body) return Promise.reject('Error fetching story!');
      if (body.description !== null) {
        return !body.description.length
          ? Promise.resolve('')
          : parseDescription(body.description, { initialParse: true })
            .then((parsed) => Promise.resolve(parsed))
            .catch((err) => Promise.reject(err));
      } else if (body.story !== null) {
        return Promise.resolve(body.story.length ? body.story : '');
      }
    })
    .catch((err) => Promise.reject('Error fetching story!'));
}

export function uploadImageToServer(image) {
  const { S3BucketURL } = getAWSResources();

  return new Promise((resolve, reject) => uploadFile(image.rawFile, { S3BucketURL })
    .then((id) => resolve({ ...image, id: id }))
    .catch((err) => reject(err)));
}

export function processRemoteImage(image) {
  return isUrlADataUrl(image) ? processDataUrlAndUpload(image) : postAndProcessImageUrl(image);
}
