import { prepareModelForDraftsterInstance, processRemoteImage, uploadImageToServer } from '../../../../client/draftster';

export default function createDraftsterConfig({ initContent, isEditorWorking, onEditorUpdate }) {
  return {
    className: 'story-editor',

    // The editor will fire this to report newly made changes since the last save. (By init content or getEditorContent)
    editorWasUpdated() {
      onEditorUpdate();
    },

    handleImageUpload(image, callback) {
      return uploadImageToServer(image)
        .then((mergedImage) => callback(null, mergedImage))
        .catch((err) => callback(err, image));
    },

    // This is specific to image uploads in Draftster. It will get called on any image upload or paste event when
    // images exist in the buffer.
    isEditorBusy(bool) {
      isEditorWorking(bool);
    },

    processImage(image) {
      return processRemoteImage(image);
    },

    setInitialContent() {
      return prepareModelForDraftsterInstance(initContent);
    },
  };
}
