import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/index';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';

import styles from './accordion.css';

const c = {
  details: `${util.bgWhite} ${layout.fullWidth} ${util.dropShadow} ${typography.charcoal} ${styles.details}`,
  summary: `${layout.flexJustifySpaceBetween} ${layout.flexCenterItems} ${typography.bold} ${styles.summary}`,
  marker: styles.marker,
  content: styles.content,
};

const handleToggle = ({ target }) => {
  if (!target.open) return;
  if (!target.dataset.toggleGroup) return;

  const otherToggles = document.querySelectorAll(`[data-toggle-group="${target.dataset.toggleGroup}"]`);

  Array.from(otherToggles).forEach((node) => {
    if (node === target) return;

    node.open = false;
  });

  target.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
};

const Accordion = ({ title, children, classList, group }) => (
  <details className={`${c.details} ${classList?.root}`} data-toggle-group={group} onToggle={handleToggle}>
    <summary className={`${c.summary} ${classList?.summary}`}>
      {title}
      <Icon className={c.marker} name="arrow-down" size="16" />
    </summary>
    <div className={`${c.content} ${classList?.content}`}>{children}</div>
  </details>
);

Accordion.propTypes = {
  classList: PropTypes.shape({ content: PropTypes.string, root: PropTypes.string, summary: PropTypes.string }),
  group: PropTypes.string,
  title: PropTypes.node,
};

Accordion.defaultProps = {
  group: null,
  title: null,
};

export default Accordion;
