/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import CustomEvent from './events/CustomEvent';
import PageView from './events/PageView';
import Session from './events/Session';

import SessionHandler from './session_handler';
import errorHandler from '../error_handler';
import isCrawler from '../../utility/isCrawler';
import { getInObjWithPrototypeAccess } from '../../utility/accessors';
import { getUTMParams } from './utm_handler';
import { isValidEventName } from './events/eventTypes';
import { postSingleEvent } from './requests';
import { isWindowDefined, windowLocationRedirect } from '../window';

export default class KeenService {
  constructor() {
    this.currentUserId = this._getUserId();
    this.sessionHandler = new SessionHandler();
    this.whitelabel = this._getWhitelabel();
  }

  recordEvent({ pageType, eventName } = {}, customProps = {}) {
    return new Promise((resolve, reject) => {
      if (isCrawler() || !this._hasKeenKeys() || !isValidEventName(eventName)) return resolve();

      pageType = pageType || this._getPageType();
      if (!pageType) return reject(new Error(`recordEvent expected a pageType, but received: ${pageType}`));

      const { createNewSession, distinctId, sessionId, utmParams } = this._getEventData();

      const event = new CustomEvent({
        customProps,
        distinctId,
        eventName,
        pageType,
        sessionId,
        userId: this.currentUserId,
        utmParams,
        whitelabel: this.whitelabel,
      });

      return Promise.all([
        postSingleEvent(event.createRecord(), 'events'),
        this.recordNewSession({ createNewSession, distinctId, sessionId, pageType, utmParams }),
      ])
        .then(() => resolve())
        .catch((err) => {
          errorHandler('KeenService recordEvent: ', err);

          return reject(err);
        });
    });
  }

  recordEventWithDelayedRedirect({ pageType, eventName } = {}, customProps = {}, url, e) {
    // If this is a normal click (not right click nor CTRL/CMD+click), we
    // postpone it until the event has been recorded.
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();

      return this._recordEventAndRedirect({ eventName, pageType }, customProps, url);
    } else {
      return this.recordEvent({ eventName, pageType }, customProps);
    }
  }

  recordEventsWithDelayedRedirect(events, url, e) {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();

      return this._recordEventsAndRedirect(events, url);
    } else {
      return this.recordEvents(events);
    }
  }

  // TODO: convert this function to only use the eventTypeTemplate object shape
  // ALSO TODO: it would be cool if we could send all events in one request
  recordEvents(events) {
    return new Promise((resolve, reject) => {
      const promises = events.map((obj) => this._normalizeObj(obj)).map(({ eventName, pageType, customProps }) => (
        this.recordEvent({ eventName, pageType }, customProps)
      ));

      return Promise.all(promises)
        .then(() => resolve())
        .catch((err) => {
          errorHandler('KeenService recordEvents: ', err);

          return reject(err);
        });
    });
  }

  // TODO: this could be refactored to use eventTypeTemplates#getClickedLinkArgs
  recordLinkClick({ eventName = 'Clicked link', pageType } = {}, customProps = {}, delayRedirect = false, e) {
    const href = customProps.href || getInObjWithPrototypeAccess(['target', 'href'], e);
    const value = customProps.value || getInObjWithPrototypeAccess(['target', 'textContent'], e) || getInObjWithPrototypeAccess(['target', 'innerText'], e);

    const newProps = {
      ...customProps,
      href,
      value,
    };

    return delayRedirect && href
      ? this.recordEventWithDelayedRedirect({ eventName, pageType }, newProps, href, e)
      : this.recordEvent({ eventName, pageType }, newProps);
  }

  recordPageView(props = {}) {
    return new Promise((resolve, reject) => {
      if (isCrawler() || !this._hasKeenKeys()) return resolve();

      const entity_id = props.entity_id || this._getEntityId();
      const pageType = props.pageType || this._getPageType();

      if (!pageType) return reject(new Error(`recordPageView expected a pageType, but received: ${pageType}`));
      const { createNewSession, distinctId, sessionId, utmParams } = this._getEventData();

      const pageView = new PageView({
        distinctId,
        entity_id,
        pageType,
        referrer: props.referrer,
        sessionId,
        userId: this.currentUserId,
        utmParams,
        whitelabel: this.whitelabel,
      });

      return Promise.all([
        postSingleEvent(pageView.createRecord(), 'page_views'),
        this.recordNewSession({ createNewSession, distinctId, sessionId, pageType, utmParams }),
      ])
        .then(() => resolve())
        .catch((err) => {
          errorHandler('KeenService recordPageView: ', err);

          return reject(err);
        });
    });
  }

  recordNewSession({ createNewSession, distinctId, sessionId, pageType, utmParams }) {
    return new Promise((resolve, reject) => {
      if (!createNewSession || isCrawler() || !this._hasKeenKeys()) return resolve();

      pageType = pageType || this._getPageType();

      if (!pageType) return reject(new Error(`recordNewSession expected a pageType, but received: ${pageType}`));

      const session = new Session({
        distinctId,
        sessionId,
        pageType,
        whitelabel: this.whitelabel,
        utmParams,
        userId: this.currentUserId,
      });

      return postSingleEvent(session.createRecord(), 'sessions')
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  _getEntityId() {
    if (!isWindowDefined) return null;

    return window.HAnalyticsGlobalData && window.HAnalyticsGlobalData.hasOwnProperty('entity_id')
      ? window.HAnalyticsGlobalData.entity_id
      : null;
  }

  _getEventData() {
    const { createNewSession, data } = this.sessionHandler.getSessionData();
    const { distinctId, sessionId } = data;
    const utmParams = getUTMParams(window.location.search, createNewSession);

    return { createNewSession, distinctId, sessionId, utmParams };
  }

  _getPageType() {
    if (typeof document !== 'undefined' && typeof document.querySelector === 'function') {
      const meta = document.querySelector('meta[name="pageType"]');

      return meta && meta.hasAttribute('content') ? this._transformPageType(meta.getAttribute('content')) : null;
    }

    return null;
  }

  _getUserId() {
    if (!isWindowDefined) return null;

    return window.HAnalyticsGlobalData && window.HAnalyticsGlobalData.hasOwnProperty('user_id')
      ? parseInt(window.HAnalyticsGlobalData.user_id)
      : null;
  }

  _getWhitelabel() {
    if (!isWindowDefined) return null;

    return window.HAnalyticsGlobalData && window.HAnalyticsGlobalData.hasOwnProperty('whitelabel')
      ? window.HAnalyticsGlobalData.whitelabel
      : null;
  }

  /* convert from new eventTypeTemplate shape for use in recordEvents */
  _eventObjTransform({ customProps = {}, event = {} } = {}) {
    return { customProps, eventName: event.eventName, pageType: event.pageType };
  }

  _hasKeenKeys() {
    if (!isWindowDefined) return null;

    return !!(window && window.jsk && window.jsk.oht && window.jsk.oak);
  }

  /**
   * During this time of transition, recordEvents should accept event objs in either of the following shapes:
   * {eventName, pageType, customProps}
   * {event: {eventName, pageType}, customProps}
   */
  _normalizeObj(obj = {}) {
    if (obj.hasOwnProperty('event')) return this._eventObjTransform(obj);
    if (obj.hasOwnProperty('eventName')) return obj;

    return {};
  }

  _recordEventAndRedirect({ pageType, eventName } = {}, customProps = {}, url) {
    return new Promise((resolve, reject) => {
      this.recordEvent({ eventName, pageType }, customProps)
        .then(() => resolve(windowLocationRedirect(url)))
        .catch((err) => {
          errorHandler('KeenService _recordEventAndRedirect: ', err);

          return reject(err);
        });
    });
  }

  _recordEventsAndRedirect(events, url) {
    return new Promise((resolve, reject) => this.recordEvents(events)
      .then(() => resolve(windowLocationRedirect(url)))
      .catch((err) => {
        errorHandler('KeenService _recordEventsAndRedirect: ', err);

        return reject(err);
      }));
  }

  // modify this if we ever need additional transformations
  _transformPageType(pageType) {
    return pageType;
  }
}
