import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import FormFileUploader from './FormFileUploader';

import layout from '../../../styles/global_ui/layout.css';

// For dropping directly into rails-controlled form templates.
const RailsFormFileUploader = (props) => {
  const [fileData, setFileData] = useState(props.initData);

  return (
    <Fragment>
      <FormFileUploader
        accept={props.accept}
        classList={{ formGroup: layout.marginBottom0 }}
        errors={props.errors[0] || null}
        fileData={fileData}
        helperText={props.helperText}
        imagePreview={props.imagePreview}
        propagateUpload={(fileData) => setFileData(fileData)}
      />
      <input name={props.inputName} type="hidden" value={fileData.id ? fileData.id.toString() : ''} />
    </Fragment>
  );
};

RailsFormFileUploader.propTypes = {
  accept: PropTypes.string,
  errors: PropTypes.array,
  helperText: PropTypes.string,
  imagePreview: PropTypes.bool,
  initData: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  }),
  inputName: PropTypes.string,
};

RailsFormFileUploader.defaultProps = {
  accept: null,
  errors: [],
  helperText: '',
  imagePreview: false,
  inputName: '',
  initData: {},
};

export default RailsFormFileUploader;
