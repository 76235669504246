import React, { Component } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';

import Draftster from '../../../../../components/draftster_core';
import FormGroup from './FormGroup';

import convertFieldToDraftsterConsumable from './convertFieldToDraftsterConsumable';
import createDraftsterConfig from './createDraftsterConfig';
import { convertToJSONModel } from '../../../../draftster';

import { getEditorFields, postEditorFields } from './requests';
import { summonGlobalMessenger } from '../../../../../utility/dispatchers';
import errorHandler from '../../../../../services/error_handler';

const FIELD_LABELS = {
  description: { label: 'The Challenge', hint: 'Write your contest’s problem statement and call-to-action. ' },
  resources: { label: 'Resources', hint: 'Include technical resources, information about any workshops/webinars and sample applications here.' },
  eligibility: { label: 'Eligibility', hint: null },
  requirements: { label: 'Requirements', hint: null },
  judging_criteria: { label: 'Judging Criteria', hint: null },
  how_to_enter: { label: 'How to Enter', hint: null },
  rules: { label: 'Rules', hint: 'If you are a hosting a community contest please replace the contests@hackster.io email address at the bottom of these rules with your company\'s contact email for contest support.' },
  judges: { label: 'Judges (optional)', hint: 'Write the names and/or bios of the contest judges. This section will go under the prizes.' },
  about_us: { label: 'About Us (optional)', hint: 'Your company description and/or any sponsors and logos.' },
};

// Note: Order of fields dictate rendering position.
const OVERVIEW_FIELDS = ['description', 'judges', 'resources', 'about_us'];
const RULES_FIELDS = ['eligibility', 'requirements', 'judging_criteria', 'how_to_enter', 'rules'];

const PE_CONTAINER_SELECTOR = '.pe-container';
const PE_CLICKED_EVENT_NAME = 'clicked:nav';
const PE_SUBMIT_BTN_SELECTOR = '.pe-submit';
const SUBMIT_EVENT = 'submit:challengeForm';

class ChallengeFormEditorGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      updatedEditors: [],
    };

    this.editorWasUpdated = this.editorWasUpdated.bind(this);
    this.handlePeClickedEvent = this.handlePeClickedEvent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUnloadEvent = this.handleUnloadEvent.bind(this);

    this.configs = {};
    this.editorSaveStates = {};
    this.fields = this._getFieldsForType(props.type);
    this._ignoreEditorUpdateStatus = false;

    // REFS: Each field name will have a ref bound to this instance.
  }

  componentDidMount() {
    window.addEventListener(PE_CLICKED_EVENT_NAME, this.handlePeClickedEvent);
    window.addEventListener(SUBMIT_EVENT, this.handleSubmit);
    window.addEventListener('beforeunload', this.handleUnloadEvent);
    this._init();
  }

  componentWillUnmount() {
    window.removeEventListener(PE_CLICKED_EVENT_NAME, this.handlePeClickedEvent);
    window.removeEventListener(SUBMIT_EVENT, this.handleSubmit);
    window.removeEventListener('beforeunload', this.handleUnloadEvent);
  }

  /**
   * Initializers
   */
  _init() {
    return getEditorFields(this.props.challenge.id, this.fields.join(','))
      .then((fields) => {
        this._initConfigs(fields);
        this._initEditorSaveStates(fields);
        this.setState({ initialized: true });
      })
      .catch((err) => errorHandler('ChallengeFormEditorGroup _init', err));
  }

  _initConfigs(initialFields) {
    this.configs = this.fields.reduce((acc, field) => {
      const initContent = convertFieldToDraftsterConsumable(initialFields[field]);
      this._initEditorSaveStates(field, initContent);

      acc[field] = createDraftsterConfig(
        initContent,
        field,
        this.props.type,
        this._isOnCorrectTab.bind(this),
        this.editorWasUpdated,
      );

      return acc;
    }, {});
  }

  _initEditorSaveStates(field, content) {
    this.editorSaveStates[field] = content;
  }

  _getFieldsForType(type) {
    switch (type) {
      case 'overview':
        return OVERVIEW_FIELDS;
      case 'rules':
        return RULES_FIELDS;
      default:
        return [];
    }
  }

  editorWasUpdated(fieldName) {
    if (!this.state.updatedEditors.includes(fieldName)) {
      this.setState({ updatedEditors: this.state.updatedEditors.concat(fieldName) });
    }
  }

  handlePeClickedEvent(e) {
    if (!this._isOnCorrectTab()) return;

    if (e.detail.hash === `#${this.props.type}` && this._hasUnsavedChanges()) {
      e.detail.event.preventDefault();

      const c = confirm('There are unsaved changes\nAre you sure you want to move away?');
      if (c) {
        this._resetEditorStates();
        this.setState({ updatedEditors: [] }, () => {
          e.detail.event.target.click();
        });
      }
    }
  }

  handleSubmit() {
    if (!this._isOnCorrectTab()) return;

    const promises = this.fields.map((field) => convertToJSONModel(this[field].getEditorContent())
      .then((json) => Promise.resolve({ [field]: json }))
      .catch((err) => Promise.reject(err)));

    this._toggleFormElements(true);

    return Promise.all(promises)
      .then((fields) => Promise.all([fields, postEditorFields(this.props.challenge.id, this._createPostData(fields))]))
      .then(([fields]) => this._setEditorSavedStates(fields))
      .then(() => this._onSubmitSuccess())
      .catch((err) => {
        this._onSubmitError(err);
        errorHandler('Submission Error: ', err);
      });
  }

  handleUnloadEvent(e) {
    if (this._isOnCorrectTab() && this._hasUnsavedChanges()) {
      const message = 'There are unsaved changes.';

      (e || window.event).returnValue = message;

      return message;
    }
  }

  _createPostData(fields) {
    return fields.reduce((acc, field) => {
      const key = Object.keys(field)[0];
      acc[`challenge[${key}_json]`] = JSON.stringify(field[key]);

      return acc;
    }, { panel: this.props.type });
  }

  _hasUnsavedChanges() {
    if (!this.state.updatedEditors.length) return false;

    let warnUser = false;

    for (let i = 0; i <= this.state.updatedEditors.length - 1; i++) {
      if (warnUser) break;

      const fieldName = this.state.updatedEditors[i];
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
      /* eslint-disable-next-line no-prototype-builtins */
      if (this.editorSaveStates.hasOwnProperty(fieldName) && !deepEqual(this.editorSaveStates[fieldName], this[fieldName].getEditorContent())) {
        warnUser = true;
      }
    }

    return warnUser;
  }

  _isOnCorrectTab() {
    return (window.location.hash || '').includes(this.props.type);
  }

  _onSubmitError() {
    this._triggerMessenger({ msg: 'There was an error saving your form.', type: 'error' });
    this._toggleFormElements(false);
  }

  _onSubmitSuccess(response) {
    this._triggerMessenger();
    this._toggleFormElements(false);
  }

  _resetEditorStates() {
    this.state.updatedEditors.forEach((field, i) => {
      // Re-enable the Save changes button only once.
      if (i === 0) this[field].props.config.isEditorBusy(false);
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
      /* eslint-disable-next-line no-prototype-builtins */
      if (this.editorSaveStates.hasOwnProperty(field)) {
        this[field].__resetEditorState(this.editorSaveStates[field]);
      }
    });
  }

  _setEditorSavedStates(fields) {
    return new Promise((resolve) => {
      fields.forEach((field) => {
        const fieldName = Object.keys(field)[0];
        this.editorSaveStates[fieldName] = field[fieldName];
      });
      this.setState({ updatedEditors: [] });
      resolve();
    });
  }

  _toggleFormElements(apply) {
    this._toggleProcessingOverlay(apply);
    this._toggleSubmitButton(apply);
  }

  _toggleSubmitButton(apply) {
    const panel = document.querySelector(window.location.hash);
    const button = panel ? panel.querySelector(PE_SUBMIT_BTN_SELECTOR) : null;

    if (button) {
      apply ? (button.innerHTML = '<i class="fa fa-circle-o-notch" />') : (button.innerHTML = 'Save changes');
    }
  }

  _toggleProcessingOverlay(apply) {
    const el = document.querySelector(PE_CONTAINER_SELECTOR);

    if (el) {
      const className = 'processing';

      if (apply && !el.classList.contains(className)) {
        el.classList.add(className);
      } else if (!apply && el.classList.contains(className)) {
        el.classList.remove(className);
      }
    }
  }

  _triggerMessenger(msg = {}) {
    summonGlobalMessenger(msg);
  }

  render() {
    if (!this.state.initialized) return null;

    return (
      <div>
        {this.fields.map((field, index) => (
          <FormGroup key={index} {...FIELD_LABELS[field]}>
            <Draftster ref={(el) => this[field] = el} config={this.configs[field]} />
          </FormGroup>
        ))}
      </div>
    );
  }
}

ChallengeFormEditorGroup.propTypes = {
  challenge: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  type: PropTypes.oneOf(['overview', 'rules']).isRequired,
};

export default ChallengeFormEditorGroup;
