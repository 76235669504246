import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../../styles/global_ui/buttons.css';

const AnchorButton = ({
  ariaLabel,
  children,
  className,
  colorStyle,
  disabled,
  external,
  href,
  onClick,
  size,
  style,
  buttonProps,
}) => {
  const classNames = `${styles[size]} ${styles[colorStyle]} ${className}`;
  const externalProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <a
      aria-disabled={disabled}
      aria-label={ariaLabel}
      className={classNames}
      disabled={disabled}
      href={href}
      onClick={onClick}
      style={style}
      {...externalProps}
      {...buttonProps}
    >
      {children}
    </a>
  );
};

AnchorButton.propTypes = {
  ariaLabel: PropTypes.string,
  buttonProps: PropTypes.object, // any other props that the button element might need
  className: PropTypes.string,
  colorStyle: PropTypes.oneOf(['', 'secondary', 'tertiary', 'outline', 'blank', 'cancel']),
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'jumbo']),
  style: PropTypes.object,
};

AnchorButton.defaultProps = {
  ariaLabel: '',
  buttonProps: {},
  className: '',
  colorStyle: '',
  disabled: false,
  external: false,
  href: 'javascript:void(0);',
  onClick: () => {},
  size: 'md',
  style: {},
};

export default AnchorButton;
