import React from 'react';
import PropTypes from 'prop-types';
import typography from '../../../styles/global_ui/typography.css';
import styles from '../../../styles/global_ui/table.css';

const Cell = ({ body, className, disabled }) => (
  <td className={`${typography.bodyM} ${typography.breakWord} ${styles.cell} ${className} ${disabled ? styles.disabled : ''}`}>
    {typeof body === 'function' ? body() : body}
  </td>
);

Cell.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Cell.defaultProps = {
  className: '',
  disabled: false,
};

export default Cell;
