import React from 'react';

import BookmarksWidget from './bookmarks_widget';
import Dropdown from '../wrappers/dropdown';
import PortalPositioner from '../wrappers/global_portal_positioner';

import keenService from '../../services/keen/main';
import { getCreateBookmarkArgs, getDeleteBookmarkArgs } from '../../services/keen/events/eventTypeTemplates';

import bookmarkStore from '../../services/bookmark_store';
import urlService from '../../services/url_service';
import { formatNumberWithCommas, pluralize } from '../../utility/formatters';
import { graphMutate } from '../../requests/graphql';
import { summonGlobalDialog } from '../../utility/dispatchers';
import { windowInnerWidth, windowPageXOffset } from '../../services/window';

import bookmarkStyles from './bookmarks.css';
import layout from '../../styles/global_ui/layout.css';

const CREATE_BOOKMARK_MUTATION = 'create_bookmark';
const DELETE_BOOKMARK_MUTATION = 'delete_bookmark';
const MED_WIDTH = 768;
const WIDGET_WIDTH = 270;

export function addOrRemoveBookmark({ addOrRemoveBool, list, projectId } = {}, analytics = {}) {
  return new Promise((resolve, reject) => {
    const mutation = addOrRemoveBool ? CREATE_BOOKMARK_MUTATION : DELETE_BOOKMARK_MUTATION;

    return graphMutate({ t: mutation }, { list_id: list.id, project_id: projectId })
      .then(({ bookmark }) => {
        const updateFn = addOrRemoveBool ? 'addProjectToList' : 'removeProjectFromList';
        const analyticsFn = addOrRemoveBool ? getCreateBookmarkArgs : getDeleteBookmarkArgs;

        keenService.reportEventWithObj(analyticsFn({ id: bookmark.id, ...analytics }));

        return resolve(bookmarkStore[updateFn](projectId, list.id));
      })
      .catch((err) => reject(err));
  });
}

export function buildBookmarkMessage(addOrRemoveBool, listName, isNew = false) {
  return `${addOrRemoveBool ? 'Saved to' : 'Removed from'}${isNew ? ' new list' : ''} '${listName}.'`;
}

export function getDashboardBookmarksURL() {
  return urlService.url('/dashboard/bookmarks');
}

export function getProjectsCount(count) {
  return `${formatNumberWithCommas(count)} ${pluralize('project', count)}`;
}

export function getPortalWidgetDropdown({ analytics, currentListId, dismiss, getTarget, projectId }) {
  return (
    <PortalPositioner getTarget={getTarget} width={WIDGET_WIDTH}>
      {getWidgetDropdown({ analytics, currentListId, dismiss, projectId })}
    </PortalPositioner>
  );
}

export function getWidgetDropdown({ analytics, currentListId, dismiss, getTarget = null, projectId }) {
  return (
    <Dropdown className={bookmarkStyles.widgetDropdown} dismiss={dismiss} style={_getDropdownStyle(getTarget)}>
      {_getWidgetBody({ analytics, currentListId, dismiss, projectId })}
    </Dropdown>
  );
}

export function outOfBoundsLeft(target, offsetLeft) {
  return (target.left - offsetLeft) + windowPageXOffset() <= 0;
}

export function showDropdownOrSummonDialog(props = {}) {
  if (windowInnerWidth() > MED_WIDTH) return true;

  _summonDialog(props);

  return false;
}

/**
 * widget helpers
 */
function _getDropdownStyle(getTarget) {
  if (!getTarget) return null;

  const rect = getTarget().getBoundingClientRect();

  const top = rect.height / 2;
  const left = outOfBoundsLeft(rect, WIDGET_WIDTH) ? rect.width : (WIDGET_WIDTH * -1);

  return { left, top };
}

function _getWidgetBody({ analytics, currentListId, dismiss, projectId }) {
  return (
    <BookmarksWidget
      analytics={analytics}
      currentListId={currentListId}
      dismiss={dismiss}
      projectId={projectId}
    />
  );
}

function _summonDialog({ currentListId, projectId }) {
  summonGlobalDialog({
    dialogProps: { enableCloseButton: false, wrapperClassName: layout.padding0 },
    getChildren: (dismiss) => _getWidgetBody({ currentListId, dismiss, projectId }),
  });
}
