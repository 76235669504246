import React, { Component } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';

import Select from '../../select';

import { fetchMapboxPlaces } from '../../../../services/mapbox';
import { formatLocationForMapBoxOptions, formatMapboxPlacesRecordForSelectOpts } from '../../../../services/mapbox/formatters';
import { isObjectWithLength } from '../../../../utility/types';

import layout from '../../../../styles/global_ui/layout.css';

export const DEFAULT_DISTANCE_OPT = { label: 'Within 5 miles', value: '5' };

const DISTANCE_OPTS = [
  { label: 'Within 2 miles', value: '2' },
  { label: 'Within 5 miles', value: '5' },
  { label: 'Within 10 miles', value: '10' },
  { label: 'Within 25 miles', value: '25' },
  { label: 'Within 50 miles', value: '50' },
];
const DEFFAULT_LOCATION_OPT = { label: '', location: {}, value: '' };
const DEFAULT_STATE = {
  distanceValue: { label: 'Within 5 miles', value: '5' },
  initOnMount: false,
  locationValue: { label: '', value: '' },
};

class LocationSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this._init(props.value) };

    this.getLocationOptions = this.getLocationOptions.bind(this);
    this.onDistanceChange = this.onDistanceChange.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);

    // Ref.
    this._distanceInput;
    this._locationInput;
  }

  /**
   * Lifecycle
   */
  componentDidUpdate(prevProps) {
    if (this.props.historyStateEnabled && !deepEqual(prevProps.value, this.props.value)) {
      this._triggerChangeForLocationInput(this.props.value);

      if (!deepEqual(prevProps.value.distance, this.props.value.distance)) {
        this._triggerChangeForDistanceInput(this.props.value);
      }
    }
  }

  /**
   * Initializers
   */
  _init(value) {
    if (!value || !isObjectWithLength(value)) return DEFAULT_STATE;

    return {
      distanceValue: this._getDistanceFromOptsFromValue(value),
      initOnMount: true,
      locationValue: this._initFormatLocationForSelect(value),
    };
  }

  _initFormatLocationForSelect(location) {
    if (!location || !isObjectWithLength(location)) return DEFFAULT_LOCATION_OPT;

    const label = formatLocationForMapBoxOptions(location);

    return {
      label: label,
      location: location,
      value: label,
    };
  }

  /**
   * Methods
   */
  getLocationOptions(query) {
    return new Promise((resolve, reject) => fetchMapboxPlaces(query)
      .then((res) => resolve(this._formatMapboxResults(res)))
      .catch((err) => reject(err)));
  }

  onDistanceChange(opt) {
    this.setState({ distanceValue: (opt || DEFAULT_DISTANCE_OPT) }, () => this._propagateLocation());
  }

  onLocationChange(opt) {
    this.setState({ locationValue: (opt || DEFFAULT_LOCATION_OPT) }, () => this._propagateLocation());
  }

  /**
   * Helpers
   */
  _formatMapboxResults({ features }) {
    // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
    // eslint-disable-next-line @stylistic/no-mixed-operators
    if (!features || features && !features.length) return { options: [] };

    return { options: features.map((feature) => formatMapboxPlacesRecordForSelectOpts(feature)).filter((n) => n !== null) };
  }

  _getDistanceFromOptsFromValue(value) {
    const distance = value.distance ? value.distance.toString() : null;

    return DISTANCE_OPTS.find((opt) => opt.value === distance) || DEFAULT_DISTANCE_OPT;
  }

  _propagateLocation() {
    const location = isObjectWithLength(this.state.locationValue.location)
      ? { ...this.state.locationValue.location, distance: this.state.distanceValue.value }
      : {};
    this.props.onChange(location);
  }

  /**
   * Input updaters
   * These are neccessary for apps that use history push state to reset the values while navigating (/search filters).
   */
  _triggerChangeForDistanceInput(value) {
    if (this._distanceInput) {
      const distanceValue = this._getDistanceFromOptsFromValue(value);
      this._distanceInput.__triggerOnChangeForValue(distanceValue.label);
    }
  }

  _triggerChangeForLocationInput(value) {
    const resetValue = isObjectWithLength(value) ? formatLocationForMapBoxOptions(value) : '';

    if (resetValue.length > 0) {
      this._locationInput.__triggerOnChangeForValue(resetValue, resetValue);

      this.setState({ locationValue: this._initFormatLocationForSelect(value) });
    } else {
      this._locationInput.__resetState();
      this.setState({ locationValue: DEFFAULT_LOCATION_OPT });
    }
  }

  render() {
    const {
      disabled,
      hasErrors,
      placeholder,
      renderDistanceInput,
      theme,
    } = this.props;

    return (
      <div>
        <Select
          ref={(el) => this._locationInput = el}
          asyncOpts={{
            initOnMount: this.state.initOnMount,
            initQuery: this.state.locationValue.location,
            request: this.getLocationOptions,
          }}
          disabled={disabled}
          hasErrors={hasErrors}
          onSelectedChange={this.onLocationChange}
          placeholder={placeholder}
          theme={this.props.theme}
          uiOpts={{
            leftIconName: theme === 'search' ? 'search' : null,
            renderX: true,
          }}
          value={this.state.locationValue}
        />
        {renderDistanceInput
        && (
          <div className={layout.marginTop10}>
            <Select
              ref={(el) => this._distanceInput = el}
              disabled={(disabled || !this.state.locationValue.value.length)}
              onSelectedChange={this.onDistanceChange}
              options={DISTANCE_OPTS}
              searchOpts={{ rule: 'norule' }}
              uiOpts={{ renderX: false }}
              value={this.state.distanceValue}
            />
          </div>
        )}
      </div>
    );
  }
}

LocationSelect.propTypes = {
  disabled: PropTypes.bool, // Means disable both selects.
  hasErrors: PropTypes.bool,
  historyStateEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  renderDistanceInput: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'search']),
  value: PropTypes.shape({
    city: PropTypes.string,
    countryCode: PropTypes.string,
    state: PropTypes.string,
    distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    geoloc: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
};

LocationSelect.defaultProps = {
  disabled: false,
  hasErrors: false,
  historyStateEnabled: false,
  onChange: () => {},
  placeholder: 'Start typing to select a location!',
  renderDistanceInput: true,
  theme: 'default',
  value: {},
};

export default LocationSelect;
