import communityConfig from './communityConfig';
import homeConfig from './homeConfig';
import platformConfig from './platformConfig';
import topicConfig from './topicConfig';
import whitelabelConfig from './whitelabelConfig';

import errorHandler from '../../../error_handler';

const APP_CONFIG = {
  community: communityConfig,
  home: homeConfig,
  platform: platformConfig,
  topic: topicConfig,
  whitelabel: whitelabelConfig,
};

export default function getConfigForType(type, props = {}, ctx = null) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!APP_CONFIG.hasOwnProperty(type)) {
    errorHandler(`Expected a known type but got, "${type}". Known types: ${Object.keys(APP_CONFIG)}`);

    return {};
  }

  return APP_CONFIG[type](props, ctx);
}
