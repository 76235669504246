import React from 'react';
import PropTypes from 'prop-types';

import DefaultEmbed from './DefaultEmbed';
import DismissButton from '../../../buttons/dismiss_button';
import VideoEmbed from './VideoEmbed';

import { isBlank } from '../../../../utility/types';
import { NOOP_HREF } from '../../../../constants/links';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './post_embed.css';

const componentTypes = { video: VideoEmbed };

const getComponent = ({ embed_type } = {}) => componentTypes[embed_type] || DefaultEmbed;
const getLinkUrl = ({ link } = {}) => link || NOOP_HREF;

const PostEmbed = ({ embed, killEmbed, mode }) => {
  if (isBlank(embed)) return null;

  return (
    <a
      className={`${styles.root} ${layout.marginBottom15} ${layout.marginTop15} ${utilStyles.border} ${utilStyles.posRelative}`}
      href={mode === 'edit' ? NOOP_HREF : getLinkUrl(embed)}
      rel="noopener noreferrer"
      target="_blank"
    >
      {mode === 'edit'
      && (
        <DismissButton
          className={styles.dismiss}
          onClick={(e) => {
            e.preventDefault(); // Prevents the anchor parent from opening new window.
            killEmbed();
          }}
        />
      )}
      {React.createElement(getComponent(embed), { embed, mode })}
    </a>
  );
};

PostEmbed.propTypes = {
  embed: PropTypes.object.isRequired,
  killEmbed: PropTypes.func,
  mode: PropTypes.string,
};

PostEmbed.defaultProps = {
  killEmbed: () => {},
  mode: 'show',
};

export default PostEmbed;
