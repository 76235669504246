import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { IntersectionObserver, ResizeObserver } from '../../../utility/observers';
import { windowInnerHeight } from '../../../services/window';

// NOTE in order for this to behave as expected, the parent element
// should have position: relative or absolute
class StickyWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { position: null }; // 'start', 'end', 'fixed'

    this.setPosition = this.setPosition.bind(this);

    // observers
    this._intersectionObserver;
    this._resizeObserver;

    // refs
    this._sentinelEnd;
    this._sentinelStart;
  }

  componentDidMount() {
    this._addObservers();
    this.setPosition();
  }

  componentWillUnmount() {
    this._removeObservers();
  }

  /**
   * Methods
   */
  setPosition(entries) {
    const position = this._getPosition();
    if (this.state.position !== position) {
      this.setState({ position }, () => this.props.reportPosition(position));
    }
  }

  /**
   * Helpers
   */
  _addObservers() {
    window.addEventListener('load', this.setPosition);

    this._intersectionObserver = new IntersectionObserver(this.setPosition);
    if (this._sentinelStart) this._intersectionObserver.observe(this._sentinelStart);
    if (this._sentinelEnd) this._intersectionObserver.observe(this._sentinelEnd);

    this._resizeObserver = new ResizeObserver(this.setPosition);
    this._resizeObserver.observe(document.getElementById(this.props.parentId));
  }

  _doesStartAtTop() {
    return this.props.startPos === 'top';
  }

  _getEndPosKey() {
    return this._doesStartAtTop() ? 'bottom' : 'top';
  }

  _getPosition() {
    if (this._shouldStickToStart()) return 'start';
    if (this._shouldStickToEnd()) return 'end';

    return 'fixed';
  }

  _getSentinelStyle() {
    return { [this.props.startPos]: this._negateBuffer(), position: 'absolute' };
  }

  _getStyle() {
    switch (this.state.position) {
      case 'end':
        return { [this._getEndPosKey()]: 0, position: 'absolute' };
      case 'fixed':
        return { position: 'fixed', [this.props.startPos]: this.props.startBuffer };
      default:
        return {};
    }
  }

  _negateBuffer() {
    if (typeof this.props.startBuffer === 'number') return this.props.startBuffer * -1;

    return `calc((${this.props.startBuffer}) * -1)`;
  }

  _removeObservers() {
    window.removeEventListener('load', this.setPosition);

    this._intersectionObserver.disconnect();
    this._intersectionObserver = undefined;

    this._resizeObserver.disconnect();
    this._resizeObserver = undefined;
  }

  _shouldStickToEnd() {
    if (!this.props.stickToEnd || !this._sentinelEnd) return false;

    if (this._doesStartAtTop()) {
      return this._sentinelEnd.getBoundingClientRect().top <= 0;
    }

    return this._sentinelEnd.getBoundingClientRect().bottom >= windowInnerHeight();
  }

  _shouldStickToStart() {
    if (this._doesStartAtTop()) {
      return this._sentinelStart.getBoundingClientRect().top >= 0;
    }

    return this._sentinelStart.getBoundingClientRect().bottom <= windowInnerHeight();
  }

  /**
   * Views
   */
  // NOTE: following description assumes startPos === 'top'. it works with
  // opposite directions if startPos === 'bottom'

  // When the sticky element is 'fixed', the intersectionObserver will not
  // detect intersections between it and any parent element or the viewport.
  // This method creates an invisible placeholder with a sentinel that can
  // tell us when the bottom of the container has scrolled up to hit the
  // bottom of the fixed content. It contains a copy of the children,
  // so its size will remain up to date with dynamically changing children.
  // Since this creates a whole copy of your children, be aware of  memory impact
  // and only set stickToEnd to true if you really need it
  _getEndPlaceholder() {
    return (
      <div style={{ [this._getEndPosKey()]: 0, position: 'absolute', visibility: 'hidden' }}>
        <div ref={(el) => this._sentinelEnd = el} style={this._getSentinelStyle()} />
        {this.props.renderPlaceholder ? this.props.renderPlaceholder() : this.props.children}
      </div>
    );
  }

  _renderContent() {
    return (
      <div className={this.props.className} style={this._getStyle()}>
        {this.props.children}
      </div>
    );
  }

  _renderEnd() {
    return this.props.stickToEnd && this._getEndPlaceholder();
  }

  _renderStart() {
    return (<div ref={(el) => this._sentinelStart = el} style={this._getSentinelStyle()} />);
  }

  render() {
    const partsInOrder = this._doesStartAtTop()
      ? [this._renderStart(), this._renderContent(), this._renderEnd()]
      : [this._renderEnd(), this._renderContent(), this._renderStart()];

    return (<Fragment>{React.Children.toArray(partsInOrder)}</Fragment>);
  }
}

StickyWrapper.propTypes = {
  className: PropTypes.string,
  parentId: PropTypes.string.isRequired,
  renderPlaceholder: PropTypes.func, // Optional element to render in bottom placeholder so that all children aren't rendered twice
  reportPosition: PropTypes.func,
  startBuffer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // can be any css measurement e.g. "50vh"
  startPos: PropTypes.oneOf(['top', 'bottom']),
  stickToEnd: PropTypes.bool,
};

StickyWrapper.defaultProps = {
  className: '',
  renderPlaceholder: null,
  reportPosition: () => {},
  startBuffer: 0,
  startPos: 'top',
  stickToEnd: false,
};

export default StickyWrapper;
