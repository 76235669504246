import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LogRow from './LogRow';
import PaginatedList from '../../../client/wrappers/paginated_list';

import errorHandler from '../../../services/error_handler';

import layout from '../../../styles/global_ui/layout.css';
import tableStyles from '../../../styles/global_ui/table.css';
import styles from './style.css';

const LOGS_PER_REQUEST = 10;

const LogHeader = ({ headerInfo }) => {
  const { size, title } = headerInfo;
  const headerWidth = size === 'large' ? styles.historyColLarge : styles.historyCol;

  return (
    <div className={`${headerWidth} ${tableStyles.thCell}`}>
      <span className={tableStyles.thTitle}>{title}</span>
    </div>
  );
};

// Adapted from Ad's HistoryLog component to be generic/use hooks/ts.
const LogsTable = ({ cellBuilder, fetchLogs, headers, logRowNormalizerFn }) => {
  const [isBusy, setIsBusy] = useState(true);
  const [pagination, setPagination] = useState({});
  const [logs, setLogs] = useState([]);

  const handlePagination = () => {
    setIsBusy(true);

    return fetchLogs({ page: pagination.next_page, per_page: LOGS_PER_REQUEST })
      .then(({ metadata, records }) => {
        setPagination(metadata);
        setLogs((prevRecords) => prevRecords.concat(records));
      })
      .catch((err) => {
        errorHandler('LogsTable handlePagination', err);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  useEffect(() => {
    handlePagination();

  // Note: Disabled for onMount / no actual deps.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isBusy && !logs.length) return <div>No logs found.</div>;

  return (
    <div className={layout.fullWidth}>
      <PaginatedList
        buttonText="Show more"
        disabled={isBusy}
        onPaginatorClick={handlePagination}
        paginator={{
          currentPage: pagination.current_page,
          nextPage: pagination.next_page,
          prevPage: pagination.prev_page,
        }}
        type="button"
      >

        <div className={tableStyles.table}>
          <div className={tableStyles.thTitleWrapper}>
            {Object.keys(headers).map((header) => <LogHeader key={header} headerInfo={headers[header]} />)}
          </div>
          <div>
            {logs.map((log) => <LogRow key={log.id} cellBuilder={cellBuilder} log={log} logRowNormalizerFn={logRowNormalizerFn} />)}
          </div>
        </div>

      </PaginatedList>
    </div>
  );
};

LogsTable.propTypes = {
  cellBuilder: PropTypes.objectOf(
    PropTypes.shape({
      size: PropTypes.oneOf(['normal', 'large']).isRequired,
      formatter: PropTypes.func,
      dataView: PropTypes.bool,
      dataSource: PropTypes.string,
      dataFormatters: PropTypes.objectOf(PropTypes.func),
    }),
  ).isRequired,
  fetchLogs: PropTypes.func.isRequired,
  headers: PropTypes.objectOf(
    PropTypes.shape({
      size: PropTypes.oneOf(['normal', 'large']).isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  logRowNormalizerFn: PropTypes.func,
};

export default LogsTable;
