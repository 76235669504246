import { CompositeDecorator } from 'draft-js';
import Link from '../components/Link';
import Mention from '../components/Mention';
import { characterHasEntity, findWithRegex } from '../draftHelpers';

function findLinkEntities(contentBlock, callback, contentState) {
  // TODO: Try to pass the range to callback like findWithRegex.  It may resolve the link issue where each char is tied to a entity
  // rather than a range.
  contentBlock.findEntityRanges((character) => characterHasEntity(character, 'LINK', contentState), callback);
}

function findMentionsCharacter(contentBlock, callback, contentState) {
  // @ followed by any digits, words and special chars as long as its not followed by a period plus letters.
  const MENTION_REGEX = /(\B@[a-z0-9_-]+\b)(?!\.\w)/gi;
  findWithRegex(MENTION_REGEX, contentBlock, callback);
}

export default function decorator(ctx) {
  return new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
    {
      strategy: findMentionsCharacter,
      component: Mention,
      props: { editorCtx: ctx },
    },
  ]);
}
