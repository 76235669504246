import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import MobileInputWrapper from '../templates/MobileInputWrapper';
import { parseDateString } from '../datetimeInputHelpers';

import styles from '../datetime.css';

class MobileDateInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { value: props.initValue };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
    this.props.onChange(e.target.value);
  }

  render() {
    const { autoComplete, disabled, errors } = this.props;
    const { day, month, year } = parseDateString(this.state.value);

    return (
      <MobileInputWrapper
        autoComplete={autoComplete}
        disabled={disabled}
        errors={errors}
        max="9999-12-31"
        onChange={this.handleChange}
        type="date"
        value={this.state.value}
      >
        <Fragment>
          <span className={styles.input}>{month || 'MM'}</span>
          <span>/</span>
          <span className={styles.input}>{day || 'DD'}</span>
          <span>/</span>
          <span className={styles.inputWide}>{year || 'YYYY'}</span>
        </Fragment>
      </MobileInputWrapper>
    );
  }
}

MobileDateInput.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

MobileDateInput.defaultProps = {
  autoComplete: null,
  disabled: false,
  errors: null,
  initValue: '',
  onChange: () => {},
};

export default MobileDateInput;
