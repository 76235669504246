import { isWindowDefined } from '../services/window';

let _isDateInputSupported = null;

export default function isDateInputSupportedBrowser() {
  if (isWindowDefined && _isDateInputSupported === null) {
    const input = document.createElement('input');
    const value = 'a';
    input.setAttribute('type', 'date');
    input.setAttribute('value', value);
    _isDateInputSupported = (input.value !== value);
  }

  return _isDateInputSupported;
}
