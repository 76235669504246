import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LazyComponent from '../lazy_component';

import { aspectRatioPadding } from '../../../utility/math';

import lazyImageStyles from '../../reusable_components/LazyImage/lazy_image.css';
import utilStyles from '../../../styles/global_ui/util.css';

class LazyResponsiveIframe extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { isLoaded: false };
  }

  render() {
    const { allowFullScreen, aspectRatio, className, src, verticalOffset } = this.props;

    return (
      <LazyComponent
        className={`${utilStyles.absolutePlaceholderParent} ${className} ${lazyImageStyles.fade} ${this.state.isLoaded ? lazyImageStyles.fadeIn : ''}`}
        style={{ paddingBottom: aspectRatioPadding({ aspectRatio }) }}
        verticalOffset={verticalOffset}
      >
        {() => (
          <iframe
            allowFullScreen={allowFullScreen}
            className={`${utilStyles.absolutePlaceholderChild} ${utilStyles.overflowHidden}`}
            frameBorder={0}
            height="100%"
            onLoad={() => this.setState({ isLoaded: true })}
            src={src}
            width="100%"
          />
        )}
      </LazyComponent>
    );
  }
}

LazyResponsiveIframe.propTypes = {
  allowFullScreen: PropTypes.bool,
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
  lazy: PropTypes.bool,
  src: PropTypes.string,
  verticalOffset: PropTypes.number,
};

LazyResponsiveIframe.defaultProps = {
  allowFullScreen: true,
  aspectRatio: (16 / 9),
  className: '',
  lazy: false,
  src: '',
  verticalOffset: 100,
};

export default LazyResponsiveIframe;
