import React from 'react';
import PropTypes from 'prop-types';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './styles.css';

const c = {
  root: `${layout.flexJustifySpaceBetween} ${styles.root}`,
  optContainer: `${layout.flexCenterCenter} ${typography.bodyS} ${typography.graphite} ${typography.bold} ${layout.paddingBottom15} ${layout.paddingTop15} ${utilStyles.cursorPointer} ${styles.optContainer}`,
};

// Note: At least 2 options required!
const MultiPillButton = ({ classList, onChange, options, selectedVal }) => (
  <div className={`${c.root} ${classList?.root}`}>
    {options.map((opt) => (
      <label key={`${opt.name}-id`} className={`${layout.fullWidth} ${styles.labelContainer}`}>
        <input
          checked={opt.value === selectedVal}
          className={utilStyles.srOnly}
          name={opt.name}
          onChange={(e) => onChange({ value: opt.value, checked: e.target.checked })}
          type="checkbox"
          value={opt.value}
        />
        <div className={`${c.optContainer} ${opt.value === selectedVal ? utilStyles.bgSkyToLake : ''} ${classList?.optContainer}`}>
          {opt.name}
        </div>
      </label>
    ))}
  </div>
);

MultiPillButton.propTypes = {
  classList: PropTypes.shape({
    root: PropTypes.string,
    optContainer: PropTypes.string,
  }),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  selectedVal: PropTypes.string,
};
MultiPillButton.defaultProps = { classList: {} };

export default MultiPillButton;
