import React, { Component } from 'react';
import PropTypes from 'prop-types';

import errorHandler from '../../../services/error_handler';
import generateRandomKey from '../../../utility/generateRandomKey';

const BASE_SCRIPT_URL = 'https://www.google.com/recaptcha/api.js';
const GLOBAL_OBJ_NAME = 'grecaptcha';

class ReCAPTCHA extends Component {
  constructor(props) {
    super(props);

    this.onScriptReady = this.onScriptReady.bind(this);

    this.widgetID;
    this.globalCBName = `recaptchaReady${generateRandomKey()}`;

    // refs
    this._root;
  }

  componentDidMount() {
    if (this.getGlobalObject()) {
      this.onScriptReady();
    } else {
      this.loadScript();
    }
  }

  // proxy functions for parents to use
  execute() {
    return this.getGlobalObject().execute(this.widgetID);
  }

  getResponse() {
    return this.getGlobalObject().getResponse(this.widgetID);
  }

  reset() {
    return this.getGlobalObject().reset(this.widgetID);
  }

  getGlobalObject() {
    return window[GLOBAL_OBJ_NAME];
  }

  loadScript() {
    // have to use this global callback because the global grecaptcha object isn't ready when script.onload callback fires
    window[this.globalCBName] = this.onScriptReady;
    const url = `${BASE_SCRIPT_URL}?onload=${this.globalCBName}&render=explicit`;

    const script = document.createElement('script');
    script.src = url;
    script.async = 1;
    script.deferred = 1;
    script.error = (err) => errorHandler(err || new Error('problem loading ReCAPTCHA script'));
    document.body.appendChild(script);
  }

  onScriptReady() {
    this.renderWidget();
    this.props.onLoad();

    // get this function out of global scope if it's there
    window[this.globalCBName] = undefined;
  }

  renderWidget() {
    if (this.getGlobalObject() && this._root) {
      this.widgetID = this.getGlobalObject().render(this._root, {
        'badge': this.props.badgePos,
        'callback': this.props.callback,
        'error-callback': this.props.callback,
        'expired-callback': this.props.callback,
        'sitekey': this.props.recaptchaSiteKey,
        'size': 'invisible',
      });
    }
  }

  render() {
    return (
      <div ref={(el) => this._root = el} className={this.props.className} />
    );
  }
}

ReCAPTCHA.propTypes = {
  badgePos: PropTypes.string,
  callback: PropTypes.func,
  onLoad: PropTypes.func,
  recaptchaSiteKey: PropTypes.string.isRequired,
};

ReCAPTCHA.defaultProps = {
  badgePos: 'bottomright',
  callback: () => {},
  onLoad: () => {},
};

export default ReCAPTCHA;
