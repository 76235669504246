import gridStyles from '../../../styles/global_ui/grid.css';
import layout from '../../../styles/global_ui/layout.css';

/**
 * Provides classNames for a basic responsive grid
 */
const CELL_WIDTH_CLASSNAMES = [
  gridStyles.cell_1, // 1 cell per row
  gridStyles.cell_1_2_sm, // 2 cells per row above small width
  gridStyles.cell_1_3_md, // 3 cells per row above med width
  gridStyles.cell_1_4_ml, // 4 cells per row above med-large width
];

const getGridClassName = (horizontal, gutterSize = 15) => {
  const gutterClassH = gridStyles[`guttersH${gutterSize}`] || null;
  const gutterClassV = gridStyles[`guttersV${gutterSize}`] || null;
  const horizontalClass = horizontal ? gridStyles.hScrollSm : null;

  return [gridStyles.grid, gutterClassH, gutterClassV, horizontalClass].filter((c) => c).join(' ');
};

const getCellClassName = (maxCols = 4) => {
  const responsiveWidths = CELL_WIDTH_CLASSNAMES.slice(0, maxCols);

  return `${layout.flex} ${gridStyles.cell} ${responsiveWidths.join(' ')}`;
};

export default function getGridClassNames(horizontal, maxCols, gutterSize, cellClassOverride) {
  return {
    cell: cellClassOverride ? cellClassOverride : getCellClassName(maxCols),
    grid: getGridClassName(horizontal, gutterSize),
  };
}
