const CATEGORIES = {
  'Announcements': 'ANNOUNCEMENTS',
  'Free hardware': 'FREE_HARDWARE',
  'General': 'GENERAL',
  'Technical help': 'TECHNICAL_HELP',
};

export const FEED_POST_CATEGORIES = Object.keys(CATEGORIES);

export const getCategoryEnum = (val) => CATEGORIES[val] || null;
