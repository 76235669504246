import React from 'react';
import PropTypes from 'prop-types';

import AnchorButton from '../../../../../client/buttons/anchor';
import ArticleAuthorCredits from './ArticleAuthorCredits';
import ArticleHeader from './ArticleHeader';
import ArticleStickySidebar from '../../templates/sticky_actions/ArticleStickySidebar';
import LazyImage from '../../../../../client/reusable_components/LazyImage';

import { renderContent } from './templates';

import { extractCoverImageFromStory } from '../../../../../client/draftster/story_json_renderers/storyJSONHelpers';
import { isBlank } from '../../../../../utility/types';

import { PORTAL_ID } from '../../../../../client/ads/article_page/ArticlePageSimpleAd';

import gridStyles from '../../../../../styles/global_ui/grid.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import styles from '../article_layout.css';

const _getCoverImageLayout = ({ article, coverImage, isPreview, social_sharing_links }) => (
  <article>
    <ArticleHeader article={article} />
    <figure className={layout.marginTop30}>
      <div className={`${utilStyles.absolutePlaceholderParent} ${utilStyles.aspectRatioPadding16_9} ${styles.coverImage}`}>
        <LazyImage
          className={utilStyles.absolutePlaceholderChild}
          ratio="16:9"
          setStyle={false}
          src={coverImage.url}
          width={830}
        />
      </div>
      {!!coverImage.figcaption
      && (
        <figcaption className={`${typography.bodyS} ${typography.pebble} ${typography.textCenter} ${layout.marginTop10}`}>
          {coverImage.figcaption}
        </figcaption>
      )}
    </figure>
    <div id={PORTAL_ID} />
    <div className={`${layout.flex} ${layout.marginTop30}`}>
      <div className={`${layout.flex} ${layout.flex1}`}>
        <ArticleStickySidebar
          article={article}
          disabled={isPreview}
          social_sharing_links={social_sharing_links}
        />
      </div>
      <div className={styles.body}>
        <section>
          {renderContent(article, isPreview)}
        </section>
        {_getTags(article.tags)}
        {!article.sponsored && <ArticleAuthorCredits user={article.user} />}
      </div>
      <div className={layout.flex1} />
      {' '}
      {/* Spacer */}
    </div>
  </article>
);

const _getDefaultLayout = ({ article, isPreview, social_sharing_links }) => (
  <div className={layout.flex}>
    <div className={`${layout.flex} ${layout.flex1}`}>
      <ArticleStickySidebar
        article={article}
        disabled={isPreview}
        shouldFadeIn={true}
        social_sharing_links={social_sharing_links}
      />
    </div>
    <article className={styles.body}>
      <ArticleHeader article={article} />
      <div id={PORTAL_ID} />
      <section className={layout.marginTop30}>
        {renderContent(article, isPreview)}
      </section>
      {_getTags(article.tags)}
      {!article.sponsored && <ArticleAuthorCredits user={article.user} />}
    </article>
    <div className={layout.flex1} />
    {' '}
    {/* Spacer */}
  </div>
);

const _getTags = (tags) => {
  if (isBlank(tags)) return null;

  return (
    <section className={layout.marginTop45}>
      <div className={`${layout.flexRowWrap} ${gridStyles.guttersH10} ${gridStyles.guttersV10}`}>
        {tags.map(({ name, url }, i) => (
          <div key={i} className={gridStyles.cell}>
            <AnchorButton colorStyle="secondary" href={url} size="sm">
              {name}
            </AnchorButton>
          </div>
        ))}
      </div>
    </section>
  );
};

const Article = ({ article, isPreview, social_sharing_links }) => {
  const { coverImage, story } = extractCoverImageFromStory(article.content);
  const extractedArticle = { ...article, content: story };

  if (coverImage) return _getCoverImageLayout({ article: extractedArticle, coverImage, isPreview, social_sharing_links });

  return _getDefaultLayout({ article: extractedArticle, isPreview, social_sharing_links });
};

Article.propTypes = {
  article: PropTypes.shape({
    sponsored: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    user: PropTypes.object.isRequired,
  }).isRequired,
  isPreview: PropTypes.bool,
  social_sharing_links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

Article.defaultProps = {
  isPreview: false,
  social_sharing_links: [],
};

export default Article;
