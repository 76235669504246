function createHeadRange(currentPage, pages) {
  return currentPage > 3 ? pages.slice(0, currentPage + 2) : pages.slice(0, 5);
}

function createRange(currentPage, max) {
  return Array.from(Array(5)).reduce((acc, n, i) => {
    if (i === 0) return acc;

    if (currentPage + i <= max && acc[acc.length - 1] < currentPage + 2) {
      acc.push(currentPage + i);
    } else {
      acc.unshift(acc[0] - 1);
    }

    return acc;
  }, [currentPage]);
}

function insertBuffers(head, pages, currentPage) {
  const buffer = ['buffer'];

  if (head.length > 2) {
    const lastOfHead = head[head.length - 1];
    const tail = pages.slice(pages.length - 2).filter((n) => n !== lastOfHead);
    const noBufferWhenNextIsConsecutive = (head[head.length - 1] + 1) === tail[0] ? [] : buffer;
    const nextPageOrBuffer = noBufferWhenNextIsConsecutive.length && ((lastOfHead + 1) === (tail[0] - 1))
      ? [(lastOfHead + 1)]
      : noBufferWhenNextIsConsecutive;

    return head.concat(nextPageOrBuffer).concat(tail);
  } else {
    const firstPart = head.concat(buffer).concat(createRange(currentPage, pages.length));
    const lastOfFirstPart = firstPart[firstPart.length - 1];
    // We have all the pages in the first part, go ahead and return early.
    if (lastOfFirstPart === pages.length) return firstPart;

    const tail = pages.slice(pages.length - 2).filter((n) => n !== lastOfFirstPart);
    const hasCollision = (lastOfFirstPart + 1) === tail[0];
    // Below is saying if pages would be [...13,14,15,16,17, buffer, 19, 20] (currentPage is 15, totalPages is 20)
    // then we might as well replace the buffer with the next page (18 in this case).
    const nextPageOrBuffer = !hasCollision && ((lastOfFirstPart + 1) === (tail[0] - 1)) ? [lastOfFirstPart + 1] : buffer;
    const lastPart = tail.length > 1 && !hasCollision ? nextPageOrBuffer.concat(tail) : tail;

    return firstPart.concat(lastPart);
  }
}

export default function createPagination(currentPage, totalPages) {
  if (totalPages <= 1) return [];
  if (totalPages <= 8) return Array.from(Array(totalPages)).map((n, i) => i + 1);

  const pages = Array.from(Array(totalPages)).map((n, i) => i + 1);
  const head = currentPage > 6 ? [1, 2] : createHeadRange(currentPage, pages);

  return insertBuffers(head, pages, currentPage);
}
