import React from 'react';
import PropTypes from 'prop-types';

import EmbedText from './EmbedText';
import LazyBackgroundImage from '../../../image/LazyBackgroundImage';

import { isBlank } from '../../../../utility/types';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './post_embed.css';

const DefaultEmbed = ({ embed }) => (
  <div className={`${layout.fullWidth} ${layout.flex} ${styles.lockHeight}`}>
    {!isBlank(embed.image)
    && (
      <LazyBackgroundImage
        classList={{ root: `${styles.image} ${utilStyles.posRelative}` }}
        ratio="1:1"
        setStyle={false}
        src={embed.image}
        width={158}
      />
    )}
    <EmbedText embed={embed} />
  </div>
);

DefaultEmbed.propTypes = { embed: PropTypes.object.isRequired };

export default DefaultEmbed;
