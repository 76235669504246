import React from 'react';
import PropTypes from 'prop-types';
import styles from './view_all.css';

const EmptyViewAll = ({ msg, title }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <p className={styles.title}>{title}</p>
    </div>
    <div className={styles.emptyMsg}>{msg}</div>
  </div>
);

EmptyViewAll.propTypes = {
  msg: PropTypes.string,
  title: PropTypes.string.isRequired,
};

EmptyViewAll.defaultProps = { msg: "No projects match this section's settings." };

export default EmptyViewAll;
