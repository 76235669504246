import GraphQLBaseService from '../base_service';
import queryBuilder from './query_builder';
import { graphQuery } from '../../../requests/graphql';

const QUERY = () => ({ t: 'published_projects_with_simple_pagination' });

export default class GraphQLProjectsService extends GraphQLBaseService {
  constructor({ history, queryString } = {}) {
    super({ history, queryBuilder, queryString: (queryString || QUERY), resolverKey: 'published_projects' });
  }

  // NOTE: Use this only when the resolver expects an Aloglia shape as its repsonse, for now.
  // Apps using this method: [ViewAllBuilder, ProjectsPage, PlatformPage, WhitelabelHome, CommunityPage]
  searchWithNoEffects(queryMap, params, recordsCount = 0) {
    return new Promise((resolve, reject) => this._translateQueryMap(queryMap, params)
      .then((vars) => graphQuery(QUERY(), vars))
      .then((response) => {
        const currentQuery = response.published_projects;

        resolve({
          hits: this._translateRecordsToAlgoliaHits(currentQuery.records),
          nbHits: this._translateMetadata(currentQuery, recordsCount),
        });
      })
      .catch((err) => reject(err)));
  }

  _translateMetadata(currentQuery, recordsCount) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    if (!currentQuery.hasOwnProperty('metadata')) return 0;

    if (currentQuery.metadata.next_page && currentQuery.metadata.next_page === null) {
      return recordsCount;
    } else if (typeof currentQuery.per_page === 'number') {
      return currentQuery.per_page + recordsCount;
    } else {
      return 0;
    }
  }

  _translateQueryMap(queryMap, params) {
    return new Promise((resolve, reject) => {
      const vars = {};
      const qlVars = queryMap.qlVars || {};

      if (queryMap.hitsPerPage) {
        vars.per_page = queryMap.hitsPerPage;
      } else if (queryMap.length) {
        vars.per_page = queryMap.length;
      }

      if (queryMap.sort && queryMap.sort === 'featured_date') {
        vars.by = 'featured';
      } else if (queryMap.sort) {
        vars.sort = queryMap.sort;
      }

      if (queryMap.category_id && queryMap.category_id === 'featured') {
        vars.by = 'featured';
      } else if (queryMap.topic_id && queryMap.topic_id === 'featured') {
        vars.by = 'featured';
      }

      return queryBuilder(vars)
        .then((query) => resolve({ ...query, ...qlVars }))
        .catch((err) => reject(err));
    });
  }

  _translateRecordsToAlgoliaHits(records) {
    return records.map((record) => ({
      ...record,
      authors: record.team.members,
      pitch: record.one_liner,
      impresssions_count: record.stats.views,
      respects_count: record.stats.respects,
    }));
  }
}
