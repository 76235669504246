import React from 'react';
import PropTypes from 'prop-types';

import CustomIcon from '../../icon/custom/index.js';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';

const TOOL_LABELS = ['bold', 'italic', 'code', 'bullet_list', 'quote'];

const Toolbar = (props) => (
  <div className={`editor-toolbar ${layout.flexCenterItems}`}>
    {TOOL_LABELS.map((label) => (
      <button
        key={label}
        className={`${buttonStyles.md} ${layout.flexCenterItems} toolbar-btn`}
        data-label={label}
        onClick={props.handleSelectTool}
      >
        <CustomIcon
          className="" // Required to override default class.
          name={`${label}_editor_tool`}
        />
      </button>
    ))}
  </div>
);

Toolbar.propTypes = { handleSelectTool: PropTypes.func.isRequired };

export default Toolbar;
