/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { getStateFromAbbr } from '../../services/country_data';
import { objHasPropertyOfLength } from '../predicates';

/**
 * Format in
 */
export function arrayToMultiSelectIn(values, labelTransform = (v) => v, valueTransform = (v) => v) {
  return values.map((v) => valueToSelectIn(v, labelTransform, valueTransform));
}

export function boolOrNullIn(val) {
  if (typeof val === 'boolean' || val === 'true' || val === 'false') return val.toString();

  return null;
}

export function boolOrStringToBool(val) {
  if (typeof val === 'boolean') return val;
  if (val === 'true' || val === 't') return true;

  return false;
}

export function imageWithNestedFileIn({ id, file = {}, name, dimensions = {} } = {}) {
  return { dimensions, id, name, url: file.url };
}

export function locationSelectIn(record) {
  // If we dont have a country, we want to ignore an initial request.
  if (!objHasPropertyOfLength(record, 'country_iso2')) return {};

  const city = record.city || '';
  const countryCode = record.country_iso2 || '';
  const state = objHasPropertyOfLength(record, 'state') ? getStateFromAbbr(record.state, countryCode) : '';
  const geoLocSpread = record.hasOwnProperty('latitude') && record.hasOwnProperty('longitude')
    ? { geoloc: { lat: record.latitude, lng: record.longitude } }
    : {};

  return {
    city,
    countryCode,
    state,
    ...geoLocSpread,
  };
}

export function lowerCaseIn(val) {
  if (!val || typeof val !== 'string') return null;

  return val.toLowerCase();
}

export function multiSelectIn(records, labelAttr = 'user_name', labelTransform = (v) => v) {
  return records.map((r) => ({
    label: labelTransform(r[labelAttr]),
    value: r.id,
  }));
}

export function valueToSelectIn(value, labelTransform = (v) => v, valueTransform = (v) => v) {
  return { label: labelTransform(value), value: valueTransform(value) };
}

/**
 * Format out
 */
export function boolOrNullOut(val) {
  if (typeof val === 'boolean') return val;
  if (typeof val === 'string') {
    if (!val.length) return null;
    if (val === 'true') return true;
    if (val === 'false') return false;

    return null;
  }

  return null;
}

export function imageWithNestedFileOut({ id, dimensions = {}, name, url } = {}) {
  return { dimensions, id, name, file: { url } };
}

export function locationSelectOut(val = {}, withGeoLoc = false) {
  const baseAcc = withGeoLoc && val.hasOwnProperty('geoloc')
    ? { latitude: (val.geoloc.lat || 0), longitude: (val.geoloc.lng || 0) }
    : {};

  // TODO: 05-05-2022 - Remove this transforms thing. We can represent states by regions via mapbox not Algolia places.
  // const transforms = {state: getStateForLocationSelectOutput};
  const transforms = {};
  const whitelist = { city: 'city', countryCode: 'country_iso2', state: 'state' };

  return Object.keys(whitelist).reduce((acc, key) => {
    if (val.hasOwnProperty(key)) {
      acc[whitelist[key]] = transforms.hasOwnProperty(key) ? transforms[key](val) : val[key];
    }

    return acc;
  }, baseAcc);
}

export function multiSelectToArrayOut(val = [], toType = (v) => v) {
  return val.map((v) => toType(v.value));
}

export function multiSelectToRecordShapeOut(val = [], keyValueMap = { id: 'value', label: 'name' }) {
  return val.map((v) => ({ id: v.value, name: v.label }));
}

export function numOrNullOut(val) {
  if (typeof val === 'number') return val;
  if (typeof val === 'string') {
    const numsOnly = (/^\d+$/).test(val);

    return numsOnly ? parseInt(val) : null;
  }

  return null;
}

export function stringOrNullOut(val) {
  if (typeof val === 'string') return val.length > 0 ? val : null;

  return null;
}
