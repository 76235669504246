// Maps to graphql/enums/project_content_type_enum.rb
const CONTENT_TYPE = {
  protip: 'PROTIP',
  showcase: 'SHOWCASE',
  tutorial: 'TUTORIAL',
  wip: 'WIP',
};
// Maps to graphql/enums/project_difficulty_enum.rb
const DIFFICULTY = {
  advanced: 'ADVANCED',
  beginner: 'EASY',
  hardcore: 'EXPERT',
  intermediate: 'INTERMEDIATE',
};
const KNOWN_PARAMS = {
  base_channel_id: (id) => parseInt(id),
  category_id: (id) => parseInt(id),
  contest_id: (id) => parseInt(id),
  part_id: (id) => parseInt(id),
  page: (page) => parseInt(page, 10),
  per_page: (per_page) => parseInt(per_page, 10),
  tag_id: (id) => parseInt(id),
  topic_id: (id) => parseInt(id),
};
// Maps to graphql/enums/project_sort_enum.rb
const SORT = {
  popular: 'POPULARITY',
  published: 'DATE_DESC',
  respected: 'RESPECTS',
  trending: 'TRENDING',
  updated: 'UPDATE_DATE',
};

const PLATFORM_TRANSFORMS = ['category_id'];
const PRIORITY_TRANSFORMS = ['featured', 'me'];

const composeQueryParameters = (queryMap) => {
  const params = filterQueryParameters(queryMap, KNOWN_PARAMS);

  return {
    ...params,
    ...getBy(queryMap),
    ...getVariableFromQueryMap(queryMap, 'content_type', CONTENT_TYPE),
    ...getVariableFromQueryMap(queryMap, 'sort', SORT),
    ...getVariableFromQueryMap(queryMap, 'difficulty', DIFFICULTY),
  };
};

const filterQueryParameters = (queryMap, map) => {
  const keys = Object.keys(queryMap);
  if (!keys.length) return {};
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  const isQueryForPlatform = queryMap.hasOwnProperty('base_channel_id');

  return keys.reduce((acc, key) => {
    if (PRIORITY_TRANSFORMS.includes(queryMap[key])) {
      acc = _transformPriorityValues(queryMap[key], acc);
    } else if (isQueryForPlatform && PLATFORM_TRANSFORMS.includes(key)) {
      acc = _transformForPlatform(key, queryMap[key], map[key], acc);
    } else if (map[key]) {
      acc[key] = map[key](queryMap[key]);
    }

    return acc;
  }, {});
};

const _transformForPlatform = (key, value, mapFn, acc) => {
  switch (key) {
    case 'category_id':
      acc['channel_category_id'] = mapFn(value);

      return acc;

    default:
      return acc;
  }
};

const _transformPriorityValues = (value, acc) => {
  switch (value) {
    case 'featured':
      acc['featured'] = true;

      return acc;

    case 'me':
      acc['user_toolbox'] = true;

      return acc;

    default:
      return acc;
  }
};

const getBy = (queryMap) => {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!queryMap.hasOwnProperty('by')) return {};
  if (queryMap.by === 'featured') {
    return { featured: true };
  } else if (queryMap.by === 'toolbox') {
    return { user_toolbox: true };
  }
};

const getVariableFromQueryMap = (queryMap, property, constant) => {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!queryMap.hasOwnProperty(property) || (queryMap[property] && !constant.hasOwnProperty(queryMap[property]))) return {};

  return { [property]: constant[queryMap[property]] };
};

export default function queryBuilder(queryMap) {
  return new Promise((resolve, reject) => {
    resolve(composeQueryParameters(queryMap));
  });
}
