import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Select from '../../select';

import { getCountryData } from '../../../../services/country_data';
import errorHandler from '../../../../services/error_handler';
import { fetchMapboxCountries } from '../../../../services/mapbox';
import { formatMapboxCountryRecordForSelectOpts } from '../../../../services/mapbox/formatters';
import { isBlank, isObjectWithLength } from '../../../../utility/types';

const DEFAULT_COUNTRY_SELECT_OBJ = { label: '', value: '' };

const formatMapboxResults = ({ features }) => {
  if (isBlank(features)) return { options: [] };

  const options = features
    .map((feature) => formatMapboxCountryRecordForSelectOpts(feature))
    .filter((n) => n !== null);

  return { options };
};

const CountrySelect = ({ countryCode, disabled, hasErrors, id, onChange, placeholder }) => {
  const countrySelectObj = useMemo(() => {
    if (isBlank(countryCode)) return DEFAULT_COUNTRY_SELECT_OBJ;

    const { name: label } = getCountryData(countryCode);
    return { label, value: countryCode };
  }, [countryCode]);

  const getCountryOptions = (query) => fetchMapboxCountries(query)
    .then((res) => formatMapboxResults(res))
    .catch((err) => errorHandler('CountrySelect getCountryOptions:', err));

  const onCountryChange = (opt) => {
    const optValid = isObjectWithLength(opt);
    onChange(optValid ? opt.value : null);
  };

  return (
    <div>
      <Select
        asyncOpts={{
          initOnMount: true,
          initQuery: countrySelectObj.label,
          request: getCountryOptions,
        }}
        disabled={disabled}
        hasErrors={hasErrors}
        onSelectedChange={onCountryChange}
        placeholder={placeholder}
        uiOpts={{ renderX: true }}
        uuid={id}
        value={countrySelectObj}
      />
    </div>
  );
};

CountrySelect.propTypes = {
  countryCode: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

CountrySelect.defaultProps = {
  countryCode: null,
  disabled: false,
  hasErrors: false,
  id: '',
  onChange: () => {},
  placeholder: 'Start typing to select a location!',
};

export default CountrySelect;
