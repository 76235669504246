import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import CarouselImage from './CarouselImage';
import Icon from '../../../client/icon';
import LazyComponent from '../../../client/wrappers/lazy_component';

import { aspectRatioPadding } from '../../../utility/math';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { cycleIndex, getCycledArray, updateActionsOnScroll } from './helpers';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import lazyImageStyles from '../../../client/reusable_components/LazyImage/lazy_image.css';
import styles from './image_carousel.css';

const HEADLINE_CONSTRAINT = { height: 555, width: 740 };
const FALLBACK_STYLES = {
  maxWidth: HEADLINE_CONSTRAINT.width,
  paddingTop: '75%',
};

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: props.activeIndex, // this will be coordinated with props.activeIndex - have it in state so that we can 'optimistically' update independant of scrolling logic
      isImgLoaded: false,
      lazyWrapperDims: null,
      loadedImageIndexes: { 0: true },
      noScroll: false,
      resizing: false,
      shouldHandleImgLoad: false,
    };

    this.centerImage = debounce(this.centerImage.bind(this), 300);
    this.centerScrollPosition = this.centerScrollPosition.bind(this);
    this.eagerUpdate = throttle(this.eagerUpdate.bind(this), 200);
    this.getBrokenBoundary = this.getBrokenBoundary.bind(this);
    this.handleImgLoad = this.handleImgLoad.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleScrollContainerReveal = this.handleScrollContainerReveal.bind(this);
    this.preventScrollAndUpdate = this.preventScrollAndUpdate.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.updateActiveIndex = this.updateActiveIndex.bind(this);
    this.updateAfterResize = debounce(this.updateAfterResize.bind(this), 600);
    this.updateImagePositions = debounce(this.updateImagePositions.bind(this), 40);
    this.updateOnBoundaryBreak = this.updateOnBoundaryBreak.bind(this);
    this.updateState = this.updateState.bind(this);

    // refs
    this._lazyWrapper;
    this._scrollContainer;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    // To prevent memory leak from async updates in Viewer mode
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeIndex !== prevProps.activeIndex) {
      this.centerScrollPosition();
    }
  }

  // TODO: Find way around the combo of getDerivedStateFromProps + componentDidUpdate
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.activeIndex === prevState.activeIndex) return null;

    return {
      activeIndex: nextProps.activeIndex,
      noScroll: false,
    };
  }

  componentWillUnmount() {
    if (window && document) {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('keydown', this.handleKeyDown);
    }
    this._isMounted = false;
  }

  /**
   * Helpers
   */
  _getImgPlaceholderStyle({ height, width } = {}) {
    if (!height || !width) return FALLBACK_STYLES;

    // don't exceed HEADLINE dimensions, and don't scale up higher than original dimensions
    const scale = Math.min((HEADLINE_CONSTRAINT.height / height), (HEADLINE_CONSTRAINT.width / width), 1);

    return {
      maxWidth: width * scale,
      paddingTop: aspectRatioPadding({ height, width }),
    };
  }

  _getLazyWrapperDims() {
    return {
      height: this._scrollContainer.clientHeight,
      width: Math.floor(this._scrollContainer.clientWidth),
    };
  }

  /**
   * Class Methods
   */
  centerImage() {
    this.updateOnBoundaryBreak('midpoint');
  }

  centerScrollPosition() {
    this._scrollContainer.scrollLeft = this._scrollContainer.clientWidth;
  }

  eagerUpdate() {
    this.updateOnBoundaryBreak('eager');
  }

  getBrokenBoundary(trigger) {
    const width = Math.floor(this._scrollContainer.clientWidth);
    const middleOfScreen = this._scrollContainer.scrollLeft + (width / 2);
    const scrollLeft = this._scrollContainer.scrollLeft;

    const rules = {
      midpoint: {
        snapLeft: width > middleOfScreen,
        center: (width * 2 > middleOfScreen) && (this._scrollContainer.scrollLeft !== this._scrollContainer.clientWidth),
        snapRight: width * 2 < middleOfScreen,
      },
      edge: {
        left: scrollLeft <= 0,
        right: (scrollLeft + width) >= width * 3,
      },
      eager: {
        eagerUpdateLeft: scrollLeft <= 0,
        eagerUpdateRight: (scrollLeft + width) >= width * 3,
      },
    }[trigger];

    return Object.keys(rules).reduce((memo, rule) => !memo && rules[rule] ? rule : memo, '');
  }

  handleImgLoad() {
    this.updateState({
      isImgLoaded: true,
      lazyWrapperDims: this._getLazyWrapperDims(),
      shouldHandleImgLoad: false,
    }, () => {
      this.centerScrollPosition();
    });
  }

  handleKeyDown(event) {
    if (!this.props.enableArrowKeyNavigation) return;

    switch (event.keyCode) {
      case 37:
        event.preventDefault();
        this.updateActiveIndex(this.props.activeIndex - 1);
        break;
      case 39:
        event.preventDefault();
        this.updateActiveIndex(this.props.activeIndex + 1);
        break;
      default:
        return;
    }
  }

  handleResize() {
    clearTimeout(this._resizeTimer);
    if (!this.state.resizing) this.setState({ lazyWrapperDims: null, resizing: true });
    this.updateAfterResize();
  }

  handleScrollContainerReveal() {
    if (window && document) {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('keydown', this.handleKeyDown);
    }

    this.updateState({ shouldHandleImgLoad: true }, () => {
      this.centerScrollPosition();
    });
  }

  preventScrollAndUpdate(update) {
    this.updateState({ noScroll: true }, () => update());
  }

  scrollHandler(event) {
    if (this._scrollContainer.scrollLeft === 0 || this.state.noScroll) event.preventDefault();

    this.centerImage();
    this.eagerUpdate();
    this.updateImagePositions();
  }

  updateActiveIndex(index) {
    this.props.updateActiveIndex(cycleIndex(index, this.props.images.length - 1));
  }

  updateAfterResize() {
    this.setState({ resizing: false, lazyWrapperDims: this._getLazyWrapperDims() }, () => this.centerScrollPosition());
  }

  updateImagePositions() {
    this.updateOnBoundaryBreak('edge');
  }

  updateOnBoundaryBreak(trigger) {
    const update = updateActionsOnScroll[this.getBrokenBoundary(trigger)];
    if (update) update(this);
  }

  updateState(...args) {
    if (this._isMounted) this.setState(...args);
  }

  /**
   * Views
   */
  _getCaption(activeIndex, images) {
    const showCount = images.length > 1;
    const showCaption = images[activeIndex] && images[activeIndex].caption;

    if (!showCount && !showCaption) return;

    return (
      <div className={styles.caption}>
        {showCount && (<span>{`${activeIndex + 1} / ${images.length}`}</span>)}
        {showCount && showCaption && ' • '}
        {showCaption && (<span>{images[activeIndex].caption}</span>)}
      </div>
    );
  }

  _getNavArrows(activeIndex) {
    if (this.props.images.length <= 1) return;

    return (
      <Fragment>
        <div className={styles.navAreaLeft} onClick={() => this.updateActiveIndex(activeIndex - 1)}>
          <div className={styles.hoverHighlightLeft}>
            <Icon className={typography.staticWhite} name="arrow-left" size={16} />
          </div>
        </div>
        <div className={styles.navAreaRight} onClick={() => this.updateActiveIndex(activeIndex + 1)}>
          <div className={styles.hoverHighlightRight}>
            <Icon className={typography.staticWhite} name="arrow-right" size={16} />
          </div>
        </div>
      </Fragment>
    );
  }

  _getImgPlaceholder(img) {
    const { maxWidth, paddingTop } = this._getImgPlaceholderStyle(img);

    return (
      <div style={{ maxWidth }}>
        <div className={utilStyles.absolutePlaceholderParent} style={{ paddingTop }} />
      </div>
    );
  }

  _getScrollContainer(activeIndex, images) {
    const cycledImages = getCycledArray(images, activeIndex);
    const containerStyles = `${layout.noScrollBar} ${styles.scrollContainer} ${this.state.noScroll ? styles.noScrollContainer : ''}`;

    return (
      <div
        ref={(el) => this._scrollContainer = el}
        className={`${containerStyles} ${this.state.isImgLoaded ? '' : styles.scrollContainerPreImgLoad}`}
        onTouchMove={cycledImages.length > 1 ? this.scrollHandler : null}
        onWheel={cycledImages.length > 1 ? this.scrollHandler : null}
      >
        {this._getNavArrows(activeIndex)}
        {this.state.resizing && cycledImages.length > 1
          ? <div className={styles.resizeOverlay}><img src={cycledImages[1].url} /></div>
          : cycledImages.map((image, i) => (
            <CarouselImage
              key={`${image.imageIndex}-${i}`}
              alt={image.caption}
              className={this.props.classList.image}
              format={image.format}
              handleImgLoad={this.handleImgLoad}
              hasLoaded={this.state.loadedImageIndexes[image.imageIndex]}
              onClick={this.props.onImageClick}
              shouldHandleImgLoad={this.state.shouldHandleImgLoad}
              shouldLoad={image.imageIndex === this.props.activeIndex || this.state.loadedImageIndexes[image.imageIndex] || i === 1}
              src={image.url}
            />
          ))}
      </div>
    );
  }

  _renderLazyChildren(isRevealed, activeIndex, images) {
    return (
      <Fragment>
        {!this.state.isImgLoaded && this._getImgPlaceholder(images[0])}
        {isRevealed && this._getScrollContainer(activeIndex, images)}
      </Fragment>
    );
  }

  render() {
    const { activeIndex, images } = this.props;

    return (
      <div className={`${styles.container} ${this.props.classList.container}`}>
        <LazyComponent
          ref={(el) => this._lazyWrapper = el}
          alwaysRenderChildren={true}
          className={`${styles.wrapper} ${lazyImageStyles.fade} ${this.state.isImgLoaded ? lazyImageStyles.fadeIn : ''}`}
          onReveal={this.handleScrollContainerReveal}
          style={(!this.props.inViewer && this.state.lazyWrapperDims) ? this.state.lazyWrapperDims : {}}
        >
          {(isRevealed) => this._renderLazyChildren(isRevealed, activeIndex, images)}
        </LazyComponent>
        {this._getCaption(activeIndex, images)}
      </div>
    );
  }
}

Carousel.propTypes = {
  activeIndex: PropTypes.number,
  classList: PropTypes.shape({
    container: PropTypes.string,
    image: PropTypes.string,
  }),
  enableArrowKeyNavigation: PropTypes.bool,
  images: PropTypes.array,
  inViewer: PropTypes.bool,
  onImageClick: PropTypes.func,
  updateActiveIndex: PropTypes.func.isRequired,
};

Carousel.defaultProps = {
  activeIndex: 0,
  classList: {},
  enableArrowKeyNavigation: false,
  images: [],
  inViewer: false,
  onImageClick: () => {},
};

export default Carousel;
