import { Component } from 'react';
import ReactDOM from 'react-dom';

import { canUseDOM } from '../../../utility/execEnvironment';

// This component should never be instantiated/rendered server side
const domExists = canUseDOM();
const rootEl = domExists ? document.getElementById('reactGlobalPortal') : null;

class GlobalPortal extends Component {
  constructor(props) {
    super(props);
    this._el = domExists ? document.createElement('div') : null;
  }

  componentDidMount() {
    if (rootEl && this._el) rootEl.appendChild(this._el);
  }

  componentWillUnmount() {
    if (rootEl && this._el) rootEl.removeChild(this._el);
  }

  render() {
    return this._el ? ReactDOM.createPortal(this.props.children, this._el) : null;
  }
}

export default GlobalPortal;
