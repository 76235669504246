import React from 'react';

import getGridClassNames from '../../projects/projects_list/getGridClassNames';

export const getDummyCells = (ItemComponent, maxCols, className) => (
  [...new Array(maxCols).keys()].map((i) => (
    <div key={i} className={className}>
      <ItemComponent />
    </div>
  ))
);

const DummyList = ({ ItemComponent, className = '', gutterSize, horizontal = false, maxCols = 4 }) => {
  const classNames = getGridClassNames(horizontal, maxCols, gutterSize);

  return (
    <div className={`${className} ${classNames.grid}`}>
      {getDummyCells(ItemComponent, maxCols, classNames.cell)}
    </div>
  );
};

export default DummyList;
