import { isEmail, isURL } from 'validator';
import { NOOP_HREF } from '../constants/links';

export const NO_URL = NOOP_HREF; // alias for duplicate constant

export function appendProtocol(url) {
  return isURL(url, { require_protocol: true }) ? url : `http://${url}`;
}

export function getExtFromFileName(fileName = '') {
  return fileName.substring(fileName.lastIndexOf('.') + 1);
}

export function getFileNameFromUrl(url = '') {
  const strippedURL = stripHashAndQuery(url);

  return strippedURL.substring(strippedURL.lastIndexOf('/') + 1);
}

export function isValidUrl(text) {
  return isURL(text) && !isEmail(text);
}

export function stripHashAndQuery(link) {
  // eslint-disable-next-line no-useless-escape
  return link.replace(/\#(.*?)$/, '').replace(/\?(.*?)$/, '');
}

export function stripProtocol(link) {
  // Strips http://, https://, ://, //
  return link.replace(/http:\/\/|https:\/\/|:\/\/|\/\//, '');
}

export function stripTrailingSlash(link) {
  return link.replace(/\/$/, '');
}
