import buildFacetFiltersForWhitelabel from './filters/buildFacetFiltersForWhitelabel';
import buildParamsFromFilters from './filters/buildParamsFromFilters';
import getAlgoliaIndex, { getAlgoliaIndexName, initAlgoliaClient } from './getAlgoliaIndex';
import { defaultWhiteList } from './stores/IndexSettings';
import { pluralize } from '../../utility/formatters';

function buildParamsForSearchApp(params, filters, index) {
  // Note:  Quick fix for now.  Im going to create a specific service for the search app and create stronger methods to compose
  // these request params.
  if (index === 'parts') {
    const facetFilters = params.facetFilters || [];
    params = { ...params, facetFilters: facetFilters.concat([['has_platform:true']]) };
  }

  if (index === 'projects') {
    const facetFilters = params.facetFilters || [];
    params = { ...params, facetFilters: facetFilters.concat([['approved:true']]) };
  }

  if (index === 'videos' || index === 'contests') {
    params = { ...params, hitsPerPage: 18, facetFilters: (params.facetFilters || []) };
  }

  return {
    ...params,
    ...buildParamsFromFilters(index, filters),
    facets: '*',
    getRankingInfo: true,
  };
}

function _buildParamsForSearchAppWhiteLabels(params, filters, index) {
  // Note: Separating logic for whitelabels in case we need more flexibility in the future.
  if (index === 'parts') {
    const facetFilters = params.facetFilters || [];
    params = { ...params, facetFilters: facetFilters.concat([['has_platform:true']]) };
  }

  return {
    ...params,
    ...buildParamsFromFilters(index, filters),
    facets: '*',
    getRankingInfo: true,
  };
}

function getSuggestionByCategory(q, suggestion) {
  return new Promise((resolve, reject) => searchIndex({ ...q, index: suggestion.category })
    .then((res) => res.nbHits > 0 ? resolve({ index: suggestion.category, results: res.hits }) : resolve(null))
    .catch((err) => reject(err)));
}

function getSuggestionByQuery(q) {
  return new Promise((resolve, reject) => searchIndex({ ...q, params: { ...q.params, facetFilters: [`query:${q.query}`] }, index: 'suggestions' })
    .then((res) => {
      const bestMatch = res.nbHits > 0 ? _getBestSuggestedMatch(q, res.hits) : null;

      return bestMatch && _validateSuggestionCategory(bestMatch) ? getSuggestionByCategory(q, bestMatch) : getSuggestionWaterfall(q);
    })
    .then((res) => resolve(res))
    .catch((err) => reject(err)));
}

// For now this just will get the first record algolia gives us, we can improve this functionality later.
function _getBestSuggestedMatch(q, hits) {
  return { ...hits[0], category: pluralize(hits[0].category).toLowerCase() };
}

function getSuggestionWaterfall(q) {
  return new Promise((resolve, reject) => fetchUserByFacet(q)
    .then((res) => res.nbHits > 0 ? resolve({ index: 'users', results: res.hits }) : fetchChannelByFacet(q))
    .then((res) => res.nbHits > 0 ? resolve({ index: 'channels', results: res.hits }) : fetchPartByFacet(q))
    .then((res) => res.nbHits > 0 ? resolve({ index: 'parts', results: res.hits }) : resolve(null))
    .catch((err) => reject(err)));
}

function fetchPartByFacet(q) {
  return searchIndex({ ...q, params: { ...q.params, facetFilters: [`name:${q.query}`] }, index: 'parts' });
}

function fetchChannelByFacet(q) {
  return searchIndex({ ...q, params: { ...q.params, facetFilters: [`name:${q.query}`] }, index: 'channels' });
}

function fetchUserByFacet(q) {
  return searchIndex({ ...q, params: { ...q.params, facetFilters: [`name:${q.query}`] }, index: 'users' });
}

function getSuggestionStrategy(suggestion) {
  return _validateSuggestionCategory(suggestion) ? getSuggestionByCategory : getSuggestionByQuery;
}

function _validateSuggestionCategory(suggestion) {
  if (suggestion && suggestion.category) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return defaultWhiteList.hasOwnProperty(suggestion.category);
  }

  return false;
}

// Duplicated searchIndex so we don't muddle up those calls in search later.  This way it'll be easier when we refactor.
function _searchIndexForWhiteLabel({ index, query, sort = null, params = {}, filters = {} }) {
  return new Promise((resolve, reject) => {
    const algoliaIndex = getAlgoliaIndex(index, sort);
    if (algoliaIndex instanceof Error) reject(algoliaIndex);

    return algoliaIndex.search(query, _buildParamsForSearchAppWhiteLabels(params, filters, index))
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export function searchForWhitelabel(q, whitelabel) {
  const params = q.params || {};
  const paramsWithFacets = { ...params, facetFilters: buildFacetFiltersForWhitelabel(q.index, whitelabel, q.sort, params.facetFilters) };

  return _searchIndexForWhiteLabel({ ...q, params: paramsWithFacets });
}

export function searchWithSuggestion(q, suggestion = null) {
  const suggestionStrategy = getSuggestionStrategy(suggestion);

  return new Promise((resolve, reject) => Promise.all([
    suggestionStrategy({ query: q.query, index: q.index, params: { hitsPerPage: 5, page: 0 } }, suggestion),
    searchIndex(q),
  ])
    .then((res) => resolve({ suggestion: res[0], res: res[1] }))
    .catch((err) => reject(err)));
}

// Use for all other algolia search services.
export function searchIndexWithFacets({ index, facetFilters = [], sort = null, params = {}, query = '', facets = [] }) {
  return new Promise((resolve, reject) => {
    const algoliaIndex = getAlgoliaIndex(index, sort);
    if (algoliaIndex instanceof Error) reject(algoliaIndex);

    return algoliaIndex.search(query, { facets, facetFilters, ...params })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

// Takes an array of algolia param objects, like: {indexName: 'parts', sort: 'a_z', params: {hitsPerPage: '20', filters: ''}, query: 'user input' }
// If the index or sort doesn't exist, it should throw an error. The other params, like filters, aren't being checked or using builders currently.
export function searchMultipleIndexes(queries) {
  return new Promise((resolve, reject) => {
    const queriesWithValidIndexes = [];
    for (let i = 0; i < queries.length; i++) {
      const { indexName, params, query, sort } = queries[i];
      const currIndexName = getAlgoliaIndexName(indexName, sort);

      if (currIndexName instanceof Error) {
        reject(currIndexName);
        return;
      } else {
        queriesWithValidIndexes.push({ params, query, indexName: currIndexName }); // Adds proper indexName and removes sort.
      }
    }

    // Note: If we upgrade to v4, this will become #multipleQueries.
    return initAlgoliaClient()
      .search(queriesWithValidIndexes)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

// Use for search app only!  I'm going to deprecate this function once the search app is pulled into its own service.
export default function searchIndex({ index, query, sort = null, params = {}, filters = {} }) {
  return new Promise((resolve, reject) => {
    const algoliaIndex = getAlgoliaIndex(index, sort);
    if (algoliaIndex instanceof Error) reject(algoliaIndex);

    return algoliaIndex.search(query, buildParamsForSearchApp(params, filters, index))
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}
