import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DateRange from '../../../../../client/cards/event_card/DateRange';
import DateRangePlaceholder from './DateRangePlaceholder';
import EventTypeOrLocation from '../../../../../client/cards/event_card/EventTypeOrLocation';
import Icon from '../../../../../client/icon';
import LazyBackgroundImage from '../../../../../client/image/LazyBackgroundImage';
import SponsorList from '../../../../../client/cards/event_card/SponsorList';
import { TextPlaceholder } from './templates';

import { getDateRangeAsText } from '../../../../../client/cards/event_card/eventCardHelpers';
import { getInObj } from '../../../../../utility/accessors';
import { isBlank } from '../../../../../utility/types';

import colors from '../../../../../styles/global_ui/colors.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import eventCardStyles from '../../../../../client/cards/event_card/event_card.css';
import previewStyles from './preview_cards.css';

const getImage = (imageUrl, title) => (
  <LazyBackgroundImage
    key={imageUrl}
    alt={title}
    classList={{ image: utilStyles.borderRadiusLarge }}
    ratio="3:2"
    setStyle={false}
    src={imageUrl} /* reInit LazyBackgroundImage when the source changes */
    width={555}
  />
);

const getTitlePlaceholder = () => (
  <Fragment>
    <TextPlaceholder backgroundColor={colors['v-asphalt']} height={34} width="100%" />
    <TextPlaceholder backgroundColor={colors['v-asphalt']} height={34} marginTop={10} width="100%" />
  </Fragment>
);

const EventCardSponsoredLargePreview = ({ className, imageRight, item }) => {
  const imageUrl = getInObj(['image', 'url'], item);

  return (
    <div className={`${layout.flexCenterItems} ${imageRight ? layout.flexRowReverse : layout.flexRow} ${eventCardStyles.largeCard} ${typography.breakWord} ${layout.fullWidth} ${className}`}>
      <div className={`${layout.flex1} ${eventCardStyles.imageLarge} ${utilStyles.posRelative} ${utilStyles.borderRadiusLarge} ${imageUrl ? '' : utilStyles.bgAsphalt}`}>
        {!!imageUrl && getImage(imageUrl, item.title)}
        <div className={eventCardStyles.dateBadge}>
          {(item.start_date || item.end_date)
            ? <DateRange endDate={item.end_date} startDate={item.start_date} />
            : <DateRangePlaceholder />}
        </div>
      </div>
      <div className={`${typography.bodyL} ${layout.flexColumnWrap} ${layout.flex1} ${imageRight ? eventCardStyles.largeCardInfoWrapperLeft : eventCardStyles.largeCardInfoWrapperRight}`}>
        <div
          className={`${eventCardStyles.largeCardInfoSection} ${previewStyles.largeCardInfoSection}`}
          onClick={(e) => e.preventDefault()}
        >
          {isBlank(item.platforms) && isBlank(item.custom_sponsors)
            ? <TextPlaceholder backgroundColor={colors['v-asphalt']} height={18} width="50%" />
            : <SponsorList item={item} />}
        </div>
        <div className={`${eventCardStyles.largeCardInfoSection} ${previewStyles.largeCardInfoSection}`}>
          {item.title
            ? <span className={`${typography.linkCharcoal} ${typography.h2Responsive} ${eventCardStyles.largeCardTitle}`}>{item.title}</span>
            : getTitlePlaceholder()}
        </div>
        {!!item.summary && <div className={`${eventCardStyles.largeCardInfoSection} ${previewStyles.largeCardInfoSection}`}>{item.summary}</div>}
        <div className={`${eventCardStyles.largeCardInfoSection} ${previewStyles.largeCardInfoSection}`}>
          <div className={`${layout.inlineFlex} ${layout.flexCenterItems}`}>
            {(item.start_date || item.end_date)
              ? getDateRangeAsText(item)
              : <TextPlaceholder backgroundColor={colors['v-asphalt']} height={18} width={80} />}
            <span className={`${layout.marginLeft10} ${layout.marginRight10}`}>•</span>
            {/* TODO: this logic might change */}
            {item.event_type
              ? <EventTypeOrLocation item={item} />
              : <TextPlaceholder backgroundColor={colors['v-asphalt']} height={18} width={80} />}
          </div>
          <span className={`${eventCardStyles.ctaLink} ${typography.bold} ${typography.linkBlue} ${typography.textWithIcon}`}>
            Learn more
            <Icon className={layout.marginLeft5} name="arrow-right" />
          </span>
        </div>
      </div>
    </div>
  );
};

EventCardSponsoredLargePreview.propTypes = {
  event: PropTypes.shape({
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    end_date: PropTypes.string,
    event_type: PropTypes.string,
    image: PropTypes.shape({ url: PropTypes.string }),
    location: PropTypes.shape({}),
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    start_date: PropTypes.string,
    state: PropTypes.string,
    title: PropTypes.string,
  }),
};

EventCardSponsoredLargePreview.defaultProps = {
  event: {
    custom_sponsors: [],
    end_date: null,
    event_type: null,
    image: null,
    location: null,
    platforms: [],
    start_date: null,
    state: null,
    title: null,
  },
};

export default EventCardSponsoredLargePreview;
