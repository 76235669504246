import PropTypes from 'prop-types';

import { buildCarousel, buildEmbed, buildHTML } from './templates';

const StoryJSONBlock = ({ item, index, buildHeaderID }) => {
  switch (item.type) {
    case 'CE':
      return buildHTML(item.json, buildHeaderID);
    case 'Carousel':
      return buildCarousel(item.images, index.toString());
    case 'Embed':
      return buildEmbed(item.data);
    case 'Video':
      // TODO: Refactor this. Disabling for linter clean-up
      // eslint-disable-next-line no-case-declarations
      const video = Array.isArray(item.video) ? item.video[0] : item.video;

      return buildEmbed(video);
    case 'WidgetPlaceholder':
    case 'WidgetPlacholder': // Backwards compatible for old typo
      if (item.data.type === 'twitter') return buildEmbed(item.data);

      return null;
    default:
      console.warn(`Draftster block type ${item.type} not yet implemented in StoryJSONBlock`, item);

      return null;
  }
};

StoryJSONBlock.propTypes = {
  buildHeaderID: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    type: PropTypes.oneOf(['CE', 'Carousel', 'Embed', 'WidgetPlaceholder', 'WidgetPlacholder', 'Video']).isRequired,
    data: PropTypes.object, // for Embed, WidgetPlaceholder
    images: PropTypes.array, // for Carousel
    json: PropTypes.array, // for CE
    video: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // for Video
  }).isRequired,
};

export default StoryJSONBlock;
