import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../buttons/base';

import inputStyles from '../../../styles/global_ui/inputs.css';
import layoutStyles from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const config = {
  login: {
    body: 'Don’t have an account yet? ',
    link: 'Join here.',
    goTo: 'signup',
    wrapperClass: `${layoutStyles.marginTop30} ${typography.textCenter}`,
  },
  signup: {
    body: 'Already have an account? ',
    link: 'Log in.',
    goTo: 'login',
    wrapperClass: `${layoutStyles.marginTop30} ${typography.textCenter}`,
  },
  email: {
    body: null,
    link: 'Create an account here.',
    goTo: 'signup',
    wrapperClass: inputStyles.help,
  },
};

const Switcher = ({ currentConfig, disabled, onClick }) => {
  const { body, link, goTo, wrapperClass } = config[currentConfig];

  return (
    <div className={wrapperClass}>
      {body}
      <Button colorStyle="blank" disabled={disabled} onClick={() => onClick(goTo)}>
        <span className={`${typography.bodyM} ${typography.linkBlue} ${disabled ? typography.linkDisabled : ''}`}>
          {link}
        </span>
      </Button>
    </div>
  );
};

Switcher.propTypes = {
  currentConfig: PropTypes.oneOf(['email', 'login', 'signup']).isRequired,
  onClick: PropTypes.func,
};

Switcher.defaultProps = { onClick: () => {} };

export default Switcher;
