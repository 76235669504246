import React from 'react';
import PropTypes from 'prop-types';

import NameCell from './NameCell';
import { formatNumberWithCommas } from '../../../../utility/formatters';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './section_list.css';

function getTypeForKey(key) {
  const map = {
    cta: 'Call to action',
    part_id: 'Product',
    promoted: 'Promoted content',
    video: 'Videos',
  };
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
  return map.hasOwnProperty(key) ? map[key] : 'Category';
}

const DragHandle = ({ attributes, listeners }) => (
  <span {...attributes} {...listeners} className={styles.dragHandle}>
    <span className={styles.dragHandleIcon}>::</span>
  </span>
);

const SectionCard = ({ attributes, item, itemIndex, listeners, removeItem, toggleView, updateItem }) => {
  const { key, label, meta, record, title } = item;

  return (
    <div className={`${layout.flex} ${layout.fullWidth}`}>
      <DragHandle attributes={attributes} listeners={listeners} />

      <NameCell
        index={itemIndex}
        item={item}
        meta={meta || null}
        title={title}
        toggleView={toggleView}
        updateTitle={(title) => updateItem({ index: itemIndex, data: { ...item, title } })}
      />

      <span className={`${styles.cell} ${styles.cell20}`}>{getTypeForKey(key)}</span>

      <span className={`${styles.cell} ${styles.cell20}`}>
        <span className={styles.tag}>{label}</span>
      </span>

      <span className={`${styles.cell} ${styles.cell10}`}>{formatNumberWithCommas(record && record.projects_count ? record.projects_count : 0)}</span>

      <span className={`${styles.cell} ${styles.cell10}`}>
        <button className={buttonStyles.blank} onClick={() => removeItem({ index: itemIndex, data: item })} type="button">
          <span className={`${typography.bodyS} ${typography.linkBlue} ${typography.bold}`}>
            remove
          </span>
        </button>
      </span>
    </div>
  );
};

SectionCard.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    record: PropTypes.shape({ projects_count: PropTypes.number }),
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
  }).isRequired,
  itemIndex: PropTypes.number.isRequired,
  removeItem: PropTypes.func.isRequired,
  toggleView: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

SectionCard.defaultProps = { record: { projects_count: 0 } };

export default SectionCard;
