import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LazyComponent from '../../../wrappers/lazy_component';
import MediaElement from '../../../image/MediaElement';

import { aspectRatioPadding } from '../../../../utility/math';
import { VIDEO } from '../../../image/helpers';

import lazyImageStyles from '../../../reusable_components/LazyImage/lazy_image.css';
import utilStyles from '../../../../styles/global_ui/util.css';

class LazyMP4 extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { isLoaded: false };
  }

  render() {
    const { embed, height, width } = this.props.data;
    const aspectRatio = (width / height) || (16 / 9);

    return (
      <LazyComponent
        className={`${utilStyles.absolutePlaceholderParent} ${lazyImageStyles.fade} ${this.state.isLoaded ? lazyImageStyles.fadeIn : ''}`}
        style={{ paddingBottom: aspectRatioPadding({ aspectRatio }) }}
        verticalOffset={250}
      >
        {() => (
          <MediaElement
            className={`${utilStyles.absolutePlaceholderChild} ${utilStyles.overflowHidden}`}
            format={VIDEO}
            onLoad={() => this.setState({ isLoaded: true })}
            src={embed}
            videoRole="video"
          />
        )}
      </LazyComponent>
    );
  }
}

LazyMP4.propTypes = {
  data: PropTypes.shape({
    embed: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    figcaption: PropTypes.string,
  }).isRequired,
};

export default LazyMP4;
