import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '../../reusable_components/Dialog';

import { getInObj } from '../../../utility/accessors';

class PushStateDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entity: null,
      open: false,
    };

    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.popHistory = this.popHistory.bind(this);
    this.pushHistory = this.pushHistory.bind(this);

    this._dismissPopStatePending = false;
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleLocationChange);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  _summonDialog(entity, shouldPushHistory = true) {
    this.props.onOpen(!shouldPushHistory, entity);
    this.setState({ entity, open: true }, () => {
      this._afterSummon(entity, shouldPushHistory);
    });
  }

  _buildPath(entity) {
    return this.props.buildPath(entity);
  }

  _dismiss(shouldPopHistory = true) {
    this.setState({ open: false }, () => this._afterDismiss(shouldPopHistory));
  }

  _afterDismiss(shouldPopHistory) {
    if (shouldPopHistory) {
      this._dismissPopStatePending = true;
      this.popHistory();
    } else {
      this.props.onDismiss(true); // true === was from POP action
    }
  }

  _afterSummon(entity, shouldPushHistory) {
    if (shouldPushHistory) this.pushHistory(entity);
  }

  handleDismiss() {
    this._dismiss();
  }

  handleLocationChange(location, action) {
    if (action === 'POP') { // back or forward browser button
      if (this._dismissPopStatePending) {
        // this is so we know window.location has been changed before calling onDismiss callback
        this._dismissPopStatePending = false;
        this.props.onDismiss(false); // false === not from POP action originally
      } else if (!this.state.open && getInObj(['state', 'entity'], location)) {
        this._summonDialog(location.state.entity, false);
      } else if (this.state.open) {
        this._dismiss(false);
      }
    }
  }

  popHistory() {
    this.props.history.goBack();
  }

  pushHistory(entity = {}) {
    this.props.history.push(this._buildPath(entity), { entity });
  }

  _renderChildren() {
    const { BodyComponent, children } = this.props;

    return typeof BodyComponent === 'function'
      ? <BodyComponent entity={this.state.entity} />
      : React.Children.count(children) === 1
        ? React.cloneElement(children, { entity: this.state.entity })
        : children;
  }

  render() {
    const {BodyComponent, buildPath, children, history, onDismiss, onOpen, ...dialogProps} = this.props;  // eslint-disable-line

    return (
      <Dialog
        {...dialogProps}
        dismiss={this.handleDismiss}
        open={this.state.open}
      >
        {this.state.entity !== null && this._renderChildren()}
      </Dialog>
    );
  }
}

PushStateDialog.propTypes = {
  BodyComponent: PropTypes.func,
  buildPath: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onDismiss: PropTypes.func,
  onOpen: PropTypes.func,
};

PushStateDialog.defaultProps = {
  BodyComponent: null,
  onDismiss: (fromPopAction) => {},
  onOpen: (fromPopAction, entity) => {},
};

export default PushStateDialog;
