import { unescape } from 'validator';

const SYNTAX_CHARS = '@[]()'.split('');
const SYNTAX_CODES = SYNTAX_CHARS.map((char) => char.charCodeAt(0));

function isValidJSON(obj) {
  try {
    JSON.parse(obj);

    return true;
  } catch (err) {
    return false;
  }
}

function mention_replace(md, config) {
  return function tokenize(state, silent) {
    const oldPos = state.pos;
    const max = state.posMax;

    let metaData,
      userName,
      invalidSyntax = false;

    if (silent) return false;

    // Match @
    if (state.src.charCodeAt(state.pos) !== SYNTAX_CODES[0]) return false;
    // Match [
    if (state.src.charCodeAt(state.pos + 1) !== SYNTAX_CODES[1]) return false;

    // Get userName
    state.pos = state.pos + 2;
    const openingBracket = state.pos;

    while (state.pos <= max) {
      if (state.pos === max) {
        invalidSyntax = true;
        break;
      }

      if (state.src.charCodeAt(state.pos) === SYNTAX_CODES[2] // Match ]
        && state.src.charCodeAt(state.pos + 1) === SYNTAX_CODES[3]) { // Match (
        // Found closing bracket, save userName.
        const closingBracket = state.pos;
        userName = unescape(state.src.slice(openingBracket, closingBracket));
        state.pos = state.pos + 1; // Move to opening parens
        break;
      }

      state.pos += 1;
    }

    // Bail if closing bracket not found
    if (invalidSyntax) {
      state.pos = oldPos;

      return false;
    }

    // Cursor is at opening parenthesis
    if (state.src.charCodeAt(state.pos) !== SYNTAX_CODES[3]) { // Match (
      state.pos = oldPos;

      return false;
    }

    // Get metaData
    const openingCurly = state.pos + 1;

    while (state.pos <= max) {
      if (state.pos === max) {
        invalidSyntax = true;
        break;
      }

      if (state.src.charCodeAt(state.pos) === SYNTAX_CODES[4]) { // Match )
        // Found closing curly bracket, get metaData.
        const closingCurly = state.pos;
        metaData = unescape(state.src.slice(openingCurly, closingCurly));
        state.pos = state.pos + 1; // Move to closing parens
        break;
      }

      state.pos += 1;
    }

    // Bail here if we dont find ) char.
    if (invalidSyntax || isValidJSON(metaData) === false) {
      state.pos = oldPos;

      return false;
    }

    // Successfully found a mention, create it.
    const token = state.push('mention');
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    const uuid = state.hasOwnProperty('env') && state.env && state.env.hasOwnProperty('uuid') ? state.env.uuid : null;
    token.content = `@${userName}`;
    // TODO: UUID needs to be on a per parse basis!
    token.attrs = [['href', JSON.parse(metaData).url], ['target', '_blank'], ['data-react-popover', metaData], ['data-mv-key', uuid]];

    state.md.inline.tokenize(state);

    return true;
  };
}

export default function mention(md, config) {
  md.renderer.rules.mention = function(tokens, idx, options, env, self) {
    return `<a class=${config.highlightClass} ${self.renderAttrs(tokens[idx])}>${tokens[idx].content}</a>`;
  };
  // We must insert this rule before an inline link ()[].
  md.inline.ruler.before('link', 'mention', mention_replace(md, config));
}
