import errorHandler from '../services/error_handler';
import { v4 as uuidv4 } from 'uuid';

const MAX_ATTEMPTS = 3;

function modId(oldId) {
  return `${oldId}x`;
}

function reportDupe(uuid, attempt) {
  errorHandler(new Error(`duplicate uuid: ${uuid}, attempt #${attempt}`));
}

function tryId(oldId, newId, i = 0) {
  if (oldId === newId) {
    reportDupe(oldId, i);

    return i < MAX_ATTEMPTS ? tryId(oldId, uuidv4(), i + 1) : modId(oldId);
  } else {
    return newId;
  }
}

export default function uuidDedupe(oldId) {
  return tryId(oldId, uuidv4());
}
