export const DRAFT_STATUS = 'DRAFT';
export const PUBLISHED_STATUS = 'PUBLISHED';
export const SCHEDULED_STATUS = 'SCHEDULED';
export const SUBMITTED_STATUS = 'SUBMITTED';

export const ALL_SORT = 'ALL_FOR_ADMIN';
export const PUBLISHED_SORT = 'PUBLISHED_AT';
export const TRENDING_DESC_SORT = 'TRENDING_DESC';
export const UPDATED_SORT = 'UPDATED_AT';

export const ALL_FILTER = 'all';
export const DRAFT_FILTER = 'draft';
export const PUBLISHED_FILTER = 'published';
export const SCHEDULED_FILTER = 'scheduled';
export const SUBMITTED_FILTER = 'submitted';
export const SPONSORED_FILTER = 'sponsored';

const FILTER_ENUM_MAP = {
  [DRAFT_FILTER]: DRAFT_STATUS,
  [PUBLISHED_FILTER]: PUBLISHED_STATUS,
  [SCHEDULED_FILTER]: SCHEDULED_STATUS,
  [SUBMITTED_FILTER]: SUBMITTED_STATUS,
};

const SORT_FILTER_ENUM_MAP = {
  [DRAFT_FILTER]: UPDATED_SORT,
  [PUBLISHED_FILTER]: PUBLISHED_SORT,
  [SCHEDULED_FILTER]: PUBLISHED_SORT,
  [SUBMITTED_FILTER]: UPDATED_SORT,
  [SPONSORED_FILTER]: UPDATED_SORT,
};

export function getStatusForFilterEnum(filter) {
  // Protect against user input in urls, fallback to null.
  return FILTER_ENUM_MAP[filter] || null;
}

export function getSortEnumForFilter(filter = null) {
  if (!filter) return ALL_SORT;
  // Protect against user input in urls, fallback to the default ALL_SORT.
  return SORT_FILTER_ENUM_MAP[filter] || ALL_SORT;
}
