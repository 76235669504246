import { Map } from 'immutable';
import getAlgoliaIndex from '../getAlgoliaIndex';

export const defaultWhiteList = {
  channels: true,
  contests: true,
  events: true,
  parts: true,
  news_articles: true,
  projects: true,
  users: true,
  videos: true,
};

// Note: make sure these match in buildFacetFiltersForWhitelabel to map the correct fields.
export const whitelabelWhiteList = {
  parts: true,
  projects: true,
  users: true,
};

export default class IndexSettings {
  constructor() {
    this.settings = Map();
    this.whitelists = {
      default: defaultWhiteList,
      whitelabel: whitelabelWhiteList,
    };
  }

  getSetting(index) {
    return new Promise((resolve, reject) => {
      if (!this._inWhiteList(index)) return reject(new Error(`IndexSettings cannont get setting for invalid index: ${index}`));

      if (this.settings.has(index)) return resolve(this.settings.get(index));

      return getAlgoliaIndex(index).getSettings()
        .then((indexSettings) => {
          this.settings = this.settings.set(index, indexSettings);
          resolve(indexSettings);
        })
        .catch((err) => reject(err));
    });
  }

  // Note: this is dangerous, you need to turn it on in Algolia for the api key used to enable it.
  __setSetting(index, settings = {}) {
    return new Promise((resolve, reject) => {
      if (!this._inWhiteList(index)) reject(new Error(`IndexSettings cannont set setting for invalid index: ${index}`));

      return getAlgoliaIndex(index).setSettings(settings)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  _inWhiteList(index, whitelabel = null) {
    if (!index || !index.length) return false;

    const map = whitelabel !== null ? this.whitelists.whitelabel : this.whitelists.default;
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return map.hasOwnProperty(index.toLowerCase());
  }
}
