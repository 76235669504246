import React from 'react';
import PropTypes from 'prop-types';

import ButtonDropdown from '../../../../buttons/button_dropdown';
import Checkbox from '../../../../form_components/checkboxes/custom';
import Icon from '../../../../icon';

import { RANKED_PRIZES_MODE, CATEGORY_CUSTOM, CATEGORY_RANKED, RUNNER_UPS } from './helpers/constants';

import buttonStyles from '../../../../../styles/global_ui/buttons.css';
import dropdownStyles from '../../../../../styles/common-dropdown.css';
import styles from './prize_editor.css';

const DEFAULT_OPTS = [
  { label: 'Runner ups', value: RUNNER_UPS },
];

const CATEGORY_OPTS = [
  { label: 'Ranked Prize', value: CATEGORY_RANKED },
  { label: 'Unranked Prize', value: CATEGORY_CUSTOM },
  { label: 'Runner ups', value: RUNNER_UPS },
];

const getOptions = (mode, categories) => mode === RANKED_PRIZES_MODE ? DEFAULT_OPTS : getCategoryOpts(categories);

const getCategoryOpts = (categories) => {
  const hasRunnerUps = categories.findIndex((cat) => cat.type === RUNNER_UPS) !== -1;

  return hasRunnerUps ? CATEGORY_OPTS.filter((opt) => opt.value !== RUNNER_UPS) : CATEGORY_OPTS;
};

const getLabelForDropdown = () => (
  <span>
    <span>Create category </span>
    <Icon name="arrow-down" size="12" />
  </span>
);

const onOptClick = (value, addCategory) => {
  if (value !== null) addCategory(value);
};

const shouldRenderCheckbox = ({ categories }) => categories.length > 1;

const shouldRenderDropdown = ({ categories, mode }) => {
  if (mode !== RANKED_PRIZES_MODE) return true;

  return categories.findIndex((cat) => cat.type === RUNNER_UPS) === -1;
};

const Menu = ({ addCategory, categories, mode, onSortModeCheckboxChange, sortMode }) => (
  <div className={styles.menuContainer}>
    {shouldRenderCheckbox({ categories })
    && (
      <div>
        <Checkbox
          isChecked={sortMode}
          label="Sort categories"
          onChange={(checked) => onSortModeCheckboxChange(checked)}
          value={sortMode}
        />
      </div>
    )}

    {shouldRenderDropdown({ categories, mode })
    && (
      <ButtonDropdown
        alignRight={true}
        classList={{
          wrapper: styles.categoryDropdown,
          button: `${buttonStyles.md} ${buttonStyles.secondary}`,
        }}
        closeOnSelect={true}
        label={getLabelForDropdown()}
      >
        <ul className={dropdownStyles.list}>
          {getOptions(mode, categories).map((opt, i) => (
            <li key={i} className={dropdownStyles.listItem} onClick={() => onOptClick(opt.value, addCategory)}>
              {opt.label}
            </li>
          ))}
        </ul>
      </ButtonDropdown>
    )}
  </div>
);

Menu.propTypes = {
  addCategory: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string.isRequired })),
  mode: PropTypes.string.isRequired,
  onSortModeCheckboxChange: PropTypes.func.isRequired,
  sortMode: PropTypes.bool.isRequired,
};

export default Menu;
