/* eslint-disable react/jsx-key */

import React from 'react';
import PropTypes from 'prop-types';

import ActionableButton from '../../buttons/actionable';
import Button from '../../buttons/base';
import Dialog from './index';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './prompt.css';

/**
 * Components
 */
const Body = ({ body, classList }) => (
  <div className={`${typography.bodyM} ${classList.body}`}>{body}</div>
);

const Title = ({ classList, title }) => (
  <h2 className={`${typography.h2} ${layout.marginBottom15} ${classList.title}`}>
    {title}
  </h2>
);

/**
 * Helpers
 */
const buildActions = ({ action, actionColor, dismiss, isBusy, okay }) => [
  <ActionableButton color={actionColor} isBusy={isBusy} onClick={okay} text={action} />,
  <Button colorStyle="cancel" disabled={isBusy} onClick={dismiss}>Cancel</Button>,
];

const buildTitle = ({ classList, title }) => {
  if (!title) return null;

  return (<Title classList={classList.title} title={title} />);
};

/**
 * Main
 */
const Prompt = ({ action, actionColor, body, classList, dismiss, isBusy, message, okay, open, title }) => (
  <Dialog
    actions={buildActions({ action, actionColor, dismiss, isBusy, okay })}
    actionsContainerStyle={{ marginTop: 30 }}
    dismiss={dismiss}
    enableCloseButton={false}
    message={message}
    open={open}
    title={buildTitle({ classList, title })}
    wrapperClassName={styles.wrapper}
  >
    <Body body={body} classList={classList} />
  </Dialog>
);

Prompt.propTypes = {
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  actionColor: PropTypes.string,
  body: PropTypes.node,
  classList: PropTypes.shape({
    body: PropTypes.string,
    title: PropTypes.string,
  }),
  dismiss: PropTypes.func,
  isBusy: PropTypes.bool,
  message: PropTypes.element,
  okay: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

Prompt.defaultProps = {
  action: 'Okay',
  actionColor: '',
  body: '',
  classList: {
    body: '',
    title: '',
  },
  dismiss: () => {},
  isBusy: false,
  message: null,
  okay: () => {},
  title: null,
};

export default Prompt;
