import React from 'react';

import DummyProjectCard from '../../../../client/cards/project_card/DummyCard';
import ProjectCard from '../../../../client/cards/project_card';

import { dummyProjectProps, projectProps } from './shared';

const SECTION_KEY_TO_VIEW_METHOD = {
  featured: '_viewAllList',
  sort: '_viewAllList',
  tag_id: '_viewAllList',
};

export default ({ topic } = {}) => ({
  _getViewMethodNameForSectionKey: (sectionKey) => SECTION_KEY_TO_VIEW_METHOD[sectionKey] || null,
  components: {
    sort: (props) => React.createElement(ProjectCard, { project: props.record, ...projectProps }),
    tag_id: (props) => React.createElement(ProjectCard, { project: props.record, ...projectProps }),
  },
  links: {
    sort: (section) => ({
      external: `/${topic.user_name.toLowerCase()}/projects?sort=${section.value}`,
      internal: `/projects?sort=${section.value}`,
    }),
    tag_id: (section) => ({
      external: `/${topic.user_name.toLowerCase()}/projects?tag_id=${section.value}`,
      internal: `/projects?tag_id=${section.value}`,
    }),
  },
  listFacet: {
    sort: (section) => [`topic_ids:${topic.id}`],
    tag_id: (section) => [`topic_ids:${topic.id}`, `tag_ids:${section.value}`],
  },
  loaderComponent: {
    sort: () => React.createElement(DummyProjectCard, dummyProjectProps),
    tag_id: () => React.createElement(DummyProjectCard, dummyProjectProps),
  },
  pagination: {
    sort: () => ({
      maxCardCount: 4,
      type: 'page',
    }),
    tag_id: () => ({
      maxCardCount: 4,
      type: 'page',
    }),
  },
  pinnedItemFacet: {},
  projectsFacet: {},
  queryMap: {
    sort: (section) => ({
      hitsPerPage: 4,
      sort: section.value,
      qlVars: { approved: true, topic_id: topic.id },
    }),
    tag_id: (section) => ({
      tag_ids: section.value, // ATTN: Does this need to be set here?
      hitsPerPage: 4,
      sort: 'trending',
      qlVars: section.value === 'featured' ? { topic_id: topic.id, featured: true } : { approved: true, topic_id: topic.id, tag_id: section.value },
    }),
  },
  service: {
    sort: () => 'projectsService',
    tag_id: () => 'projectsService',
  },
  serviceOverride: { // Temp bucket.
    featured: () => 'graphQlProjectsService',
    sort: () => 'graphQlProjectsService',
    tag_id: () => 'graphQlProjectsService',
  },
});
