import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../icon';

import { mapifyStringQuery, mapToStringQuery } from '../../../utility/converters';
import createPagination from './createPagination';

import { NOOP_HREF } from '../../../constants/links';

import styles from './paginator.css';

/**
 * Helpers
 */
const _buildHref = (currentQuery, pageNum) => {
  if (currentQuery === null) return NOOP_HREF;

  const params = mapifyStringQuery(currentQuery);

  return '?' + mapToStringQuery({ ...params, page: pageNum });
};

const _shouldEnableLeftArrow = (currentPage) => (currentPage > 1);

const _shouldEnableRightArrow = (currentPage, nextPage, totalPages) => (
  (currentPage < totalPages) || !!nextPage
);

/**
 * Views
 */
const getLeftArrowButton = ({ currentPage, currentQuery, disabled, onClick }) => {
  const isEnabled = !disabled && _shouldEnableLeftArrow(currentPage);

  return (
    <a
      className={`${styles.arrow} ${isEnabled ? '' : styles.disabled}`}
      href={isEnabled ? _buildHref(currentQuery, currentPage - 1) : null}
      onClick={(e) => {
        e.preventDefault();
        if (isEnabled) return onClick(currentPage - 1);
      }}
    >
      <Icon name="arrow-left" />
    </a>
  );
};

const getRightArrowButton = ({ currentPage, currentQuery, disabled, nextPage, onClick, totalPages }) => {
  const isEnabled = !disabled && _shouldEnableRightArrow(currentPage, nextPage, totalPages);

  return (
    <a
      className={`${styles.arrow} ${isEnabled ? '' : styles.disabled}`}
      href={isEnabled ? _buildHref(currentQuery, currentPage + 1) : null}
      onClick={(e) => {
        e.preventDefault();
        if (isEnabled) return onClick(currentPage + 1);
      }}
    >
      <Icon name="arrow-right" />
    </a>
  );
};

const getPageItems = ({ currentPage, currentQuery, disabled, onClick, totalPages }) => (
  createPagination(currentPage, totalPages).map((page, i) => page === 'buffer'
    ? <span key={`${page}_${i}`} className={styles.buffer}>...</span>
    : (
      <a
        key={`${page}_${i}`}
        className={`${styles.number} ${disabled ? styles.disabled : currentPage === page ? styles.currentPage : ''}`}
        href={_buildHref(currentQuery, page)}
        onClick={(e) => {
          e.preventDefault();
          if (!disabled && currentPage !== page) return onClick(page);
        }}
      >
        {page.toString()}
      </a>
      ))
);

/**
 * Main component
 */
const Paginator = (props) => (
  <div className={`${styles.root} ${props.classList.root}`}>
    {getLeftArrowButton(props)}
    {props.totalPages > 0 && getPageItems(props)}
    {getRightArrowButton(props)}
  </div>
);

/**
 * Besides required "currentPage", default and simple pagination each provides Paginator other distinct metadata fields/props ("totalPages" for default vs. "nextPage" & "prevPage" for simple).
 * We depend on that assumption and those unique props all around to determine the behavior for both pagination types (ex. enabled/disabled arrow buttons, displaying page buttons), without conerns about overlapping each other.
 */
Paginator.propTypes = {
  classList: PropTypes.shape({ root: PropTypes.string }),
  currentPage: PropTypes.number.isRequired,
  currentQuery: PropTypes.string,
  disabled: PropTypes.bool,
  nextPage: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  prevPage: PropTypes.number,
  totalPages: PropTypes.number,
};

Paginator.defaultProps = {
  classList: { root: '' },
  currentQuery: null,
  disabled: false,
  nextPage: null,
  prevPage: null,
  totalPages: null,
};

export default Paginator;
