import { Modifier, SelectionState } from 'draft-js';
import { findWithRegex } from '../draftHelpers';

const entityMap = { MENTION: (contentState) => createMention(contentState) };

function createEntity(type, contentState) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!entityMap.hasOwnProperty(type)) return new Error('Entity type is not defined!  Check entifyContent.');

  return entityMap[type](contentState);
}

function createMention(contentState) {
  return contentState.createEntity('MENTION', 'MUTABLE', {});
}

/**
 * [entifyContent applies a Draft Entity type to all ranges that match regex.  Decorates matching text if function is set.]
 * @param  {[contentState]} contentState
 * @param  {[regex]}        ENTITY_REGEX [matcher]
 * @param  {Function}       textFn       [decorator function for matched text]
 * @return {[ContentState]}
 */
export default function entifyContent(contentState, ENTITY_REGEX, entityType, textFn = (text) => text) {
  return contentState.getBlockMap().reduce((acc, block) => {
    const replacers = [];

    const applyEntityToRanges = (start, end, match) => {
      const updatedContentState = createEntity(entityType, acc);

      // Store replacement data.  We'll sweep through this later to update the text.
      replacers.push({
        blockKey: block.getKey(),
        entityKey: updatedContentState.getLastCreatedEntityKey(),
        text: textFn(match),
        start,
        end,
      });

      const selectionState = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: start,
        focusOffset: end,
      });

      const contentStateWithEntity = Modifier.applyEntity(
        updatedContentState,
        selectionState,
        updatedContentState.getLastCreatedEntityKey(),
      );

      acc = contentStateWithEntity;
    };

    findWithRegex(ENTITY_REGEX, block, applyEntityToRanges);

    // Replace text of each block starting in reverse order of how we found the matches.
    return replacers.reduceRight((accum, replacer) => {
      const selectionState = SelectionState.createEmpty(replacer.blockKey).merge({
        anchorOffset: replacer.start,
        focusOffset: replacer.end,
      });

      return Modifier.replaceText(
        accum,
        selectionState,
        replacer.text,
        null,
        replacer.entityKey,
      );
    }, acc);
  }, contentState);
}
