import React from 'react';
import PropTypes from 'prop-types';

import markdown from '../../utils/Markdown';

import layoutStyles from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './video_info.css';

const VideoInfo = ({ inDialog, video }) => (
  <div>
    <header className={layoutStyles.marginTop30}>
      <h1 className={`${typography.h2} ${inDialog ? typography.staticWhite : ''}`}>{video.title}</h1>
    </header>
    <div className={`${typography.bodyM} ${inDialog ? typography.staticWhite : ''}`}>
      <section className={layoutStyles.marginTop10}>{video.category}</section>

      <section
        dangerouslySetInnerHTML={{ __html: markdown.render(video.description) }}
        className={`${layoutStyles.marginTop15} ${inDialog ? styles.hasLinksWhite : styles.hasLinks}`}
      />
    </div>
  </div>
);

VideoInfo.propTypes = {
  inDialog: PropTypes.bool,
  video: PropTypes.shape({
    category: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

VideoInfo.defaultProps = { inDialog: false };

export default VideoInfo;
