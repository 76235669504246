import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';

import { debounce, throttle } from 'lodash';
import smoothScroll from '../../utils/smoothScroll';

import layout from '../../../styles/global_ui/layout.css';
import styles from './horizontal_scroll_box.css';

const LEFT = -1;
const RIGHT = 1;

class HorizontalScrollBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftArrow: false,
      rightArrow: false,
    };

    this.scroll = this.scroll.bind(this);
    this.setArrowsDisplay = this.setArrowsDisplay.bind(this);

    this.handleScroll = throttle(this.setArrowsDisplay, 100);

    // Refs
    this._scrollContainer;
  }

  componentDidMount() {
    this.setArrowsDisplay();

    this.handleResize = debounce(this.setArrowsDisplay, 500);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * Methods
   */
  scroll(direction) {
    if (this._scrollContainer) {
      const scrollTo = this._scrollContainer.scrollLeft + (this.props.scrollDistance * direction);
      smoothScroll(scrollTo, 350, () => {}, this._scrollContainer, true);
    }
  }

  setArrowsDisplay() {
    if (this._scrollContainer) {
      const containerViewWidth = this._scrollContainer.getBoundingClientRect().width;

      const leftArrow = this._scrollContainer.scrollLeft > 0;
      const rightArrow = Math.ceil(this._scrollContainer.scrollLeft + containerViewWidth) < this._scrollContainer.scrollWidth;

      if (leftArrow !== this.state.leftArrow || rightArrow !== this.state.rightArrow) {
        this.setState({ leftArrow, rightArrow });
      }
    }
  }

  /**
   * Views
   */
  _displayArrowLeft() {
    return this.state.leftArrow && (
      <div
        className={`${layout.flexCenterItems} ${styles.arrowLeft} ${this.props.classList.arrowLeft}`}
        onClick={() => this.scroll(LEFT)}
      >
        <Icon name="arrow-left" size={12} />
      </div>
    );
  }

  _displayArrowRight() {
    return this.state.rightArrow && (
      <div
        className={`${layout.flexCenterItems} ${styles.arrowRight} ${this.props.classList.arrowRight}`}
        onClick={() => this.scroll(RIGHT)}
      >
        <Icon name="arrow-right" size={12} />
      </div>
    );
  }

  render() {
    const { children, classList } = this.props;

    return (
      <div className={`${styles.wrapper} ${classList.wrapper}`}>
        <div
          ref={(el) => this._scrollContainer = el}
          className={`${layout.noScrollBar} ${styles.scrollContainer} ${classList.scrollContainer}`}
          onScroll={this.handleScroll}
        >
          {children}
        </div>

        {this._displayArrowLeft()}
        {this._displayArrowRight()}
      </div>
    );
  }
}

HorizontalScrollBox.propTypes = {
  classList: PropTypes.shape({
    arrowLeft: PropTypes.string,
    arrowRight: PropTypes.string,
    scrollContainer: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  scrollDistance: PropTypes.number,
};

HorizontalScrollBox.defaultProps = {
  classList: {
    arrowLeft: '',
    arrowRight: '',
    scrollContainer: '',
    wrapper: '',
  },
  scrollDistance: 200,
};

export default HorizontalScrollBox;
