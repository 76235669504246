import React from 'react';
import PropTypes from 'prop-types';

import styles from './simple_logo_card.css';

const DummyLogoCard = ({ classList }) => (
  <div className={classList.wrapper || styles.wrapper}>
    <div className={styles.card}>
      <div className={styles.dummyImage} />
      <div className={styles.dummyTitle}>
      </div>
    </div>
  </div>
);

DummyLogoCard.propTypes = { classList: PropTypes.shape({ wrapper: PropTypes.string }) };

DummyLogoCard.defaultProps = { classList: {} };

export default DummyLogoCard;
