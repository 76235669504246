/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import errorHandler from '../../../services/error_handler';
import getConfigForType, { getStoreKey } from './config.js';
import store from '../../../services/user_relations_store';
import trackRelation from '../../../services/keen/trackRelation';

import { graphMutate } from '../../../requests/graphql';
import { summonLoginPanel } from '../../../utility/dispatchers';
import { cancelablePromise } from '../../../utility/promises';

import utilStyles from '../../../styles/global_ui/util.css';

class UserRelationButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      config: { ...getConfigForType(props.type), ...props.config },
      currentUser: null,
      disabled: props.disabled,
      viewIndex: 0,
    };

    this.updateSub = store.getChannel().subscribe('store.changed', this.handleChange);
    this.activePromise;
  }

  componentDidMount() {
    return this._handleInitialState(getStoreKey(this.props.type));
  }

  componentDidUpdate(prevProps, prevState) {
    // Update RespectButton's label with new count
    if (this.props.config && prevProps.config && this.props.config.label !== prevProps.config.label) {
      this.setState({
        config: {
          ...prevState.config,
          label: this.props.config.label,
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.activePromise) this.activePromise.cancel();
    this.updateSub.unsubscribe();
  }

  _handleInitialState(resource) {
    if (!store.has(resource)) {
      this.setState({ disabled: true });

      this.activePromise = cancelablePromise(store.fetchAll());

      return this.activePromise
        .promise
        .then((res) => {
          this.activePromise = undefined;

          if (!res.hasOwnProperty('promiseCanceled')) {
            this.setState({
              currentUser: store.has('currentUser') ? store.get('currentUser') : null,
              disabled: false,
              viewIndex: store.hasIn(resource, this.props.id) ? 1 : 0,
            });
          }
        })
        .catch((err) => {
          this.activePromise = undefined;
          errorHandler('UserRelationButton fetch error:', err);
          if (!err.hasOwnProperty('promiseCanceled')) this.setState({ disabled: false });
        });
    } else {
      this.setState({
        currentUser: store.has('currentUser') ? store.get('currentUser') : this.state.currentUser,
        disabled: false,
        viewIndex: store.hasIn(resource, this.props.id) ? 1 : 0,
      });
    }
  }

  handleChange() {
    const resource = getStoreKey(this.props.type);
    if (store.has(resource)) {
      this.setState({ disabled: false, viewIndex: store.hasIn(resource, this.props.id) ? 1 : 0 });
    }
  }

  handleClick() {
    if (this.state.disabled) return;

    if (store.has('currentUser') && this.state.config.create && this.state.config.delete) {
      this.state.viewIndex === 0 ? this._createRelation() : this._deleteRelation();
      this.props.onClick(this.state.viewIndex === 0);
    } else {
      summonLoginPanel({ detail: { source: this.props.type, ...this.props.signinEventDetail } });
    }
  }

  _createRelation() {
    const { additionalRequestArgs, id, source } = this.props;
    this.setState({ disabled: true, viewIndex: 1 });

    this.activePromise = cancelablePromise(graphMutate(this.state.config.create, { id, ...additionalRequestArgs }));

    return this.activePromise
      .promise
      .then((res) => {
        const isResolved = !res.hasOwnProperty('unresolved');
        const canSetState = !res.hasOwnProperty('promiseCanceled');
        const key = getStoreKey(this.props.type);

        if (isResolved) {
          trackRelation({ key, id, source, createOrDeleteBool: true });
          store.setIn(key, id);
        }

        if (canSetState) {
          this.setState({
            disabled: false,
            viewIndex: isResolved ? 1 : 0,
          });
        }
      })
      .catch((err) => {
        this.props.onClick(false);
        this.activePromise = undefined;
        if (err.status !== 401) errorHandler('_createRelation Error', err);
        if (!err.hasOwnProperty('promiseCanceled')) this.setState({ disabled: false, viewIndex: 0 });
      });
  }

  _deleteRelation() {
    const { additionalRequestArgs, id, source } = this.props;
    this.setState({ disabled: true, viewIndex: 0 });

    this.activePromise = cancelablePromise(graphMutate(this.state.config.delete, { id, ...additionalRequestArgs }));

    return this.activePromise
      .promise
      .then((res) => {
        const isResolved = !res.hasOwnProperty('unresolved');
        const canSetState = !res.hasOwnProperty('promiseCanceled');
        const key = getStoreKey(this.props.type);

        if (isResolved) {
          trackRelation({ key, id, source, createOrDeleteBool: false });
          store.deleteIn(key, id);
        }

        if (canSetState) {
          this.setState({
            disabled: false,
            viewIndex: isResolved ? 0 : 1,
          });
        }
      })
      .catch((err) => {
        this.props.onClick(true);
        errorHandler('_deleteRelation Error', err);
        if (!err.hasOwnProperty('promiseCanceled')) this.setState({ disabled: false, viewIndex: 1 });
      });
  }

  _shouldDisableBtn() {
    return this.props.type.includes('followed_user') && this.state.currentUser === this.props.id;
  }

  render() {
    const { config, viewIndex } = this.state;
    const disableCompletely = (this.props.disableCompletely || this._shouldDisableBtn());

    return (
      <button
        className={`${config && config.className ? config.className[viewIndex] : ''} ${disableCompletely ? utilStyles.disabled : ''}`}
        disabled={disableCompletely}
        onClick={this.handleClick}
        type="button"
      >
        {config.label[viewIndex]}
      </button>
    );
  }
}

UserRelationButton.propTypes = {
  additionalRequestArgs: PropTypes.object,
  config: PropTypes.shape({
    className: PropTypes.array,
    create: PropTypes.object,
    delete: PropTypes.object,
    label: PropTypes.array,
  }),
  disableCompletely: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  signinEventDetail: PropTypes.object,
  source: PropTypes.string,
  type: PropTypes.string.isRequired,
};

UserRelationButton.defaultProps = {
  additionalRequestArgs: {},
  config: null,
  disableCompletely: false,
  disabled: false,
  onClick: () => {},
  signinEventDetail: {},
  source: null,
};

export default UserRelationButton;
