import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';

import layout from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';
import icon from './icon.css';

const c = {
  root: `${layout.flexJustifySpaceBetween} ${icon.root}`,
  optContainer: `${utilStyles.cursorPointer} ${icon.optContainer}`,
};

// Note: At least 2 options required!
// Component: Pill shape, renders icons with circle bg, radio button behavior upon select.
const IconMultiPillButton = ({ classList, onChange, options, selectedVal }) => (
  <div className={`${c.root} ${classList?.root}`}>
    {options.map((opt) => (
      <label key={`${opt.icon}-id`} className={`${layout.fullWidth} ${icon.labelContainer}`}>
        <input
          checked={opt.value === selectedVal}
          className={utilStyles.srOnly}
          name={opt.value}
          onChange={(e) => {
            onChange({ value: opt.value, checked: e.target.checked });
          }}
          type="radio"
          value={opt.value}
        />
        <div className={`${c.optContainer} ${opt.value === selectedVal ? icon.fog10 : ''} ${classList?.optContainer}`}>
          <Icon className={opt.value === selectedVal ? icon.svgFull : icon.svgHalf} name={opt.icon} size="12" />
        </div>
      </label>
    ))}
  </div>
);

IconMultiPillButton.propTypes = {
  classList: PropTypes.shape({
    root: PropTypes.string,
    optContainer: PropTypes.string,
  }),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  selectedVal: PropTypes.string,
};
IconMultiPillButton.defaultProps = { classList: {} };

export default IconMultiPillButton;
