import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import CustomIcon from '../../icon/custom';

import errorHandler from '../../../services/error_handler';
import getCSRFTokenFromDOM from '../../../services/oauth/getCSRFToken';
import { getCSRFTokenFromApi } from '../../../requests/authentication';
import { summonGlobalMessenger } from '../../../utility/dispatchers';
import { firstCharUpperCase } from '../../../utility/formatters';
import { isAString, isBlank } from '../../../utility/types';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import styles from './oauth_button.css';

const icons = {
  arduino: <CustomIcon className="fa" name="arduino" />,
  facebook: <i className="fa fa-facebook" />,
  github: <i className="fa fa-github" />,
  google: <CustomIcon name="google" />,
  windowslive: <i className="fa fa-windows" />,
};

function getCSRFToken() {
  const pageToken = getCSRFTokenFromDOM();
  if (pageToken) {
    return Promise.resolve(pageToken);
  }

  return getCSRFTokenFromApi();
}

const OAuthButton = ({ service, authEndpoint, action }) => {
  const csrfTokenRef = useRef();
  const [isBusy, setIsBusy] = useState(false);
  const variant = isAString(action) ? 'fullWidth' : 'square';

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setIsBusy(true);

    try {
      const csrfToken = await getCSRFToken();

      if (isBlank(csrfTokenRef.current)) {
        throw new Error('DOM is missing hidden CSRF input for initiating OAuth flow');
      }

      csrfTokenRef.current.value = csrfToken;
      event.target.submit();
    } catch (err) {
      errorHandler(err);
      summonGlobalMessenger({ msg: 'Something went wrong. Please try again.' });
    }

    setIsBusy(false);
  }, []);

  return (
    <form
      action={authEndpoint}
      className={`${styles[variant]} ${styles[service]}`}
      method="POST"
      onSubmit={handleSubmit}
    >
      <input ref={csrfTokenRef} name="authenticity_token" type="hidden" />
      <button
        className={`${buttonStyles.lg} ${styles.button}`}
        disabled={isBusy}
      >
        <div className={styles.icon}>{icons[service]}</div>
        {action && <div>{`${action} with ${firstCharUpperCase(service)}`}</div>}
      </button>
    </form>
  );
};

OAuthButton.propTypes = {
  action: PropTypes.string,
  authEndpoint: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
};

OAuthButton.defaultProps = { action: null };

export default OAuthButton;
