import React from 'react';
import PropTypes from 'prop-types';

import DummyProjectsList from '../projects_list/DummyProjectsList';
import LazyComponent from '../../wrappers/lazy_component';
import SmallProjectCard from '../../cards/small_project_card';

import smoothScroll from '../../utils/smoothScroll';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const SideView = ({ count, initialized, onReveal, records }) => (
  <LazyComponent onReveal={onReveal}>
    <div>
      {(!initialized || !!records.length)
      && (
        <h5 className={`${typography.h5} ${layout.marginBottom15}`}>
          Related projects
        </h5>
      )}
      {initialized
        ? records.map((record) => (
          <SmallProjectCard
            key={record.id}
            location="project_similar_side"
            project={record}
          />
        ))
        : (
          <DummyProjectsList
            cardBorder={false}
            grid={false}
            maxCols={count}
            type="small"
          />
          )}
      {(!initialized || !!records.length)
      && (
        <a
          className={`${typography.linkPebble} ${typography.bold} ${typography.bodyS}`}
          href="#similar-projects"
          onClick={(e) => {
            e.preventDefault();
            smoothScroll(document.getElementById('similar-projects'));
          }}
        >
          View more related projects
        </a>
      )}
    </div>
  </LazyComponent>
);

SideView.propTypes = {
  count: PropTypes.number.isRequired,
  initialized: PropTypes.bool.isRequired,
  onReveal: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
};

export default SideView;
