// import {CharacterMetadata, ContentBlock, ContentState, EditorState, genKey, Modifier, SelectionState} from 'draft-js';

import {isUrlValid} from '../media/url';
import {isVideoUrlValid} from '../media/video';
import {isEmbedlyUrlValid, isGistUrlValid, isTwitterUrlValid} from '../media/social';

export function doesCurrentSelectionContainStyle(editorState, style) {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getAnchorKey());
  const styles = block.getInlineStyleAt(selection.getStartOffset());
  return styles.includes(style);
}

export function doesPreviousCharContainEntity(editorState, entityList=[]) {
  const selection = editorState.getSelection();
  const startOffset = selection.getStartOffset();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getAnchorKey());

  if (selection.isCollapsed() === false || startOffset <= 0) return false;

  const entityKey = block.getEntityAt(startOffset-1);

  if (!entityList.length) return entityKey !== null;
  return entityKey && entityList.indexOf(content.getEntity(entityKey).getType()) > -1;
}

export function doesPreviousCharContainStyle(editorState, styleList=[]) {
  const selection = editorState.getSelection();
  const startOffset = selection.getStartOffset();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getAnchorKey());

  if (selection.isCollapsed() === false || startOffset <= 0) return false;

  const styles = block.getInlineStyleAt(startOffset-1);

  if (!styleList.length) return styles.size > 0;

  return styleList.reduce((acc, style) => {
    if (acc) return acc;
    return styles.includes(style);
  }, false);
}

export function doesPreviousWordContainValidUrl(editorState, type) {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getAnchorKey());
  const prevWord = block.getText().slice(0, selection.getStartOffset()).split(' ').pop();

  switch(type) {
    case 'EMBEDLY':
      return isEmbedlyUrlValid(prevWord);

    case 'GIST':
      return isGistUrlValid(prevWord);

    case 'LINK':
      return isUrlValid(prevWord);

    case 'VIDEO':
      return isVideoUrlValid(prevWord);

    case 'TWEET':
      return isTwitterUrlValid(prevWord);

    default:
      return false;
  }
}

export function isCurrentBlockAType(currentBlock, types=[]) {
  const blockType = currentBlock.get('type');
  return Array.isArray(types) ? types.includes(blockType) : (blockType === types);
}

export function isCursorAmidEntities(editorState) {
  const selection = editorState.getSelection();
  const startOffset = selection.getStartOffset();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getAnchorKey());

  if (selection.isCollapsed() === false || startOffset <= 0) return false;

  const prevEntityKey = block.getEntityAt(startOffset-1);
  const nextEntityKey = block.getEntityAt(startOffset);

  return prevEntityKey !== null && nextEntityKey !== null && prevEntityKey === nextEntityKey;
}

export function isCursorAmidStyles(editorState) {
  const selection = editorState.getSelection();
  const startOffset = selection.getStartOffset();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getAnchorKey());

  if (selection.isCollapsed() === false || startOffset <= 0) return false;

  const prevStyles = block.getInlineStyleAt(startOffset-1);
  const nextStyles = block.getInlineStyleAt(startOffset);

  return prevStyles.size > 0 && nextStyles.size > 0;
}
