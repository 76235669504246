import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ChannelCard from '../../../../../client/cards/channel_card';

import initStyles from './community_card.css';

// TODO: try to get rid of this card and use ChannelCard directly.
class CommunityCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { avatar_url, brief, classList, id, name, stats, url } = this.props;
    const styles = { ...initStyles, ...classList };
    const item = { avatar_url, id, members_count: stats.members, name, pitch: brief, projects_count: stats.projects, url: url };

    return (
      <ChannelCard
        classList={{ wrapper: styles.root }}
        item={item}
        showBorder={false}
      />
    );
  }
}

// TODO: stop passing props commented out below:
CommunityCard.propTypes = {
  avatar_url: PropTypes.string,
  brief: PropTypes.string.isRequired,
  classList: PropTypes.shape({
    root: PropTypes.string,
    panel: PropTypes.string,
  }),
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  // showJoinButton: PropTypes.bool,
  // showStats: PropTypes.bool,
  stats: PropTypes.shape({
    members: PropTypes.number.isRequired,
    projects: PropTypes.number.isRequired,
  }),
  // updateResource: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

CommunityCard.defaultProps = {
  avatar_url: null,
  classList: {},
  // showJoinButton: true,
  // showStats: true
};

export default CommunityCard;
