import { isBlank } from '../../../utility/types';

export const extractCoverImageFromStory = (story) => {
  if (isBlank(story)) return { coverImage: null, story };

  const firstBlock = story[0];

  // remove empty CE blocks at the beginning of the story
  if (firstBlock.type === 'CE' && !doesCEBlockHaveContent(firstBlock)) {
    return extractCoverImageFromStory(story.slice(1));
  }

  if (firstBlock.type === 'Carousel') {
    return {
      coverImage: firstBlock.images[0],
      story: story.slice(1),
    };
  }

  return { coverImage: null, story };
};

export const extractItemContent = (item) => {
  if (item.content) return item.content;

  return item.children.map((child) => extractItemContent(child)).join(' ');
};

export const doesCEBlockHaveContent = (block) => doesCENodesHaveContent(block.json);

export const doesCENodeHaveContent = (node) => !isBlank(node.content) || doesCENodesHaveContent(node.children);

export const doesCENodesHaveContent = (nodes = []) => {
  for (let i = 0; i < nodes.length; i++) {
    if (doesCENodeHaveContent(nodes[i])) return true;
  }

  return false;
};

export const doesStoryHaveContent = (story = []) => {
  if (!story) return false;

  for (let i = 0; i < story.length; i++) {
    const block = story[i];
    if (block.type !== 'CE' || doesCEBlockHaveContent(block)) return true;
  }

  return false;
};
