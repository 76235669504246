import { requestAnimationFrame } from '../../utility/requestAnimationFrame';

/**
 * Some hacked apart version of:
 * https://github.com/alicelieutier/smoothScroll/blob/master/smoothscroll.js
 */

function doAbort() {
  return noWindow() || oldBrowser();
}

// ease in out function thanks to:
// http://blog.greweb.fr/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/
function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

// Get the top position of an element in the document
function getEdge(element, start, side = 'top') {
  // return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
  if (element.nodeName === 'HTML') return -start;

  return element.getBoundingClientRect()[side] + start;
}

function noWindow() {
  // Do not initialize smoothScroll when running server side, handle it in client.
  return (typeof window !== 'object' || typeof document !== 'object');
}

function oldBrowser() {
  // We do not want this script to be applied in browsers that do not support those
  // That means no smoothscroll on IE9 and below.
  return document.querySelectorAll === void 0 || window.pageYOffset === void 0
    || history.pushState === void 0;
}

// calculate the scroll position we should be in
// given the start and end point of the scroll
// the time elapsed from the beginning of the scroll
// and the total duration of the scroll (default 500ms)
function position(start, end, elapsed, duration) {
  if (elapsed > duration) return end;

  return start + (end - start) * easeInOutCubic(elapsed / duration); // <-- you can change the easing funtion there
  // return start + (end - start) * (elapsed / duration); // <-- this would give a linear scroll
}

function scrollInContainer(target, duration, container, horizontal) {
  const scrollCoord = horizontal ? 'scrollLeft' : 'scrollTop';
  const posCoord = horizontal ? 'left' : 'top';

  const start = container[scrollCoord];
  // const end = typeof target === 'number' ? parseInt(target) : target.getBoundingClientRect()[posCoord] - container.getBoundingClientRect()[posCoord];
  const end = typeof target === 'number' ? parseInt(target) : target.getBoundingClientRect()[posCoord] + container[scrollCoord];

  const action = (newPosition) => {
    container[scrollCoord] = newPosition;
  };

  return { action, start, end };
}

function scrollInWindow(target, duration, horizontal) {
  const start = horizontal ? window.pageXOffset : window.pageYOffset;
  const otherCoord = horizontal ? window.pageYOffset : window.pageXOffset;

  const end = typeof target === 'number' ? parseInt(target) : getEdge(target, start, horizontal ? 'left' : 'top');
  const action = horizontal
    ? (newPosition) => window.scroll(newPosition, otherCoord)
    : (newPosition) => window.scroll(otherCoord, newPosition);

  return { action, start, end };
}

function step({ start, end, clock, duration, action, callback, target }) {
  const elapsed = Date.now() - clock;

  action(position(start, end, elapsed, duration));

  if (elapsed > duration) {
    if (typeof callback === 'function') {
      callback(target);
    }
  } else {
    requestAnimationFrame(() => step({ start, end, clock, duration, action, callback, target }));
  }
}

// we use requestAnimationFrame to be called by the browser before every repaint
// if the first argument is an element then scroll to the top of this element
// if the first argument is numeric then scroll to this location
// if the callback exist, it is called when the scrolling is finished
// if context is set then scroll that element, else scroll window
export default function smoothScroll(target, duration = 500, callback = null, container = window, horizontal = false) {
  if (doAbort()) return;

  const { action, start, end } = (container === window)
    ? scrollInWindow(target, duration, horizontal)
    : scrollInContainer(target, duration, container, horizontal);

  const clock = Date.now();
  step({ start, end, clock, duration, action, callback, target });
}
