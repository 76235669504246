import React from 'react';
import PropTypes from 'prop-types';

import SponsorTooltip from './SponsorTooltip';

import { flatten } from '../../../utility/converters';
import { isBlank } from '../../../utility/types';
import { objHasPropertyOfLength } from '../../../utility/predicates';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const MAX_CHARS = 25;

/**
 * Helpers
 */
const _concatSponsors = (sponsors) => (
  React.Children.toArray(sponsors.reduce((acc, sponsor, i) => (
    i === sponsors.length - 1
      ? acc.concat([_getSponsor(sponsor)])
      : acc.concat([_getSponsor(sponsor), ', '])
  ), []))
);

const _getNameLength = (sponsor) => _isPlatform(sponsor) ? sponsor.name.length : sponsor.length;

const _getPlatformLink = (platform) => (
  <a className={typography.linkBlue} href={platform.url}>{platform.name}</a>
);

const _getSponsor = (sponsor) => _isPlatform(sponsor) ? _getPlatformLink(sponsor) : sponsor;

const _isPlatform = (sponsor) => objHasPropertyOfLength(sponsor, 'name');

const _splitSponsors = (allSponsors) => {
  if (allSponsors.length === 1) return { visibleSponsors: allSponsors, tooltipSponsors: [] };

  const { count } = allSponsors.reduce((acc, sponsor) => {
    const remainingChars = acc.remainingChars - _getNameLength(sponsor);

    return {
      ...acc,
      count: remainingChars < 0 ? acc.count : acc.count + 1,
      remainingChars,
    };
  }, { remainingChars: MAX_CHARS, count: 0 });

  return { visibleSponsors: allSponsors.slice(0, count), tooltipSponsors: allSponsors.slice(count) };
};

/**
 * Views
 */
const _getTooltipTarget = (tooltip) => (<SponsorTooltip tooltip={tooltip} />);

const _getSponsorsView = (visibleSponsors, tooltipSponsors) => {
  if (tooltipSponsors.length) {
    const tooltipTarget = _getTooltipTarget(_concatSponsors(tooltipSponsors));

    return _concatSponsors(visibleSponsors.concat([tooltipTarget]));
  }

  return _concatSponsors(visibleSponsors);
};

const SponsorList = ({ className, item, prependSlash }) => {
  const { platforms, custom_sponsors, event_type } = item;
  if (isBlank(platforms) && isBlank(custom_sponsors)) return null;

  const allSponsors = flatten([item.platforms, item.custom_sponsors].filter(Array.isArray));
  const { visibleSponsors, tooltipSponsors } = _splitSponsors(allSponsors);

  return (
    <span className={className}>
      {prependSlash
      && <span className={`${layout.marginLeft5} ${layout.marginRight5}`}>/</span>}
      {event_type === 'Community' ? 'featuring ' : 'presented by '}
      {_getSponsorsView(visibleSponsors, tooltipSponsors)}
    </span>
  );
};

SponsorList.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.shape({ url: PropTypes.string }),
    link: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    start_date: PropTypes.string,
    state: PropTypes.string,
    title: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
  prependSlash: PropTypes.bool,
};

SponsorList.defaultProps = {
  className: layout.marginTop5,
  prependSlash: false,
};

export default SponsorList;
