import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';
import RespectButton from '../../buttons/respect_button';

import { formatNumberLimit4Chars } from '../../../utility/formatters';

import styles from './project_card.css';

const ProjectCardStats = ({ className, controlledRespects, customIcon, project, toggleRespect, usePortal }) => {
  if (project.published_state.toLowerCase() === 'private') return null;

  return (
    <section className={className}>
      <section className={styles.stats}>
        <span className={styles.stat}>
          <RespectButton
            controlled={controlledRespects}
            id={project.id}
            onClick={(createOrDeleteBool) => toggleRespect(project.hid, createOrDeleteBool)}
            respects={project.stats.respects}
            source="project_card"
            theme="project_card"
          />
        </span>
        <span className={styles.stat}>
          <Icon name="eye" size={16} />
          <span className={styles.statNumber}>
            {formatNumberLimit4Chars(project.stats.views)}
          </span>
        </span>
      </section>
      {customIcon(project, usePortal)}
    </section>
  );
};

ProjectCardStats.propTypes = {
  className: PropTypes.string,
  controlledRespects: PropTypes.bool,
  customIcon: PropTypes.func, // (project) => <Whatever/>
  project: PropTypes.shape({
    content_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    cover_image_url: PropTypes.string,
    difficulty: PropTypes.string,
    edit_url: PropTypes.string,
    guest_name: PropTypes.string,
    id: PropTypes.number,
    hid: PropTypes.string,
    name: PropTypes.string,
    one_liner: PropTypes.string,
    published_state: PropTypes.string,
    respects: PropTypes.number,
    stats: PropTypes.object,
    team: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
  toggleRespect: PropTypes.func,
  usePortal: PropTypes.bool,
};

ProjectCardStats.defaultProps = {
  className: '',
  controlledRespects: false,
  customIcon: () => null,
  toggleRespect: () => {},
  usePortal: false,
};

export default ProjectCardStats;
