export default class PromiseQueue {
  constructor({ progressFn = () => {} } = {}) {
    this.queue = [];
    this.onProgressUpdate = progressFn;

    // Flag
    this._processing = false;
  }

  push(promise, args = [], resolve, reject) {
    this.queue.push({ args, promise, resolve, reject });
    this._processQueue();
  }

  _queueNextOrQuit() {
    if (this.queue.length > 0) {
      this._resolveQueue();
    } else {
      this._processing = false;
    }
  }

  _processQueue() {
    if (!this._processing) {
      this._processing = true;
      this._resolveQueue();
    }
  }

  _propagateProgress() {
    this.onProgressUpdate({ size: this.queue.length });
  }

  _resolveQueue() {
    const { args, promise, resolve, reject } = this.queue.shift();

    return promise.apply(null, args)
      .then((res) => {
        resolve(res);
        this._propagateProgress();
        this._queueNextOrQuit();
      })
      .catch((err) => {
        reject(err);
        this._propagateProgress();
        this._queueNextOrQuit();
      });
  }
}
