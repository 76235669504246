import { processRemoteImage, uploadImageToServer } from '../../../../draftster';

export default function createDraftsterConfig(
  initContent,
  fieldName,
  type,
  _isOnCorrectTab,
  beenUpdated,
) {
  return {
    className: 'story-editor',

    editorWasUpdated() {
      beenUpdated(fieldName);
    },

    handleImageUpload(image, callback) {
      return uploadImageToServer(image)
        .then((mergedImage) => callback(null, mergedImage))
        .catch((err) => callback(err, image));
    },

    // This will force Draftster to rerender when the location hash changes. Its so the toolbar can render
    // the width of the panel.
    hideEditor() {
      return !_isOnCorrectTab();
    },

    // This is specific to image uploads in Draftster. It will get called on any image upload or paste event when
    // images exist in the buffer.
    isEditorBusy(bool) {
      const selector = `#${type}`;
      [].slice.call(document.querySelectorAll(`${selector} .react-submit-btn`)).forEach((button) => {
        if (!button.getAttribute('disabled') && bool) {
          button.setAttribute('disabled', true);
          button.text = 'Image uploading...';
        } else if (button.getAttribute('disabled') && !bool) {
          button.removeAttribute('disabled');
          button.text = 'Save changes';
        }
      });
    },

    processImage(image) {
      return processRemoteImage(image);
    },

    setInitialContent() {
      return initContent;
    },

    toolbar: {
      carousel: false,
      linkedImage: true,
    },
  };
}
