import polyfillIntersectionObserver from './polyfillIntersectionObserver';
import { isWindowDefined } from '../../services/window';

function getIntersectionObserver() {
  if (!isWindowDefined) return null;

  polyfillIntersectionObserver(window, document);

  return window.IntersectionObserver;
}

export default getIntersectionObserver();
