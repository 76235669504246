import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Draftster from '../../../../components/draftster_core';
import draftsterConfig from './draftsterConfig';
import { convertToJSONModel } from '../../../../client/draftster';

import BasicFormInput from '../../../../client/form_components/inputs/basic_form_input';
import RadioGroup from '../../../../client/form_components/inputs/radio_group';
import StickyActionsBar from '../templates/StickyActionsBar';
import TopMenuBar from '../templates/TopMenuBar';

import { createImageUploader, createCropperTitle, createMultiSelect, recordsToOptions } from '../../../home_edit/home_sections/promoted_content_form/content_form/components';

import errorHandler from '../../../../services/error_handler';
import { getErrorHelperView } from '../../../../client/form_components/templates';
import { getInObj } from '../../../../utility/accessors';
import { removeFromObject } from '../../../../utility/filters';
import { getErrorForField, getFieldValuesAsObject, initFields, scrollToError, setIsBusy, setStateOrError, validateFields } from '../../../../utility/forms';
import { boolOrNullIn, boolOrNullOut, multiSelectIn, multiSelectToRecordShapeOut } from '../../../../utility/forms/formatters';
import { imageV, isDraftsterEmpty, isUrlWithProtocol, maxLength, minLength } from '../../../../services/validation/validators';

import formStyles from '../../../../styles/global_ui/forms.css';
import inputStyles from '../../../../styles/global_ui/inputs.css';
import layout from '../../../../styles/global_ui/layout.css';

const FIELDS_TEMPLATE = {
  content: { order: 1, validate: () => null, value: null, notRequired: true }, // Validation is manual for content.
  image: { order: 3, validate: () => null, value: null, notRequired: true },
  mobile_image: { order: 4, validate: () => null, value: null, notRequired: true },
  platforms: { order: 8, validate: (v) => maxLength(5, v), value: [], notRequired: true, formatIn: (v) => multiSelectIn(v, 'name') },
  products: { order: 7, validate: (v) => maxLength(5, v), value: [], notRequired: true, formatIn: (v) => multiSelectIn(v, 'name') },
  summary: { order: 2, validate: (v) => maxLength(140, v), value: '', notRequired: true },
  tags: { order: 6, validate: (v) => maxLength(5, v), value: [], notRequired: true, formatIn: (v) => multiSelectIn(v, 'name') },
  title: { order: 0, validate: (v) => (minLength(3, v) || maxLength(100, v)), value: '' },
  topics: { order: 5, validate: (v) => maxLength(5, v), value: [], notRequired: true, formatIn: (v) => multiSelectIn(v, 'name') },
};
const SPONSERED_FIELDS_TEMPLATE = {
  sponsored: { order: 9, validate: () => null, value: 'false', formatIn: (v) => boolOrNullIn(v), formatOut: (v) => boolOrNullOut(v) },
  sponsor_image: { order: 12, validate: () => null, value: null, notRequired: true },
  sponsor_link: { order: 11, validate: () => null, value: '', notRequired: true },
  sponsor_name: { order: 10, validate: () => null, value: '', notRequired: true },
};
const SPONSORED_BUTTONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
const SPONSOR_STATE_RESET = { sponsored: false, sponsor_image: null, sponsor_link: null, sponsor_name: null };
const STATE_SPREAD = { hasChanges: true };
const SUBMISSION_VALIDATIONS = {
  content: { validate: (v) => minLength(1, v) },
  image: { validate: (v) => imageV(v) },
  mobile_image: { validate: (v) => v !== null && Object.keys(v).length > 0 && imageV(v), notRequired: true },
  platforms: { validate: (v) => maxLength(5, v), notRequired: true },
  products: { validate: (v) => maxLength(5, v), notRequired: true },
  summary: { validate: (v) => maxLength(140, v) },
  tags: { validate: (v) => maxLength(5, v), notRequired: true },
  topics: { validate: (v) => maxLength(5, v), notRequired: true },
};
const SUBMISSION_SPONSORED_VALIDATIONS = {
  sponsor_image: { validate: (v) => imageV(v) },
  sponsor_link: { validate: (v) => isUrlWithProtocol(v) },
  sponsor_name: { validate: (v) => minLength(1, v) },
};

class NewsArticleForm extends PureComponent {
  constructor(props) {
    super(props);

    const template = props.isAdminOrEditor ? { ...FIELDS_TEMPLATE, ...SPONSERED_FIELDS_TEMPLATE } : FIELDS_TEMPLATE;

    this.state = {
      errors: {},
      formError: null,
      hasChanges: props.currentRecord.hasChanges,
      isBusy: false,
      fields: initFields(template, props.currentRecord.record),
      workers: [],
    };

    this.addOrUpdateContent = this.addOrUpdateContent.bind(this);
    this.onEditorUpdate = this.onEditorUpdate.bind(this);
    this.isEditorWorking = this.isEditorWorking.bind(this);
    this.requestResolver = this.requestResolver.bind(this);
    this.toggleCurrentViewProxy = this.toggleCurrentViewProxy.bind(this);
    // Form helpers
    this.getErrorForField = getErrorForField.bind(this);
    this.getFieldValuesAsObject = getFieldValuesAsObject.bind(this);
    this.setIsBusy = setIsBusy.bind(this);
    this.setStateOrError = setStateOrError.bind(this);
    this.validate = validateFields.bind(this);

    // Refs
    this._contentEditor;
  }

  /**
   * Initializers
   */
  _initDraftster() {
    return draftsterConfig({
      initContent: getInObj(['content'], this.props.currentRecord.record),
      isEditorWorking: this.isEditorWorking,
      onEditorUpdate: this.onEditorUpdate,
    });
  }

  /**
   * Methods
   */

  /**
   * @param {int} validationLvl -
   *   0: Title only.
   *   1: Publish and submission validations.
   * @param {string} status - The status enum for submitOrPublishContent.
   */
  addOrUpdateContent(validationLvl, status = null) {
    const validated = validationLvl === 0 ? this._valiadatePrimary() : this._valiadateSecondary();

    if (validated) {
      status ? this._submitOrPublishContent(status) : this._saveContent();
    }
  }

  isEditorWorking(bool) {
    if (this.state.isBusy !== bool) this.setState({ isBusy: bool });
  }

  onEditorUpdate() {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    if (this.state.errors.hasOwnProperty('content')) {
      this.setState({ errors: removeFromObject(this.state.errors, 'content'), hasChanges: true });
    } else {
      this.setState({ hasChanges: true });
    }
  }

  requestResolver(err) {
    if (err) return this.setState({ formError: err, isBusy: false });

    this.setState({
      hasChanges: false,
      isBusy: false,
    });
  }

  /**
   * @param  {string} view - ['default', 'preview']
   */
  toggleCurrentViewProxy(view) {
    return (view === 'default' && this.state.hasChanges) ? this.props.summonPrompt('redirect', view) : this._memoizeContentAndToggleView(view);
  }

  /**
   * Helpers
   */
  _buildObjectForPropagation() {
    return new Promise((resolve, reject) => convertToJSONModel(this._contentEditor.getEditorContent(), true)
      .then((content) => resolve(this._buildObjectWithContent(content)))
      .catch((err) => reject(err)));
  }

  _buildObjectWithContent(content) {
    const id = this.props.currentRecord.record ? { id: this.props.currentRecord.record.id } : {};
    const valuesObj = this.getFieldValuesAsObject();

    return {
      ...id,
      ...valuesObj,
      ...this._buildObjectForSponsor(valuesObj),
      content: content,
      platforms: multiSelectToRecordShapeOut(valuesObj.platforms),
      products: multiSelectToRecordShapeOut(valuesObj.products),
      tags: multiSelectToRecordShapeOut(valuesObj.tags),
      topics: multiSelectToRecordShapeOut(valuesObj.topics),
    };
  }

  _buildObjectForSponsor(valuesObj) {
    // If the current user is a adminOrEditor the sponsored field will exist, we want to reset sponsor data if they toggle it to false.
    // NOTE: The sponsor data will exist in memory if they toggle it back to true without a refetch / reload.
    if ((getInObj(['sponsored', 'value'], this.state.fields) === 'false')) return SPONSOR_STATE_RESET;

    // Otherwise, the sponsor toggle is true or doesn't exist.
    // If the article is null, then honor the fields if they exist or null them.
    const article = this.props.currentRecord.record;

    return {
      sponsored: (valuesObj.sponsored || getInObj(['sponsored'], article) || false),
      sponsor_image: (valuesObj.sponsor_image || getInObj(['sponsor_image'], article)),
      sponsor_link: (valuesObj.sponsor_link || getInObj(['sponsor_link'], article)),
      sponsor_name: (valuesObj.sponsor_name || getInObj(['sponsor_name'], article)),
    };
  }

  _memoizeContentAndToggleView(view) {
    if (!this.state.hasChanges) return this.props.toggleCurrentView(view);

    return this._buildObjectForPropagation()
      .then((obj) => this.props.toggleCurrentView(view, obj))
      .catch((err) => errorHandler('NewsArticleForm _memoizeContentAndToggleView', err));
  }

  _saveContent() {
    return this._buildObjectForPropagation()
      .then((obj) => this.props.saveContent(obj, this.requestResolver))
      .catch((err) => errorHandler('NewsArticleForm _saveContent', err));
  }

  _setContentFieldErrorAndScrollTo(contentError) {
    this.setState({ errors: { ...this.state.errors, content: contentError } }, () => scrollToError(this.state.errors, { content: { order: 0 } }));
  }

  _submitOrPublishContent(status) {
    this.props.submitOrPublishArticle(this.props.currentRecord.record.id, status, this.requestResolver);
  }

  _valiadatePrimary() {
    // Do not validate sponsor fields for Authors.
    if (!this.props.isAdminOrEditor) return this.validate();

    const sponsorValidations = (getInObj(['sponsored', 'value'], this.state.fields) === 'true') ? SUBMISSION_SPONSORED_VALIDATIONS : {};

    return this.validate({ validationOverrideMap: sponsorValidations });
  }

  _valiadateSecondary() {
    const submissionValidations = (getInObj(['sponsored', 'value'], this.state.fields) === 'true')
      ? { ...SUBMISSION_VALIDATIONS, ...SUBMISSION_SPONSORED_VALIDATIONS }
      : SUBMISSION_VALIDATIONS;

    const validated = this.validate({ validationOverrideMap: submissionValidations });
    const contentError = isDraftsterEmpty(this._contentEditor.getEditorContent());

    // This is to prevent having to setState twice.
    // Downside is that the content field wont get a validation error until the others have passed.
    if (validated && contentError) {
      this._setContentFieldErrorAndScrollTo(contentError);
    }

    return validated === true && contentError === null;
  }

  /**
   * Views
   */
  _getSponsoredInput() {
    if (!this.props.isAdminOrEditor) return null;

    return (
      <div id="vfsponsored">
        <RadioGroup
          buttonFirst={true}
          buttons={SPONSORED_BUTTONS}
          errors={this.state.errors.sponsored}
          label="Is this a sponsored story?"
          onChange={(e) => this.setStateOrError(null, 'sponsored', e.target.value, STATE_SPREAD)}
          value={this.state.fields.sponsored.value}
        />
        {this.state.fields.sponsored.value === 'true' && this._getSponsorInputs()}
      </div>
    );
  }

  _getSponsorInputs() {
    return (
      <div className={formStyles.nestedFogContainer}>
        <div id="vfsponsor_name">
          <BasicFormInput
            charCount={this.state.fields.sponsor_name.value.length}
            errors={this.state.errors.sponsor_name}
            label="Sponsor name"
            maxVal={255}
            onChange={(e) => this.setStateOrError(maxLength(255, e.target.value), 'sponsor_name', e.target.value, STATE_SPREAD)}
            value={this.state.fields.sponsor_name.value}
          />
        </div>

        <div id="vfsponsor_link">
          <BasicFormInput
            charCount={this.state.fields.sponsor_link.value.length}
            errors={this.state.errors.sponsor_link}
            label="Sponsor link"
            maxVal={255}
            onChange={(e) => this.setStateOrError(maxLength(255, e.target.value), 'sponsor_link', e.target.value, STATE_SPREAD)}
            value={this.state.fields.sponsor_link.value}
          />
        </div>

        <div id="vfsponsor_image">
          <label className={inputStyles.label}>Sponsor image</label>
          {createImageUploader({
            aspectRatio: (1 / 1),
            attachmentType: 'Avatar',
            cropperTitle: createCropperTitle({ title: '1:1 Crop' }),
            dimensionMins: { width: 80 },
            errors: this.state.errors.sponsor_image,
            helperText: 'This picture should have a ratio of 1:1 and be 240x240px (minimum 80x80px) for the best quality.',
            imageData: this.state.fields.sponsor_image.value,
            propagateStatus: (isBusy) => this.setIsBusy(isBusy, 'sponsor_image'),
            propagateUpload: (image) => this.setStateOrError(null, 'sponsor_image', image, STATE_SPREAD),
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <form>
        <TopMenuBar
          article={this.props.currentRecord.record}
          deleteArticle={this.props.deleteArticle}
          isAdminOrEditor={this.props.isAdminOrEditor}
          unpublishArticle={this.props.unpublishArticle}
        />

        <div className={`${layout.flexColumn} ${layout.flexCenterItems} ${layout.padding3015}`}>
          <div className={formStyles.container675}>
            <div id="vftitle">
              <BasicFormInput
                charCount={this.state.fields.title.value.length}
                errors={this.state.errors.title}
                label="Title"
                maxVal={100}
                onChange={(e) => this.setStateOrError(maxLength(100, e.target.value), 'title', e.target.value, STATE_SPREAD)}
                placeholder="Article title"
                value={this.state.fields.title.value}
              />
            </div>

            <div className={layout.marginBottom30} id="vfcontent">
              <label className={inputStyles.label}>Content</label>
              <Draftster ref={(el) => this._contentEditor = el} config={this._initDraftster()} />
              {getErrorHelperView(this.state.errors.content)}
            </div>

            <div id="vfsummary">
              <BasicFormInput
                charCount={this.state.fields.summary.value.length}
                classList={{ help: layout.paddingRight45 }}
                element="textarea"
                errors={this.state.errors.summary}
                helperText="This summary will display in cards when the article is featured."
                label="Summary"
                maxVal={140}
                onChange={(e) => this.setStateOrError(maxLength(140, e.target.value), 'summary', e.target.value, STATE_SPREAD)}
                placeholder="i.e. Learn how to automate almost anything in your home."
                value={this.state.fields.summary.value}
              />
            </div>

            <div id="vfimage">
              <label className={inputStyles.label}>Thumbnail</label>
              {createImageUploader({
                aspectRatio: (16 / 9),
                attachmentType: 'CoverImage',
                cropperTitle: createCropperTitle({ title: '16:9 Crop' }),
                dimensionMins: { width: 349 },
                errors: this.state.errors.image,
                helperText: 'This image will display in cards that link to the article. Use a ratio of 16:9 and be 1860x1047px (minimum 620x349px) for the best quality.',
                imageData: this.state.fields.image.value,
                propagateStatus: (isBusy) => this.setIsBusy(isBusy, 'image'),
                propagateUpload: (image) => this.setStateOrError(null, 'image', image, STATE_SPREAD),
              })}
            </div>

            <div id="vfmobile_image">
              <label className={inputStyles.label}>Mobile thumbnail</label>
              {createImageUploader({
                aspectRatio: (1 / 1),
                attachmentType: 'MobileCoverImage',
                cropperTitle: createCropperTitle({ title: '1:1 Crop' }),
                dimensionMins: { width: 120 },
                errors: this.state.errors.mobile_image,
                helperText: 'This image will display in the latest/trending section and on mobile. Use a ratio of 1:1 and be 360x360px (minimum 120x120px) for the best quality.',
                imageData: this.state.fields.mobile_image.value,
                propagateStatus: (isBusy) => this.setIsBusy(isBusy, 'mobile_image'),
                propagateUpload: (image) => this.setStateOrError(null, 'mobile_image', image, STATE_SPREAD),
              })}
            </div>

            <div id="vftopics">
              <label className={inputStyles.label}>Which main topics does this article relate to? (optional)</label>
              {createMultiSelect({
                algoliaParameters: { hitsPerPage: 100, initFacet: ['model:TopicChannel'] },
                algoliaService: this.props.algoliaServices.topics,
                errors: this.state.errors.topics,
                maxWidth: '100%',
                onSelect: (topics) => this.setStateOrError(null, 'topics', topics, STATE_SPREAD),
                placeholder: 'i.e. Wearables, Gaming, Robotics',
                recordsToOptions: recordsToOptions,
                selectionLimit: 5,
                value: this.state.fields.topics.value,
              })}
            </div>

            <div id="vftags">
              <label className={inputStyles.label}>Add up to 5 tags to help readers know what this article is about (optional)</label>
              {createMultiSelect({
                algoliaParameters: { hitsPerPage: 100 },
                algoliaService: this.props.algoliaServices.tags,
                errors: this.state.errors.tags,
                maxWidth: '100%',
                onSelect: (tags) => this.setStateOrError(null, 'tags', tags, STATE_SPREAD),
                placeholder: 'i.e. iot',
                recordsToOptions: recordsToOptions,
                selectionLimit: 5,
                value: this.state.fields.tags.value,
              })}
            </div>

            <div id="vfproducts">
              <label className={inputStyles.label}>Select products that are relevant to this article (optional)</label>
              {createMultiSelect({
                algoliaParameters: { hitsPerPage: 100 },
                algoliaService: this.props.algoliaServices.parts,
                errors: this.state.errors.products,
                maxWidth: '100%',
                onSelect: (products) => this.setStateOrError(null, 'products', products, STATE_SPREAD),
                placeholder: 'i.e. Raspberry Pi Zero',
                recordsToOptions: recordsToOptions,
                selectionLimit: 5,
                value: this.state.fields.products.value,
              })}
            </div>

            <div id="vfplatforms">
              <label className={inputStyles.label}>Select platforms that are relevant to this article (optional)</label>
              {createMultiSelect({
                algoliaParameters: { hitsPerPage: 100, initFacet: ['model:Platform'] },
                algoliaService: this.props.algoliaServices.platforms,
                errors: this.state.errors.platforms,
                maxWidth: '100%',
                onSelect: (platforms) => this.setStateOrError(null, 'platforms', platforms, STATE_SPREAD),
                placeholder: 'i.e. Arduino, Raspberry Pi',
                recordsToOptions: recordsToOptions,
                selectionLimit: 5,
                value: this.state.fields.platforms.value,
              })}
            </div>

            {this._getSponsoredInput()}
          </div>
        </div>
        <StickyActionsBar
          article={this.props.currentRecord.record}
          disabled={this.state.isBusy} // state.isBusy indicates form workers are busy, and should just disable buttons
          hasChanges={this.state.hasChanges}
          isAdminOrEditor={this.props.isAdminOrEditor}
          isBusy={this.props.isBusy} // props.isBusy indicates save and publish actions are busy, and should render a spinner on the actionable button
          saveProgress={this.addOrUpdateContent}
          submitOrPublish={this.submitOrPublishContent}
          toggleCurrentView={this.toggleCurrentViewProxy}
          view="form"
        />
      </form>
    );
  }
}

NewsArticleForm.propTypes = {
  algoliaServices: PropTypes.shape({
    parts: PropTypes.object.isRequired,
    platforms: PropTypes.object.isRequired,
    tags: PropTypes.object.isRequired,
    topics: PropTypes.object.isRequired,
  }).isRequired,
  currentRecord: PropTypes.shape({
    hasChanges: PropTypes.bool.isRequired,
    record: PropTypes.shape({
      content: PropTypes.array, // JSON
      id: PropTypes.number,
      image: PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      }),
      platforms: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })),
      products: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })),
      published_at: PropTypes.string,
      respects_count: PropTypes.number,
      sponsor_image: PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      }),
      sponsor_link: PropTypes.string,
      sponsor_name: PropTypes.string,
      sponsored: PropTypes.bool.isRequired,
      status: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })),
      title: PropTypes.string.isRequired,
      topics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })),
      updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Variable for Date.now and Rails formats.
      url: PropTypes.string,
      user: PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        news_role: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }),
  deleteArticle: PropTypes.func.isRequired,
  isAdminOrEditor: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isFetchingRecord: PropTypes.bool.isRequired,
  saveContent: PropTypes.func.isRequired,
  submitOrPublishArticle: PropTypes.func.isRequired,
  summonPrompt: PropTypes.func.isRequired,
  toggleCurrentView: PropTypes.func.isRequired,
  unpublishArticle: PropTypes.func.isRequired,
};

NewsArticleForm.defaultProps = {
  currentRecord: {
    hasChanges: false,
    record: null,
  },
};

export default NewsArticleForm;
