import React from 'react';

import { TextPlaceholder } from '../../../scenes/home_edit/events/forms/preview_cards/templates';

import layout from '../../../styles/global_ui/layout.css';

const DummyBaseCardText = ({ className = '', size = 'M' }) => (
  <div className={`${className} ${layout.fullWidth}`}>
    <TextPlaceholder height={size === 'M' ? 16 : 18} width="95%" />
    <TextPlaceholder height={size === 'M' ? 14 : 16} marginTop={10} width="40%" />
    <TextPlaceholder height={size === 'M' ? 14 : 16} marginTop={10} width="60%" />
  </div>
);

export default DummyBaseCardText;
