import React from 'react';
import PropTypes from 'prop-types';

import { VIDEO } from './helpers';

const VIDEO_ROLE_PROPS = {
  gif: {
    autoPlay: true,
    loop: true,
    muted: true,
  },
  video: {
    controls: true,
    preload: 'metadata',
  },
};

const _getImgView = ({ alt, className, onClick, onError, onLoad, src, srcSet }) => (
  <img
    alt={alt}
    className={className}
    onClick={onClick}
    onError={onError}
    onLoad={onLoad}
    src={src}
    srcSet={srcSet}
  />
);

const _getVideoView = ({ alt, className, onClick, onError, onLoad, src, videoRole }) => (
  <video
    alt={alt}
    className={className}
    onClick={onClick}
    onError={onError}
    onLoadedData={onLoad}
    playsInline
    {...VIDEO_ROLE_PROPS[videoRole]}
  >
    <source src={src} type="video/mp4" />
  </video>
);

const MediaElement = (props) => (
  props.format === VIDEO ? _getVideoView(props) : _getImgView(props)
);

MediaElement.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  format: PropTypes.string,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  videoRole: PropTypes.oneOf(['gif', 'video']), // should it behave like a gif or a video player?
};

MediaElement.defaultProps = {
  alt: '',
  className: '',
  format: '',
  onClick: () => {},
  onError: () => {},
  onLoad: () => {},
  src: '',
  srcSet: '',
  videoRole: 'gif',
};

export default MediaElement;
