import React from 'react';
import PropTypes from 'prop-types';

import animation from '../../styles/animation.css';
import colors from '../../styles/global_ui/colors.css';
import layout from '../../styles/global_ui/layout.css';

const ImagePlaceholder = ({ animated, aspectRatio, backgroundColor, className }) => (
  <div
    className={`${layout.fullWidth} ${className} ${animated ? animation.loader : ''}`}
    style={{
      backgroundColor: backgroundColor,
      paddingTop: `${(1 / aspectRatio) * 100}%`,
    }}
  />
);

ImagePlaceholder.propTypes = {
  animated: PropTypes.bool,
  aspectRatio: PropTypes.number,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

ImagePlaceholder.defaultProps = {
  animated: false,
  aspectRatio: 4 / 3,
  backgroundColor: colors['v-fog'],
  className: '',
};

export default ImagePlaceholder;
