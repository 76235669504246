import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import {genKey} from 'draft-js';

import EmbedToolbar from './EmbedToolbar';
import Figcaption from './Figcaption';
import Dialog from './Dialog';
import ImageLinkForm from './ImageLinkForm';
import Spinner from './Spinner';

import styles from './Carousel/carousel.css';
import slideUpTransitions from '../styles/slideUpTransitions.css';

class ImageLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      showForm: false
    };

    this.handleCaptionKeyDown = this.handleCaptionKeyDown.bind(this);
    this.handleFigcaptionChange = this.handleFigcaptionChange.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.updateImage = this.updateImage.bind(this);

    this._isMounted;
  }

  UNSAFE_componentWillMount() {
    const entity = this.props.block.getEntityAt(0);
    const image = this.props.contentState.getEntity(entity).getData().image;

    if (image.get('pending')) {
      this.props.blockProps.processImage(image.toJS(), entity, this.props.block.getKey());
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onMouseOver(e) {
    if (e.target.classList.contains('react-editor-image') || e.target.classList.contains('react-editor-image-wrapper')) {
      this.setState({hovered: true});
    }
  }

  handleCaptionKeyDown(e) {
    switch(e.keyCode) {
      case 38: // UP ARROW
        this._moveSelectionTo(e, 'previous');
        break;
      case 40: // DOWN ARROW
      case 13: // ENTER
      case 9: // TAB
        this._moveSelectionTo(e, 'next');
        break;
      default:
        break;
    }
  }

  handleFigcaptionChange(value) {
    const {block, blockProps, contentState} = this.props;
    const image = this.props.contentState.getEntity(block.getEntityAt(0)).getData().image;
    const newImage = image.set('figcaption', value);

    blockProps.updateContentState(contentState.replaceEntityData(block.getEntityAt(0), { image: newImage }));
  }

  _moveSelectionTo(e, direction) {
    e.preventDefault();
    this.props.blockProps.moveSelectionTo(this.props.block, direction);
  }

  toggleForm(bool) {
    this.props.blockProps.editing(bool);

    setTimeout(() => {
      if (this._isMounted) {
        this.props.blockProps.actions.toggleDialogStatus(bool);
        this.setState({showForm: bool});
      }
    }, 20);
  }

  updateImage(array) {
    const { block, blockProps, contentState } = this.props;
    const image = contentState.getEntity(block.getEntityAt(0)).getData().image;

    blockProps.updateContentState(contentState.replaceEntityData(block.getEntityAt(0), { image: image.merge(array[0]) }), 'apply-entity');

    // Reset the EmbedToolbars height.
    this.setState({hovered: false});
  }

  render() {
    const { block, blockProps, contentState } = this.props;
    const image = contentState.getEntity(block.getEntityAt(0)).getData().image;

    return (
      <div
        className={`${styles.root} react-editor-carousel`}
        onMouseOver={(e) => this.onMouseOver(e)}
        tabIndex={0}
      >
        <div className={`${styles.images} react-editor-carousel-inner`}>
          <figure className={`${styles.figure} ${styles.show} react-editor-figure`} data-type="image">
            <div className={`${styles.imageWrapper} react-editor-image-wrapper`} contentEditable={false}>
              {!image.has('id') &&
                <div className={styles.loader}>
                  <Spinner />
                  <p>Uploading image</p>
                </div>
              }
              <img
                alt={image.get('name')}
                className={`${styles.image} react-editor-image`}
                src={image.get('url')}
                width={image.get('width')}
              />
              <Figcaption
                className={`${styles.figcaption} react-editor-figcaption`}
                onBlur={() => this.props.blockProps.editing(false)}
                onFocus={() => this.props.blockProps.editing(true)}
                onKeyDown={this.handleCaptionKeyDown}
                onValueChange={this.handleFigcaptionChange}
                value={image.get('figcaption') || ''}
              />
            </div>
          </figure>
          {
            this.state.hovered
              ? (
                  <EmbedToolbar
                    data={{height: image.get('height')}}
                    embedType="link"
                    openImageEditor={() => this.toggleForm(true)}
                    parent={this}
                    remove={() => blockProps.remove(block)}
                    unmountToolbar={() => this.setState({hovered: false})}
                  />
                )
              : null
          }
          <CSSTransition
            classNames={slideUpTransitions}
            timeout={450}>
            {
              this.state.showForm
                ? (
                    <Dialog
                      dismiss={() => this.toggleForm(false)}
                      open={this.state.showForm}
                      title="Edit image"
                      >
                      <ImageLinkForm
                        create={this.updateImage}
                        dismiss={() => this.toggleForm(false)}
                        image={image.toJS()}
                        link={image.get('link')}
                        messenger={blockProps.message}
                      />
                    </Dialog>
                  )
                : (<div></div>)
            }
          </CSSTransition>
        </div>
      </div>
    );
  }
}

ImageLink.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: PropTypes.object.isRequired
};

ImageLink.defaultProps = {
};

export default ImageLink;