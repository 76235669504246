// Types with associated entites
export const EVENT_CARD = 'event_card';
export const NEWS_ARTICLE_CARD = 'news_article_card';
export const PROJECT_CARD = 'project_card';

// Types without associated entities
export const NEWS_PROMO_BANNER = 'news_promo_banner';

export function getEntityType(type) {
  return LINK_TO_ENTITY_TYPE_MAP[type];
}

const LINK_TO_ENTITY_TYPE_MAP = {
  [EVENT_CARD]: 'Event',
  [NEWS_ARTICLE_CARD]: 'NewsArticle',
  [PROJECT_CARD]: 'Project',
};
