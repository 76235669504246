import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import MarkdownViewer from '../../markdown/markdown_viewer';
import PostCardCommentHeader from './PostCardCommentHeader';
import PostCardCommentInput from './PostCardCommentInput';
import RespectButton from '../../buttons/respect_button';
import RingSpinner from '../../spinners/ring';

import keenService from '../../../services/keen/main';
import { getClickedViewMoreArgs } from '../../../services/keen/events/eventTypeTemplates';

import shouldTruncateEscapedText from '../../../services/markdown/shouldTruncateEscapedText';
import { getInObj } from '../../../utility/accessors';
import { isBlank } from '../../../utility/types';
import { objHasPropertyWithValue } from '../../../utility/predicates';

import { COMMENT } from '../../../graphql/respects/enum.js';

import animation from '../../../styles/global_ui/animation.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './post_card_comments.css';

const COLLAPSE_AT_LIMIT = 150;
const DELETED_COMMENT_COPY = 'This comment has been deleted.';

const _isChildComment = (comment) => objHasPropertyWithValue(comment, 'parent_id');

class PostCardComment extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      isReplying: false,
      locationHash: null,
      openMenu: false,
      renderChildren: props.renderAllComments,
    };

    this.toggleMenu = this.toggleMenu.bind(this);

    // memo
    this._calculatedHash = null;
  }

  /**
   * Methods
   */
  toggleMenu() {
    this.setState({ openMenu: !this.state.openMenu });
  }

  /**
   * Helpers
   */
  _areAllChildIdsRenderedInChildren() {
    if (!this.props.children) return false;

    const renderedChildrenIds = this.props.children.map((child) => child.props.comment.id);

    return this.props.comment.child_ids.every((id) => renderedChildrenIds.includes(id));
  }

  _doesCommentIdMatchLocationHash() {
    if (!this.props.historyLocation.hash) return false;

    // This only happens once per page mount. If we need to internally scroll via history state, we can remove the memoization.
    // The Comments component pulls out any initial url hash onMount, scrolls to the comment if it exists, then here
    // we apply the animation class if theres a match.
    if (this._calculatedHash !== null) return this._calculatedHash;
    // Hash: "comment-comment_id"
    const memo = this.props.historyLocation.hash.split('-')[1] === this.props.comment.id.toString();
    this._calculatedHash = memo;

    return memo;
  }

  _fireViewMoreClickedAnalytics() {
    keenService.reportEventWithObj(getClickedViewMoreArgs({ entity_id: this.props.comment.id, entity_type: 'Comment' }));
  }

  _getDefaultBodyViewShouldTruncate() {
    const shouldTruncate = getInObj(['__meta', 'truncate'], this.props.comment);

    return shouldTruncate === null ? shouldTruncateEscapedText(this.props.comment.body_with_relations, COLLAPSE_AT_LIMIT) : { limit: COLLAPSE_AT_LIMIT, truncate: shouldTruncate };
  }

  _getShapeForDeletion() {
    return { ...this.props.comment, commentable_id: this.props.postId };
  }

  _handleDeleteClick() {
    this.props.onDelete({ comment: this._getShapeForDeletion() });
    this.setState({ openMenu: false });
  }

  _handleReplyButtonClick() {
    if (!this.props.currentUser.id) return this.props.summonLoginPanel();

    return _isChildComment(this.props.comment)
      ? this.props.replyToThread()
      : this.setState({ isReplying: true });
  }

  // Child comments will get this injected into their props via cloneElement.
  _handleReplyToThreadClick() {
    this.setState({ isReplying: true });
  }

  _getCommentShapeForReplyingInput() {
    // When replying we need to use the parent_id if it exists when a user clicks "Reply to conversation".
    return {
      id: null,
      parent_id: (this.props.comment.parent_id || this.props.comment.id),
      raw_body: '',
    };
  }

  _getShowRepliesWorkerKey() {
    return `comment_${this.props.comment.id}_replies`;
  }

  /**
   * Views
   */
  _getBody() {
    if (this.props.comment.deleted) return DELETED_COMMENT_COPY;
    if (this.state.isEditing) return this._getEditBodyView();

    return this._getDefaultBodyView();
  }

  _getEditBodyView() {
    return (
      <div className={`${layout.marginTop5} ${layout.marginBottom15}`}>
        <PostCardCommentInput
          comment={this.props.comment}
          currentUser={this.props.currentUser}
          dismiss={() => this.setState({ isEditing: false })}
          isBusy={this.props.isBusy}
          markdownService={this.props.markdownServices.editor}
          mode="edit"
          onPost={this.props.onPost}
          postId={this.props.postId}
          summonConfirmationEmailMsg={this.props.summonConfirmationEmailMsg}
          summonLoginPanel={this.props.summonLoginPanel}
        />
      </div>
    );
  }

  /**
   * Truncate when state.didExpandContent is false, otherwise the user clicked more and on edit and remount, honor it.
   */
  _getDefaultBodyView() {
    return (
      <MarkdownViewer
        content={this.props.comment.body_with_relations}
        markdownService={this.props.markdownServices.postOrComment}
        truncateHtml={this._getDefaultBodyViewShouldTruncate()}
        viewMoreClicked={() => this._fireViewMoreClickedAnalytics()}
      />
    );
  }

  _getConversationClosed() {
    return (
      <span className={`${typography.bodyXS} ${typography.pebble} ${layout.marginRight10}`}>
        (Conversation closed)
      </span>
    );
  }

  _getReplyButton() {
    if (this.props.comment.deleted || this.state.isEditing || !this.props.userCanComment) return null;
    if (this.props.parentIsDeleted) return this._getConversationClosed();

    return (
      <p
        className={`${typography.linkCharcoal} ${typography.bodyS} ${layout.marginRight10}`}
        onClick={() => this._handleReplyButtonClick()}
      >
        {_isChildComment(this.props.comment) ? 'Reply to conversation' : 'Reply'}
      </p>
    );
  }

  _getRespectButton() {
    if (this.props.comment.deleted || this.state.isEditing) return null;

    return (
      <span>
        <RespectButton
          id={this.props.comment.id}
          respects={this.props.comment.liking_user_ids.length}
          theme="postCardComment"
          type={COMMENT}
        />
      </span>
    );
  }

  _getShowRepliesBtn() {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return this.props.workers.hasOwnProperty(this._getShowRepliesWorkerKey())
      ? this._getShowRepliesBtnBusyView()
      : this._getShowRepliesBtnView();
  }

  _getShowRepliesBtnView() {
    const child_ids = this.props.comment.child_ids;

    if (!child_ids || (child_ids && !child_ids.length)) return null;
    if (this._areAllChildIdsRenderedInChildren()) return null;

    return (
      <p className={`${typography.bodyS} ${layout.marginLeft5}`}>
        <span className={typography.hackster}>&#8627; </span>
        <span
          className={typography.linkBlue}
          onClick={() => this.props.onShowMoreCommentRepliesClick({
            comment: this.props.comment,
            postId: this.props.postId,
            worker: this._getShowRepliesWorkerKey(),
          })}
        >
          {`Show ${child_ids.length} ${child_ids.length > 1 ? 'replies' : 'reply'}`}
        </span>
      </p>
    );
  }

  _getShowRepliesBtnBusyView() {
    return (
      <div>
        <RingSpinner size={16} />
      </div>
    );
  }

  render() {
    const isChildComment = _isChildComment(this.props.comment);

    return (
      <div className={`${isChildComment ? styles.childCommentRoot : styles.commentRoot}`} id={`comment-${this.props.comment.id}`}>
        <div className={layout.flex}>
          <div>
            <img
              className={`${isChildComment ? styles.avatarSm : styles.avatar} ${this._doesCommentIdMatchLocationHash() ? animation.pulse : ''}`}
              src={this.props.comment.user.avatar_url}
            />
          </div>

          <div className={`${layout.fullWidth} ${layout.marginLeft10}`}>
            <div className={styles.showMenuOnHover}>
              <PostCardCommentHeader
                comment={this.props.comment}
                currentUser={this.props.currentUser}
                isFlagged={this.props.isFlagged}
                onCopyLinkClick={() => this.props.onCopyLinkClick(
                  { commentId: this.props.comment.id, postId: this.props.postId },
                  'comment',
                  this.toggleMenu,
                )}
                onDeleteClick={() => this._handleDeleteClick()}
                onEditClick={() => this.setState({ isEditing: true, isReplying: false, openMenu: false })}
                onReportClick={() => this.props.currentUser.id === null
                  ? this.props.summonLoginPanel()
                  : this.props.onReportClick(this.props.comment, 'comment', this.toggleMenu)}
                onSpamClick={() => this.props.onSpamClick(this._getShapeForDeletion(), 'comment', this.toggleMenu)}
                openMenu={this.state.openMenu}
                origin={this.props.origin}
                toggleMenu={this.toggleMenu}
              />

              <div className={typography.bodyM}>
                {this._getBody()}
              </div>

              <div className={`${layout.flexCenterItems} ${layout.marginTop5}`}>
                {this._getReplyButton()}
                {this._getRespectButton()}
              </div>

              {this._getShowRepliesBtn()}
            </div>

            {!isBlank(this.props.children)
            && (
              <div>
                {this.props.children.map((child) => React.cloneElement(child, { replyToThread: () => this._handleReplyToThreadClick() }))}
              </div>
            )}

            {this.state.isReplying && this.props.userCanComment
            && (
              <div className={`${layout.marginTop5}`}>
                <PostCardCommentInput
                  comment={this._getCommentShapeForReplyingInput()}
                  currentUser={this.props.currentUser}
                  dismiss={() => this.setState({ isReplying: false })}
                  isBusy={this.props.isBusy}
                  markdownService={this.props.markdownServices.editor}
                  mode="reply"
                  onPost={this.props.onPost}
                  postId={this.props.postId}
                  summonConfirmationEmailMsg={this.props.summonConfirmationEmailMsg}
                  summonLoginPanel={this.props.summonLoginPanel}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PostCardComment.propTypes = {
  comment: PropTypes.shape({
    __meta: PropTypes.shape({ // Added to records when creating or updating in the root app.
      truncate: PropTypes.bool,
    }),
    body_with_relations: PropTypes.string,
    child_ids: PropTypes.arrayOf(PropTypes.number), // Exists for feed comments. Gives us a count of children.
    children: PropTypes.array, // Recursive comments shape, this may be null
    created_at: PropTypes.string.isRequired,
    edited_at: PropTypes.string,
    deleted: PropTypes.bool,
    id: PropTypes.number.isRequired,
    liking_user_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    parent_count: PropTypes.number, // Exists for feed comments. Total root comment records.
    parent_id: PropTypes.number,
    raw_body: PropTypes.string.isRequired, // used when editing the comment
    relations: PropTypes.object,
    user: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string.isRequired,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  historyLocation: PropTypes.shape({ hash: PropTypes.string }).isRequired,
  isBusy: PropTypes.bool.isRequired,
  isFlagged: PropTypes.bool.isRequired,
  markdownServices: PropTypes.shape({
    editor: PropTypes.object.isRequired,
    postOrComment: PropTypes.object.isRequired,
  }).isRequired,
  onCopyLinkClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPost: PropTypes.func.isRequired,
  onShowMoreCommentRepliesClick: PropTypes.func.isRequired,
  origin: PropTypes.shape({
    admin_ids: PropTypes.arrayOf(PropTypes.number),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  parentIsDeleted: PropTypes.bool.isRequired,
  postId: PropTypes.number.isRequired,
  renderAllComments: PropTypes.bool.isRequired,
  summonConfirmationEmailMsg: PropTypes.func.isRequired,
  summonLoginPanel: PropTypes.func.isRequired,
  userCanComment: PropTypes.bool.isRequired,
  workers: PropTypes.object.isRequired,
};

PostCardComment.defaultProps = {
  historyLocation: { hash: null },
  replyToThread: () => {},
};

export default PostCardComment;
