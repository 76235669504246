import React from 'react';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './promoted_content.css';

const DummyMobileCard = () => (
  <div className={styles.mobileCard}>
    <div className={styles.dummyMobileCardImage} />
    <div className={styles.mobileCardInfo}>
      <p className={typography.h5}>Type</p>
      <a className={`${typography.bodyS} ${typography.link} ${typography.bold}`}>Title</a>
    </div>
  </div>
);

export const DummyListItem = () => (
  <div className={styles.listItem}>
    <div>
      <p className={typography.h5}>Type</p>
      <p className={`${typography.link} ${typography.bodyM} ${typography.bold}`}>Title</p>
    </div>
    <div className={styles.dummyImageSmall} />
  </div>
);

export const DummyContentDefaultView = () => (
  <div className={styles.defaultView}>
    <div className={styles.mainCard}>
      <div className={styles.mainInfo}>
        <p className={`${typography.h5} ${layout.marginBottom10}`}>Type</p>
        <p className={`${typography.linkCharcoal} ${typography.h3} ${layout.marginBottom15} ${layout.block}`}>
          Four item preview
        </p>
        <p className={typography.bodyM}>This preview layout will change when you add 1-2 items, then again on 3-4 items.</p>
      </div>

      <div className={styles.dummyImageLarge} />
    </div>

    <div className={styles.listContainer}>
      {[0, 0, 0].map((n, i) => <DummyListItem key={i} />)}
    </div>
  </div>
);

export const DummyMobileView = () => (
  <div className={styles.dummyMobileView}>
    {[0, 0, 0, 0].map((item, i) => (<DummyMobileCard key={i} />))}
  </div>
);

export const DummyTwoByCard = () => (
  <div className={styles.twoByCard}>
    <div className={styles.twoByCardInfo}>
      <div>
        <p className={`${typography.h5} ${layout.marginBottom10}`}>Type</p>
        <p className={`${typography.linkCharcoal} ${typography.h4} ${layout.marginBottom22} ${layout.block}`}>Title</p>
      </div>
      <p className={typography.bodyS}>Description</p>
    </div>

    <div className={styles.dummyImageTwoBy} />
  </div>
);
