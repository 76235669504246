import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Cell from '../cells/Cell';
import Paginator from '../../nav_components/paginator';
import SelectableCell from '../cells/SelectableCell';
import SelectableHeader from '../headers/SelectableHeader';
import OrderableHeader from '../headers/OrderableHeader';

import typography from '../../../styles/global_ui/typography.css';
import styles from '../../../styles/global_ui/table.css';

class SelectableTable extends Component {
  constructor(props) {
    super(props);

    this.state = { headers: this._initHeaders() };

    this.handleSelectableHeader = this.handleSelectableHeader.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.setHeaderActive = this.setHeaderActive.bind(this);
  }

  /**
   * Initializers
   */
  _initHeaders() {
    return this.props.headers.map((header) => ({
      ...header,
      status: {
        isActive: header.default || false,
        state: 0,
      },
    }));
  }

  /**
   * Methods
   */
  handleSelectableHeader(bool, header) {
    const updatedIndexes = bool ? [...Array(this.props.tableItems.length)].map((u, i) => i) : [];
    this.props.reportSelected(updatedIndexes);
  }

  onCheckboxChange(isSelected, index, record) {
    const updatedIndexes = isSelected ? this.props.selectedIndexes.concat(index) : this.props.selectedIndexes.filter((item) => item !== index);
    this.props.reportSelected(updatedIndexes, isSelected, record);
  }

  setHeaderActive(header, position) {
    const activeHeaderStatus = header.status.isActive ? { isActive: true, state: header.status.state === 1 ? 0 : 1 } : { isActive: true, state: 0 };
    const updatedHeaders = this.state.headers.map((header, i) => ({
      ...header,
      status: (i === position) ? activeHeaderStatus : { isActive: false, state: 0 },
    }));

    this.props.onSort({ sort: header.sort, status: activeHeaderStatus.state });

    this.setState({ headers: updatedHeaders });
  }

  /**
   * Views
   */
  _createBodyView() {
    return this.props.tableItems.map((item, itemIndex) => {
      const isSelected = this.props.selectedIndexes.includes(itemIndex);

      return (
        <tr key={item.record.id} className={styles.row}>
          {item.columns.map((col, i) => (
            i === 0
              ? this._createSelectableCell(col, i, itemIndex, item.record, isSelected)
              : this._createCell(col, i, itemIndex, item.record.id, isSelected)
          ))}
        </tr>
      );
    });
  }

  _createCell(body, index, tableItemIndex, id, isSelected) {
    return (<Cell key={`${id}_${index}`} body={body} className={styles.cell} disabled={this.props.disabledState && !isSelected} />);
  }

  _createOrderableHeader(header, i) {
    return (
      <OrderableHeader
        key={i}
        config={this.props.config[i]}
        header={header}
        position={i}
        setHeaderActive={this.setHeaderActive}
      />
    );
  }

  _createNoContentView() {
    return (
      <tr>
        <td className={`${styles.noContent} ${typography.bodyM}`} colSpan={this.props.headers.length}>No content</td>
      </tr>
    );
  }

  _createSelectableCell(body, index, tableItemIndex, record, isSelected) {
    return (
      <SelectableCell
        key={`${record.id}_${index}`}
        body={body}
        className={styles.selectableCell}
        disabled={this.props.disabledState && !isSelected}
        isSelected={isSelected}
        onCheckboxChange={(bool) => this.onCheckboxChange(bool, tableItemIndex, record)}
      />
    );
  }

  _createSelectableHeader(header, i) {
    return (
      <SelectableHeader
        key={i}
        config={this.props.config[i]}
        header={header}
        isSelected={this.props.tableItems.length > 0 && this.props.selectedIndexes.length === this.props.tableItems.length}
        onCheck={(bool) => this.handleSelectableHeader(bool, header)}
        position={i}
        setHeaderActive={this.setHeaderActive}
      />
    );
  }

  _getBodyView() {
    if (!this.props.tableItems.length) return this._createNoContentView();

    return this._createBodyView();
  }

  _getHeaderView() {
    return this.state.headers.map((header, i) => (
      i === 0 && header.selectable ? this._createSelectableHeader(header, i) : this._createOrderableHeader(header, i)
    ));
  }

  _getPaginatorView() {
    if (!this.props.tableItems.length) return null;
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return this.props.pagination.hasOwnProperty('total_pages') ? this._getDefaultPaginator() : this._getSimplifiedPaginator();
  }

  _getDefaultPaginator() {
    return (
      <Paginator
        currentPage={this.props.pagination.current_page}
        onClick={(page) => this.props.onPagination(page)}
        totalPages={this.props.pagination.total_pages}
      />
    );
  }

  _getSimplifiedPaginator() {
    if (this.props.pagination.current_page === 1 && this.props.pagination.next_page === null) return null;

    return (
      <Paginator
        currentPage={this.props.pagination.current_page}
        nextPage={this.props.pagination.next_page}
        onClick={(page) => this.props.onPagination(page)}
        prevPage={this.props.pagination.prev_page}
      />
    );
  }

  render() {
    return (
      <div>
        <table className={styles.table}>
          <thead className="">
            <tr>{this._getHeaderView()}</tr>
          </thead>

          <tbody className={styles.body}>
            {this._getBodyView()}
            {this.props.isBusy
            && (
              <tr>
                {[...new Array(this.props.headers.length)].map((n, i) => i === 0 ? <td key={i} className={styles.loadingOverlay} /> : <td key={i} />,
                )}
              </tr>
            )}
          </tbody>
        </table>

        {this._getPaginatorView()}
      </div>
    );
  }
}

SelectableTable.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({ width: PropTypes.number })),
  disabledState: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    orderable: PropTypes.bool,
    sort: PropTypes.string,
  })).isRequired,
  isBusy: PropTypes.bool.isRequired,
  onPagination: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    current_page: PropTypes.integer,
    per_page: PropTypes.integer,
    // Normal pagination
    total_pages: PropTypes.integer,
    total_records: PropTypes.integer,
    // Simplified pagination
    next_page: PropTypes.integer,
    prev_page: PropTypes.integer,
  }),
  reportSelected: PropTypes.func,
  tableItems: PropTypes.arrayOf(PropTypes.shape({
    columns: PropTypes.array.isRequired,
    record: PropTypes.object,
  })).isRequired,
};

SelectableTable.defaultProps = {
  config: [],
  disabledState: false,
  reportSelected: () => {},
};

export default SelectableTable;
