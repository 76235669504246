import React from 'react';
import PropTypes from 'prop-types';

// HOC
import adAnalyticsTracker from '../wrappers/adAnalyticsTracker';

import { adFAQLink } from '../../../constants/links';
import Image from '../../image';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './home_page_banner_ad.css';

const HomePageBannerAd = ({ ad, forwardedRef, onClick, onImageLoad }) => (
  <div ref={forwardedRef} style={{ paddingTop: 50 /* Matches view_all_container */ }}>
    <div className={styles.imageContainer}>
      <a
        href={ad.link}
        onClick={() => onClick(ad)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Image
          className={utilStyles.borderRadius}
          lazy={true}
          onLoad={() => onImageLoad(ad)}
          ratio="364:45"
          setStyle={false}
          src={ad.cover_image.url}
          width={1170}
        />
      </a>
    </div>

    <div className={layout.flexJustifyEnd}>
      <a
        className={typography.bodyS}
        href={adFAQLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        Ad
      </a>
    </div>
  </div>
);

HomePageBannerAd.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ad_type: PropTypes.string.isRequired,
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
  }),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  onImageLoad: PropTypes.func.isRequired,
};

HomePageBannerAd.defaultProps = { ad: null };

export default adAnalyticsTracker(HomePageBannerAd);
