import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Badge from '../../reusable_components/Badge';
import CommentActions from './CommentActions';
import CommentEditor from '../editor';
import CommentMenu from './CommentMenu';

import markdown from '../../utils/Markdown';
import { timestampToPrettyDate, timestampToRelativeTime } from '../../../utility/time';

class Comment extends Component {
  constructor(props) {
    super(props);

    this.state = { isEditing: false };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleReplyClick = this.handleReplyClick.bind(this);
    this.updateComment = this.updateComment.bind(this);
  }

  /**
   * Methods
   */
  handleDeleteClick(e) {
    e.preventDefault();

    const confirm = window.confirm('Are you sure you want to delete this comment?');

    if (confirm) {
      this.props.deleteComment();
    }
  }

  handleReplyClick() {
    this.props.toggleReplyBox({
      id: this.props.comment.depth === 0 ? this.props.comment.id : this.props.comment.parent_id,
      show: true,
    });
  }

  updateComment(value) {
    if (value !== this.props.comment.md_body) {
      this.props.updateComment(this.props.comment, value);
    }
    this.setState({ isEditing: false });
  }

  /**
   * Views
   */
  _getBodyView() {
    return this.state.isEditing ? this._getEditingBodyView() : this._getDefaultBodyView();
  }

  _getDefaultBodyView() {
    if (this.props.comment.depth === 0 && this.props.comment.deleted === true) {
      return (<div className="comment-body">This comment has been deleted.</div>);
    }

    return (
      <Fragment>
        <div dangerouslySetInnerHTML={{ __html: markdown.render(this.props.comment.md_body) }} className="comment-body" />
        <CommentActions
          comment={this.props.comment}
          createLike={this.props.createLike}
          currentUser={this.props.currentUser}
          deleteLike={this.props.deleteLike}
          newCommentsDisabled={this.props.newCommentsDisabled}
          onReplyClick={this.handleReplyClick}
          parentIsDeleted={this.props.parentIsDeleted}
        />
      </Fragment>
    );
  }

  _getEditingBodyView() {
    return (
      <div className="comments-form">
        <CommentEditor
          initialTextValue={this.props.comment.md_body}
          isEditing={this.state.isEditing}
          onDismiss={() => this.setState({ isEditing: false })}
          onPost={this.updateComment}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }

  _getReplyBoxView() {
    // We render the reply box at the bottom of threads. The replyBox.id will always point to a root comment.
    if (!this.props.replyBox.show || this.props.replyBox.id !== this.props.comment.id) return null;

    const editorWrapperClass = (this.props.children && this.props.children.length > 0) ? 'comments-form reply' : 'comments-form';

    return (
      <div className="reply-box">
        <div className={editorWrapperClass}>
          <CommentEditor
            config={{ scrollToOnMount: true }}
            onDismiss={() => this.props.toggleReplyBox({ id: null, show: false })}
            onPost={(text) => this.props.postCommentReply(text, this.props.comment)}
            placeholder={this.props.placeholder}
          />
        </div>
      </div>
    );
  }

  _getUserAvatarView() {
    const user = this.props.comment.user;

    return user.url
      ? (
        <a href={user.url}>
          <img alt={user.name} src={user.avatar_url} />
        </a>
        )
      : (<img alt={user.name} src={user.avatar_url} />);
  }

  _getUserNameView() {
    const user = this.props.comment.user;

    return user.url
      ? (<a href={user.url}>{user.name}</a>)
      : user.name;
  }

  render() {
    const commentClassName = this.props.comment.depth === 0 ? 'comment' : 'comment comment-nested';

    return (
      <div>
        <div className={commentClassName} id={`comment_${this.props.comment.id}`}>
          <div className="comment-title">
            <div className="avatar">{this._getUserAvatarView()}</div>

            <div className="profile-name">
              <h4>
                <strong>
                  {this._getUserNameView()}
                </strong>
                <Badge size="small" user={this.props.comment.user} />
              </h4>
              <div className="text-muted comment-date" title={timestampToPrettyDate(this.props.comment.created_at)}>{timestampToRelativeTime(this.props.comment.created_at)}</div>
            </div>

            {(!this.state.isEditing && !this.props.comment.deleted)
            && (
              <CommentMenu
                comment={this.props.comment}
                currentUser={this.props.currentUser}
                onDeleteClick={this.handleDeleteClick}
                onEditClick={() => this.setState({ isEditing: true })}
              />
            )}
          </div>

          {this._getBodyView()}
        </div>
        {this.props.children}
        {!this.state.isEditing && this._getReplyBoxView()}
      </div>
    );
  }
}

Comment.propTypes = {
  children: PropTypes.array,
  comment: PropTypes.shape({
    body_with_relations: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    deleted: PropTypes.bool.isRequired,
    depth: PropTypes.number.isRequired,
    edited_at: PropTypes.string,
    id: PropTypes.number.isRequired,
    liking_user_ids: PropTypes.arrayOf(PropTypes.number),
    md_body: PropTypes.string,
    parent_id: PropTypes.number,
    user: PropTypes.shape({
      avatar_url: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
  createLike: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }),
  deleteComment: PropTypes.func.isRequired,
  deleteLike: PropTypes.func.isRequired,
  newCommentsDisabled: PropTypes.bool,
  parentIsDeleted: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  postCommentReply: PropTypes.func.isRequired,
  replyBox: PropTypes.object.isRequired,
  toggleReplyBox: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
};

Comment.defaultProps = {
  children: null,
  currentUser: null,
  newCommentsDisabled: false,
  placeholder: '',
};

export default Comment;
