/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { doesInitFacetHavePlatform, doesQueryMapContainFeatured } from './helpers';

const FILTER_TO_ALGOLIA_MAP = {
  featured_date: () => 'featured_date',
  popular: () => '', // most_impressions (base index).
  published: (initFacet) => doesInitFacetHavePlatform(initFacet) ? 'platforms_most_recent' : 'most_recent',
  respected: () => 'most_respected',
  trending: () => 'trending',
  updated: () => 'last_updated',
};

const FEATURED_PLATFORMS = {
  category_id: (value) => value === 'featured' ? 'featured_platforms' : '',
  tag_id: (value) => value === 'featured' ? 'featured_platforms' : '',
  topic_id: (value) => value === 'featured' ? 'featured_platforms' : '',
};

function getSortIndex(queryMap, initFacet) {
  if (!queryMap.hasOwnProperty('sort') || !FILTER_TO_ALGOLIA_MAP[queryMap['sort']]) return 'trending';

  return FILTER_TO_ALGOLIA_MAP[queryMap['sort']](initFacet);
}

function getFeaturedIndexForPlatform(queryMap, initFacet = []) {
  const keys = Object.keys(queryMap);
  const featuredIndex = keys.reduce((acc, key) => {
    if (acc.length > 0) return acc;

    return FEATURED_PLATFORMS.hasOwnProperty(key) ? FEATURED_PLATFORMS[key](queryMap[key]) : acc;
  }, '');

  const sortIndex = getSortIndex(queryMap, initFacet);
  const forceTrending = !queryMap.hasOwnProperty('sort');
  // On initial mount, if a sort hasn't yet been applied, default to featured_platforms("trending"). "trending" may be hard set from above,
  // and in that case we ignore it since we want the featured_platforms index in this case.
  return sortIndex === 'trending' || forceTrending ? featuredIndex : sortIndex;
}

export default function getSortFromQueryMap(queryMap, initFacet) {
  // NOTE: In the future if other entities need specific indexes for sorts, we can extend the logic below.  For now its just for platforms.
  return doesQueryMapContainFeatured(queryMap) && doesInitFacetHavePlatform(initFacet)
    ? getFeaturedIndexForPlatform(queryMap, initFacet)
    : getSortIndex(queryMap, initFacet);
}
