import React from 'react';
import PropTypes from 'prop-types';

import BasicFormInput from '../../../../form_components/inputs/basic_form_input';
import Checkbox from '../../../../form_components/checkboxes/custom';
import ImageUploader from '../../../../form_components/image_uploaders/form_image_uploader';

import { getInObj } from '../../../../../utility/accessors';

import inputStyles from '../../../../../styles/global_ui/inputs.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import styles from './prize_editor.css';
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
/* eslint-disable-next-line no-prototype-builtins */
const getErrorsForAttr = (errors, attr) => (errors && errors.hasOwnProperty(attr) ? errors[attr] : null);
const getIdOrUuid = (id, uuid) => (id || uuid);

const Prize = ({
  allowQuantity,
  cash_value,
  description,
  errors,
  id,
  image,
  label,
  link,
  name,
  quantity,
  requires_shipping,
  updateAttribute,
  uuid,
}) => {
  const dataId = getIdOrUuid(id, uuid);

  return (
    <div className={styles.prize} data-prize-id={dataId}>
      <div className={styles.prizeImgWrapper}>
        <label className={`${inputStyles.label}`}>Prize image</label>
        <ImageUploader
          allowRemoteURL={false}
          classList={{ previewWrapper: styles.prizeImg }}
          dimensionMins={{ width: 217 }}
          helperText="This image should have a ratio of 3:2 and be 651x434px (minimum 217x145px) for the best quality."
          imageData={{
            id: getInObj(['id'], image),
            url: getInObj(['url'], image),
          }}
          imageVersion="prize_thumb"
          propagateUpload={(data) => updateAttribute('image', data)}
          ratio={3 / 2}
        />
      </div>

      <div className={styles.prizeData}>
        {label && <h3 className={`${typography.h3} ${typography.bold} ${layout.marginBottom30}`}>{label}</h3>}

        <div data-prize-name={dataId}>
          <BasicFormInput
            charCount={name.length}
            errors={getErrorsForAttr(errors, 'name')}
            label="Prize name (is required)"
            maxVal={255}
            onChange={(e) => updateAttribute('name', e.target.value)}
            value={name}
          />
        </div>

        <BasicFormInput
          element="textarea"
          label="Prize description (optional)"
          onChange={(e) => updateAttribute('description', e.target.value)}
          value={description}
        />

        <BasicFormInput
          label="Cash value"
          onChange={(e) => updateAttribute('cash_value', parseInt(e.target.value))}
          type="number"
          value={cash_value}
        />

        <div data-prize-link={dataId}>
          <BasicFormInput
            charCount={link.length}
            errors={getErrorsForAttr(errors, 'link')}
            label="Link"
            maxVal={255}
            onChange={(e) => updateAttribute('link', e.target.value)}
            value={link}
          />
        </div>

        {allowQuantity
        && (
          <div data-prize-quantity={dataId}>
            <BasicFormInput
              errors={getErrorsForAttr(errors, 'quantity')}
              label="Quantity"
              onChange={(e) => updateAttribute('quantity', parseInt(e.target.value))}
              type="number"
              value={quantity}
            />
          </div>
        )}

        <div className={`${layout.marginBottom30} ${layout.flexColumn} ${layout.gutter10}`}>
          <Checkbox
            classList={{ label: inputStyles.label }}
            helperText="*All winners will have their address verified after being selected.)"
            isChecked={requires_shipping}
            label="Requires shipping? (Leave blank if this is a virtual prize)"
            onChange={(checked) => updateAttribute('requires_shipping', checked)}
            value={requires_shipping}

          />
        </div>
      </div>
    </div>
  );
};

Prize.propTypes = {
  allowQuantity: PropTypes.bool.isRequired,
  cash_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  errors: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
  id: PropTypes.number,
  image: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  }),
  label: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.number,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  requires_shipping: PropTypes.bool,
  updateAttribute: PropTypes.func.isRequired,
  uuid: PropTypes.string,
};

Prize.defaultProps = {
  errors: null,
  image: null,
  label: null,
};

export default Prize;
