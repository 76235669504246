import request from 'superagent';

import getInvalidAddressMsgs from './getInvalidAddressMsgs';
import { getBestRecommendation, getDiffingAddress, isAddressedVerified } from './apiParser';

const SMARTY_STREET_US_URL = `https://api.smartystreets.com/street-address?auth-id=${CLIENT_ENV.SMARTY_STREET_WEBSITE_KEY}`;
const SMARTY_STREET_INTL_URL = `https://international-street.api.smartystreets.com/verify?auth-id=${CLIENT_ENV.SMARTY_STREET_WEBSITE_KEY}`;

const US_MAP = {
  address_line1: 'street',
  address_line2: 'secondary',
  city: 'city',
  state: 'state',
  zip: 'zipcode',
};

const INTL_MAP = {
  address_line1: 'address1',
  address_line2: 'address2',
  city: 'locality',
  country: 'country',
  state: 'administrative_area',
  zip: 'postal_code',
};

/**
 * @param  {object} address - Object thats about to be posted.
 * @return {object} - Mapped values to smarty streets.
 */
const buildQuery = (address) => {
  const map = address.country_iso2 === 'US' ? US_MAP : INTL_MAP;

  return Object.keys(address).reduce((acc, key) => {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    if (map.hasOwnProperty(key) && address[key].length > 0) {
      // Use country_iso2 if we can, SmartyStreets doesn't accept some country names from the country-data package.
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
      /* eslint-disable-next-line no-prototype-builtins */
      if (key === 'country' && (address.hasOwnProperty('country_iso2') && address.country_iso2.length > 0)) {
        acc[map[key]] = address.country_iso2;
      } else {
        acc[map[key]] = address[key];
      }
    }

    return acc;
  }, {});
};

/**
 * Object that gets handed down to VerificationView.
 * The originIsVerified property is to quickly post the address, bypassing the verification step.
 *
 * @param {object} address - Object from form.
 * @param {object|nullable} record - SmartyStreets api response, singular record.
 * @param {bool} isUS
 */
const composeVerificationProps = (address, record, isUS = true) => {
  const originIsVerified = isAddressedVerified(record, isUS, true, address);

  const verified = (originIsVerified === false || record === null)
    ? null
    : {
        address: getDiffingAddress(address, record, isUS),
        invalidMsgs: getInvalidAddressMsgs(record, isUS),
        isDeliverable: isAddressedVerified(record, isUS, false, address),
      };

  return { address, originIsVerified, verified };
};

/**
 * When SmartyStreets returns data, we get the best possible match returned by the api. The analysis object will tell us
 * if the address we provided was fully verified or if the item returned was mutated in some way. When the active property is 'Y' and has
 * no footnotes property, the address we provided is 100% legit. If active is not 'Y' or footnotes exists, we need to parse the footnotes
 * to see whats up.
 *
 * @param  {object} address - Address from form.
 * @param  {object} apiRes - Full SmartyStreets response.
 * @param  {bool} isUS
 * @return {object} - Verification metadata, see composeVerificationResponse.
 */
const handleSmartyStreetResponse = (address, apiRes, isUS) => {
  if (!apiRes || !apiRes.length) return composeVerificationProps(address, null, isUS);

  const record = getBestRecommendation(apiRes, isUS);

  return composeVerificationProps(address, record, isUS);
};

export default function verifyAddress(address) {
  return new Promise((resolve, reject) => {
    const isUS = address.country_iso2 === 'US';
    const url = isUS ? SMARTY_STREET_US_URL : SMARTY_STREET_INTL_URL;

    return request(url)
      .query(buildQuery(address))
      .then((res) => handleSmartyStreetResponse(address, res.body, isUS))
      .then((verification) => resolve(verification))
      .catch((err) => reject(err));
  });
}
