import BaseAlgoliaService from '../base_service';
import queryBuilder from '../parts_service/query_builder'; // Borrow partsService's for now.
import { searchIndexWithFacets } from '../searchIndex';

export default class AlgoliaContestsService extends BaseAlgoliaService {
  constructor(history = {}) {
    super(history, 'contests', queryBuilder);
  }

  searchWithQueryString(queryString, queryMap, initFacet) {
    return new Promise((resolve, reject) => queryBuilder(queryMap, initFacet)
      .then((params) => searchIndexWithFacets({ index: this.algoliaIndex, query: queryString, ...params }))
      .then((algoliaRes) => resolve(algoliaRes))
      .catch((err) => reject(err)));
  }
}
