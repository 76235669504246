import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Icon from '../../../icon';

import layout from '../../../../styles/global_ui/layout.css';
import inputStyles from '../../../../styles/global_ui/inputs.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './custom_checkbox.css';

// TODO: Rework into Hook.
class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = { checked: props.isChecked };

    this.onClick = this.onClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isChecked !== this.state.checked) {
      this.setState({ checked: nextProps.isChecked });
    }
  }

  onClick() {
    if (this.props.disabled) return;

    const checked = !this.state.checked;
    this.props.onChange(checked);
    this.setState({ checked });
  }

  _getCheckboxPositionClass() {
    const position = this.props.checkboxPosition;

    return position ? styles[`checkbox${position.slice(0, 1).toUpperCase()}${position.slice(1)}`] : '';
  }

  render() {
    const { classList, error, disabled, helperText, label, name, value } = this.props;

    return (
      <div className={`${styles.checkboxWrapper} ${!label ? styles.maxWidth : ''} ${disabled ? styles.disabled : ''}`} onClick={disabled ? null : this.onClick}>
        <div className={`${styles.checkbox} ${this._getCheckboxPositionClass()} ${error ? styles.error : ''}`} tabIndex="0">
          <input checked={this.state.checked} className={styles.input} name={name} onChange={() => {}} type="checkbox" value={value} />
          {this.state.checked && <Icon className={typography.charcoal} name="checkmark" />}
        </div>

        {label
        && (
          <label
            className={`${layout.marginLeft15} ${layout.marginBottom0} ${typography.bodyM} ${classList.label} ${disabled ? styles.disabled : ''}`}
          >
            {label}
            {helperText && <p className={`${layout.margin0} ${inputStyles.help} ${classList.help}`}>{helperText}</p>}
          </label>
        )}
      </div>
    );
  }
}

Checkbox.propTypes = {
  checkboxPosition: PropTypes.string,
  classList: PropTypes.shape({ label: PropTypes.string, help: PropTypes.string }),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Checkbox.defaultProps = {
  checkboxPosition: null,
  classList: { label: '' },
  disabled: false,
  error: null,
  label: null,
  name: '',
  onChange: () => {},
  value: '',
};

export default Checkbox;
