import { EditorState, SelectionState } from 'draft-js';

import Carousel from '../components/Carousel';
import Embed from '../components/Embed';
import ImageLink from '../components/ImageLink';
import LegacyWidget from '../components/LegacyWidget';
import Tweet from '../components/Tweet';
import { removeBlock, updateBlock } from '../utils/draftUtils';

function _composeBlockProps() {
  return {
    actions: {
      toggleDialogStatus: this.props.toggleDialogStatus
    },
    focus: () => {
      this.focus();
    },
    update: (block) => {
      const currentState = this.props.draftState;
      const newContent = updateBlock(currentState, block);

      this.setDraftState(EditorState.forceSelection(EditorState.push(currentState, newContent), currentState.getSelection()));
    },
    updateContentState: (updatedState, changeType='') => {
      const currentState = this.props.draftState;
      this.setDraftState(EditorState.push(currentState, updatedState, changeType));
    },
    updateEntityData: (entityKey, data) => {
      // NOTE: We need to do all the state mutations in the Draft component. These customBlocks do not get fully rendered in FF and Safari on keyDown. The contentState
      // is off. Hence we do the state updates above where the draft editor prop is correct. This method does it properly, we need to move update and updateContentState
      // above to fix any similar behaviours.
      this.replaceEntityData(entityKey, data);
    },
    remove: (block) => {
      const currentState = this.props.draftState;
      const newContent = removeBlock(currentState, block);
      const nextKeyToFocus = currentState.getCurrentContent().getKeyAfter(block.getKey());
      const updatedSelection = SelectionState.createEmpty(nextKeyToFocus);

      if (this.props.readOnly) this.props.toggleReadOnly(false);
      this.setDraftState(EditorState.forceSelection(EditorState.push(currentState, newContent), updatedSelection));
    },
    editing: (bool, element) => {
      this.props.toggleReadOnly(bool);
    },
    message: (msg) => {
      this.props.setMessenger(msg);
    },
    moveSelectionTo: (block, direction) => {
      // this.props.toggleReadOnly(false);

      const currentState = this.props.draftState;
      const currentContent = currentState.getCurrentContent();
      // TODO: If index is the first or last, we might have a null variable here.  https://facebook.github.io/draft-js/docs/api-reference-content-state.html#getkeyafter
      const newSelection = direction === 'next'
        ? SelectionState.createEmpty(currentContent.getKeyAfter(block.getKey()))
        : SelectionState.createEmpty(currentContent.getKeyBefore(block.getKey()));

      this.setDraftState(EditorState.forceSelection(currentState, newSelection), false);

      // Refocus the Editor to prevent the cursor from jumping.
      // OnChange fires from the Draft instance and resets the selection immediately after the first keydown and making
      // the cursor jump back to 0.  This will make it stick.

      // ATTN
      // setTimeout(() => { this.focus(); }, 10);
    },
    setUnsavedChanges: () => {
      this.props.setUnsavedChanges();
    }
  };
}

function _composeCarousel(ctx) {
  return {
    component: Carousel,
    editable: false,
    props: {
      ..._composeBlockProps.call(ctx),
      processImage: (image, entityKey, blockKey) => {
        ctx.props.processImage(image, entityKey, blockKey);
      },
      uploadImages: (files, entityKey, blockKey) => {
        ctx.props.uploadImages(files, entityKey, blockKey);
      }
    }
  };
}

function _composeEmbed(ctx) {
  return {
    component: Embed,
    editable: false,
    props: _composeBlockProps.call(ctx)
  };
}

function _composeImageLink(ctx) {
  return {
    component: ImageLink,
    editable: false,
    props: {
      ..._composeBlockProps.call(ctx),
      processImage: (image, entityKey, blockKey) => {
        ctx.props.processImage(image, entityKey, blockKey);
      }
    }
  };
}

function _composeTweet(ctx) {
  return {
    component: Tweet,
    editable: false,
    props: _composeBlockProps.call(ctx)
  };
}

function _composeLegacyWidget(ctx) {
  return {
    component: LegacyWidget,
    editable: false,
    props: _composeBlockProps.call(ctx)
  };
}

export default function customBlockRenderer(ctx, contentBlock) {
  switch(contentBlock.getType()) {
    case 'CAROUSEL':
      return _composeCarousel(ctx);

    case 'EMBED':
      return _composeEmbed(ctx);

    case 'IMAGE_LINK':
      return _composeImageLink(ctx);

    case 'LEGACY_WIDGET':
      return _composeLegacyWidget(ctx);

    case 'TWEET':
      return _composeTweet(ctx);

    default:
      return null;
  }
}
