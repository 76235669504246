import smoothScroll from '../client/utils/smoothScroll';
import { windowInnerHeight, windowInnerWidth, windowPageYOffset } from '../services/window';

function addScrollToBody() {
  if (document && document.body && !document.body.classList.contains('no-scroll')) {
    document.body.classList.add('no-scroll');
  }
}

function removeScrollFromBody() {
  if (document && document.body && document.body.classList.contains('no-scroll')) {
    document.body.classList.remove('no-scroll');
  }
}

export function documentActiveElement() {
  return document.activeElement;
}

export function isDomElement(element) {
  return !!element && (element instanceof HTMLElement || element.nodeType === 1);
}

export function toggleNoScroll(addOrRemove) {
  if (typeof addOrRemove === 'undefined' || typeof addOrRemove !== 'string') {
    return new Error('toggleNoScroll method is undefined');
  }

  return addOrRemove === 'add' ? addScrollToBody() : removeScrollFromBody();
}

export function isElementInView(el, { bufferH = 0, bufferV = 0 } = {}) {
  const rect = el.getBoundingClientRect();

  const left = rect.left - bufferH;
  const right = rect.right + bufferH;
  const top = rect.top - bufferV;
  const bottom = rect.bottom + bufferV;

  // adapted from https://developer.mozilla.org/en-US/docs/Games/Techniques/2D_collision_detection
  return (
    left < windowInnerWidth()
    && right > 0
    && top < windowInnerHeight()
    && bottom > 0
  );
}

export function scrollElementToMiddle(el, duration = 500) {
  if (el) smoothScroll((el.getBoundingClientRect().top + windowPageYOffset()) - (windowInnerHeight() / 2), duration);
}
