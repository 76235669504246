import React from 'react';
import PropTypes from 'prop-types';

import DragAndDropList from '../../../client/reusable_components/DnDKit/DragAndDropList';
import SectionListLoader from './section_list/SectionListLoader';
import SectionCard from './section_list/SectionCard';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import sectionListStyles from './section_list/section_list.css';
import layout from '../../../styles/global_ui/layout.css';
import styles from './home.css';

const TableView = ({ removeSection, sections, toggleView, updateSection, updateSectionsOrder }) => (
  <section className={styles.root}>
    <header className={layout.marginBottom60}>
      <p>We recommend having 8-10 sections</p>
      <button
        className={`${buttonStyles.lg} ${buttonStyles.secondary}`}
        disabled={sections === null}
        onClick={() => toggleView('addSection')}
        type="button"
      >
        Add section
      </button>

      <button
        className={`${buttonStyles.lg} ${layout.marginLeft15}`}
        onClick={() => toggleView('preview')}
        type="button"
      >
        Preview
      </button>
    </header>

    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <div className={styles.ghostDragBox} />
        <div className={`${styles.headerCell} ${sectionListStyles.cell30}`}>Name</div>
        <div className={`${styles.headerCell} ${sectionListStyles.cell20}`}>Type</div>
        <div className={`${styles.headerCell} ${sectionListStyles.cell20}`}>Content tag</div>
        <div className={`${styles.headerCell} ${sectionListStyles.cell10}`}>Projects count</div>
        <div className={`${styles.headerCell} ${sectionListStyles.cell10}`}>Actions</div>
      </div>
      {(sections && sections.length > 0)
        ? (
          <DragAndDropList
            ItemComponent={SectionCard}
            classNames={{ item: sectionListStyles.row }}
            dragEndCallback={updateSectionsOrder}
            hasDragHandle={true}
            itemProps={{
              removeItem: removeSection,
              toggleView: toggleView,
              updateItem: updateSection,
            }}
            items={sections.map((s) => ({ ...s, id: s.value }))}
          />
          )
        : <SectionListLoader />}
    </div>
  </section>
);

TableView.propTypes = {
  removeSection: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.object),
  toggleView: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
  updateSectionsOrder: PropTypes.func.isRequired,
};

TableView.defaultProps = { sections: null };

export default TableView;
