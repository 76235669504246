import PropTypes from 'prop-types';
import React from 'react';

const FormGroup = ({ children, hint, label }) => (
  <div className="form-group">
    <div className="col-sm-4">
      <label className="text optional control-label" htmlFor="">{label}</label>
      {hint && <div className="help-block text-right">{hint}</div>}
    </div>
    <div className="col-sm-8">
      {children}
    </div>
  </div>
);

FormGroup.propTypes = {
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default FormGroup;
