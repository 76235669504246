import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import EditIcon from './EditIcon';
import Icon from '../../../client/icon';

import { getInObj } from '../../../utility/accessors';
import { capitalize } from '../../../utility/formatters';

import styles from './members.css';
import animation from '../../../styles/animation.css';
import ellipsesLoader from '../../../styles/loaders/ellipses.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const ACTIONS_MAX_HEIGHT = 44;

/**
 * Helpers
 */
const _getActionsView = (props) => (
  _getWorkerIds(props.workers).includes(props.member.id) ? ActionsLoader() : ActionsView(props)
);

const _getEditMenu = (member, roleMenu) => roleMenu.filter((opt) => opt.value !== member.role);

const _getMemberNameOrEmailView = (member) => getInObj(['user', 'name'], member) ? MemberNameView(member) : MemberEmailView(member);

const _getMembersListView = (props) => {
  if (props.isFetching) return LoaderView();
  if (!props.isFetching && props.members.length === 0) return EmptyListView(props.searchHits, props.emptyListMsg);

  return MembersListView(props);
};

const _getWorkerIds = (workers) => {
  if (!workers.length) return [];

  return workers.reduce((acc, worker) => {
    const id = getInObj(['member', 'id'], worker);

    return id === null ? acc : acc.concat(id);
  }, []);
};

const _shouldDisableAction = (currentUser, member) => {
  if (currentUser.isAdmin) return false;

  return (
    member.invitation_pending
    || currentUser.id === getInObj(['user', 'id'], member)
  );
};

/**
 * Views
 */
const ActionsLoader = () => (
  // NOTE: minHeight is important to match the height of the action Icons.
  <div className={ellipsesLoader.ellipses} style={{ minHeight: ACTIONS_MAX_HEIGHT }}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

const ActionsView = ({ currentUser, member, onDeleteMember, onUpdateMemberRole, roleMenu }) => (
  <Fragment>
    <span className={styles.memberCardEditIconWrapper} title="Change role">
      <EditIcon
        disabled={_shouldDisableAction(currentUser, member)}
        menuOpts={_getEditMenu(member, roleMenu)}
        onOptClick={(opt) => onUpdateMemberRole(member, opt.value)}
      />
    </span>
    <span className={styles.memberCardTrashIconWrapper} title="Delete member">
      <Icon
        className={`${typography.iconClickableRed} ${typography.icon20}`}
        disabled={_shouldDisableAction(currentUser, member)}
        name="delete"
        onClick={() => onDeleteMember(member)}
      />
    </span>
  </Fragment>
);

const EmptyListView = (searchHits, emptyListMsg) => (
  <div className={`${layout.flexJustifySpaceBetween} ${styles.memberCard}`}>
    <h5 className={`${typography.h5}`}>
      {searchHits !== null ? 'No members found for your search' : emptyListMsg}
    </h5>
  </div>
);

const LoaderView = () => (
  <div className={`${layout.flexJustifySpaceBetween} ${styles.memberCard}`}>
    <p className={`${layout.flex1} ${layout.alignSelfCenter} ${typography.textCenter}`}>
      <span className={`${typography.dummyM} ${animation.loader}`} style={{ display: 'block', maxWidth: 150 }} />
    </p>
    <p className={`${layout.flex} ${layout.flex1} ${layout.alignSelfCenter}`}>
      <span className={`${layout.alignSelfCenter} ${typography.dummyM} ${animation.loader}`} style={{ display: 'block', maxWidth: 150 }} />
    </p>
    <p className={`${layout.flexJustifyEnd} ${layout.flex1} ${layout.alignSelfCenter}`}>
      <span className={`${layout.alignSelfCenter} ${typography.dummyM} ${animation.loader}`} style={{ display: 'block', maxWidth: 100 }} />
    </p>
  </div>
);

const MemberEmailView = (member) => (
  <div className={`${layout.flex1} ${layout.flexColumn}`}>
    <p className={`${layout.alignSelfStart} ${layout.flexCenterItems} ${typography.bodyM}`}>
      {member.user.email}
    </p>
    {/* Mobile view below */}
    <p className={`${layout.hiddenSmallUp} ${layout.flexColumn} ${layout.flex1} ${layout.marginTop10} ${typography.bodyS}`}>
      <span className={`${layout.alignSelfStart}`}>{capitalize(member.role)}</span>
      {member.invitation_pending && (<span className={`${layout.alignSelfStart} ${typography.bodyXS} ${typography.bold}`}> [Invitation pending]</span>)}
    </p>
  </div>
);

const MemberNameView = (member) => (
  <div className={`${layout.flex1} ${layout.flexColumn}`}>
    <a
      className={`${layout.flex1} ${layout.alignSelfStart} ${layout.flexCenterItems} ${typography.linkBlue} ${typography.bodyM}`}
      href={member.user.url}
      rel="noreferrer"
      target="_blank"
    >
      {`${member.user.name} (${member.user.user_name})`}
    </a>

    {/* Mobile view below */}
    <p className={`${layout.hiddenSmallUp} ${layout.flexColumn} ${layout.flex1} ${layout.marginTop10} ${typography.bodyS}`}>
      <span className={`${layout.alignSelfStart}`}>{capitalize(member.role)}</span>
      {member.invitation_pending && (<span className={`${layout.alignSelfStart} ${typography.bodyXS} ${typography.bold}`}> [Invitation pending]</span>)}
    </p>
  </div>
);

const MemberCard = ({ member, ...props }) => (
  <div key={member.id} className={`${layout.flexJustifySpaceBetween} ${styles.memberCard}`}>
    {_getMemberNameOrEmailView(member)}

    {/* Hidden on mobile below */}
    <p className={`${layout.hiddenSmallDown} ${layout.flexColumn} ${layout.flex1} ${layout.alignSelfCenter} ${typography.textCenter} ${typography.bodyM}`}>
      <span>{capitalize(member.role)}</span>
      {member.invitation_pending && (<span className={`${typography.bodyXS} ${typography.bold}`}> [Invitation pending]</span>)}
    </p>

    <div className={`${layout.flexJustifyEnd} ${layout.alignSelfCenter}`} style={{ maxHeight: ACTIONS_MAX_HEIGHT }}>
      {_getActionsView({ member, ...props })}
    </div>
  </div>
);

const MembersListView = ({ currentUser, members, onDeleteMember, onUpdateMemberRole, roleMenu, workers }) => (
  members.map((member) => (
    <MemberCard
      key={member.id}
      currentUser={currentUser}
      member={member}
      onDeleteMember={onDeleteMember}
      onUpdateMemberRole={onUpdateMemberRole}
      roleMenu={roleMenu}
      workers={workers}
    />
  ))
);

const MembersTableView = (props) => (
  <div className={layout.flexColumn}>
    <div className={`${layout.flexJustifySpaceBetween} ${layout.marginBottom10}`}>
      <p className={`${layout.flex1} ${typography.h3}`}>User</p>
      <p className={`${layout.hiddenSmallDown} ${layout.flex1} ${typography.h3} ${typography.textCenter}`}>Role</p>
      <p className={`${layout.flex1} ${typography.h3} ${typography.textRight}`}>Action</p>
    </div>

    <div>
      {_getMembersListView(props)}
    </div>
  </div>
);

MembersTableView.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }).isRequired,
  emptyListMsg: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    invitation_pending: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      url: PropTypes.string,
      user_name: PropTypes.string, // Might not exist while member is invited
    }).isRequired,
  })),
  onDeleteMember: PropTypes.func.isRequired,
  onUpdateMemberRole: PropTypes.func.isRequired,
  roleMenu: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  searchHits: PropTypes.number,
  workers: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.string.isRequired,
    member: PropTypes.object.isRequired, // Member shape
  })).isRequired,
};

MembersTableView.defaultProps = {
  emptyListMsg: 'Search for members',
  members: [],
  searchHits: null,
};

export default MembersTableView;
