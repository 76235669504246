import React from 'react';
import PropTypes from 'prop-types';

import { unescape as validatorUnescape } from 'validator';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './post_embed.css';

const MAX_LENGTH = 200;

const formatDescription = (description) => {
  const unescaped = description ? validatorUnescape(description) : '';

  return (description.length > MAX_LENGTH) ? truncateDescription(unescaped) : unescaped;
};

// eslint-disable-next-line no-useless-escape
const truncateDescription = (description) => description.substring(0, MAX_LENGTH).replace(/[\,\.\;\-\(\+\=\{\s]+$/, '') + '...';

const EmbedText = ({ embed }) => (
  <div className={`${layout.padding15} ${layout.flexColumn} ${styles.bodyWrapper}`}>
    <div className={`${utilStyles.overflowHidden} ${layout.flexColumn} ${styles.body}`}>
      <div className={`${typography.bodyM} ${typography.link} ${typography.bold}`}>
        {embed.title}
      </div>
      <p className={`${styles.description} ${typography.bodyS} ${typography.pebble} ${typography.overflowBreakWord}`}>
        {formatDescription(embed.description)}
      </p>
      <div className={`${styles.siteName} ${typography.bodyS} ${typography.pebble}`}>
        {embed.website_name}
      </div>
    </div>
  </div>
);

EmbedText.propTypes = { embed: PropTypes.object.isRequired };

export default EmbedText;
