/* eslint-disable react/no-direct-mutation-state */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BasicFormInput from '../../form_components/inputs/basic_form_input';
import Button from '../../buttons/base';
import FreezeWidthButton from '../../buttons/freeze_width_button';
import RingSpinner from '../../spinners/ring';

import { getFormErrorView } from '../../form_components/templates';
import { graphMutate } from '../../../requests/graphql';

import keenService from '../../../services/keen/main';
import { getCreateBookmarkArgs, getCreateBookmarkListArgs } from '../../../services/keen/events/eventTypeTemplates';

import errorHandler from '../../../services/error_handler';
import {
  getErrorForField,
  getFieldValuesAsObject,
  initFields,
  setStateOrError,
  validateFields,
} from '../../../utility/forms';
import { objHasPropertyWithValue } from '../../../utility/predicates';
import { isRequired, maxLength } from '../../../services/validation/validators';

import { GENERIC_ERROR } from '../../../constants/alerts';
import { ENTER } from '../../../constants/keyCodes';

import inputStyles from '../../../styles/global_ui/inputs.css';
import layout from '../../../styles/global_ui/layout.css';

const modeConfig = {
  create: { mutation: 'create_bookmark_list' },
  update: { mutation: 'update_bookmark_list' },
};

const fieldTemplate = {
  id: { validate: () => null, value: null, notRequired: true }, // this field will be set by props if applicable
  name: {
    validate: (val) => maxLength(60, val, 'Too long'),
    value: '',
    customIsRequired: (val) => isRequired(val, 'Required field'),
  },
  private: { validate: () => null, value: true }, // this field won't change in the current form configuration
  project_id: { validate: () => null, value: null, notRequired: true }, // this field will be set by props if applicable
};

class BookmarkListForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      fields: initFields(fieldTemplate, props.initValues),
      formError: '',
      isBusy: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // bound form helpers
    this.getErrorForField = getErrorForField.bind(this);
    this.getFieldValuesAsObject = getFieldValuesAsObject.bind(this);
    this.setStateOrError = setStateOrError.bind(this);
    this.validateFields = validateFields.bind(this);
  }

  /**
   * Methods
   */
  handleChange(e) {
    const { name, value } = e.target;
    this.setStateOrError(null, name, value);
    this.setState({ formError: '' });
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER) this.handleSubmit(e);
  }

  handleSubmit() {
    const validated = this.validateFields({ doScroll: false });

    if (validated) {
      return this._submitForm();
    }
  }

  /**
   * Helpers
   */
  _handleCreateListAnalytics(list) {
    keenService.reportEventWithObj(getCreateBookmarkListArgs({ id: list.id, ...this.props.analytics }));

    // When a list sends the server a project_id arg, we create the bookmark in the same request. Here we check for
    // that and.fire a create bookmark event. For delete ops, we ignore the mass deletion for bookmarks.
    if (objHasPropertyWithValue(list, 'bookmark_id')) {
      keenService.reportEventWithObj(getCreateBookmarkArgs({ id: list.bookmark_id, ...this.props.analytics }));
    }
  }

  _resetForm(clearValues) {
    this.state = {
      errors: {},
      fields: clearValues ? initFields(fieldTemplate, {}) : this.state.fields,
      formError: '',
      isBusy: false,
    };
  }

  _submitForm() {
    const values = this.getFieldValuesAsObject();
    const { mutation } = modeConfig[this.props.mode];

    this.setState({ isBusy: true });

    return graphMutate({ t: mutation }, values)
      .then(({ list }) => {
        this._resetForm(this.props.clearOnSubmit);
        this.props.onSubmit({ ...values, ...list });
        this._handleCreateListAnalytics(list);
      })
      .catch((err) => {
        this.setState({ formError: GENERIC_ERROR, isBusy: false });
        errorHandler('BookmarksListForm handleSubmit', err);
      });
  }

  /**
   * Views
   */
  _getCreateButton() {
    return (
      <Button
        key="bfs" // so react doesn't confuse this with the button in the widget
        className={layout.fullWidth}
        colorStyle="secondary"
        disabled={this.state.isBusy}
        onClick={this.handleSubmit}
        size={this.props.componentSize}
        type="submit"
      >
        {this.state.isBusy ? <RingSpinner size={16} /> : 'Create list'}
      </Button>
    );
  }

  _getUpdateButtons() {
    return (
      <div>
        <FreezeWidthButton
          disabled={this.state.isBusy}
          onClick={this.handleSubmit}
          size={this.props.componentSize}
          type="submit"
        >
          {this.state.isBusy ? <RingSpinner size={16} /> : 'Save'}
        </FreezeWidthButton>
        <Button
          className={layout.marginLeft10}
          colorStyle="danger"
          disabled={this.state.isBusy}
          onClick={this.props.onDeleteClick}
          size={this.props.componentSize}
        >
          Delete
        </Button>
        <Button
          colorStyle="cancel"
          disabled={this.state.isBusy}
          onClick={this.props.onCancelClick}
          size={this.props.componentSize}
        >
          Cancel
        </Button>
      </div>
    );
  }

  render() {
    const { formError, fields } = this.state;

    return (
      <div>
        <div onKeyDown={this.handleKeyDown}>
          <BasicFormInput
            autoFocus={true}
            charCount={fields.name.value.length}
            classList={{ input: inputStyles[this.props.componentSize] }}
            errors={this.getErrorForField('name')}
            label="Name"
            maxVal={60}
            name="name"
            onChange={this.handleChange}
            value={fields.name.value}
          />
          {/* TODO: radio buttons for privacy - v2? */}
        </div>
        {getFormErrorView(formError)}
        {this.props.mode === 'update' ? this._getUpdateButtons() : this._getCreateButton()}
      </div>
    );
  }
}

BookmarkListForm.propTypes = {
  analytics: PropTypes.shape({ widget_src: PropTypes.string.isRequired }).isRequired,
  clearOnSubmit: PropTypes.bool,
  componentSize: PropTypes.string,
  initValues: PropTypes.shape({
    name: PropTypes.string,
    private: PropTypes.bool,
  }),
  mode: PropTypes.oneOf(['create', 'update']),
  onCancelClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

BookmarkListForm.defaultProps = {
  clearOnSubmit: true,
  componentSize: 'md',
  initValues: {},
  mode: 'create',
  onCancelClick: null,
  onDeleteClick: null,
  onSubmit: () => {},
};

export default BookmarkListForm;
