import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BookmarkListForm from '../bookmark_list_form';
import BookmarkListToggles from '../bookmark_list_toggles';
import Button from '../../buttons/base';
import DismissButton from '../../buttons/dismiss_button';

import bookmarkStore from '../../../services/bookmark_store';
import errorHandler from '../../../services/error_handler';
import { addOrRemoveBookmark, buildBookmarkMessage, getDashboardBookmarksURL } from '../helpers';
import { fireClickedLinkAnalyticsWithRedirect } from '../../../services/keen/mainServiceOperators';
import { summonGlobalMessenger } from '../../../utility/dispatchers';

import { GENERIC_ERROR } from '../../../constants/alerts';

import bookmarkStyles from '../bookmarks.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

/**
 * this is a hack to prevent a false positive in clickOutsideListener, when a clicked
 * element is unmounted before the event propagates, and so is not "inside" the dropdown
 * when the event is received by the listener.
 * TODO: find a better way
 */
const _stopImmediatePropagation = (e) => e.nativeEvent.stopImmediatePropagation();

class BookmarksWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      lists: [],
      showForm: false,
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleNewList = this.handleNewList.bind(this);
    this.summonForm = this.summonForm.bind(this);
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._init();
  }

  /**
   * Initializers
   */
  _init() {
    return bookmarkStore.initialize()
      .then(() => this.setState({ initialized: true, lists: bookmarkStore.getLists() }))
      .catch((err) => errorHandler(err));
  }

  /**
   * Methods
   */
  handleCheckboxChange({ addOrRemoveBool, list }) {
    return addOrRemoveBookmark({ addOrRemoveBool, list, projectId: this.props.projectId }, this.props.analytics)
      .then((lists) => {
        this.setState({ lists });
        summonGlobalMessenger({ msg: buildBookmarkMessage(addOrRemoveBool, list.name) });
      })
      .catch((err) => {
        this._handleError(err);

        return Promise.reject(err); // AsyncCheckbox handles this rejection
      });
  }

  handleNewList(list) {
    summonGlobalMessenger({ msg: buildBookmarkMessage(true, list.name, true) });
    this.setState({
      lists: bookmarkStore.addList(list, this.props.projectId),
      showForm: false,
    });
  }

  summonForm() {
    this.setState({ showForm: true });
  }

  /**
   * Helpers
   */
  _handleError(err) {
    errorHandler(err);
    summonGlobalMessenger({ msg: GENERIC_ERROR, type: 'error' });
  }

  _handleMyBookmarksLinkClick(e) {
    const url = getDashboardBookmarksURL();
    fireClickedLinkAnalyticsWithRedirect(
      { href: url, entity_id: this.props.projectId, location: 'My bookmarks', value: this.props.analytics.widget_src },
      url,
      e,
    );
  }

  render() {
    // only render once mounted, so autofocus works when this component is in a portal
    // https://reactjs.org/docs/portals.html#event-bubbling-through-portals
    if (!this.state.initialized) return null;

    return (
      <div
        className={`${bookmarkStyles.dropdown} ${utilStyles.bgWhite} ${utilStyles.boxShadow2}`}
        onClick={_stopImmediatePropagation}
      >
        <div className={bookmarkStyles.widgetHeader}>
          <a
            className={`${typography.linkPebble} ${typography.h5}`}
            href={getDashboardBookmarksURL()}
            onClick={(e) => this._handleMyBookmarksLinkClick(e)}
          >
            My bookmarks
          </a>
          <DismissButton autoFocus={true} onClick={this.props.dismiss} style={{ right: '5px', top: '5px' }} />
        </div>
        <div className={`${layout.padding15} ${utilStyles.borderTop}`}>
          <BookmarkListToggles
            currentListId={this.props.currentListId}
            handleCheckboxChange={this.handleCheckboxChange}
            lists={this.state.lists}
            projectId={this.props.projectId}
          />
          {!this.state.showForm
          && (
            <Button
              className={`${layout.fullWidth} ${layout.marginTop15}`}
              colorStyle="secondary"
              disabled={this.state.isBusy}
              onClick={this.summonForm}
              size="sm"
            >
              Create new list
            </Button>
          )}
        </div>
        {this.state.showForm
        && (
          <div className={`${layout.padding15} ${utilStyles.borderTop}`}>
            <h5 className={`${typography.h5} ${layout.marginBottom10}`}>Create new list</h5>
            <BookmarkListForm
              analytics={this.props.analytics}
              componentSize="sm"
              initValues={{ project_id: this.props.projectId }}
              onSubmit={this.handleNewList}
            />
          </div>
        )}
      </div>
    );
  }
}

BookmarksWidget.propTypes = {
  analytics: PropTypes.shape({ widget_src: PropTypes.string.isRequired }).isRequired,
  currentListId: PropTypes.number,
  dismiss: PropTypes.func,
  onChange: PropTypes.func,
  projectId: PropTypes.number.isRequired,
};

BookmarksWidget.defaultProps = {
  currentListId: null,
  dismiss: () => {},
  onChange: () => {},
};

export default BookmarksWidget;
