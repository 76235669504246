import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';
import hljs from '../../services/markdown/rules/highlightjs';

const markdown = new MarkdownIt({
  breaks: true,
  linkify: true,
  xhtmlOut: true,
})
  .use(emoji)
  .use(hljs)
  .disable(['image', 'hr', 'heading', 'lheading']);

// Remember old renderer, if overriden, or proxy to default renderer
const defaultRender = markdown.renderer.rules.link_open || function(tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options);
};

markdown.renderer.rules.link_open = function(tokens, idx, options, env, self) {
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex('target');

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank'; // replace value of existing attr
  }

  const bIndex = tokens[idx].attrIndex('rel');
  if (bIndex < 0) {
    tokens[idx].attrPush(['rel', 'noopener noreferrer nofollow']); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = 'noopener noreferrer nofollow'; // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

export default markdown;
