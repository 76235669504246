import KeenService from './index';
import errorHandler from '../error_handler';

import SCENE_WHITELIST from './sceneWhitelist';

/**
 * Main keen service to use for firing analytics events. Use this singleton to expose helper methods like fire and forgets.
 * This is to reduce the amount of newly instantiated keen services riddling the code base and constrain it here.
 */
class MainKeenService extends KeenService {
  constructor() {
    super();
    this._lastPageViewURL = this._initLastPageViewURL();
  }

  _initLastPageViewURL() {
    if (SCENE_WHITELIST.indexOf(this._getPageType()) > -1) {
      // The first pageview that this service reports will be the initial pageView.
      // It should let the PageView constructor use document.referrer as its referrer value
      return null;
    }

    // Initial pageview has already been fired in GlobalService, using document.referrer as its referrer property.
    // We need to set _lastPageViewURL to be used as the referrer for the next pageView
    return this._getCurrentURL();
  }

  _getCurrentURL() {
    if (typeof window === 'undefined') return null;

    return window && window.location ? window.location.toString() : null;
  }

  /**
   * Fire and forget. When we don't care to resolve the recordEvent promise.
   * Useful for 'Clicked links' events when we don't redirect.
   */
  reportEvent(event, customProps) {
    return this.recordEvent(event, customProps)
      .then(() => {})
      .catch((err) => errorHandler('reportEvent: ', err));
  }

  /**
   * Use these methods with the event objects returned by the eventTypeTemplates
   */
  reportEventWithObj({ customProps, event }) {
    this.reportEvent(event, customProps);
  }

  reportEventWithDelayedRedirectWithObj({ customProps, event }, url, e) {
    this.recordEventWithDelayedRedirect(event, customProps, url, e);
  }

  /**
   * For (virtual) pageView events that happen after GlobalService.reportPageView runs.
   * Usually triggered by navigation in SPAs
   */
  reportVirtualPageView(props = {}) {
    const referrer = props.referrer || this._lastPageViewURL;

    this._lastPageViewURL = this._getCurrentURL();

    return this.recordPageView({ ...props, referrer })
      .then(() => {})
      .catch((err) => errorHandler('MainKeenService reportPageView: ', err));
  }
}

const mainService = new MainKeenService();
export default mainService;
