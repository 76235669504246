import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import EventTypeOrLocation from '../../../../../client/cards/event_card/EventTypeOrLocation';

import { getDateRangeAsText } from '../../../../../client/cards/event_card/eventCardHelpers';

import { TextPlaceholder } from './templates';

import colors from '../../../../../styles/global_ui/colors.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';

const getLink = ({ color, item, size }) => (
  <span className={`${typography.link} ${typography.bold} ${size === 'M' ? typography.bodyM : typography.bodyL} ${typography[color]}`}>
    {item.title}
  </span>
);

const getLinkPlaceholder = (size) => {
  const height = size === 'M' ? 16 : 18;

  return (
    <Fragment>
      <TextPlaceholder backgroundColor={colors['v-asphalt']} height={height} width="100%" />
      <TextPlaceholder backgroundColor={colors['v-asphalt']} height={height} marginTop={size === 'M' ? 6 : 8} width="100%" />
    </Fragment>
  );
};

const getInfoLinePlaceholder = (size) => (
  <TextPlaceholder backgroundColor={colors['v-asphalt']} height={size === 'M' ? 14 : 16} width="50%" />
);

const EventCardTextPreview = ({ className, color, item, size }) => (
  <div className={`${className} ${layout.fullWidth} ${typography.breakWord}`}>
    {item.title ? getLink({ color, item, size }) : getLinkPlaceholder(size)}
    <div className={`${size === 'M' ? typography.bodyS : typography.bodyM} ${typography[color]}`}>
      <div className={layout.marginTop10}>
        {(item.start_date || item.end_date) ? getDateRangeAsText(item) : getInfoLinePlaceholder(size)}
      </div>
      <div className={layout.marginTop10}>
        {item.event_type ? <EventTypeOrLocation item={item} /> : getInfoLinePlaceholder(size)}
      </div>
    </div>
  </div>
);

EventCardTextPreview.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  item: PropTypes.shape({
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.shape({ url: PropTypes.string }),
    imageRight: PropTypes.bool,
    link: PropTypes.string,
    start_date: PropTypes.string,
    state: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  size: PropTypes.oneOf(['M', 'L']),
};

EventCardTextPreview.defaultProps = {
  className: '',
  color: 'charcoal',
  size: 'M',
};

export default EventCardTextPreview;
