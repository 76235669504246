import { summonGlobalMessenger } from '../../utility/dispatchers';

export const copyStringToClipboard = (text, msg = 'Link copied!') => {
  const input = document.createElement('input');
  input.value = text;
  document.body.appendChild(input);
  // Copy
  input.select();
  document.execCommand('copy');
  // Clean up
  document.body.removeChild(input);
  summonGlobalMessenger({ msg });
};
