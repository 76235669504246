export const BlockElements = {
  'BLOCKQUOTE': true,
  'H3': true,
  'DIV': true,
  'P': true,
  'PRE': true,
  'UL': true
};

export const ElementWhiteList = {
  'blockquote': 'block',
  'br': 'block',
  'div': 'block',
  'h3': 'block',
  'p': 'block',
  'pre': 'block',
  'ul': 'block',
  'li': 'block',
  'img': 'block',
  'a': 'inline',
  'code': 'inline',
  'em': 'inline',
  'span': 'inline',
  'strong': 'inline'
};

export const AtomicBlocks = ['CAROUSEL', 'EMBED', 'IMAGE_LINK', 'LEGACY_WIDGET', 'TWEET'];