import { genKey } from 'draft-js';

export function getImageDimensions(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => resolve({width: image.width, height: image.height});
    image.onerror = err => reject('getImageDimensions Error: ', err);
    image.src = src;
  });
}

function resizeImage(src, data) {
  return new Promise((resolve, reject) => {
    let image = new Image();
    // Setting the crossOrigin to Anon will upset Safari CORS gods.
    image.onload = () => {
      let canvas = document.createElement('canvas'),
          maxWidth = 680,
          maxHeight = 510,
          width = image.width,
          height = image.height,
          scale = Math.min((maxWidth/image.width),(maxHeight/image.height)),
          dataUrl;

      // if(image.width > maxWidth || image.height > maxHeight) {
      //   width = (image.width * scale),
      //   height = (image.height * scale)
      // }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      dataUrl = canvas.toDataURL();

      resolve({...data, width: width, height: height, scale: scale, url: dataUrl});
    };

    image.onerror = () => reject('Image resize error');
    image.src = src;
  });
}

function handleFileReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = upload => {
      resolve(resizeImage(upload.target.result, {
        figcaption: '',
        uuid: genKey(),
        name: file.name,
        show: false,
        rawFile: file,
        url: upload.target.result
      }));
      // TODO: Resolve like below without the resizing when imgix supports gifs!!!
      // resolve({
      //   figcaption: '',
      //   uuid: genKey(),
      //   name: file.name,
      //   show: false,
      //   rawFile: file,
      //   url: upload.target.result
      // });
    };

    reader.onerror = err => reject(err);
    reader.readAsDataURL(file);
  });
}

export default function processImageFiles(files) {
  const promised = files.map(file => handleFileReader(file));
  return Promise.all(promised);
}
