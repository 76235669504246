import { requestAnimationFrame, cancelAnimationFrame } from './requestAnimationFrame';

function requestTimeout(fn, delay = 0) {
  const start = Date.now();
  let request = null;

  function loop() {
    const current = Date.now();
    const delta = current - start;

    if (delta >= delay) {
      fn.call();
    } else {
      request = requestAnimationFrame(loop);
    }
  }

  requestAnimationFrame(loop);

  // return a "clearTimeout" function
  return () => cancelAnimationFrame(request);
}

export default requestTimeout;
