// Server rendering protection.
const getWindow = () => {
  if (typeof window === 'undefined') return {};

  return window;
};

const fallback = (fn) => window.setTimeout(fn, 1000 / 60);

function getRequestAnimationFrame(window = {}) {
  return window.requestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.oRequestAnimationFrame
    || window.msRequestAnimationFrame
    || fallback;
}

function getCancelAnimationFrame(window = {}) {
  return window.cancelAnimationFrame
    || window.webkitCancelAnimationFrame
    || window.webkitCancelRequestAnimationFrame
    || window.mozCancelRequestAnimationFrame
    || window.oCancelRequestAnimationFrame
    || window.msCancelRequestAnimationFrame
    || window.clearTimeout;
}

export const requestAnimationFrame = getRequestAnimationFrame(getWindow());
export const cancelAnimationFrame = getCancelAnimationFrame(getWindow());
