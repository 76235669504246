import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Notes:
 * This requires the scrollWidth polyfill for both Edge and IE11 to work properly. We have that polyfill included in the Rails bundle.
 * The flex properties are strictly for IE11 and seems to be okay in other browsers.
 *
 * Flex-wrap is broken in Firefox and Edge. I can get Edge to work if I set the flex-basis to a pixel based dimension. FF is just broken.
 * Having to do browser sniffing and alternative logic for each seems nasty, so Im fore-going it.
 *
 * The reason Im not using react-autosize-input is because that package has bugs of its own I'd rather not inherit them. If we absolutely need to,
 * we can replicate how that works and update the inputs width in pixels for every render (:barf).
 */
class AutosizeInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      flex: '1',
      width: '100%',
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.setAndPropagateRef = this.setAndPropagateRef.bind(this);

    // Ref
    this._input;
  }

  /**
   * Methods
   */
  onChange(e) {
    this.props.onChange(e);
    this._toggleWidth(e);
  }

  onKeyDown(e) {
    // Collapse the input to a single row on backspace. If props.value is an empty string and the next action is
    // a delete, the onChange callback does not get called since theres no change in the inputs value. This will take over
    // in that edge case.
    if (e.keyCode === 8 && e.target.value === '' && this.props.value === '') {
      this._toggleWidth(e);
    }
    this.props.onKeyDown(e);
  }

  setAndPropagateRef(c) {
    this._input = c;
    this.props.getInputRef(c);
  }

  /**
   * Helpers
   */

  /**
   * Auto behaves best as a fallback.
   * Flex-wrap will break early, but its better than the input overflowing with minimal width.
   */
  _fallbackToWidthAuto() {
    if (this.state.width !== 'auto') this.setState({ width: 'auto' });
  }

  _toggleWidth(e) {
    if (typeof this._input === 'undefined') return this._fallbackToWidthAuto();

    const dims = this._input.getBoundingClientRect();
    const inputWidth = Math.round(parseFloat(dims.width));
    const scrollWidth = this._input.scrollWidth;
    const value = e.target.value;

    if (scrollWidth > inputWidth && this.state.width === '100%') {
      // When the input is overflowing, make it wrap to a new line.
      this.setState({ flex: '1 0 100%', width: 'auto' });
    } else if (scrollWidth < inputWidth && this.state.width === 'auto') {
      // When the input is not overflowing, try to let flex collapse the input to the same line as selected options.
      this.setState({ flex: '1', width: '100%' });
    } else {
      // Reset on backspace.
      if (value === '' && scrollWidth === inputWidth && this.state.width === 'auto') {
        this.setState({ flex: '1', width: '100%' });
      }
    }
  }

  render() {
    return (
      <input
        ref={this.setAndPropagateRef}
        className={this.props.className}
        disabled={this.props.disabled}
        id={this.props.id}
        name={this.props.name}
        onBlur={this.props.onBlur}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        placeholder={this.props.placeholder}
        style={{ flex: this.state.flex, width: this.state.width }}
        value={this.props.value}
      />
    );
  }
}

AutosizeInput.propTypes = {
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any.isRequired,
};

AutosizeInput.defaultProps = {
  id: null,
  name: null,
  placeholder: null,
};

export default AutosizeInput;
