import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { getDummyCells } from './DummyList';
import getGridClassNames from '../../projects/projects_list/getGridClassNames';

import overlayStyles from '../../../styles/common-overlay.css';

const renderList = ({
  DummyComponent,
  ItemComponent,
  cellClassOverride,
  className,
  injectableConfig,
  gutterSize,
  horizontal,
  isLoading,
  itemKey,
  itemProps,
  loadingIndicator,
  maxCols,
  records,
  ref,
}) => {
  const classNames = getGridClassNames(horizontal, maxCols, gutterSize, cellClassOverride);
  const items = (injectableConfig !== null && records.length > 0) ? _injectComponent(records, injectableConfig) : records;

  return (
    <div ref={ref} className={`${classNames.grid} ${className}`}>
      {items.map((item, i) => {
        const props = { ...itemProps, [itemKey]: item, itemIndex: i };

        return (
          <div key={injectableConfig ? i : (item.hid || item.id || i)} className={classNames.cell}>
            {_shouldDisplayComponent(i, injectableConfig) ? <injectableConfig.component {...item} /> : <ItemComponent {...props} />}
          </div>
        );
      })}
      {isLoading && loadingIndicator === 'dummy' && getDummyCells(DummyComponent, maxCols, classNames.cell)}
    </div>
  );
};

const _injectComponent = (records, { index, props }) => (
  records.slice(0, index).concat([props]).concat(records.slice(index))
);

const _shouldDisplayComponent = (index, injectableConfig) => (
  (injectableConfig !== null) && (index === injectableConfig.index)
);

const renderPlaceholder = (placeholder, props) => (
  typeof placeholder === 'function' ? placeholder(props) : placeholder
);

const defaultValidationFn = ({ records }) => records.length > 0;

const GridList = forwardRef(({ isLoading, placeholder, validationFn, ...listProps }, ref) => (
  <div className={overlayStyles.parent}>
    {validationFn({ isLoading, records: listProps.records })
      ? renderList({ isLoading, ...listProps, ref })
      : renderPlaceholder(placeholder, listProps)}
    {isLoading && listProps.loadingIndicator === 'overlay' && <div className={overlayStyles.overlay} />}
  </div>
));

GridList.propTypes = {
  DummyComponent: PropTypes.func,
  ItemComponent: PropTypes.func,
  cellClassOverride: PropTypes.string, // Default is 4 -> 1 items per row (responsive). Override for more/less.
  className: PropTypes.string,
  gutterSize: PropTypes.number,
  horizontal: PropTypes.bool, // should the list be 1 scrollable row at small widths?
  injectableConfig: PropTypes.shape({
    component: PropTypes.func,
    index: PropTypes.number,
    props: PropTypes.shape({
      ad: PropTypes.object,
      initialized: PropTypes.bool,
    }),
  }),
  isLoading: PropTypes.bool,
  itemKey: PropTypes.string,
  itemProps: PropTypes.object,
  loadingIndicator: PropTypes.oneOf(['overlay', 'dummy']),
  maxCols: PropTypes.number,
  placeholder: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  records: PropTypes.array,
  validationFn: PropTypes.func,
};

GridList.defaultProps = {
  DummyComponent: () => null,
  ItemComponent: () => null,
  cellClassOverride: '',
  className: '',
  injectableConfig: null,
  gutterSize: 15,
  horizontal: false,
  itemKey: 'item',
  itemProps: {},
  isLoading: false,
  loadingIndicator: 'overlay',
  maxCols: 4,
  placeholder: 'No results',
  records: [],
  validationFn: defaultValidationFn,
};

GridList.displayName = 'GridList';

export default GridList;
