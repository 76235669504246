import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserRelationButton from '../../../server/user/relation_button';
import Icon from '../../icon';

import { formatNumberLimit4Chars } from '../../../utility/formatters';

import { COMMENT, FEED_POST, NEWS_ARTICLE, PROJECT, respectableTypeEnumToLabel } from '../../../graphql/respects/enum.js';

import themes from './themes';
import layoutStyles from '../../../styles/global_ui/layout.css';

const getTypeForButton = (type) => `respected_${respectableTypeEnumToLabel(type)}`;

class RespectButton extends Component {
  constructor(props) {
    super(props);

    this.state = { respects: props.respects };

    this.setRespects = this.setRespects.bind(this);
  }

  /**
   * Methods
   */
  setRespects(createOrDeleteBool) {
    if (!this.props.controlled) {
      const respects = createOrDeleteBool ? (this.state.respects + 1) : (this.state.respects - 1);
      this.setState({ respects });
    }
    this.props.onClick(createOrDeleteBool);
  }

  /**
   * Helpers
   */
  _getStyles() {
    return themes[this.props.theme];
  }

  _getRespectCount() {
    return this.props.controlled ? this.props.respects : this.state.respects;
  }

  /**
   * Views
   */
  _getLabel(icon, styles) {
    const respects = this._getRespectCount();

    return (
      <span className={styles.wrapper}>
        <Icon name={icon} />
        {respects > 0
        && <span className={layoutStyles.marginLeft5}>{formatNumberLimit4Chars(respects)}</span>}
      </span>
    );
  }

  render() {
    const { disabled, id, type } = this.props;
    const styles = this._getStyles();

    return (
      <UserRelationButton
        additionalRequestArgs={{ respectable_type: type }}
        config={{
          className: [styles.respectButton, styles.respectedButton],
          label: [this._getLabel('thumbs-up', styles), this._getLabel('thumbs-up-filled', styles)],
        }}
        disableCompletely={disabled}
        id={id}
        onClick={this.setRespects}
        type={getTypeForButton(type)}
      />
    );
  }
}

RespectButton.propTypes = {
  controlled: PropTypes.bool, // When we want a parent to dictate the respect count, set to true.
  disabled: PropTypes.bool,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  respects: PropTypes.number,
  theme: PropTypes.string,
  type: PropTypes.oneOf([COMMENT, FEED_POST, NEWS_ARTICLE, PROJECT]),
};

RespectButton.defaultProps = {
  controlled: false,
  disabled: false,
  onClick: () => {},
  respects: 0,
  theme: 'default',
  type: PROJECT,
};

export default RespectButton;
