import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Button from '../../buttons/base';
import Icon from '../../icon';
import ProjectThumbs from '../project_thumbs';

import keenService from '../../../services/keen/main';
import { addViewedProject } from '../storageHelpers';
import { summonLoginPanel } from '../../../utility/dispatchers';

import dialogTransitions from '../../reusable_components/Dialog/transitions.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './project_count_banner.css';

const COPY = [
  (featuring) => `Hey stranger! Sign up to access unlimited projects${featuring} – it's free.`,
  (featuring, projectCount) => `Join Hackster to access over ${projectCount} free projects${featuring}.`,
  (featuring) => `You're on your last one! Sign up to access unlimited projects${featuring}.`,
];
const FEATURING_COPY = (category) => (category ? ` featuring ${category} and more` : '');

class ProjectCountBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      images: [],
      show: false,
    };

    this.dismiss = this.dismiss.bind(this);
    this.summonAuthDialog = this.summonAuthDialog.bind(this);
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._initializeProjects();
  }

  /**
   * Initializers
   */
  _initializeProjects() {
    const { count, images } = addViewedProject(this.props.currentProject);
    keenService.reportEvent({ eventName: 'Viewed lock screen' }, {
      projects_viewed: count,
      type: 'project_warning',
    });

    this.setState({ count, images, show: true });
  }

  /**
   * Methods
   */

  dismiss() {
    this.setState({ show: false });
  }

  summonAuthDialog() {
    summonLoginPanel({
      detail: {
        state: { currentPanel: 'signup' },
        source: 'project_lock_banner',
      },
    });
  }

  render() {
    const { count, images, show } = this.state;
    const { featured_category, projectCount } = this.props;

    return (
      <TransitionGroup>
        {show
        && (
          <CSSTransition classNames={dialogTransitions} timeout={250}>
            <div className={styles.container}>
              <div className={styles.banner}>
                <div className={styles.content}>
                  <ProjectThumbs count={count} images={images} />
                  <div className={`${styles.text} ${typography.bodyL}`}>
                    {COPY[Math.max(count - 1, 0)](FEATURING_COPY(featured_category), projectCount)}
                  </div>
                </div>
                <div className={styles.buttons}>
                  <Button onClick={this.summonAuthDialog}>Sign up</Button>
                  <Button className={styles.cancelButtonDesktop} colorStyle="blank" onClick={this.dismiss}>
                    <Icon className={typography.charcoal} name="close" />
                  </Button>
                  <a className={`${styles.cancelButtonMobile} ${typography.link} ${typography.bodyM}`} href="javascript:void(0);" onClick={this.dismiss}>
                    Not now
                  </a>
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
}

ProjectCountBanner.propTypes = {
  currentProject: PropTypes.shape({
    cover_image_url: PropTypes.string,
    hid: PropTypes.string,
  }).isRequired,
  featured_category: PropTypes.string,
  projectCount: PropTypes.string.isRequired,
};

ProjectCountBanner.defaultProps = { featured_category: null };

export default ProjectCountBanner;
