import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import EventForm from '../../home_edit/events/forms/EventForm';
import Prompt from '../../../client/reusable_components/Dialog/Prompt';
import ReCAPTCHA from '../../../client/auth/recaptcha';
import { getErrorHelperView } from '../../../client/form_components/templates';

import AlgoliaPlatformsService from '../../../services/algolia/platforms_service';

import currentUserService from '../../../services/current_user';
import keenService from '../../../services/keen/main';
import { getCreateEventArgs, getDeleteEventArgs } from '../../../services/keen/events/eventTypeTemplates';

import { graphMutate } from '../../../requests/graphql';
import { getEventTypeEnum } from '../../../graphql/events/enums';

import errorHandler from '../../../services/error_handler';
import { filterObject, removeFromObject } from '../../../utility/filters';
import { getInObjWithPrototypeAccess } from '../../../utility/accessors';
import { locationSelectIn } from '../../../utility/forms/formatters';
import { mapifyStringQuery } from '../../../utility/converters';
import { reflectPromise } from '../../../utility/promises';
import { summonGlobalMessenger } from '../../../utility/dispatchers';
import { windowLocationRedirect, windowLocationSearch } from '../../../services/window';

import { GENERIC_ERROR } from '../../../constants/alerts';

const DASHBOARD_REDIRECT_KEY = 'dashboard';

const EMPTY_PROMPT = {
  hasError: false,
  isBusy: false,
  okay: null,
  open: false,
};

const PROMPT_COPY = {
  action: 'Delete',
  body: 'Once you delete your Event it cannot be undone.',
  title: 'Are you sure you want to delete your Event?',
};

class EventFormPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      initRecord: this._formatEvent(props.event),
      isBusy: false,
      mode: props.event === null ? 'default' : 'edit',
      pendingRecord: null,
      prompt: EMPTY_PROMPT,
      recaptchaPending: false,
      recaptchaReady: false,
      redirectTo: null,
    };

    this.createWithRecaptcha = this.createWithRecaptcha.bind(this);
    this.createOrUpdateRecord = this.createOrUpdateRecord.bind(this);
    this.dismissPrompt = this.dismissPrompt.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.recaptchaCallback = this.recaptchaCallback.bind(this);
    this.renderRecaptchaBadge = this.renderRecaptchaBadge.bind(this);

    this.algoliaPlatformsService = new AlgoliaPlatformsService();
    this._recaptcha = React.createRef();
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._init();
  }

  /**
   * Initializers
   */
  _init() {
    return currentUserService.getStoreAsync()
      .then((currentUser) => {
        this.setState({
          currentUser,
          redirectTo: this._initRedirectState(),
        });
      })
      .catch((err) => errorHandler('EventFormPage _init', err));
  }

  _initRedirectState() {
    return mapifyStringQuery(windowLocationSearch.get()).redirect_to === DASHBOARD_REDIRECT_KEY ? DASHBOARD_REDIRECT_KEY : null;
  }

  /**
   * Methods
   */
  createWithRecaptcha(record) {
    const recaptchaInstance = this._recaptcha.current;
    if (!recaptchaInstance || !this.state.recaptchaReady) return this._handleRecaptchaError();

    this.setState({
      pendingRecord: record,
      recaptchaPending: true,
    }, () => recaptchaInstance.execute());
  }

  createOrUpdateRecord(record, recaptchaResponse) {
    this.setState({ isBusy: true });

    return graphMutate(this._getTemplateForMode(), this._translateRecordForRequest(record, recaptchaResponse))
      .then(({ event }) => this._fireAnalyticsForNewEvent(event))
      .then(() => this._locationRedirect(this.state.mode === 'default' ? `create_event${this.state.currentUser.isAdmin ? '_admin' : ''}` : 'update_event'))
      .catch((err) => {
        this.setState({ isBusy: false });
        summonGlobalMessenger({ msg: 'Sorry, there was an error saving.', type: 'error' });
        errorHandler('EventFormPage::createOrUpdateRecord: ', err);
      });
  }

  dismissPrompt() {
    this.setState({ prompt: EMPTY_PROMPT });
  }

  handleDeleteClick() {
    this.setState({
      prompt: {
        hasError: false,
        isBusy: false,
        okay: () => this._deleteEvent(),
        open: true,
      },
    });
  }

  recaptchaCallback(recaptchaResponse) {
    if (!this.state.recaptchaPending || !recaptchaResponse) {
      this._handleRecaptchaError();
    } else {
      this.createOrUpdateRecord(this.state.pendingRecord, recaptchaResponse);
      this.setState({ recaptchaPending: false, pendingRecord: null });
    }

    const recaptchaReset = getInObjWithPrototypeAccess(['_recaptcha', 'current', 'reset'], this);
    if (recaptchaReset) recaptchaReset();
  }

  renderRecaptchaBadge(className) {
    if (this.state.mode === 'edit') return null;

    return (
      <ReCAPTCHA
        ref={this._recaptcha} // TODO: this might not be best practice to have another component render this ref?
        badgePos="inline"
        callback={this.recaptchaCallback}
        className={className}
        onLoad={() => this.setState({ recaptchaReady: true })}
        recaptchaSiteKey={this.props.recaptcha_site_key}
      />
    );
  }

  /**
   * Helpers
   */
  _deleteEvent() {
    const event = this.state.initRecord;
    if (!event) return; // this should never happen

    this.setState({ prompt: { ...this.state.prompt, isBusy: true } });

    return graphMutate({ t: 'delete_event' }, { id: event.id })
      .then(() => this._fireAnalyticsForDeleteEvent(event))
      .then(() => this._locationRedirect('delete_event'))
      .catch((err) => {
        this.setState({ prompt: { ...this.state.prompt, isBusy: false, hasError: true } });
        errorHandler('EventFormPage _deleteEvent', err);
      });
  }

  _fireAnalyticsForDeleteEvent({ id, event_type }) {
    const { customProps, event } = getDeleteEventArgs(id, event_type);

    return reflectPromise(keenService.recordEvent(event, customProps));
  }

  _fireAnalyticsForNewEvent({ id, event_type }) {
    return new Promise((resolve, reject) => {
      if (this.state.mode !== 'default') return resolve();

      const { customProps, event } = getCreateEventArgs(id, event_type);

      return keenService.recordEvent(event, customProps)
        .then(() => resolve())
        .catch((err) => resolve()); // Fail silently, we dont want analytics to raise an error.
    });
  }

  _formatEvent(event) {
    if (!event) return null;

    return {
      ...event,
      location: locationSelectIn(event),
    };
  }

  _getBreadcrumbProps() {
    if (this.state.redirectTo === DASHBOARD_REDIRECT_KEY) {
      return { href: this.props.redirect_urls.dashboard, text: 'My events' };
    }

    return { href: this.props.redirect_urls.events, text: 'Events home' };
  }

  _getTemplateForMode() {
    return this.state.mode === 'default' ? { t: 'create_event' } : { t: 'update_event' };
  }

  _handleRecaptchaError() {
    summonGlobalMessenger({ msg: GENERIC_ERROR, type: 'error' });

    this.setState({
      pendingRecord: null,
      recaptchaPending: false,
      isBusy: false,
    });
  }

  _locationRedirect(action) {
    windowLocationRedirect(`${this.props.redirect_urls.dashboard}?flash_event=${action}`);
  }

  _translateRecordForRequest(record, recaptchaResponse) {
    const cleaned = removeFromObject(record, ['image', 'mobile_image', 'location', 'platforms']);
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    const idSpread = this.state.initRecord && this.state.initRecord.hasOwnProperty('id') ? { id: this.state.initRecord.id } : {};
    const mobile_image_id = record.mobile_image ? { mobile_image_id: record.mobile_image.id } : {};
    const recaptchaSpread = recaptchaResponse ? { recaptcha_response: recaptchaResponse } : {};

    return filterObject({
      ...cleaned,
      ...idSpread,
      ...mobile_image_id,
      ...recaptchaSpread,
      ...record.location,
      event_type: getEventTypeEnum(record.event_type),
      image_id: record.image.id,
      platform_ids: record.platforms,
    });
  }

  render() {
    if (!this.state.currentUser) return null;

    return (
      <Fragment>
        <EventForm
          algoliaPlatformsService={this.algoliaPlatformsService}
          breadcrumbProps={this._getBreadcrumbProps()}
          initRecord={this.state.initRecord}
          isAdmin={this.state.currentUser.isAdmin}
          isBusy={this.state.isBusy}
          mode={this.state.mode}
          onDeleteClick={this.handleDeleteClick}
          renderRecaptchaBadge={this.renderRecaptchaBadge}
          saveContent={this.state.mode === 'default' ? this.createWithRecaptcha : this.createOrUpdateRecord}
        />
        <Prompt
          action={PROMPT_COPY.action}
          actionColor="danger"
          body={PROMPT_COPY.body}
          dismiss={this.dismissPrompt}
          isBusy={this.state.prompt.isBusy}
          message={this.state.prompt.hasError ? getErrorHelperView(GENERIC_ERROR) : null}
          okay={this.state.prompt.okay}
          open={this.state.prompt.open}
          title={PROMPT_COPY.title}
        />
      </Fragment>
    );
  }
}

EventFormPage.propTypes = {
  event: PropTypes.shape({
    event_type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.string.isRequired,
    location: PropTypes.shape({
      city: PropTypes.string,
      countryCode: PropTypes.string.isRequired,
      state: PropTypes.string,
    }),
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })),
    start_date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
  recaptcha_site_key: PropTypes.string,
  redirect_urls: PropTypes.shape({
    dashboard: PropTypes.string.isRequired,
    events: PropTypes.string.isRequired,
  }).isRequired,
};

EventFormPage.defaultProps = {
  event: null,
  recaptcha_site_key: null,
};

export default EventFormPage;
