import React from 'react';
import PropTypes from 'prop-types';

import FormGroup from '../../wrappers/form_group';
import LocationSelect from '../location_select';

const FormLocationSelect = (props) => (
  <FormGroup
    errors={Array.isArray(props.errors) ? props.errors.join(', ') : props.errors}
    helperText={props.helperText}
    label={props.label}
    name={props.name}
    value=""
  >
    <LocationSelect {...props} hasErrors={props.errors && props.errors.length > 0} />
  </FormGroup>
);

FormLocationSelect.propTypes = {
  disabled: PropTypes.bool, // Means disable both selects.
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  helperText: PropTypes.string,
  historyStateEnabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  renderDistanceInput: PropTypes.bool,
  value: PropTypes.shape({
    city: PropTypes.string,
    countryCode: PropTypes.string,
    state: PropTypes.string,
    distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    geoloc: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
};

FormLocationSelect.defaultProps = {
  disabled: false,
  errors: null,
  helperText: null,
  name: null,
  historyStateEnabled: false,
  label: 'Location',
  onChange: () => {},
  placeholder: 'Start typing to select a location!',
  renderDistanceInput: true,
  value: {},
};
export default FormLocationSelect;
