import { createBrowserHistory, createMemoryHistory } from 'history';
import { cleanPreAndPostSlashes as cleanPreAndPostSlashesUrlHelper } from '../../../utility/urls';
import { isWindowDefined } from '../../../services/window';

function supportsHistory() {
  const ua = window.navigator.userAgent;
  if ((ua.indexOf('Android 2.') !== -1 || ua.indexOf('Android 4.0') !== -1) && ua.indexOf('Mobile Safari') !== -1 && ua.indexOf('Chrome') === -1 && ua.indexOf('Windows Phone') === -1) return false;

  return window.history && 'pushState' in window.history;
}

// Note: configPath can accept wildcard paths which will be ignored when matching that index.
// Nested wildcard paths must be defined in the root component flatly.
export function arePathsEqual(configPath, realPath) {
  const realPathParts = cleanPreAndPostSlashes(realPath).split('/');
  const configPathParts = cleanPreAndPostSlashes(configPath).split('/');

  if (realPathParts.length !== configPathParts.length) {
    return false;
  }

  let isEqual = true;

  configPathParts.forEach((path, i, list) => {
    if (isEqual === false) return;

    if ((path === '*') || (path === realPathParts[i])) {
      isEqual = true;
    } else {
      isEqual = false;
    }
  });

  return isEqual;
}

function getMemoryHistoryEntriesFromBasePath(basePath = '', fullPath = '') {
  if (isWindowDefined && window.location) {
    return [cleanPreAndPostSlashes(window.location.pathname.slice(basePath.length))];
  } else {
    const params = fullPath.split('?')[1];

    return [{ pathname: '/', search: params && params.length ? `?${params}` : '' }];
  }
}

function createMemoryHistoryEntries(basePath, fullPath) {
  return getMemoryHistoryEntriesFromBasePath(basePath, fullPath);
}

export function cleanPreAndPostSlashes(path) {
  return cleanPreAndPostSlashesUrlHelper(path);
}

export function getPath(history) {
  return history.location.pathname;
}

export function transition(history, path, prompt = false, state = {}) {
  if (!arePathsEqual(history.location.pathname, path)) {
    history.push({ pathname: path, state: { prompt: prompt, ...state } });
  }
}

export function transitionWithSearch({ history, path, search, prompt = false, state = {} }) {
  if (!arePathsEqual(history.location.pathname, path)) {
    history.push({ pathname: path, search, state: { prompt: prompt, ...state } });
  }
}

/**
 * fullPath argument is used for server rendering and honoring search query parameters. Sometimes we use if for UI nav selection.
 */
export default function createHistory(basePath = '', fullPath) {
  return isWindowDefined && supportsHistory()
    ? createBrowserHistory({ basename: basePath })
    : createMemoryHistory({
      initialEntries: createMemoryHistoryEntries(basePath, fullPath),
      initialIndex: 0,
    });
}

export function createSimpleHistory(basename) {
  return isWindowDefined && supportsHistory()
    ? createBrowserHistory({ basename })
    : createMemoryHistory({
      initialEntries: getMemoryHistoryEntriesFromBasePath(basename),
      initialIndex: 0,
    });
}
