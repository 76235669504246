import request from 'superagent';
import OAuth from '../services/oauth';

export function flagContent(flaggable, user_id) {
  return OAuth.apiRequest(
    request
      .post(`${OAuth.getApiPath()}/private/flags`)
      .send({ flag: { flaggable_id: flaggable.id, flaggable_type: flaggable.type, user_id } }),
    false,
    false,
  );
}

export function markAsSpam(flaggable, id_type = 'id') {
  return OAuth.apiRequest(
    request
      .post(`${OAuth.getApiPath()}/private/spam_entities`)
      .send({ id: flaggable.id, type: flaggable.type, id_type }),
    true,
    false,
  );
}
