import {ContentState, EditorState} from 'draft-js';
import {OrderedMap} from 'immutable';
import validator from 'validator';

import parseHTML from '../parsers/paste';
import insertFragmentAtCursor from '../transaction/insertFragmentAtCursor';
import {stringifyLineBreaksToParagraphs, toLiveHtml} from '../parsers/utils';
import {getCurrentBlock} from '../utils/draft/getters';
import {removeSelectedText} from '../utils/draftUtils';

// When pasting into a code-block, we want to wrap the pasted text in a pre element so when it gets passed to convertFromHTMLToContentBlocks,
// the whitespace is not trimmed.
// Otherwise we create a new div for each line break which wil get converted to its own block.
const getHTMLForRawText = (editor, text) => {
  const isPastingInPre = getCurrentBlock(editor).getType() === 'code-block';
  const HTMLString = isPastingInPre ? (`<pre>${validator.escape(text)}</pre>`) : stringifyLineBreaksToParagraphs(text);
  return toLiveHtml(HTMLString);
};

export default function customPasteHandler(editor, callback, restrictions, text, html) {
  const ctx = this;

  if(!html && !text) return false;

  const { cleanedContentState, updatedSelection } = removeSelectedText(editor.getCurrentContent(), editor.getSelection());

  // When copying from draft, the native clipboard won't get the data-block element we care about to replicate.
  // Draft stores an internal clipboard we can use that has the data we want.
  if (ctx.editor._clipboard) {
    const clipboard = ctx.editor.getClipboard();
    const clipboardText = ContentState.createFromBlockArray(clipboard.toArray()).getPlainText();

    // If the plaintext (text argument) is equal to the clipboardText, we know the user hasn't copied anything new from outside the editor.
    // Always use drafts internal clipboard in this case so that we can access the data-block (ie h3, blockquote, etc.)
    if (clipboardText === text || html && html.search(/data-editor=/) > 0) {
      const { insertedContentState, insertedSelection } = insertFragmentAtCursor(cleanedContentState, updatedSelection, clipboard);
      callback(
        EditorState.forceSelection(
          EditorState.push(editor, insertedContentState),
          insertedSelection
        )
      );
      return true;
    }
  }
  // Below and onward handles the native Clipboard's text or html.
  const liveHtml = !html ? getHTMLForRawText(editor, text) : toLiveHtml(html);
  // Note: This has.a entityMap property that we might have to pass to contentState in the future.
  const {contentBlocks} = parseHTML(liveHtml.cloneNode(true), restrictions);
  // Parses and transforms html into an OrderedMap of ContentBlocks.  Ref: draft-js/lib/BlockMapBuilder.js
  const blocks = OrderedMap(contentBlocks.map(block => [block.getKey(), block]));

  const { insertedContentState, insertedSelection } = insertFragmentAtCursor(cleanedContentState, updatedSelection, blocks);

  callback(
    EditorState.forceSelection(
      EditorState.push(editor, insertedContentState),
      insertedSelection
    )
  );
  return true;
}
