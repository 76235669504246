import React from 'react';
import PropTypes from 'prop-types';

import styles from './tooltip_target.css';

const TooltipBody = ({ children, classList, multiline, offsetX, offsetY, position, innerWidth }) => (
  <div className={`${classList.tooltip} ${styles.tooltip} ${styles[position]}`}>
    <div
      className={`${styles.tooltipInner} ${classList.tooltipInner} ${multiline ? styles.tooltipInnerMultiline : ''}`}
      style={{ left: offsetX, top: offsetY, width: innerWidth }}
    >
      {children}
    </div>
  </div>
);

TooltipBody.propTypes = {
  classList: PropTypes.shape({
    tooltip: PropTypes.string,
    tooltipInner: PropTypes.string,
  }),
  innerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multiline: PropTypes.bool,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  position: PropTypes.string,
};

TooltipBody.defaultProps = {
  classList: {},
  innerWidth: null,
  multiline: false,
  offsetX: 0,
  offsetY: 0,
  position: 'bottom',
};

export default TooltipBody;
