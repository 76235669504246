export const DELETE = 8;
export const ENTER = 13;
export const ESC = 27;
export const SPACE = 32;

// Arrow keys
export const DOWN = 40;
export const LEFT = 37;
export const RIGHT = 39;
export const UP = 38;

// ALPHANUMERIC
export const A = 65;
export const P = 80;
