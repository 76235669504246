import React from 'react';
import PropTypes from 'prop-types';

import { getInObj } from '../../../utility/accessors';
import { NOOP_HREF } from '../../../constants/links';

import layout from '../../../styles/global_ui/layout.css';
import navStyles from '../../../styles/navbar.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

const getHref = (tab) => {
  if (typeof tab === 'string') return NOOP_HREF;
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return tab.hasOwnProperty('href') ? tab.href : NOOP_HREF;
};

const getTabLabel = (tab) => (typeof tab === 'string') ? tab : tab.label;

const getAnchor = (props) => getInObj(['external'], props.tab) === true ? _getExternalAnchor(props) : _getDefaultAnchor(props);

const _getExternalAnchor = ({ i, isActive, isDisabled, onClick, tab }) => (
  <a
    className={isActive ? navStyles.linkFocused : navStyles.link}
    href={getHref(tab)}
    onClick={() => {
      if (!isDisabled) onClick(i, tab);
    }}
    rel="noreferrer"
    target="_blank"
  >
    {getTabLabel(tab)}
  </a>
);

const _getDefaultAnchor = ({ i, isActive, isDisabled, onClick, tab }) => (
  <a
    className={isActive ? navStyles.linkFocused : navStyles.link}
    href={getHref(tab)}
    onClick={(e) => {
      e.preventDefault();
      if (!isDisabled) onClick(i, tab);
    }}
  >
    {getTabLabel(tab)}
  </a>
);

// Dumbed down version of other navbars. TODO: combine/compose other navbars to dry out code?
const TabbedHeader = ({ activeIndex, centered, onClick, tabs, underline }) => (
  <div className={`${navStyles.center} ${navStyles.themeLight} ${underline ? utilStyles.borderBottom : ''}`}>
    <ul className={`${navStyles.list} ${layout.noScrollBar} ${centered ? layout.flexJustifyCenter : ''}`}>
      {tabs.map((tab, i) => {
        const isActive = activeIndex === i;
        const isDisabled = getInObj(['disabled'], tab);

        return (
          <li key={i} className={`${navStyles.listItem} ${isDisabled ? typography.linkDisabled : ''}`}>
            <div className={isActive ? navStyles.linkWrapperFocused : navStyles.linkWrapper}>
              {getAnchor({ i, isActive, isDisabled, onClick, tab })}
            </div>
          </li>
        );
      })}
    </ul>
  </div>
);

TabbedHeader.propTypes = {
  activeIndex: PropTypes.number,
  centered: PropTypes.bool,
  onClick: PropTypes.func,
  tabs: PropTypes.array, // Strings or objects {href: '', label: '', value: '', diabled: <true|false>, external: <true|false>>}
  underline: PropTypes.bool,
};

TabbedHeader.defaultProps = {
  activeIndex: 0,
  centered: true,
  onClick: () => {},
  tabs: [],
  underline: true,
};

export default TabbedHeader;
