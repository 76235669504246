import { getPropTemplate } from './eventTypes';
import { isBlank, isObjectWithLength } from '../../../utility/types';

const normalizers = {
  array: (prop) => (Array.isArray(prop) && prop.length > 0) ? prop : null,
  boolean: (prop) => {
    if (typeof prop === 'boolean') return prop;
    if (typeof prop === 'string') {
      const string = prop.toLowerCase();

      return string === 'true' ? true : string === 'false' ? false : null;
    }

    return null;
  },
  number: (prop) => {
    if (Array.isArray(prop)) return null;
    const float = parseFloat(prop);

    return isNaN(float) ? null : float;
  },
  string: (prop) => {
    if (typeof prop === 'string') return prop;
    if (typeof prop === 'boolean' || typeof prop === 'number') return prop.toString();

    return null;
  },
};

function normalizeProps(props = {}, template = {}) {
  return Object.keys(props).reduce((acc, key) => {
    const templateVal = template[key];
    const prop = props[key];

    const val = isObjectWithLength(prop)
      ? normalizeProps(prop, templateVal)
      : normalizeProp(prop, templateVal, key);

    return isBlank(val) ? acc : { ...acc, [key]: val };
  }, {});
}

function normalizeProp(prop, propType, key) {
  const normalizer = normalizers[propType];

  return normalizer ? normalizer(prop) : inferTypeAndNormalize(prop, key);
}

function inferTypeAndNormalize(prop, key = '') {
  const inferredType = (key.endsWith('_id') && key !== 'event_id') ? 'number' : 'string';

  return normalizers[inferredType](prop);
}

export default function normalizeEventProps(customProps, eventName = '') {
  return isObjectWithLength(customProps) ? normalizeProps(customProps, getPropTemplate(eventName)) : null;
}
