import request from 'superagent';
import getCSRFToken from '../../../../../services/oauth/getCSRFToken';
import OAuth from '../../../../../services/oauth';

export function getEditorFields(id, fields) {
  return OAuth.apiRequest(
    request(`${OAuth.getApiPath()}/private/challenges/${id}/fields`)
      .query({ fields: fields }),
  );
}

export function postEditorFields(id, data) {
  return OAuth.apiRequest(
    request
      .post(`/contests/${id}`)
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      .set('X-CSRF-Token', getCSRFToken())
      .type('form')
      .send({ _method: 'patch', ...data }),
  );
}
