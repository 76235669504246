export function tryJSONParse(val, fallback = null) {
  try {
    return JSON.parse(val);
  } catch (err) {
    return fallback;
  }
}

export function tryJSONStringify(val, fallback = '') {
  try {
    return JSON.stringify(val);
  } catch (err) {
    return fallback;
  }
}
