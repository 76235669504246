import React from 'react';
import PropTypes from 'prop-types';

import { DummyContentDefaultView, DummyTwoByCard } from './DummyViews';
import LazyImage from '../../../../client/reusable_components/LazyImage';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

import styles from './promoted_content.css';

/**
 * Helpers
 */
const _getMainView = (props) => props.content.length <= 2 ? TwoItemsView(props) : DefaultView(props);

const _sideItemsCountImageWidth = (content) => content.length === 3 ? 100 : 70;

/**
 * Views
 */
const ListItem = ({ contentLen, imageWidth, index, item, onClick, renderForPreview }) => (
  <div className={styles.listItem}>
    <div className={styles.listItemContentWrapper}>
      <div>
        <p className={`${typography.h5} ${layout.marginBottom10}`}>{item.contentType.value}</p>
        <a
          className={`${typography.link} ${typography.bodyM} ${typography.bold} ${layout.noScrollBar} ${styles[`listItemTitle${imageWidth}`]}`}
          href={item.link}
          onClick={(e) => {
            if (renderForPreview) return e.preventDefault();
            onClick(e, item.link, index);
          }}
        >
          {item.title}
        </a>
      </div>
      <a
        className={`${styles[`listItemImageDims${imageWidth}`]}`}
        href={item.link}
        onClick={(e) => {
          if (renderForPreview) return e.preventDefault();
          onClick(e, item.link, index);
        }}
      >
        <LazyImage
          key={`${item.title}_${contentLen}_${index}`}
          alt={item.image11.name}
          ratio="1:1"
          src={item.image11.url}
          width={imageWidth}
        />
      </a>
    </div>
  </div>
);

const DefaultView = ({ content, onClick, renderForPreview }) => {
  const sideItemImageWidth = _sideItemsCountImageWidth(content);

  return (
    <div className={styles.defaultView}>
      <div className={styles.mainCard}>
        <div className={styles.mainInfo}>
          <p className={`${typography.h5} ${layout.marginBottom10}`}>{content[0].contentType.value}</p>
          <a
            className={`${typography.linkCharcoal} ${typography.h3} ${typography.overflowBreakWord} ${layout.marginBottom15} ${layout.block}`}
            href={content[0].link}
            onClick={(e) => {
              if (renderForPreview) return e.preventDefault();
              onClick(e, content[0].link, 0);
            }}
          >
            {content[0].title}
          </a>
          <p className={`${typography.bodyM} ${typography.overflowBreakWord}`}>{content[0].description}</p>
        </div>

        <div>
          <a
            className={styles.mainImageWrapper}
            href={content[0].link}
            onClick={(e) => {
              if (renderForPreview) return e.preventDefault();
              onClick(e, content[0].link, 0);
            }}
          >
            <LazyImage
              key={`${content[0].title}_${0}`}
              alt={content[0].image43.name}
              ratio="4:3"
              src={content[0].image43.url}
              width={375}
            />
          </a>
        </div>
      </div>

      <div className={styles.listContainer}>
        {content.slice(1, 4).map((item, i) => (
          <ListItem
            key={`${item.title}_${content.length}_${i}`}
            contentLen={content.length} // This is for editing. The key needs to change when items are added and removed for proper re-rendering.
            imageWidth={sideItemImageWidth}
            index={i + 1}
            item={item}
            onClick={onClick}
            renderForPreview={renderForPreview}
          />
        ))}
      </div>
    </div>
  );
};

const TwoItemCardView = ({ index, item, onClick, renderForPreview }) => (
  <div className={styles.twoByCard}>
    <div className={styles.twoByCardInfo}>
      <div>
        <h5 className={`${typography.h5} ${layout.marginBottom10}`}>{item.contentType.value}</h5>
        <a
          className={`${typography.linkCharcoal} ${typography.bodyM} ${typography.bold} ${layout.marginBottom22} ${layout.block}`}
          href={item.link}
          onClick={(e) => {
            if (renderForPreview) return e.preventDefault();
            onClick(e, item.link, index);
          }}
        >
          {item.title}
        </a>
      </div>
      <p className={typography.bodyS}>{item.description}</p>
    </div>

    <div>
      <a
        className={styles.twoByMainImageWrapper}
        href={item.link}
        onClick={(e) => {
          if (renderForPreview) return e.preventDefault();
          onClick(e, item.link, index);
        }}
      >
        <LazyImage
          key={`${item.title}_${index}`}
          alt={item.image43.name}
          ratio="4:3"
          src={item.image43.url}
          width={285}
        />
      </a>
    </div>
  </div>
);

const TwoItemsView = ({ content, onClick, renderForPreview }) => (
  <div className={`${layout.flexJustifySpaceBetween} ${styles.twoByView}`}>
    <TwoItemCardView index={0} item={content[0]} onClick={onClick} renderForPreview={renderForPreview} />
    {content.length === 2 ? <TwoItemCardView index={1} item={content[1]} onClick={onClick} renderForPreview={renderForPreview} /> : <DummyTwoByCard />}
  </div>
);

const PromotedContentDefaultView = (props) => !props.content.length ? <DummyContentDefaultView /> : _getMainView(props);

PromotedContentDefaultView.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    contentType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    description: PropTypes.string,
    image11: PropTypes.shape({
      dimensions: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
      }),
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    image43: PropTypes.shape({
      dimensions: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
      }),
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    link: PropTypes.string,
    products: PropTypes.array,
    tags: PropTypes.array,
    title: PropTypes.string,
  })),
  onClick: PropTypes.func.isRequired,
  renderForPreview: PropTypes.bool,
};

PromotedContentDefaultView.defaultProps = { renderForPreview: false };

export default PromotedContentDefaultView;
