import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../../client/nav_components/breadcrumb';
import RingSpinner from '../../../../client/spinners/ring';

import { getInObj } from '../../../../utility/accessors';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

class NewsArticleAnalytics extends Component {
  constructor(props) {
    super(props);

    this.state = { redirectToArticle: this._initFromQuery(props.currentHistoryData) };
  }

  /**
   * Initializers
   */
  _initFromQuery(currentHistoryData) {
    if (this.props.view === 'default') return false;

    const search = getInObj(['search'], currentHistoryData);

    return search.includes('from_article=t');
  }

  /**
   * Helpers
   */
  _onBreadCrumbClick(e) {
    if (!this.state.redirectToArticle) {
      e.preventDefault();
      this.props.toggleCurrentView();
    }
  }

  /**
   * View Helpers
   */
  _getBreadCrumbData() {
    return this.state.redirectToArticle
      ? { href: this.props.currentRecord.record.url, text: 'Back to article' }
      : { href: '/news/admin', text: 'Back to articles' };
  }

  /**
   * Views
   */
  _getViewForType() {
    return this.props.view === 'default' ? this._getDefaultView() : this._getArticleStatsView();
  }

  _getDefaultView() {
    return (
      <div>
        <div className={layout.marginBottom30}>
          <h1 className={`${typography.h1} ${layout.marginBottom30}`}>Analytics</h1>
        </div>

        <iframe
          allowFullScreen={true}
          frameBorder="0"
          height={this.props.analytics.metadata.height}
          src={this.props.analytics.url}
          width={this.props.analytics.metadata.width}
        />
      </div>
    );
  }

  _getArticleStatsView() {
    if (!this.props.currentRecord.record) return this._getLoaderView();

    const { analytics, respects_count, title } = this.props.currentRecord.record;

    return (
      <div>
        <div className={layout.marginBottom30}>
          <h1 className={`${typography.h1} ${layout.marginBottom30}`}>Stats</h1>
          <h2 className={typography.h3}>{title}</h2>
          <p className={`${typography.bodyM} ${typography.bold} ${layout.marginTop15}`}>{`Respects: ${respects_count}`}</p>
        </div>

        <iframe
          allowFullScreen={true}
          frameBorder="0"
          height={analytics.metadata.height}
          src={analytics.url}
          width={analytics.metadata.width}
        />
      </div>
    );
  }

  _getLoaderView() {
    return (
      <div className={layout.container}>
        <div className={`${layout.wrapper1170} ${layout.flex}`}>
          <RingSpinner size={20} />
          <p className={`${typography.bodyM} ${typography.bold} ${layout.marginLeft10}`}>Loading...</p>
        </div>
      </div>
    );
  }

  render() {
    const breadcrumb = this._getBreadCrumbData();

    return (
      <div className={layout.container}>
        <div className={layout.wrapper1170}>
          <div className={layout.marginBottom30}>
            <Breadcrumb
              color="Charcoal"
              href={breadcrumb.href}
              onClick={(e) => this._onBreadCrumbClick(e)}
              text={breadcrumb.text}
            />
          </div>

          {this._getViewForType()}
        </div>
      </div>
    );
  }
}

NewsArticleAnalytics.propTypes = {
  analytics: PropTypes.shape({ // Will be null when using this view for a particular Article.
    metadata: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }),
  currentHistoryData: PropTypes.object.isRequired,
  currentRecord: PropTypes.shape({
    hasChanges: PropTypes.bool.isRequired,
    record: PropTypes.shape({
      analytics: PropTypes.shape({
        metadata: PropTypes.shape({
          height: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
        }).isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
    }),
  }),
  toggleCurrentView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};

NewsArticleAnalytics.defaultProps = {
  analytics: null,
  currentRecord: {
    hasChanges: false,
    record: null,
  },
};

export default NewsArticleAnalytics;
