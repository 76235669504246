import React from 'react';

import DummyProjectCard from '../../../../client/cards/project_card/DummyCard';
import ProjectCard from '../../../../client/cards/project_card';

import { dummyProjectProps, projectProps } from './shared';

const SECTION_KEY_TO_VIEW_METHOD = {
  category_id: '_viewAllList',
  featured: '_viewAllList',
  sort: '_viewAllList',
  topic_id: '_viewAllList',
};

export default ({ community } = {}) => ({
  _getViewMethodNameForSectionKey: (sectionKey) => SECTION_KEY_TO_VIEW_METHOD[sectionKey] || null,
  components: {
    category_id: (props) => React.createElement(ProjectCard, { project: props.record, ...projectProps }),
    sort: (props) => React.createElement(ProjectCard, { project: props.record, ...projectProps }),
    topic_id: (props) => React.createElement(ProjectCard, { project: props.record, ...projectProps }),
  },
  links: {
    category_id: (section) => ({
      external: `/${community.user_name}/projects?category_id=${section.value}`,
      internal: `/projects?category_id=${section.value}`,
    }),
    sort: (section) => ({
      external: `/${community.user_name}/projects?sort=${section.value}`,
      internal: `/projects?sort=${section.value}`,
    }),
    topic_id: (section) => ({
      external: `/${community.user_name}/projects?topic_id=${section.value}`,
      internal: `/projects?topic_id=${section.value}`,
    }),
  },
  listFacet: {
    category_id: (section) => [`communities.id:${community.id}`],
    sort: (section) => [`communities.id:${community.id}`],
    topic_id: (section) => [`communities.id:${community.id}`, `topic_ids:${section.value}`],
  },
  loaderComponent: {
    category_id: () => React.createElement(DummyProjectCard, dummyProjectProps),
    sort: () => React.createElement(DummyProjectCard, dummyProjectProps),
    topic_id: () => React.createElement(DummyProjectCard, dummyProjectProps),
  },
  pagination: {
    category_id: () => ({
      maxCardCount: 4,
      type: 'page',
    }),
    sort: () => ({
      maxCardCount: 4,
      type: 'page',
    }),
    topic_id: () => ({
      maxCardCount: 4,
      type: 'page',
    }),
  },
  pinnedItemFacet: {},
  projectsFacet: {},
  queryMap: {
    category_id: (section) => ({
      category_id: section.value,
      hitsPerPage: 4,
      sort: 'trending',
      qlVars: section.value === 'featured' ? { base_channel_id: community.id, featured: true } : { base_channel_id: community.id, channel_category_id: section.value },
    }),
    sort: (section) => ({
      hitsPerPage: 4,
      sort: section.value,
      qlVars: { base_channel_id: community.id },
    }),
    topic_id: (section) => ({
      topic_ids: section.value, // ATTN: Does this need to be set here?
      hitsPerPage: 4,
      sort: 'trending',
      qlVars: section.value === 'featured' ? { base_channel_id: community.id, featured: true } : { base_channel_id: community.id, topic_id: section.value },
    }),
  },
  service: {
    category_id: () => 'projectsService',
    sort: () => 'projectsService',
    topic_id: () => 'projectsService',
  },
  serviceOverride: { // Temp bucket.
    category_id: () => 'graphQlProjectsService',
    featured: () => 'graphQlProjectsService',
    sort: () => 'graphQlProjectsService',
    topic_id: () => 'graphQlProjectsService',
  },
  pinnedServiceOverride: { }, // Temp bucket, make sure to add the qlVars prop to queryMap above.
});
