import React from 'react';
import PropTypes from 'prop-types';

import PlayButton from '../../buttons/play_button';

import styles from './video_card.css';

const VideoOverlay = ({ className, onClick }) => (
  <div className={`${styles.overlay} ${className}`} onClick={onClick}>
    <PlayButton className={styles.playButton} />
  </div>
);

VideoOverlay.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

VideoOverlay.defaultProps = {
  className: '',
  onClick: () => {},
};

export default VideoOverlay;
