import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../link/AnalyticsLink';
import Icon from '../../icon';
import LazyComponent from '../../wrappers/lazy_component';

import layoutStyles from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './similar_projects_bar.css';

import { PROJECT_CARD } from '../../../services/keen/events/linkConstants';

const renderProject = (project) => (
  <AnalyticsLink
    className={`${styles.container} ${typography.link}`}
    item={project}
    linkType={PROJECT_CARD}
    location="bottom_bar"
  >
    <div className={styles.text}>
      <h5 className={typography.h5}>
        Next project
      </h5>
      <div className={`${typography.bodyS} ${typography.bold} ${styles.link}`}>
        {project.name}
      </div>
    </div>
    <div className={layoutStyles.marginLeft15}>
      <Icon name="arrow-right" />
    </div>
  </AnalyticsLink>
);

const BarView = ({ initialized, onReveal, records }) => (
  <LazyComponent onReveal={onReveal}>
    {initialized && !!records.length ? renderProject(records[0]) : null}
  </LazyComponent>
);

BarView.propTypes = {
  initialized: PropTypes.bool.isRequired,
  onReveal: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
};

export default BarView;
