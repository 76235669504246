import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import Select from '../../form_components/select';

import { isObjectWithLength } from '../../../utility/types';

class Search extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.selectRenderer = this.selectRenderer.bind(this);
    this.handleViewState = this.handleViewState.bind(this);
    this.handlePostForm = this.handlePostForm.bind(this);
    this.handleContinueButton = this.handleContinueButton.bind(this);

    this.state = { isLoading: false, viewState: 'selection', value: null };
  }

  onChange(value) {
    if (isObjectWithLength(value)) {
      this.setState({ value });
    }
  }

  getOptions(input) {
    const { getOptionsHandler } = this.props.actions;

    this.setState({ isLoading: true });

    const query = {
      type: this.props.type,
      q: input,
    };

    if (this.props.type === 'Course') {
      query.parent_id = this.props.store['university'].id;
    }

    return getOptionsHandler(query)
      .then((response) => {
        const options = response.map((group) => ({
          value: group.id,
          label: group.name,
          optionLabel: this.selectRenderer(group),
          ...group,
        }));

        this.setState({ isLoading: false });

        return Promise.resolve({ options });
      })
      .catch((err) => Promise.reject(err));
  }

  handleViewState(state) {
    this.setState({ viewState: state });
  }

  handlePostForm(form, endpoint, imageData) {
    this.props.actions.postFormHandler(form, endpoint, imageData);
  }

  handleContinueButton(e) {
    e.preventDefault();
    this.props.actions.setSelectionValue(this.state.value, this.props.name.toLowerCase());
  }

  render() {
    const { name, search, type } = this.props;

    const continueBtn = this.state.value
      ? (
        <div className="continue-btn">
          <button className="btn btn-success btn-sm" onClick={this.handleContinueButton}>Continue</button>
        </div>
        )
      : null;

    const view = this.state.viewState === 'selection' && search
      ? (
        <div className="course-wizard-search">
          <div className="create-new-form">
            Not in the list?&nbsp;
            <a href="javascript:void(0);" onClick={this.handleViewState.bind(this, 'form')}>Create a new one</a>
            .
          </div>
          <Select
            asyncOpts={{
              initOnMount: true,
              request: this.getOptions,
            }}
            onSelectedChange={this.onChange}
            placeholder={`Search for a ${name}`}
          />
          {continueBtn}
        </div>
        )
      : (
        <div className="course-wizard-search">
          <Form
            {...this.props.form}
            postForm={this.handlePostForm}
            search={search}
            toggleToSearch={this.handleViewState.bind(this, 'selection')}
            type={type}
          />
        </div>
        );

    return view;
  }

  selectRenderer(group) {
    let detailsText = group.city ? group.city : '';
    detailsText += group.country ? `, ${group.country}` : '';
    const details = detailsText !== '' ? (<div className="result-details">{detailsText}</div>) : null;

    return (
      <div className="course-wizard-result">
        <div className="result-name">{group.name + ' '}</div>
        {details}
      </div>
    );
  }
}

Search.propTypes = {
  actions: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  search: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.object,
};

Search.defaultProps = { value: null };

export default Search;
