import React from 'react';

import EventCardSponsoredLargePreview from './preview_cards/EventCardSponsoredLargePreview';
import EventCardSponsoredSmallPreview from './preview_cards/EventCardSponsoredSmallPreview';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const PreviewLayoutSponsored = ({ item }) => (
  <div>
    <EventCardSponsoredLargePreview item={item} />
    <div className={`${layout.marginTop45} ${layout.marginBottom30} ${layout.paddingTop45} ${utilStyles.borderTop}`}>
      <div style={{ maxWidth: 350 }}>
        <EventCardSponsoredSmallPreview item={item} />
      </div>
    </div>
  </div>
);

export default PreviewLayoutSponsored;
