import React from 'react';
import PropTypes from 'prop-types';

import ContestSponsorsList from '../../../client/cards/contest_card/helpers/ContestSponsorsList';

import { getInObj } from '../../../utility/accessors';
import { timestampToPrettyDate } from '../../../utility/time';

import AnchorButton from '../../../client/buttons/anchor';
import LazyImage from '../../../client/reusable_components/LazyImage';
import ProBadge from '../../reusable_components/Badge/ProBadge';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './contest_card.css';

import { MEMBERSHIP_TIERS, MEMBERSHIP_TIERS_RANK_MAP } from '../../../scenes/contests_page/enum.js';

const JUDGING_STATUS = 'JUDGING';

/**
 * Helpers
 */
export const canRegisterForContest = (contestTier, userTier) => MEMBERSHIP_TIERS_RANK_MAP[contestTier.toUpperCase()] <= MEMBERSHIP_TIERS_RANK_MAP[userTier.toUpperCase()];

const _getStatusText = (contest) => {
  if (contest.is_registration_open) {
    return 'Participation open until:';
  } else if (contest.status === JUDGING_STATUS) {
    return 'Judging in progress';
  } else {
    null;
  }
};

const _getStatusButtonProps = (contest, userTier) => {
  const tier = userTier ?? 'BASIC';

  if (contest.is_registration_open) {
    const canRegister = canRegisterForContest(contest.required_user_tier, tier);

    return {
      text: canRegister ? 'Participate' : 'Learn more about PRO',
      url: canRegister ? contest.url : '/pro',
      bgColor: canRegister ? '' : 'secondary',
    };
  } else if (contest.status === JUDGING_STATUS && !contest.disable_projects_tab) {
    return { text: 'View all submissions', url: contest.submissions_url };
  } else {
    // 1. When contest is judging and disable_projects_tab is true.
    // 2. Base case fallback in case of buggy backend.
    return { text: 'View contest', url: contest.url };
  }
};

/**
 * Views
 */
const GeneralInfoView = ({ contest, userTier }) => (
  <div className={`${layout.flexColumn} ${layout.flexJustifySpaceBetween} ${styles.activeContestInfoWrapper}`}>
    <div>
      {contest.required_user_tier === 'PRO' && <ProBadge contentType="contest" size="medium" />}
      <a className={`${typography.link}`} href={contest.url}>
        <h4 className={`${typography.breakWord} ${typography.h4} ${layout.marginBottom10}`}>{contest.name}</h4>
      </a>

      <ContestSponsorsList sponsors={contest.sponsors} sponsorsConfig={{ limit: 5, menuPos: { alignRight: true, alignTop: false } }} />
    </div>

    <div className={styles.activeContestStatusWrapper}>
      <ContestStatusInfoView contest={contest} userTier={userTier} />
    </div>
  </div>
);

const ContestStatusInfoView = ({ contest, userTier }) => {
  const buttonProps = _getStatusButtonProps(contest, userTier);

  return (
    <div>
      <div className={`${typography.bodyS} ${typography.pebble} ${layout.marginBottom10}`}>
        <p className={layout.marginBottom0}>{_getStatusText(contest)}</p>
        {contest.is_registration_open && <p className={`${layout.marginBottom0} ${typography.bold}`}>{timestampToPrettyDate(contest.end_date_in_pdt)}</p>}
      </div>

      <AnchorButton
        className={styles.activeContestActionable}
        colorStyle={buttonProps.bgColor}
        href={buttonProps.url}
      >
        {buttonProps.text}
      </AnchorButton>
    </div>
  );
};

/**
 * Cards
 */
const DesktopCard = ({ contest, userTier }) => (
  <div className={styles.activeContestLargeCardWrapper}>
    <div className={styles.activeContestInfoContainer}>
      <GeneralInfoView contest={contest} userTier={userTier} />
    </div>

    <div className={styles.activeContestImgWrapper}>
      <a href={contest.url}>
        <LazyImage
          alt={contest.name}
          classList={{ image: styles.activeContestImg }}
          ratio="21:10"
          src={getInObj(['cover_image', 'url'], contest)}
          width={630}
        />
      </a>
    </div>

    <div className={styles.activeContestPrizeContainer}>
      <div>
        <img
          className={styles.activeContestPrizeIcon}
          src={getInObj(['prize', 'icon_urls', 'x1'], contest)}
          srcSet={`${getInObj(
            ['prize', 'icon_urls', 'x1'],
            contest,
          )} 1x, ${getInObj(['prize', 'icon_urls', 'x2'], contest)} 2x`}
        />
      </div>

      <div
        className={`${layout.flexCenterItems} ${layout.flexColumn} ${layout.flexJustifyCenter}`}
      >
        <p
          className={`${layout.marginBottom15} ${typography.bodyM} ${typography.bold} ${typography.breakWord} ${typography.charcoal} ${typography.textCenter}`}
        >
          {getInObj(['prize', 'category', 'name'], contest)
          || 'First place prize'}
        </p>

        <LazyImage
          alt={getInObj(['prize', 'name'], contest)}
          classList={{
            root: `${layout.marginBottom15} ${utilStyles.bgTransparent}`,
            image: `${utilStyles.borderRadius}`,
          }}
          fit="fill"
          forceImg={true}
          queryParams={{ bg: 'var(--whiteToCardBGColor)' }}
          ratio="3:2"
          src={getInObj(['prize', 'image', 'url'], contest)}
          width={130}
        />

        <p
          className={`${typography.bodyXS} ${typography.breakWord} ${typography.textCenter}`}
        >
          {getInObj(['prize', 'name'], contest)}
        </p>
      </div>

      <a
        className={`${typography.bodyS} ${typography.bold} ${typography.linkBlue}`}
        href={`${contest.url}#overview-prizes`}
      >
        View prizes
      </a>
    </div>
  </div>
);

const MobileCard = ({ contest, userTier }) => (
  <div className={styles.activeMobileCardContainer}>
    <a className={`${styles.activeMobileImgAspectRatioPadding} ${utilStyles.absolutePlaceholderParent}`} href={contest.url}>
      <LazyImage
        alt={contest.name}
        classList={{ root: `${utilStyles.absolutePlaceholderChild} ${utilStyles.borderBottom}`, image: utilStyles.borderRadiusTopLeftTopRight }}
        ratio="21:10"
        setStyle={false}
        src={getInObj(['cover_image', 'url'], contest)}
        width={345}
      />
    </a>

    <GeneralInfoView contest={contest} userTier={userTier} />
  </div>
);

const ActiveContestCard = ({ contest, userTier }) => (
  <div className={`${layout.marginBottom30} ${utilStyles.secondaryBorder} ${utilStyles.borderRadius}`}>
    <MobileCard contest={contest} userTier={userTier} />
    <DesktopCard contest={contest} userTier={userTier} />
  </div>
);

ActiveContestCard.propTypes = {
  contest: PropTypes.shape({
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    disable_projects_tab: PropTypes.bool.isRequired,
    end_date_in_pdt: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_registration_open: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    prize: PropTypes.shape({
      cash_value: PropTypes.number.isRequired,
      icon_urls: PropTypes.shape({
        x1: PropTypes.string.isRequired,
        x2: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({ url: PropTypes.string.isRequired }),
      name: PropTypes.string.isRequired,
    }).isRequired,
    required_user_tier: PropTypes.oneOf(MEMBERSHIP_TIERS).isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    start_date_in_pdt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    submissions_url: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  userTier: PropTypes.oneOf(MEMBERSHIP_TIERS),
};

export default ActiveContestCard;
