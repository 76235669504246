import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateCSV, getFileDetails } from './utils/Requests';
import { pollJob } from '../../requests/attachments';
import errorHandler from '../../services/error_handler';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      isError: false,
      isWorking: false,
      fileId: null,
    };
  }

  handleClick(e) {
    if (this.state.isWorking || this.state.fileUrl) {
      return;
    }

    e.preventDefault();
    this.setState({ isError: false, isWorking: true });
    let fileId = null;

    return generateCSV(this.props.url)
      .then((response) => {
        fileId = response.body.file_id;

        return pollJob(response.body.job_id);
      })
      .then((response) => getFileDetails(fileId))
      .then((response) => {
        this.setState({ isWorking: false, fileUrl: response.body.file.url });
        if (window) {
          window.location = this.state.fileUrl;
        }

        return;
      })
      .catch((err) => {
        this.setState({ isError: true, isWorking: false });
        errorHandler('CSV Exporter handleClick: ', err);
      });
  }

  render() {
    return (
      <span>
        {this.state.isWorking
          ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              <span>Preparing file...</span>
            </span>
            )
          : (
            <span>
              {this.state.isError && 'An error happened. Please try again - '}
              <a href={this.state.fileUrl ? this.state.fileUrl : 'javascript:void(0)'} onClick={this.handleClick}>
                {this.props.label}
              </a>
            </span>
            )}
      </span>
    );
  }
}

App.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
