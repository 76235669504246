import { getCountryData } from '../country_data';
import { getInObj } from '../../utility/accessors';
import { objHasPropertyOfLength } from '../../utility/predicates';

const TRUNCATED_OPTS = {
  'Hong Kong': {
    short_code: 'hk',
    text: 'Hong Kong',
  },
};

/**
 * Helpers
 */
// Turns place_type ["country", "region", "place"] into a mapped object to the city name.
// This is needed for places like Hong Kong.
const getLocationDataForMissingContext = (feature) => feature.place_type.reduce((acc, type) => {
  if (type === 'country') {
    acc[type] = { text: feature.text, short_code: getInObj(['properties', 'short_code'], feature) };
  } else {
    acc[type] = { text: feature.text };
  }

  return acc;
}, {
  country: null,
  place: null,
  region: null,
});

const getLocationDataFromContext = (feature) => {
  const country = feature.context.find((f) => f.id.includes('country'));
  const region = feature.context.find((f) => f.id.includes('region'));

  // Politics can play a role in the API data. HK places does not return a country.
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  const countryWithFallback = (!country && (region && TRUNCATED_OPTS.hasOwnProperty(region.text))) ? TRUNCATED_OPTS[region.text] : country;

  return {
    country: countryWithFallback,
    place: (feature.context.find((f) => f.id.includes('place')) || { text: feature.text }),
    region: feature.context.find((f) => f.id.includes('region')),
  };
};

// Converts a saved location object to a value string.
// The location prop has to match the exact string that Mapbox returns to autoselect it on mount.
// TRUNCATED_OPTS are for places that have 2 or 3 redundancies in their returned value (city, state or country are the same name).
export const formatLocationForMapBoxOptions = (location) => {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (TRUNCATED_OPTS.hasOwnProperty(location.city)) return location.city;

  const locationAttributesToMerge = location.state === 'Hong Kong' ? ['city', 'state'] : ['city', 'state', 'countryCode'];
  const countryDataObj = getCountryData(location.countryCode);

  return locationAttributesToMerge.reduce((acc, key, i) => {
    if (objHasPropertyOfLength(location, key)) {
      // Turn the countryIso2 into a full name (FR -> France) to match the API's output
      const attr = key === 'countryCode' ? countryDataObj.name : location[key];

      return i === 0 ? attr : `${acc}, ${attr}`;
    }

    return acc;
  }, '');
};

// Note: This is for query arg, types=place.
export const formatMapboxPlacesRecordForSelectOpts = (feature) => {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  const { country, place, region } = feature.hasOwnProperty('context') ? getLocationDataFromContext(feature) : getLocationDataForMissingContext(feature);

  // Filter out options that do not have country data.
  if (!country || !objHasPropertyOfLength(country, 'short_code')) return null;

  const full = feature.place_name;
  const geoloc = { lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] };
  const state = getInObj(['text'], region);

  return {
    label: full,
    location: { city: place.text, country: country.text, countryCode: country.short_code.toUpperCase(), state, geoloc, full },
    value: full,
  };
};

// Note: This is for query arg, types=country. CountrySelect only needs the country name and short_code.
export const formatMapboxCountryRecordForSelectOpts = (feature) => {
  // Below check is just to be safe, but 'types=country' should ensure all results are valid countries.
  const code = getInObj(['properties', 'short_code'], feature);
  if (!code) return null;

  return {
    label: feature.text,
    value: code.toUpperCase(),
  };
};

// Note: This is for query arg, types=poi.
export const formatMapboxPoiRecordForState = (feature) => {
  const place = feature.context.find((f) => f.id.includes('place'));
  const region = feature.context.find((f) => f.id.includes('region'));
  const country = feature.context.find((f) => f.id.includes('country'));
  const lng = feature.geometry.coordinates[0];
  const lat = feature.geometry.coordinates[1];

  const geoloc = { lat, lng };
  const city = getInObj(['text'], place) || '';
  const state = getInObj(['text'], region) || '';
  const full = formatLocationForMapBoxOptions({ city, state, countryCode: country.short_code.toUpperCase() });

  return { city, full, geoloc, state, country: country.text, countryCode: country.short_code.toUpperCase() };
};
