/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SimpleSelect from '../../form_components/simple_select';

import KeenService from '../../../services/keen';
import { removeFromObject } from '../../../utility/filters';

import styles from './paginated_list.css';
import buttonStyles from '../../../styles/global_ui/buttons.css';

const keenService = new KeenService();

class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      enactedFilters: this._initFilterCount(props.filters),
    };
  }

  _initFilterCount(filters) {
    return filters.reduce((acc, filter) => {
      if (filter.key !== 'sort') {
        const isActive = filter.options.find((option) => option.active === true && !option.default);
        acc = isActive ? { ...acc, [filter.key]: true } : acc;
      }

      return acc;
    }, {});
  }

  _handleSelection(filter, option) {
    const queryString = filter.hasOwnProperty('key') ? `${filter.key}=${option.value}` : '';
    this._handleFilterCounter(filter, option);
    this.props.onSelection({ queryString, option });
    keenService.recordEvent({ eventName: 'Clicked filter value' }, { filter_name: filter.key, filter_value: option.value });
  }

  _handleFilterCounter(filter, option) {
    if (!filter.key || filter.key === 'sort') return;

    if (this.state.enactedFilters.hasOwnProperty(filter.key) && option.hasOwnProperty('default')) { // Remove
      this.setState({ enactedFilters: removeFromObject(this.state.enactedFilters, filter.key) });
    } else if (!this.state.enactedFilters.hasOwnProperty(filter.key) && !option.hasOwnProperty('default')) { // Add
      this.setState({ enactedFilters: { ...this.state.enactedFilters, [filter.key]: true } });
    }
  }

  _renderMobileFiltersToggle() {
    const enactedFiltersLength = Object.keys(this.state.enactedFilters).length;

    return (
      <div className={styles.filtersToggle}>
        <button
          className={`${buttonStyles.blank} ${styles.filtersButton}`}
          onClick={() => this.setState({ open: !this.state.open })}
          type="button"
        >
          Filters
          {enactedFiltersLength > 0
          && (
            <span className={styles.filtersToggleCount}>
              <span className={enactedFiltersLength === 1 ? styles.marginLeftOnOne : ''}>{enactedFiltersLength}</span>
            </span>
          )}
        </button>
      </div>
    );
  }

  render() {
    const { filters } = this.props;

    return (
      <div className={styles.wrapper}>
        {this._renderMobileFiltersToggle()}
        <div className={`${styles.filterGroup} ${this.state.open ? '' : styles.filterGroupClosed}`}>
          {filters.map((filter, i) => (
            <span key={i} className={styles.filter}>
              <SimpleSelect
                onSelection={(option) => this._handleSelection(filter, option)}
                options={filter.options}
              />
            </span>
          ))}
        </div>
      </div>
    );
  }
}

Filters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelection: PropTypes.func,
};

Filters.defaultProps = { onSelection: () => {} };

export default Filters;
