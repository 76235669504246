import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import utilStyles from '../../../styles/global_ui/util.css';

// Purely presentation clone of the draggable component for DragOverlay.
// eslint-disable-next-line react/display-name
export const DragItem = forwardRef(({ ItemComponent, ...props }, ref) => <ItemComponent {...props} ref={ref} />);

const ItemsList = ({ classNames, children }) => (
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  classNames && classNames.hasOwnProperty('container')
    ? <div className={classNames.container}>{children}</div>
    : children
);

export const SortableItem = ({ itemStyles, hasDragHandle, isActive, isDisabled, item, ItemComponent, itemIndex, itemProps }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: item.id,
    ...(isDisabled ? { disabled: true } : {}),
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(isActive ? { opacity: '50%' } : {}),
  };

  return hasDragHandle
    ? (
      <div ref={setNodeRef} className={itemStyles} style={style}>
        <ItemComponent attributes={attributes} item={item} itemIndex={itemIndex} listeners={listeners} {...itemProps} />
      </div>
      )
    : (
      <div ref={setNodeRef} className={itemStyles} style={style} {...attributes} {...listeners}>
        <ItemComponent item={item} itemIndex={itemIndex} {...itemProps} />
      </div>
      );
};

// SortableContext's "items" prop must corresponds to unique identifiers for each list item (i.e. an array of ids), NOT the item objects themselves.
// SortableItems's "items" prop id values should also be unique and non-zero. Providing zero as an id value will fix the first item to the top of the list.
// https://github.com/clauderic/dnd-kit/discussions/357
const DragAndDropList = ({ classNames, dragEndCallback, hasDragHandle, items, ItemComponent, sortingStrategy, itemProps }) => {
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === 0 || over.id === 0) console.warn("A sortable list item with an ID of 0 detected. This is likely a result of mapping a sortable item's ID key to an index of 0.");

    if (active.id !== over.id) {
      const oldIdx = items.findIndex((el) => el.id === active.id);
      const newIdx = items.findIndex((el) => el.id === over.id);

      dragEndCallback ? dragEndCallback(arrayMove(items, oldIdx, newIdx)) : arrayMove(items, oldIdx, newIdx);
    }

    setActiveId(null);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={(e) => handleDragEnd(e)}
      onDragStart={(e) => handleDragStart(e)}
      sensors={sensors}
    >
      <SortableContext
        items={items.map((item) => item.id)}
        strategy={sortingStrategy}
      >
        <ItemsList classNames={classNames}>
          {items.map((item, i) => (
            <SortableItem
              key={item.id}
              ItemComponent={ItemComponent}
              hasDragHandle={hasDragHandle}
              isActive={activeId === item.id}
              item={item}
              itemIndex={i}
              itemProps={itemProps}
              itemStyles={classNames.item}
            />
          ))}
        </ItemsList>
      </SortableContext>
      <DragOverlay className={utilStyles.boxShadow}>
        {activeId && <DragItem ItemComponent={ItemComponent} item={items[items.findIndex((el) => el.id === activeId)]} itemIndex={0} {...itemProps} />}
      </DragOverlay>
    </DndContext>
  );
};

export default DragAndDropList;

DragAndDropList.propTypes = {
  ItemComponent: PropTypes.func.isRequired,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    item: PropTypes.string,
  }),
  dragEndCallback: PropTypes.func, // Consumes the newly ordered array.
  hasDragHandle: PropTypes.bool,
  itemProps: PropTypes.object, // Will depend on ItemComponent. Example: Functions to remove/update list item.
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired })).isRequired,
  sortingStrategy: PropTypes.func,
};

DragAndDropList.defaultProps = {
  dragEndCallback: null,
  classNames: {},
  hasDragHandle: false,
  itemProps: {},
  sortingStrategy: verticalListSortingStrategy,
};
