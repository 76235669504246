import React from 'react';
import PropTypes from 'prop-types';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

const CategoryBadgeLabel = ({ categoryConfig }) => (
  <div className={layout.flexCenterItems}>
    <div className={utilStyles[categoryConfig.colorClass]} style={{ borderRadius: 2, height: 10, width: 10 }} />
    <span className={`${typography.bodyS} ${layout.marginLeft5}`}>
      {categoryConfig.title}
    </span>
  </div>
);

CategoryBadgeLabel.propTypes = {
  categoryConfig: PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default CategoryBadgeLabel;
