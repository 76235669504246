/**
 * Converts Draftster output into Hackster's JSON model.
 */

import parseHTML from './parsers/submit';

function processCE(block) {
  return parseHTML(block.html)
    .then((json) => Promise.resolve({ type: 'CE', json }))
    .catch((err) => Promise.reject(err));
}

function processCarousel(block, retainUrls) {
  const images = block.images
    .filter((image) => image.id !== undefined && image.id !== null)
    .map((image) => {
      const { id, figcaption, uuid, name, width, height, url } = image;

      return {
        id,
        figcaption,
        uuid,
        name,
        height,
        width,
        url: retainUrls ? url : null,
      };
    });

  return { type: 'Carousel', images };
}

function processImageLink(block, retainUrls) {
  const { id, figcaption, uuid, name, link, width, height } = block.image;

  return {
    type: 'ImageLink',
    image: {
      id,
      figcaption,
      uuid,
      name,
      link,
      width,
      height,
      // TODO: should this be block.image.url?
      // eslint-disable-next-line no-undef
      url: retainUrls ? image.url : null,
    },
  };
}

function processVideo(block) {
  const { id, figcaption, embed, service, type, height, width } = block.video;

  return { type: 'Video', video: [{ id, figcaption, embed, service, type, height, width }] };
}

export default function convertToJSONModel(blocks, retainUrls = false) {
  const promises = blocks.reduce((acc, block, index) => {
    switch (block.type) {
      case 'CE':
        acc.push(Promise.resolve(processCE(block)));
        break;

      case 'Carousel':
        // TODO: Refactor this. Disabling for linter clean-up
        // eslint-disable-next-line no-case-declarations
        const Carousel = processCarousel(block, retainUrls);
        if (Carousel.images.length) acc.push(Promise.resolve(Carousel));
        break;

      case 'ImageLink':
        acc.push(Promise.resolve(processImageLink(block, retainUrls)));
        break;

      case 'Video':
        acc.push(Promise.resolve(processVideo(block)));
        break;

      default:
        acc.push(Promise.resolve(block));
        break;
    }

    return acc;
  }, []);

  return Promise.all(promises);
}
