export const CHALLENGE_EXTRA_FIELDS_INPUT_TYPES = {
  IMAGE: 'image',
  MULTI: 'multi',
  NUMBER: 'number',
  RADIO: 'radio',
  SELECT: 'select',
  TEXT: 'input',
  TEXTAREA: 'textarea',
};

export const getTypeForFormSelect = (t) => t === 'SELECT' || t === 'select' ? 'default' : t;

export const isInputTypeWithOptions = (t) => t === 'SELECT' || t === 'MULTI' || t === 'RADIO';

export const isInputTypeSelect = (t) => t === 'SELECT' || t === 'MULTI';
export const isInputTypeRadio = (t) => t === 'RADIO';

export const resolveInputType = (t) => CHALLENGE_EXTRA_FIELDS_INPUT_TYPES[t] || 'textarea';
