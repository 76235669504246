import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionsSection from '../../../client/form_components/templates/ActionsSection';
import CTAForm from './cta_form';
import getConfigByType from './config';

import errorHandler from '../../../services/error_handler';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './home_sections.css';

class EditCTAView extends Component {
  constructor(props) {
    super(props);

    this.state = { isBusy: false };

    // Refs.
    this.ctaForm;
  }

  _addCTASection() {
    if (!this.ctaForm) return errorHandler('ctaForm ref does not exist!');

    const isValid = this.ctaForm.validate();

    if (isValid) {
      const ctaConfig = getConfigByType('cta');

      this.props.updateSection({
        index: this.props.ctaSection.index,
        data: ctaConfig.toSection(this.ctaForm.getFormData()),
      });
      this.props.toggleView('table');
    }
  }

  render() {
    const { title, meta, value } = this.props.ctaSection.data;

    return (
      <div className={styles.root}>
        <h2 className={`${typography.h2} ${layout.marginBottom30}`}>
          {`Edit ${title}`}
        </h2>

        <CTAForm
          ref={(el) => this.ctaForm = el}
          initData={{
            image: {
              id: value,
              name: meta.image_name,
              url: meta.image_url,
            },
            link: meta.link,
            name: title,
          }}
          propagateStatus={(bool) => this.setState({ isBusy: bool })}
        />

        <div>
          <ActionsSection
            isBusy={this.state.isBusy}
            primaryBtnConfig={{ onClick: () => this._addCTASection(), text: 'Save changes' }}
            secondaryBtnConfig={{ onClick: () => this.props.toggleView('table') }}
          />
        </div>
      </div>
    );
  }
}

EditCTAView.propTypes = {
  ctaSection: PropTypes.shape({
    index: PropTypes.number,
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      meta: PropTypes.shape({
        image_name: PropTypes.string,
        image_url: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  toggleView: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
};

EditCTAView.defaultProps = {};

export default EditCTAView;
