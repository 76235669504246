import React, { Component } from 'react';

import Dialog from '../../reusable_components/Dialog';
import { SUMMON_GLOBAL_DIALOG } from '../../../utility/dispatchers';

const INIT_STATE = {
  open: false,
  getChildren: null,
  dialogProps: {},
};

class GlobalDialog extends Component {
  constructor(props) {
    super(props);

    this.state = INIT_STATE;

    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleSummon = this.handleSummon.bind(this);
  }

  componentDidMount() {
    window.addEventListener(SUMMON_GLOBAL_DIALOG, this.handleSummon);
  }

  componentWillUnmount() {
    window.removeEventListener(SUMMON_GLOBAL_DIALOG, this.handleSummon);
  }

  handleDismiss() {
    this.setState(INIT_STATE);
  }

  handleSummon(e) {
    const { dialogProps, getChildren } = e.detail;

    this.setState({
      open: true,
      dialogProps: dialogProps || {},
      getChildren,
    });
  }

  render() {
    const { open, dialogProps, getChildren } = this.state;

    return (
      <Dialog
        {...dialogProps}
        dismiss={this.handleDismiss}
        open={open}
      >
        {open && typeof getChildren === 'function' && getChildren(this.handleDismiss)}
      </Dialog>
    );
  }
}

export default GlobalDialog;
