const KNOWN_PARAMS = {
  hitsPerPage: 'hitsPerPage',
  length: 'length',
  offset: 'offset',
  page: 'page',
};

export default function buildFirstLevelAlgoliaParams(queryMap, extendedParams = {}) {
  const keys = Object.keys(queryMap);
  if (!keys.length) return {};

  const PARAMS = { ...KNOWN_PARAMS, ...extendedParams };

  return keys.reduce((acc, key) => {
    if (PARAMS[key]) {
      acc[key] = queryMap[key];
    }

    return acc;
  }, {});
}
