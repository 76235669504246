import React from 'react';
import PropTypes from 'prop-types';

// HOC
import adAnalyticsTracker from '../wrappers/adAnalyticsTracker';

import SimpleAd, { getPlaceholderView } from '../simple_ad';
import GenericPortal from '../../portals/GenericPortal';

import styles from './project_page.css';

export const PORTAL_ID = 'project_page_simple_ad_portal';

const getSimpleAd = ({ ad, initialized, isPortal, onClick }) => (
  <SimpleAd
    ad={ad}
    classList={{ root: isPortal ? styles.portal : '' }}
    initialized={initialized}
    onClick={onClick}
  />
);

const ProjectPageSimpleAd = ({ ad, forwardedRef, initialized, onClick }) => {
  const view = (isPortal = false) => initialized && ad !== null ? getSimpleAd({ ad, initialized, isPortal, onClick }) : getPlaceholderView();

  return (
    <div ref={forwardedRef}>
      {view()}
      <GenericPortal id={PORTAL_ID}>
        {view(true)}

        <div className={`project-section-break ${styles.portal}`} />
      </GenericPortal>
    </div>
  );
};

ProjectPageSimpleAd.displayName = 'ProjectPageSimpleAd';
ProjectPageSimpleAd.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  initialized: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ProjectPageSimpleAd.defaultTypes = { ad: null };

export default adAnalyticsTracker(ProjectPageSimpleAd);
