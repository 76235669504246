import { Request } from 'superagent';
import errorHandler from '../error_handler';
import { doesWindowExistWithProperty, reloadDocumentLocation, windowLocationPort, windowLocationProtocol } from '../window';
import { summonLoginPanel } from '../../utility/dispatchers';
import { getInObj } from '../../utility/accessors';

import AccessTokens from './AccessTokens';

function cloneRequest(request) {
  const clone = new Request(request.method, request.url);
  clone._query = request._query;
  clone.header = request.header;
  clone._header = request._header;
  clone._data = request._data;

  return clone;
}

class OAuthServiceConstructor {
  constructor() {
    this.initialized = false;
    this.cachedApiPath = null;
    this.callbackQueueForNonPromises = [];
    this.requestQueue = [];
    this.requestQueueWorking = false;
    this.userIdFromDom = null;

    this.Tokens = new AccessTokens();

    // Bound to hold reference when used indirectly (e.g. Hster.Services.OAuthService)
    this.apiRequest = this.apiRequest.bind(this);
    this.clearApiTokens = this.clearApiTokens.bind(this);
    this.getApiPath = this.getApiPath.bind(this);
    this.getApiToken = this.getApiToken.bind(this);
    this.processApiRequestError = this.processApiRequestError.bind(this);
  }

  /**
   * Private Methods
   */
  _initialize() {
    this.initialized = true;
    this.userIdFromDom = this._fetchUserIdFromDOMBody();

    if (!this.Tokens.initialized) {
      this.Tokens._initialize();
    }
  }

  _cacheRequest(request, resolve, reject, resolver, tokenType, attempts = 0) {
    this.requestQueue.push({ request, resolve, reject, resolver, tokenType, attempts });
  }

  _createRequestResolver(request, userOnly, returnResponseBody, showSignInDialog) {
    return (token, reCallback, resolve, reject, attempts = 0) => cloneRequest(request)
      .query({ bearer_token: token })
      .then((res) => resolve(returnResponseBody ? { ...res.body, token } : res))
      .catch((err) => {
        const withinErrorRange = (err && err.status && (err.status >= 400 && err.status <= 500));

        if (!this.userIdFromDom && attempts >= 1 && withinErrorRange) {
          return resolve({});
        } else if (this.userIdFromDom && attempts >= 1 && withinErrorRange) {
          return reject(err);
        } else if (attempts >= 2) {
          return reject(err);
        }

        this._recoverFailedRequest(request, resolve, reject, reCallback, attempts, userOnly, showSignInDialog);
      });
  }

  _fetchTokens(userOnly, forceFetch = false) {
    return this.Tokens.fetchTokens(userOnly, forceFetch);
  }

  _fetchUserIdFromDOMBody() {
    // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
    // eslint-disable-next-line @stylistic/no-mixed-operators
    return document && document.body.getAttribute('data-user-signed-in') || null;
  }

  _handleTokens(tokens, userOnly, showSignInDialog) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    if (tokens.hasOwnProperty('user_token')) {
      return this._resolveRequestQueue(tokens);
    } else {
      return userOnly && showSignInDialog
        ? this._summonLoginPanel(userOnly)
        : userOnly
          ? this._handleUnresolvedUserRequests()
          : this._resolveRequestsForTokenType(tokens.client_token, 'client');
    }
  }

  _handleUnresolvedUserRequests() {
    // Try again if theres a userId on the DOM body.
    if (this.userIdFromDom) {
      this._resolveRequestsForTokenType(null, 'user');
    } else {
      this._resolveUserPromisesForUnresolvedRequests();
    }
  }

  _recoverFailedRequest(request, resolve, reject, resolver, attempts, userOnly, showSignInDialog) {
    this.Tokens.deleteTokens();
    this._cacheRequest(request, resolve, reject, resolver, this.Tokens.getTokenType(userOnly), attempts += 1);

    return this._fetchTokens(userOnly)
      .then((tokens) => this._handleTokens(tokens, userOnly, showSignInDialog))
      .catch((err) => reject(err));
  }

  _reFetchTokensAfterLogin(userOnly, reLoggedUserId) {
    this._initialize();

    if (!this.userIdFromDom || parseInt(this.userIdFromDom, 10) !== parseInt(reLoggedUserId, 10)) {
      return reloadDocumentLocation();
    }

    return this._fetchTokens(userOnly, true)
      .catch((err) => errorHandler('_reFetchTokensAfterLogin Error', err));
  }

  _resolveCallbacksForGetApiToken(tokens) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    const hasUserToken = tokens.hasOwnProperty('user_token');
    const token = hasUserToken ? tokens.user_token : (tokens.client_token || null);

    this.callbackQueueForNonPromises = this.callbackQueueForNonPromises.filter((callback) => {
      if ((callback.userOnly && hasUserToken) || (callback.userOnly === false)) {
        callback.callback(token);

        return false;
      } else if (callback.attempts < 2) {
        this.getApiToken.call(this, callback.callback, callback.userOnly, callback.attempts += 1);

        return false;
      } else {
        callback.callback(null);

        return false;
      }
    });
  }

  _resolveRequestQueue(tokens) {
    if (this.requestQueueWorking) return;

    this.requestQueueWorking = true;
    this.requestQueue = this.requestQueue.filter((request) => {
      request.resolver.call(this, tokens.user_token, request.resolver, request.resolve, request.reject, request.attempts);

      return false;
    });

    this.requestQueueWorking = false;
  }

  _resolveRequestsForTokenType(token, tokenType) {
    this.requestQueue = this.requestQueue.filter((request) => {
      if (request.tokenType === tokenType) {
        request.resolver.call(this, token, request.resolver, request.resolve, request.reject, request.attempts);

        return false;
      }

      return true;
    });
  }

  _resolveUserPromisesForUnresolvedRequests() {
    this.requestQueue = this.requestQueue.filter((request) => {
      if (request.tokenType === 'user') {
        request.resolve({ unresolved: true });

        return false;
      }

      return true;
    });
  }

  _summonLoginPanel(userOnly) {
    summonLoginPanel({ detail: { callback: this._reFetchTokensAfterLogin.bind(this, userOnly), redirect_to: null } });
    this._resolveUserPromisesForUnresolvedRequests();
  }

  /**
   * Public Methods
   */
  apiRequest(request, userOnly = false, returnResponseBody = true, showSignInDialog = true) {
    return new Promise((resolve, reject) => {
      if (!this.initialized) this._initialize();

      const tokenType = this.Tokens.getTokenType(userOnly);
      const resolver = this._createRequestResolver(request, userOnly, returnResponseBody, showSignInDialog);

      this._cacheRequest(request, resolve, reject, resolver, tokenType, 0);

      return this._fetchTokens(userOnly)
        .then((tokens) => this._handleTokens(tokens, userOnly, showSignInDialog))
        .catch((err) => reject(err));
    });
  }

  clearApiTokens() {
    this.Tokens.deleteTokens();
  }

  getApiPath() {
    if (typeof this.cachedApiPath === 'string') return this.cachedApiPath;

    if (doesWindowExistWithProperty('location')) {
      const protocol = windowLocationProtocol.get();
      const port = windowLocationPort.get();
      const element = document.getElementById('api-uri');
      const apiPath = element && element.content && port && port.length
        ? `${protocol}//${element.content}:${port}`
        : element && element.content
          ? `${protocol}//${element.content}`
          : errorHandler('OAuthService getApiPath expects a meta tag with an id of api-uri');

      if (typeof apiPath === 'string') this.cachedApiPath = apiPath;

      return apiPath;
    }
  }

  getApiToken(callback, userOnly = false, attempts = 0) {
    this.callbackQueueForNonPromises.push({ callback, userOnly, attempts });

    return this._fetchTokens(userOnly)
      .then((tokens) => this._resolveCallbacksForGetApiToken(tokens))
      .catch((err) => this._resolveCallbacksForGetApiToken({}));
  }

  processApiRequestError(error = {}) {
    return getInObj(['response', 'body', 'errors'], error) || error;
  }
}

const OAuthService = new OAuthServiceConstructor();
export default OAuthService;
