export const MD_SETTINGS = {
  bold: {
    appendedChars: '**',
    prependedChars: '**',
  },
  bullet_list: {
    appendedChars: '',
    prependedChars: '- ',
    formatBlock: true,
  },
  code_fence: {
    appendedChars: '\n```',
    prependedChars: '```\n',
  },
  code_inline: {
    appendedChars: '`',
    prependedChars: '`',
  },
  italic: {
    appendedChars: '_',
    prependedChars: '_',
  },
  quote: {
    appendedChars: '',
    prependedChars: '> ',
    formatBlock: true,
  },
};

export const REGEX = {
  linebreak: /\r?\n/,
  nonWhitespace: /\S/,
  leadingWhitespace: /^\s+/,
  trailingWhitespace: /\s+$/,
};
