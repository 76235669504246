import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../icon';
import { getCategoryTypeForHeader } from './helpers/templates';

import typography from '../../../../../styles/global_ui/typography.css';
import styles from './prize_editor.css';

const SortablePrizeRow = ({ attributes, getTitleForCategory, item, listeners }) => (
  <div className={styles.sortableCategory}>
    <div className={styles.categoryHeader}>
      <div className={styles.categoryHeaderText}>
        <h4 className={`${typography.h4}`}>
          {getTitleForCategory(item)}
          <span> </span>
          <span className={typography.bodyS}>{getCategoryTypeForHeader(item.type, item.name)}</span>
        </h4>
      </div>

      <span {...attributes} {...listeners}>
        <Icon className={styles.dragIcon} name="hamburger" />
      </span>
    </div>
  </div>
);

SortablePrizeRow.propTypes = {
  attributes: PropTypes.object,
  getTitleForCategory: PropTypes.func.isRequired,
  item: PropTypes.object,
  listeners: PropTypes.object,
};

SortablePrizeRow.defaultProps = {
  attributes: {},
  listeners: {},
};

export default SortablePrizeRow;
