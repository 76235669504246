export const adFAQLink = 'http://help.hackster.io/knowledgebase/what-are-these-ads';

export const NOOP_HREF = 'javascript:void(0);';

export const socialLinks = {
  facebook: 'https://www.facebook.com/hacksterio',
  instagram: 'https://www.instagram.com/hacksterio',
  linkedin: 'https://www.linkedin.com/company/hacksterio',
  newsletter: '/newsletter/sign_up',
  twitter: 'https://twitter.com/Hacksterio',
  youtube: 'https://www.youtube.com/hacksterio',
};
