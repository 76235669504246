import {MEDIA_ID_REGEX} from './regex';

const SERVICES = {
  'channel9': {
    embed: id => `https://channel9.msdn.com/${id}/player`,
    regexp: MEDIA_ID_REGEX['channel9'],
    regexpIndex: 1
  },
  'gfycat': {
    embed: id => `https://gfycat.com/ifr/${id}`,
    regexp: MEDIA_ID_REGEX['gfycat'],
    regexpIndex: 1
  },
  'giphy': {
    embed: id => `https://giphy.com/embed/${id}`,
    regexp: MEDIA_ID_REGEX['giphy'],
    regexpIndex: 1
  },
  'instagram': {
    embed: id => `https://instagram.com/p/${id}/embed/`,
    regexp: MEDIA_ID_REGEX['instagram'],
    regexpIndex: 1
  },
  'kickstarter': {
    embed: id => `https://www.kickstarter.com/projects/${id}/widget/video.html`,
    regexp: MEDIA_ID_REGEX['kickstarter'],
    regexpIndex: 1
  },
  'mp4': {
    embed: id => id,
    regexp: MEDIA_ID_REGEX['mp4'],
    regexpIndex: 0
  },
  'vimeo': {
    embed: id => `https://player.vimeo.com/video/${id}`,
    regexp: MEDIA_ID_REGEX['vimeo'],
    regexpIndex: 1
  },
  'vine': {
    embed: id => `https://vine.co/v/${id}/embed/simple`,
    regexp: MEDIA_ID_REGEX['vine'],
    regexpIndex: 1
  },
  'ustream': {
    embed: id => `http://www.ustream.tv/embed/${id}?html5ui`,
    regexp: MEDIA_ID_REGEX['ustream'],
    regexpIndex: 1
  },
  'youtube': {
    embed: id => `https://www.youtube.com/embed/${id}`,
    regexp: MEDIA_ID_REGEX['youtube'],
    regexpIndex: 1
  }
};

const getServiceData = url => {
  const urlToMatch = url.replace(/youtu\.be/, 'youtube');
  const validService = urlToMatch.match(/(channel9|instagram|gfycat|giphy|kickstarter|mp4|vimeo|vine|ustream|youtube)/);

  if (!validService) return null;

  const service = validService[0];
  const serviceConfig = SERVICES[service];

  return {service, serviceConfig};
};

export function getVideoData(url) {
  const serviceData = getServiceData(url);

  if (!serviceData) return null;

  const {service, serviceConfig} = serviceData;
  const validServiceRegexp = url.match(serviceConfig.regexp);

  if (!validServiceRegexp) return null;

  const id = validServiceRegexp[serviceConfig.regexpIndex];
  const embed = serviceConfig.embed(id);

  return {id, embed, service, type: 'iframe'};
}

export function isVideoUrlValid(url) {
  const serviceData = getServiceData(url);

  if (!serviceData) return false;

  const validServiceRegexp = url.match(serviceData.serviceConfig.regexp);
  return validServiceRegexp && validServiceRegexp.length > 0;
}