/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '../../buttons/base';
import Icon from '../../icon';

import { doesStringsMatch } from '../../../utility/predicates';
import generateRandomKey from '../../../utility/generateRandomKey';
import typography from '../../../styles/global_ui/typography.css';

/**
 * Note:
 * Having the mouseMove event on the root element and the disable style on its child is important for the
 * cursor disabling to work correctly. Cursor disabling is when a user is selecting a option via keyDown and the mouse cursor
 * is within the menu. Its to prevent the mouseOver event from firing and causing the highlighted option to jump because of it.
 */
class SelectMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disableCursor: false,
      highlight: 0,
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onOptionMouseOver = this.onOptionMouseOver.bind(this);
    this.onSelect = this.onSelect.bind(this);

    // Ref
    this._menu;
    // uuid
    this._uuid = (props.uuid || generateRandomKey());
    // timeout
    this._mountTimeout;
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
    this._handleMountTimeout();
    this._scrollToView();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  /**
   * Initializers
   */
  _scrollToView() {
    if (!this.props.config.scrollToView) return;
    if (this._menu) {
      const menuDims = this._menu.getBoundingClientRect();
      const buffer = this.props.config.scrollBuffer;

      if (menuDims.bottom && (window.innerHeight < (menuDims.bottom + buffer))) {
        window.scrollBy(0, ((menuDims.bottom + buffer) - window.innerHeight));
      }
    }
  }

  /**
   * Methods
   */
  onKeyDown(e) {
    switch (e.keyCode) {
      case 13: // ENTER
        e.preventDefault();

        return this._selectOptByHighlightedIndex();

      case 38: // ARROW UP
        e.preventDefault();

        return this._arrowToOpt(-1);

      case 40: // ARROW DOWN
        e.preventDefault();
        if (typeof this._mountTimeout !== 'undefined') return;

        return this._arrowToOpt(1);

      default:
        return;
    }
  }

  onMouseMove() {
    if (this.state.disableCursor) this.setState({ disableCursor: false });
  }

  onOptionMouseOver(index) {
    if (typeof this._mountTimeout !== 'undefined') return;
    this.setState({ highlight: index });
  }

  onSelect(opt, isSelected) {
    this.props.onSelect(opt, isSelected);
  }

  /**
   * Helpers
   */
  _arrowToOpt(dir) {
    if (this.props.isBusy || !this.props.options.length) return;

    // Kill mouse events until the mouse moves.
    this._disableCursor();

    const toIndex = this.state.highlight + dir;

    if (dir > 0) { // Arrow down
      this.setState({ highlight: toIndex <= this.props.options.length - 1 ? toIndex : 0 }, () => this._scrollToOption());
    } else { // Arrow up
      this.setState({ highlight: toIndex < 0 ? this.props.options.length - 1 : toIndex }, () => this._scrollToOption());
    }
  }

  _disableCursor() {
    if (this.state.disableCursor === false) this.setState({ disableCursor: true });
  }

  /**
   * This lets us ignore events for a brief time, like keydown events triggered by the parent element.
   */
  _handleMountTimeout() {
    this._mountTimeout = window.setTimeout(() => {
      window.clearTimeout(this._mountTimeout);
      this._mountTimeout = undefined;
    }, 30);
  }

  /**
   * On arrow navigation, we need to scroll the view to the top or bottom of the highlighted option.
   */
  _scrollToOption() {
    const option = document.querySelector(`[data-select-opt="${this._uuid}=${this.state.highlight}"]`);

    if (option) {
      const optDims = option.getBoundingClientRect();
      const menuDims = this._menu.getBoundingClientRect();

      if (optDims.top < menuDims.top) {
        // Arrow up
        this._menu.scrollTop = this._menu.scrollTop - (menuDims.top - optDims.top);
      } else if (optDims.bottom > menuDims.bottom) {
        // Arrow down
        this._menu.scrollTop = this._menu.scrollTop + (optDims.bottom - menuDims.bottom);
      }
    }
  }

  _selectOptByHighlightedIndex() {
    if (!this.props.options.length || this.props.isBusy) return;
    const opt = this.props.options.find((o, i) => i === this.state.highlight);
    const isSelected = this.props.selected.find((s) => s.value === opt.value) !== undefined;

    if (!opt.hasOwnProperty('disabled') || (opt.hasOwnProperty('disabled') && opt.disabled === false)) {
      this.onSelect(opt, isSelected);
    }
  }

  /**
   * Views
   */
  _getList() {
    if (this.props.isBusy) return this._getLoaderView();

    return this.props.options.length > 0 ? this._getOptsListView() : this._getNoResultsView();
  }

  // TODO: Disabled views. No cursor on hover, different color on hover?
  //       Implement, when/if needed.
  _getOptsListView() {
    const styles = this.props.styles;

    return this.props.options.map((o, i) => {
      const isSelected = this.props.selected.find((s) => s.value === o.value) !== undefined;
      const optionClass = (typeof this.props.templates.option === 'function') ? styles.optionCustom : styles.option;

      return (
        <div
          key={i}
          className={`${optionClass} ${this.state.highlight === i ? styles.highlight : ''}`}
          data-select-opt={`${this._uuid}=${i}`}
          onClick={() => {
            if (!o.hasOwnProperty('disabled') || (o.hasOwnProperty('disabled') && o.disabled === false)) {
              this.onSelect(o, isSelected);
            } else {
              // Ignore the inputs onBlur event (i.e. do nothing when a disabled option is clicked)
              this.props.clearBlurTimeout();
            }
          }}
          onMouseOver={() => this.onOptionMouseOver(i)}
        >
          {this._getOptInnerView(o)}
          {isSelected && <Icon name="checkmark" />}
        </div>
      );
    });
  }

  _getOptInnerView(opt) {
    return (typeof this.props.templates.option === 'function') ? this.props.templates.option(opt) : this._getDefaultOptView(opt);
  }

  _getDefaultOptView(opt) {
    const styles = this.props.styles;

    return (<span className={styles.label}>{this._getDefaultOptLabelView(opt)}</span>);
  }

  _getDefaultOptLabelView(opt) {
    if (opt.hasOwnProperty('labelView')) {
      return (typeof opt.labelView === 'function') ? opt.labelView() : opt.labelView;
    } else {
      return opt.label;
    }
  }

  _getLoaderView() {
    const styles = this.props.styles;

    return (
      <div className={styles.optionNoResult}>Loading...</div>
    );
  }

  _getNoResultsView() {
    const styles = this.props.styles;

    return (
      <div className={`${styles.optionNoResult} ${typography.bold}`}>
        No results found
        {this._isCurrentValueAValidCreatableOpt() && this._getCreatableButtonView()}
      </div>
    );
  }

  /**
   * Note: In the future if we need to refine how we allow options to be created, we can do it here.
   * Right now we do a loose toLowerCase equality check on every selected items (since we see this view only when options are empty),
   * label and value property against the inputs value. A configurable creatableOpts prop would be best.
   */
  _isCurrentValueAValidCreatableOpt() {
    if (this.props.creatable === false) return false;
    const value = this.props.currentValue;

    return (value.length > 0 && (this.props.selected.find((s) => (doesStringsMatch(s.label, value) || doesStringsMatch(s.value, value))) === undefined));
  }

  /**
   * When overriding the actionable, the onClick argument will allow you to have the select create a option as normal.
   * If you do not want to create a option, ignore the argument and handle the click event elsewhere.
   */
  _getCreatableButtonView() {
    if (typeof this.props.templates.actionable === 'function') {
      return this.props.templates.actionable({ disable: this.props.isBusy, inputValue: this.props.currentValue, onClick: this.onActionableClick });
    }

    const btnText = () => (this.props.currentValue.length > 0 && this.props.currentValue.length < 30)
      ? `Create ${this.props.currentValue}`
      : 'Create option';

    return (
      <Button disable={this.props.isBusy} onClick={this.props.onActionableClick} size="sm">
        {(typeof this.props.templates.actionableText === 'function')
          ? this.props.templates.actionableText({ value: this.props.currentValue })
          : btnText()}
      </Button>
    );
  }

  render() {
    const styles = this.props.styles;

    return (
      <div
        ref={(c) => this._menu = c}
        className={styles.root}
        onMouseMove={this.onMouseMove}
      >
        <div className={`${styles.inner} ${this.state.disableCursor ? styles.disableMouseEvents : ''}`}>
          {this._getList()}
        </div>
      </div>
    );
  }
}

SelectMenu.propTypes = {
  clearBlurTimeout: PropTypes.func.isRequired,
  config: PropTypes.shape({
    scrollBuffer: PropTypes.number.isRequired,
    scrollToView: PropTypes.bool.isRequired,
  }).isRequired,
  creatable: PropTypes.bool.isRequired,
  currentValue: PropTypes.string.isRequired,
  isBusy: PropTypes.bool.isRequired,
  onActionableClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    labelView: PropTypes.any, // Escape hatch when we want customize a option's label.
    value: PropTypes.any,
  })),
  selected: PropTypes.array.isRequired,
  styles: PropTypes.object.isRequired,
  templates: PropTypes.shape({
    actionable: PropTypes.func,
    actionableText: PropTypes.func,
    option: PropTypes.func,
  }),
};

SelectMenu.defaultProps = {
  onActionableClick: () => {},
  options: [],
  templates: {
    actionable: null,
    actionableText: null,
    option: null,
  },
};

export default SelectMenu;
