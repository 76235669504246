import React from 'react';

import { TextPlaceholder } from './templates';

import colors from '../../../../../styles/global_ui/colors.css';
import layout from '../../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../../styles/global_ui/util.css';

const DateRangePlaceholder = () => (
  <div
    className={`${utilStyles.bgWhite} ${layout.flexCenterItems} ${layout.flexJustifySpaceBetween} ${layout.flexColumn} ${utilStyles.borderRadius} ${layout.padding15}`}
    style={{ height: 78, width: 66 }}
  >
    <TextPlaceholder backgroundColor={colors['v-pebble']} height={22} width={15} />
    <TextPlaceholder backgroundColor={colors['v-pebble']} height={16} width="100%" />
  </div>
);

export default DateRangePlaceholder;
