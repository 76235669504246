import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MediaElement from '../../image/MediaElement';
import LazyComponent from '../../wrappers/lazy_component';

import requestTimeout from '../../../utility/requestTimeout';
import { IMG, VIDEO, processURL, getHeight } from '../../image/helpers';
import { windowDPR } from '../../../services/window';

import styles from './lazy_image.css';

// TODO: move to client/image
class LazyImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initStyles: {
        height: props.ratio ? getHeight(props.ratio, props.width) : null,
        width: props.width,
      },
      fadeIn: false,
      format: null,
      src: '',
    };

    this.errorHandler = this.errorHandler.bind(this);
    this.fetchImage = this.fetchImage.bind(this);
    this.loadHandler = this.loadHandler.bind(this);

    this._clearTimeout;
    this._isMounted;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    if (this._clearTimeout) this._clearTimeout();
    this._isMounted = false;
  }

  /**
   * Methods
   */
  errorHandler(err) {
    if (this.state.format === VIDEO) {
      // First, try falling back to img tag
      this._setStateIfMounted({ format: IMG });
    } else {
      this._setStateIfMounted({ fadeIn: false, format: null, src: '' });
    }
  }

  fetchImage() {
    const { fit, forceImg, queryParams, ratio, src, width } = this.props;
    const processed = processURL({
      fit,
      forceImg,
      queryParams: { ...queryParams, dpr: windowDPR() },
      ratio,
      src,
      width,
    });

    this.setState({ fetched: true, format: processed.format, src: processed.src });
  }

  loadHandler() {
    this._clearTimeout = requestTimeout(() => this._revealImage(), 17);
  }

  /**
   * Helpers
   */
  _getStyle() {
    return this.props.setStyle ? this.state.initStyles : {};
  }

  _revealImage() {
    this._setStateIfMounted({ fadeIn: true });
    this._clearTimeout = null;
    this.props.onLoad();
  }

  _setStateIfMounted(state, cb) {
    if (this._isMounted) {
      this.setState(state, cb);
    }
  }

  render() {
    const { alt, classList, className, loadBuffer, onClick, onMouseLeave, onMouseOver } = this.props;

    return (
      <LazyComponent
        className={`${className} ${classList.root} ${styles.root}`}
        horizontalOffset={loadBuffer}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        onReveal={this.fetchImage}
        style={this._getStyle()}
        verticalOffset={loadBuffer}
      >
        {!!this.state.src
        && (
          <MediaElement
            alt={alt}
            className={`${styles.image} ${classList.image} ${this.state.fadeIn ? styles.fadeIn : ''}`}
            format={this.state.format}
            onError={this.errorHandler}
            onLoad={this.loadHandler}
            src={this.state.src}
          />
        )}
      </LazyComponent>
    );
  }
}

LazyImage.propTypes = {
  alt: PropTypes.string,
  captureScroll: PropTypes.bool, // DEPRECATED
  classList: PropTypes.shape({
    image: PropTypes.string,
    wrapper: PropTypes.string,
    root: PropTypes.string,
  }),
  className: PropTypes.string, // Legacy support. use classList.root from now on
  fit: PropTypes.string,
  forceImg: PropTypes.bool,
  loadBuffer: PropTypes.number,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  queryParams: PropTypes.object,
  ratio: PropTypes.string,
  setStyle: PropTypes.bool, // Set inline width/height styles to calculated dimensions
  src: PropTypes.string,
  width: PropTypes.number.isRequired,
};

LazyImage.defaultProps = {
  alt: '',
  classList: {},
  className: '',
  loadBuffer: 0,
  fit: 'min',
  forceImg: false,
  onClick: () => {},
  onLoad: () => {},
  onMouseLeave: () => {},
  onMouseOver: () => {},
  queryParams: {},
  ratio: '',
  setStyle: true,
  src: '',
};

export default LazyImage;
