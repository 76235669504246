// For HMR
if (module.hot) module.hot.accept();

/** Babel polyfills */
require('core-js');
require('regenerator-runtime/runtime');

/** Global CSS */
require('../styles/global.css');

/** Global Base UI styles (Add all files as we create them) */

// layout and typography are base styles that should be able to be
// overriden in other files, so should go first
require('../styles/global_ui/layout.css');
require('../styles/global_ui/typography.css');

require('../styles/global_ui/buttons.css');
require('../styles/global_ui/grid.css');
require('../styles/global_ui/inputs.css');
require('../styles/global_ui/nav-tabbed.css');
require('../styles/global_ui/animation.css');
require('../styles/global_ui/util.css');

/** Library CSS (When adding global libs, you must extend the regex in webpack) */
require('cropperjs/dist/cropper.css');

/** Global Vendor Packages (will be pulled into vendor.js) */
require('expose-loader?exposes=hljs!highlight.js');
require('expose-loader?exposes=lodashDebounce!lodash.debounce');

/** Hackster Namespace */
require('expose-loader?exposes=Hster!../hster');
