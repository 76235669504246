/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { graphQuery, graphMutate } from '../../../requests/graphql';

import errorHandler from '../../../services/error_handler';
import { getErrorMsg } from '../../global_components/messenger/messages';
import { summonGlobalMessenger } from '../../../utility/dispatchers';

import keenService from '../../../services/keen/main';
import {
  getCreateCommentArgs,
  getDeleteCommentArgs,
  getUpdateCommentArgs,
} from '../../../services/keen/events/eventTypeTemplates';

/***********************
  QUERIES
/***********************

/**
 * Get Comments
 */

// Used when the "Show Previous" button is clicked.
const addFetchedCommentsToPost = (records, commentsFromServer, postId) => records.map((record) => {
  if (record.id === postId) {
    // We fetch all comments when the Show Previous is clicked. So, we want to filter out the
    // comments we already have from the list in case the user fetched replies for those current comments.
    const knownIds = record.comments.map((c) => c.id);
    const filteredComments = commentsFromServer.filter((comment) => !knownIds.includes(comment.id));
    record.comments = filteredComments.concat(record.comments);
  }

  return record;
});

export function getComments(post, worker) {
  this.addWorker(worker);

  return graphQuery({ t: 'get_comments_for_feed' }, {
    commentable_id: post.id,
    commentable_type: 'FeedPost',
  })
    .then(({ comments }) => {
      this._safelySetState({
        records: addFetchedCommentsToPost(this.state.records, comments, post.id),
        workers: this.removeFromWorkersState(worker),
      });
    })
    .catch((err) => {
      this._safelySetState({ workers: this.removeFromWorkersState(worker) });
      errorHandler('Discussion getComments: ', err);
      summonGlobalMessenger({ msg: getErrorMsg('retrieving comments'), type: 'error' });
    });
}

/**
 * Get Replies To Comment
 */

// Used when fetching comment replies.
const addFetchedCommentsToCommentChildren = (records, commentsFromServer, parentComment, postId) => records.map((record) => {
  if (record.id === postId) {
    record.comments = addFetchedCommentsToParentComment(record.comments, commentsFromServer, parentComment);
  }

  return record;
});

const addFetchedCommentsToParentComment = (comments, commentsFromServer, parentComment) => comments.map((comment) => {
  if (comment.id === parentComment.id) {
    // commentsFromServer will include any recently added comments. Replacing the whole children tree is safe.
    return {
      ...comment,
      child_ids: commentsFromServer.map((c) => c.id),
      children: commentsFromServer,
    };
  }

  return comment;
});

export function getCommentReplies({ comment, postId, worker }) {
  this.addWorker(worker);

  return graphQuery({ t: 'get_comments' }, { parent_id: comment.id })
    .then(({ comments }) => {
      this._safelySetState({
        records: addFetchedCommentsToCommentChildren(this.state.records, comments, comment, postId),
        workers: this.removeFromWorkersState(worker),
      });
    })
    .catch((err) => {
      this._safelySetState({ workers: this.removeFromWorkersState(worker) });
      errorHandler('Discussion getCommentReplies: ', err);
      summonGlobalMessenger({ msg: getErrorMsg('retrieving comments'), type: 'error' });
    });
}

/***********************
  MUTATIONS
/***********************

/**
 * Create Comment
 */
const addCommentToPost = (records, comment, props) => records.map((record) => {
  if (record.id === comment.commentable_id) {
    record.comments = addCommentToComments(record.comments, comment, props);
  }

  return record;
});

const addCommentToComments = (comments, commentToAdd, props) => {
  if (!commentToAdd.parent_id) return comments.concat(createNewComment(commentToAdd, props));

  return comments.map((comment) => {
    if (comment.id === commentToAdd.parent_id) {
      const children = comment.children || [];

      return { ...comment, children: children.concat(createNewComment(commentToAdd, props)) };
    }

    return comment;
  });
};

const createNewComment = ({ body_with_relations, created_at, id, parent_id, raw_body }, props) => ({
  body_with_relations,
  created_at,
  id,
  raw_body,
  children: [],
  deleted: false,
  edited_at: null,
  liking_user_ids: [],
  parent_id: parent_id || null,
  relations: {},
  user: {
    ...props.currentUser,
    isAdmin: props.currentUser.role === 'admin',
  },
  __meta: { truncate: false },
});

export const fireCreateCommentAnalytics = (comment, commentFromEditor) => (
  keenService.reportEventWithObj(getCreateCommentArgs({
    id: comment.id,
    is_reply: commentFromEditor.hasOwnProperty('parent_id'),
  }))
);

export function createComment({ comment, resolverFn }) {
  this._safelySetState({ isBusy: true });

  return graphMutate({ t: 'create_comment' }, comment)
    .then((res) => _createCommentResolver.call(this, res, comment, resolverFn))
    .catch((err) => {
      this._safelySetState({ isBusy: false });
      errorHandler('Discussion createComment: ', err);
      summonGlobalMessenger({ msg: getErrorMsg('posting your comment'), type: 'error' });
    });
}

function _createCommentResolver({ comment }, commentFromEditor, resolverFn) {
  this._safelySetState({
    isBusy: false,
    records: addCommentToPost(this.state.records, { ...commentFromEditor, ...comment }, this.props),
  }, () => {
    if (typeof resolverFn === 'function') resolverFn();
  });

  fireCreateCommentAnalytics(comment, commentFromEditor);
}

/**
 * Delete Comment
 */
const _deleteCommentInPost = (records, comment) => records.map((record) => {
  if (record.id === comment.commentable_id) {
    const comments = _deleteCommentInComments(record.comments, comment);

    return { ...record, comments };
  }

  return record;
});

const _deleteCommentInComments = (comments, commentToDelete) => !commentToDelete.parent_id
  ? _deleteOrFlagRootComment(comments, commentToDelete)
  : _deleteChildCommentFromComments(comments, commentToDelete);

const _deleteChildCommentFromComments = (comments, commentToDelete) => comments.reduce((acc, comment) => {
  if (comment.id === commentToDelete.parent_id) {
    const children = comment.children.filter((child) => child.id !== commentToDelete.id);

    // If the parent comment is deleted and we just emptied out its children, kill the thread.
    // Otherwise, just update the children normally.
    if (comment.deleted === false || children.length > 0) {
      acc = acc.concat({ ...comment, children });
    }
  } else {
    acc = acc.concat(comment);
  }

  return acc;
}, []);

const _deleteOrFlagRootComment = (comments, commentToDelete) => comments.reduce((acc, comment) => {
  if (comment.id === commentToDelete.id) {
    // If the comment has children, flag it as deleted but perserve its child chain.
    if (comment.children && comment.children.length > 0) {
      acc = acc.concat({ ...comment, deleted: true });
    }
  } else {
    acc = acc.concat(comment);
  }

  return acc;
}, []);

export const fireDeleteCommentAnalytics = (comment) => (
  keenService.reportEventWithObj(getDeleteCommentArgs({
    id: comment.id,
    is_reply: comment.hasOwnProperty('parent_id'),
  }))
);

export function deleteComment({ comment }) {
  const memo = [...this.state.records];

  this._safelySetState({ isBusy: true, records: _deleteCommentInPost(this.state.records, comment) });

  return graphMutate({ t: 'delete_comment' }, { id: comment.id })
    .then(() => _deleteCommentResolver.call(this, comment))
    .catch((err) => {
      this._safelySetState({ isBusy: false, records: memo });
      errorHandler('ContestDiscussion _deleteComment: ', err);
      summonGlobalMessenger({ msg: getErrorMsg('deleting the comment'), type: 'error' });
    });
}

// When markAsSpam resolves, a worker will save a copy of the comment and delete it. To avoid having to poll the worker, we're
// going to go ahead and trust the system and remove the comment from memory here.
export function deleteCommentViaSpam(comment) {
  this._safelySetState({ isBusy: false, records: _deleteCommentInPost(this.state.records, comment) });
  summonGlobalMessenger({ msg: 'Comment successfully deleted.', type: 'success' });
  fireDeleteCommentAnalytics(comment);
}

function _deleteCommentResolver(commentFromEditor) {
  this._safelySetState({ isBusy: false });
  summonGlobalMessenger({ msg: 'Comment successfully deleted.', type: 'success' });
  fireDeleteCommentAnalytics(commentFromEditor);
}

/**
 * Update Comment
 */
const updateCommentInComments = (comments, commentToUpdate) => {
  if (!commentToUpdate.parent_id) {
    return comments.map((comment) => ((comment.id === commentToUpdate.id) ? { ...comment, ...commentToUpdate } : comment));
  }

  return comments.map((comment) => {
    if (comment.id === commentToUpdate.parent_id) {
      comment.children = comment.children.map((child) => (
        (child.id === commentToUpdate.id) ? { ...child, ...commentToUpdate } : child
      ));
    }

    return comment;
  });
};

const updateCommentInPost = (records, comment) => records.map((record) => {
  if (record.id === comment.commentable_id) {
    record.comments = updateCommentInComments(record.comments, comment);
  }

  return record;
});

export const fireUpdatedCommentAnalytics = (comment) => (
  keenService.reportEventWithObj(getUpdateCommentArgs({
    id: comment.id,
    is_reply: comment.hasOwnProperty('parent_id'),
  }))
);

export function updateComment({ comment, resolverFn }) {
  this._safelySetState({ isBusy: true });

  return graphMutate({ t: 'update_comment' }, comment)
    .then((res) => _updateCommentResolver.call(this, res, comment, resolverFn))
    .catch((err) => {
      this._safelySetState({ isBusy: false });
      errorHandler('Discussion updateComment: ', err);
      summonGlobalMessenger({ msg: getErrorMsg('updating the comment'), type: 'error' });
    });
}

function _updateCommentResolver({ comment }, commentFromEditor, resolverFn) {
  this._safelySetState({
    isBusy: false,
    records: updateCommentInPost(this.state.records, {
      ...comment,
      commentable_id: commentFromEditor.commentable_id,
      parent_id: commentFromEditor.parent_id,
      raw_body: commentFromEditor.raw_body,
      __meta: { truncate: false },
    }),
  }, () => {
    if (typeof resolverFn === 'function') resolverFn();
  });

  fireUpdatedCommentAnalytics(commentFromEditor);
}
