import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';
import OptionsMenu from '../templates/OptionsMenu';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import utilStyles from '../../../styles/global_ui/util.css';

const isCurrentUserAnAdmin = (currentUser) => currentUser && currentUser.role === 'admin';

const isCurrentUserPostAuthorOrAdmin = (currentUser, record) => {
  if (!currentUser) return false;

  return (currentUser.role === 'admin' || currentUser.id === record.user.id);
};

const isCurrentUserOriginModerator = (currentUser, origin) => {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!currentUser || !origin || !origin.hasOwnProperty('admin_ids')) return false;

  return origin.admin_ids.findIndex((adminId) => currentUser.id === adminId) !== -1;
};

const OptionsMenuWithIcon = ({
  copyLinkText,
  currentUser,
  extendedAdminOptions,
  isFlagged,
  onCopyLinkClick,
  onDeleteClick,
  onEditClick,
  onReportClick,
  onSpamClick,
  openMenu,
  origin,
  record,
  toggleMenu,
}) => (
  <div className={`${utilStyles.posRelative} ${utilStyles.height100P}`}>
    <button className={buttonStyles.blank} onClick={toggleMenu}>
      <Icon name="view-more" size={16} />
    </button>

    {openMenu
    && (
      <OptionsMenu
        copyLinkText={copyLinkText}
        extendedAdminOptions={extendedAdminOptions}
        isCurrentUserAnAdmin={isCurrentUserAnAdmin(currentUser)}
        isCurrentUserOriginModerator={isCurrentUserOriginModerator(currentUser, origin)}
        isCurrentUserPostAuthorOrAdmin={isCurrentUserPostAuthorOrAdmin(currentUser, record)}
        isFlagged={isFlagged}
        isUserSignedIn={currentUser.id > 0}
        onCopyLinkClick={onCopyLinkClick}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        onReportClick={onReportClick}
        onSpamClick={onSpamClick}
        toggleMenu={toggleMenu}
      />
    )}
  </div>
);

OptionsMenuWithIcon.propTypes = {
  copyLinkText: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string,
  }),
  extendedAdminOptions: PropTypes.func,
  isFlagged: PropTypes.bool.isRequired,
  onCopyLinkClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  onSpamClick: PropTypes.func.isRequired,
  openMenu: PropTypes.bool.isRequired,
  origin: PropTypes.shape({ admin_ids: PropTypes.arrayOf(PropTypes.number) }),
  record: PropTypes.shape({ user: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired }).isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

OptionsMenuWithIcon.defaultProps = {
  currentUser: null,
  extendedAdminOptions: () => {},
  origin: null,
};

export default OptionsMenuWithIcon;
