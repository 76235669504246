import { ARROW_SIZE } from './PopoverArrow';

export const ARROW_ADJUSTMENT = 1;
export const REPOSITION_OFFSET = 5;

function composePositionObject(domRect) {
  const { top, bottom, left, right } = domRect;
  const width = getElementWidth(left, right);
  const height = getElementHeight(top, bottom);

  // adjusting for scroll offsets on our target here so that individual calculations don't have to account for it
  return {
    top: top + window.pageYOffset,
    bottom: bottom + window.pageYOffset,
    left: left + window.pageXOffset,
    right: right + window.pageXOffset,
    width,
    height,
  };
}

function getAdjustedPosition(alignment, targetPositionObj, popoverDimensions, styles, adjustmentProps = {}) {
  const { bodyStyles, arrowStyles } = styles;
  const axis = alignment === 'left' || alignment === 'right'
    ? 'vertical'
    : 'horizontal';

  const adjustments = repositionAdjustments(bodyStyles, popoverDimensions)[axis];
  const rules = repositionRules(bodyStyles, popoverDimensions)[axis];

  let adjustedBodyStyles = {};
  let adjustedArrowStyles = {};
  let adjustedArrowDirection;

  switch (axis) {
    case 'vertical':
      // TODO: Refactor this. Disabling for linter clean-up
      // eslint-disable-next-line no-case-declarations
      const { obVerticalTop, obVerticalBottom } = rules['popover-out-of-view']();
      adjustedBodyStyles = obVerticalTop
        ? { top: adjustments['reposition-popover-down']() }
        : obVerticalBottom
          ? { top: adjustments['reposition-popover-up']() }
          : {};

      // recenter arrow
      // TODO: Refactor this. Disabling for linter clean-up
      // eslint-disable-next-line no-case-declarations
      const arrowAdjustment = adjustments['recenter-arrow'](adjustedBodyStyles, targetPositionObj);
      rules['will-arrow-fit-on-popover'](arrowAdjustment, popoverDimensions)
        ? adjustedArrowStyles.top = arrowAdjustment
        : adjustedBodyStyles.top = bodyStyles.top;

      break;
    case 'horizontal':
      // TODO: Refactor this. Disabling for linter clean-up
      // eslint-disable-next-line no-case-declarations
      const { obHorizontalTop, obHorizontalBottom } = rules['popover-out-of-view']();
      // TODO: Refactor this. Disabling for linter clean-up
      // eslint-disable-next-line no-case-declarations
      let flip = {};

      if (obHorizontalBottom) {
        flip = adjustments['flip-popover-up'](targetPositionObj, adjustmentProps);
        adjustedArrowDirection = 'bottom';
      } else if (obHorizontalTop) {
        flip = adjustments['flip-popover-down'](targetPositionObj, adjustmentProps);
        adjustedArrowDirection = 'top';
      }

      adjustedBodyStyles = flip.bodyStyles || {};
      adjustedArrowStyles = flip.arrowStyles || {};

      break;
    default:
      console.warn('axis value should always be horizontal or vertical. Somehow got:', axis);
  }

  const newArrowDirection = adjustedArrowDirection ? { adjustedArrowDirection } : {};

  return {
    bodyStyles: { ...bodyStyles, ...adjustedBodyStyles },
    arrowStyles: { ...arrowStyles, ...adjustedArrowStyles },
    ...newArrowDirection,
  };
}

function getElementHeight(top, bottom) {
  return bottom - top;
}

function getElementWidth(left, right) {
  return right - left;
}

function getStyles(alignment = '', targetPositions = {}, popoverDimensions = {}, adjustmentProps = {}) {
  const { top, right, left, width, height } = targetPositions;
  const popoverOffsetVertical = adjustmentProps.popoverOffsetVertical || 0;
  const targetOffset = adjustmentProps.targetOffset || 0;

  const styles = {
    top: () => ({
      bodyStyles: {
        top: top - popoverDimensions.height - targetOffset,
        left: left - (popoverDimensions.width - width) / 2, // make this center regardless of height differences
      },
      arrowStyles: {
        top: popoverDimensions.height - ARROW_ADJUSTMENT - 1,
        left: (popoverDimensions.width / 2) - ARROW_SIZE - ARROW_ADJUSTMENT, // this assumes that the popover is centered
      },
    }),
    right: () => ({
      bodyStyles: {
        top: top + popoverOffsetVertical,
        left: right + targetOffset,
      },
      arrowStyles: {
        top: height / 2 - (ARROW_SIZE) - popoverOffsetVertical,
        left: -ARROW_SIZE,
      },
    }),
    bottom: () => ({
      bodyStyles: {
        top: top + height + targetOffset,
        left: left - (popoverDimensions.width - width) / 2,
      },
      arrowStyles: {
        top: -(ARROW_SIZE),
        left: (popoverDimensions.width / 2) - ARROW_SIZE - ARROW_ADJUSTMENT,
      },
    }),
    left: () => ({
      bodyStyles: {
        top: top + popoverOffsetVertical,
        left: left - popoverDimensions.width - targetOffset,
      },
      arrowStyles: {
        top: (height / 2) - (ARROW_SIZE) - popoverOffsetVertical,
        left: popoverDimensions.width - (ARROW_ADJUSTMENT + 1),
      },
    }),
  };

  return styles[alignment]();
}

function getViewportBoundaries() {
  return {
    top: window.pageYOffset,
    right: window.innerWidth + window.pageXOffset,
    bottom: window.innerHeight + window.pageYOffset,
    left: window.pageXOffset,
  };
}

function repositionAdjustments(bodyStyles, popoverDimensions) {
  const view = getViewportBoundaries();
  const popoverBottom = bodyStyles.top + popoverDimensions.height;

  return {
    vertical: {
      ['reposition-popover-up']: () => bodyStyles.top - (popoverBottom - view.bottom + REPOSITION_OFFSET),
      ['reposition-popover-down']: () => bodyStyles.top + (view.top - bodyStyles.top + REPOSITION_OFFSET),
      ['recenter-arrow']: (adjustedBodyStyles, targetPositionObj) => (targetPositionObj.top + (targetPositionObj.height / 2)) - adjustedBodyStyles.top - (ARROW_SIZE),
    },
    horizontal: {
      ['flip-popover-up']: (targetPositions, adjustmentProps) => getStyles('top', targetPositions, popoverDimensions, adjustmentProps),
      ['flip-popover-down']: (targetPositions, adjustmentProps) => getStyles('bottom', targetPositions, popoverDimensions, adjustmentProps),
    },
  };
}

function repositionRules(bodyStyles, popoverDimensions) {
  const view = getViewportBoundaries();
  const popoverBottom = bodyStyles.top + popoverDimensions.height;

  return {
    vertical: {
      ['popover-out-of-view']: () => ({
        obVerticalTop: bodyStyles.top < view.top,
        obVerticalBottom: popoverBottom > view.bottom,
      }),
      ['will-arrow-fit-on-popover']: (arrowTop, popoverDimensions) => {
        const cutoffOffset = 3;
        const arrowBottom = arrowTop + (ARROW_SIZE * 2);

        return (arrowBottom + cutoffOffset) < popoverDimensions.height && (arrowTop - cutoffOffset) > 0;
      },
    },
    horizontal: {
      ['popover-out-of-view']: () => ({
        obHorizontalTop: bodyStyles.top < view.top,
        obHorizontalBottom: popoverBottom > view.bottom,
      }),
    },
  };
}

export function getPosition(alignment, targetPosition = {}, popoverDimensions = {}, adjustmentProps = {}) {
  const targetPositionObj = composePositionObject(targetPosition);
  const styles = getStyles(alignment, targetPositionObj, popoverDimensions, adjustmentProps);

  return getAdjustedPosition(alignment, targetPositionObj, popoverDimensions, styles, adjustmentProps);
}
