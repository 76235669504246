import React from 'react';
import ReactDOM from 'react-dom';
import { tryJSONParse } from '../../utility/json';

import AddressButton from '../address/button';
import AuthDialog from '../auth/dialog';
import AuthPanel from '../auth/panel';
import Badge from '../reusable_components/Badge';
import BookmarkButton from '../buttons/bookmark_button';
import BookmarkList from '../../scenes/bookmark_list_page';
import ChallengeFormEditorGroup from '../challenges/edit/form/editor_group';
import ChallengePrizeEditor from '../challenges/edit/form/prize_editor';
import CollectionCategorySelect from '../form_components/selects/collection_category_select';
import ContestCsvExporter from '../contest_csv_exporter/app';
import ContestDiscussion from '../../scenes/contest_page/discussion';
import CourseWizard from '../course_wizard';
import DialogAction from '../buttons/dialog_action';
import EventFormPage from '../../scenes/events/event_form_page';
import FileUploader from '../form_components/file_uploaders/RailsFormFileUploader';
import FlagButton from '../buttons/flag_button';
import GlobalDialog from '../global_components/global_dialog';
import GlobalMessenger from '../global_components/messenger';
import GlobalPopover from '../reusable_components/Popover/GlobalPopover';
import ImageUploader from '../form_components/image_uploaders/rails_form_image_uploader';
import LeaderBoardAirframe from '../../scenes/contest_page/leader_board_airframe';
import LocalStoragePopover from '../global_components/local_storage_popover';
import MarkSpamButton from '../buttons/mark_spam_button';
import NewFeatureTooltip from '../reusable_components/NewFeatureTooltip';
import NewsAdminPage from '../../scenes/news/admin_page';
import ProBadge from '../reusable_components/Badge/ProBadge';
import ProjectComments from '../comments/project_comments';
import ProjectCountBanner from '../content_locking/project_count_banner';
import ProjectEditorImageUploader from '../form_components/image_uploaders/project_editor_image_uploader';
import RespectButton from '../buttons/respect_button';
import SimilarProjects from '../projects/similar_projects';
import StoryEditor from '../story_editor/app';
import StoryEditorHelpBox from '../story_editor/HelpBox';
import UIPreferenceDialog from '../global_components/ui_preference_dialog';
import UIPreferenceSelector from '../global_components/ui_preference_selector';

import AdminUserModeration from '../../scenes/admin/UserModeration';
import AdminUserMembershipDashboard from '../../scenes/admin/UserMembershipDashboard';

// Temporary component. Will be moved under the ChannelDashboard SPA shortly.
import ChannelManageConfirmationButton from '../../scenes/channel_dashboard/confirmation_button';
import ChannelManageHome from '../../scenes/channel_dashboard/home';
import ChannelManageMembers from '../../scenes/channel_dashboard/members';

const DATA_KEY = 'hacksternova-key';
const PROPS_KEY = 'hacksternova-props';

// Import components here for the client_renderer (when the Rails mounting helper has prerender undefined or false).
// We'll move these import/exports to client/index.js once we're done with react-rails.
const CLIENT_COMPONENTS = {
  AddressButton,
  AuthDialog,
  AuthPanel,
  Badge,
  BookmarkButton,
  BookmarkList,
  ChallengeFormEditorGroup,
  ChallengePrizeEditor,
  ChannelManageConfirmationButton, // Temp
  ChannelManageHome, // Temp
  ChannelManageMembers, // Temp
  CollectionCategorySelect,
  ContestCsvExporter,
  ContestDiscussion,
  CourseWizard,
  DialogAction,
  EventFormPage,
  GlobalDialog,
  GlobalMessenger,
  GlobalPopover,
  FileUploader,
  FlagButton,
  ImageUploader,
  LeaderBoardAirframe,
  LocalStoragePopover,
  MarkSpamButton,
  NewFeatureTooltip,
  ProBadge,
  ProjectComments,
  ProjectCountBanner,
  ProjectEditorImageUploader,
  RespectButton,
  SimilarProjects,
  StoryEditor,
  StoryEditorHelpBox,
  UIPreferenceDialog,
  UIPreferenceSelector,
};

const ADMIN_COMPONENTS = {
  AdminUserMembershipDashboard,
  AdminUserModeration,
  NewsAdminPage,
};

const COMPONENTS = { ...ADMIN_COMPONENTS, ...CLIENT_COMPONENTS };

const getNodesWithData = function(container = document) {
  const nodes = container.querySelectorAll(`[data-${DATA_KEY}]`);

  return [].reduce.call(nodes, (acc, node) => {
    const name = node.getAttribute(`data-${DATA_KEY}`);
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    if (!COMPONENTS.hasOwnProperty(name)) {
      console.warn('renderComponents found an element that has no matching Component. You likely forgot to import it or misspelled the Component name.', name);

      return acc;
    }

    const props = tryJSONParse(node.getAttribute(`data-${PROPS_KEY}`), {});

    return acc.concat({ node, data: { name, props } });
  }, []);
};

/**
 * When Rails does its UJS form magic, it overwrites the entire html. We need to unmount the components
 * before that happens to clean up event listeners and such.
 */
export function unmountComponents(container = document) {
  const payloads = getNodesWithData(container);

  payloads.forEach((payload) => {
    const { node } = payload;
    ReactDOM.unmountComponentAtNode(node);
  });
}

export default function renderComponents(container = document) {
  const payloads = getNodesWithData(container);

  payloads.forEach((payload) => {
    const { node, data } = payload;
    const element = React.createElement(COMPONENTS[data.name], data.props);
    ReactDOM.render(element, node);
  });
}
