import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './button.css';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false, tooltipWidth: 0 };
    this.tooltipRef;
  }

  UNSAFE_componentWillUpdate(_nextProps, nextState) {
    if (nextState.hovered && !this.state.hovered && typeof this.tooltipRef !== 'undefined') {
      const dimensions = this.tooltipRef.getBoundingClientRect();
      this.setState({ tooltipWidth: parseInt(dimensions.width, 10) + 12 });  // 12 === tooltip padding * 2.
    }
  }

  render() {
    const { buttonStyle, classList, disabled, icon, iconStyle, tooltip } = this.props;

    return (
      <span>
        <button
          style={buttonStyle}
          className={`${classList} draftster-button-with-tooltip`}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            this.props.onClick(e);
          }}
          onMouseOver={() => this.setState({ hovered: true })}
          onMouseLeave={() => this.setState({ hovered: false })}
          >

          <i style={iconStyle} className={`${icon} ${styles.icon}`} />

          {tooltip.length && !disabled &&
            <div
              style={{marginLeft: -Math.abs(((parseInt(this.state.tooltipWidth, 10) - 6) / 2))}} // 6 === tooltip padding
              className={styles.tWrapper}
              >
              <span
                ref={(el) => this.tooltipRef = el}
                style={this.state.hovered ? {opacity: 0.9, width: this.state.tooltipWidth} : {opacity: 0}}
                className={styles.tooltip}
                >
                {tooltip}
              </span>
            </div>
          }
        </button>
      </span>
    );
  }
}

Button.propTypes = {
  buttonStyle: PropTypes.object,
  classList: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string
};

Button.defaultProps = {
  buttonStyle: {},
  classList: '',
  disabled: false,
  iconStyle: {},
  tooltip: ''
};

export default Button;