/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { mapifyStringQuery, mapToStringQuery } from '../../../utility/converters';
import { windowLocationPathname, windowLocationSearch } from '../../window';

export function appendFilterStringToQueryMap(queryMap, filterString) {
  return new Promise((resolve) => {
    const filter = filterString.split('=');
    const queryMapCopy = { ...queryMap };

    // Filter will be null if its the default, we dont want to do anything with a null value, so remove it!
    if (filter[1] === 'null' && queryMapCopy.hasOwnProperty(filter[0])) {
      delete queryMapCopy[filter[0]];
    } else if (filter[0] === 'page' && filter[1] === '0' && queryMapCopy.hasOwnProperty('page')) {
      delete queryMapCopy.page;
    } else if (filter[1] !== 'null') {
      queryMapCopy[filter[0]] = filter[1];
    }

    // Remove page when resorting.
    if (filter[0] === 'sort' && queryMapCopy.hasOwnProperty('page')) {
      delete queryMapCopy.page;
    }

    resolve(queryMapCopy);
  });
}

export function decodeLastUrlSegment() {
  return new Promise((resolve) => {
    const parts = windowLocationPathname.get().split('/').filter((part) => part.length && part !== '/');
    const part = parts[parts.length - 1] || '';
    resolve(decodeURIComponent(part));
  });
}

export function getUrlSegmentsAfterUrlPart(partToSliceAt) {
  return new Promise((resolve) => {
    const parts = windowLocationPathname.get().split('/').filter((part) => part.length && part !== '/');
    const sliceIndex = parts.indexOf(partToSliceAt);
    const segments = parts.slice(sliceIndex + 1);
    resolve(segments.map((segment) => decodeURIComponent(segment)));
  });
}

export function mapifySearchQuery() {
  return new Promise((resolve) => {
    const queryMap = mapifyStringQuery(windowLocationSearch.get());
    const keys = Object.keys(queryMap);
    const updatedMap = keys.reduce((acc, key) => {
      if (queryMap[key] && queryMap[key].length && queryMap[key] !== 'null' && queryMap[key] !== 'undefined' && queryMap[key] !== '""') {
        acc[key] = queryMap[key];
      }

      return acc;
    }, {});

    resolve(updatedMap);
  });
}

export function stringifyQueryMap(queryMap) {
  return new Promise((resolve) => {
    resolve(mapToStringQuery(queryMap));
  });
}
