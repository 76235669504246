const normalPaginator = (currentQuery) => ({ hitsPerPage: currentQuery.hitsPerPage, page: currentQuery.page + 1 });
const offsetPaginator = (currentQuery, pagination) => ({ length: pagination.length, offset: currentQuery.offset + currentQuery.length, sort: 'trending' });

const paginatorMap = {
  offset: offsetPaginator,
  page: normalPaginator,
};

export const buildPaginatedQuery = ({ queryMap, pagination, currentQuery }) => (
  {
    ...queryMap,
    ...paginatorMap[pagination.type](currentQuery, pagination),
  }
);
