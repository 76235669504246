function isSpace(code) {
  switch (code) {
    case 0x09:
    case 0x20:
      return true;
  }

  return false;
}

function getRegexForChar(char) {
  return char === '@'
    // eslint-disable-next-line no-useless-escape
    ? new RegExp(`(\B${char}[a-z0-9_-]+\b)(?!\.\w)`, 'g')
    // eslint-disable-next-line no-useless-escape
    : new RegExp(`${char}[a-zA-Z0-9_\-]+`, 'g');
}

function highlight_entity(md, config) {
  return function tokenize(state, silent) {
    // We need to know what character starts this entity.
    if (!config.highlightChar) return false;

    const SYNTAX_CHAR = config.highlightChar;
    const SYNTAX_CODE = SYNTAX_CHAR.charCodeAt(0);

    const oldPos = state.pos;
    const max = state.posMax;

    let highlightWord,
      invalidSyntax = false;

    if (silent) return false;

    // Match SYNTAX_CHAR
    if (state.src.charCodeAt(state.pos) !== SYNTAX_CODE) return false;

    const opening = state.pos;

    while (state.pos <= max) {
      if (state.pos === max) {
        if (state.src.slice(opening, state.pos).match(getRegexForChar(SYNTAX_CHAR))) { // All content is the entity
          highlightWord = state.src.slice(opening, state.pos);
          break;
        } else {
          invalidSyntax = true;
          break;
        }
      }

      // End the match proposal at any space.
      // If the opening was at index 0 then honor it, otherwise we only want to match if the preceeding char is an
      // empty space.
      if (isSpace(state.src.charCodeAt(state.pos)) && (opening === 0 || isSpace(state.src.charCodeAt(opening - 1)))) {
        highlightWord = state.src.slice(opening, state.pos);
        break;
      }

      state.pos += 1;
    }

    if (invalidSyntax) {
      state.pos = oldPos;

      return false;
    }

    // Successfully found a entity, create it.
    const token = state.push('entity');
    token.content = `${highlightWord}`;

    state.md.inline.tokenize(state);

    return true;
  };
}

export default function highlightEntity(md, config) {
  md.renderer.rules.entity = function(tokens, idx, options, env, self) {
    return `<span class=${config.highlightClass} ${self.renderAttrs(tokens[idx])}>${tokens[idx].content}</span>`;
  };
  md.inline.ruler.push('entity', highlight_entity(md, config));
}
