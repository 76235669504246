import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmbedToolbar from './EmbedToolbar';
import Figcaption from './Figcaption';

export default class Embed extends Component {
  constructor(props) {
    super(props);

    this.state = { height: 0, hovered: false, toolbarData: {} };

    this.handleCaptionKeyDown = this.handleCaptionKeyDown.bind(this);
    this.handleFigcaptionChange = this.handleFigcaptionChange.bind(this);
    this.handleIFrameLoad = this.handleIFrameLoad.bind(this);
    this.handleIFrameResponse = this.handleIFrameResponse.bind(this);
    this.handleMediaLoad = this.handleMediaLoad.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);

    this._embed;
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIFrameResponse);
  }

  componentWillUnmount() {
    if (window && this.focusCaptionTimeout) window.clearTimeout(this.focusCaptionTimeout);
    window.removeEventListener('message', this.handleIFrameResponse);
  }

  handleMouseOver(e) {
    if (e.target.classList.contains('react-editor-embed') || e.target.classList.contains('react-editor-embed-wrapper')) {
      this.setState({ hovered: true });
    }
  }

  handleCaptionKeyDown(e) {
    switch(e.keyCode) {
      case 38: // UP ARROW
        this._moveSelectionTo(e, 'previous');
        break;
      case 40: // DOWN ARROW
      case 13: // ENTER
      case 9:  // TAB
        this._moveSelectionTo(e, 'next');
        break;
      default:
        break;
    }
  }

  handleIFrameLoad(e) {
    if (this._embed) {
      this._embed.contentWindow.postMessage({ id: this.props.block.get('key') }, '*');
      // Calling this method below is to calculate a height when postMessage does not get a response. Only Instagram and Gists will respond.
      // For all others like videos, we need to do this manually. This unfortunetly causes 2 rerenders for the two services above. Since we
      // have a race condition with the message events, this is the easiest way to grow the embed.
      this._setDimensionsToStateAndEntity();
    }
  }

  handleIFrameResponse(e) {
    // Boot early if this component's iframe is not the source of the event.
    if (e.source !== this._embed.contentWindow) return;

    if (e.data.id === this.props.block.get('key') && this._embed) {
      this._setDimensionsToStateAndEntity(e.data.height);
    } else if (e.origin.includes('instagram')) {
      this._parseInstagramMessage(e);
    }
  }

  handleFigcaptionChange(value) {
    const {block, blockProps, contentState} = this.props;
    const embedData = contentState.getEntity(block.getEntityAt(0)).getData().embedData;
    const updatedData = embedData.set('figcaption', value);

    blockProps.updateContentState(contentState.replaceEntityData(block.getEntityAt(0), { embedData: updatedData }));
  }

  handleMediaLoad(e) {
    if (this._embed) this._setDimensionsToStateAndEntity();
  }

  _getIframeSrc(data) {
    const embed = data.get('embed');

    // Github gists have a security header that blocks direct iframes. This workaround will pull the js from github
    // to create the gist itself. The js is the gists url with .js appended.
    if (data.get('service') === 'gist') {
      return `data:text/html;charset=utf-8,<body><script src="${embed}.js"></script>${this._getGistIframePostMsgScript()}</body>`;
    }

    return embed;
  }

  _getGistIframePostMsgScript() {
    return (`<script>
      window.addEventListener('message', function(event) {
        if (event.data.id === "${this.props.block.get('key')}") {
          var body = document.body, html = document.documentElement;
          var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
          window.parent.postMessage({ height: height, id: "${this.props.block.get('key')}" }, "*");
        }
      }, false);
    </script>`);
  }

  _moveSelectionTo(e, direction) {
    e.preventDefault();
    this.props.blockProps.moveSelectionTo(this.props.block, direction);
  }

  _parseInstagramMessage(e) {
    const data = JSON.parse(e.data);

    if (data && data.type === 'MEASURE') {
      const height = data.details && data.details.height ? data.details.height : null;
      if (height && this.state.height !== height) this._setDimensionsToStateAndEntity(parseInt(height, 10));
    }
  }

  _setDimensionsToStateAndEntity(knownHeight=null) {
    const width = this._embed.getBoundingClientRect().width;
    const height = knownHeight || (width * (9/16));
    this.setState({height});
    this._mutateEntity({height, width});
  }

  _mutateEntity(data={}) {
    const entityKey = this.props.block.getEntityAt(0);
    const embedData = this.props.contentState.getEntity(entityKey).getData().embedData;

    this.props.blockProps.updateContentState(
      this.props.contentState.mergeEntityData(entityKey, {embedData: embedData.merge(data)})
    );
  }

  _getEmbedView(data) {
    switch(data.get('type')) {
      case 'iframe':
        return this._getIFrameView(data);

      case 'image':
        return (
          <img
            ref={el => this._embed = el}
            style={{ height: this.state.height === 0 ? 'auto' : this.state.height }}
            className="react-editor-embed"
            src={data.get('embed')}
            onLoad={this.handleMediaLoad}
            />
        );
      default:
        return null;
    }
  }

  _getIFrameView(data) {
    const alt = data.get('alt');

    if (data.get('service') === 'mp4') {
      const embed = data.get('embed');

      return (
        <video
          ref={el => this._embed = el}
          style={{ height: this.state.height === 0 ? 'auto' : this.state.height }}
          className="react-editor-embed"
          preload="metadata"
          controls="controls"
          onLoadedData={this.handleMediaLoad}
          >
          <source type="video/mp4" src={embed} />
          <a href={embed} alt={alt}>{embed}</a>
        </video>
      );
    } else {
      const embed = this._getIframeSrc(data);

      return (
        <iframe
          ref={el => this._embed = el}
          key={this.props.block.get('key')}
          style={{ height: this.state.height === 0 ? 'auto' : this.state.height }}
          className="react-editor-embed react-editor-embed-override"
          src={embed}
          alt={alt}
          frameBorder="0"
          onLoad={this.handleIFrameLoad}
          />
      );
    }
  }

  render() {
    const embedData = this.props.contentState.getEntity(this.props.block.getEntityAt(0)).getData().embedData;
    const shouldRenderFigcaption = embedData.get('service') !== 'gist';

    return (
      <div
        className="react-editor-embeddable"
        onMouseOver={this.handleMouseOver}
        >
        <div className="react-editor-embeddable-inner">
          <figure className="react-editor-figure">
            <div
              className="react-editor-embed-wrapper"
              contentEditable={false}
              >
              {this._getEmbedView(embedData)}
              {shouldRenderFigcaption &&
                <Figcaption
                  className="react-editor-figcaption"
                  onBlur={() => this.props.blockProps.editing(false, 'figcaption')}
                  onFocus={() => this.props.blockProps.editing(true)}
                  onKeyDown={this.handleCaptionKeyDown}
                  onValueChange={this.handleFigcaptionChange}
                  placeholder="caption (optional)"
                  value={embedData.get('figcaption') || ''}
                  />
              }
            </div>
          </figure>
        </div>
        {this.state.hovered &&
          <EmbedToolbar
            data={{height: this.state.height}}
            disableButtonsByType={[]}
            embedType="default"
            parent={this}
            parentRenderingFigcaption={shouldRenderFigcaption}
            remove={() => this.props.blockProps.remove(this.props.block)}
            unmountToolbar={() => this.setState({ hovered: false })}
            />
        }
      </div>
    );
  }
}

Embed.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: PropTypes.object.isRequired
};