import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../buttons/base';
import ContestEmail from '../../challenges/emails/ContestEmail';
import Dialog from '../../reusable_components/Dialog';

/**
 * NOTE: Use this Component whenever we need to bring up a Dialog via Rails. We'll extend functionality as we need.
 */
class DialogAction extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  /**
   * Views
   */
  _getActionable() {
    if (this.props.actionEl) return this.props.actionEl;

    switch (this.props.actionableType) {
      default:
        return this._getButtonView();
    }
  }

  _getButtonView() {
    const props = this.props.actionableConfig.props || {};

    return (
      <Button
        {...props}
        onClick={() => this.setState({ open: true })}
      >
        {this.props.actionableConfig.text}
      </Button>
    );
  }

  _getDialogBody() {
    if (this.props.dialogRenderType === 'custom') return this._getCustomDialogBodyComponent();

    switch (this.props.dialogRenderType) {
      default:
        return (this.props.children || null);
    }
  }

  _getCustomDialogBodyComponent() {
    switch (this.props.customDialogBodyComponent) {
      case 'ContestEmail':
        // TODO: move this up or just use the long winded version.
        // eslint-disable-next-line no-case-declarations
        const { email } = this.props.dialogProps;

        return (<ContestEmail body={email[1]} subject={email[0]} />);

      default:
        null;
    }
  }

  render() {
    const { fullScreen } = this.props.dialogConfig;

    return (
      <Fragment>
        {this._getActionable()}

        <Dialog
          fullScreen={fullScreen || false}
          open={this.state.open}
        >
          {this._getDialogBody()}
        </Dialog>
      </Fragment>
    );
  }
}

DialogAction.propTypes = {
  actionEl: PropTypes.element,
  actionableConfig: PropTypes.shape({
    props: PropTypes.object,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  actionableType: PropTypes.oneOf(['button']),
  customDialogBodyComponent: PropTypes.string,
  dialogConfig: PropTypes.shape({
    fullScreen: PropTypes.bool,
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  dialogProps: PropTypes.shape({}),
  dialogRenderType: PropTypes.oneOf(['children', 'custom', 'element', 'html', 'iframe']), // If custom be sure to set a customDialogBodyComponent.
};

DialogAction.defaultProps = {
  actionEl: null,
  actionableConfig: {
    props: {},
    text: 'Open',
  },
  actionableType: 'button',
  customDialogBodyComponent: null,
  dialogConfig: {
    fullScreen: false,
    minWidth: null,
    width: null,
  },
  dialogProps: {},
  dialogRenderType: 'children',
};

export default DialogAction;
