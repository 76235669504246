const toRad = function(n) {
  return n * Math.PI / 180;
};

const toDeg = function(n) {
  return n * 180 / Math.PI;
};

const milesToKM = function(miles) {
  if (typeof miles !== 'number') {
    miles = parseFloat(miles);
  }

  return miles * 1.6;
};

const destinationPoint = function(lat, long, brng, dist) {
  const earthRadius = 6371;
  dist = dist / earthRadius;
  brng = toRad(brng);

  const lat1 = toRad(lat);
  const lon1 = toRad(long);

  const lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist)
    + Math.cos(lat1) * Math.sin(dist) * Math.cos(brng));

  const lon2 = lon1 + Math.atan2(Math.sin(brng) * Math.sin(dist)
    * Math.cos(lat1),
  Math.cos(dist) - Math.sin(lat1)
  * Math.sin(lat2));

  if (isNaN(lat2) || isNaN(lon2)) return null;

  return { lat: toDeg(lat2), lng: toDeg(lon2) };
};

export default function createBoundingBox(geoloc, distance) {
  distance = milesToKM(distance);
  const nw = destinationPoint(parseFloat(geoloc.lat), parseFloat(geoloc.lng), -45, distance);
  const se = destinationPoint(parseFloat(geoloc.lat), parseFloat(geoloc.lng), 135, distance);

  if (nw === null || se === null) return null;

  return [nw.lat, nw.lng, se.lat, se.lng];
}
