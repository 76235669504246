import getPathPrefix from '../services/oauth/getPathPrefix';
import getCSRFToken from '../services/oauth/getCSRFToken';
import renderComponents, { unmountComponents } from '../client/initializers/renderComponents';
import smoothScroll from '../client/utils/smoothScroll';

export const Utils = {
  getCSRFToken,
  getPathPrefix,
  renderComponents,
  smoothScroll,
  unmountComponents,
};
