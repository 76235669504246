/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionsSection from '../../../../client/form_components/templates/ActionsSection';
import BasicFormInput from '../../../../client/form_components/inputs/basic_form_input';
import FormSelect from '../../../../client/form_components/selects/form_select';

import { createImageUploader, createCropperTitle, createMultiSelect, recordsToOptions } from '../../home_sections/promoted_content_form/content_form/components';

import smoothScroll from '../../../../client/utils/smoothScroll';
import { getFieldValuesAsObject, initFields } from '../../../../utility/forms';
import { getInObj } from '../../../../utility/accessors';
import { imageV, isRequired, isValidEmbedUrl, maxLength, minLength } from '../../../../services/validation/validators';
import { validation as validateDuration } from '../../../../client/form_components/inputs/duration';
import { valueToSelectIn } from '../../../../utility/forms/formatters';
import { windowScrollTo } from '../../../../services/window';

import { VIDEO_CATEGORIES } from '../../../../graphql/videos/enums';

import formStyles from '../../../../styles/global_ui/forms.css';
import inputStyles from '../../../../styles/global_ui/inputs.css';
import layout from '../../../../styles/global_ui/layout.css';

const CATEGORY_OPTIONS = VIDEO_CATEGORIES.map((key) => ({ label: key, value: key }));

const FIELDS_TEMPLATE = {
  category: { order: 1, validate: (value) => minLength(1, value), value: '', formatIn: (v) => valueToSelectIn(v), formatOut: (v) => getInObj(['value'], v) },
  description: { order: 2, validate: (value) => minLength(1, value), value: '' },
  duration: { order: 3, validate: (value) => validateDuration(value), value: '' },
  image: { order: 9, validate: (value) => imageV(value), value: null },
  platforms: { order: 7, validate: (value) => null, value: [], notRequired: true },
  products: { order: 6, validate: (value) => null, value: [], notRequired: true },
  tags: { order: 5, validate: (value) => null, value: [], notRequired: true },
  title: { order: 0, validate: (value) => maxLength(67, value), value: '' },
  topics: { order: 8, validate: (value) => null, value: [], notRequired: true },
  url: { order: 4, validate: (value) => isValidEmbedUrl(value), value: '' },
};

// TODO: Replace all methods with those that exist in utility/forms.
class VideoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      isBusy: false,
      fields: initFields(FIELDS_TEMPLATE, this._getInitData(props)),
      workers: [],
    };

    this.addOrUpdateContent = this.addOrUpdateContent.bind(this);

    // Form Helpers
    this.getFieldValuesAsObject = getFieldValuesAsObject.bind(this);
  }
  /**
   * Initializers
   */

  _getInitData(props) {
    return getInObj(['initData', 'data'], props) || {};
  }

  componentDidMount() {
    windowScrollTo(0, 0);
  }

  /**
   * Methods
   */
  addOrUpdateContent() {
    const validated = this._validate();

    if (validated) {
      this.props.saveContent(this._getObjectToPropagate());
    }
  }

  /**
   * Helpers
   */
  _getObjectToPropagate() {
    return {
      data: this.getFieldValuesAsObject(),
      index: this.props.initData && this.props.initData.index !== null ? this.props.initData.index : null,
    };
  }

  _setIsBusy(status, worker) {
    if (status && this.state.workers.includes(worker)) return;

    const workers = status ? this.state.workers.concat(worker) : this.state.workers.filter((w) => w !== worker);
    this.setState({
      isBusy: workers.length > 0,
      workers,
    });
  }

  _setStateOrError(errorMsg, key, value) {
    if (errorMsg === null) {
      const { [key]: deleted, ...errors } = this.state.errors; // eslint-disable-line @typescript-eslint/no-unused-vars
      this.setState({
        errors,
        fields: { ...this.state.fields, [key]: { ...this.state.fields[key], value: value } },
      });
    } else {
      this.setState({
        errors: { ...this.state.errors, [key]: errorMsg },
        fields: { ...this.state.fields, [key]: { ...this.state.fields[key], value: value } },
      });
    }
  }

  _scrollToError(errors) {
    const el = Object.keys(this.state.fields)
      .sort((a, b) => this.state.fields[a].order - this.state.fields[b].order)
      .reduce((acc, key) => {
        if (acc !== null) return acc;

        return errors.hasOwnProperty(key) ? document.getElementById(`vf${key}`) : acc;
      }, null);

    if (el) {
      smoothScroll(el, 200, null, window);
    }
  }

  _validate() {
    const keys = Object.keys(this.state.fields);
    const errors = keys.reduce((acc, key) => {
      const field = this.state.fields[key];
      const validations = field.notRequired ? [field.validate] : [isRequired, field.validate];
      const error = validations.reduce((a, fn) => {
        if (a !== null) return a;

        return fn(field.value);
      }, null);

      if (error && error.length) {
        acc[key] = error;
      }

      return acc;
    }, {});

    if (Object.keys(errors).length) {
      this.setState({ errors: { ...this.state.errors, ...errors } }, () => this._scrollToError(this.state.errors));

      return false;
    }

    return true;
  }

  /**
   * Views
   */
  _getErrorViewForType(type, helperText) {
    return (
      <div className={inputStyles.msgWrapper}>
        <div>
          {this.state.errors.hasOwnProperty(type)
          && <div className={inputStyles.error}>{this.state.errors[type]}</div>}
          {helperText && <div className={inputStyles.help}>{helperText}</div>}
        </div>
      </div>
    );
  }

  render() {
    return (
      <form className={formStyles.container}>
        <div id="vftitle">
          <BasicFormInput
            charCount={this.state.fields.title.value.length}
            errors={this.state.errors.title}
            label="Title"
            maxVal={60}
            onChange={(e) => this._setStateOrError(maxLength(60, e.target.value), 'title', e.target.value)}
            placeholder="Type your title here"
            value={this.state.fields.title.value}
          />
        </div>

        <div id="vfcategory">
          <FormSelect
            errors={this.state.errors.category}
            label="Category"
            onSelectedChange={(opt) => this._setStateOrError(null, 'category', opt)}
            options={CATEGORY_OPTIONS}
            placeholder="Select a category"
            selectOpts={{ rule: 'norule' }}
            value={this.state.fields.category.value}
          />
        </div>

        <div id="vfdescription">
          <BasicFormInput
            element="textarea"
            errors={this.state.errors.description}
            label="Description"
            onChange={(e) => this._setStateOrError(null, 'description', e.target.value)}
            placeholder="Write a quick description of the video"
            value={this.state.fields.description.value}
          />
        </div>

        <div id="vfduration">
          <BasicFormInput
            classList={{ input: inputStyles.halfWidth }}
            element="duration" // Custom
            errors={this.state.errors.duration}
            label="Duration"
            onChange={(e, formattedVal) => this._setStateOrError(null, 'duration', formattedVal)}
            placeholder="00:00:00"
            value={this.state.fields.duration.value}
          />
        </div>

        <div id="vfurl">
          <BasicFormInput
            errors={this.state.errors.url}
            label="Url"
            onChange={(e) => this._setStateOrError(null, 'url', e.target.value)}
            placeholder="Example: https://www.youtube.com"
            type="url"
            value={this.state.fields.url.value}
          />
        </div>

        <div className={layout.marginBottom30} id="vftags">
          <label className={inputStyles.label}>Select up to 5 tags (optional)</label>
          {createMultiSelect({
            algoliaService: this.props.algoliaTagsService,
            errors: this.state.errors.hasOwnProperty('tags') ? this.state.errors.tags : null,
            maxWidth: '100%',
            onSelect: (tags) => this._setStateOrError(null, 'tags', tags),
            recordsToOptions: recordsToOptions,
            selectionLimit: 5,
            value: this.state.fields.tags.value,
          })}
          {this._getErrorViewForType('tags')}
        </div>

        <div className={layout.marginBottom30} id="vfproducts">
          <label className={inputStyles.label}>Select relevant products (optional)</label>
          {createMultiSelect({
            algoliaService: this.props.algoliaPartsService,
            errors: this.state.errors.hasOwnProperty('products') ? this.state.errors.products : null,
            maxWidth: '100%',
            onSelect: (products) => this._setStateOrError(null, 'products', products),
            recordsToOptions: recordsToOptions,
            value: this.state.fields.products.value,
          })}
          {this._getErrorViewForType('products')}
        </div>

        <div className={layout.marginBottom30} id="vfplatforms">
          <label className={inputStyles.label}>Select relevant platforms (optional)</label>
          {createMultiSelect({
            algoliaService: this.props.algoliaPlatformsService,
            errors: this.state.errors.hasOwnProperty('platforms') ? this.state.errors.platforms : null,
            maxWidth: '100%',
            onSelect: (platforms) => this._setStateOrError(null, 'platforms', platforms),
            recordsToOptions: recordsToOptions,
            value: this.state.fields.platforms.value,
          })}
          {this._getErrorViewForType('platforms')}
        </div>

        <div className={layout.marginBottom30} id="vftopics">
          <label className={inputStyles.label}>Select relevant topics (optional)</label>
          {createMultiSelect({
            algoliaService: this.props.algoliaTopicsService,
            errors: this.state.errors.hasOwnProperty('topics') ? this.state.errors.topics : null,
            maxWidth: '100%',
            onSelect: (topics) => this._setStateOrError(null, 'topics', topics),
            recordsToOptions: recordsToOptions,
            value: this.state.fields.topics.value,
          })}
          {this._getErrorViewForType('topics')}
        </div>

        <div id="vfimage">
          {createImageUploader({
            aspectRatio: (16 / 9),
            cropperTitle: createCropperTitle({ title: '16:9 Crop' }),
            errors: this.state.errors['image'],
            label: 'Upload image',
            dimensionMins: { width: 912 },
            helperText: 'This picture should have a ratio of 16:9 and be 2736x1539px (minimum 912x513px) for the best quality.',
            imageData: this.state.fields.image.value,
            nestedDialogLevel: 1,
            propagateStatus: (isBusy) => this._setIsBusy(isBusy, 'image'),
            propagateUpload: (image) => this._setStateOrError(null, 'image', image),
          })}
        </div>

        <ActionsSection
          classNames={{ root: layout.marginTop30 }}
          isBusy={this.state.isBusy || this.props.isBusy}
          mode={this.props.mode}
          primaryBtnConfig={{
            onClick: this.addOrUpdateContent,
            text: 'Create video',
          }}
          secondaryBtnConfig={{ onClick: this.props.dismiss }}
        />
      </form>
    );
  }
}

VideoForm.propTypes = {
  algoliaPartsService: PropTypes.object.isRequired,
  algoliaPlatformsService: PropTypes.object.isRequired,
  algoliaTagsService: PropTypes.object.isRequired,
  algoliaTopicsService: PropTypes.object.isRequired,
  initData: PropTypes.shape({
    data: PropTypes.object,
    index: PropTypes.number,
  }),
  isBusy: PropTypes.bool.isRequired,
  mode: PropTypes.string,
  saveContent: PropTypes.func.isRequired,
};

VideoForm.defaultProps = {
  initData: null,
  mode: 'default',
};

export default VideoForm;
