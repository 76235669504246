import React from 'react';

import styles from './progress_bar.css';

const ProgressBar = () => (
  <div className={styles.wrapper}>
    <div className={styles.bg}>
      <div className={styles.fill} />
    </div>
  </div>
);

export default ProgressBar;
