export { default as convertToJSONModel } from './convertToJSONModel';
export { uploadImageToServer, processRemoteImage } from './helpers';

/**
 * When urls are preserved via convertTOJSONModel, an images url might be a huge blob which we dont want to send over the wire.
 * The server doesn't need the url property anyways, so its safe to null them all out.
 */
export const cleanUrlsFromCarousels = (model) => model.map((item) => {
  if (item.type !== 'Carousel') return item;

  const images = item.images.map((image) => ({ ...image, url: null }));

  return { ...item, images };
});

/**
 * Preps html for Draftster. Currently we should only have to expand pre blocks.
 * @param  {[array || string]} model - JSON consumable, empty string or null
 * @return {[array || string]}
 */
export const prepareModelForDraftsterInstance = (model) => {
  // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
  // eslint-disable-next-line @stylistic/no-mixed-operators
  if (!model || model && !model.length) return '';

  return model;
};
