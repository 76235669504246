import React, { Fragment } from 'react';
import inputStyles from '../../../styles/global_ui/inputs.css';

import { isBlank } from '../../../utility/types';

/**
 * Templates for common form views. Keep these stateless functions.
 */

/**
 * For custom components that don't internally have a errors/helpers section.
 * @param  {string} error
 * @param  {string} helperText
 * @param  {string} warningText
 */
export function getErrorHelperView(error, helperText = null, warningText = null) {
  return (
    <div className={inputStyles.msgWrapper}>
      <div>
        {getErrorHelperViewWithoutWrapper(error, helperText, warningText)}
      </div>
    </div>
  );
}

export function getErrorHelperViewWithoutWrapper(error, helperText, warningText) {
  return (
    <Fragment>
      {!isBlank(error) && <div className={inputStyles.error}>{error}</div>}
      {isBlank(error) && !isBlank(warningText)
      && <div className={inputStyles.warning}>{warningText}</div>}
      {!isBlank(helperText) && <div className={inputStyles.help}>{helperText}</div>}
    </Fragment>
  );
}

/**
 * For Errors that do not belong to any one field
 * @param  {string} error
 */
export function getFormErrorView(error) {
  return !isBlank(error) && (
    <div className={inputStyles.formError}>{error}</div>
  );
}
