import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { genKey } from 'draft-js';
import draftEventProxy from '../../events/draftEventProxy';
import styles from './dropdown_list.css';

class DropdownList extends Component {
  constructor(props) {
    super(props);

    this.state = { focused: -1 };

    this.dismiss = this.dismiss.bind(this);

    this._eventKey;
    this._isMounted;
  }

  componentDidMount() {
    this._eventKey = genKey();
    this._isMounted = true;
    draftEventProxy.sub('arrow_up', this.handleArrow.bind(this), this._eventKey);
    draftEventProxy.sub('arrow_down', this.handleArrow.bind(this), this._eventKey);
    draftEventProxy.sub('blur', this.dismiss.bind(this), this._eventKey);
    draftEventProxy.sub('entityChanged', this.handleDelayedDismiss.bind(this), this._eventKey);
    draftEventProxy.sub('escape', this.dismiss.bind(this), this._eventKey);
    draftEventProxy.sub('return', this.handleReturn.bind(this), this._eventKey);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuery !== this.props.currentQuery) {
      this.setState({ focused: -1 });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    draftEventProxy.unsub('arrow_up', this._eventKey);
    draftEventProxy.unsub('arrow_down', this._eventKey);
    draftEventProxy.unsub('blur', this._eventKey);
    draftEventProxy.unsub('entityChanged', this._eventKey);
    draftEventProxy.unsub('escape', this._eventKey);
    draftEventProxy.unsub('return', this._eventKey);
    this.props.stuntReturn(null);
  }

  dismiss() {
    if (!this._isMounted) return;
    this.props.dismiss();
  }

  handleArrow(e) {
    if (!this._isMounted) return;

    // Traverse out of the entity if the cursor is currently in the entity and not in the dropdown.
    // If there is text above the current entity and UP is pressed, dismiss this dropdown.
    if (e.keyCode === 38 /* UP */ && this.state.focused === -1) {
      return this.props.dismiss();
    }

    e.preventDefault();
    this.props.stuntReturn('return'); // Return needs to be supressed so that we can select options without carriage return.

    if (e.keyCode === 40 /* DOWN */ && this.state.focused < this.props.options.length - 1) {
      this.setState({ focused: this.state.focused + 1 });
    } else if (e.keyCode === 38 /* UP */) {
      if (this.state.focused === 0) this.props.stuntReturn(null); // Remove stunt when cursor is focusing entity.
      this.setState({ focused: this.state.focused - 1 });
    }
  }

  handleDelayedDismiss({ currentEntity, previousEntity }) {
    if (this.props.entityKey === previousEntity && currentEntity === null) { // Cursor has moved away from this entity.
      setTimeout(() => {
        if (this._isMounted) this.props.dismiss();
      }, 20);
    }
  }

  handleReturn(e) {
    if (!this._isMounted) return;

    if (this.state.focused >= 0) {
      this.props.onSelect(this.props.options[this.state.focused]);
    } else {
      this.props.dismiss();
    }
  }

  render() {
    return (
      <ul className={styles.list}>
        {this.props.options.map((option, i) => (
          <li
            key={i}
            className={`${styles.listItem} ${this.state.focused === i ? styles.focused : ''}`}
            onClick={() => this.props.onSelect(option)}
            onMouseOver={(e) => this.setState({ focused: i })}
          >
            {this.props.templateFn(option)}
          </li>
        ))}
      </ul>
    );
  }
}

DropdownList.propTypes = {
  currentQuery: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
  entityKey: PropTypes.string, // Can start as null
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired, // List of users from Algolia.
  stuntReturn: PropTypes.func.isRequired,
  templateFn: PropTypes.func.isRequired,
};

export default DropdownList;
