import request from 'superagent';
import OAuth from '../services/oauth';

import { pollWithBackoff } from '../utility/polling';
import { tryJSONParse } from '../utility/json';

function checkJobStatus(jobId) {
  return OAuth.apiRequest(
    request
      .get(`${OAuth.getApiPath()}/private/background_jobs/${jobId}`),
    true,
  );
}

function fetchJobResult(jobId) {
  return OAuth.apiRequest(
    request
      .get(`${OAuth.getApiPath()}/private/scraper/${jobId}`),
    true,
  );
}

function pollScraperJob(jobId) {
  return pollWithBackoff((resolve, reject, retry) => (
    checkJobStatus(jobId)
      .then((res) => res.status === 'complete' ? resolve(jobId) : retry())
      .catch((err) => reject(err))
  ));
}

export default function fetchScrapedPage(url) {
  return OAuth.apiRequest(
    request
      .post(`${OAuth.getApiPath()}/private/scraper`)
      .send({ url }),
    true,
  )
    .then((body) => pollScraperJob(body.job_id))
    .then((jobId) => fetchJobResult(jobId))
    .then((res) => tryJSONParse(res.result))
    .then((page) => Promise.resolve(page))
    .catch((err) => Promise.reject(err));
}
