import React from 'react';
import PropTypes from 'prop-types';

import LazyBackgroundImage from '../../../image/LazyBackgroundImage';

import { getInObj } from '../../../../utility/accessors';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from '../news_card.css';

const NewsArticleSponsoredCardLargePreview = ({ article, isResponsive }) => (
  <div className={`${styles.sponsoredCard} ${styles.sponsoredCardLg}`}>
    <div className={`${styles.featuredImgWrapperSm} ${layout.fullWidth} ${utilStyles.posRelative}`} style={{ margin: 0 }}>
      <LazyBackgroundImage
        classList={{ image: utilStyles.borderRadius }}
        ratio="16:9"
        setStyle={false}
        src={getInObj(['image', 'url'], article)}
        width={555}
      />
    </div>
    <div className={`${styles.sponsoredDataWrapperLg} ${styles.marginLeftReset}`}>
      <p className={`${typography.bodyL} ${typography.bold}`}>{article.title}</p>
      <p className={`${typography.bodyM} ${layout.marginTop5}`}>
        {'Sponsored by '}
        {(article.sponsor_name && article.sponsor_name.length > 0)
        && (
          <span className={typography.linkBlue}>
            {article.sponsor_name}
          </span>
        )}
      </p>
    </div>
  </div>
);

NewsArticleSponsoredCardLargePreview.propTypes = {
  article: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string }),
    sponsor_link: PropTypes.string,
    sponsor_name: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isResponsive: PropTypes.bool,
};

NewsArticleSponsoredCardLargePreview.defaultProps = { isResponsive: true };

export default NewsArticleSponsoredCardLargePreview;
