import React from 'react';
import PropTypes from 'prop-types';

import DummyProjectsList from '../projects_list/DummyProjectsList';
import LazyComponent from '../../wrappers/lazy_component';
import ProjectsList from '../projects_list';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const BottomView = ({ initialized, onReveal, records }) => (
  <LazyComponent onReveal={onReveal} verticalOffset={250}>
    <div className={`${layout.container} ${layout.paddingBottom45} ${layout.paddingTop45}`}>
      <div className={layout.wrapper1170}>
        <h2 className={`${typography.h2} ${layout.marginBottom15}`}>
          Similar projects you might like
        </h2>
        {initialized
          ? (
            <ProjectsList
              cardBorder
              className={layout.marginBottom30}
              location="project_similar"
              records={records}
            />
            )
          : <DummyProjectsList />}
      </div>
    </div>
  </LazyComponent>
);

BottomView.propTypes = {
  initialized: PropTypes.bool.isRequired,
  onReveal: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
};

export default BottomView;
