import { deleteItem, getItem, setItem } from '../../local_storage';
import { mapifyStringQuery } from '../../../utility/converters';

const LS_KEY = 'hck.kn.utm';
const PARAM_NAMES = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
];

const emptyMap = PARAM_NAMES.reduce((acc, key) => ({ ...acc, [key]: '' }), {});

function _clearParams() {
  deleteItem(LS_KEY);
}

function _getParams() {
  return getItem(LS_KEY) || emptyMap;
}

function _mapHasParams(map) {
  return PARAM_NAMES.reduce((acc, key) => (acc || !!map[key]), false);
}

function _setParams(obj) {
  return setItem(LS_KEY, obj);
}

export function getUTMParams(queryString = '', sessionExpired = false) {
  const fullMap = mapifyStringQuery(queryString);

  const map = PARAM_NAMES.reduce((acc, key) => (
    { ...acc, [key]: fullMap[key] || '' }
  ), {});

  if (_mapHasParams(map)) {
    _setParams(map);

    return map;
  } else if (sessionExpired) {
    _clearParams();

    return emptyMap;
  }

  return _getParams();
}
