import React from 'react';
import PropTypes from 'prop-types';

import animation from '../../../styles/global_ui/animation.css';
import typography from '../../../styles/global_ui/typography.css';

const RingSpinner = ({ className, size }) => (
  <svg className={`${animation.ringSpinner} ${typography['icon' + size]} ${className}`} viewBox="25 25 50 50">
    <circle cx="50" cy="50" fill="none" r="20" strokeMiterlimit="10" strokeWidth="2" />
  </svg>
);

RingSpinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

RingSpinner.defaultProps = {
  className: '',
  size: 'fill',
};

export default RingSpinner;
