import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { IntersectionObserver } from '../../../../../utility/observers';

import ActionButtons from './ActionButtons';
import StickyWrapper from '../../../../../client/wrappers/sticky_wrapper';

import layout from '../../../../../styles/global_ui/layout.css';
import lazyImageStyles from '../../../../../client/reusable_components/LazyImage/lazy_image.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import styles from './sticky_actions.css';

const STICKY_SIDEBAR_ID = 'articleStickySidebar';

class ArticleStickySidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { visible: !props.shouldFadeIn };

    this.setVisible = this.setVisible.bind(this);

    this._sentinelRef = React.createRef();
    this._intersectionObserver;
  }

  componentDidMount() {
    if (!this.props.shouldFadeIn) return;

    this._intersectionObserver = new IntersectionObserver(this.setVisible);
    this._intersectionObserver.observe(this._sentinelRef.current);

    this.setVisible();
  }

  componentWillUnmount() {
    if (!this.props.shouldFadeIn) return;

    this._intersectionObserver.disconnect();
    this._intersectionObserver = undefined;
  }

  setVisible() {
    const el = this._sentinelRef && this._sentinelRef.current;
    if (el) {
      const dims = el.getBoundingClientRect();
      const visible = dims.top < 0;

      if (visible !== this.state.visible) {
        this.setState({ visible });
      }
    }
  }

  render() {
    const { article, disabled, social_sharing_links } = this.props;

    return (
      <div
        className={`${utilStyles.posRelative} ${layout.marginRight30} ${layout.hiddenMedDown} ${styles.zIndex10}`}
        id={STICKY_SIDEBAR_ID}
      >
        <div ref={this._sentinelRef} />

        <div className={styles.stickyButton} />
        {' '}
        {/* Placeholder to maintain width when sticky content is "fixed" */}
        <StickyWrapper parentId={STICKY_SIDEBAR_ID} startBuffer="50vh" stickToEnd={true}>
          <div className={`${lazyImageStyles.fade} ${this.state.visible ? lazyImageStyles.fadeIn : ''}`}>
            <ActionButtons article={article} disabled={disabled} social_sharing_links={social_sharing_links} />
          </div>
        </StickyWrapper>
      </div>
    );
  }
}

ArticleStickySidebar.propTypes = {
  article: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  shouldFadeIn: PropTypes.bool,
  social_sharing_links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

ArticleStickySidebar.defaultProps = {
  disabled: false,
  shouldFadeIn: false,
  social_sharing_links: [],
};

export default ArticleStickySidebar;
