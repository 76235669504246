export default function getPlaceholderText(isProfileOwner, type, args = {}) {
  const asProfileViewer = {
    ['no-communities']: [`${args.name} has not joined a community.`],
    ['no-follows']: [`${args.name} isn't following anyone.`],
    ['no-followers']: [`${args.name} has no followers.`],
    ['no-product']: ['This product does not exist'],
    ['no-products']: ['There are no products.', args.canManage && { href: args.manageProductsLink, text: 'Add products' }],
    ['no-projects']: ['There are no projects.', args.canManage && { href: '/projects/new', text: 'Create a new project' }],
    ['no-public-projects']: [`${args.name} hasn't publicly shared any projects.`],
    ['no-published-articles']: [`${args.name} hasn't published any articles.`],
    ['no-respects']: [`${args.name} has no respects.`],
    ['no-tools']: [`${args.name} hasn't added any products to their interests.`],
  };

  const asProfileOwner = {
    ['no-bio']: [{ text: 'Add a bio', href: '/dashboard/profile' }],
    ['no-communities']: [{ href: '/channels', text: 'Join a community' }],
    ['no-draft-projects']: ['You aren\'t currently working on any projects.  ', { href: '/projects/new', text: 'Create a new project' }],
    ['no-follows']: ['You aren\'t following anyone.'],
    ['no-followers']: ['No one is following you.'],
    ['no-forHire']: [{ text: 'Add availability', href: '/dashboard/profile' }],
    ['no-location']: [{ text: 'Add your location', href: '/dashboard/profile' }],
    ['no-notifications']: ['You don\'t have any notifications. Get updates from your projects, connections, and communities by managing your ', { href: '/users/notifications/edit', text: 'notification settings' }],
    ['no-projects']: [{ href: '/projects/new', text: 'Create a new project ' }, 'and start sharing your work!'],
    ['no-public-projects']: ['You haven\'t publicly shared any projects. ', { href: '/projects/new', text: 'Create a project' }],
    ['no-published-articles']: ['You haven\'t published any articles. ', { href: '/news/admin/new', text: 'Write an article' }],
    ['no-skills']: [{ text: 'Add skills', href: '/dashboard/profile' }],
    ['no-tools']: [{ href: '/onboarding/interests?filter=products', text: 'Add products' }],
    ['with-public-without-drafts']: ['You aren\'t currently working on any projects.  ', { href: '/projects/new', text: 'Create a new project' }, ' or ', { href: '/dashboard/projects', text: 'view your existing projects' }, '.'],
  };

  return isProfileOwner ? asProfileOwner[type] : asProfileViewer[type];
}
