import React from 'react';

import SocialIcons from '../../../nav/social_icons';

import keenService from '../../../../services/keen/main';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './sticky_footer.css';

const handleSocialClick = ({ delayRedirect, e, type, value }) => {
  keenService.recordLinkClick({}, { location: 'Welcome footer', type, value }, delayRedirect, e);
};

const SocialFooterView = () => (
  <div className={layout.flexJustifyCenter}>
    <div className={styles.socialFooterBodyWrapper}>
      <p className={`${typography.bodyL} ${typography.graphite} ${styles.socialFooterBody}`}>
        <strong className={layout.marginRight10}>Welcome to Hackster!</strong>
        <span>Be sure to follow us to stay up to date with the latest news & projects.</span>
      </p>
      <SocialIcons className={styles.socialList} trackLinkClick={handleSocialClick} />
    </div>
  </div>
);

export default SocialFooterView;
