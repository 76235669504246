import { CharacterMetadata } from 'draft-js';
import { fromJS } from 'immutable';

import { doesPreviousWordContainValidUrl,
         getPreviousWord,
         removePreviousWord,
         splitBlockAndInsertCustomBlock } from '../utils/draftUtils';

import { getTwitterIdFromUrl } from '../utils/Helpers';

// TODO: Rewrite all the functions here using the new helpers in the utils/draft folder.
// Have these fns take an editorState and return the same!
export function insertVideoComponent(states) {
  const { currentBlock, currentSelection, currentContentState, editorState } = states;

  const embedData = doesPreviousWordContainValidUrl(currentBlock, currentSelection, 'VIDEO');
  const contentStateWithEntity = currentContentState.createEntity('TOKEN', 'IMMUTABLE', { embedData: fromJS({ ...embedData, model: 'Video', key: 'video' }) });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const charData = CharacterMetadata.create({ entity: entityKey });

  const { updatedContentState, updatedSelection } = removePreviousWord(currentBlock, currentSelection, contentStateWithEntity);
  return splitBlockAndInsertCustomBlock(editorState, updatedContentState, updatedSelection, charData, 'EMBED');
}

export function insertTweetComponent(states) {
  const { currentBlock, currentSelection, currentContentState, editorState } = states;
  const prevWord = getPreviousWord(currentBlock, currentSelection).split('?')[0];
  const tweetID = getTwitterIdFromUrl(prevWord);
  const contentStateWithEntity = currentContentState.createEntity('TOKEN', 'IMMUTABLE', { twitterData: fromJS({ id: tweetID, embed: prevWord, model: 'WidgetPlaceholder', type: 'twitter' }) });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const charData = CharacterMetadata.create({ entity: entityKey });

  const { updatedContentState, updatedSelection } = removePreviousWord(currentBlock, currentSelection, contentStateWithEntity);
  return splitBlockAndInsertCustomBlock(editorState, updatedContentState, updatedSelection, charData, 'TWEET');
}

export function insertGistComponent(states) {
  const { currentBlock, currentSelection, currentContentState, editorState } = states;

  const prevWord = getPreviousWord(currentBlock, currentSelection);
  const contentStateWithEntity = currentContentState.createEntity('TOKEN', 'IMMUTABLE', { embedData: fromJS({ embed: prevWord, type: 'iframe', service: 'gist', model: 'Embed', key: 'data' }) });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const charData = CharacterMetadata.create({ entity: entityKey });

  const { updatedContentState, updatedSelection } = removePreviousWord(currentBlock, currentSelection, contentStateWithEntity);
  return splitBlockAndInsertCustomBlock(editorState, updatedContentState, updatedSelection, charData, 'EMBED');
}

export function insertEmbedlyComponent(states) {
  const { currentBlock, currentSelection, currentContentState, editorState } = states;

  const prevWord = getPreviousWord(currentBlock, currentSelection);
  const contentStateWithEntity = currentContentState.createEntity('TOKEN', 'IMMUTABLE', { embedData: fromJS({ embed: prevWord, type: 'iframe', service: 'embedly', model: 'Embed', key: 'data' }) });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const charData = CharacterMetadata.create({ entity: entityKey });

  const { updatedContentState, updatedSelection } = removePreviousWord(currentBlock, currentSelection, contentStateWithEntity);
  return splitBlockAndInsertCustomBlock(editorState, updatedContentState, updatedSelection, charData, 'EMBED');
}
