/* This prop type map contains prop types that cannot be inferred
   by the following rules:
   1. if the key is event_id, it's a string - this is for deduping "server-side" events
   2. else, if the key ends in _id, it's a number,
   3. else, it's a string

   The full prop map is at the bottom of the page
*/

const eventNameWhitelistAndPropTypes = {
  'Ad clicked': { id: 'number' }, // Templated
  'Ad viewed': { id: 'number', blocked: 'boolean' }, // Templated
  'Added project to contest': {},
  'Added project to list': {},
  'Changed notification settings': {},
  'Clicked filter value': {},
  'Clicked button': {}, // Templated
  'Clicked launch campaign': {},
  'Clicked link': {},
  'Clicked menu item': {}, // Templated
  'Clicked part buy link': { link_position: 'number' },
  'Clicked view all section': {},
  'Clicked view more': {}, // Templated
  'Completed onboarding': {},
  'Completed onboarding step': {}, // DEPRECATED
  'Confirmed email address': {},
  'Created account': {},
  'Created bookmark': {}, // Templated
  'Created bookmark list': {}, // Templated
  'Created comment': {}, // Templated (NOTE: for Discussion 2019, not project page)
  'Created event': {}, // Templated
  'Created post': {}, // Templated
  'Created project': {},
  'Deleted account': {},
  'Deleted bookmark': {}, // Templated
  'Deleted bookmark list': {}, // Templated
  'Deleted comment': {}, // Templated (NOTE: for Discussion 2019, not project page)
  'Deleted event': {}, // Templated
  'Deleted post': {}, // Templated
  'Dismissed tooltip': {},
  'Entered feature gate': { feature_gate: 'string', feature_enabled: 'boolean', rate: 'number' },
  'Followed entity': {},
  'Joined experiment': {},
  'Logged in': { method: 'string' },
  'Logged out': {},
  'Made project public': {},
  'Opened bookmark menu': {}, // Templated
  'Opened explore menu': {}, // DEPRECATED
  'Opened menu': {},
  'Posted comment': {},
  'Posted feed post': {},
  'Registered for contest': {}, // Templated
  'Registered for workshop': {},
  'Respected entity': {},
  'Searched': {
    filters: {
      // project search filters
      parts: 'array',
      project_type: 'array',
      difficulty: 'array',
      language: 'array',

      // people search filters
      skills: 'array',
      available_for_hire: 'boolean',
      location: { // also for event search
        geoloc: {
          lat: 'number',
          lng: 'number',
        },
        distance: 'number',
      },
    }, // end filters
    pagination: {
      current_page: 'number',
      per_page: 'number',
      total_pages: 'number',
      total_records: 'number',
    },
  },
  'Search filter': {},
  'Search location filter': {},
  'Sent invites': { count: 'number' },
  'Signup prompt clicked': {},
  'Signup prompt shown': {},
  'Submitted idea to contest': {}, // Templated
  'Submitted project to contest': {}, // Templated
  'Unfollowed entity': {},
  'Updated comment': {}, // Templated (NOTE: for Discussion 2019, not project page)
  'Updated post': {}, // Templated
  'Unregistered for contest': {}, // Templated
  'Unrespected entity': {},
  'Viewed lock screen': { projects_viewed: 'number' },
};

export function isValidEventName(eventName) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return eventNameWhitelistAndPropTypes.hasOwnProperty(eventName);
}

export function getPropTemplate(eventName) {
  return eventNameWhitelistAndPropTypes[eventName];
}

/* Full event prop type map below.

const fullPropMap = {
  'Added project to contest': {
    event_id: 'string',
    entity_id: 'number',
    entity_type: 'string',
    project_id: 'number'
  },
  'Added project to list': {
    list_id: 'number',
    list_name: 'string',
    project_hid: 'string'
  },
  'Changed notification settings': {
    action: 'string', // subscribed/unsubscribed
    method: 'string', // form/direct_link
    name: 'string'
  },
  'Clicked filter value': {
    filter_name: 'string',
    filter_value: 'string'
  },
  Clicked launch campaign: {
    campaign_id: number,
    campaign_name: string, // Url string
    href: 'string',
    location: string, // What component called the event
    value: string // Title of campaign
  }
  'Clicked link': {
    href: 'string',
    location: 'string',  // What component called the event
    type: 'string',      // i.e. 'social'
    value: 'string'      // i.e. 'facebook'
    entity_type: 'string',
    entity_id: 'number'
  },
  'Clicked part buy link': {
    part_id: 'number',
    link_id: 'number',
    retailer_id: 'number',
    link_position: 'number',
  },
  'Clicked view all section': {
    item: {
      id: 'number',
      index: 'number',
      type: 'string'
    },
    section: {
      key: 'string',
      index: 'number',
      value: ('string' || 'number')
    }
  },
  'Completed onboarding': {
    entity_id: 'number'
  },
  'Completed onboarding step': {  // DEPRECATED
    step_name: 'string'
  },
  'Confirmed email address': {
    event_id: 'string'
  },
  'Created account': {
    event_id: 'string',
    platform: 'string',
    provider: 'string',
    location: 'string',
    source: 'string',
    type: 'string'
  },
  'Created project': {
    event_id: 'string',
    project_id: 'number'
  },
  'Deleted account': {
    event_id: 'string'
  },
  'Dismissed tooltip': {},
  'Followed entity': {
    entity_id: 'number',
    entity_type: 'string'
  },
  'Joined experiment': {
    exp_name: 'string',
    value: 'string'
  },
  'Logged in': {
    event_id: 'string'
  },
  'Logged out': {
    event_id: 'string'
  },
  'Made project public': {
    event_id: 'string',
    project_id: 'number'
  },
  'Opened explore menu': {}, // DEPRECATED
  'Opened menu': {
    type: 'string'
  },
  'Posted comment': {
    comment_id: 'number'
  },
  'Posted feed post': {
    post_hid: 'string'
  },
  'Registered for contest': {
    event_id: 'string',
    entity_id: 'number',
    entity_type: 'string'
  },
  'Registered for workshop': {
    discount_codes: 'array',
    event_date: 'data',
    event_id: 'string',
    skus: 'array',
    total_discounts: 'number',
    total_price: 'number',
    workshop_ids: 'array'
  },
  'Respected entity': {
    entity_id: 'number',
    entity_type: 'string'
  },
  'Searched': {
    filters: {
      // project search filters
      parts: 'array',
      project_type: 'array',
      difficulty: 'array',
      language: 'array',

      // people search filters
      skills: 'array',
      available_for_hire: 'boolean',
      location: { // also for event search
        city: 'string',
        country_iso2: 'string',
        geoloc: {
          lat: 'number',
          lng: 'number',
        },
        state: 'string',
        distance: 'number',
      },
    }, // end filters
    pagination: {
      current_page: 'number',
      per_page: 'number',
      total_pages: 'number',
      total_records: 'number'
    },
    query: 'string',
    result_type: 'string',
    search_type: 'string',
    sort: 'string'
  },
  'Search filter': {
    query: 'string'
  },
  'Sent invites': {
    count: 'number'
  },
  'Signup prompt clicked': {},
  'Signup prompt shown': {},
  'Submitted idea to contest': {
    event_id: 'string',
    entity_id: 'number',
    entity_type: 'string',
    idea_id: 'number'
  },
  'Submitted project to contest': {
    event_id: 'string',
    entity_id: 'number',
    entity_type: 'string',
    project_id: 'number'
  },
  'Unfollowed entity': {
    entity_id: 'number',
    entity_type: 'string'
  },
  'Unregistered for contest': {
    event_id: 'string',
    entity_id: 'number',
    entity_type: 'string'
  },
  'Unrespected entity': {
    entity_id: 'number',
    entity_type: 'string'
  },
  'Viewed lock screen': {
    projects_viewed: 'number',
    type: 'string',
  }
};

*/
