import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import inputStyles from '../../../../../styles/global_ui/inputs.css';

import styles from '../datetime.css';

class MobileInputWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this._inputRef = React.createRef();
  }

  render() {
    return (
      <div
        className={`${styles.wrapperMobile} ${inputStyles.input} ${this.props.errors ? inputStyles.inputError : ''}`}
        onFocus={() => this._inputRef.current && this._inputRef.current.focus()}
        tabIndex={this.props.disabled ? null : 0}
      >
        <Fragment>
          {this.props.children}
          <input
            ref={this._inputRef}
            autoComplete={this.props.autoComplete}
            className={inputStyles.hiddenInput}
            disabled={this.props.disabled}
            max={this.props.max}
            onChange={this.props.onChange}
            type={this.props.type}
            value={this.props.value}
          />
        </Fragment>
      </div>
    );
  }
}

MobileInputWrapper.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string.isRequired,
};

MobileInputWrapper.defaultProps = {
  autoComplete: null,
  disabled: false,
  errors: null,
  onChange: () => {},
};

export default MobileInputWrapper;
