import { graphMutate } from '../../../requests/graphql';

import errorHandler from '../../../services/error_handler';
import { getErrorMsg } from '../../global_components/messenger/messages';
import { capitalize, unsnakeString } from '../../../utility/formatters';
import { summonGlobalMessenger } from '../../../utility/dispatchers';

import keenService from '../../../services/keen/main';
import {
  getCreatePostArgs,
  getDeletePostArgs,
  getUpdatePostArgs,
} from '../../../services/keen/events/eventTypeTemplates';

/***********************
  MUTATIONS
/***********************

/**
 * Create Post
 */
const createNewPost = ({ body, category, created_at, entities, id, relations }, props) => ({
  body,
  category,
  created_at,
  id,
  relations,
  comments: [],
  edited_at: null,
  entities: (entities || {}),
  respects_count: 0,
  user: {
    ...props.currentUser,
    isAdmin: props.currentUser.role === 'admin',
  },
  __meta: { truncate: false },
});

export const fireCreatePostAnalytics = (post, postFromEditor) => (
  keenService.reportEventWithObj(getCreatePostArgs(post.id, postFromEditor.category))
);

export function createPost({ post, failureFn, resolverFn }) {
  this._safelySetState({ isBusy: true });

  return graphMutate({ t: 'create_feed_post' }, {
    ...post,
    origin_id: this.props.origin.id,
    origin_type: this.props.origin.type,
  })
    .then(({ feed_post }) => {
      this._safelySetState({
        isBusy: false,
        offset: this.state.offset + 1,
        records: [createNewPost({ ...post, ...feed_post }, this.props)].concat(this.state.records),
      }, () => {
        if (typeof resolverFn === 'function') resolverFn();
      });

      fireCreatePostAnalytics(feed_post, post);
    })
    .catch((err) => {
      this._safelySetState({ isBusy: false });
      if (typeof failureFn === 'function') failureFn();
      errorHandler('Discussion createPost: ', err);
    });
}

/**
 * Delete Post
 */
export const fireDeletePostAnalytics = (post) => (
  keenService.reportEventWithObj(getDeletePostArgs(post.id, post.category))
);

export function deletePost(post, resolverFn) {
  this._safelySetState({ isBusy: true });

  if (typeof resolverFn === 'function') resolverFn(); // Close menu immediately

  return graphMutate({ t: 'delete_feed_post' }, { id: post.id })
    .then(() => _deletePostResolver.call(this, post))
    .catch((err) => {
      this._safelySetState({ isBusy: false });
      errorHandler('Discussion deletePost: ', err);
      summonGlobalMessenger({ msg: getErrorMsg('deleting the post'), type: 'error' });
    });
}

// When markAsSpam resolves, a worker will save a copy of the post and delete it. To avoid having to poll the worker, we're
// going to go ahead and trust the system and remove the post from memory here.
export function deletePostViaSpam(post) {
  return _deletePostResolver.call(this, post);
}

function _deletePostResolver(post) {
  this._safelySetState({
    isBusy: false,
    offset: this.state.offset - 1, // We want this to go into negatives.
    pinnedPosts: this.state.pinnedPosts.filter((record) => record.id !== post.id),
    records: this.state.records.filter((record) => record.id !== post.id),
  }, () => {
    summonGlobalMessenger({ msg: 'Post successfully deleted.', type: 'success' });

    if (this.state.currentView === 'singular') this.props.transition({ pathname: '/' });
    if (this._windower) this._windower.__deleteFromMeta(post.id);
  });

  fireDeletePostAnalytics(post);
}

/**
 * Update Post
 */
export const fireUpdatedPostAnalytics = (post) => (
  keenService.reportEventWithObj(getUpdatePostArgs(post.id, post.category))
);

export function updatePost({ post, oldPost, failureFn, resolverFn }) {
  this._safelySetState({ isBusy: true });

  return graphMutate({ t: 'update_feed_post' }, post)
    .then(({ feed_post }) => _updatePostResolver.call(this, {
      updatedPost: { ...post, ...feed_post, __meta: { truncate: false } },
      oldPost,
      resolverFn,
    }))
    .catch((err) => {
      this._safelySetState({ isBusy: false });
      if (typeof failureFn === 'function') failureFn();
      errorHandler('Discussion updatePost: ', err);
    });
}

function _updatePostResolver({ updatedPost, oldPost, resolverFn }) {
  const shouldFilterRecordFromState = (updatedPost.category !== oldPost.category && this.state.currentView === 'category');
  const records = shouldFilterRecordFromState
    ? this.state.records.filter((record) => record.id !== updatedPost.id)
    : this.state.records.map((record) => record.id === updatedPost.id ? { ...record, ...updatedPost } : record);

  this._safelySetState({
    isBusy: false,
    pinnedPosts: this.state.pinnedPosts.map((p) => p.id === updatedPost.id ? { ...p, ...updatedPost } : p),
    records,
  }, () => {
    if (typeof resolverFn === 'function') resolverFn();
  });

  if (shouldFilterRecordFromState) summonGlobalMessenger({ msg: `Post moved to ${unsnakeString(updatedPost.category, capitalize)}.` });
  fireUpdatedPostAnalytics(updatedPost);
}
