import React from 'react';
import PropTypes from 'prop-types';
import Prize from './Prize';
import { getIdOrUuid } from '../../../../../utility/accessors';

const getErrorsForPrize = (errors, prize) => {
  const id = getIdOrUuid(prize);
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return errors && errors.hasOwnProperty(id) ? errors[id] : {};
};

const getPrizeLabelForIndex = (i) => {
  switch (i) {
    case 0:
      return '1st Place';
    case 1:
      return '2nd Place';
    case 2:
      return '3rd Place';
    default:
      return null;
  }
};

const RankedPrizeList = ({ errors, prizes, updatePrize }) => (
  <div>
    {prizes.map((prize, i) => (
      <Prize
        {...prize}
        key={prize.id || prize.uuid}
        allowQuantity
        errors={getErrorsForPrize(errors, prize)}
        label={getPrizeLabelForIndex(i)}
        updateAttribute={(key, value) => updatePrize(key, value, prize)}
      />
    ))}
  </div>
);

RankedPrizeList.propTypes = {
  errors: PropTypes.shape({ [PropTypes.string]: PropTypes.shape({ [PropTypes.string]: PropTypes.string }) }),
  prizes: PropTypes.arrayOf(PropTypes.shape({
    cash_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
    id: PropTypes.number,
    image_url: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.number,
    quantity: PropTypes.number,
    requires_shipping: PropTypes.bool,
    uuid: PropTypes.string,
  })).isRequired,
  updatePrize: PropTypes.func.isRequired,
};

RankedPrizeList.defaultProps = { errors: null };

export default RankedPrizeList;
