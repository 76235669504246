import currentUserService from '../current_user';
import errorHandler from '../error_handler';
import keenService from '../keen/main';
import { getClickedAdArgs, getViewedAdArgs } from '../keen/events/eventTypeTemplates';
import { graphMutateWithoutUser } from '../../requests/graphql';

class AdAnalytics {
  constructor() {
  }

  /**
   * Helpers
   */
  _incrementImpression(ad) {
    return new Promise((resolve, reject) => graphMutateWithoutUser({ t: 'update_ad_impression' }, { id: ad.id })
      .then(({ success }) => resolve(success))
      .catch((err) => reject(err)));
  }

  /**
   * Public
   */
  reportClick(ad) {
    keenService.reportEventWithObj(getClickedAdArgs({ id: ad.id }));
  }

  // Does not report if the current user is an admin. KeenService does this internally.
  reportImpression(ad) {
    return currentUserService.getAsync('isAdmin')
      .then((isAdmin) => isAdmin ? Promise.resolve(true) : this._incrementImpression(ad))
      .then((success) => {})
      .catch((err) => errorHandler('Analytics reportImpression', err));
  }

  reportView(ad, meta = {}) {
    keenService.reportEventWithObj(getViewedAdArgs({ id: ad.id, ...meta }));
    this.reportImpression(ad);
  }
}

const adAnalyticsService = new AdAnalytics();
export default adAnalyticsService;
