/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
export const LOCATION_KEYS = ['city', 'countryCode', 'distance', 'geoloc', 'state'];

export function validateLocationObj(loc) {
  let valid = true;
  LOCATION_KEYS.forEach((key) => {
    if (key === 'geoloc' && (!loc.hasOwnProperty('geoloc') || !loc.geoloc.hasOwnProperty('lat') || !loc.geoloc.hasOwnProperty('lng'))) {
      valid = false;
    } else if (!loc.hasOwnProperty(key)) {
      valid = false;
    }
  });

  return valid;
}
