import { MD_SETTINGS, REGEX } from './constants';

import formatBlock from './formatBlock';
import formatAdjacent from './formatAdjacent';

function _getCodeLabel({ textAreaValue, selectedStr, selectionRange }) {
  const rows = selectedStr.split(REGEX.linebreak);

  return rows.length > 1
    ? 'code_fence'
    : 'code_inline';
}

export default function formatSelection(formattingData) {
  const toolLabel = formattingData.toolLabel === 'code' ? _getCodeLabel(formattingData) : formattingData.toolLabel;

  return MD_SETTINGS[toolLabel].formatBlock
    ? formatBlock({ ...formattingData, toolLabel, mdSettings: MD_SETTINGS[toolLabel] })
    : formatAdjacent({ ...formattingData, toolLabel, mdSettings: MD_SETTINGS[toolLabel] });
}
