import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { NOOP_HREF } from '../../../constants/links';
import { pluralize } from '../../../utility/formatters';

/**
 * Views
 */
const getActionsForUser = ({ comment, createLike, currentUser, deleteLike, newCommentsDisabled, onReplyClick, parentIsDeleted }) => (
  <Fragment>
    {getLikeBtn({ comment, createLike, currentUser, deleteLike })}
    {getReplyBtn({ comment, currentUser, newCommentsDisabled, onReplyClick, parentIsDeleted })}
    {getLikesCounter({ comment })}
  </Fragment>
);

const getLikeBtn = ({ comment, createLike, currentUser, deleteLike }) => {
  const currentUserLiked = (comment.liking_user_ids.indexOf(currentUser.id) > -1);
  const onClick = currentUserLiked ? deleteLike : createLike;

  return (
    <li className="r-comment-like-button">
      <a
        href={NOOP_HREF}
        onClick={onClick}
      >
        {currentUserLiked ? 'Thanked' : 'Thank'}
      </a>
    </li>
  );
};

const getLikesCounter = ({ comment }) => {
  if (!comment.liking_user_ids.length) return null;

  return (
    <li className="r-comments-counter">
      <span>
        {comment.liking_user_ids.length}
        {' '}
        {pluralize('thank', comment.liking_user_ids.length)}
      </span>
    </li>
  );
};

const getReplyBtn = ({ comment, currentUser, newCommentsDisabled, onReplyClick, parentIsDeleted }) => {
  if (!currentUser.isConfirmed || newCommentsDisabled) return null;

  if (parentIsDeleted) return (<li className="text-muted">(Discussion closed)</li>);

  return (
    <li>
      <a href={NOOP_HREF} onClick={onReplyClick}>
        {comment.depth === 0 ? 'Reply' : 'Reply to conversation'}
      </a>
    </li>
  );
};

/**
 * Main
 */
const CommentActions = ({ comment, createLike, currentUser, deleteLike, newCommentsDisabled, onReplyClick, parentIsDeleted }) => {
  if (!currentUser && !comment.liking_user_ids.length) return null;

  return (
    <ul className="comment-actions">
      {currentUser
        ? getActionsForUser({ comment, createLike, currentUser, deleteLike, newCommentsDisabled, onReplyClick, parentIsDeleted })
        : getLikesCounter({ comment })}
    </ul>
  );
};

CommentActions.propTypes = {
  comment: PropTypes.shape({ liking_user_ids: PropTypes.array.isRequired }).isRequired,
  createLike: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
  }),
  deleteLike: PropTypes.func.isRequired,
  newCommentsDisabled: PropTypes.bool.isRequired,
  onReplyClick: PropTypes.func.isRequired,
  parentIsDeleted: PropTypes.bool.isRequired,
};

CommentActions.defaultProps = { currentUser: null };

export default CommentActions;
