/**
 * Templating arrays.
 * The strings that are not surrounded by the template tokens (<><>) must be an address property.
 */
const standard = {
  default: ['city', '<>,<>', '<> <>', 'state', '<> <>', 'zip'],
  diff: ['city', '<>,<>', '<> <>', 'state', '<> <>', 'zip'],
};
const standardNoComma = {
  default: ['city', '<> <>', 'state', '<> <>', 'zip'],
  diff: ['city', '<> <>', 'state', '<> <>', 'zip'],
};
const zipCityState = {
  default: ['zip', '<> <>', 'city', '<> <>', 'state'],
  diff: ['zip', '<> <>', 'city', '<> <>', 'state'],
};
const zipCity = {
  default: ['zip', '<> <>', 'city'],
  diff: ['zip', '<> <>', 'city'],
};

const ISO2_STANDARD_LIST = ['US'];
const ISO2_STANDARD_NO_COMMA_LIST = ['CA', 'IN'];
const hasValuesWithLen = (values, obj) => values.every((val) => obj[val]?.length > 0);

export default function getFormatForAddressLine3(address, forDiff = false) {
  const key = forDiff ? 'diff' : 'default';

  if (ISO2_STANDARD_LIST.includes(address.country_iso2)) {
    return standard[key];
  } else if (ISO2_STANDARD_NO_COMMA_LIST.includes(address.country_iso2)) {
    return standardNoComma[key];
  }

  return hasValuesWithLen(['city', 'state', 'zip'], address) ? zipCityState[key] : zipCity[key];
}
