import React from 'react';
import PropTypes from 'prop-types';

import { fireClickedLinkAnalytics, fireClickedLinkAnalyticsWithRedirect } from '../../services/keen/mainServiceOperators';
import { getClickedLinkArgs } from '../../services/keen/events/eventTypeTemplates';
import { getEntityType } from '../../services/keen/events/linkConstants';

import typography from '../../styles/global_ui/typography.css';

const DEFAULT_EXTERNAL_LINK_ATTRIBUTES = {
  rel: 'noopener noreferrer',
  target: '_blank',
};

const _buildLinkData = (item, linkType, location, value) => ({
  entity_id: item.id,
  entity_type: getEntityType(linkType),
  href: item.url,
  location,
  type: linkType,
  value,
});

const _getOtherAttributes = (isExternal, customAttributes) => {
  const externalLinkAttributes = isExternal ? DEFAULT_EXTERNAL_LINK_ATTRIBUTES : {};

  return { ...externalLinkAttributes, ...customAttributes };
};

const _reportLink = (linkData, shouldDelayRedirect, itemUrl, e) => (
  shouldDelayRedirect
    ? fireClickedLinkAnalyticsWithRedirect(linkData, itemUrl, e)
    : fireClickedLinkAnalytics(linkData, e)
);

const handleClick = ({ e, item, itemIndex, linkType, location, overrideTracking, shouldDelayRedirect, value }) => {
  const linkData = _buildLinkData(item, linkType, location, value);

  if (typeof overrideTracking === 'function') {
    const event = getClickedLinkArgs(linkData, e);

    /**
     * overrideTracking
     * @param  {object} e - the DOM event
     * @param  {object} links - internal and external links to be used by router or redirect
     * @param  {object} item - the id, index, and type of the entity this event is associated with
     * @param  {array} events - events that would have been fired without this override
     */
    overrideTracking(e, {}, { id: item.id, index: itemIndex, type: linkType }, [event]);
  } else {
    _reportLink(linkData, shouldDelayRedirect, item.url, e);
  }
};

const AnalyticsLink = ({
  className,
  customAttributes,
  isExternal,
  item,
  itemIndex,
  linkType,
  children,
  location,
  overrideTracking,
  shouldDelayRedirect,
  style,
  value,
}) => (
  <a
    className={`${typography.link} ${className}`}
    href={item.url}
    onClick={(e) => handleClick({ e, item, itemIndex, linkType, location, overrideTracking, shouldDelayRedirect, value })}
    style={style}
    {..._getOtherAttributes(isExternal, customAttributes)}
  >
    {children}
  </a>
);

AnalyticsLink.propTypes = {
  className: PropTypes.string,
  customAttributes: PropTypes.object, // To override DEFAULT_EXTERNAL_LINK_ATTRIBUTES if necessary
  isExternal: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string.isRequired,
  }).isRequired,
  itemIndex: PropTypes.number,
  linkType: PropTypes.string.isRequired,
  location: PropTypes.string,
  overrideTracking: PropTypes.func,
  shouldDelayRedirect: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
};

AnalyticsLink.defaultProps = {
  className: '',
  customAttributes: {},
  isExternal: false,
  itemIndex: null,
  location: '',
  overrideTracking: null,
  shouldDelayRedirect: false,
  style: null,
  value: '',
};

export default AnalyticsLink;
