import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';

import cardStyles from '../cards.css';
import dummyStyles from './dummy_card.css';
import styles from './project_card.css';

const DummyProjectCard = ({ cardBorder, classList }) => (
  <div className={classList.wrapper || cardStyles.wrapper}>
    <div className={`${cardBorder ? cardStyles.cardWithBorder : cardStyles.card} ${classList.card}`}>
      <div className={styles.imageContainer}>
        <div className={`${styles.itemImage} ${dummyStyles.itemImage}`} />
      </div>

      <div className={cardStyles.body}>
        <div className={dummyStyles.contentType} />
        <div className={dummyStyles.title} />
        <div className={dummyStyles.author} />
      </div>

      <section className={styles.moreInfo}>
        <div className={dummyStyles.stats}>
          <div className={dummyStyles.thumbs}>
            <Icon name="thumbs-up" size={12} />
            <span className={dummyStyles.statNumber} />
          </div>
          <div className={dummyStyles.views}>
            <Icon name="eye" size={16} />
            <span className={dummyStyles.statNumber} />
          </div>
        </div>
        <div className={dummyStyles.difficulty} />
      </section>
    </div>
  </div>
);

DummyProjectCard.propTypes = {
  cardBorder: PropTypes.bool,
  classList: PropTypes.shape({
    card: PropTypes.string,
    wrapper: PropTypes.string,
  }),
};

DummyProjectCard.defaultProps = {
  cardBorder: false,
  classList: {},
};

export default DummyProjectCard;
