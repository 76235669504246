import keenService from './main';
import { isBlank } from '../../utility/types';
import { filterObject } from '../../utility/filters';
import {
  getClickedFilterArgs,
  getClickedLinkArgs,
  getClickedViewAllSectionArgs,
  getSearchFilterArgs,
  getSearchLocationFilterArgs,
} from './events/eventTypeTemplates';

const LOCATION_WHITELIST = ['city', 'country_iso2', 'latitude', 'longitude', 'state'];

/**
 * Helpers for fire and forget analytics reporting
 */
export const fireFilterClickAnalytics = (key, value) => {
  if (isBlank(value)) return;
  keenService.reportEventWithObj(getClickedFilterArgs(key, value));
};

export const fireClickedLinkAnalytics = ({ entity_type, entity_id, href, location, type, value }, e) => {
  keenService.reportEventWithObj(getClickedLinkArgs({ entity_type, entity_id, href, location, type, value }, e));
};

export const fireClickedLinkAnalyticsWithRedirect = ({ entity_type, entity_id, href, location, type, value }, url, e) => {
  const args = getClickedLinkArgs({ entity_type, entity_id, href, location, type, value }, e);
  const redirectUrl = url || args.customProps.href; // url can be inferred from the event in getClickedLinkArgs

  keenService.reportEventWithDelayedRedirectWithObj(args, redirectUrl, e);
};

export const fireSearchFilterAnalytics = (query) => {
  if (isBlank(query)) return;
  keenService.reportEventWithObj(getSearchFilterArgs(query));
};

export const fireSearchLocationFilterAnalytics = (location, formatterFn = null) => {
  if (isBlank(location)) return;
  const formatted = typeof formatterFn === 'function' ? formatterFn(location) : location;
  const cleaned = filterObject(formatted, (val, key) => LOCATION_WHITELIST.includes(key));
  keenService.reportEventWithObj(getSearchLocationFilterArgs(cleaned));
};

/**
 * View all section click event - may piggyback onto other events given in the "events" param
 */
export const fireViewAllSectionClickAnalytics = ({ delayRedirect = false, e, events = [], index, item = {}, pageType = null, section = {}, url }) => {
  const eventObj = getClickedViewAllSectionArgs(section, index, item, pageType);
  const allEvents = events.concat([eventObj]);
  if (delayRedirect && url) {
    keenService.recordEventsWithDelayedRedirect(allEvents, url, e);
  } else {
    keenService.recordEvents(allEvents);
  }
};
