import PropTypes from 'prop-types';
import React from 'react';

import Image from '../../image';

import keenService from '../../../services/keen/main';

import styles from './small_project_card.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

function handleMainLinkClick(e, project, location) {
  keenService.recordEventWithDelayedRedirect({ eventName: 'Clicked link' }, { href: project.url, location: location, type: 'project_card', value: project.name }, project.url, e);
}

const SmallProjectCard = ({ cardBorder, classList, imageProps, imgBorderRadius, lazy, location, maxWidth, project }) => (
  <div className={`${cardBorder ? styles.cardBorder : ''} ${classList.wrapper || styles.wrapper}`}>
    <a className={`${styles.imageContainer} ${imgBorderRadius ? utilStyles.borderRadiusSmall : ''}`} href={project.url} onClick={(e) => handleMainLinkClick(e, project, location)}>
      <div className={styles.itemImage}>
        <Image
          alt={project.name}
          className={`${styles.lazyImage} ${imgBorderRadius ? utilStyles.borderRadiusSmall : ''}`}
          lazy={lazy}
          ratio="4:3"
          setStyle={false}
          src={project.cover_image_url}
          width={maxWidth}
          {...imageProps}
        />
      </div>
    </a>
    <a className={`${typography.link} ${typography.bold} ${typography.bodyS}`} href={project.url} onClick={(e) => handleMainLinkClick(e, project, location)}>
      {project.name}
    </a>
  </div>
);

SmallProjectCard.propTypes = {
  cardBorder: PropTypes.bool,
  classList: PropTypes.shape({ wrapper: PropTypes.string }),
  imageProps: PropTypes.object,
  imgBorderRadius: PropTypes.bool,
  lazy: PropTypes.bool,
  location: PropTypes.string,
  maxWidth: PropTypes.number,
  project: PropTypes.shape({
    cover_image_url: PropTypes.string,
    hid: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  projectDefaultImageUrl: PropTypes.string,
};

SmallProjectCard.defaultProps = {
  cardBorder: false,
  classList: {},
  imageProps: {},
  imgBorderRadius: false,
  lazy: true,
  location: null,
  maxWidth: 90,
  projectDefaultImageUrl: '',
};

export default SmallProjectCard;
