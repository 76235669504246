import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Carousel from './Carousel';
import Viewer from './Viewer';

import { gifToVideo } from './helpers';

import styles from './image_carousel.css';

class ImageCarousel extends Component {
  constructor(props) {
    super(props);

    const images = props.images.map(gifToVideo);

    this.state = {
      active: false,
      activeIndex: 0,
      activeViewerIndex: 0,
      images: images.map((image, i) => ({ ...image, url: image.image_urls['headline_url'], imageIndex: i })),
      showViewer: false,
      viewerImages: images.map((image, i) => ({ ...image, url: image.image_urls['lightbox_url'], imageIndex: i })),
    };

    this.dismiss = this.dismiss.bind(this);
    this.updateActiveIndex = this.updateActiveIndex.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }

  dismiss() {
    this.setState({ showViewer: false, activeIndex: this.state.activeViewerIndex });
  }

  toggleActive(bool) {
    if (this.state.active === bool) return;
    this.setState({ active: bool });
  }

  updateActiveIndex(key, activeIndex) {
    this.setState({ [key]: activeIndex });
  }

  render() {
    return (
      <div key={`react-carousel-${this.props.uid}`} onMouseLeave={() => this.toggleActive(false)} onMouseOver={() => this.toggleActive(true)}>
        <Carousel
          activeIndex={this.state.activeIndex}
          classList={this.props.classList}
          enableArrowKeyNavigation={this.state.active && !this.state.showViewer}
          images={this.state.images}
          onImageClick={() => this.setState({ showViewer: true, activeViewerIndex: this.state.activeIndex })}
          updateActiveIndex={(index) => this.updateActiveIndex('activeIndex', index)}
        />
        <Viewer dialogProps={{ dismiss: this.dismiss, open: this.state.showViewer }}>
          <Carousel
            activeIndex={this.state.activeViewerIndex}
            classList={{ image: styles.viewerImage, container: styles.viewerContainer }}
            enableArrowKeyNavigation={this.state.showViewer}
            images={this.state.viewerImages}
            inViewer={this.state.showViewer}
            updateActiveIndex={(index) => this.updateActiveIndex('activeViewerIndex', index)}
          />
        </Viewer>
      </div>
    );
  }
}

ImageCarousel.propTypes = {
  classList: PropTypes.shape({ container: PropTypes.string }),
  images: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
};

ImageCarousel.defaultProps = { classList: {} };

export default ImageCarousel;
