export default function customBlockStyleFn(contentBlock) {
  const block = 'draftster-block';

  switch (contentBlock.getType()) {
    case 'header-three':
      return `${block} draftster-header-three`;

    case 'unstyled':
    case 'paragraph':
      return `${block} draftster-paragraph`;

    case 'blockquote':
      return `draftster-blockquote`;

    case 'code-block':
      return `draftster-code-block`;

    case 'unordered-list-item':
      return `draftster-list-item`;

    case 'CAROUSEL':
    case 'LEGACY_WIDGET':
    case 'TWEET':
    case 'EMBED':
    case 'IMAGE_LINK':
      return block;

    default:
      break;
  }
}