import { unescape } from 'validator';

const LINK_MARKDOWN_REGEX = /(\[.+\]\(.+\))/g;

// When raw text has markdown and we're truncating feed posts or comments, we don't want to truncate
// in the middle of a markdown token. This will prevent the markdown renderer from presenting broken markdown links.
export default function shouldTruncateEscapedText(escapedText, limit = 150) {
  const unescapedText = unescape(escapedText || '');
  const match = unescapedText.match(LINK_MARKDOWN_REGEX);

  if (match && match.length > 0) {
    const startIndex = unescapedText.search(LINK_MARKDOWN_REGEX);
    const endIndex = startIndex + match[0].length;

    // If the markdown link code is past the truncate index
    // or if the end index (end of the code) is before the truncate limit,
    // then truncate.
    return { limit, truncate: (startIndex > limit || endIndex < limit) };
  }

  return { limit, truncate: true };
}
