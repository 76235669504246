import request from 'superagent';
import { v4 as uuidv4 } from 'uuid';
import xmlParser from 'xml2js';

import OAuth from '../oauth';
import { getAWSResources } from '../../utility/images';

function getS3AuthData(fileName, apiPath, token) {
  fileName = fileName || uuidv4();

  return new Promise((resolve, reject) => {
    request
      .get(`${apiPath}/private/files/signed_url?file%5Bname%5D=${fileName}&context=no-context`)
      .set('Authorization', `Bearer ${token}`)
      .end((err, res) => {
        err ? reject(err) : resolve(res.body);
      });
  });
}

function postToS3FromBrowser(data, file, opts) {
  const { progressCallback } = opts;
  const { S3BucketURL } = getAWSResources();
  const form = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    form.append(key, value);
  });
  form.append('file', file);

  return new Promise((resolve, reject) => {
    request
      .post(S3BucketURL)
      .accept('application/xml')
      .send(form)
      .on('progress', (e) => {
        if (progressCallback) {
          progressCallback(e);
        }
      })
      .end((err, S3Response) => {
        if (err) return reject(err);

        xmlParser.parseString(S3Response.text, (error, parsedXML) => {
          error ? reject(error) : resolve(parsedXML.PostResponse.Location[0]);
        });
      });
  });
}

function postUrlToHackster(url, apiPath, token) {
  return new Promise((resolve, reject) => {
    request
      .post(`${apiPath}/private/files`)
      .set('Authorization', `Bearer ${token}`)
      .send({ file_url: url })
      .end((err, res) => {
        err ? reject(err) : resolve(res.body.id);
      });
  });
}

function processAndUploadFile(file, token, opts) {
  const apiPath = OAuth.getApiPath();

  return new Promise((resolve, reject) => {
    getS3AuthData(file.name, apiPath, token)
      .then((S3Data) => postToS3FromBrowser(S3Data, file, opts))
      .then((url) => postUrlToHackster(url, apiPath, token))
      .then((id) => resolve(id))
      .catch((err) => reject(err));
  });
}

export default function uploadFile(file, opts) {
  opts = opts || {};

  return new Promise((resolve, reject) => {
    OAuth.getApiToken((token) => {
      processAndUploadFile(file, token, opts)
        .then((id) => resolve(id))
        .catch((err) => reject(err));
    });
  });
}
