import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from '../news_card.css';

export const getImgContainerStyles = (isHero) => (
  isHero
    ? `${utilStyles.absolutePlaceholderParent} ${utilStyles.aspectRatioPadding16_9} ${layout.marginBottom22}`
    : `${layout.fullWidth} ${utilStyles.posRelative} ${styles.featuredImgWrapperSm}`
);

export const getRootStyles = (isHero) => (`${styles.featuredCard} ${layout.flex} ${typography.overflowBreakWord} ${isHero ? styles.featuredHero : styles.featuredSm}`);
export const getTitleStyles = (isHero) => (`${typography.charcoal} ${isHero ? typography.h3 : typography.h4}`);
