const ADMIN = 'ADMIN';
const MEMBER = 'MEMBER';
const MODERATOR = 'MODERATOR';

const filterToEnumMap = {
  admin: ADMIN,
  member: MEMBER,
  moderator: MODERATOR,
};

export const MANAGER_ROLES = [ADMIN, MODERATOR];

export const channelMemberEnumsFromRolesObj = (roles) => Object.keys(roles).map((role) => filterToEnumMap[role]);

export const channelMemberRoleToEnum = (filter) => {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!filterToEnumMap.hasOwnProperty(filter)) {
    console.warn(`channelMemberRoleToEnum recieved an unknown filter role: ${filter}`);

    return null;
  }

  return filterToEnumMap[filter];
};
