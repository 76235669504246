import validator from 'validator';

const blockTagMap = {
  'header-one': 'h3',
  'header-two': 'h3',
  'header-three': 'h3',
  'header-four': 'h3',
  'header-five': 'h3',
  'header-six': 'h3',
  'unstyled': 'p',
  'code-block': 'pre',
  'blockquote': 'blockquote',
  'ordered-list-item': 'li',
  'unordered-list-item': 'li',
  'paragraph': 'p',
  'default': 'p'
};

const inlineTagMap = {
  'BOLD': ['<strong>','</strong>'],
  'ITALIC': ['<em>','</em>'],
  'UNDERLINE': ['<u>','</u>'],
  'CODE': ['<code>','</code>'],
  'STRIKETHROUGH': ['<del>', '</del>'],
  'SPAN': ['<span>','</span>'],
  'default': ['','']
};

const entityTagMap = {
  'LINK': {
    open: (data) => {
      const href = data.href;
      return `<a href="${href}">`;
    },
    close: '</a>'
  }
};

const nestedTagMap = {
  'ordered-list-item': 'ol',
  'unordered-list-item': 'ul'
};

// Uses Drafts CharacterMetaData, which pools styles for us per character.  We just fetch it to save us operations.
function getStylesByCharData(block, charIndex) {
  if (block.getCharacterList().size === 0) return ['default'];

  const styleList = block
    .getCharacterList()
    .get(charIndex)
    .get('style')
    .toArray();
  return styleList.length ? styleList : ['default'];
}

// Builds a list of entity types by checking the raw block's entityRanges.
// If we find that the characters index falls between an entity's range, we fetch the enitity by key from the raw
// entityMap.  This gets us entities like 'LINK'.
function getEntitiesByRanges(entityRanges, entityMap, charIndex) {
  return entityRanges.reduce((acc, range) => {
    if(charIndex >= range.offset && charIndex <= range.offset + range.length-1) {
      acc.push(entityMap[range.key]);
    }
    return acc;
  }, []);
}

function shouldMerge(a, b) {
  if (a.length !== b.length) return false;
  return a.every(aStyle => b.includes(aStyle));
}

function buildBlockElement(type, children) {
  const tag = blockTagMap[type];

  return nestedTagMap[type]
   ?  `<${nestedTagMap[type]}><${tag}>${children}</${tag}></${nestedTagMap[type]}>`
   : `<${tag}>${children}</${tag}>`;
}

function buildInlineElements(block, blockMap, entityMap) {
  const charMap = Array.from(block.text).map((char, index) => {
    // Builds metadata for each character similar to how draft does.
    return {
      text: char,
      styles: getStylesByCharData(blockMap.get(block.key), index),
      entities: getEntitiesByRanges(block.entityRanges, entityMap, index)
    };
  }).map(char => {
    // This is going to add entity types to the styles array so we can do shouldMerge below a bit cleaner.
    // These entities are only for inlines like 'LINK'.  Block entities are handled elsewhere.
    if (char.entities.length) {
      char.entities.forEach(entity => {
        if (!char.styles.includes(entity.type)) {
          char.styles.push(entity.type);
        }
      });
    }
    return char;
  }).reduce((acc, char) => {
    // Merges character nodes by similar style types. The styles arrays MUST be strict matches.
    // This will help us nest the inlines correctly and save us another cleaning/serialize loop.
    if (!acc.length) {
      acc.push(char);
    } else if(shouldMerge(acc[acc.length-1].styles, char.styles)) {
      acc[acc.length-1].text += char.text;
    } else {
      acc.push(char);
    }
    return acc;
  }, []);
  // We also need a way to see if an inline is going to span multiple characters and perhaps have another inline as a child of it.
  // Maybe when we are creating the html string we can look ahead at the next character, and if theres matching styles, hold the
  // inline style open, append the unmatching styles, and pop those new unmatching styles on a stack after the styles still open.  That
  // way we can keep track of what tags are open and need closing.  If the next tag doesnt have a matching style, close the one on the
  // stack, etc.
  let tagStack = [];
  const html = charMap.map(char => {

    const start = char.styles.map(style => {
      // We need to look for any entities first to handle data attributes.
      if (entityTagMap[style]) {
        tagStack.unshift(entityTagMap[style].close);
        return entityTagMap[style].open(char.entities[0].data); // This line needs review.  Is entities[0] always correct?
      } else if (inlineTagMap[style]) {
        tagStack.unshift(inlineTagMap[style][1]);
        return `${inlineTagMap[style][0]}`;
      }
    }).join('');

    const end = tagStack.join('');
    tagStack = [];

    // We need to sanitize all text then replace the line breaks with <br> elements afterwards. That way they dont get sanitized.
    const sanitizedText = validator.escape(char.text).replace(/\n/g, '<br/>');
    return start.concat(sanitizedText).concat(end);

  }).join('');

  return html;
}

export default function convertRawBlockToHtml(rawBlock, blockMap, entityMap) {
  return buildBlockElement(
    rawBlock.type,
    buildInlineElements(rawBlock, blockMap, entityMap)
  );
}