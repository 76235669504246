export default class PromiseBatcher {
  constructor() {
    this.batch = [];
  }

  // Resolver is expected to have resolve and reject callbacks as an object.
  cachePromise(resolver) {
    this.batch.push(resolver);
  }

  rejectBatch(err) {
    this.batch = this.batch.filter((resolver) => {
      resolver.reject(err);

      return false;
    });
  }

  resolveBatch(res) {
    this.batch = this.batch.filter((resolver) => {
      resolver.resolve(res);

      return false;
    });
  }
}
