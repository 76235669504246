import {EditorState, RichUtils} from 'draft-js';
import {getCurrentBlock, getPreviousChar} from '../utils/draft/getters';
import {getCommonStates} from '../utils/draft/general';
import {deletePreviousWord, insertEmptySpace} from '../utils/draft/modifiers';

function backspaceHandler(editorState, setDraftState) {
  const currentContent = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();

  // Boot out to default if the selection isn't collapsed.  This check needs to be before prevBlock.getType().
  if (!currentSelection.isCollapsed()) return 'not-handled';

  const prevBlock = currentContent.getBlockBefore(currentSelection.getAnchorKey());

  if (!prevBlock) return 'not-handled';

  const prevBlockType = prevBlock.getType();

  // If the cursor is at the start of a block && the previous block is a custom component, manually remove the
  // component and update the EditorState.
  // Else if we're backspacing up into an empty paragraph, delete the previous block.
  if (currentSelection.getStartOffset() < 1 && prevBlock &&
    (prevBlockType === 'CAROUSEL' ||
     prevBlockType === 'EMBED' ||
     prevBlockType === 'LEGACY_WIDGET' ||
     prevBlockType === 'TWEET' ||
     prevBlockType === 'IMAGE_LINK')) {
    // const updatedBlockMap = blockMap.remove(prevBlock.getKey());
    // const updatedState = EditorState.push(editorState, ContentState.createFromBlockArray(updatedBlockMap.toList()));

    // setDraftState(EditorState.acceptSelection(
    //   updatedState,
    //   currentSelection
    // ));

    // NOTE: Uncomment the lines above to enable editor to remove custom blocks. createFromBlockArray would need updating to v0.11 api.
    return 'handled';
  } else if (currentSelection.getStartOffset() < 1 && prevBlock && prevBlock.getText().length < 1 &&
            currentContent.getBlockForKey(currentSelection.getAnchorKey()).getType() !== 'unstyled' ) {

    const updatedState = EditorState.push(
      editorState,
      currentContent.set('blockMap', currentContent.getBlockMap().delete(prevBlock.getKey()))
    );

    setDraftState(EditorState.acceptSelection(
      updatedState,
      currentSelection
    ));

    return 'handled';
  }

  return 'not-handled';
}

function inlineHandler(cmd, editorState, setDraftState, toggleActiveStyle) {
  if (getCurrentBlock(editorState).getType() === 'header-three') return 'not-handled';
  if (editorState.getSelection().isCollapsed()) {
    toggleActiveStyle(cmd);
    return 'handled';
  }

  setDraftState(RichUtils.toggleInlineStyle(
    editorState,
    cmd
  ));
  return 'handled';
}

const deleteLastWord = (editorState, setDraftState) => {
  const {selection} = getCommonStates(editorState);

  if (!selection.isCollapsed()) return 'not-handled';

  const prevChar = getPreviousChar(editorState);

  if (prevChar !== ' ') {
    setDraftState(deletePreviousWord(editorState));
    return 'handled';
  }

  return 'not-handled';
};

const insertLineBreak = (editorState, setDraftState) => {
  const {selection} = getCommonStates(editorState);

  if (!selection.isCollapsed()) return 'not-handled';

  setDraftState(RichUtils.insertSoftNewline(editorState));
  return 'handled';
};

const handleTab = (editorState, setDraftState) => {
  const currentBlock = getCurrentBlock(editorState);

  if (currentBlock.getType() === 'code-block') {
    setDraftState(insertEmptySpace(editorState, '    '));
    return 'handled';
  }

  return 'not-handled';
};

export default function customKeyHandlers(cmd, editorState, setDraftState, toggleActiveStyle) {
  switch(cmd) {
    case 'backspace':
      return backspaceHandler(editorState, setDraftState);

    case 'ARROW_DOWN':
      return handleArrowDown(editorState, setDraftState);

    case 'BOLD':
    case 'CODE':
    case 'ITALIC':
      return inlineHandler(cmd, editorState, setDraftState, toggleActiveStyle);

    case 'DELETE_LAST_WORD':
      return deleteLastWord(editorState, setDraftState);

    case 'INSERT_LINE_BREAK':
      return insertLineBreak(editorState, setDraftState);

    case 'TAB':
      return handleTab(editorState, setDraftState);

    default:
      return 'not-handled';
  }
}
