import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';
import ButtonDropdown from '../../buttons/button_dropdown';

import isMobileBrowser from '../../../utility/isMobileBrowser';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layoutStyles from '../../../styles/global_ui/layout.css';
import styles from './simple_select.css';

// The ternary is a slight hack. componentDidUpdate in SimpleSelect will try to update the selectedIndex, if things change above.
// We need a brief null state here until state is reset.
const _renderLabel = ({ classList, open = false, options, selectedValueIndex }) => (
  <div className={`${styles.label} ${layoutStyles.flexCenterItems} ${classList.label}`}>
    {options[selectedValueIndex] ? options[selectedValueIndex].label : null}
    <Icon className={open ? styles.iconOpen : styles.icon} name="arrow-down" />
  </div>
);

const _renderDesktop = ({ classList, options, selectedValueIndex, selectOptionByIndex }) => (
  <ButtonDropdown
    classList={{
      button: buttonStyles.blank,
      dropdown: styles.dropdown,
      wrapper: classList.root,
    }}
    closeOnSelect={true}
    label={_renderLabel({ classList, options, selectedValueIndex })}
    labelOpen={_renderLabel({ classList, options, selectedValueIndex, open: true })}
  >
    <ul>
      {options.map((option, i) => shouldRenderOption(option) && (
        <li
          key={i}
          className={selectedValueIndex === i ? styles.optionSelected : styles.option}
          onClick={(e) => selectOptionByIndex({ target: { value: i } })}
        >
          {option.label}
        </li>
      ))}
    </ul>
  </ButtonDropdown>
);

const _renderTouch = ({ classList, options, selectedValueIndex, selectOptionByIndex }) => (
  <div className={`${styles.selectWrapper} ${classList.root}`}>
    <select
      className={`${styles.select} ${classList.label}`}
      onChange={selectOptionByIndex}
      value={selectedValueIndex.toString()}
    >
      {options.map((option, i) => (
        <option key={i} disabled={option.disabled} hidden={option.hidden} value={i}>
          {option.labelText || option.label}
        </option>
      ))}
    </select>
    <Icon className={styles.selectIcon} name="arrow-down" />
  </div>
);

const shouldRenderOption = (option) => !option.hidden;

const DefaultSimpleSelectView = (props) => (
  isMobileBrowser() ? _renderTouch(props) : _renderDesktop(props)
);

DefaultSimpleSelectView.propTypes = {
  classList: PropTypes.shape({
    label: PropTypes.string,
    root: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
    labelText: PropTypes.string, // If label is an element, this provides a text alternative for native select labels
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]), // Can be null
    default: PropTypes.bool,
  })).isRequired,
  selectOptionByIndex: PropTypes.func.isRequired,
  selectedValueIndex: PropTypes.number.isRequired,
};

DefaultSimpleSelectView.defaultProps = {
  classList: {
    label: '',
    root: '',
  },
};

export default DefaultSimpleSelectView;
