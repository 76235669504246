import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';

import styles from './dismiss_button.css';

const DismissButton = ({ autoFocus, className, fullScreen, onClick, style }) => (
  <button
    autoFocus={autoFocus}
    className={`${fullScreen ? styles.fixed : styles.absolute} ${className}`}
    onClick={onClick}
    style={style}
    type="button"
  >
    <Icon name="close" size="16" />
  </button>
);

DismissButton.propTypes = {
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

DismissButton.defaultProps = {
  className: '',
  fullScreen: false,
  onClick: () => {},
  style: {},
};

export default DismissButton;
