import React from 'react';
import PropTypes from 'prop-types';

import FlexCell from '../../../../../client/tables/cells/FlexCell';

import { timestampToPrettyDate } from '../../../../../utility/time';

import flexCellStyles from '../../../../../client/tables/cells/cells.css';
import tableStyles from '../../../../../styles/global_ui/table.css';
import styles from '../../section_list/section_list.css';

const DragHandle = ({ attributes, listeners }) => (
  <span {...attributes} {...listeners} className={styles.dragHandle}>
    <span className={styles.dragHandleIcon}>: :</span>
  </span>
);

const SectionCard = ({ attributes, item, listeners, removeItem, updateItem }) => (
  <div className={styles.row}>
    <DragHandle attributes={attributes} listeners={listeners} />
    <FlexCell body={item.title} className={`${flexCellStyles.cell0}`} />
    <FlexCell body={item.category} />
    <FlexCell body={timestampToPrettyDate(item.created_at)} />
    <FlexCell
      body={() => (
        <span>
          <button className={`${tableStyles.editBtn}`} onClick={() => updateItem(item)} type="button">Edit</button>
          <button className={`${tableStyles.deleteBtn}`} onClick={() => removeItem(item)} type="button">Unselect</button>
        </span>
      )}
    />
  </div>
);

SectionCard.propTypes = {
  item: PropTypes.shape({
    category: PropTypes.string.isRequired,
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  removeItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

SectionCard.defaultProps = {};

export default SectionCard;
