import React from 'react';
import PropTypes from 'prop-types';

import dummyStyles from '../project_card/dummy_card.css';
import styles from './small_project_card.css';

const SmallDummyProjectCard = ({ cardBorder, classList }) => (
  <div className={`${cardBorder ? styles.cardBorder : ''} ${classList.wrapper || styles.wrapper}`}>
    <div className={styles.imageContainer}>
      <div className={`${styles.itemImage} ${dummyStyles.itemImage}`} />
    </div>
    <div className={dummyStyles.smallBody}>
      <div className={dummyStyles.smallTitle} />
      <div className={dummyStyles.smallTitle} />
    </div>
  </div>
);

SmallDummyProjectCard.propTypes = {
  cardBorder: PropTypes.bool,
  classList: PropTypes.shape({
    card: PropTypes.string,
    wrapper: PropTypes.string,
  }),
};

SmallDummyProjectCard.defaultProps = {
  cardBorder: false,
  classList: {},
};

export default SmallDummyProjectCard;
