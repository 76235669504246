import React from 'react';

const shapes = {
  'arduino': {
    shape: (
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <path d="M176 30C95.62 30 30.46 92.68 30.46 170S95.62 310 176 310C366 320 346 30 546 30c80.38 0 145.54 62.68 145.54 140S626.38 310 546 310C356 320 376 30 176 30" strokeWidth="60" />
        <path d="M530.07 204.67v-32.78h-32.54V158.2h32.54v-32.54h13.86v32.54h32.54v13.69h-32.54v32.78z" fill="currentColor" fillRule="nonzero" strokeWidth="20" />
        <path d="M139.1 170.73V159.6h83.31v11.14z" fill="currentColor" fillRule="nonzero" strokeWidth="28.23" />
      </g>),
    svgProps: { viewBox: '0 0 722 341' },
  },
  'existing-project': {
    shape: (
      <g fillRule="nonzero">
        <path d="M7 4a1 1 0 0 0-1 1v35a1 1 0 0 0 1 1h31a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H7zm0-2h31a3 3 0 0 1 3 3v35a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3z" />
        <path d="M13 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm5-4V9h16v2zm0 4v-2h8v2zm-8 21v-2h19v2zm0-5v-2h25v2zm2-10v3h21v-3H12zm-2-2h25v7H10v-7z" />
      </g>),
    svgProps: { viewBox: '0 0 45 45' },
  },
  'google': {
    shape: (
      <g>
        <defs><path d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z" id="a" /></defs>
        <clipPath id="b"><use overflow="visible" xlinkHref="#a" /></clipPath>
        <path clipPath="url(#b)" d="M0 37V11l17 13z" fill="#FBBC05" />
        <path clipPath="url(#b)" d="M0 11l17 13 7-6.1L48 14V0H0z" fill="#EA4335" />
        <path clipPath="url(#b)" d="M0 37l30-23 7.9 1L48 0v48H0z" fill="#34A853" />
        <path clipPath="url(#b)" d="M48 48L17 24l-4-3 35-10z" fill="#4285F4" />
      </g>
    ),
    svgProps: { viewBox: '0 0 48 48' },
  },
  'new-project': {
    shape: (
      <g fillRule="nonzero">
        <path d="M36 22.194h2v15.382A3.424 3.424 0 0 1 34.576 41H8.424A3.424 3.424 0 0 1 5 37.576V11.424A3.424 3.424 0 0 1 8.424 8h15.843v2H8.424C7.637 10 7 10.637 7 11.424v26.152C7 38.363 7.637 39 8.424 39h26.152c.787 0 1.424-.637 1.424-1.424V22.194z" />
        <path d="M20.765 22.248v3.536h3.536L41.239 8.845 37.703 5.31 20.765 22.248zM44.067 8.845L25.13 27.784h-6.364V21.42L37.703 2.48l6.364 6.364z" />
        <path d="M33.793 8.207l1.414-1.414 4.528 4.528-1.414 1.414z" />
      </g>
    ),
    svgProps: { viewBox: '0 0 45 45' },
  },
  'star': {
    shape: <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />,
    svgProps: { viewBox: '0 0 24 24' },
  },

  // Comment Editor Icons
  'bold_editor_tool': {
    shape: <path d="M22.12 15.145C23.293 13.753 24 11.957 24 10a8.01 8.01 0 0 0-8-8H6v28h12a8.01 8.01 0 0 0 8-8c0-2.905-1.556-5.453-3.88-6.855zM12 6h3.172c1.75 0 3.172 1.794 3.172 4s-1.423 4-3.172 4H12V6zm4.97 20H12v-8h4.97c1.827 0 3.313 1.794 3.313 4s-1.486 4-3.313 4z" />,
    svgProps: { viewBox: '0 0 40 40' },
  },
  'code_editor_tool': {
    shape: <path d="M18 23l3 3 10-10L21 6l-3 3 7 7zM14 9l-3-3L1 16l10 10 3-3-7-7z" />,
    svgProps: { viewBox: '0 0 35 35' },
  },
  'italic_editor_tool': {
    shape: <path d="M28 2v2h-4L14 28h4v2H4v-2h4L18 4h-4V2z" />,
    svgProps: { viewBox: '0 0 40 40' },
  },
  'bullet_list_editor_tool': {
    shape: <path d="M12 2h20v4H12V2zm0 12h20v4H12v-4zm0 12h20v4H12v-4zM0 4a4 4 0 1 1 8 0 4 4 0 1 1-8 0zm0 12a4 4 0 1 1 8 0 4 4 0 1 1-8 0zm0 12a4 4 0 1 1 8 0 4 4 0 1 1-8 0z" />,
    svgProps: { viewBox: '0 0 40 40' },
  },
  'markdown_logo': {
    shape: (
      <svg height="128" width="208" xmlns="http://www.w3.org/2000/svg">
        <mask id="a">
          <rect fill="#fff" height="100%" width="100%" />
          <path d="M30 98V30h20l20 25 20-25h20v68H90V59L70 84 50 59v39zm125 0l-30-33h20V30h20v35h20z" />
        </mask>
        <rect height="100%" mask="url(#a)" ry="15" width="100%" />
      </svg>
    ),
    svgProps: { viewBox: '0 0 208 128' },
  },
  'quote_editor_tool': {
    shape: <path d="M7.03 14a7 7 0 1 1 0 14 7 7 0 0 1-7-7L0 20C0 12.268 6.268 6 14 6v4c-2.67 0-5.182 1.04-7.07 2.93a10.24 10.24 0 0 0-.995 1.157A7.08 7.08 0 0 1 7.031 14zm18 0a7 7 0 1 1 0 14 7 7 0 0 1-7-7L18 20c0-7.732 6.268-14 14-14v4c-2.67 0-5.182 1.04-7.07 2.93a10.24 10.24 0 0 0-.995 1.157A7.09 7.09 0 0 1 25.031 14z" />,
    svgProps: { viewBox: '0 0 35 35' },
  },
};

export default shapes;
