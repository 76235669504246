/* eslint-disable react/jsx-key */

import React from 'react';
import Icon from '../../../client/icon';

import { getInObj } from '../../../utility/accessors';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

// works for any "channel" (community, platform, topic)
const channelConfig = (size) => ({
  className: [buttonStyles[size], `${buttonStyles[size]} ${buttonStyles.secondary}`],
  create: { t: 'create_channel_member' },
  delete: { t: 'delete_channel_member' },
  label: ['Join Community', (
    <span>
      <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="checkmark" />
      <span>You&apos;re a member</span>
    </span>
  )],
  storeKey: 'channel',
});

const respectConfig = {
  // className & label are currently always overridden
  className: '',
  create: { t: 'create_respect' },
  delete: { t: 'delete_respect' },
  label: [],
};

const userConfig = (size) => ({
  className: [buttonStyles[size], `${buttonStyles[size]} ${buttonStyles.secondary}`],
  create: { t: 'create_follow_relation' },
  delete: { t: 'delete_follow_relation' },
  label: ['Follow', (
    <span>
      <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="checkmark" />
      <span>Following</span>
    </span>
  )],
  storeKey: 'followed_user',
});

const configMap = {
  channel_sm: channelConfig('sm'),
  channel_md: channelConfig('md'),
  followed_user_xs: userConfig('xs'),
  followed_user_sm: userConfig('sm'),
  followed_user_md: userConfig('md'),
  part: { // Channel products toolbox.
    className: [`${buttonStyles.md} ${buttonStyles.secondary}`, `${buttonStyles.md} ${buttonStyles.secondary}`],
    create: { t: 'create_part_user' },
    delete: { t: 'delete_part_user' },
    label: ['Add to interests', (
      <span>
        <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="checkmark" />
        <span>In interests</span>
      </span>
    )],
  },
  respected_comment: respectConfig,
  respected_feed_post: respectConfig,
  respected_news_article: respectConfig,
  respected_project: respectConfig,
};

export function labelOverride(label = '') {
  return [label, (
    <span>
      <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="checkmark" />
      <span>You&apos;re a member</span>
    </span>
  )];
}

export function getStoreKey(configKey) {
  const key = getInObj([configKey, 'storeKey'], configMap) || configKey;

  return `${key}_ids`;
}

export default function getConfigForType(type) {
  return configMap[type] || {};
}
