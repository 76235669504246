import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../wrappers/dropdown';
import Icon from '../../icon';

import { ESC } from '../../../constants/keyCodes';

import utilStyles from '../../../styles/global_ui/util.css';

// TODO: look into the accessible focus/blur method outlined in https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
class ButtonDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  /**
   * Hooks
   */
  __toggleDropdownHook(open = false) {
    this._toggleDropdown(open);
  }

  /**
   * Methods
   */
  handleClick(e) {
    this._toggleDropdown(!this.state.open);
  }

  handleKeyDown(e) {
    if (this.state.open && e.keyCode === ESC) {
      this._toggleDropdown(false);
    }
  }

  /**
   * Helpers
   */
  _toggleDropdown(open) {
    this.setState({ open });
    this.props.handleToggle(open);
  }

  render() {
    const { open } = this.state;
    const { classList, disabled, targetWillUnmount } = this.props;
    const label = open && this.props.labelOpen ? this.props.labelOpen : this.props.label;

    return (
      <div className={`${utilStyles.posRelative} ${classList.wrapper}`} onKeyDown={this.handleKeyDown}>
        {/* TODO: audit usage and change to uikit Button component */}
        <button
          aria-expanded={open}
          aria-haspopup="true"
          className={classList.button}
          disabled={disabled}
          onClick={this.handleClick}
          type="button"
        >
          {label}
        </button>

        {open
        && (
          <Dropdown
            alignRight={this.props.alignRight}
            alignTop={this.props.alignTop}
            className={classList.dropdown}
            dismiss={this.handleClick}
            elevation={this.props.elevation}
            onClick={this.props.closeOnSelect ? this.handleClick : null}
            // TODO: this closeOnSelect prop is hacky. replace by using the __toggleDropdownHook from parent compoennts instead
            targetWillUnmount={targetWillUnmount}
          >
            {this.props.children}
          </Dropdown>
        )}
      </div>
    );
  }
}

ButtonDropdown.propTypes = {
  alignRight: PropTypes.bool,
  alignTop: PropTypes.bool,
  classList: PropTypes.shape({
    button: PropTypes.string,
    dropdown: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  closeOnSelect: PropTypes.bool,
  elevation: PropTypes.oneOf([0, 1, 2]), // https://projects.invisionapp.com/d/main#/console/11627398/305976062/preview
  handleToggle: PropTypes.func,
  label: PropTypes.node, // todo: make this a render prop with 'open' argument instead of two separate props
  labelOpen: PropTypes.node,
  targetWillUnmount: PropTypes.bool,
};

ButtonDropdown.defaultProps = {
  alignRight: false,
  alignTop: false,
  classList: {
    button: '',
    dropdown: '',
    wrapper: '',
  },
  closeOnSelect: false,
  elevation: 1,
  handleToggle: () => {},
  label: <Icon name="arrow-down" />,
  labelOpen: null,
  targetWillUnmount: false,
};

export default ButtonDropdown;
