import React from 'react';
import PropTypes from 'prop-types';

import EventCardTextPreview from './EventCardTextPreview';
import LazyBackgroundImage from '../../../../../client/image/LazyBackgroundImage';

import { getInObj } from '../../../../../utility/accessors';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';

const EventCardHorizontalSmallPreview = ({ className, item }) => {
  const imageUrl = getInObj(['mobile_image', 'url'], item) || getInObj(['image', 'url'], item);

  return (
    <div className={`${className} ${layout.flexCenterItems} ${layout.fullWidth} ${typography.breakWord}`}>
      <div className={`${utilStyles.posRelative} ${layout.flex10Auto} ${utilStyles.borderRadius} ${imageUrl ? '' : utilStyles.bgAsphalt}`} style={{ width: 120, height: 120 }}>
        {!!imageUrl
        && (
          <LazyBackgroundImage
            key={imageUrl}
            alt={item.title}
            classList={{ image: utilStyles.borderRadius }}
            ratio="1:1"
            src={imageUrl} /* reInit LazyBackgroundImage when the source changes */
            width={120}
          />
        )}
      </div>
      <EventCardTextPreview className={layout.marginLeft15} item={item} />
    </div>
  );
};

EventCardHorizontalSmallPreview.propTypes = {
  event: PropTypes.shape({
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    end_date: PropTypes.string,
    event_type: PropTypes.string,
    image: PropTypes.shape({ url: PropTypes.string }),
    location: PropTypes.shape({}),
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    start_date: PropTypes.string,
    state: PropTypes.string,
    title: PropTypes.string,
  }),
};

EventCardHorizontalSmallPreview.defaultProps = {
  event: {
    custom_sponsors: [],
    end_date: null,
    event_type: null,
    image: null,
    location: null,
    platforms: [],
    start_date: null,
    state: null,
    title: null,
  },
};

export default EventCardHorizontalSmallPreview;
