import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ClickOutsideWrapper from '../../wrappers/click_outside_wrapper';
import TooltipBody from '../../reusable_components/TooltipTarget/TooltipBody';

import isTouchDevice from '../../../utility/isTouchDevice';
import { NOOP_HREF } from '../../../constants/links';

import tooltipStyles from '../../reusable_components/TooltipTarget/tooltip_target.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './event_card.css';

class SponsorTooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.dismissTooltip = this.dismissTooltip.bind(this);
    this.summonTooltip = this.summonTooltip.bind(this);
  }

  /**
   * Methods
   */
  dismissTooltip(e) {
    this.setState({ open: false });
  }

  summonTooltip(e) {
    this.setState({ open: true });
  }

  /**
   * Views
   */
  _getHoverTarget() {
    return (
      <span
        className={styles.tooltipTarget}
        onMouseEnter={this.summonTooltip}
        onMouseLeave={this.dismissTooltip}
      >
        {this._getTargetBody()}
        {this.state.open && this._getTooltip()}
      </span>
    );
  }

  _getTargetBody() {
    return (<a className={typography.link} href={NOOP_HREF}>and more</a>);
  }

  _getTooltip() {
    return (
      <TooltipBody innerWidth={220} multiline={true} position="top">
        {this.props.tooltip}
      </TooltipBody>
    );
  }

  _getTouchTarget() {
    return (
      <span className={styles.tooltipTarget} onClick={this.summonTooltip}>
        {this._getTargetBody()}
        {this.state.open
        && (
          <ClickOutsideWrapper onClickOutside={this.dismissTooltip}>
            {this._getTooltip()}
          </ClickOutsideWrapper>
        )}
      </span>
    );
  }

  render() {
    return (
      <span className={tooltipStyles.tooltipV2}>
        {isTouchDevice() ? this._getTouchTarget() : this._getHoverTarget()}
      </span>
    );
  }
}

SponsorTooltip.propTypes = { tooltip: PropTypes.node };

SponsorTooltip.defaultProps = { tooltip: null };

export default SponsorTooltip;
