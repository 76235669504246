import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from '../styles/Prompt.css';
import buttonStyles from '../styles/buttons.css';
import slideUpTransitions from '../styles/slideUpTransitions.css';

class Prompt extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {value: props.defaultValue};

    // Refs.
    this.input;
  }

  componentDidMount() {
    if (this.input) this._autoFocus();
  }

  _autoFocus() {
    this.input.click();

    setTimeout(() => {
      this.input.focus();
    }, 20);
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 && this.props.submit) { // Enter
      e.preventDefault();
      this.props.submit(this.state.value);
    } else if (e.keyCode === 27) { // Esc
      e.preventDefault();
      this.props.dismiss();
    }
  }

  getInputValue() {
    return this.state.value;
  }

  render() {
    const { actions, dismiss, inputs, enableCloseButton } = this.props;

    return (
      <div className={`${styles.container} draftster-prompt-container`}>
        <div className={`${styles.body} ${slideUpTransitions.translateY} draftster-prompt-body`}>
          <div className={`${styles.innerBody} draftster-prompt-inner-body`}>
            {enableCloseButton ? <button className='close' onClick={dismiss}>×</button> : null}
            {inputs.length
              ? React.Children.toArray(inputs)
              : <input
                  ref={(el) => this.input = el}
                  className={`${styles.input} no-focus-outline draftster-prompt-input`}
                  type="text"
                  placeholder="Enter a url"
                  onChange={(e) => this.setState({value: e.target.value})}
                  onKeyDown={this.handleKeyDown}
                  value={this.state.value}
                />
            }
            <div className={`${styles.actionsWrapper} draftster-prompt-actions-wrapper`}>
              {
                actions.length
                  ? React.Children.toArray(actions)
                  : React.Children.toArray([
                      <button
                        className={`${buttonStyles.btn} ${buttonStyles.btnLink} draftster-btn-secondary`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.dismiss();
                        }}>
                        Cancel
                      </button>,
                      <button
                        className={`${buttonStyles.btn} ${buttonStyles.btnPrimary} draftster-btn-primary`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.submit(this.state.value);
                        }}>
                        Ok
                      </button>
                    ])
              }
            </div>
          </div>
        </div>
        <div className={`${styles.mask} draftster-prompt-mask`} onClick={this.props.dismiss}></div>
      </div>
    );
  }
}

Prompt.propTypes = {
  actions: PropTypes.array,
  defaultValue: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  inputs: PropTypes.array,
  submit: PropTypes.func
};

Prompt.defaultProps = {
  actions: [],
  defaultValue: '',
  inputs: [],
  submit: null
};

export default Prompt;