/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React from 'react';

import AlgoliaPinnedItemViewAllList from '../../../client/algolia/pinned_item_view_all_list';
import AlgoliaViewAllList from '../../../client/algolia/view_all_list';

import AlgoliaContestsService from '../contests_service';
import AlgoliaPartsService from '../parts_service';
import AlgoliaPlatformsService from '../platforms_service';
import AlgoliaProjectsService from '../projects_service';
import AlgoliaTopicsService from '../topics_service';
import AlgoliaVideosService from '../videos_service';

import GraphQlProjectsService from '../../graphql/projects_service';
import GraphQlVideosService from '../../graphql/videos_service';

import getConfigForType from './config';
import errorHandler from '../../error_handler';

export default class ViewAllBuilder {
  constructor({ type, props = {} }, ctx = null) {
    this.config = getConfigForType(type, props, ctx);

    this.contestsService = new AlgoliaContestsService();
    this.partsService = new AlgoliaPartsService();
    this.platformsService = new AlgoliaPlatformsService();
    this.projectsService = new AlgoliaProjectsService();
    this.topicsService = new AlgoliaTopicsService();
    this.videosService = new AlgoliaVideosService();

    this.graphQlProjectsService = new GraphQlProjectsService();
    this.graphQlVideosService = new GraphQlVideosService();
  }

  /**
   * section: { key: <Str>, label: <Str>, title: <Str>, value: (<Int>, <Str>, <Bool>, <null>) }
   * boundCallbacks: methods that need to be bound to a parent component's context
   */
  getComponent(section, i, boundCallbacks, allSections) {
    const methodName = this.config._getViewMethodNameForSectionKey(section.key);

    if (!methodName) {
      errorHandler(`ViewAllBuilder getComponent requires a known key but got, ${section.key}! Check the config's SECTION_KEY_TO_VIEW_METHOD to make sure the key is defined!`);

      return (<div />);
    }

    return this[methodName](section, i, boundCallbacks, allSections);
  }

  /**
   * Prop getters
   */
  _getComponentFromConfig(section, props) {
    return this.config.components.hasOwnProperty(section.key) ? this.config.components[section.key](props) : (<div />);
  }

  _getLinksFromConfig(section, item) {
    return this.config.links.hasOwnProperty(section.key) ? this.config.links[section.key](section, item) : { external: '', internal: '' };
  }

  _getListFacet(section, allSections, i) {
    return this.config.listFacet.hasOwnProperty(section.key) ? this.config.listFacet[section.key](section, allSections, i) : [];
  }

  _getLoaderComponentFromConfig(section) {
    return this.config.loaderComponent.hasOwnProperty(section.key) ? this.config.loaderComponent[section.key](section) : (<div />);
  }

  _getPagination(section) {
    return this.config.pagination.hasOwnProperty(section.key) ? this.config.pagination[section.key](section) : {};
  }

  _getPinnedItemFacet(section) {
    return this.config.pinnedItemFacet.hasOwnProperty(section.key) ? this.config.pinnedItemFacet[section.key](section) : [];
  }

  _getProjectsFacet(section) {
    return this.config.projectsFacet.hasOwnProperty(section.key) ? this.config.projectsFacet[section.key](section) : [];
  }

  _getQueryMap(section) {
    return this.config.queryMap.hasOwnProperty(section.key) ? this.config.queryMap[section.key](section) : {};
  }

  _getServiceFromConfig(section) {
    if (!this.config.service.hasOwnProperty(section.key)) {
      errorHandler(`ViewAllBuilder _getServiceFromConfig got an unknown key, ${section.key}!  Known keys: ${Object.keys(this.config.service)}`);

      return {};
    }

    // NOTE: Add the correct graphQlServices as we override things bit by bit. When we're done we'll remove the override and copy
    // the config to the normal service object.
    if (this.config.hasOwnProperty('serviceOverride') && this.config.serviceOverride.hasOwnProperty(section.key)) {
      return this[this.config.serviceOverride[section.key]()];
    }

    return this[this.config.service[section.key]()];
  }

  // NOTE: This is to temporarily override specific project graphQlServices for relations.
  _getProjectServiceForPinnedList(section) {
    if (this.config.hasOwnProperty('pinnedServiceOverride') && this.config.pinnedServiceOverride.hasOwnProperty(section.key)) {
      return this[this.config.pinnedServiceOverride[section.key]()];
    }

    return this.projectsService;
  }

  /**
   * Views
   */
  _pinnedItemProjectsListView(section, i, passThroughProps, allSections) {
    return (
      <AlgoliaPinnedItemViewAllList
        key={`${section.key}_${section.value}`}
        algoliaPinnedItemService={this._getServiceFromConfig(section)}
        algoliaProjectsService={this._getProjectServiceForPinnedList(section)}
        index={i}
        initPinnedItemFacet={this._getPinnedItemFacet(section)}
        initProjectsFacet={this._getProjectsFacet(section)}
        pagination={this._getPagination(section)}
        pinnedCardTemplateFn={(props) => this._getComponentFromConfig(section, props)}
        queryMap={this._getQueryMap(section)}
        title={section.title}
        viewAllLinksComposer={(item) => this._getLinksFromConfig(section, item)}
        {...passThroughProps}
      />
    );
  }

  _viewAllList(section, i, passThroughProps, allSections) {
    return (
      <AlgoliaViewAllList
        key={`${section.key}_${section.value}`}
        algoliaService={this._getServiceFromConfig(section)}
        cardTemplateFn={(record, i, props) => this._getComponentFromConfig(section, { record, i, ...props })}
        index={i}
        initFacet={this._getListFacet(section, allSections, i)}
        loaderCardFn={() => this._getLoaderComponentFromConfig(section)}
        pagination={this._getPagination(section)}
        queryMap={this._getQueryMap(section)}
        section={section}
        title={section.title}
        viewAllLinksComposer={() => this._getLinksFromConfig(section)}
        {...passThroughProps}
      />
    );
  }
}
