import React from 'react';
import PropTypes from 'prop-types';

import EventCardDate from './EventCardDate';

import typography from '../../../styles/global_ui/typography.css';
import styles from './event_card.css';

const classList = {
  day: `${typography.h4} ${typography.lineHeight1}`,
  month: `${typography.bodyM} ${typography.pebble} ${typography.uppercase}`,
};

const DateRange = ({ endDate, startDate }) => (
  <div className={styles.dateWrapper}>
    {!!startDate && <EventCardDate classList={classList} dateString={startDate} />}
    {!!startDate && !!endDate && <div className={styles.dateDivider} />}
    {!!endDate && <EventCardDate classList={classList} dateString={endDate} />}
  </div>
);

DateRange.propTypes = {
  endDate: PropTypes.string,
  startDate: PropTypes.string,
};

DateRange.defaultProps = {
  endDate: '',
  startDate: '',
};

export default DateRange;
