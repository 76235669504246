import styles from './styles.css';

const themes = {
  light: styles,
  dark: {
    ...styles,
    dialog: styles.dialogDark,
    dismiss: styles.dismissDark,
    wrapper: styles.wrapperDark,
  },
};

export default themes;
