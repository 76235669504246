import React from 'react';
import PropTypes from 'prop-types';

import LazyBackgroundImage from '../../../image/LazyBackgroundImage';

import { getInObj } from '../../../../utility/accessors';

import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from '../news_card.css';

const NewsArticleSponsoredCardPreview = ({ article, isResponsive }) => (
  <div className={styles.sponsoredCard}>
    <div className={styles.sponsoredImgWrapper}>
      <LazyBackgroundImage
        classList={{ root: `${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}` }}
        ratio={getInObj(['mobile_image', 'url'], article) !== null ? '1:1' : '16:9'}
        setStyle={false}
        src={getInObj(['mobile_image', 'url'], article) || getInObj(['image', 'url'], article)}
        width={158}
      />
    </div>
    <div className={styles.sponsoredDataWrapper}>
      <p className={`${typography.bodyM} ${typography.bold} ${typography.linkCharcoal}`}>
        {article.title}
      </p>
      <p className={typography.bodyS}>
        {'Sponsored by '}
        {(article.sponsor_name && article.sponsor_name.length > 0)
        && (
          <span className={typography.linkBlue}>
            {article.sponsor_name}
          </span>
        )}
      </p>
    </div>
  </div>
);

NewsArticleSponsoredCardPreview.propTypes = {
  article: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string }),
    mobile_image: PropTypes.shape({ url: PropTypes.string }),
    sponsor_link: PropTypes.string,
    sponsor_name: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isResponsive: PropTypes.bool,
};

NewsArticleSponsoredCardPreview.defaultProps = { isResponsive: true };

export default NewsArticleSponsoredCardPreview;
