import React from 'react';
import PropTypes from 'prop-types';

import Button from '../base';
import FreezeWidthButton from '../freeze_width_button';
import RingSpinner from '../../spinners/ring';

import layout from '../../../styles/global_ui/layout.css';

const getDefaultView = ({ buttonType, color, disabled, isBusy, onClick, size, text }) => (
  <FreezeWidthButton colorStyle={color} disabled={disabled || isBusy} onClick={onClick} size={size} type={buttonType}>
    {isBusy ? (<RingSpinner size={size === 'lg' ? 20 : 16} />) : text}
  </FreezeWidthButton>
);

const getFullWidthView = ({ buttonType, color, disabled, isBusy, onClick, size, text }) => (
  <Button className={layout.fullWidth} colorStyle={color} disabled={disabled || isBusy} onClick={onClick} size={size} type={buttonType}>
    {isBusy ? (<RingSpinner size={size === 'lg' ? 20 : 16} />) : text}
  </Button>
);

const getComponentForType = (props) => {
  switch (props.type) {
    case 'fullWidth':
      return getFullWidthView(props);

    default:
      return getDefaultView(props);
  }
};

const ActionableButton = (props) => getComponentForType(props);

ActionableButton.propTypes = {
  buttonType: PropTypes.oneOf(['button', 'submit']),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isBusy: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['default', 'fullWidth']),
};

ActionableButton.defaultProps = {
  buttonType: 'button',
  color: '',
  disabled: false,
  isBusy: false,
  onClick: null,
  size: 'md',
  type: 'default',
};

export default ActionableButton;
