import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Figcaption extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onBlur(e) {
    // Stop Propagation is very important!
    e.stopPropagation();
    this.props.onBlur(e);
  }

  onChange(e) {
    const value = e.target.value;
    this.props.onValueChange(value);
    this.setState({value});
  }

  render() {
    return (
      <input
        ref={el => this.input = el}
        className={this.props.className}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={e => this.props.onFocus(e)}
        onKeyDown={this.props.onKeyDown}
        placeholder={this.props.placeholder}
        type="text"
        value={this.state.value}
      />
    );
  }
}

Figcaption.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  value: PropTypes.string
};

Figcaption.defaultProps = {
  className: 'react-editor-figcaption',
  placeholder: 'caption (optional)',
  value: ''
};

export default Figcaption;