import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import AutosizeInput from './AutosizeInput';
import Icon from '../../icon';
import SelectMenu from './SelectMenu';
import MultiSelected from './MultiSelected';
import RingSpinner from '../../spinners/ring';

import filterOptions from './utils/filterOptions';
import getStylesForTheme from './themes';
import errorHandler from '../../../services/error_handler';

import { isAFunction, isObject } from '../../../utility/types';

import inputStyles from '../../../styles/global_ui/inputs.css';

const GENERIC_RESOLVER = (res) => Promise.resolve(res);

// TODO:
// In async mode, if initOnMount is false and user opens the menu (by focus or arrow or down key), there will be a no results view.
// Solutions: Do not render the menu if value is empty and theres no options. Or we can fetch the initial options on menu open and save it in
// cache as per norm.

/**
 * Notes:
 * If the Select your creating is on a form, use selects/form_select for the proper FormGroup UI as per 2018 guidelines.
 *
 * Rails note:
 *   When creating a Component that is embedded into a Rails form (bootstrap styles), make sure to pass a maxWidth prop
 *   in. Without it, flex-wrap doesn't work properly in IE11.
 */
class Select extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      isBusy: true,
      openMenu: false,
      options: props.options,
      selected: [],
      value: '',
    };

    this.debouncedRequest = debounce(this.debouncedRequest.bind(this), 150);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectActionableClick = this.onSelectActionableClick.bind(this);
    this.onUnselect = this.onUnselect.bind(this);

    // Hooks
    this.__resetState = this.__resetState.bind(this);
    this.__triggerOnChangeForValue = this.__triggerOnChangeForValue.bind(this);

    // Ref
    this._input;
    // Timer
    this._blurTimeout;
    // Flag
    this._ignoreClick = false;
    // Cache
    this._asyncCache = {};
    // Theme
    this._theme = getStylesForTheme(props.theme);
  }

  componentDidMount() {
    this._isMounted = true;
    this._init();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._clearBlurTimeout();
  }

  /**
   * Initializers
   */
  _init() {
    return (this._isAsync() && this.props.asyncOpts.initOnMount) ? this._initAsync() : this._initSync();
  }

  _initSync(resetValue = null) {
    this.setState({
      initialized: true,
      isBusy: false,
      ...this._initStateValues((resetValue || this.props.value), this.props.options),
    });
  }

  _initAsync(resetValue = null, resetRequestValue = null) {
    const resolver = isAFunction(this.props.asyncOpts.resolver) ? this.props.asyncOpts.resolver : GENERIC_RESOLVER;

    return this.props.asyncOpts.request((resetRequestValue || this.props.asyncOpts.initQuery || ''))
      .then((res) => resolver(res))
      .then(({ options }) => {
        if (resetValue === null && resetRequestValue === null && !this._hasInAsyncCache('')) {
          this._setInAsyncCache('', options);
        }
        this._setStateIfMounted({
          initialized: true,
          isBusy: false,
          ...this._initStateValues((resetValue || this.props.value), options),
        });
      })
      .catch((err) => {
        if (isAFunction(this.props.asyncOpts.onError)) this.props.asyncOpts.onError(err);
        errorHandler('Select _init: ', err);
      });
  }

  _initStateValues(initVal, options) {
    if (this.props.type === 'default') {
      const value = isObject(initVal) ? initVal.value : initVal;
      const selectedOpt = options.find((o) => o.value === value);
      const selected = selectedOpt ? [selectedOpt] : [];
      const inputValue = selectedOpt ? selectedOpt.label : value;

      return { options, selected, value: inputValue };
    } else {
      if (!Array.isArray(initVal)) {
        console.warn(`Select got a value prop of: ${initVal}, but expects an array of objects for type "multi"`);

        return { options, selected: [], value: '' };
      }

      return { options, selected: initVal, value: '' };
    }
  }

  /**
   * Hooks
   */
  __resetState() {
    this.setState({
      openMenu: false,
      options: this.props.options,
      selected: [],
      value: '',
    });
  }

  __triggerOnChangeForValue(value, requestValue) {
    return this._isAsync() ? this._initAsync(value, requestValue) : this._initSync(value);
  }

  /**
   * Async Cache Helpers
   */
  _getFromAsyncCache(key, val) {
    return this._hasInAsyncCache(key) ? this._asyncCache[key] : [];
  }

  _hasInAsyncCache(key) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return this._asyncCache.hasOwnProperty(key);
  }

  _setInAsyncCache(key, val, overwrite = false) {
    if (this._hasInAsyncCache(key) === false || overwrite === true) {
      this._asyncCache[key] = val;
    }
  }

  /**
   * Methods
   */
  debouncedRequest(fn) {
    fn();
  }

  onBlur() {
    this._blurTimeout = window.setTimeout(() => {
      this._clearBlurTimeout();
      this.setState({ openMenu: false, options: this._getOptionsForPotentialReset(), value: this._getInputValueOnBlur() });
    }, 150);
  }

  onChange(e) {
    const value = e.target.value;

    return this._isAsync() ? this._asyncOnChange(value) : this._syncOnChange(value);
  }

  _asyncOnChange(value, openMenu = true) {
    this.setState({ openMenu, isBusy: true, value: value });

    return this.debouncedRequest(() => {
      const resolver = isAFunction(this.props.asyncOpts.resolver) ? this.props.asyncOpts.resolver : GENERIC_RESOLVER;

      return this.props.asyncOpts.request(value)
        .then((res) => resolver(res))
        .then(({ options }) => {
          // Set the default search result in cache if we dont already have it.
          if (value === '' && this._hasInAsyncCache('') === false) {
            this._setInAsyncCache('', options);
          }

          this._setStateIfMounted({
            isBusy: false,
            selected: this._isMulti() ? this.state.selected : [],
            options,
          });
        })
        .catch((err) => {
          if (isAFunction(this.props.asyncOpts.onError)) this.props.asyncOpts.onError(err);
          this._setStateIfMounted({ isBusy: false });
          errorHandler('Select _asyncOnChange', err);
        });
    });
  }

  _syncOnChange(value, openMenu = true) {
    this.setState({
      openMenu,
      selected: this._isMulti() ? this.state.selected : [],
      value,
    });
  }

  onClear() {
    this._propagateSelected([], { openMenu: false, options: this._getOptionsForPotentialReset(), selected: [], value: '' });
  }

  onClick() {
    if (this.state.initialized === false) return;

    if (this._ignoreClick) {
      this._ignoreClick = false;

      return;
    }

    if (this.state.openMenu === false && !this.props.disabled) {
      this._setStateAndFocusInput({ openMenu: true });
    }
  }

  onKeyDown(e) {
    switch (e.keyCode) {
      case 8: // DELETE
        if (this._isMulti() && e.target.value === '' && this.state.value === '' && this.state.selected.length > 0) {
          const selected = this.state.selected.slice(0, this.state.selected.length - 1);
          this._propagateSelected(selected, { selected }, (state) => this.setState(state));
        }
        break;

      case 27: // ESC
        if (this.state.openMenu) {
          this.setState({ openMenu: false });
        }
        break;

      case 40: // ARROW DWN
        if (this.state.openMenu === false && !this.props.disabled) {
          this.setState({ openMenu: true });
        }
        break;

      default:
        return;
    }
  }

  onSelect(opt, isCurrentlySelected) {
    const selected = isCurrentlySelected
      ? this.state.selected.filter((s) => s.value !== opt.value)
      : this._isMulti()
        ? this.state.selected.concat(opt)
        : [opt];
    const value = (this._isMulti() || isCurrentlySelected === true) ? '' : opt.label;
    const state = {
      selected,
      value,
      openMenu: false,
      options: this._getOptionsForPotentialReset(),
    };
    this._propagateSelected(selected, state);
  }

  onSelectActionableClick() {
    return (isAFunction(this.props.creatableOpts.request)) ? this._createOptionAsync() : this._createOptionSync();
  }

  onUnselect(opt) {
    const selected = this.state.selected.filter((s) => s.value !== opt.value);
    this._propagateSelected(selected, { selected }, (state) => this._setIngoreClickAndSetState(state));
  }

  /**
   * Helpers
   */
  _clearBlurTimeout() {
    if (this._blurTimeout) {
      window.clearTimeout(this._blurTimeout);
      this._blurTimeout = undefined;
    }
  }

  // Potential bug: if we're pulling data from algolia and create a option async, when a user deletes the option,
  // triggers a search and tries to find the deleted tag again, it probably wont be there in Algolia. Then if they try to create
  // the option again, we may issue a error saying its already created. We can cache the async tags in state in case of this,
  // but are lost if a reload happens.
  _createOptionAsync() {
    this._setStateAndFocusInput({ isBusy: true });

    const resolver = isAFunction(this.props.creatableOpts.resolver) ? this.props.creatableOpts.resolver : GENERIC_RESOLVER;

    return this.props.creatableOpts.request(this.state.value)
      .then((res) => resolver(res))
      .then(({ option }) => this.setState({
        isBusy: false,
        openMenu: false,
        options: [option, ...this.state.options],
        selected: this._isMulti() ? this.state.selected.concat(option) : [option],
        value: this._isMulti() ? '' : option.label,
      }))
      .catch((err) => {
        if (isAFunction(this.props.creatableOpts.onError)) this.props.creatableOpts.onError(err);
        errorHandler('_createOptionAsync', err);
      });
  }

  _createOptionSync() {
    const value = this.state.value;
    const option = { label: value, value: value };
    const selected = this._isMulti() ? this.state.selected.concat(option) : [option];

    this._propagateSelected(selected, {
      openMenu: false,
      options: [option, ...this.state.options],
      selected,
      value: this._isMulti() ? '' : option.label,
    });
  }

  /**
   * Option filtering happens per render. When in async mode, we let the outside request do the filter/sorting.
   * To determine what algorithm the internal filtering uses, see searchOpts.rule.
   */
  _filterOptions() {
    if (this._isAsync()) return this.state.options;

    return filterOptions({ options: this.state.options, value: this.state.value, opts: this.props.searchOpts });
  }

  _getInputValueOnBlur() {
    if (this.props.debugMode) return this.state.value;
    if (this._isMulti()) return '';

    return this.state.selected.length > 0 ? this.state.value : '';
  }

  _getOptionsForPotentialReset() {
    return this._isAsync() ? this._getFromAsyncCache('') : this.state.options;
  }

  _getSelectedToPropagate(selected) {
    return this._isMulti() ? selected : selected.length > 0 ? selected[0] : null;
  }

  _isAsync() {
    return isAFunction(this.props.asyncOpts.request);
  }

  _isMulti() {
    return this.props.type === 'multi';
  }

  /**
   * Handles how selected items get passed up to the parent element (Async or Sync).
   * The state argument is required when you expect _setStateAndFocusInput to be called.
   * The cb or callback argument is when you want to just setState or something else and not call _setStateAndFocusInput.
   * The cb will receive the state argument, but shouldn't be needed.
   */
  _propagateSelected(selected, state = null, cb = null) {
    return (isAFunction(this.props.onSelectOpts.request))
      ? this._propagateSelectedAsync(this._getSelectedToPropagate(selected), state, cb)
      : this._propagateSelectedSync(this._getSelectedToPropagate(selected), state, cb);
  }

  _propagateSelectedAsync(selected, stateToBe, cb) {
    this.setState({ isBusy: true });

    const resolver = isAFunction(this.props.onSelectOpts.resolver) ? this.props.onSelectOpts.resolver : GENERIC_RESOLVER;

    return this.props.onSelectOpts.request(selected, stateToBe, this.state)
      .then((res) => resolver(res))
      .then(({ state }) => {
        // Allow parent to override any state for flexibility. Lets the parent handle cases where it internally failed a request
        // and it wants to reset state here.
        const updatedState = { ...stateToBe, ...state, isBusy: false };

        return isAFunction(cb) ? cb(updatedState) : this._setStateAndFocusInput(updatedState);
      })
      .catch((err) => {
        if (isAFunction(this.props.onSelectOpts.onError)) this.props.onSelectOpts.onError(err);
        errorHandler('_propagateSelectedAsync', err);
      });
  }

  _propagateSelectedSync(selected, state, cb) {
    this.props.onSelectedChange(selected);

    if (isAFunction(cb)) {
      cb(state);
    } else if (state !== null) {
      this._setStateAndFocusInput(state);
    }
  }

  /**
   *  We want to ignore clicks on multiOptions and anything under the inputWrapper class element.
   *  Its so the menu doesn't open unnecessarily.
   */
  _setIngoreClickAndSetState(state) {
    this._ignoreClick = true;
    this._setStateAndFocusInput(state);
  }

  _setStateAndFocusInput(state = {}) {
    this._clearBlurTimeout();
    this.setState(state, () => {
      if (this._input) this._input.focus();
    });
  }

  _setStateIfMounted(state = {}) {
    if (this._isMounted) this.setState(state);
  }

  /**
   * Views
   */
  _getCloseXView() {
    if (!this.props.uiOpts.renderX) return null;

    const styles = this._theme.select;

    return (
      <span className={styles.closeXWrapper} onClick={this.onClear}>
        <Icon className={styles.closeX} name="close" />
      </span>
    );
  }

  _getInputView() {
    return this._isMulti() ? this._getInputAutosizeView() : this._getInputDefaultView();
  }

  _getInputAutosizeView() {
    const styles = this._theme.select;

    return (
      <AutosizeInput
        className={`${inputStyles.input} ${styles.input}`}
        disabled={(this.state.initialized === false || this.props.disabled)}
        getInputRef={(c) => this._input = c}
        id={this.props.uuid}
        name={this.props.name}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        placeholder={this._getPlaceholder()}
        value={this.state.value}
      />
    );
  }

  _getInputDefaultView() {
    const styles = this._theme.select;

    return (
      <input
        ref={(c) => this._input = c}
        className={`${inputStyles.input} ${styles.input} ${this.props.classList.input}`}
        disabled={(this.state.initialized === false || this.props.disabled)}
        id={this.props.uuid}
        name={this.props.name}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        placeholder={this._getPlaceholder()}
        value={this.state.value}
      />
    );
  }

  _getLoaderOrCloseXView() {
    if (this.state.isBusy) {
      return this._getSpinnerView();
    } else if (this.state.selected.length > 0) {
      return this._getCloseXView();
    }
  }

  _getPlaceholder() {
    if (this.state.initialized === false) return 'Loading...';
    if (this._isMulti() && this.state.selected.length > 0) return null;

    return this.props.placeholder;
  }

  _getMultiSelectedView() {
    if (this._isMulti() === false) return;

    return (
      <MultiSelected
        onUnselect={this.onUnselect}
        selected={this.state.selected}
        styles={this._theme.select}
      />
    );
  }

  _getSpinnerView() {
    const styles = this._theme.select;

    return (
      <span className={styles.closeXWrapper}>
        <RingSpinner size="16" />
      </span>
    );
  }

  render() {
    const styles = this._theme.select;

    return (
      <div className={`${styles.root} ${this.props.classList.root}`} style={{ maxWidth: this.props.maxWidth }}>
        <div
          className={`${styles.container} ${this.props.classList.container} ${this.state.openMenu ? styles.menuOpened : ''} ${this.props.hasErrors ? styles.borderError : ''} ${this.props.disabled ? styles.disabled : ''}`}
          data-opened={this.state.openMenu}
        >

          {this.props.uiOpts.leftIconName
          && (
            <div className={styles.leftIconWrapper}>
              <Icon className={styles.leftIcon} name={this.props.uiOpts.leftIconName} size={16} />
            </div>
          )}

          <div className={`${styles.inputWrapper} ${this.props.classList.inputWrapper}`} onClick={this.onClick}>
            {this._getMultiSelectedView()}
            {this._getInputView()}
          </div>

          <div className={`${styles.actions} ${this.props.disabled ? styles.disabled : ''}`}>
            {this._getLoaderOrCloseXView()}

            <span className={styles.arrowWrapper} onClick={() => !this.props.disabled && this._setStateAndFocusInput({ openMenu: !this.state.openMenu })}>
              <Icon
                className={`${styles.arrow} ${this.state.openMenu ? styles.arrowSelected : ''}`}
                name="arrow-down"
              />
            </span>
          </div>
        </div>

        {(this.props.debugMode || this.state.openMenu)
        && (
          <SelectMenu
            clearBlurTimeout={() => this._clearBlurTimeout()}
            config={this.props.menuOpts}
            creatable={this.props.creatableOpts.creatable}
            currentValue={this.state.value}
            isBusy={this.state.isBusy}
            onActionableClick={this.onSelectActionableClick}
            onSelect={this.onSelect}
            options={this._filterOptions()}
            selected={this.state.selected}
            styles={this._theme.menu}
            templates={{
              actionable: this.props.templates.menuActionable,
              actionableText: this.props.templates.menuActionableText,
              option: this.props.templates.menuOption,
            }}
            uuid={this.props.uuid}
          />
        )}
      </div>
    );
  }
}

Select.propTypes = {
  asyncOpts: PropTypes.shape({
    initOnMount: PropTypes.bool, // Whether to fire a initial request on mount.
    initQuery: PropTypes.any, // Initial string to bake request with.
    onError: PropTypes.func, // Called when the reqeuest or resolver throws an error.
    request: PropTypes.func, // If this property is a function, we assume the component a AsyncSelect.
    resolver: PropTypes.func, // Maps the request's response to an array of options. Expects an object with a 'options' property.
  }),
  classList: PropTypes.shape({
    container: PropTypes.string,
    input: PropTypes.string,
    inputWrapper: PropTypes.string,
    root: PropTypes.string,
  }),
  creatableOpts: PropTypes.shape({
    creatable: PropTypes.bool, // Can this instance create options.
    onError: PropTypes.func, // Called when the reqeuest or resolver throws an error.
    request: PropTypes.func, // If set option creation will assume to be async.
    resolver: PropTypes.func, // Maps the request's response to a option object. Expects an object with a 'option' property.
  }),
  debugMode: PropTypes.bool, // Keeps the menu open and does not reset values onBlur.
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  maxWidth: PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string],
  ),
  menuOpts: PropTypes.shape({
    scrollBuffer: PropTypes.number, // Adds a padding bottom buffer below the menu.
    scrollToView: PropTypes.bool, // When menu is opened, scroll screen to the bottom of the menu.
  }),
  name: PropTypes.string, // Input name.
  onSelectOpts: PropTypes.shape({ // If a parent requires a async action onSelect use these options.
    onError: PropTypes.func, // Called when the reqeuest or resolver throws an error.
    request: PropTypes.func, // Overrides the onSelectedChange prop.
    resolver: PropTypes.func, // Maps the requests response to a state object. Expects an object with a 'state' property.
  }),
  onSelectedChange: PropTypes.func, // Called whenever the selected state updates.
  options: PropTypes.arrayOf( // Menu items.
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  placeholder: PropTypes.string,
  searchOpts: PropTypes.shape({
    rule: PropTypes.oneOf( // How the input value dictates the menu filtering when in sync mode.
      ['absolute', 'default', 'norule', 'strict'],
    ),
  }),
  templates: PropTypes.shape({
    menuActionable: PropTypes.func, // By default the "Create option" button, replaces the entire button view.
    menuActionableText: PropTypes.func, // Overrides the "Create option" button's text only.
    menuOption: PropTypes.func, // Override each list item view, argument passed into this function is the option itself.
  }),
  theme: PropTypes.oneOf(['default', 'bootstrap', 'search']),
  type: PropTypes.oneOf(['default', 'multi']),
  uiOpts: PropTypes.shape({
    leftIconName: PropTypes.string, // Icon that will render to the left of the input. i.e search glass.
    renderX: PropTypes.bool,
  }),
  uuid: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.value })),
  ]),
};

Select.defaultProps = {
  asyncOpts: {
    initOnMount: false,
    initQuery: '',
    onError: () => {},
    request: null,
    resolver: (options) => Promise.resolve({ options }),
  },
  classList: {
    container: '',
    input: '',
    inputWrapper: '',
    root: '',
  },
  creatableOpts: {
    creatable: false,
    onError: () => {},
    request: null,
    resolver: null,
  },
  debugMode: false,
  disabled: false,
  hasErrors: false,
  maxWidth: '100%',
  menuOpts: {
    scrollBuffer: 0,
    scrollToView: false,
  },
  name: null,
  onSelectOpts: {
    request: null,
    resolver: (state) => Promise.resolve({ state }),
  },
  onSelectedChange: () => {},
  options: [],
  placeholder: '',
  searchOpts: { rule: 'default' },
  templates: {
    menuActionable: null,
    menuActionableText: null,
    menuOption: null,
  },
  theme: 'default',
  type: 'default',
  uiOpts: {
    leftIconName: null,
    renderX: true,
  },
  uuid: null,
  value: '',
};

export default Select;
