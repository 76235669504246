import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DesktopInputWrapper from '../templates/DesktopInputWrapper';
import DateInputs from '../templates/DateInputs';
import TimeInputs from '../templates/TimeInputs';

import { parseDatetimeString, datetimeConfig, formatDatetimeOut } from '../datetimeInputHelpers';

const DesktopDatetimeLocalInput = ({ autoComplete, disabled, errors, initValue, onChange }) => (
  <DesktopInputWrapper
    disabled={disabled}
    errors={errors}
    formatOut={formatDatetimeOut}
    initValue={initValue}
    inputConfig={datetimeConfig}
    onChange={onChange}
    parseValueString={parseDatetimeString}
  >
    {({ _inputRefs, handleInputKeyDown, handleNumberBlur, handleNumberChange, values }) => (
      <Fragment>
        <DateInputs
          _inputRefs={_inputRefs.slice(0, 3)}
          autoComplete={autoComplete}
          disabled={disabled}
          handleInputKeyDown={handleInputKeyDown}
          handleNumberBlur={handleNumberBlur}
          handleNumberChange={handleNumberChange}
          values={values}
        />
        <TimeInputs
          _inputRefs={_inputRefs.slice(3)}
          autoComplete={autoComplete}
          disabled={disabled}
          handleInputKeyDown={handleInputKeyDown}
          handleNumberBlur={handleNumberBlur}
          handleNumberChange={handleNumberChange}
          values={values}
        />
      </Fragment>
    )}
  </DesktopInputWrapper>
);

DesktopDatetimeLocalInput.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  initValue: PropTypes.string, // format: HH:MM (no timezone info) for compatibility with native <input type="time">
  onChange: PropTypes.func.isRequired,
};

DesktopDatetimeLocalInput.defaultProps = {
  autoComplete: null,
  disabled: false,
  errors: null,
  initValue: '',
};

export default DesktopDatetimeLocalInput;
