import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DateRange from '../../../../../client/cards/event_card/DateRange';
import DateRangePlaceholder from './DateRangePlaceholder';
import EventTypeOrLocation from '../../../../../client/cards/event_card/EventTypeOrLocation';
import Icon from '../../../../../client/icon';
import LazyImage from '../../../../../client/reusable_components/LazyImage';
import SponsorList from '../../../../../client/cards/event_card/SponsorList';
import { TextPlaceholder } from './templates';

import { getDateRangeAsText } from '../../../../../client/cards/event_card/eventCardHelpers';
import { getInObj } from '../../../../../utility/accessors';
import { isBlank } from '../../../../../utility/types';

import colors from '../../../../../styles/global_ui/colors.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import eventCardStyles from '../../../../../client/cards/event_card/event_card.css';

const getTitlePlaceholder = () => (
  <Fragment>
    <TextPlaceholder backgroundColor={colors['v-asphalt']} height={24} marginTop={15} width="100%" />
    <TextPlaceholder backgroundColor={colors['v-asphalt']} height={24} marginTop={10} width="80%" />
  </Fragment>
);

const EventCardSponsoredSmallPreview = ({ className, imageRight, item }) => {
  const imageUrl = getInObj(['image', 'url'], item);

  return (
    <div className={`${layout.flexCenterItems} ${layout.flexColumnWrap} ${layout.fullWidth} ${typography.breakWord} ${className}`}>
      <div className={`${utilStyles.absolutePlaceholderParent} ${utilStyles.aspectRatioPadding3_2} ${layout.fullWidth} ${utilStyles.borderRadius} ${imageUrl ? '' : utilStyles.bgAsphalt} ${typography.breakWord}`}>
        {!!imageUrl
        && (
          <LazyImage
            alt={item.title}
            classList={{ root: utilStyles.absolutePlaceholderChild, image: utilStyles.borderRadius }}
            ratio="3:2"
            setStyle={false}
            src={imageUrl}
            width={370}
          />
        )}
        <div className={eventCardStyles.dateBadge}>
          {(item.start_date || item.end_date)
            ? <DateRange endDate={item.end_date} startDate={item.start_date} />
            : <DateRangePlaceholder />}
        </div>
      </div>
      <div
        className={`${typography.bodyL} ${layout.flexColumnWrap} ${layout.flexCenterItems} ${layout.fullWidth}`}
        onClick={(e) => e.preventDefault()}
      >
        {isBlank(item.platforms) && isBlank(item.custom_sponsors)
          ? <TextPlaceholder backgroundColor={colors['v-asphalt']} height={18} marginTop={22} width="50%" />
          : <SponsorList className={`${layout.marginTop22} ${typography.textCenter}`} item={item} />}
        {item.title
          ? <span className={`${typography.linkCharcoal} ${typography.h3Responsive} ${layout.marginTop10} ${typography.textCenter}`}>{item.title}</span>
          : getTitlePlaceholder()}
        <div className={`${layout.inlineFlex} ${layout.flexCenterItems} ${layout.marginTop10}`}>
          {(item.start_date || item.end_date)
            ? getDateRangeAsText(item)
            : <TextPlaceholder backgroundColor={colors['v-asphalt']} height={18} width={80} />}
          <span className={`${layout.marginLeft10} ${layout.marginRight10}`}>•</span>
          {/* TODO: this logic might change */}
          {item.event_type
            ? <EventTypeOrLocation item={item} />
            : <TextPlaceholder backgroundColor={colors['v-asphalt']} height={18} width={80} />}
        </div>
        <span className={`${layout.flex} ${layout.flexJustifyCenter} ${layout.marginTop10} ${typography.bold} ${typography.linkBlue} ${typography.textWithIcon}`}>
          Learn more
          <Icon className={layout.marginLeft5} name="arrow-right" />
        </span>
      </div>
    </div>
  );
};

EventCardSponsoredSmallPreview.propTypes = {
  event: PropTypes.shape({
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    end_date: PropTypes.string,
    event_type: PropTypes.string,
    image: PropTypes.shape({ url: PropTypes.string }),
    location: PropTypes.shape({}),
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    start_date: PropTypes.string,
    state: PropTypes.string,
    title: PropTypes.string,
  }),
};

EventCardSponsoredSmallPreview.defaultProps = {
  event: {
    custom_sponsors: [],
    end_date: null,
    event_type: null,
    image: null,
    location: null,
    platforms: [],
    start_date: null,
    state: null,
    title: null,
  },
};

export default EventCardSponsoredSmallPreview;
