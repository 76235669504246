import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DefaultSimpleSelectView from './DefaultSimpleSelectView';
import TabbedSimpleSelectView from './TabbedSimpleSelectView';

const VIEWS = {
  default: DefaultSimpleSelectView,
  tabbed: TabbedSimpleSelectView,
};

class SimpleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedValueIndex: this._getActiveOrDefaultIndex(props.options) };

    this.selectOptionByIndex = this.selectOptionByIndex.bind(this);
  }

  _getActiveOrDefaultIndex(options) {
    const activeIndex = options.findIndex((option) => option.active);
    if (activeIndex > -1) return activeIndex;

    const defaultIndex = options.findIndex((option) => option.default);

    return defaultIndex > -1 ? defaultIndex : 0;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({ selectedValueIndex: this._getActiveOrDefaultIndex(this.props.options) });
    }
  }

  selectOptionByIndex(e) {
    const index = parseInt(e.target.value, 10);

    if (!isNaN(index)) {
      this._propagateSelectionByIndex(index);
      this.setState({ selectedValueIndex: index });
    }
  }

  _propagateSelectionByIndex(index) {
    this.props.onSelection(this.props.options[index], index);
  }

  render() {
    return React.createElement(VIEWS[this.props.view], {
      classList: this.props.classList,
      options: this.props.options,
      selectedValueIndex: this.state.selectedValueIndex,
      selectOptionByIndex: this.selectOptionByIndex,
      underline: this.props.tabbedViewUnderline,
    });
  }
}

SimpleSelect.propTypes = {
  classList: PropTypes.shape({
    label: PropTypes.string,
    root: PropTypes.string,
  }),
  onSelection: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]), // Can be null
    default: PropTypes.bool,
  })).isRequired,
  tabbedViewUnderline: PropTypes.bool,
  view: PropTypes.oneOf(['default', 'tabbed']),
};

SimpleSelect.defaultProps = {
  classList: {
    label: '',
    root: '',
  },
  onSelection: () => {},
  tabbedViewUnderline: true,
  view: 'default',
};

export default SimpleSelect;
