import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../client/wrappers/dropdown';
import Icon from '../../client/icon';

import currentUserService from '../../services/current_user';
import errorHandler from '../../services/error_handler';
import { showDropdownOrSummonDialog, getWidgetDropdown, getPortalWidgetDropdown, outOfBoundsLeft } from '../../client/bookmarks/helpers';

import buttonStyles from '../../styles/global_ui/buttons.css';
import layout from '../../styles/global_ui/layout.css';
import utilStyles from '../../styles/global_ui/util.css';
import menuStyles from '../../client/form_components/simple_select/simple_select.css';

const MENU_WIDTH = 136;

/**
 * Like BookmarkButton, but with an intermediary menu giving the option to remove
 * the project from the parent bookmark list.
 */
class AltBookmarkButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      dropdownType: null,
    };

    this.handleDismiss = this.handleDismiss.bind(this);
    this.summonMenu = this.summonMenu.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.summonWidget = this.summonWidget.bind(this);

    // ref
    this._button;
    this._wrapper;
  }

  componentDidMount() {
    return currentUserService.getAsync('id')
      .then((id) => this.setState({ initialized: true }))
      .catch((err) => errorHandler(err));
  }

  handleDismiss() {
    this.setState({ dropdownType: null });
  }

  handleRemove() {
    this.props.handleRemove(this.props.projectId);
  }

  summonMenu() {
    this.props.onSummonMenu({ project_id: this.props.projectId });
    this.setState({ dropdownType: 'menu' });
  }

  summonWidget() {
    const showDropdown = showDropdownOrSummonDialog({
      currentListId: this.props.list.id,
      projectId: this.props.projectId,
    });

    this.setState({ dropdownType: showDropdown ? 'widget' : null });
  }

  /**
   * Views
   */
  _getDropdown() {
    switch (this.state.dropdownType) {
      case 'menu':
        return this._getMenuDropdown();
      case 'widget':
        return this._getWidgetDropdown();
      default:
        return null;
    }
  }

  _getMenuDropdown() {
    return (
      <Dropdown
        key="menu"
        alignRight={!outOfBoundsLeft(this._wrapper.getBoundingClientRect(), MENU_WIDTH)}
        className={`${layout.paddingTop10} ${layout.paddingBottom10}`}
        dismiss={this.handleDismiss}
        style={{ width: MENU_WIDTH }}
      >
        <ul className={utilStyles.ulReset}>
          <li className={menuStyles.option} onClick={this.summonWidget}>Add to list</li>
          <li className={menuStyles.option} onClick={this.handleRemove}>Remove</li>
        </ul>
      </Dropdown>
    );
  }

  _getWidgetDropdown() {
    const params = {
      analytics: this.props.analytics,
      currentListId: this.props.list.id,
      dismiss: this.handleDismiss,
      projectId: this.props.projectId,
      getTarget: () => this._wrapper,
    };

    return this.props.usePortal ? getPortalWidgetDropdown(params) : getWidgetDropdown(params);
  }

  render() {
    const { initialized } = this.state;

    return (
      <div ref={(el) => this._wrapper = el} className={utilStyles.posRelative} onKeyDown={this.handleKeyDown}>
        <button className={buttonStyles.blank} disabled={!initialized} onClick={this.summonMenu}>
          <Icon name="view-more" size={16} />
        </button>
        {this._getDropdown()}
      </div>
    );
  }
}

AltBookmarkButton.propTypes = {
  analytics: PropTypes.shape({ widget_src: PropTypes.string.isRequired }).isRequired,
  handleRemove: PropTypes.func.isRequired,
  list: PropTypes.shape({
    projects_count: PropTypes.number,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    private: PropTypes.bool.isRequired,
  }).isRequired,
  onSummonMenu: PropTypes.func, // Used for analytics.
  projectId: PropTypes.number.isRequired,
  usePortal: PropTypes.bool, // uses a portal to 'break out' of containers where overflow is invisible. use only when necessary
};

AltBookmarkButton.defaultProps = {
  onSummonMenu: () => {},
  usePortal: false,
};

export default AltBookmarkButton;
