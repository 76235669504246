import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BasicSearchInput from '../../../../client/form_components/inputs/basic_search_input';
import Breadcrumb from '../../../../client/nav_components/breadcrumb';
import Button from '../../../../client/buttons/base';
import HorizontalScrollBox from '../../../../client/wrappers/horizontal_scroll_box';
import Icon from '../../../../client/icon';
import NewsArticleListItem from './NewsArticleListItem';
import NewsArticleListLoader from './NewsArticleListLoader';
import Paginator from '../../../../client/nav_components/paginator';
import SimpleSelect from '../../../../client/form_components/simple_select';

import {
  DRAFT_FILTER,
  PUBLISHED_FILTER,
  SCHEDULED_FILTER,
  SUBMITTED_FILTER,
  SPONSORED_FILTER,
} from '../../../../graphql/news/enums';

import { getInObj } from '../../../../utility/accessors';
import { mapifyStringQuery } from '../../../../utility/converters';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './news_admin_list.css';

const STATUS_OPTS = [
  { key: 'all', default: true, label: 'All', value: null },
  { key: 'drafts', label: 'Drafts', value: DRAFT_FILTER },
  { key: 'submitted', label: 'Submitted', value: SUBMITTED_FILTER },
  { key: 'scheduled', label: 'Scheduled', value: SCHEDULED_FILTER },
  { key: 'published', label: 'Published', value: PUBLISHED_FILTER },
  { key: 'sponsored', label: 'Sponsored', value: SPONSORED_FILTER },
];

// NOTE: Leaving this as a PureComponent on purpose.
class NewsArticlesList extends PureComponent {
  constructor(props) {
    super(props);

    this._handleOnSelection = this._handleOnSelection.bind(this);
  }

  /**
   * Helpers
   */
  _getSearchValueFromHistory(queryMap) {
    return (getInObj(['q'], queryMap) || '');
  }

  _getStatusOptions(queryMap) {
    const status = getInObj(['status'], queryMap);
    const filter = getInObj(['filter'], queryMap);
    const refined = filter || status;

    return refined
      ? STATUS_OPTS.map((opt) => opt.value === refined ? { ...opt, active: true } : opt,
      )
      : STATUS_OPTS;
  }

  /**
   * Views
   */
  _getEmptyView() {
    return (
      <p className={`${typography.bodyM} ${layout.paddingTop30}`}>No articles found</p>
    );
  }

  _getLayoutBreadCrumb() {
    if (!this.props.isAdminOrEditor) return null;

    return (
      <Breadcrumb
        direction="right"
        href={this.props.adminNewsUrl}
        size="M"
        text="Go to layout editor"
      />
    );
  }

  _getListView() {
    if (this.props.isNavigating) return (<NewsArticleListLoader isAdminOrEditor={this.props.isAdminOrEditor} />);
    if (!this.props.records.length) return this._getEmptyView();

    return this.props.records.map((article, i) => (
      <NewsArticleListItem
        key={article.id}
        article={article}
        deleteArticle={this.props.deleteArticle}
        isAdminOrEditor={this.props.isAdminOrEditor}
        isFeaturedOnNewsPage={this.props.news_page_ids.includes(article.id)}
        redirectToArticleEdit={this.props.redirectToArticleEdit}
        redirectToArticleStats={this.props.redirectToArticleStats}
        unpublishArticle={this.props.unpublishArticle}
      />
    ));
  }

  _getPaginatorView() {
    if (!this.props.pagination.next_page && !this.props.pagination.prev_page) return null;

    return (
      <Paginator
        classList={{ root: layout.marginTop30 }}
        currentPage={this.props.pagination.current_page}
        currentQuery={this.props.currentQuery}
        disabled={this.props.isBusy || this.props.isNavigating}
        nextPage={this.props.pagination.next_page}
        onClick={(page) => this.props.propagateSearchQuery({ page })}
        prevPage={this.props.pagination.prev_page}
      />
    );
  }

  _handleOnSelection(option) {
    const filter
      = option.value === 'sponsored'
        ? { status: null, filter: option.value }
        : { status: option.value, filter: null };
    this.props.propagateSearchQuery({ page: null, ...filter });
  }

  render() {
    const queryMap = mapifyStringQuery(this.props.currentHistoryData.search);

    return (
      <div className={layout.container}>
        <div className={layout.wrapper1170}>
          {this._getLayoutBreadCrumb()}

          <div className={styles.header}>
            <h1 className={typography.h1}>Articles</h1>

            <div className={styles.actionsWrapper}>
              <BasicSearchInput
                classList={{ wrapper: styles.inputWrapper }}
                enableHistory={true}
                initVal={this._getSearchValueFromHistory(queryMap)}
                onEnter={(search) => this.props.propagateSearchQuery({ q: search })}
                placeholder="Search by article name"
              />

              <Button
                className={`${buttonStyles.inputPairedRight} ${layout.flex10Auto}`}
                colorStyle="outlineBlue"
                onClick={() => this.props.toggleCurrentView('analytics')}
                size="md"
              >
                <span className={styles.addBtnDefault}>View analytics</span>
                <Icon className={styles.addBtnPlus} name="platforms" size="16" />
              </Button>

              <Button
                className={`${buttonStyles.inputPairedRight} ${layout.flex10Auto}`}
                onClick={() => this.props.toggleCurrentView('form')}
                size="md"
              >
                <span className={styles.addBtnDefault}>New article</span>
                <Icon className={styles.addBtnPlus} name="plus" size="16" />
              </Button>
            </div>
          </div>

          <div>
            <HorizontalScrollBox classList={{ wrapper: utilStyles.borderBottom }}>
              <SimpleSelect
                onSelection={this._handleOnSelection}
                options={this._getStatusOptions(queryMap)}
                tabbedViewUnderline={false}
                view="tabbed"
              />
            </HorizontalScrollBox>
          </div>

          <div className={styles.listWrapper}>
            {this._getListView()}
            {this._getPaginatorView()}
          </div>
        </div>
      </div>
    );
  }
}

NewsArticlesList.propTypes = {
  adminNewsUrl: PropTypes.string.isRequired,
  currentHistoryData: PropTypes.object.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  isAdminOrEditor: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isNavigating: PropTypes.bool.isRequired,
  news_page_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  pagination: PropTypes.shape({
    current_page: PropTypes.number.isRequired,
    next_page: PropTypes.number,
    prev_page: PropTypes.number,
  }).isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    }),
    published_at: PropTypes.string,
    status: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Variable for Date.now and Rails formats.
    url: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  redirectToArticleEdit: PropTypes.func.isRequired,
  redirectToArticleStats: PropTypes.func.isRequired,
  toggleCurrentView: PropTypes.func.isRequired,
  unpublishArticle: PropTypes.func.isRequired,
};

export default NewsArticlesList;
