import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { selectTargetContents } from '../../../../../utility/events';

import styles from '../datetime.css';

const DateInputs = ({ _inputRefs, autoComplete, disabled, handleInputKeyDown, handleNumberBlur, handleNumberChange, values }) => (
  <Fragment>
    <input
      ref={_inputRefs[0]}
      autoComplete={autoComplete}
      className={styles.input}
      disabled={disabled}
      maxLength={2}
      minLength={2}
      name="month"
      onBlur={handleNumberBlur}
      onChange={handleNumberChange}
      onClick={selectTargetContents}
      onFocus={selectTargetContents}
      onKeyDown={handleInputKeyDown}
      placeholder="MM"
      value={values.month}
    />
    <span>/</span>
    <input
      ref={_inputRefs[1]}
      autoComplete={autoComplete}
      className={styles.input}
      disabled={disabled}
      maxLength={2}
      minLength={2}
      name="day"
      onBlur={handleNumberBlur}
      onChange={handleNumberChange}
      onClick={selectTargetContents}
      onFocus={selectTargetContents}
      onKeyDown={handleInputKeyDown}
      placeholder="DD"
      value={values.day}

    />
    <span>/</span>
    <input
      ref={_inputRefs[2]}
      autoComplete={autoComplete}
      className={styles.inputWide}
      disabled={disabled}
      maxLength={4}
      minLength={4}
      name="year"
      onBlur={handleNumberBlur}
      onChange={handleNumberChange}
      onClick={selectTargetContents}
      onFocus={selectTargetContents}
      onKeyDown={handleInputKeyDown}
      placeholder="YYYY"
      value={values.year}
    />
  </Fragment>
);

DateInputs.propTypes = {
  _inputRefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  handleInputKeyDown: PropTypes.func.isRequired,
  handleNumberBlur: PropTypes.func.isRequired,
  handleNumberChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    day: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  }).isRequired,
};

DateInputs.defaultProps = {
  autoComplete: null,
  disabled: false,
};

export default DateInputs;
