import { getNavigator } from '../services/window';

/**
 * Sources:
 * https://github.com/monperrus/crawler-user-agents/blob/master/crawler-user-agents.json
 * https://www.keycdn.com/blog/web-crawlers/
 */
const PATTERN = /facebook|google|yandex|bing|ezooms|twikle|wotbox|fetch|wordpress|pingdom|slurp|sogou|crawl|bot|spider/i;
let _isCrawlerFlag = null;

function testString(ua) {
  return PATTERN.test(ua);
}

export default function isCrawler() {
  if (_isCrawlerFlag === null) {
    const navigatorObj = getNavigator();

    if (navigatorObj && navigatorObj.userAgent) {
      _isCrawlerFlag = testString(navigatorObj.userAgent);
    } else {
      _isCrawlerFlag = true; // no navigator.userAgent so not a normal browser
    }
  }

  return _isCrawlerFlag;
}

// for testing
export function __resetFlag() {
  _isCrawlerFlag = null;
}
