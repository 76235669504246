import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../reusable_components/LazyImage';
import utilStyles from '../../../styles/global_ui/util.css';

import styles from './project_thumbs.css';

const ProjectThumb = ({ isViewed, position, src }) => (
  <div className={`${utilStyles.circle} ${utilStyles.border} ${styles.thumb}`}>
    {isViewed && !!src
    && (
      <LazyImage
        fit="crop"
        queryParams={{ crop: 'edges' }}
        ratio="1:1"
        src={src}
        width={30}
      />
    )}
    <div className={isViewed ? styles.numberViewed : styles.number}>
      {position.toString()}
    </div>
  </div>
);

ProjectThumb.propTypes = {
  isViewed: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  src: PropTypes.string,
};

ProjectThumb.defaultProps = { src: '' };

export default ProjectThumb;
