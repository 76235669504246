import {MEDIA_ID_REGEX} from './regex';


export function isEmbedlyUrlValid(url) {
  const match = url.match(MEDIA_ID_REGEX['embedly']);
  return match && match.length;
}

export function isGistUrlValid(url) {
  const match = url.match(MEDIA_ID_REGEX['gist']);
  return match && match.length;
}

export function getTwitterIdFromUrl(url) {
  return url.match(MEDIA_ID_REGEX['twitter'])[1].split('/')[2];
}

export function isTwitterUrlValid(url) {
  const match = url.match(MEDIA_ID_REGEX['twitter']);
  return match && match.length;
}