import BaseEvent from './BaseEvent';

class PageView extends BaseEvent {
  constructor(props) {
    super(props);
    this.entity_id = props.entity_id || null;
    this.referrer = props.referrer || document.referrer || '';
  }

  createRecord() {
    const record = super.createRecord();

    return {
      ...record,
      entity_id: this.entity_id,
      referrer: { url: this.referrer },
      keen: {
        ...record.keen,
        addons: record.keen.addons.concat({
          name: 'keen:referrer_parser',
          input: {
            page_url: 'page_url',
            referrer_url: 'referrer.url',
          },
          output: 'referrer.info',
        }),
      },
    };
  }
}

export default PageView;
