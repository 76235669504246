/**
 * https://github.com/airbnb/hypernova-react/blob/master/src/index.js
 * Copied over so we don't have to fork the package.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import hypernova, { serialize, load } from '@hacksterio/hypernova';

// Disables hydrate in test mode. This will let Rails tests ignore hypernova warnings.
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
/* eslint-disable-next-line no-prototype-builtins */
const IS_TEST_ENV = process.env.hasOwnProperty('RAILS_TEST') && process.env.RAILS_TEST === true;

export const renderReact = (name, component) => hypernova({
  server() {
    return (props) => {
      const contents = ReactDOMServer.renderToString(React.createElement(component, props));

      return serialize(name, contents, props);
    };
  },

  client() {
    const payloads = load(name);

    if (payloads) {
      payloads.forEach((payload) => {
        const { node, data } = payload;
        const element = React.createElement(component, data);

        if (ReactDOM.hydrate && !IS_TEST_ENV) {
          ReactDOM.hydrate(element, node);
        } else {
          ReactDOM.render(element, node);
        }
      });
    }

    return component;
  },
});
