import { filterObject } from '../../../utility/filters';

export const FLAGGED_ID_KEYS = ['flaggedCommentIds', 'flaggedPostIds'];

// Non-stateful
export const getFlaggedIds = (getter) => FLAGGED_ID_KEYS.reduce((acc, key) => ({ ...acc, [key]: getter(key) }), {});

// Stateful. bind to instance of to Discussion component's parent
export function setFlaggedIds(state = {}, callback) {
  this.setState(filterObject(state, null, FLAGGED_ID_KEYS), callback);
}
