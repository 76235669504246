import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../buttons/base';
import SelectableAddress from '../views/SelectableAddress';

import layout from '../../../styles/global_ui/layout.css';
import formStyles from '../../../styles/global_ui/forms.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './address_form.css';

const HAS_VERIFIED_TEXT = 'There seems to be a problem with the address provided. We\'ve made corrections to your address to ensure it is accurate. Please confirm or edit your address.';
const NO_VERIFIED_TEXT = 'We couldn\'t verify your address as a known deliverable address. Please review it before saving.';

class VerifyAddressView extends Component {
  constructor(props) {
    super(props);

    this.state = { selected: 0 };
  }

  /**
   * Helpers
   */
  _getAddressForSelectedIndex() {
    const address = this.state.selected === 0 ? this.props.verificationData.address : this._getAddressFromVerificationObject();
    const verified = this.state.selected === 1 && this.props.verificationData.verified.isDeliverable;

    return { ...address, verified };
  }

  _getAddressToPropagate(address, verified) {
    return { ...address, verified };
  }

  _getAddressFromVerificationObject() {
    const address = this.props.verificationData.verified.address;

    return Object.keys(address).reduce((acc, key) => {
      acc[key] = address[key].value;

      return acc;
    }, {});
  }

  /**
   * Views
   */
  _getHelperText() {
    const verified = this.props.verificationData.verified;
    // We have no data to recommend.
    if (!verified) return NO_VERIFIED_TEXT;
    // We have a recommendation to better the users address.
    if (verified.isDeliverable) return HAS_VERIFIED_TEXT;
    // Below on means the address inputted is not deliverable and we attempt to give the user hints why thats so.
    const msgs = verified.invalidMsgs;
    if (!msgs.length) return NO_VERIFIED_TEXT;

    return (
      <span>
        <span>{'We couldn\'t verify the address you provided as deliverable. Please review the items below for potential problems.'}</span>
        <span className={`${styles.footnoteMsgs} ${layout.marginTop15} ${typography.bodyL} ${typography.error}`}>
          {msgs.map((msg, i) => (
            <span key={i} className={styles.footnoteMsg}>
              •
              {' '}
              {msg}
            </span>
          ))}
        </span>
      </span>
    );
  }

  render() {
    const hasVerifiedData = this.props.verificationData.verified;

    return (
      <div className={formStyles.container}>
        <h2 className={`${typography.h2} ${layout.marginBottom10} ${typography.error}`}>Verify your address</h2>

        <p className={typography.bodyL}>{this._getHelperText()}</p>

        <div className={layout.flexRowWrap}>
          <SelectableAddress
            address={this.props.verificationData.address}
            addressView={hasVerifiedData ? 'verifiedDiff' : 'select'}
            diffingAddress={hasVerifiedData ? this.props.verificationData.verified.address : null}
            disabledEdit={this.props.isBusy}
            edit={(address) => this.props.editAddress(this._getAddressToPropagate(address, false))}
            isChecked={this.state.selected === 0}
            onChange={() => this.setState({ selected: 0 })}
            showDefaultView={false}
          />

          {hasVerifiedData && this.props.verificationData.verified.isDeliverable
          && (
            <SelectableAddress
              address={this._getAddressFromVerificationObject()}
              addressView="verified"
              disabledEdit={this.props.isBusy}
              edit={(address) => this.props.editAddress(this._getAddressToPropagate(address, this.props.verificationData.verified.isDeliverable))}
              isChecked={this.state.selected === 1}
              onChange={() => this.setState({ selected: 1 })}
              showDefaultView={false}
            />
          )}
        </div>

        <div className={layout.marginTop45}>
          <Button
            colorStyle="secondary"
            disabled={this.props.isBusy}
            onClick={() => this.props.saveAddress(this._getAddressForSelectedIndex())}
            size="md"
          >
            {this.props.isBusy ? 'Saving address' : 'Save address'}
          </Button>
        </div>
      </div>
    );
  }
}

VerifyAddressView.propTypes = {
  editAddress: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  saveAddress: PropTypes.func.isRequired,
  verificationData: PropTypes.shape({
    address: PropTypes.object.isRequired, // See AddressForm for shape.
    originIsVerified: PropTypes.bool.isRequired,
    verified: PropTypes.shape({
      address: PropTypes.object, // {key: {matchesInput: bool, value: string}} (smartyStreets/apiParser.getDiffingAddress)
      invalidMsgs: PropTypes.arrayOf(PropTypes.string),
      isDeliverable: PropTypes.bool,
    }),
  }).isRequired,
};

VerifyAddressView.defaultProps = { verificationData: { verified: null } };

export default VerifyAddressView;
