import buildFacets from '../../query_builders/buildFacetsFromQueryMap';
// Validation functions are to ignore options that need a specific sorting index on algolia.
const KNOWN_FACETS = {
  by: (property) => property !== 'toolbox',
  category_id: (property) => property !== 'featured',
  category_ids: (property) => property !== 'featured',
  content_type: () => true,
  difficulty: () => true,
  part_id: (property) => property !== 'me', // In my toolbox
  tag_id: (property) => property !== 'featured',
  topic_id: (property) => property !== 'featured',
};

const ALGOLIA_FACETS = {
  by: (property) => property === 'featured' ? 'featured:true' : `by:${property}`,
  category_id: (property) => getArrayOfFacets('platforms.category_ids', property),
  category_ids: (property) => getArrayOfFacets('platforms.category_ids', property),
  content_type: 'content_type',
  difficulty: 'difficulty',
  part_id: 'parts.id',
  tag_id: 'tag_ids',
  topic_id: 'topic_ids',
};

const getArrayOfFacets = (key, value) => {
  if (Array.isArray(value)) {
    return value.map((id) => `${key}:${id}`);
  } else {
    return [`${key}:${value}`];
  }
};

export default function buildFacetsFromQueryMap(queryMap) {
  return buildFacets(queryMap, KNOWN_FACETS, ALGOLIA_FACETS);
}
