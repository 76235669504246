import videoTagSupported from '../../utility/videoTagSupported';

const IMG = 'IMG';
const VIDEO = 'VIDEO';

function _buildOptions(queryParams = {}) {
  const keys = Object.keys(queryParams);

  return keys.length ? '&' + keys.map((key) => `${key}=${queryParams[key]}`).join('&') : '';
}

function _buildSrc({ format, fit, height, queryParams, src, width }) {
  const h = height ? `&h=${height}` : '';
  return `${src}?${_getDefaultParams(format, src)}&w=${width}${h}&fit=${fit}${_buildOptions(queryParams)}`;
}

function _buildSrcSet({ fit, height, queryParams, src, width }) {
  return [1, 2, 3].map((density) => (
    `${_buildSrc({ fit, height, queryParams: { ...queryParams, dpr: density }, src, width })} ${density}x`
  )).join(', ');
}

function _getDefaultParams(format, src = '') {
  const isGIF = src.includes('.gif');

  return (format === VIDEO || isGIF) ? 'gifq=35' : 'auto=compress%2Cformat';
}

function _getImageProps({ src, ratio, width, fit, queryParams, useSrcSet }) {
  const height = ratio ? getHeight(ratio, width) : null;

  return {
    format: IMG,
    height,
    src: _buildSrc({ fit, height, queryParams, src, width }),
    srcSet: useSrcSet ? _buildSrcSet({ fit, height, queryParams, src, width }) : null,
  };
}

function _getVideoProps({ src, ratio, width, fit, queryParams }) {
  const height = ratio ? getHeight(ratio, width) : null;

  return {
    format: VIDEO,
    height,
    src: _buildSrc({ fit, format: VIDEO, height, queryParams: { ...queryParams, fm: 'mp4' }, src, width }),
  };
}

function _removeQueryParamsFromSrc(src) {
  return src.split('?')[0];
}

export function canConvertToMP4(src) {
  return videoTagSupported() && (/\.gif/).test(src);
}

export function getHeight(ratio, width) {
  const { h, w } = parseRatio(ratio);

  return parseInt(width, 10) * (h / w);
}

export function parseRatio(ratio) {
  const [width, height] = ratio.split(':');

  return {
    h: parseInt(height, 10),
    w: parseInt(width, 10),
  };
}

export function processURL({
  fit = 'min',
  forceImg = false,
  queryParams = {},
  ratio,
  src = '',
  useSrcSet = false,
  width,
}) {
  if (!(/hackster?.*\.imgix\.net/).test(src)) {
    const height = ratio ? getHeight(ratio, width) : null;
    return {
      format: IMG,
      height,
      src,
      srcSet: null,
    };
  }

  const bareSrc = _removeQueryParamsFromSrc(src);

  if (canConvertToMP4(bareSrc) && !forceImg) {
    return _getVideoProps({ src: bareSrc, ratio, width, fit, queryParams });
  }

  return _getImageProps({ src: bareSrc, ratio, width, fit, queryParams, useSrcSet });
}

export { IMG, VIDEO };
