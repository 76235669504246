import React from 'react';

import DummyProjectCard from '../../../../client/cards/project_card/DummyCard';
import PartCard from '../../../../client/cards/part_card';
import ProjectCard from '../../../../client/cards/project_card';

import { dummyProjectProps, projectProps } from './shared';

import viewAllStyles from '../../../../client/wrappers/view_all/view_all.css';

const SECTION_KEY_TO_VIEW_METHOD = {
  category_id: '_viewAllList',
  featured: '_viewAllList',
  part_id: '_pinnedItemProjectsListView',
  sort: '_viewAllList',
  topic_id: '_viewAllList',
};

const pathPrefix = (rootPath) => rootPath === '/' ? rootPath : `${rootPath}/`;

/**
 * ATTN: This is a clone of platformConfig.
 * The reason for duplication is the links and query params need to be adjusted for the projects and product pages.
 */
export default ({ platform, rootPath } = {}) => ({
  _getViewMethodNameForSectionKey: (sectionKey) => SECTION_KEY_TO_VIEW_METHOD[sectionKey] || null,
  components: {
    category_id: (props) => React.createElement(ProjectCard, { project: props.record, ...projectProps }),
    part_id: (props) => React.createElement(PartCard, { part: { ...props.item, url: `${pathPrefix(rootPath)}products/${props.item.slug}` }, onClick: props.onClick, classList: { wrapper: viewAllStyles.scrollerItemProject } }),
    sort: (props) => React.createElement(ProjectCard, { project: props.record, ...projectProps }),
    topic_id: (props) => React.createElement(ProjectCard, { project: props.record, ...projectProps }),
  },
  links: {
    category_id: (section) => ({
      external: `${pathPrefix(rootPath)}projects?category_id=${section.value}`,
      internal: `${pathPrefix(rootPath)}projects?category_id=${section.value}`,
    }),
    part_id: (section, part) => ({
      external: `${pathPrefix(rootPath)}products/${part.slug}`,
      internal: `${pathPrefix(rootPath)}products/${part.slug}`,
    }),
    sort: (section) => ({
      external: `${pathPrefix(rootPath)}projects?sort=${section.value}`,
      internal: `${pathPrefix(rootPath)}projects?sort=${section.value}`,
    }),
    topic_id: (section) => ({
      external: `${pathPrefix(rootPath)}projects?topic_id=${section.value}`,
      internal: `${pathPrefix(rootPath)}projects?topic_id=${section.value}`,
    }),
  },
  listFacet: {
    category_id: (section) => [`platforms.id:${platform.id}`],
    sort: (section) => [`platforms.id:${platform.id}`],
    topic_id: (section) => [`platforms.id:${platform.id}`, `topic_ids:${section.value}`],
  },
  loaderComponent: {
    category_id: () => React.createElement(DummyProjectCard, dummyProjectProps),
    sort: () => React.createElement(DummyProjectCard, dummyProjectProps),
    topic_id: () => React.createElement(DummyProjectCard, dummyProjectProps),
  },
  pagination: {
    category_id: () => ({
      maxCardCount: 4,
      type: 'page',
    }),
    part_id: () => ({
      maxCardCount: 4,
      type: 'offset',
      length: 4,
    }),
    sort: () => ({
      maxCardCount: 4,
      type: 'page',
    }),
    topic_id: () => ({
      maxCardCount: 4,
      type: 'page',
    }),
  },
  pinnedItemFacet: { part_id: (section) => [`id:${section.value}`] },
  projectsFacet: { part_id: (section) => [`platforms.id:${platform.id}`, `parts_for_channels:${section.value}`] },
  queryMap: {
    category_id: (section) => ({
      category_id: section.value,
      hitsPerPage: 4,
      sort: 'trending',
      qlVars: section.value === 'featured' ? { base_channel_id: platform.id, featured: true } : { base_channel_id: platform.id, channel_category_id: section.value },
    }),
    part_id: (section) => ({
      hitsPerPage: 3,
      sort: 'trending',
      qlVars: { base_channel_id: platform.id, part_id: section.value },
    }),
    sort: (section) => ({
      hitsPerPage: 4,
      sort: section.value,
      qlVars: { base_channel_id: platform.id },
    }),
    topic_id: (section) => ({
      topic_ids: section.value, // ATTN: Does this need to be set here?
      hitsPerPage: 4,
      sort: 'trending',
      qlVars: section.value === 'featured' ? { base_channel_id: platform.id, featured: true } : { base_channel_id: platform.id, topic_id: section.value },
    }),
  },
  service: {
    category_id: () => 'projectsService',
    part_id: () => 'partsService',
    sort: () => 'projectsService',
    topic_id: () => 'projectsService',
  },
  serviceOverride: { // Temp bucket.
    category_id: () => 'graphQlProjectsService',
    featured: () => 'graphQlProjectsService',
    sort: () => 'graphQlProjectsService',
    topic_id: () => 'graphQlProjectsService',
  },
  pinnedServiceOverride: { // Temp bucket, make sure to add the qlVars prop to queryMap above.
    part_id: () => 'graphQlProjectsService',
  },
});
