import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import {fromJS} from 'immutable';

import EmbedToolbar from '../EmbedToolbar';
import Dialog from '../Dialog';
import CarouselEditor from '../CarouselEditor';
import Figcaption from '../Figcaption';
import Spinner from '../Spinner';

import processImageFiles from '../../utils/processImageFiles';
import {isImageValid} from '../../utils/Helpers';

import styles from './carousel.css';
import buttonStyles from '../../styles/buttons.css';
import slideUpTransitions from '../../styles/slideUpTransitions.css';

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      isImageUploading: false,
      openDialog: false,
      toolbarData: { height: 0 }
    };

    this.handleCaptionKeyDown = this.handleCaptionKeyDown.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.pushImagesToCarousel  = this.pushImagesToCarousel.bind(this);
    this.removeImageFromCarousel = this.removeImageFromCarousel.bind(this);
    this.reorderImages = this.reorderImages.bind(this);

    // Refs
    this.carouselEditorRef;
  }

  componentDidMount() {
    const entity = this.props.block.getEntityAt(0);
    const pendingImagesToProcess = this.props.contentState.getEntity(entity).getData().images.filter(image => image.get('pending'));

    if (pendingImagesToProcess.size) {
      pendingImagesToProcess.toJS().forEach(image => this.props.blockProps.processImage(image, entity, this.props.block.getKey()));
      this.setState({isImageUploading: true});
    }
  }

  UNSAFE_componentWillUpdate(_nextProps, nextState) {
    if (nextState.openDialog !== this.state.openDialog) {
      this.props.blockProps.actions.toggleDialogStatus(nextState.openDialog);
    }
  }

  componentDidUpdate(prevProps) {
    const entity = this.props.block.getEntityAt(0);

    if (entity && this.props.contentState.getEntity(entity).getData().images !== prevProps.contentState.getEntity(prevProps.block.getEntityAt(0)).getData().images) {
      const images = this.props.contentState.getEntity(entity).getData().images.filter(image => image.get('pending'));

      if (images.size) {
        images.toJS().forEach(image => this.props.blockProps.processImage(image, entity, this.props.block.getKey()));
        this.setState({ isImageUploading: true });
      } else if (!images.size && this.state.isImageUploading) {
        // After the last pending image is cleared, falsify the flag.
        this.setState({ isImageUploading: false });
      }
    }
  }

  handleCaptionKeyDown(e) {
    switch(e.keyCode) {
      case 38: // UP ARROW
        this._moveSelectionTo(e, 'previous');
        break;
      case 40: // DOWN ARROW
      case 13: // ENTER
      case 9:  // TAB
        this._moveSelectionTo(e, 'next');
        break;
      default:
        break;
    }
  }

  handleMouseOver(e) {
    // Ignore figcaption.
    if (e.target.classList.contains('react-editor-image') || e.target.classList.contains('react-editor-image-wrapper')) {
      const height = ReactDOM.findDOMNode(this).getBoundingClientRect().height;
      this.setState({hovered: true, toolbarData: {height}});
    }
  }

  handleNavigation(direction, e) {
    e.preventDefault();

    const {block, contentState} = this.props;
    const images = contentState.getEntity(block.getEntityAt(0)).getData().images;
    const activeIndex = images.findIndex(image => image.get('show'));
    const updatedImages = images.setIn([activeIndex, 'show'], false);

    if (direction === 'left') {
      const navigatedImages = activeIndex === 0
        ? updatedImages.setIn([updatedImages.size-1, 'show'], true)
        : updatedImages.setIn([activeIndex-1, 'show'], true);

      this.props.blockProps.updateEntityData(block.getEntityAt(0), {images: navigatedImages});
    } else {
      const navigatedImages = activeIndex === updatedImages.size-1
        ? updatedImages.setIn([0, 'show'], true)
        : updatedImages.setIn([activeIndex+1, 'show'], true);

      this.props.blockProps.updateEntityData(block.getEntityAt(0), {images: navigatedImages});
    }

    this.setState({ hovered: false });
  }

  pushImagesToCarousel(e) {
    const files = e.target.files;
    const filteredFiles = [].slice.call(files).filter(file => isImageValid(file.type));

    if (!filteredFiles.length) return;

    return processImageFiles(filteredFiles)
      .then(newImages => {
        newImages[0].show = true;

        const { block, contentState } = this.props;
        const images = this.props.contentState.getEntity(block.getEntityAt(0)).getData().images;
        const activeIndex = images.findIndex(image => image.get('show'));
        const updatedImages = images.setIn([activeIndex, 'show'], false);
        const mergedImages = updatedImages.concat(fromJS(newImages));

        this.props.blockProps.updateContentState(contentState.replaceEntityData(block.getEntityAt(0), { images: mergedImages }));
        this.props.blockProps.uploadImages(newImages, block.getEntityAt(0), block.getKey());
        this.setState({ hovered: false });
      })
      .catch(err => console.error('Image Upload Error: ', err));
  }

  removeImageFromCarousel() {
    const { block, contentState } = this.props;
    const images = this.props.contentState.getEntity(block.getEntityAt(0)).getData().images;
    const activeIndex = images.findIndex(image => image.get('show'));
    const filteredImages = images.filter((image, idx) => idx !== activeIndex);

    if (filteredImages.size) {
      const updatedImages = activeIndex > filteredImages.size-1
        ? filteredImages.setIn([0, 'show'], true)
        : filteredImages.setIn([activeIndex, 'show'], true);

      this.props.blockProps.updateContentState(contentState.replaceEntityData(block.getEntityAt(0), { images: updatedImages }));
      this.props.blockProps.setUnsavedChanges();
      this.setState({ hovered: false });
    } else {
      this.props.blockProps.remove(block);
    }
  }

  _moveSelectionTo(e, direction) {
    e.preventDefault();
    this.props.blockProps.moveSelectionTo(this.props.block, direction);
  }

  reorderImages(e) {
    e.preventDefault();
    e.stopPropagation();

    const {block} = this.props;
    this.props.blockProps.updateEntityData(block.getEntityAt(0), {images: this.carouselEditorRef.getImages()});
    this.setState({ openDialog: false });
  }

  _getImages() {
    const entityKey = this.props.block.getEntityAt(0);
    return entityKey ? this.props.contentState.getEntity(entityKey).getData().images : [];
  }

  _getImageUniqueId(image, index) {
    return image.has('id') ? image.get('id') : image.has('uuid') ? image.get('uuid') : index;
  }

  _handleFigcaptionChange(image, value) {
    const {block, blockProps, contentState} = this.props;
    const images = this.props.contentState.getEntity(block.getEntityAt(0)).getData().images;
    const newImages = images.map(img => this._getMatchingEntity(img, image) ? img.set('figcaption', value) : img);

    blockProps.updateContentState(contentState.replaceEntityData(block.getEntityAt(0), { images: newImages }));
    blockProps.setUnsavedChanges();
  }

  _getMatchingEntity(a, b) {
    if (a.has('id') && b.has('id')) {
      return a.get('id') === b.get('id')
    } else if (a.has('uuid') && b.has('uuid')) {
      return a.get('uuid') === b.get('uuid');
    } else {
      return a.get('show') === true;
    }
  }

  _getDialogActions() {
    return [
      <button
        className={`${buttonStyles.btn} ${buttonStyles.btnLink} draftster-btn-secondary`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.setState({openDialog: false});
        }}
        type="button"
        >
        Cancel
      </button>,
      <button
        className={`${buttonStyles.btn} ${buttonStyles.btnPrimary} draftster-btn-primary`}
        disabled={this.state.isImageUploading}
        onClick={this.reorderImages}
        type="button"
        >
        {this.state.isImageUploading ? 'Working...' : 'Reorder'}
      </button>
    ];
  }

  render() {
    const images = this._getImages();

    return (
      <div
        className={`${styles.root} react-editor-carousel`}
        onMouseOver={this.handleMouseOver}
        >
        <div className={`${styles.images} react-editor-carousel-inner`}>
          {images.map((image, index) => {
            return (
              <figure
                key={this._getImageUniqueId(image, index)}
                className={`${styles.figure} react-editor-figure ${image.get('show') ? styles.show : ''}`}
                data-type="image"
                >
                <div className={`${styles.imageWrapper} react-editor-image-wrapper`}>
                  <div className={styles.loaderWrapper}>
                    {!image.has('id') &&
                      <div className={styles.loader}>
                        <Spinner />
                        <p>Uploading image</p>
                      </div>
                    }
                    <img className={`${styles.image} react-editor-image`} src={image.get('url')} alt={image.get('name')} />
                  </div>
                  <Figcaption
                    className={`${styles.figcaption} react-editor-figcaption`}
                    onBlur={() => this.props.blockProps.editing(false)}
                    onFocus={() => this.props.blockProps.editing(true)}
                    onKeyDown={this.handleCaptionKeyDown}
                    onValueChange={value => this._handleFigcaptionChange(image, value)}
                    placeholder="caption (optional)"
                    value={image.get('figcaption') || ''}
                  />
                </div>
              </figure>
            );
          })}
        </div>
        {images.size > 1 &&
          <div className="reit-controls">
            <button
              className={`${styles.controlBtn} ${styles.left} reit-controls-button left fa fa-chevron-left fa-2x`}
              onClick={this.handleNavigation.bind(this, 'left')} />
            <button
              className={`${styles.controlBtn} ${styles.right} reit-controls-button right fa fa-chevron-right fa-2x`}
              onClick={this.handleNavigation.bind(this, 'right')} />
          </div>
        }
        {this.state.hovered &&
          <EmbedToolbar
            data={this.state.toolbarData}
            disableButtonsByType={images.size <= 1 ? ['reorder'] : []}
            embedType="carousel"
            openImageEditor={() => this.setState({ openDialog: true })}
            parent={this}
            remove={this.removeImageFromCarousel}
            unmountToolbar={() => this.setState({ hovered: false, toolbarData: {height: 0} })}
            upload={this.pushImagesToCarousel}
          />
        }
        <CSSTransition
          classNames={slideUpTransitions}
          in={this.state.openDialog}
          timeout={450}
          >
          <div>
            {this.state.openDialog &&
              <Dialog
                actions={this._getDialogActions()}
                className={`${styles.reorderDialog} draftster-carousel-reorder-dialog`}
                dismiss={() => this.setState({openDialog: false})}
                enableCloseButton={false}
                open={this.state.openDialog}
                title="Reorder Images"
                wrapperClassName="dialog-body"
                >
                <CarouselEditor ref={(el) => this.carouselEditorRef = el} images={images}/>
              </Dialog>
            }
          </div>
        </CSSTransition>
      </div>
    );
  }
}

Carousel.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: PropTypes.shape({
    actions: PropTypes.object.isRequired,
    editing: PropTypes.func.isRequired,
    message: PropTypes.func.isRequired,
    moveSelectionTo: PropTypes.func.isRequired,
    processImage: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    setUnsavedChanges: PropTypes.func.isRequired,
    updateContentState: PropTypes.func.isRequired,
    uploadImages: PropTypes.func.isRequired
  }).isRequired
};

export default Carousel;