import { EditorState, Modifier } from 'draft-js';
import { getWordBeforeCursor } from '../draftHelpers.js';
import { appendProtocol } from '../../../../../utility/links';

export function linkifyPreviousWord(editorState) {
  const selectionState = editorState.getSelection();
  const previousWord = getWordBeforeCursor(editorState);
  const linkWithProtocol = appendProtocol(previousWord);

  const manualSelection = selectionState.merge({
    anchorOffset: selectionState.getAnchorOffset() - previousWord.length,
    focusOffset: selectionState.getAnchorOffset(),
  });

  const contentStateWithEntity = editorState.getCurrentContent().createEntity(
    'LINK',
    'MUTABLE',
    { href: appendProtocol(previousWord) },
  );

  const contentStateWithLink = Modifier.applyEntity(
    editorState.getCurrentContent(),
    manualSelection,
    contentStateWithEntity.getLastCreatedEntityKey(),
  );

  return {
    link: linkWithProtocol,
    updatedEditorState: EditorState.forceSelection(
      EditorState.set(editorState, { currentContent: contentStateWithLink }),
      selectionState,
    ),
  };
}
