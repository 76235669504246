import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';

import BasicSearchInput from '../../../client/form_components/inputs/basic_search_input';
import Breadcrumb from '../../../client/nav_components/breadcrumb';
import Icon from '../../../client/icon';
import SimpleSelect from '../../../client/form_components/simple_select';
import TabbedHeader from '../../../client/nav_components/tabbed_header';
import MembersTableView from './MembersTableView';
import Prompt from '../../../client/reusable_components/Dialog/Prompt';

import currentUserService from '../../../services/current_user';

import errorHandler from '../../../services/error_handler';
import { graphMutate, graphQueryWithUser } from '../../../requests/graphql';

import { getInObj } from '../../../utility/accessors';
import { isBlank } from '../../../utility/types';
import { summonGlobalMessenger } from '../../../utility/dispatchers';

import { MANAGER_ROLES, channelMemberEnumsFromRolesObj, channelMemberRoleToEnum } from '../../../graphql/channel_members/enums';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './members.css';

const EMPTY_PROMPT_STATE = { action: 'Delete', body: '', okFn: () => {}, open: false, title: 'Delete member' };
const MEMBER_ROLE_MENU_MAP = {
  admin: 'Make admin',
  member: 'Make member',
  moderator: 'Make moderator',
};
const NAV_OPTS = [
  { href: '#managers', label: 'Managers' },
  { href: '#searchMembers', label: 'Search members' },
  {
    external: true, label: (
      <span className={`${layout.flexCenterItems}`}>
        Invite members
        {' '}
        <Icon className={layout.marginLeft5} name="external-link" />
      </span>
    ),
  },
];
const SEARCH_OPTS_STATE = { filter: 'all', hits: null, text: null };

class ChannelManageMembers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: 0,
      currentUser: { id: 0, isAdmin: false },
      isBusy: false,
      isFetching: false,
      managers: props.managers, // Used to keep track of active admins
      members: props.managers,
      prompt: EMPTY_PROMPT_STATE,
      searchOpts: SEARCH_OPTS_STATE,
      workers: [],
    };

    this.onDeleteMember = this.onDeleteMember.bind(this);
    this.onNavClick = this.onNavClick.bind(this);
    this.onDeletePromptApproval = this.onDeletePromptApproval.bind(this);
    this.onSearchFilterSelect = this.onSearchFilterSelect.bind(this);
    this.onSearchTextInput = this.onSearchTextInput.bind(this);
    this.onUpdateMemberRole = this.onUpdateMemberRole.bind(this);

    // Configs
    this._roleFilters = this._initRoleFilters(props);
    this._roleMenu = this._initRoleMenu(props);
  }

  /**
   * Initializers
   */
  _initCurrentUser() {
    return currentUserService.getStoreAsync()
      .then((currentUser) => this.setState({ currentUser }))
      .catch((err) => errorHandler('ChannelManageMembers _initCurrentUser', err));
  }

  _initRoleFilters(props) {
    return [{ label: 'All roles', value: 'all' }].concat(Object.keys(props.channel.roles).map((roleKey) => ({
      label: props.channel.roles[roleKey],
      value: roleKey,
    })));
  }

  _initRoleMenu(props) {
    return Object.keys(props.channel.roles).map((role) => ({
      label: getInObj([role], MEMBER_ROLE_MENU_MAP),
      value: role,
    }));
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._initCurrentUser();
  }

  /**
   * Methods
   */
  onDeleteMember(member) {
    this.setState({
      prompt: {
        ...EMPTY_PROMPT_STATE,
        body: `Are you sure you want to delete ${(getInObj(['user', 'name'], member) || member.user.email)} as a member?`,
        okFn: () => this.onDeletePromptApproval(member),
        open: true,
      },
    });
  }

  onNavClick(index, tab) {
    if (tab.external || index === this.state.activeTabIndex) return;

    if (index === 0) {
      this.setState({ activeTabIndex: index, isFetching: true, members: [], searchOpts: SEARCH_OPTS_STATE });
      this._getMembers({ roles: MANAGER_ROLES });
    } else {
      this.setState({ activeTabIndex: index, members: [], searchOpts: SEARCH_OPTS_STATE });
    }
  }

  onDeletePromptApproval(member) {
    if (member.role === 'admin' && this._isLastAdminOfChannel()) {
      return summonGlobalMessenger({ msg: 'Sorry, at least one admin must remain.', type: 'error' });
    }

    this.setState({
      isBusy: true,
      workers: this._setWorker('delete', member),
    });

    return this._deleteChannelMember(member);
  }

  onSearchFilterSelect(opt) {
    this.setState((state) => ({ searchOpts: { ...state.searchOpts, filter: opt.value } }), () => {
      if (!isBlank(this.state.searchOpts.text) && this.state.searchOpts.text.length >= 3) {
        this.onSearchTextInput(this.state.searchOpts.text, true);
      }
    });
  }

  // NOTE: The second arg is to trigger another request when the role filter is updated.
  // The flag is needed becuase of the check in _shouldIgnoreSearch to prevent making duplicated requests when the text has not changed.
  onSearchTextInput(text, overrideFromFilter = false) {
    if (overrideFromFilter === false && this._shouldIgnoreSearch(text)) return;
    if (isBlank(text) && !isBlank(this.state.searchOpts.text)) return this.setState({ members: [], searchOpts: SEARCH_OPTS_STATE }); // Input was cleared

    // Trim the text in case theres space before and/or after any text. All blank searches will be caught above.
    const trimmed = text.trim();
    if (trimmed.length < 3) return summonGlobalMessenger({ msg: 'Please enter at least 3 characters to search.', type: 'error' });

    this.setState({
      isFetching: true,
      searchOpts: { ...this.state.searchOpts, text: trimmed },
    });

    const userNameOrEmail = isEmail(trimmed) ? { email: trimmed } : { user_name: trimmed };

    return this._getMembers({ roles: this._getRolesForSearchFilter(), sort: null, ...userNameOrEmail });
  }

  onUpdateMemberRole(member, role) {
    if (member.role === 'admin' && this._isLastAdminOfChannel()) {
      return summonGlobalMessenger({ msg: 'Sorry, at least one admin must remain.', type: 'error' });
    }

    this.setState({
      isBusy: true,
      workers: this._setWorker('update', member),
    });

    return this._updateMemberRole(member, role);
  }

  /**
   * Requests
   */
  _deleteChannelMember(member) {
    return graphMutate({ t: 'delete_channel_member_by_admin' }, { channel_id: this.props.channel.id, member_id: member.id })
      .then((res) => {
        this.setState((state) => ({
          isBusy: false,
          managers: state.managers.filter((m) => m.id !== member.id),
          members: state.members.filter((m) => m.id !== member.id),
          prompt: EMPTY_PROMPT_STATE,
          workers: this._removeWorker(member),
        }), () => summonGlobalMessenger(this._getToastMessageForDelete(true, member)));
      })
      .catch((err) => {
        errorHandler('ChannelManageMembers _deleteChannelMember', err);
        this.setState({
          isBusy: false,
          prompt: EMPTY_PROMPT_STATE,
          workers: this._removeWorker(member),
        }, () => summonGlobalMessenger(this._getToastMessageForDelete(false, member)));
      });
  }

  _getMembers(vars = {}) {
    return graphQueryWithUser({ t: 'get_channel_members_admin' }, { channel_id: this.props.channel.id, ...vars })
      .then(({ members }) => {
        this.setState((state) => ({
          isBusy: false,
          isFetching: false,
          members: members,
          searchOpts: { ...state.searchOpts, hits: members.length },
        }));
      })
      .catch((err) => {
        errorHandler('ChannelManageMembers _getMembers', err);
        this.setState({
          isBusy: false,
          isFetching: false,
        });
      });
  }

  _updateMemberRole(member, role) {
    return graphMutate({ t: 'update_channel_member_by_admin' }, { channel_id: this.props.channel.id, member_id: member.id, role: channelMemberRoleToEnum(role) })
      .then((res) => {
        const updatedMember = { ...member, role };
        this.setState((state) => ({
          isBusy: false,
          managers: this._processManagersOnUpdate(state.managers, updatedMember),
          members: state.members.map((m) => m.id === member.id ? updatedMember : m),
          workers: this._removeWorker(member),
        }), () => summonGlobalMessenger(this._getToastMessageForRoleUpdate(true, updatedMember)));
      })
      .catch((err) => {
        errorHandler('ChannelManageMembers _updateMemberRole', err);
        this.setState({
          isBusy: false,
          workers: this._removeWorker(member),
        }, () => summonGlobalMessenger(this._getToastMessageForRoleUpdate(false, member)));
      });
  }

  /**
   * Helpers
   */
  _getCurrentView() {
    switch (this.state.activeTabIndex) {
      case 0:
        return this._getDefaultView();
      case 1:
        return this._getSearchMembersView();
      default:
        return null;
    }
  }

  _getMemberUserNameOrEmail(member) {
    return getInObj(['user', 'name'], member) || member.user.email;
  }

  _getNavOpts() {
    return NAV_OPTS.map((opt, i) => i === 2 ? { ...opt, href: this.props.channel.invite_url } : opt);
  }

  _getRolesForSearchFilter() {
    return this.state.searchOpts.filter === 'all' ? channelMemberEnumsFromRolesObj(this.props.channel.roles) : [channelMemberRoleToEnum(this.state.searchOpts.filter)];
  }

  _getToastMessageForRoleUpdate(isSuccessful, member) {
    return {
      msg: isSuccessful
        ? `Successfully made ${this._getMemberUserNameOrEmail(member)} a ${member.role}.`
        : `Sorry, we had an issue updating ${this._getMemberUserNameOrEmail(member)}.`,
      type: isSuccessful ? 'success' : 'error',
    };
  }

  _getToastMessageForDelete(isSuccessful, member) {
    return {
      msg: isSuccessful
        ? `Successfully deleted ${this._getMemberUserNameOrEmail(member)}.`
        : `Sorry, we had an issue deleting ${this._getMemberUserNameOrEmail(member)}.`,
      type: isSuccessful ? 'success' : 'error',
    };
  }

  _isLastAdminOfChannel() {
    return this.state.managers.filter((member) => member.role === 'admin').length <= 1;
  }

  _processManagersOnUpdate(managers, updatedMember) {
    return updatedMember.role === 'member'
      ? managers.filter((manager) => manager.id !== updatedMember.id)
      : managers.concat(updatedMember);
  }

  _removeWorker(member) {
    return this.state.workers.filter((worker) => getInObj(['member', 'id'], worker) !== member.id);
  }

  _setWorker(action, member) {
    return this.state.workers.concat({ action, member });
  }

  _shouldIgnoreSearch(text) {
    return (isBlank(text) && isBlank(this.state.searchOpts.text)) || (text === this.state.searchOpts.text);
  }

  /**
   * Views
   */

  _getDefaultView() {
    return (
      <MembersTableView
        currentUser={this.state.currentUser}
        emptyListMsg="No managers found"
        isFetching={this.state.isFetching}
        members={this.state.members}
        onDeleteMember={this.onDeleteMember}
        onUpdateMemberRole={this.onUpdateMemberRole}
        roleMenu={this._roleMenu}
        workers={this.state.workers}
      />
    );
  }

  _getSearchMembersView() {
    return (
      <div>
        <div className={`${layout.flex} ${layout.marginBottom30} ${styles.searchInputWrapper}`}>
          <BasicSearchInput
            classList={{ wrapper: layout.marginRight10 }}
            enableX={true}
            onEnter={this.onSearchTextInput}
            placeholder="Search by name or email (press Enter)"
          />
          <span className={styles.roleFilterWrapper}>
            <SimpleSelect onSelection={this.onSearchFilterSelect} options={this._roleFilters} />
          </span>
        </div>

        <div>
          <MembersTableView
            currentUser={this.state.currentUser}
            isFetching={this.state.isFetching}
            members={this.state.members}
            onDeleteMember={this.onDeleteMember}
            onUpdateMemberRole={this.onUpdateMemberRole}
            roleMenu={this._roleMenu}
            searchHits={this.state.searchOpts.hits}
            workers={this.state.workers}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={layout.container}>
        <div className={`${layout.flexColumn} ${layout.paddingTop30} ${layout.wrapper960}`}>
          <div>
            <div>
              <Breadcrumb
                color="Blue"
                href={this.props.channel.url}
                text={`Back to ${this.props.channel.name}'s homepage`}
              />
            </div>

            <h1 className={`${typography.h1} ${layout.flexJustifyCenter} ${layout.paddingBottom30}`}>Manage members</h1>
          </div>

          <div className={`${layout.dashboardPanel960} ${styles.dashboardPanel}`}>
            <div className={layout.marginBottom30}>
              <TabbedHeader
                activeIndex={this.state.activeTabIndex}
                centered={false}
                onClick={this.onNavClick}
                tabs={this._getNavOpts()}
              />
            </div>

            {this._getCurrentView()}
          </div>

          <Prompt
            action="Delete"
            actionColor="danger"
            body={this.state.prompt.body}
            dismiss={() => this.setState({ prompt: EMPTY_PROMPT_STATE })}
            okay={this.state.prompt.okFn}
            open={this.state.prompt.open}
            title={this.state.prompt.title}
          />
        </div>
      </div>
    );
  }
}

ChannelManageMembers.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    enable_moderators: PropTypes.bool.isRequired,
    invite_url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    roles: PropTypes.shape({
      admin: PropTypes.string.isRequired,
      member: PropTypes.string.isRequired,
      moderator: PropTypes.string,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  managers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    invitation_pending: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      url: PropTypes.string,
      user_name: PropTypes.string, // Might not exist while member is invited
    }).isRequired,
  })),
};

ChannelManageMembers.defaultProps = { managers: [] };

export default ChannelManageMembers;
