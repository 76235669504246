import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isURL from 'validator/lib/isURL';

import BasicFormInput from '../../../../client/form_components/inputs/basic_form_input';
import FormImageUploader from '../../../../client/form_components/image_uploaders/form_image_uploader';
import smoothScroll from '../../../../client/utils/smoothScroll';

import formStyles from '../../../../styles/global_ui/forms.css';

class CTAForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      ...this._initState(props),
    };

    this.getFormData = this.getFormData.bind(this);
    this.validate = this.validate.bind(this);
  }

  _initState(props) {
    if (!props.initData) {
      return {
        image: null, // {id, name, url}
        link: '',
        name: '',
      };
    }

    return props.initData;
  }

  /**
   * Callbacks
   */
  getFormData() {
    return {
      image: this.state.image,
      link: this.state.link,
      name: this.state.name,
    };
  }

  validate() {
    const map = { image: this._imageV(), name: this._nameV(), link: this._linkV() };
    const errors = Object.keys(map).reduce((acc, key) => {
      if (map[key] && typeof map[key] === 'string') {
        acc[key] = map[key];
      }

      return acc;
    }, {});

    if (Object.keys(errors).length > 0) {
      this.setState({ errors }, () => this._scrollToError(errors));

      return false;
    }

    return true;
  }

  _scrollToError(errors) {
    const el = ['name', 'image', 'link'].reduce((acc, key) => {
      if (acc !== null) return acc;
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
      /* eslint-disable-next-line no-prototype-builtins */
      return errors.hasOwnProperty(key) ? document.getElementById(`cta${key}`) : acc;
    }, null);

    if (el) {
      smoothScroll(el);
    }
  }

  _imageV() {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return ['id', 'name', 'url'].every((key) => this.state.image && this.state.image.hasOwnProperty(key))
      ? null
      : 'An image is required';
  }

  _nameV() {
    return this.state.name && this.state.name.length > 2 ? null : 'A name is required. 3 characters or more';
  }

  _linkV() {
    return this.state.link && this.state.link.length > 0 && isURL(this.state.link, { require_protocol: true })
      ? null
      : 'A link is required. Make sure to add a correct protocol (http:// or https://)';
  }

  _removeErrorForType(type) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [type]: deleted, ...errors } = this.state.errors;

    return errors;
  }

  _setErrorForType(type, msg) {
    this.setState({
      errors: {
        ...this.state.errors,
        [type]: msg,
      },
    });
  }

  render() {
    return (
      <form className={formStyles.container}>
        <div id="ctaname">
          <BasicFormInput
            errors={this.state.errors.name}
            helperText="This is used for admin purposes"
            label="Name"
            name="name"
            onChange={(e) => this.setState({ name: e.target.value })}
            value={this.state.name}
          />
        </div>

        <div id="ctaimage">
          <FormImageUploader
            aspectRatio={(4 / 1)}
            dimensionMins={{ width: 1170 }}
            errors={this.state.errors.image}
            helperText="This image will display in mobile cards and sections where the image is a square. This image should have a ratio of 4:1 and be 3510x877.5px (minimum 1170x292.5px) for the best quality."
            imageData={this.state.image}
            label="Add image"
            propagateStatus={this.props.propagateStatus}
            propagateUpload={(image) => this.setState({ image, errors: this._removeErrorForType('image') })}
            reportError={(msg) => this._setErrorForType('image', msg)}
          />
        </div>

        <div id="ctalink">
          <BasicFormInput
            errors={this.state.errors.link}
            label="Action link"
            name="link"
            onChange={(e) => this.setState({ link: e.target.value })}
            value={this.state.link}
          />
        </div>
      </form>
    );
  }
}

CTAForm.propTypes = {
  initData: PropTypes.shape({
    image: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    link: PropTypes.string,
    name: PropTypes.string,
  }),
  propagateStatus: PropTypes.func,
};

CTAForm.defaultProps = {
  initData: null,
  propagateStatus: () => {},
};

export default CTAForm;
