/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import request from 'superagent';
import OAuthService from '../services/oauth';

/* Queries */
export function graphQuery(q, variables = null, requireUser = false, returnResponseBody = true, showSignInDialog = true) {
  return new Promise((resolve, reject) => {
    const data = typeof q === 'object' ? { t: q.t, variables } : { q, variables };

    return OAuthService.apiRequest(
      request
        .post(`${OAuthService.getApiPath()}/graphql/query`)
        .type('form')
        .send({ json_data: JSON.stringify(data) }),
      requireUser,
      returnResponseBody,
      showSignInDialog,
    )
      .then((res) => {
        if (res.errors) return reject(res);

        return resolve(res && res.hasOwnProperty('data') ? res.data : (res || {}));
      })
      .catch((err) => reject(err));
  });
}

export function graphQueryWithUser(q, variables = {}) {
  return graphQuery(q, variables, true);
}

export function graphQueryWithUserNoSigninDialog(q, variables = {}) {
  return graphQuery(q, variables, true, true, false);
}

/* Mutations */
export function graphMutate(q, variables = {}, requireUser = true) {
  return new Promise((resolve, reject) => {
    const data = typeof q === 'object' ? { t: q.t, variables } : { q, variables };

    return OAuthService.apiRequest(
      request
        .post(`${OAuthService.getApiPath()}/graphql/mutate`)
        .type('form')
        .send({ json_data: JSON.stringify(data) }),
      requireUser,
    )
      .then((res) => {
        if (res.errors) return reject(res);

        return resolve(res && res.hasOwnProperty('data') ? res.data : (res || {}));
      })
      .catch((err) => reject(err));
  });
}

export function graphMutateWithoutUser(q, variables) {
  return graphMutate(q, variables, false);
}
