import React from 'react';

/**
 * Like Array.prototype.join, except it returns a renderable list of renderable elements
 * @param  {Array} arr              Array of renderable elements to "join"
 * @param  {PropTypes.node} divider Renderable element to interleave between elements of arr
 * @return {Arrary}                 Elements in arr with divider intersperced
 */
const joinComponents = (arr = [], divider = null) => (
  React.Children.toArray(arr.reduce((acc, node, i) => (
    (i === arr.length - 1) ? acc.concat([node]) : acc.concat([node, divider])
  ), []))
);

export default joinComponents;
