const EVENT_FIND_BY_TYPES = {
  conference: 'CONFERENCE',
  hackathon: 'HACKATHON',
  meetup: 'MEETUP',
  webinar: 'WEBINAR',
  workshop: 'WORKSHOP',
};

const EVENT_TYPES = {
  community: 'COMMUNITY', // deprecated
  conference: 'CONFERENCE',
  hackathon: 'HACKATHON',
  live: 'LIVE', // deprecated
  meetup: 'MEETUP',
  virtual: 'VIRTUAL', // deprecated
  webinar: 'WEBINAR',
  workshop: 'WORKSHOP',
};

const TIME_FILTERS = {
  upcoming: 'UPCOMING',
  past: 'PAST',
};

export const HACKSTER_FORM_EVENT_TYPE_OPTS = ['CONFERENCE', 'HACKATHON', 'MEETUP', 'WEBINAR', 'WORKSHOP'];

export function getEventFindByEnum(type) {
  return EVENT_FIND_BY_TYPES[type];
}

export function getEventTypeEnum(type = '') {
  return EVENT_TYPES[type.toLowerCase()];
}

export function getSortEnum(val, order = 0) {
  const key = `${val}_${order}`;

  switch (key) {
    case 'most_recent_0':
      return 'START_DESC';

    case 'most_recent_1':
      return 'START_ASC';

    default:
      return null;
  }
}

export function getTimeFilterEnum(val) {
  return TIME_FILTERS[val] || null;
}
