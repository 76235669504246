import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import markdown from '../../../utils/Markdown';

import Icon from '../../../icon';

import btnStyles from '../../../../styles/global_ui/buttons.css';
import styles from './sticky_footer.css';
import transitions from '../transitions.css';

const renderMsgType = (msg = '', props = {}) => {
  switch (typeof msg) {
    case 'function':
      return msg(props);
    case 'string':
      return (<div dangerouslySetInnerHTML={{ __html: markdown.render(msg) }} />);
    default:
      return null;
  }
};

const StickyFooterView = ({ handleClick, isVisible, msg }) => (
  <CSSTransition classNames={transitions} timeout={250}>
    <div>
      {isVisible
      && (
        <div className={styles.root} id="sticky-footer">
          <div className={styles.bodyWrapper}>
            {renderMsgType(msg)}
          </div>
          <button className={`${btnStyles.blank} ${styles.closeBtn}`} onClick={handleClick}>
            <Icon name="close" />
          </button>
        </div>
      )}
    </div>
  </CSSTransition>
);

StickyFooterView.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]).isRequired,
};

export default StickyFooterView;
