import React, { Component } from 'react';
import PropTypes from 'prop-types';
import request from 'superagent';

import OAuth from '../../../services/oauth';
import { summonGlobalMessenger } from '../../../utility/dispatchers';

import Address from '../views/Address';
import AddressBookSelectView from '../book/AddressBookSelectView';
import AddressForm from '../form';
import Button from '../../buttons/base';
import ButtonLoader from '../../buttons/loader';
import Dialog from '../../reusable_components/Dialog';
import VerifyAddressView from '../form/VerifyAddressView';

import currentUserService from '../../../services/current_user';
import errorHandler from '../../../services/error_handler';

import verifyAddress from '../../../services/smartyStreets';
import { graphMutate } from '../../../requests/graphql';

import { isBlank } from '../../../utility/types';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

/**
 * NOTE: This Component is meant for a current user's addresses only. If a form using this Component has admin access
 * to edit the form on behalf of a user, that form must unmount this Component from the view.
 */
class AddressButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeAddress: null,
      addresses: [],
      dialog: { open: false, data: null, ref: null, view: 'default' }, // views: ['book', 'edit', 'default', 'verify']
      initialized: false,
      isBusy: false,
      view: 'default', // ['edit', 'deleted', 'default']
      saved: false,
    };

    this.addOrUpdateAddress = this.addOrUpdateAddress.bind(this);
    this.makeAddressDefault = this.makeAddressDefault.bind(this);
    this.patchShippingAddress = this.patchShippingAddress.bind(this);
    this.saveVerifiedAddress = this.saveVerifiedAddress.bind(this);
  }

  componentDidMount() {
    this._fetchUserAddresses();
  }

  /**
   * Initializers
   */
  _fetchUserAddresses() {
    return currentUserService.fetchProperty('addresses', { t: 'get_addresses_for_current_user' })
      .then((addresses) => {
        const initId = this.props.initAddressId || this.props.addressId || null;
        const activeAddress = this._getActiveAddress(addresses, initId);
        const currentUserHasInitAddress = ((initId === null) || (initId && activeAddress));

        this.setState({
          activeAddress,
          addresses: this._sortAddresses(addresses),
          initialized: true,
          view: !currentUserHasInitAddress ? 'deleted' : addresses.length > 0 ? 'edit' : 'default',
          dialog: this.props?.modal?.open ? this._getDialogShape(true, 'book') : this._getDialogShape(false, 'default', null, null, true),
        });
      })
      .catch((err) => errorHandler('AddressButton _fetchUserAddresses', err));
  }

  _getActiveAddress(addresses, initAddressId) {
    if (!addresses.length) return null;

    const active = addresses.find((address) => initAddressId ? address.id === initAddressId : address.default);
    const activeAddress = active || addresses[0];
    this.props.onSelection(activeAddress);

    return activeAddress;
  }

  _sortAddresses(addresses) {
    return addresses.reduce((acc, address) => address.default ? [address].concat(acc) : acc.concat(address), []);
  }

  /**
   * Methods
   */
  addOrUpdateAddress(address) {
    this.setState({ isBusy: true });

    return verifyAddress(address)
      .then((verification) => (
        verification.originIsVerified
          ? this._createOrUpdateAddress({ ...verification.address, verified: true })
          : this._toggleToVerificationView(verification)
      ))
      .then((resolver) => resolver())
      .then(() => this.setState({ saved: true }))
      .catch((err) => {
        summonGlobalMessenger({ msg: 'Oops, something went wrong. Please try again later. Or email us at help@hackster.io ', type: 'error' });
        errorHandler('AddressButton addOrUpdateAddress: ', err);
        this.setState({ isBusy: false });
      });
  }

  makeAddressDefault(address) {
    const oldAddresses = this.state.addresses;
    const addresses = this._swapDefaultAddress(oldAddresses, address);
    this.setState({ addresses, isBusy: true });

    return graphMutate({ t: 'update_address' }, { ...address, default: true })
      .then(() => {
        this.setState({ isBusy: false });
        return addresses;
      })
      .catch((err) => {
        this.setState({ addresses: oldAddresses, isBusy: false });
        return oldAddresses;
      });
  }

  saveVerifiedAddress(address) {
    this.setState({ isBusy: true });

    return this._createOrUpdateAddress(address)
      .then((resolver) => resolver())
      .catch((err) => {
        errorHandler('AddressButton saveVerifiedAddress', err);
        this.setState({ isBusy: false });
      });
  }

  patchShippingAddress(id) {
    if (!this.props.shipTo) return;

    const { path, key } = this.props.shipTo;

    return OAuth.apiRequest(
      request
        .patch(`${OAuth.getApiPath()}${path}`)
        .send({ [key]: { address_id: id } }),
      true,
      false,
    ).then(() => this.setState({ saved: true }));
  }

  /**
   * Helpers
   */
  _createOrUpdateAddress(address) {
    return new Promise((resolve, reject) => {
      const isUpdating = Object.hasOwn(address, 'id') && !isBlank(address.id);
      const mutation = isUpdating ? { t: 'update_address' } : { t: 'create_address' };

      return graphMutate(mutation, address)
        .then(({ record }) => {
          // TODO: Write a handler where isUpdating is false and record.id does not exist (server didn't create record),
          // to render a server error near the actions in BookSelectView. Write a conditional here that calls either that new
          // resolver or the one below.
          resolve(() => {
            const updatedAddress = isUpdating ? address : { ...address, id: parseInt(record.id) };
            const addresses = isUpdating
              ? this.state.addresses.map((a) => a.id === address.id ? address : a)
              : this.state.addresses.concat(updatedAddress);

            // If we're adding an address make it active and select it
            this.props.onSelection(updatedAddress);

            this.patchShippingAddress(updatedAddress.id);

            this.setState({
              addresses,
              activeAddress: updatedAddress,
              dialog: this._getDialogShape(false, 'default', null, null, true),
              isBusy: false,
              view: 'edit',
            });
          });
        })
        .catch((err) => {
          summonGlobalMessenger({ msg: JSON.parse(err.response.text).errors[0], type: 'error' });
        }).finally(() => {
          this.setState({ isBusy: false });
        });
    });
  }

  /**
   * Helps us do "breadcrumbing" through dialog view states. The ref here is for referrer, not a React ref.
   * When useRef is true and a dialog state has a ref, we toggle back to the previous view that has been cached.
   * This lets us easily do back and forth view flipping when navigating through the dialog views.
   *
   * Examples:
   *
   * AddressButton -> clicks add address -> AddressForm -> clicks save -> VerificationView -> clicks save ->
   * we close the Dialog.
   *
   * AddressBookView -> clicks add new -> AddressForm -> clicks save -> VerificationView -> clicks save ->
   * we return to the AddressBook instead of closing the Dialog.
   */
  _getDialogShape(open = false, view = 'default', data = null, ref = null, useRef = false) {
    const o = useRef && this.state.dialog.ref ? true : open;
    const v = useRef && this.state.dialog.ref ? this.state.dialog.ref : view;

    return { open: o, view: v, data, ref };
  }

  _swapDefaultAddress(addresses, defaultAddress) {
    return addresses.reduce((acc, address) => {
      if (address.default) {
        acc = acc.concat({ ...address, default: false });
      } else if (address.id === defaultAddress.id) {
        acc = acc.concat({ ...address, default: true });
      } else {
        acc = acc.concat(address);
      }

      return acc;
    }, []);
  }

  _toggleToVerificationView(data) {
    return () => this.setState({
      dialog: this._getDialogShape(true, 'verify', data, this.state.dialog.ref),
      isBusy: false,
    });
  }

  /**
   * Views
   */
  _getMainView() {
    if (!this.state.initialized) return (<ButtonLoader width={145} />);

    switch (this.state.view) {
      case 'edit':
        return this._getEditView();

      case 'deleted':
        return this._getDefaultView({ hasAddress: false });

      default:
        return this._getDefaultView({ hasAddress: Boolean(this.state.activeAddress?.id) });
    }
  }

  _getDefaultView({ hasAddress }) {
    return (
      <>
        {!hasAddress && <p className={`${typography.error}`}>Looks like we don&apos;t have an address saved. Please add one.</p>}
        <Button
          colorStyle="secondary"
          disabled={this.state.initialized === false}
          onClick={() => this.setState({ dialog: this._getDialogShape(true, 'default') })}
          size="md"
        >
          Add an address
        </Button>
      </>
    );
  }

  _getEditView() {
    return (
      <div className={`${layout.flexColumnStart} ${layout.gutter15}`}>
        <Address address={this.state.activeAddress} view="brief" />
        {!this.props.canEditAddress && (
          <p className={`${typography.bodyS}`}>
            Please reach out to
            <a href="mailto:help@hackster.io"> help@hackster.io</a>
            {' '}
            if you need to update your address.
          </p>
        )}
        <div className={`${layout.flex} ${layout.gutter15}`}>
          <Button
            colorStyle="primary"
            disabled={this.state.initialized === false || !this.props.canEditAddress || this.state.saved}
            onClick={() => this.addOrUpdateAddress(this.state.activeAddress)}
            size="md"
          >
            { this.state.saved ? 'Verified!' : 'Use this address'}
          </Button>
          <Button
            colorStyle="secondary"
            disabled={this.state.initialized === false || !this.props.canEditAddress}
            onClick={() => this.setState({ dialog: this._getDialogShape(true, 'book') })}
            size="md"
          >
            Use another address
          </Button>
        </div>
      </div>
    );
  }

  _getDialogView() {
    switch (this.state.dialog.view) {
      case 'book':
        return this._getAddressBookView();

      case 'verify':
        return this._getVerifyView();

      default:
        return this._getAddressFormView();
    }
  }

  _getAddressBookView() {
    return (
      <AddressBookSelectView
        activeAddress={this.state.activeAddress}
        addNewAddress={() => this.setState({ dialog: this._getDialogShape(true, 'default', null, 'book') })}
        addresses={this.state.addresses}
        editAddress={(address) => this.setState({ dialog: this._getDialogShape(true, 'edit', address, 'book') })}
        isBusy={this.state.isBusy}
        makeDefault={this.makeAddressDefault}
        selectActive={(activeAddress) => {
          this.props.onSelection(activeAddress);
          this.setState({
            activeAddress,
            dialog: this._getDialogShape(false, this.state.dialog.view),
          });
        }}
        ship={this.patchShippingAddress}
      />
    );
  }

  _getAddressFormView() {
    return (
      <AddressForm
        addOrUpdateAddress={this.addOrUpdateAddress}
        dismiss={() => this.setState({ dialog: this._getDialogShape(false, 'default', null, null, true) })}
        initData={this.state.dialog.data}
        isBusy={this.state.isBusy}
        view={this.state.dialog.view}
      />
    );
  }

  _getVerifyView() {
    return (
      <VerifyAddressView
        editAddress={(address) => this.setState({ dialog: this._getDialogShape(true, 'edit', address, this.state.dialog.ref) })}
        isBusy={this.state.isBusy}
        saveAddress={this.saveVerifiedAddress}
        verificationData={this.state.dialog.data}
      />
    );
  }

  render() {
    return (
      <div>
        {this._getMainView()}

        <Dialog
          dismiss={() => this.setState({ dialog: this._getDialogShape(false, this.state.dialog.view, this.state.dialog.data) })}
          open={this.state.dialog.open}
        >
          {this._getDialogView()}
        </Dialog>
      </div>

    );
  }
}

AddressButton.propTypes = {
  initAddressId: PropTypes.number,
  onSelection: PropTypes.func,
};

AddressButton.defaultProps = {
  initAddressId: null,
  onSelection: () => {},
};

export default AddressButton;
