import validator from 'validator';

import { BlockElements } from '../constants/elements';
import { genKey as createHashId } from 'draft-js';
import { domWalk } from '../utils/Traversal';

export function toLiveHtml(string) {
  const doc = document.createDocumentFragment();
  let body = document.createElement('body');
  body.innerHTML = string;
  doc.appendChild(body);
  return doc.firstChild;
};

export function stringifyLineBreaksToParagraphs(text, nodeName) {
  const tag = nodeName && nodeName.toLowerCase() === 'pre' ? 'pre' : 'div';
  return text
    .split('\n')
    .map(line => `<${tag}>${!line.length ? '<br/>' : validator.escape(line) + '\r'}</${tag}>`)
    .join('');
};

export default {

  toHtml(json) {
    let attribs, tag, innards, children;
    return json.map(item => {
      attribs = this.getAttributesByTagType(item);
      tag = `<${item.tag}${attribs}>`;
      innards = item.content || '';
      children = item.children && item.children.length < 1 ? '' : this.toHtml(item.children);
      return item.tag === 'br' ? `<${item.tag}/>` : `${tag}${innards}${children}</${item.tag}>`;
    }).join('');
  },

  getAttributesByTagType(item) {
    item.attribs = item.attribs || {};
    let hash = BlockElements[item.tag.toUpperCase()] ? item.attribs['data-hash'] || createHashId() : null;
    let block = BlockElements[item.tag.toUpperCase()] ? item.tag : null;
    let classes = item.attribs.class || '';
    let attribs = {
      'a': ` href="${item.attribs.href}"`,
      [block]: ` data-hash="${hash}" class="${classes}"`
    };
    return attribs[item.tag] ? attribs[item.tag] : '';
  },

  toHtmlImmutable(json) {
    return json.map(item => {
      const attribs = this.getAttributesByTagTypeImmutable(item);
      const tag = `<${item.get('tag')}${attribs}>`;
      const innards = item.get('content') || '';
      const children = item.get('children') && item.get('children').size < 1 ? '' : this.toHtmlImmutable(item.get('children'));
      return item.get('tag') === 'br' ? `<${item.get('tag')}/>` : `${tag}${innards}${children}</${item.get('tag')}>`;
    }).join('');
  },

  getAttributesByTagTypeImmutable(item) {
    const attribs = item.get('attribs') || {};
    const tag = item.get('tag');

    const hash = BlockElements[tag.toUpperCase()] ? attribs.get('data-hash') || createHashId() : null;
    const block = BlockElements[tag.toUpperCase()] ? tag : null;
    const classes = attribs.get('class') || '';
    const attribsMap = {
      'a': ` href="${attribs.get('href')}"`,
      [block]: ` data-hash="${hash}" class="${classes}"`
    };
    return attribsMap[tag] ? attribsMap[tag] : '';
  },

  /** A single node only. */
  // toLiveHtml(string) {
  //   let doc = document.createDocumentFragment();
  //   let body = document.createElement('body');
  //   body.innerHTML = string;
  //   doc.appendChild(body);
  //   return doc.firstChild;
  // },

  // stringifyLineBreaksToParagraphs(text, nodeName) {
  //   let lines = text.split('\n');
  //   let tag = nodeName === 'PRE' ? 'pre' : 'p';
  //   return lines.map(line => {
  //     return `<${tag}>${!line.length ? '<br/>' : validator.escape(line)}</${tag}>`;
  //   }).join('');
  // },

  cleanEmptyElements(parent) {
    let newParent = document.createElement(parent.nodeName);
    [].slice.apply(parent.childNodes).forEach(child => {
      if(child.childNodes.length && child.textContent.trim().length) {
        newParent.appendChild(child);
      }
    });
    return newParent;
  },

  replaceHashIds(liveNode) {
    return domWalk(liveNode, (root, child, depth) => {
      if(depth === 0 && root.nodeType === 1 && root.hasAttribute('data-hash')) {
        root.setAttribute('data-hash', createHashId());
      } else if(child.nodeType === 1 && child.hasAttribute('data-hash')) {
        child.setAttribute('data-hash', createHashId());
      }
    });
  },

  removeAttributes(json) {
    return (function recurse(json) {
      return json.map(child => {
        if(!child.children || !child.children.length) {
          if(child.tag !== 'a') {
            let hash = child.attribs && child.attribs['data-hash'] ? child.attribs['data-hash'] : null;
            child.attribs = hash !== null ? { 'data-hash': hash } : {};
          } else {
            child.attribs = { href: child.attribs.href };
          }
          return child;
        } else {
          if(child.tag !== 'a') {
            let hash = child.attribs && child.attribs['data-hash'] ? child.attribs['data-hash'] : null;
            child.attribs = hash !== null ? { 'data-hash': hash } : {};
          } else {
            child.attribs = { href: child.attribs.href };
          }
          child.children = recurse(child.children);
          return child;
        }
      });
    }(json));
  }
}