import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroup from '../../wrappers/form_group';
import BasicImageUploader from '../basic_image_uploader';

import { getInObj } from '../../../../utility/accessors';

const DIMENSIONS_WARNING = (width) => `Warning: your image is smaller than our recommended dimensions. Image width: ${width}px.`;

class FormImageUploader extends Component {
  constructor(props) {
    super(props);

    this.state = { uploaderError: '' };

    this.handleError = this.handleError.bind(this);
  }

  /**
   * Methods
   */
  handleError(uploaderError) {
    this.setState({ uploaderError });
  }

  /**
   * Helpers
   */
  _getImageDimensionsWarning() {
    const width = getInObj(['imageData', 'dimensions', 'width'], this.props);
    const minWidth = this.props.dimensionMins.width;

    return (width && minWidth && width < minWidth) ? DIMENSIONS_WARNING(width) : null;
  }

  _getWarningText() {
    return this.state.uploaderError || this._getImageDimensionsWarning();
  }

  render() {
    return (
      <FormGroup
        classList={{ root: this.props.classList.formGroup }}
        errors={this.props.errors}
        helperText={this.props.helperText}
        label={this.props.label}
        name={this.props.name}
        value=""
        warningText={this._getWarningText()}
      >
        <BasicImageUploader {...this.props} reportError={this.handleError} />
      </FormGroup>
    );
  }
}

FormImageUploader.propTypes = {
  allowGifs: PropTypes.bool,
  aspectRatio: PropTypes.number,
  classList: PropTypes.shape({
    button: PropTypes.string,
    cancelButton: PropTypes.string,
    formGroup: PropTypes.string,
    previewWrapper: PropTypes.string,
  }),
  dimensionsMins: PropTypes.shape({ width: PropTypes.number }),
  errors: PropTypes.string,
  helperText: PropTypes.string,
  imageData: PropTypes.shape({
    dimensions: PropTypes.shape({ width: PropTypes.number }),
    url: PropTypes.string,
    name: PropTypes.string,
  }),
  nestedDialogLevel: PropTypes.number,
};

FormImageUploader.defaultProps = {
  allowGifs: true,
  aspectRatio: null,
  classList: {
    button: '',
    cancelButton: '',
    formGroup: '',
    previewWrapper: '',
  },
  dimensionMins: {},
  errors: '',
  helperText: '',
  imageData: {},
  nestedDialogLevel: 0,
};

export default FormImageUploader;
