import { mapifyStringQuery, mapToStringQuery } from './converters';
import { windowLocationPathname } from '../services/window';

// This ignores hash. put it in if you need it for some reason
export function addQueryParamsToURL(url = '', queryParams = {}) {
  const [base, query] = url.split('?');
  const map = { ...mapifyStringQuery(query), ...queryParams };

  return `${base}?${mapToStringQuery(map)}`;
}

export function appendToPath(pathString, partsToAppend = []) {
  if (!partsToAppend.length) return pathString;
  if (pathString.slice(-1) === '/') {
    pathString = pathString.substring(0, pathString.length - 1);
  }

  // warning: this calls window.encodeURIComponent. won't work with SSR
  const encodedParts = partsToAppend.map((part) => window.encodeURIComponent(part));

  return prependLeadingSlash(pathString.concat('/').concat(encodedParts.join('/')));
}

export function cleanPreAndPostSlashes(path) {
  return path === '/' || path === '' ? '/' : path.split('/').filter((l) => l.length).join('/');
}

export function getDomain(url) {
  // eslint-disable-next-line no-useless-escape
  const match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
  const result = match && match.length ? match[1] : null;

  return result && result.length ? result : null;
}

export function getPathUpTilPart(part) {
  const path = windowLocationPathname.get();
  const parts = path.split('/').filter((p) => p.length && p !== '/');
  const indexToSlice = parts.indexOf(part);

  return indexToSlice > -1 ? parts.slice(0, indexToSlice + 1).join('/') : path;
}

export function prependLeadingSlash(string) {
  return string.slice(0, 1) !== '/' ? '/'.concat(string) : string;
}

export function stripProtocol(url) {
  return url.replace(/(^\w+:|^)\/\//, '');
}
