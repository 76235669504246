export function doesInitFacetHavePlatform(initFacet = []) {
  return initFacet.findIndex((facet) => typeof facet === 'string' && facet.split(':')[0] === 'platforms.id') > -1;
}

export function doesQueryMapContainFeatured(queryMap = {}) {
  const keys = Object.keys(queryMap);

  return (
    // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
    // eslint-disable-next-line @stylistic/no-mixed-operators
    (keys.includes('by')) && queryMap['by'] === 'featured'
    // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
    // eslint-disable-next-line @stylistic/no-mixed-operators
    || (keys.includes('tag_id') && queryMap['tag_id'] === 'featured')
    || (keys.includes('topic_id') && queryMap['topic_id'] === 'featured')
    || (keys.includes('category_id') && queryMap['category_id'] === 'featured')
  );
}
