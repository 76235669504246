import React from 'react';

export default (props) => {
  const entity = props.contentState.getEntity(props.entityKey);
  return (
    <a href={entity.get('data').href}>
      {props.children}
    </a>
  );
}
