import postal from 'postal';
import userRelationsStore from '../user_relations_store';

/**
 * Addon to manipulate the bookmarks in the userRelationsStore, since the normal
 * store methods are not built to handle the nested structure of the bookmark lists.
 */
const STORE_KEY = 'bookmark_lists';

class BookmarkStore {
  constructor() {
    this._channel = postal.channel('bookmarks');
  }

  /**
   * Initializers
   */
  initialize() {
    return new Promise((resolve, reject) => {
      if (userRelationsStore.has(STORE_KEY)) return resolve();

      // fetchAll is the only userRelationsStore method that fetches bookmarks
      return userRelationsStore.fetchAll()
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  /**
   * Helpers
   */
  _publishBookmarkChange(listId, projectId, addOrRemoveBool) {
    this._channel.publish('bookmark.changed', { listId, projectId, addOrRemoveBool });
  }

  _sortByName(lists = []) {
    // make a copy of lists to stay immutable
    return [...lists].sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
  }

  /**
   * Getters
   */
  getChannel() {
    return this._channel;
  }

  getList(listId) {
    return this.getLists().find((list) => list.id === listId);
  }

  getLists() {
    return userRelationsStore.get(STORE_KEY);
  }

  /**
   * Setters
   */
  addList(newList, projectId = null) {
    const lists = this.getLists();

    const project_ids = projectId ? [projectId] : [];
    const list = { ...newList, project_ids };
    const newLists = this._sortByName([list].concat(lists));

    userRelationsStore.set(STORE_KEY, newLists);
    if (projectId) this._publishBookmarkChange(newList.id, projectId, true);

    return newLists;
  }

  updateList(newList) {
    const lists = this.getLists();

    const newLists = lists.map((list) => list.id === newList.id ? { ...list, ...newList } : list);
    const sortedLists = this._sortByName(newLists);

    userRelationsStore.set(STORE_KEY, sortedLists);

    return sortedLists;
  }

  removeList(listId) {
    const lists = this.getLists();

    const newLists = lists.filter((list) => list.id !== listId);
    userRelationsStore.set(STORE_KEY, newLists);

    return newLists;
  }

  addProjectToList(projectId, listId) {
    const lists = this.getLists();

    const newLists = lists.reduce((acc, list) => {
      const newList = list.id === listId ? { ...list, project_ids: [projectId].concat(list.project_ids) } : list;

      return acc.concat(newList);
    }, []);

    userRelationsStore.set(STORE_KEY, newLists);
    this._publishBookmarkChange(listId, projectId, true);

    return newLists;
  }

  removeProjectFromList(projectId, listId) {
    const lists = this.getLists();

    const newLists = lists.reduce((acc, list) => {
      const newList = list.id === listId ? { ...list, project_ids: list.project_ids.filter((id) => id !== projectId) } : list;

      return acc.concat(newList);
    }, []);

    userRelationsStore.set(STORE_KEY, newLists);
    this._publishBookmarkChange(listId, projectId, false);

    return newLists;
  }
}

const bookmarkStore = new BookmarkStore();
export default bookmarkStore;
