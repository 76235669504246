import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../buttons/base';
import CategoryMenuBody from './CategoryMenuBody';
import Dialog from '../../reusable_components/Dialog';
import Icon from '../../icon';

import slideTransition from '../../../styles/transitions/slide_in_rtl.css';
import typography from '../../../styles/global_ui/typography.css';

class DialogCategoryMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };

    this.dismiss = this.dismiss.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  dismiss() {
    this.setState({ showMenu: false });
  }

  handleClick(e, category, analytics) {
    this.dismiss();
    this.props.onClick(e, category, analytics);
  }

  render() {
    const { basePath, categories, className, topAnchorId } = this.props;

    return (
      <Fragment>
        <Button
          className={className}
          colorStyle="blank"
          onClick={() => this.setState({ showMenu: true })}
        >
          <Icon className={typography.bodyM} name="hamburger" />
        </Button>
        <Dialog
          dismiss={this.dismiss}
          dismissStyle={{ top: 5 }}
          fullScreen={true}
          open={this.state.showMenu}
          transition={slideTransition}
          wrapperStyle={{ padding: 15 }}
        >
          {this.state.showMenu
          && (
            <CategoryMenuBody
              basePath={basePath}
              categories={categories}
              onClick={this.handleClick}
              topAnchorId={topAnchorId}
            />
          )}
        </Dialog>
      </Fragment>
    );
  }
}

DialogCategoryMenu.propTypes = {
  basePath: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  topAnchorId: PropTypes.string,
};

DialogCategoryMenu.defaultProps = { topAnchorId: null };

export default DialogCategoryMenu;
