import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../../client/wrappers/dropdown';
import Icon from '../../../client/icon';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import menuStyles from '../../../client/form_components/simple_select/simple_select.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

// TODO: Make this a Hook Component that can be re-used.
class EditIcon extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { openMenu: false };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  /**
   * Methods
   */
  toggleMenu() {
    this.setState({ openMenu: !this.state.openMenu });
  }

  render() {
    return (
      <div className={`${utilStyles.posRelative}`}>
        <button className={buttonStyles.blank} disabled={this.props.disabled} onClick={this.toggleMenu} type="button">
          <Icon className={`${typography.iconClickableBlue} ${this.props.disabled && typography.iconDisabled}`} name="edit" size={20} />
        </button>

        {this.state.openMenu
        && (
          <Dropdown
            key="menu"
            alignRight={true}
            alignTop={false}
            dismiss={this.toggleMenu}
          >
            <ul className={utilStyles.ulReset}>
              {this.props.menuOpts.map((opt) => (
                <li
                  key={opt.value}
                  className={menuStyles.option}
                  onClick={() => {
                    this.toggleMenu();
                    this.props.onOptClick(opt);
                  }}
                >
                  {opt.label}
                </li>
              ))}
            </ul>
          </Dropdown>
        )}
      </div>
    );
  }
}

EditIcon.propTypes = {
  disabled: PropTypes.bool.isRequired,
  menuOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

export default EditIcon;
