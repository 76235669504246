/**
 * [domWalk  :Recursively walks a DOM Node exposing each node via a callback]
 * @param  {[DOM Node]}   node     [Expects a live DOM Node to traverse]
 * @param  {Function} callback [Allows us to mutate nodes on a cloned tree.  Params: child, root, depth]
 * @return {[DOM NODE]}
 */
export function domWalk(node, callback) {
  (function recurse(root, depth) {
    if(!root.childNodes.length) {
      return root;
    } else {
      let childNodes = [].slice.apply(root.childNodes);
      childNodes.forEach(child => {
        callback(child, root, depth);
        recurse(child, depth+1);
      });
    }
  }(node, 0));
  return node;
}

/**
 * [treeWalk  :Recursively walks a JSON-like collection exposing each object via a callback]
 * @param  {[array]}   json     [deeply nested collection; one root parent (we take the first index ONLY!)]
 * @param  {Function} callback [Exposes every object. First occurance only happens if json[0] has no children.  Params: child, root, depth]
 * @return {[array]}
 */
export function treeWalk(json, callback) {
  json = (function recurse(root, depth) {
    if(!root.children.length) {
      if(depth === 0) { callback(root, root, depth); }
      return root;
    } else {
      root.children = root.children.map(child => {
        child = callback(child, root, depth) || child;
        return recurse(child, depth+1);
      });
      return root;
    }
  }(json[0], 0));
  return [json];
}