import { DEFAULT_PAGE_DESCRIPTION, DEFAULT_PAGE_TITLE } from '../../constants/pageMetadata';

const CONFIG = {
  '/': () => ({
    title: DEFAULT_PAGE_TITLE,
    pageType: 'pages#home',
    meta_desc: DEFAULT_PAGE_DESCRIPTION,
  }),
};

export default (path) => CONFIG[path];
