import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';

import ActionsSection from '../../../client/form_components/templates/ActionsSection';
import CTAForm from '../../home_edit/home_sections/cta_form';
import FormSelect from '../../../client/form_components/selects/form_select';
import PromotedContentForm from '../../home_edit/home_sections/promoted_content_form';
import RadioGroup from '../../../client/form_components/inputs/radio_group';

import AlgoliaPartsService from '../../../services/algolia/parts_service';
import AlgoliaTagsService from '../../../services/algolia/tags_service';

import { graphQuery } from '../../../requests/graphql';

import { isBlank } from '../../../utility/types';
import { pluralize } from '../../../utility/formatters';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './home.css';

const RADIO_BUTTONS = [
  { label: 'Call to action', value: 'cta' },
  { label: 'Category', value: 'category' },
  { label: 'Product', value: 'product' },
  { label: 'Promoted content', value: 'promotedContent' },
  { label: 'Tag', value: 'tag' },
];

class AddSectionView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      sectionError: null, // Used for input errors that do not have their own forms.
      sectionType: 'cta', // Maps to radio button value.
      sectionValue: '', // Used for inputs that do not have their own forms.
    };

    this.algoliaPartsService = new AlgoliaPartsService();
    this.algoliaTagsService = new AlgoliaTagsService();

    this.onAddSectionClick = this.onAddSectionClick.bind(this);
    this.onPartsSearch = this.onPartsSearch.bind(this);

    // Refs
    this.ctaRef = createRef();
    this.promotedContentRef = createRef();
  }

  /**
   * Methods
   */
  onAddSectionClick() {
    switch (this.state.sectionType) {
      case 'cta':
        return this._validateAndPropagateCTA();

      case 'category':
      case 'product':
        return this._validateAndPropagateCategoryOrProduct();

      case 'promotedContent':
        return this._validateAndPropagatePromotedContent();

      case 'tag':
        return this._validateAndPropagateTag();

      default:
        return null;
    }
  }

  onPartsSearch(query) {
    return new Promise((resolve, reject) => graphQuery({ t: 'search_parts' }, { q: query, platform_id: this.props.channel.id, part_ids: this.props.partIdsFilterFacet })
      .then((res) => {
        this.setState({ sectionError: null });
        resolve({ options: this._qlPartRecordsToSelectOptions(res.parts.records) });
      })
      .catch((err) => reject(err)));
  }

  /**
   * Helpers
   */
  _categoryListRecordsToSelectOptions(records = []) {
    return records.map((record) => ({
      disabled: (record.record.projects_count < 1),
      label: record.label,
      labelView: this._composeCategoryListOptionLabel(record),
      record: record.record,
      value: record.value,
    }));
  }

  _categoryOrCategoryTagToSection(option) {
    return {
      key: this.props.knownSorts.includes(option.value) ? 'sort' : this.props.categorySelectionList.key,
      label: option.label,
      title: option.label,
      value: option.value,
      record: option.record,
    };
  }

  _ctaToSection(cta) {
    return {
      key: 'cta',
      label: cta.name,
      meta: {
        image_name: cta.image.name,
        image_url: cta.image.url,
        link: cta.link,
      },
      title: cta.name,
      value: cta.image.id,
    };
  }

  _partRecordToSection(option) {
    return {
      key: 'part_id',
      label: option.label,
      title: option.label,
      value: option.value,
      record: option.record,
    };
  }

  _promotedContentToSection(pc) {
    return {
      key: 'promoted',
      label: 'Promoted content',
      meta: { content: pc.content },
      title: pc.title,
      value: uuidv1(),
    };
  }

  _tagToSection(option) {
    return {
      key: 'tag_id',
      label: option.label,
      title: option.title,
      value: option.value,
      record: option.record,
    };
  }

  _qlPartRecordsToSelectOptions(records = []) {
    const partCounts = this.props.projectRelationsCounts.parts || {};

    return records.map((record) => {
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
      const count = partCounts.hasOwnProperty(record.id) ? partCounts[record.id] : 0;
      const recordWithCounts = { ...record, projects_count: count };

      return {
        disabled: (count < 1),
        label: record.name,
        labelView: this._getPartOptionLabelView(recordWithCounts),
        record: recordWithCounts,
        value: record.id,
      };
    }).sort((a, b) => {
      if (a.record.projects_count > b.record.projects_count) return -1;
      if (a.record.projects_count < b.record.projects_count) return 1;

      return 0;
    });
  }

  _radioButtonsForChannelType(type) {
    switch (type) {
      case 'Community':
        return RADIO_BUTTONS.filter((rb) => rb.value !== 'product' && rb.value !== 'tag');
      case 'Platform':
        return RADIO_BUTTONS.filter((rb) => rb.value !== 'tag');
      case 'Topic':
        return RADIO_BUTTONS.filter((rb) => rb.value !== 'product');
      default:
        return null;
    }
  }

  _tagSelectionListRecordsToSelectOptions(records = []) {
    return records.map((record) => ({
      disabled: (record.record.projects_count < 1),
      label: record.label,
      labelView: this._getTagSelectionListOptionLabel(record),
      record: record.record,
      title: record.title,
      value: record.value,
    }));
  }

  _validateAndPropagateCategoryOrProduct() {
    if (isBlank(this.state.sectionValue)) return this.setState({ sectionError: `A ${this.state.sectionType} must be selected.` });

    return this.state.sectionType === 'product'
      ? this.props.appendSection(this._partRecordToSection(this.state.sectionValue), this.state.sectionType)
      : this.props.appendSection(this._categoryOrCategoryTagToSection(this.state.sectionValue), this.state.sectionType);
  }

  _validateAndPropagateCTA() {
    const validated = this.ctaRef.current.validate();
    if (validated) {
      this.props.appendSection(this._ctaToSection(this.ctaRef.current.getFormData()), this.state.sectionType);
    }
  }

  _validateAndPropagatePromotedContent() {
    const validated = this.promotedContentRef.current.validate();
    if (validated) {
      this.props.appendSection(this._promotedContentToSection(this.promotedContentRef.current.getContent()), this.state.sectionType);
    }
  }

  _validateAndPropagateTag() {
    if (isBlank(this.state.sectionValue)) return this.setState({ sectionError: `A ${this.state.sectionType} must be selected.` });
    this.props.appendSection(this._tagToSection(this.state.sectionValue), this.state.sectionType);
  }

  /**
   * Views
   */
  _getPartOptionLabelView(record) {
    return (
      <span className={`${styles.partOption} ${record.projects_count < 1 && styles.partOptionDisabled}`}>
        <span className={styles.partOptionName}>{record.name}</span>
        <span>
          (
          {record.projects_count}
          {' '}
          {pluralize('project', record.projects_count)}
          )
        </span>
      </span>
    );
  }

  _getTagSelectionListOptionLabel(record) {
    return (
      <span className={`${styles.partOption} ${record.record.projects_count < 1 && styles.partOptionDisabled}`}>
        <span className={styles.partOptionName}>{record.title}</span>
        <span>
          (
          {record.record.projects_count}
          {' '}
          {pluralize('project', record.record.projects_count)}
          )
        </span>
      </span>
    );
  }

  _composeCategoryListOptionLabel(record) {
    return (
      <span className={`${styles.partOption} ${record.record.projects_count < 1 && styles.partOptionDisabled}`}>
        <span className={styles.partOptionName}>{record.label}</span>
        <span>
          (
          {record.record.projects_count}
          {' '}
          {pluralize('project', record.record.projects_count)}
          )
        </span>
      </span>
    );
  }

  _getFormView() {
    switch (this.state.sectionType) {
      case 'category':
        return this._getCategoryView();

      case 'cta':
        return this._getCTAView();

      case 'product':
        return this._getProductView();

      case 'promotedContent':
        return this._getPromotedContentView();

      case 'tag':
        return this._getTagView();

      default:
        return null;
    }
  }

  _getCategoryView() {
    return (
      <div>
        <h3 className={`${typography.h3}`}>Create a Project category section</h3>
        <p className={`${typography.bodyS} ${layout.marginBottom30}`}>
          <span className={layout.block}>A project category section will show the 4 most recent projects of a category.</span>
          <span className={layout.block}>The options include default project sorts and any custom Project categories created on the Projects tab above.</span>
        </p>
        <FormSelect
          key="category"
          errors={this.state.sectionError}
          helperText="A section must have at-least 1 published project to be valid. Project approval may be necessary determined by your Moderation level."
          maxWidth={500}
          onSelectedChange={(opt) => this.setState({ sectionError: null, sectionValue: opt })}
          options={this._categoryListRecordsToSelectOptions(this.props.categorySelectionList.options)}
          placeholder="Select an option from the dropdown"
          selectOpts={{ rule: 'norule' }}
          value={this.state.sectionValue}
        />
      </div>
    );
  }

  _getCTAView() {
    return (
      <div>
        <h3 className={`${typography.h3}`}>Create a call to action section</h3>
        <p className={`${typography.bodyS} ${layout.marginBottom30}`}>A CTA section is a full page banner for promotional means. The image will scale dynamically with the user&apos;s screen.</p>
        <CTAForm
          ref={this.ctaRef}
          propagateStatus={(isBusy) => this.setState({ isBusy })}
        />
      </div>
    );
  }

  _getProductView() {
    return (
      <div>
        <h3 className={`${typography.h3}`}>Create a Product projects section</h3>
        <p className={`${typography.bodyS} ${layout.marginBottom30}`}>A Product projects section will display recent projects where your product is in its BOM.</p>
        <FormSelect
          key="product"
          asyncOpts={{
            initOnMount: true,
            initQuery: '',
            request: this.onPartsSearch,
          }}
          errors={this.state.sectionError}
          helperText="Only approved products will be shown in this dropdown. We will show only 20 products at a time. If you don't see your product in the dropdown, search for it by name."
          maxWidth={500}
          onSelectedChange={(opt) => this.setState({ sectionError: null, sectionValue: opt })}
          placeholder="Select an option from the dropdown or type to search"
          value={this.state.sectionValue}
        />
      </div>
    );
  }

  _getPromotedContentView() {
    return (
      <div>
        <h3 className={`${typography.h3}`}>Create a promoted content section</h3>
        <p className={`${typography.bodyS} ${layout.marginBottom30}`}>
          <span className={layout.block}>A promoted content section is a catch-all section to promote various types of your content.</span>
          <span className={layout.block}>You may have 2-4 items per section and the layout will adjust according to the amount of items.</span>
        </p>
        <PromotedContentForm
          ref={this.promotedContentRef}
          algoliaPartsService={this.algoliaPartsService}
          algoliaTagsService={this.algoliaTagsService}
          propagateStatus={(isBusy) => this.setState({ isBusy })}
        />
      </div>
    );
  }

  _getTagView() {
    return (
      <div>
        <h3 className={`${typography.h3}`}>Create a Tag projects section</h3>
        <p className={`${typography.bodyS} ${layout.marginBottom30}`}>
          <span className={layout.block}>A tag section will show the 4 most recent projects that has the tag attached.</span>
        </p>
        <FormSelect
          key="tag"
          errors={this.state.sectionError}
          helperText="A section must have at-least 1 published project to be valid."
          maxWidth={500}
          onSelectedChange={(opt) => this.setState({ sectionError: null, sectionValue: opt })}
          options={this._tagSelectionListRecordsToSelectOptions(this.props.tagSelectionList)}
          placeholder="Select an option from the dropdown"
          selectOpts={{ rule: 'norule' }}
          value={this.state.sectionValue}
        />
      </div>
    );
  }

  render() {
    return (
      <section className={styles.root}>
        <div className={layout.marginBottom60}>
          <h4 className={layout.marginBottom30}>Add project section</h4>

          <RadioGroup
            buttons={this._radioButtonsForChannelType(this.props.channel.type)}
            label="Select a section type"
            onChange={(e) => this.setState({ sectionError: null, sectionType: e.target.value, sectionValue: '' })}
            value={this.state.sectionType}
          />

          <div>
            {this._getFormView()}
          </div>

          <div>
            <ActionsSection
              disabled={this.state.isBusy}
              isBusy={this.state.isBusy}
              primaryBtnConfig={{ onClick: this.onAddSectionClick, text: 'Add section' }}
              secondaryBtnConfig={{ onClick: this.props.toggleView }}
            />
          </div>
        </div>
      </section>
    );
  }
}

AddSectionView.propTypes = {
  appendSection: PropTypes.func.isRequired,
  categorySelectionList: PropTypes.shape({
    key: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      record: PropTypes.shape({ projects_count: PropTypes.number }),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
    })),
  }).isRequired,
  channel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  knownSorts: PropTypes.arrayOf(PropTypes.string).isRequired,
  partIdsFilterFacet: PropTypes.arrayOf(PropTypes.number).isRequired,
  projectRelationsCounts: PropTypes.object.isRequired,
  tagSelectionList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.shape({ projects_count: PropTypes.number }),
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  })),
  toggleView: PropTypes.func.isRequired,
};

export default AddSectionView;
