import request from 'superagent';

import uploadFile from '../services/file_upload';
import { getAttachment, pollForAttachment, pollJob, postRemoteURL } from '../requests/attachments';
import { getErrorMsg } from '../client/global_components/messenger/messages';
import { getExtFromFileName, getFileNameFromUrl } from './links';
import { getInObj } from './accessors';
import { graphMutate } from '../requests/graphql';

export const BMP_MIMETYPE = 'image/bmp';
export const GIF_MIMETYPE = 'image/gif';
export const JPEG_MIMETYPE = 'image/jpeg';
export const JPG_MIMETYPE = 'image/jpg'; // TODO: This isn't a correct mime type. remove it?
export const PNG_MIMETYPE = 'image/png';
export const TIFF_MIMETYPE = 'image/tiff';

const IMAGE_MIMETYPES = {
  [BMP_MIMETYPE]: true,
  [GIF_MIMETYPE]: true,
  [JPEG_MIMETYPE]: true,
  [JPG_MIMETYPE]: true,
  [PNG_MIMETYPE]: true,
  [TIFF_MIMETYPE]: true,
};

// https://www.sitepoint.com/mime-types-complete-list/
const IMAGE_EXT_TO_MIME_MAP = {
  bm: BMP_MIMETYPE,
  bmp: BMP_MIMETYPE,
  gif: GIF_MIMETYPE,
  jfif: JPEG_MIMETYPE,
  jpe: JPEG_MIMETYPE,
  jpeg: JPEG_MIMETYPE,
  jpg: JPEG_MIMETYPE,
  png: PNG_MIMETYPE,
  tif: TIFF_MIMETYPE,
  tiff: TIFF_MIMETYPE,
};

let S3BucketURL = null;

export function fileExtToMIMEType(ext = '') {
  return IMAGE_EXT_TO_MIME_MAP[ext.toLowerCase()] || null;
}

export function getAndUpdateAttachmentFromLocalFile(file, dimensions, imageAttachmentArgs) {
  return uploadFile(file, getAWSResources())
    .then((id) => pollForAttachment(id))
    .then((attachment) => updateImageAttachment(attachment, dimensions, imageAttachmentArgs));
}

export function getAndUpdateAttachmentFromRemoteURL(url, imageAttachmentArgs) {
  return getAttachmentFromRemoteURL(url)
    .then((attachment) => Promise.all([Promise.resolve(attachment), getImageDimsFromUrl(attachment.file.url)])) // Pass along "attachment" to next line
    .then(([attachment, dimensions]) => updateImageAttachment(attachment, dimensions, imageAttachmentArgs));
}

export function getAttachmentFromRemoteURL(url) {
  return new Promise((resolve, reject) => {
    postRemoteURL(url, null, null, { mime_whitelist_type: 'image' })
      .then((res) => Promise.all([Promise.resolve(res), pollJob(res['job_id'])])) // pass along "res" to next line below. TODO: we could probably combine some of these on the backend to decrease the number of requests
      .then(([res, status]) => getAttachment(res.id))
      .then((attachment) => {
        if (getInObj(['file', 'url'], attachment)) {
          resolve(attachment);
        } else {
          reject(new Error('Remote file did not upload properly'));
        }
      })
      .catch((err) => reject(err));
  });
}

export function getAWSResources() {
  if (S3BucketURL === null) S3BucketURL = window.jsk.s3u;

  return { S3BucketURL };
}

export function getImageDimsFromUrl(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve({ width: image.width, height: image.height });
    image.onerror = () => reject(new Error(getErrorMsg('loading the image')));
    image.src = url;
  });
}

export function getInputAcceptProp(allowGifs = true) {
  const exts = Object.keys(IMAGE_EXT_TO_MIME_MAP)
    .filter((ext) => isAcceptedMIMEType(IMAGE_EXT_TO_MIME_MAP[ext], allowGifs))
    .map((ext) => `.${ext}`);

  const mimeTypes = Object.keys(IMAGE_MIMETYPES).filter((mimeType) => isAcceptedMIMEType(mimeType, allowGifs));

  return exts.concat(mimeTypes).join(',');
}

export function getRemoteFileTypeAndConfirmURL(url) {
  return request
    .head(url)
    .then(({ type }) => ({ url, type }))
  // If the HEAD request rejected, it may be blocked by CORS rules.
  // So, download the image as attachment using our server, then use that
  // attachment url as the remote url
    .catch(() => _remoteCORSFallback(url));
}

export function isGif(mimeType) {
  return mimeType === GIF_MIMETYPE;
}

export function isJpeg(mimeType) {
  return [JPEG_MIMETYPE, JPG_MIMETYPE].includes(mimeType);
}

export function isImageMIMEType(mimeType) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return IMAGE_MIMETYPES.hasOwnProperty(mimeType);
}

export function isAcceptedMIMEType(mimeType, allowGifs = true) {
  if (!allowGifs && isGif(mimeType)) return false;

  return isImageMIMEType(mimeType);
}

export function updateImageAttachment(attachment, dimensions, { get_image_version, type }) {
  return graphMutate({ t: 'update_attachment' }, {
    id: parseInt(attachment.id, 10),
    get_image_version,
    metadata: dimensions,
    type,
  });
}

/**
 * Helpers
 */
function _getMIMETypeFromUrl(url) {
  return fileExtToMIMEType(getExtFromFileName(getFileNameFromUrl(url)));
}

function _remoteCORSFallback(url) {
  return getAttachmentFromRemoteURL(url)
    // trusting that our server has appended the correct file extension in the url
    .then((res) => ({ type: _getMIMETypeFromUrl(res.file.url), url: res.file.url }));
}
