import PropTypes from 'prop-types';
import React from 'react';
const ROOT_2 = Math.sqrt(2);
const ARROW_ANGLE_ADJUSTMENT = 2;
const BASE_SIZE = 10;
const ARROW_SIZE = BASE_SIZE + ROOT_2;

const PopoverArrow = (props) => {
  const landscape = props.direction === 'top' || props.direction === 'bottom';
  const mirror = props.direction === 'left' || props.direction === 'top';

  const borderDiagonal = props.borderWidth * ROOT_2;
  const halfBorderDiagonal = borderDiagonal / 2;

  const primary = BASE_SIZE + borderDiagonal;
  const secondary = 2 * BASE_SIZE + 2 * borderDiagonal;

  const primaryStart = mirror ? primary : 0;
  const primaryEnd = mirror ? halfBorderDiagonal : primary - halfBorderDiagonal;

  const secondaryStart = halfBorderDiagonal;
  const secondaryMiddle = secondary / 2;
  const secondaryEnd = secondary - halfBorderDiagonal;

  const pathData = landscape
    ? [
        'M', secondaryStart, primaryStart,
        'L', secondaryMiddle, primaryEnd,
        'L', secondaryEnd, primaryStart,
      ]
    : [
        'M', primaryStart, secondaryStart + ARROW_ANGLE_ADJUSTMENT,
        'L', primaryEnd, secondaryMiddle,
        'L', primaryStart, secondaryEnd - ARROW_ANGLE_ADJUSTMENT,
      ];

  return (
    <svg
      className={props.classList}
      height={landscape ? primary : secondary}
      style={{ position: 'absolute', ...props.style }}
      width={landscape ? secondary : primary}
      xmlns="http://www.w3.org/svg/2000"
    >
      <path d={pathData.join(' ')} fill={props.color} stroke={props.borderColor} strokeLinecap="square" strokeWidth={props.borderWidth} />
    </svg>
  );
};

PopoverArrow.propTypes = {
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  classList: PropTypes.string,
  color: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
  style: PropTypes.object,
};

PopoverArrow.defaultProps = {
  borderWidth: 1,
  borderColor: 'transparent',
  classList: '',
  style: {},
};

export default PopoverArrow;
export { ARROW_SIZE };
