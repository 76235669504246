import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RingSpinner from '../../spinners/ring';

import { flagContent } from '../../../requests/flags';
import errorHandler from '../../../services/error_handler';

import menuStyles from '../../form_components/simple_select/simple_select.css';

class FlagButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flagged: false,
      isLoading: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (!this.state.isLoading && !this.state.flagged) {
      this.setState({ isLoading: true });
      this._createFlag();
    }
  }

  _createFlag() {
    return flagContent(this.props.flaggable, this.props.currentUserId)
      .then((res) => this.setState({ isLoading: false, flagged: true }))
      .catch((err) => errorHandler('FlagButton createFlag ', err));
  }

  _getLoader() {
    return (<RingSpinner size="16" />);
  }

  _getText() {
    return this.state.flagged ? 'Report sent' : 'Report';
  }

  render() {
    return (
      <span className={`flag-btn ${this.state.flagged ? menuStyles.optionDisabled : ''}`} onClick={this.onClick}>
        {this.state.isLoading ? this._getLoader() : this._getText()}
      </span>
    );
  }
}

FlagButton.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  flaggable: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default FlagButton;
