import PropTypes from 'prop-types';
import React from 'react';

const ErrorPage = ({ basePath, history, transition }) => (
  <div style={{ padding: '10%', textAlign: 'center' }}>
    <h1>Sorry, this page does not exist</h1>
    {history.length > 1
    && (
      <a
        href="javascript:void(0);"
        onClick={(e) => {
          e.preventDefault();
          history.goBack();
        }}
      >
        Go back to the previous page
      </a>
    )}
    {history.length > 1 && <span>{' or '}</span>}
    <a
      href="javascript:void(0);"
      onClick={(e) => {
        e.preventDefault();
        transition(history, '/');
      }}
    >
      {`Go to ${basePath.slice(1).slice(0, 1).toUpperCase()}${basePath.slice(2)}`}
    </a>
  </div>
);

ErrorPage.propTypes = {
  basePath: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  transition: PropTypes.func.isRequired,
};

export default ErrorPage;
