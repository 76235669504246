const windowInnerHeight = function() {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
};

const windowInnerWidth = function() {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};

export default class Session {
  constructor({
    distinctId = null,
    sessionId = null,
    pageType = null,
    userId = null,
    utmParams = {},
    whitelabel = null,
  } = {}) {
    this.distinctId = distinctId;
    this.pageType = pageType;
    this.sessionId = sessionId;
    this.userId = userId;

    this.utm_campaign = utmParams.utm_campaign;
    this.utm_content = utmParams.utm_content;
    this.utm_medium = utmParams.utm_medium;
    this.utm_source = utmParams.utm_source;
    this.utm_term = utmParams.utm_term;

    this.whitelabel = whitelabel;
  }

  createRecord() {
    return {
      distinct_id: this.distinctId,
      ip: '${keen.ip}',
      landing_page_type: this.pageType,
      landing_page_url: document.location.toString(),
      referrer: { url: document.referrer || '' },
      screen: {
        height: windowInnerHeight(),
        width: windowInnerWidth(),
      },
      session_id: this.sessionId,
      user_agent: '${keen.user_agent}',
      user_id: this.userId,
      utm_campaign: this.utm_campaign,
      utm_content: this.utm_content,
      utm_medium: this.utm_medium,
      utm_source: this.utm_source,
      utm_term: this.utm_term,
      whitelabel: this.whitelabel,
      keen: {
        addons: [
          {
            name: 'keen:ip_to_geo',
            input: { ip: 'ip' },
            output: 'geo_info',
          },
          {
            name: 'keen:referrer_parser',
            input: { referrer_url: 'referrer.url' },
            output: 'referrer.info',
          },
          {
            name: 'keen:ua_parser',
            input: { ua_string: 'user_agent' },
            output: 'parsed_user_agent',
          },
          {
            name: 'keen:url_parser',
            input: { url: 'landing_page_url' },
            output: 'parsed_landing_page_url',
          },
        ],
      },
    };
  }
}
