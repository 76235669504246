/* eslint-disable */
// ESLINT DISABLED
import {Parser} from 'htmlparser2';
import {DomHandler} from 'domhandler';
import sanitizer from 'sanitizer';

export const BlockElements = {
  'p': true,
  'ul': true,
  'h3': true,
  'blockquote': true,
  'pre': true
};

export const ElementWhiteList = {
  'p': true,
  'a': true,
  'pre': true,
  'blockquote': true,
  'ul': true,
  'h3': true,
  'code': true,
  'strong': true,
  'span': true,
  'br': true,
  'em': true,
  'li': true
};

function transformTagNames(name, depth) {
  const transformMap = {
    'b': 'strong',
    'bold': 'strong',
    'italic': 'em',
    'i': 'em',
    'ol': 'ul',
    'h1': 'h3',
    'h2': 'h3',
    'h4': 'h3'
  };
  const updatedName = transformMap.hasOwnProperty(name) ? transformMap[name] : name;

  if (!ElementWhiteList.hasOwnProperty(updatedName)) return depth > 0 ? 'span' : 'p';
  return updatedName;
}

function createSpan(content, children=[]) {
  content = content || '';
  return { tag: 'span', attribs: {}, content: content, children: children };
}

function createBR() {
  return { tag: 'br', attribs: {}, content: null, children: [] };
}

function flattenChildren(children) {
  let content = [];

  (function recurse(array) {
    if(!array.length) {
      return;
    } else {
      array.forEach(item => {
        if (item.children.length) {
          recurse(item.children);
        } else if (item.tag === 'br') {
          content.push(createBR());
        } else if (item.content && item.content.length > 0){
          content.push(createSpan(item.content));
        } // TODO: Might need to add a case here to add a br in a span for placement.
      });
    }
  }(children));

  return content;
}

function createPreChildren(node, isFirstPreFound=false) {
  let children = isFirstPreFound ? [] : [createBR()];

  if (node.children.length) {

    if (node.content) children.push(createSpan(node.content));
    children.push(...flattenChildren(node.children));

  } else {
    children.push(createSpan(node.content));
  }

  return children.filter(child => child !== null);
}

function concatPreBlocks(json) {
  return json.reduce((acc, curr, index) => {
    if (acc.length > 0 && acc[acc.length-1].tag === 'pre' && curr.tag === 'pre') {

      let children = createPreChildren(curr, false);
      acc[acc.length-1].children[0].children.push(...children);

    } else if (curr.tag === 'pre') {

      let code = { tag: 'code', attribs: {}, content: '', children: createPreChildren(curr, true) };
      acc.push({ tag: 'pre', attribs: {}, content: '', children: [ code ] });

    } else {
      acc.push(curr);
    }

    return acc;
  }, []);
}

function concatULBlocks(json) {
  return json.reduce((acc, curr, index) => {
    const lastItem = acc[acc.length-1];

    if (lastItem && lastItem.tag === 'ul' && curr.tag === 'ul') {
      const children = lastItem.children.concat(curr.children);
      acc[acc.length-1].children = children;
    } else if (curr.tag === 'p' && !curr.children.length && !curr.content) {
      acc.push({...curr, content: '', children: [createBR()]});
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);
}

function getAttributesByTagType(item) {
  item.attribs = item.attribs || {};

  const block = BlockElements[item.tag] ? item.tag : null;
  const classes = item.attribs.class || '';
  const attribs = {
    'a': ` href="${item.attribs.href}"`,
    [block]: ` class="${classes}"`
  };

  return attribs[item.tag] ? attribs[item.tag] : '';
}

function parseTree(dom) {
  function handler(html, depth) {
    if (!html.length) {
      return [];
    }

    return html.map(item => {
      /** Transform tags to whitelist. */
      item.name = transformTagNames(item.name, depth);

      if (item.type === 'text' && !item.children) {  // Node contents, this is the base of any node tree.

        // if (item.data.match(/&nbsp;/g)) {
        //   item.data = item.data.replace(/&nbsp;/g, ' ');
        // }

        return {
          tag: 'span',
          content: sanitizer.escape(item.data),
          attribs: {},
          children: []
        };

      } else if (item.children && item.children.length === 1 && item.children[0].type === 'text') {

        // if (item.children[0].data.match(/&nbsp;/g)) {
        //   item.children[0].data = item.children[0].data.replace(/&nbsp;/g, ' ');
        // }
        return {
          tag: item.name,
          content: sanitizer.escape(item.children[0].data),
          attribs: item.attribs,
          children: []
        };

      } else {
        return {
          tag: item.name,
          content: null,
          attribs: item.attribs,
          children: handler(item.children || [], depth+1)
        };
      }
    })
    .filter(item => item !== null);
  }
  return handler(dom, 0);
}

export default function parseHTML(html, options={}) {
  return new Promise((resolve, reject) => {
    const handler = new DomHandler((err, dom) => {
      if (err) reject(`DomHandler Error: ${err}`);

      resolve(concatULBlocks(concatPreBlocks(parseTree(dom))));
    }, { ...options, normalizeWhitespace: false });

    const parser = new Parser(handler, {decodeEntities: true});
    parser.write(html);
    parser.done();
  });
}