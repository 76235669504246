import {getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
const {hasCommandModifier} = KeyBindingUtil;

export default function customKeyBindings(e) {
  // Multi keys / Hotkeys
  if (e.keyCode === 66 /* B */ && hasCommandModifier(e)) {
    return 'BOLD';
  }

  if (e.keyCode === 73 /* I */ && hasCommandModifier(e)) {
    return 'ITALIC';
  }

  if (e.keyCode === 8 /* DELETE */ && hasCommandModifier(e)) {
    return 'DELETE_LAST_WORD';
  }

  if (e.keyCode === 13 /* ENTER*/ && e.shiftKey) {
    return 'INSERT_LINE_BREAK';
  }

  if (e.keyCode === 9) {
    return 'TAB';
  }

  // Single Keys
  switch(e.keyCode) {
    case 8:
      return 'backspace'; // Note: must be lowercase, I think draft listens for this as well.

    default:
      return getDefaultKeyBinding(e);
  }
}