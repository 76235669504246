import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../icon';
import OAuthButton from '../../../auth/oauth_button';

import layoutStyles from '../../../../styles/global_ui/layout.css';
import styles from './social.css';
import typographyStyles from '../../../../styles/global_ui/typography.css';

const secondaryBtns = ['arduino', 'github', 'windowslive'];

class SocialAuth extends Component {
  constructor(props) {
    super(props);

    this.state = { showSecondary: false };

    this.toggleSecondary = this.toggleSecondary.bind(this);
  }

  _getVerb() {
    return this.props.currentPanel === 'login' ? 'Log in' : 'Sign up';
  }

  toggleSecondary(e) {
    e.preventDefault(); // TODO: look into this bug. facebook link is getting a click event?
    this.setState({ showSecondary: !this.state.showSecondary });
  }

  _renderPrimary(omniauthUrls) {
    return (
      <div className={`${layoutStyles.flexColumn} ${layoutStyles.gap15} ${layoutStyles.marginBottom15}`}>
        <OAuthButton
          action={this._getVerb()}
          authEndpoint={omniauthUrls.google_oauth2}
          service="google"
        />
        <OAuthButton
          action={this._getVerb()}
          authEndpoint={omniauthUrls.facebook}
          service="facebook"
        />
        <a
          className={`${typographyStyles.link} ${styles.centerLink}`}
          href="javascript:void(0)"
          onClick={this.toggleSecondary}
        >
          <span className={layoutStyles.marginRight10}>More options</span>
          <Icon name="arrow-right" size="12" />
        </a>
      </div>
    );
  }

  _renderSecondary(omniauthUrls) {
    return (
      <div>
        <div
          className={`${layoutStyles.flexRow} ${layoutStyles.gap15} ${styles.secondaryBtns}`}
        >
          {secondaryBtns.map((service) => (
            <OAuthButton
              key={service}
              authEndpoint={omniauthUrls[service]}
              service={service}
            />
          ))}
        </div>
        <a
          className={`${typographyStyles.link} ${styles.centerLink}`}
          href="javascript:void(0)"
          onClick={this.toggleSecondary}
        >
          <Icon name="arrow-left" size="12" />
          <span className={layoutStyles.marginLeft10}>
            Back to Google and Facebook
          </span>
        </a>
      </div>
    );
  }

  render() {
    const { omniauthUrls } = this.props;

    return this.state.showSecondary
      ? this._renderSecondary(omniauthUrls)
      : this._renderPrimary(omniauthUrls);
  }
}

SocialAuth.propTypes = {
  currentPanel: PropTypes.string,
  omniauthUrls: PropTypes.shape({
    facebook: PropTypes.string.isRequired,
    github: PropTypes.string.isRequired,
    google_oauth2: PropTypes.string.isRequired,
    windowslive: PropTypes.string.isRequired,
  }).isRequired,
};

SocialAuth.defaultProps = { currentPanel: 'signup' };

export default SocialAuth;
