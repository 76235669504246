import React, { Component } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

import Icon from '../../../client/icon';

import { cancelablePromise } from '../../../utility/promises';
import errorHandler from '../../../services/error_handler';
import smoothScroll from '../../../client/utils/smoothScroll';

import button from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './horizontal_scroll.css';

/**
 * DEPRECATED – start using HorizontalScrollBox instead.
 */
class HorizontalScroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftArrow: false,
      loading: false,
      rightArrow: false,
    };

    this.fetchMore = this.fetchMore.bind(this);
    this.scroll = this.scroll.bind(this);
    this.updateInfiniteScrollAndArrows = this.updateInfiniteScrollAndArrows.bind(this);

    // throttled state updater to use on _outerContainer's onScroll event
    this.handleScroll = throttle(this.updateInfiniteScrollAndArrows, 100);

    // Refs.
    this._innerContainer;
    this._outerContainer;

    this._activePromise;

    // Protects against a potential SO in fetchMore's catch block.
    this._errorCounter = 0;
  }

  componentDidMount() {
    this.handleResize = debounce(this.updateInfiniteScrollAndArrows, 300);
    window.addEventListener('resize', this.handleResize);

    this.updateInfiniteScrollAndArrows();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.recordsCount !== prevProps.recordsCount || this.state.loading !== prevState.loading) {
      this.updateInfiniteScrollAndArrows();
    }
  }

  componentWillUnmount() {
    if (this._activePromise) this._activePromise.cancel();
    window.removeEventListener('resize', this.handleResize);
  }

  _isScrolledToEnd(innerBox, outerBox) {
    return (outerBox.right + this.props.buffer) > innerBox.right;
  }

  _setArrowDisplay(innerBox, outerBox) {
    const isWideEnough = (outerBox.right - outerBox.left) >= this.props.minWithForArrows;

    const leftArrow = innerBox.left < outerBox.left && isWideEnough;
    const rightArrow = innerBox.right > (outerBox.right + 2) && isWideEnough; // TODO: the +2 is a dirty hack. figure out how to clean it up

    if (leftArrow !== this.state.leftArrow || rightArrow !== this.state.rightArrow) {
      this.setState({ leftArrow, rightArrow });
    }
  }

  _shouldLoadMore(innerBox, outerBox) {
    return (
      !this.state.loading
      && this._errorCounter <= 5
      && this.props.recordsCount < this.props.totalRecordsCount
      && this._isScrolledToEnd(innerBox, outerBox)
    );
  }

  fetchMore() {
    this.setState({ loading: true });
    this._activePromise = cancelablePromise(this.props.fetchMore());

    return this._activePromise
      .promise
      .then((res) => {
        if (res.promiseCanceled) return;
        this.setState({ loading: false }, () => this._errorCounter = 0);
      })
      .catch((err) => {
        if (err.promiseCanceled) return;
        errorHandler('HorizontalScroll fetch error', err);
        this.setState({ loading: false });
        this._errorCounter += 1;
      });
  }

  scroll(direction) {
    if (this._outerContainer) {
      const left = this._outerContainer.scrollLeft;

      const scrollTo = left + this.props.scrollDistance * direction;

      smoothScroll(scrollTo, 350, () => {}, this._outerContainer, true);
    }
  }

  updateInfiniteScrollAndArrows() {
    if (this._outerContainer && this._innerContainer) {
      const innerBox = this._innerContainer.getBoundingClientRect();
      const outerBox = this._outerContainer.getBoundingClientRect();

      this._setArrowDisplay(innerBox, outerBox);

      if (this._shouldLoadMore(innerBox, outerBox)) {
        this.fetchMore();
      }
    }
  }

  render() {
    const { children, classList } = this.props;

    return (
      <div className={`${styles.wrapper} ${classList.wrapper}`}>
        <div ref={(el) => this._outerContainer = el} className={`${styles.scrollContainer} ${layout.noScrollBar}`} onScroll={this.handleScroll}>
          <div ref={(el) => this._innerContainer = el} className={`${styles.itemsContainer} ${classList.scrollerInner}`}>
            {children}
            {this.state.loading
            && (
              <div className={styles.loader}>
                <i className="fa fa-circle-o-notch fa-spin"></i>
              </div>
            )}
          </div>
        </div>
        {this.state.leftArrow
        && (
          <button className={`${button.unset} ${styles.scrollLeft}`} onClick={(e) => this.scroll(-1)}>
            <Icon className={typography.pebble} name="arrow-left" size={16} />
          </button>
        )}
        {this.state.rightArrow
        && (
          <button className={`${button.unset} ${styles.scrollRight}`} onClick={(e) => this.scroll(1)}>
            <Icon className={typography.pebble} name="arrow-right" size={16} />
          </button>
        )}
      </div>
    );
  }
}

HorizontalScroll.propTypes = {
  buffer: PropTypes.number,
  classList: PropTypes.shape({
    scrollerInner: PropTypes.string,
    scrollerItem: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  fetchMore: PropTypes.func,
  minWithForArrows: PropTypes.number,
  recordsCount: PropTypes.number.isRequired,
  scrollDistance: PropTypes.number,
  totalRecordsCount: PropTypes.number.isRequired,
};

HorizontalScroll.defaultProps = {
  buffer: 150,
  classList: {},
  fetchMore: () => Promise.resolve(),
  minWithForArrows: 480,
  scrollDistance: 250,
};

export default HorizontalScroll;
