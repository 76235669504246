import BaseEvent from './BaseEvent';
import normalizeEventProps from './normalizeEventProps';

class CustomEvent extends BaseEvent {
  constructor(props) {
    super(props);
    this.eventName = props.eventName;
    this.customProps = normalizeEventProps(props.customProps, this.eventName);
  }

  createRecord() {
    return {
      ...super.createRecord(),
      custom: this.customProps,
      event_name: this.eventName,
    };
  }
}

export default CustomEvent;
