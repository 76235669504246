import { getInObjWithPrototypeAccess } from './accessors';

export function clickEventHasModifierKey(e) {
  return e.altKey || e.ctrlKey || e.shiftKey || e.metaKey;
}

export function getEventTargetHref(e) {
  return getInObjWithPrototypeAccess(['target', 'href'], e);
}

export function getEventTargetText(e) {
  return getInObjWithPrototypeAccess(['target', 'textContent'], e) || getInObjWithPrototypeAccess(['target', 'innerText'], e);
}

export function getFilesFromEvent(e) {
  const files = e.dataTransfer ? e.dataTransfer.files : e.target ? e.target.files : null;

  return [].slice.call(files || []);
}

export function selectTargetContents(e) {
  e.target.select();
}
