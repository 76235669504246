import React from 'react';
import PropTypes from 'prop-types';

import HorizontalScroll from '../horizontal_scroll';
import ViewAllWrapper from './index';

const ViewAllList = ({ cardTemplateFn, fetchMore, links, onClick, records, scrollDistance, sectionIndex, title, totalRecordsCount }) => (
  <ViewAllWrapper links={links} onClick={onClick} title={title}>
    <HorizontalScroll
      fetchMore={fetchMore}
      recordsCount={records.length}
      scrollDistance={scrollDistance}
      totalRecordsCount={totalRecordsCount}
    >
      {React.Children.toArray(records.map((record, i) => (
        cardTemplateFn(record, i, { overrideTracking: onClick, sectionIndex: sectionIndex })
      )))}
    </HorizontalScroll>
  </ViewAllWrapper>
);

ViewAllList.propTypes = {
  cardTemplateFn: PropTypes.func.isRequired,
  fetchMore: PropTypes.func,
  links: PropTypes.shape({
    external: PropTypes.string,
    intrenal: PropTypes.string,
  }),
  onClick: PropTypes.func,
  records: PropTypes.array,
  scrollDistance: PropTypes.number,
  sectionIndex: PropTypes.number,
  title: PropTypes.string,
  totalRecordsCount: PropTypes.number.isRequired,
};

ViewAllList.defaultProps = {
  fetchMore: () => {},
  links: {},
  onClick: null,
  records: [],
  scrollDistance: 540,
  sectionIndex: null,
  title: '',
};

export default ViewAllList;
