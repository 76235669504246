import React from 'react';
import PropTypes from 'prop-types';

import Image from '../../image';
import { adFAQLink } from '../../../constants/links';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './simple_ad.css';

export const getPlaceholderView = () => (<div className={styles.root} />);

const SimpleAd = ({ ad, classList, initialized, onClick }) => {
  if (!initialized) return getPlaceholderView();

  const { cover_image, id, link, text } = ad;

  return (
    <div className={`${styles.root} ${classList.root}`}>
      <div className={styles.wrapper}>
        <a
          href={link}
          onClick={(e) => onClick(e, { id })}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image className={styles.image} lazy={true} ratio="4:3" src={cover_image.url} width={125} />
        </a>

        <div className={styles.textWrapper}>
          <a
            className={`${styles.text} ${typography.bodyS} ${typography.link} ${typography.whitespacePre} ${typography.anchorNoUnderlineOnHover}`}
            href={link}
            onClick={(e) => onClick(e, { id })}
            rel="noopener noreferrer"
            target="_blank"
          >
            {text}
          </a>

          <div className={`${styles.disclaimerWrapper} ${layout.marginTop10}`}>
            <a
              className={`${styles.adFAQLink} ${typography.uppercase} ${typography.bold} ${typography.anchorNoUnderlineOnHover}`}
              href={adFAQLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Advertisement
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleAd.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  classList: PropTypes.shape({ root: PropTypes.string }),
  initialized: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

SimpleAd.defaultProps = { classList: { root: '' } };

export default SimpleAd;
