const FILTER_TO_ALGOLIA_MAP = {
  popular: () => 'most_popular',
  published: () => '', // created_at (base index)
};

function getSortIndex(queryMap, initFacet) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!queryMap.hasOwnProperty('sort') || !FILTER_TO_ALGOLIA_MAP[queryMap['sort']]) return '';

  return FILTER_TO_ALGOLIA_MAP[queryMap['sort']](initFacet);
}

export default function getSortFromQueryMap(queryMap, initFacet) {
  return getSortIndex(queryMap, initFacet);
}
