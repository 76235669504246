import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';

import highlightEntity from './rules/highlightEntity';
import hljs from './rules/highlightjs';
import mention from './rules/mention';

const RULE_MAP = {
  mention: mention,
  highlight: highlightEntity,
  code: hljs,
};

function getRenderer(config) {
  const renderer = new MarkdownIt({
    breaks: true,
    linkify: true,
    xhtmlOut: true,
  })
    .use(emoji)
    .disable(['image', 'heading']);

  // Dynamically append rules.
  if (config.rules && config.rules.length) {
    config.rules.forEach((rule) => {
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
      /* eslint-disable-next-line no-prototype-builtins */
      if (RULE_MAP.hasOwnProperty(rule)) {
        renderer.use(RULE_MAP[rule], config);
      }
    });
  }

  return renderer;
}

export default function createMarkdownRenderer(config = {}) {
  const markdown = getRenderer(config);

  // Remember old renderer, if overriden, or proxy to default renderer
  const defaultRender = markdown.renderer.rules.link_open || function(tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

  markdown.renderer.rules.link_open = function(tokens, idx, options, env, self) {
    const aIndex = tokens[idx].attrIndex('target');

    if (aIndex < 0) {
      tokens[idx].attrPush(['target', '_blank']);
    } else {
      tokens[idx].attrs[aIndex][1] = '_blank';
    }
    tokens[idx].attrPush(['class', config.highlightClass]);
    tokens[idx].attrPush(['rel', 'noopener noreferrer nofollow']);

    // pass token to default renderer.
    return defaultRender(tokens, idx, options, env, self);
  };

  return markdown;
}
