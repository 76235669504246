import {List, Map} from 'immutable';
import {getCommonStates} from './general';

export function getCurrentBlock(editorState) {
  return editorState
    .getCurrentContent()
    .getBlockForKey(
      editorState
        .getSelection()
        .getAnchorKey()
    );
}

export function getInlineStylesAndEntity(editorState) {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getAnchorKey());

  // Block is index based, so we want the startOffset minus one, meaning the character left of the cursor.
  const startOffset = Math.max(selection.getStartOffset()-1, 0);
  const styles = block.getInlineStyleAt(startOffset).toList();
  const entityKey = block.getEntityAt(startOffset);
  const entity = entityKey ? content.getEntity(entityKey) : null;

  // Highlight code block toolbar button and ignore any nested CODE elements.
  if (block.get('type') === 'code-block') {
    return Map({
      styles: styles.filter(s => s !== 'CODE').push('code-block'),
      entity
    });
  }

  return Map({styles, entity});
}

export function getPreviousChar(editorState) {
  const {block, content, selection} = getCommonStates(editorState);
  const startOffset = selection.getStartOffset();
  return block.getText().slice(Math.max(startOffset-1, 0), startOffset);
}