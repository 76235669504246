import request from 'superagent';
import getPathPrefix from '../services/oauth/getPathPrefix';

export function authRequest(url, params) {
  return getCSRFTokenFromApi()
    .then((token) => new Promise((resolve, reject) => {
      request
        .post(url)
        .set('X-CSRF-Token', token)
        .set('Accept', 'application/json')
        .withCredentials()
        .send(params)
        .end((err, res) => {
          err ? reject(err) : resolve(res.body);
        });
    }));
}

export function getCSRFTokenFromApi() {
  return new Promise((resolve, reject) => {
    request
      .get(`${getPathPrefix()}/csrf`)
      .set('Accept', 'text/plain')
      .end(function(err, res) {
        err ? reject(err) : resolve(res.text);
      });
  });
}
