import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AutosizeTextarea from '../../form_components/inputs/autosize_textarea';
import Button from '../../buttons/base';
import Icon from '../../icon';
import RingSpinner from '../../spinners/ring';
import Toolbar from './Toolbar';

import { formatSelection, getSelection } from './helpers';

import { maxLength } from '../../../services/validation/validators';
import markdown from '../../utils/Markdown';
import smoothScroll from '../../utils/smoothScroll';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import inputStyles from '../../../styles/global_ui/inputs.css';
import layoutStyles from '../../../styles/global_ui/layout.css';

const COMMENT_MAX_LEN = 1600;

class CommentEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'write',
      textAreaValue: (props.initialTextValue || ''),
    };

    this.handleSelectTool = this.handleSelectTool.bind(this);
    this.handleTextareaChange = this.handleTextareaChange.bind(this);

    this.__resetStateHook = this.__resetStateHook.bind(this);

    // ref
    this._autosizeWrapper;
    this._root;
  }

  componentDidMount() {
    if (this.props.config.scrollToOnMount) {
      this._scrollToAndFocus();
    }
  }

  /**
   * Hooks
   */
  __resetStateHook() {
    this.setState({
      activeTab: 'write',
      textAreaValue: '',
    });
  }

  /**
   * Initializers
   */
  _scrollToAndFocus() {
    if (this._root) {
      smoothScroll((this._root.getBoundingClientRect().top + window.pageYOffset) - (window.innerHeight / 2), 500, () => {
        this._autosizeWrapper.__focusTextAreaHook();
      });
    }
  }

  /**
   * Helpers
   */
  _setActiveTab(tabLabel) {
    this.setState({ activeTab: tabLabel });
  }

  _getTabClass(tabLabel) {
    return (tabLabel === this.state.activeTab) ? 'comments-form-tab active' : 'comments-form-tab';
  }

  _getSelectionData() {
    const { selectionStart, selectionEnd, value: textAreaValue } = this._autosizeWrapper._textarea;

    return getSelection({ textAreaValue, selectionStart, selectionEnd });
  }

  _getTextAreaErrors() {
    return maxLength(COMMENT_MAX_LEN, this.state.textAreaValue);
  }

  /**
   * Methods
   */
  handleTextareaChange(e) {
    this.setState({ textAreaValue: e.target.value });
  }

  handleSelectTool(e) {
    e.preventDefault();
    const toolLabel = e.currentTarget.dataset.label;
    const { textAreaValue, selectedStr, selectionRange, initialCaretPos } = this._getSelectionData();

    const formattingData = { textAreaValue, selectedStr, selectionRange, initialCaretPos, toolLabel };
    const { newCaretPos, newTextAreaValue } = formatSelection(formattingData);

    this.setState({ textAreaValue: newTextAreaValue }, () => this._autosizeWrapper.__focusTextAreaHook(newCaretPos.start, newCaretPos.end));
  }

  /**
   * Views
   */

  _getErrorView() {
    const errors = this._getTextAreaErrors();
    if (errors === null) return;

    return (
      <div className={`${inputStyles.error} ${layoutStyles.margin0} ${layoutStyles.paddingLeft5} ${layoutStyles.flex1}`}>{errors}</div>
    );
  }

  _getNavView() {
    return (
      <nav className="comments-form-nav">
        <button
          className={this._getTabClass('write')}
          disabled={this.state.activeTab === 'write'}
          onClick={() => this._setActiveTab('write')}
        >
          Write
        </button>
        <button
          className={this._getTabClass('preview')}
          disabled={this.state.activeTab === 'preview'}
          onClick={() => this._setActiveTab('preview')}
        >
          <Icon className={layoutStyles.marginRight5} name="markdown" size="20" />
          Preview
        </button>
      </nav>
    );
  }

  _getTextAreaView() {
    return (
      <AutosizeTextarea
        ref={(el) => this._autosizeWrapper = el}
        autoFocus={false}
        className="comments-textarea"
        disableUndo={true}
        onChange={this.handleTextareaChange}
        placeholder={this.props.placeholder}
        value={this.state.textAreaValue}
      />
    );
  }

  _getPreviewView() {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: markdown.render(this.state.textAreaValue) }}
        className="comments-preview"
      />
    );
  }

  _getPostButton() {
    const label = this.props.isEditing ? 'Save changes' : 'Post';

    return (
      <Button
        className={buttonStyles.sm}
        onClick={() => {
          if (this._getTextAreaErrors() === null) this.props.onPost(this.state.textAreaValue);
        }}
      >
        {this.props.isBusy ? <RingSpinner size={16} /> : label}
      </Button>
    );
  }

  _getCancelButton() {
    if (!this.props.uiConfig.renderCancel) return null;

    return (
      <a
        className="btn-link btn"
        href="javascript:void(0);"
        onClick={this.props.onDismiss}
      >
        Cancel
      </a>
    );
  }

  render() {
    return (
      <div ref={(el) => this._root = el}>
        <div className={layoutStyles.flex}>
          {this._getNavView()}
          <Toolbar handleSelectTool={this.handleSelectTool} />
        </div>
        <div className={layoutStyles.flex}>
          {this.state.activeTab === 'write'
            ? this._getTextAreaView()
            : this._getPreviewView()}
        </div>
        <div className="comments-form-button-container">
          {this._getErrorView()}
          {this._getPostButton()}
          {this._getCancelButton()}
        </div>
      </div>
    );
  }
}

CommentEditor.propTypes = {
  config: PropTypes.shape({ scrollToOnMount: PropTypes.bool }),
  initialTextValue: PropTypes.string,
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  onPost: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  uiConfig: PropTypes.shape({ renderCancel: PropTypes.bool }),
};

CommentEditor.defaultProps = {
  config: { scrollToOnMount: false },
  initialTextValue: '',
  isEditing: false,
  onCancel: null,
  placeholder: '',
  uiConfig: { renderCancel: true },
};

export default CommentEditor;
