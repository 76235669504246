/* eslint-disable no-useless-escape */
import { mapifyStringQuery, mapToStringQuery } from './converters';

// Valid embeds for Video content (Home sections).
export const VIDEO_EMBED_REGEXS = {
  facebook: /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:.+\/)*([\w\.\-]+)/,
  youtube: /(?:youtube\.com|youtu\.be)\/(?:watch\?v=|v\/|embed\/|shorts\/|live\/)?([a-zA-Z0-9\-_]{10,})/,
};

const VIDEO_URL_CONVERTERS = {
  facebook: (url, autoplay = false) => {
    // is it already an embed url or not?
    const href = /plugins\/video.+\?.*href/.test(url) ? mapifyStringQuery(url.split('?')[1]).href : url;
    const params = {
      href,
      autoplay: autoplay ? 1 : 0,
      height: 540, // enforce aspect ratio/max width. make this changeable if necessary
      mute: 0,
      width: 960,
    };

    return `https://www.facebook.com/plugins/video.php?${mapToStringQuery(params)}`;
  },
  youtube: (url, autoplay = false) => {
    const parts = url.match(VIDEO_EMBED_REGEXS['youtube']);

    return `https://www.youtube.com/embed/${parts[1]}?autoplay=${autoplay ? 1 : 0}`;
  },
};

// stuff for project story embeds
const URL_EXTENSIONS = {
  autodesk360: /myhub\.autodesk360\.com\/([a-z0-9]+\/shares\/public\/[a-zA-Z0-9]+)/,
  bitbucket: /bitbucket\.org\/([0-9a-zA-Z_\-]+\/[0-9a-zA-Z_\-]+)/,
  circuits: /123d\.circuits\.io\/circuits\/([a-z0-9\-]+)/,
  channel9: /channel9\.msdn\.com\/((?:\/?[0-9a-zA-Z_\-]+){3})/,
  codebender: /codebender\.cc\/sketch:([0-9]+)/,
  fritzing: /fritzing\.org\/projects\/([0-9a-z-]+)/,
  gist: /gist\.github\.com\/(?:[0-9a-zA-Z_\-]+\/)?([0-9a-zA-Z_\-]+)/,
  github: /github\.com\/(?:downloads\/)?([0-9a-zA-Z_\-\.]+\/[0-9a-zA-Z_\-\.]+)/,
  gfycat: /gfycat.com\/([a-zA-Z]+)/,
  instagram: /instagram\.com\/(?:p|reel|tv)\/([a-zA-Z\-0-9]+)/,
  kickstarter: /kickstarter\.com\/projects\/([0-9a-z\-]+\/[0-9a-z\-]+)/,
  oshpark: /oshpark\.com\/shared_projects\/([a-zA-Z0-9]+)/,
  sketchfab: /sketchfab\.com\/models\/([a-z0-9]+)/,
  snip2code: /snip2code\.com\/Snippet\/([0-9]+\/[0-9a-zA-Z]+)/,
  twitter: /twitter.com\/([a-zA-Z0-9_@]+\/status\/[0-9]+)/,
  upverter: /upverter\.com\/[^\/]+\/(?:embed\/)?(?:\#designId\=)?([a-z0-9]+)(?:\/)?(?:[^\/])*/,
  ustream: /ustream\.tv\/([a-z]+\/[0-9]+(\/[a-z]+\/[0-9]+)?)/,
  vimeo: /(?:player\.)?vimeo\.com\/(?:video\/)?([0-9]+)/,
  vine: /vine\.co\/v\/([a-zA-Z0-9]+)/,
  youtube: /(?:youtube\.com|youtu\.be)\/(?:watch\?v=|v\/|embed\/|shorts\/)?([a-zA-Z0-9\-_]+)/,
  youmagine: /youmagine\.com\/designs\/([a-zA-Z0-9\-]+)/,
  mp4: /(.+\.(?:mp4)(?:\?.*)?)$/i,
};

const regExps = {
  channel9: {
    regexp: URL_EXTENSIONS['channel9'],
    embed: (id) => `https://channel9.msdn.com/${id}/player`,
    index: 1,
  },
  gfycat: {
    regexp: URL_EXTENSIONS['gfycat'],
    embed: (id) => `https://gfycat.com/ifr/${id}`,
    index: 1,
  },
  instagram: {
    regexp: URL_EXTENSIONS['instagram'],
    embed: (id) => `https://instagram.com/p/${id}/embed/`,
    index: 1,
  },
  kickstarter: {
    regexp: URL_EXTENSIONS['kickstarter'],
    embed: (id) => `https://www.kickstarter.com/projects/${id}/widget/video.html`,
    index: 1,
  },
  mp4: {
    regexp: URL_EXTENSIONS['mp4'],
    embed: (id) => id,
    index: 0,
  },
  vimeo: {
    regexp: URL_EXTENSIONS['vimeo'],
    requestLink: (id) => `https://vimeo.com/api/v2/video/${id}.json`,
    embed: (id) => `https://player.vimeo.com/video/${id}`,
    index: 1,
  },
  vine: {
    regexp: URL_EXTENSIONS['vine'],
    requestLink: (id) => `https://vine.co/oembed.json?id=${id}`,
    embed: (id) => `https://vine.co/v/${id}/embed/simple`,
    index: 1,
  },
  ustream: {
    regexp: URL_EXTENSIONS['ustream'],
    embed: (id) => `http://www.ustream.tv/embed/${id}?html5ui`,
    index: 1,
  },
  youtube: {
    regexp: URL_EXTENSIONS['youtube'],
    image: (id) => `https://i.ytimg.com/vi/${id}/0.jpg`,
    requestLink: (id) => `https://img.youtube.com/vi/${id}/0.jpg`,
    embed: (id) => `https://www.youtube.com/embed/${id}`,
    index: 1,
  },
};

export function getVideoData(url) {
  const service = getService(url, /(channel9|gfycat|instagram|kickstarter|mp4|vimeo|vine|ustream|youtube)/);

  if (!service) {
    return null;
  }

  const obj = regExps[service];
  const match = url.match(obj.regexp);

  if (match && match.length) {
    const id = match[obj.index];
    const embed = obj.embed(id);
    const image = obj.image ? obj.image(id) : null;

    return { id, embed, image, service, type: 'iframe' };
  } else {
    // TODO: Handle Error!
    return null;
  }
}

export function getYouTubeId(url) {
  let ID = '';
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }

  return ID;
}

// This is for the new "video" objects (not in post embeds, not in project writeups)
// TODO: combine or better differentiate all video things in this file
// NOTE: this skips a valitation step by assuming the url is valid. If we stop validating all video urls on creation, change this
export function getEmbedUrl(url, autoplay = false) {
  const service = getService(url, /(facebook|youtube)/);
  if (!service) return null;

  return VIDEO_URL_CONVERTERS[service](url, autoplay);
}

function getService(url, regex) {
  const urlToMatch = url.replace(/youtu\.be/, 'youtube');
  const type = urlToMatch.match(regex);

  return type !== null ? type[0] : null;
}
