import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import FormImageUploader from '../form_image_uploader';

import layout from '../../../../styles/global_ui/layout.css';

// For dropping directly into rails-controlled form templates, the old way.
const RailsFormImageUploader = (props) => {
  const [imageData, setImageData] = useState(props.initData);

  return (
    <Fragment>
      <FormImageUploader
        allowRemoteURL={props.allowRemoteURL}
        aspectRatio={props.aspectRatio}
        attachmentType={props.attachmentType}
        attachmentURLKey={props.attachmentURLKey}
        classList={{ formGroup: layout.marginBottom0 }}
        dimensionMins={props.dimensionMins}
        errors={props.errors[0] || null}
        helperText={props.helperText}
        ignoreCropper={props.ignoreCropper}
        imageData={imageData}
        imageVersion={props.imageVersion}
        inputClassName={props.innerInputClassName}
        placeholderUrl={props.placeholderUrl}
        propagateUpload={(imageData) => setImageData(imageData)}
        stackedView={props.stackedView}
      />
      <input
        name={props.inputName}
        type="hidden"
        value={imageData.id ? imageData.id.toString() : ''}
      />
    </Fragment>
  );
};

RailsFormImageUploader.propTypes = {
  aspectRatio: PropTypes.number,
  attachmentType: PropTypes.string,
  attachmentURLKey: PropTypes.oneOf(['file_url', 'imgix_url']),
  dimensionMins: PropTypes.shape({ width: PropTypes.number }),
  errors: PropTypes.array,
  helperText: PropTypes.string,
  imageVersion: PropTypes.string,
  initData: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  }),
  inputName: PropTypes.string,
  placeholderUrl: PropTypes.string,
  stackedView: PropTypes.bool,
};

RailsFormImageUploader.defaultProps = {
  aspectRatio: null,
  attachmentType: 'Image',
  attachmentURLKey: 'imgix_url',
  dimensionMins: {},
  errors: [],
  helperText: '',
  imageVersion: null,
  inputName: '',
  initData: {},
  placeholderUrl: null,
  stackedView: false,
};

export default RailsFormImageUploader;
