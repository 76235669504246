import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isRequired, maxLength } from '../../../services/validation/validators';
import smoothScroll from '../../utils/smoothScroll';

import BasicFormInput from '../../form_components/inputs/basic_form_input';
import Button from '../../buttons/base';
import FormSelect from '../../form_components/selects/form_select';

import getHiddenFieldsConfig from './getHiddenFieldsConfig';
import { getCountryDataForAddress, getCountryListForSelect, getStateForCountry } from '../../../services/country_data';
import { getInObj } from '../../../utility/accessors';

import formStyles from '../../../styles/global_ui/forms.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './address_form.css';

const HIDDEN_FIELDS_ORDER = 10;

/**
 * @param  {object} value - {data: {country-data object}, label: country name, value: country iso2}
 * @return {object}
 */
const formatCountryOut = (value) => {
  const alpha2 = getInObj(['data', 'alpha2'], value);
  if (alpha2) {
    return { country: value.data.name, country_iso2: alpha2 };
  } else {
    // If country_iso2 is null?
    return { country: value.label, country_iso2: value.value };
  }
};

/**
 * @param  {string} value - user input
 * @param  {object} fields - all state fields
 * @return {string} - Abbreviated state name if we could find one.
 */
const formatStateOut = (value, fields) => {
  const countryData = fields.country.value.data;

  return { state: getStateForCountry(value, countryData) };
};

const FIELDS_TEMPLATE = {
  address_line1: { order: 4, validate: (value) => maxLength(35, value), value: '' },
  address_line2: { order: 5, validate: (value) => maxLength(35, value), value: '', notRequired: true },
  city: { order: 6, validate: (value) => maxLength(35, value), value: '' },
  company: { order: 3, validate: (value) => maxLength(35, value), value: '', notRequired: true },
  country: { order: 0, validate: (value) => maxLength(100, value), value: '', formatOut: formatCountryOut },
  first_name: { order: 1, validate: (value) => maxLength(35, value), value: '' },
  last_name: { order: 2, validate: (value) => maxLength(35, value), value: '' },
  phone: { order: 9, validate: (value) => maxLength(35, value), value: '' },
  state: { order: 7, validate: (value) => maxLength(35, value), value: '', formatOut: formatStateOut },
  zip: { order: 8, validate: (value) => maxLength(35, value), value: '' },
};

/**
 * Note: This Component is used in a Dialog that is nested in a form. Since we can't nest forms semantically, maintain the root
 * node as a div.
 */
class AddressForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      hiddenInputsConfig: null,
      fields: this._initFields(props),
      view: props.view,
    };

    this.handleCountrySelect = this.handleCountrySelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _initFields(props) {
    if (!props.initData) return FIELDS_TEMPLATE;

    const content = props.initData;
    const keys = Object.keys(FIELDS_TEMPLATE);

    return keys.reduce((acc, key) => {
      if (key === 'country') {
        const countryData = getCountryDataForAddress(content);
        acc[key] = { ...FIELDS_TEMPLATE[key], value: { data: countryData, label: countryData.name, value: countryData.alpha2 } };
      } else if (key === 'phone') {
        acc[key] = { ...FIELDS_TEMPLATE[key], value: content[key].replace(/[^0-9+-]/g, '') };
      } else if (Object.hasOwn(content, key)) {
        acc[key] = { ...FIELDS_TEMPLATE[key], value: content[key] };
      } else {
        acc[key] = FIELDS_TEMPLATE[key];
      }

      return acc;
    }, {});
  }

  /**
   * Methods
   */
  handleCountrySelect(option) {
    // Option param will be null when the input is cleared.
    const config = option?.data ? getHiddenFieldsConfig(option.data) : null;
    this.setState({
      errors: {},
      hiddenInputsConfig: config ? config({ option: HIDDEN_FIELDS_ORDER }) : null,
      fields: { ...this.state.fields, country: { ...this.state.fields.country, value: (option || '') } },
    });
  }

  handleSubmit() {
    const validated = this._validate();

    if (validated) {
      this.props.addOrUpdateAddress(this._getAddressToPropagate());
    }
  }

  /**
   * Helpers
   */
  _getAddressToPropagate() {
    const id = this.props.initData && this.props.initData.id ? { id: this.props.initData.id } : {};

    return {
      ...id,
      ...this._getFieldValuesAsObject(),
      default: this.props.initData && this.props.initData.default ? this.props.initData.default : false,
    };
  }

  _getFieldValuesAsObject() {
    const allFields = this.state.hiddenInputsConfig ? { ...this.state.fields, ...this.state.hiddenInputsConfig.fields } : this.state.fields;
    const keys = Object.keys(allFields);

    return keys.reduce((acc, key) => {
      const field = allFields[key];

      if (Object.hasOwn(field, 'formatOut')) {
        acc = { ...acc, ...field.formatOut(field.value, this.state.fields) };
      } else {
        acc[key] = field.value;
      }

      return acc;
    }, {});
  }

  _scrollToError(errors) {
    const allFields = this.state.hiddenInputsConfig ? { ...this.state.fields, ...this.state.hiddenInputsConfig.fields } : this.state.fields;
    const el = Object.keys(allFields)
      .sort((a, b) => allFields[a].order - allFields[b].order)
      .reduce((acc, key) => {
        if (acc !== null) return acc;

        return Object.hasOwn(errors, key) ? document.getElementById(`vf${key}`) : acc;
      }, null);

    if (el) {
      const container = this.props.dialogRef ? document.querySelector(`[data-ref="${this.props.dialogRef}"]`) : window;
      smoothScroll(el, 200, null, container);
    }
  }

  _setStateOrError(errorMsg, key, value) {
    if (errorMsg === null) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: deleted, ...errors } = this.state.errors;
      this.setState({
        errors,
        fields: { ...this.state.fields, [key]: { ...this.state.fields[key], value: value } },
      });
    } else {
      this.setState({
        errors: { ...this.state.errors, [key]: errorMsg },
        fields: { ...this.state.fields, [key]: { ...this.state.fields[key], value: value } },
      });
    }
  }

  _setStateForHiddenField(key, value) {
    this.setState({
      hiddenInputsConfig: {
        ...this.state.hiddenInputsConfig,
        fields: {
          ...this.state.hiddenInputsConfig.fields,
          [key]: { ...this.state.hiddenInputsConfig.fields[key], value: value },
        },
      },
    });
  }

  _validate() {
    const allFields = this.state.hiddenInputsConfig ? { ...this.state.fields, ...this.state.hiddenInputsConfig.fields } : this.state.fields;
    const keys = Object.keys(allFields);
    const errors = keys.reduce((acc, key) => {
      const field = allFields[key];
      const isRequiredFn = Object.hasOwn(field, 'customRequired') ? field.customRequired : isRequired;
      const validations = field.notRequired ? [field.validate] : [isRequiredFn, field.validate];
      const error = validations.reduce((a, fn) => {
        if (a !== null) return a;

        return fn(field.value);
      }, null);

      if (error && error.length) {
        acc[key] = error;
      }

      return acc;
    }, {});

    if (Object.keys(errors).length) {
      this.setState({ errors: { ...this.state.errors, ...errors } }, () => this._scrollToError(this.state.errors));

      return false;
    }

    return true;
  }

  /**
   * Views
   */
  _getTitle() {
    const title = this.props.view === 'edit' ? 'Edit your address' : 'What\'s your address?';

    return (
      <div className={styles.title}>
        <h2 className={typography.h2}>{title}</h2>
      </div>
    );
  }

  render() {
    return (
      <div className={formStyles.container}>
        {this._getTitle()}

        <div id="vfcountry">
          <FormSelect
            errors={this.state.errors.country}
            label="Country"
            onSelectedChange={this.handleCountrySelect}
            options={getCountryListForSelect()}
            value={this.state.fields.country.value}
          />
        </div>

        <div id="vffirst_name">
          <BasicFormInput
            errors={this.state.errors.first_name}
            label="First name"
            name="first_name"
            onChange={(e) => this._setStateOrError(null, 'first_name', e.target.value)}
            value={this.state.fields.first_name.value}
          />
        </div>

        <div id="vflast_name">
          <BasicFormInput
            errors={this.state.errors.last_name}
            label="Last name"
            name="last_name"
            onChange={(e) => this._setStateOrError(null, 'last_name', e.target.value)}
            value={this.state.fields.last_name.value}
          />
        </div>

        <div id="vfcompany">
          <BasicFormInput
            errors={this.state.errors.company}
            label="Company (optional)"
            name="company"
            onChange={(e) => this._setStateOrError(null, 'company', e.target.value)}
            value={this.state.fields.company.value}
          />
        </div>

        <div id="vfaddress_line1">
          <BasicFormInput
            errors={this.state.errors.address_line1}
            label="Address Line 1"
            name="address_line1"
            onChange={(e) => this._setStateOrError(null, 'address_line1', e.target.value)}
            value={this.state.fields.address_line1.value}
          />
        </div>

        <div id="vfaddress_line2">
          <BasicFormInput
            errors={this.state.errors.address_line2}
            label="Address Line 2 (if necessary)"
            name="address_line2"
            onChange={(e) => this._setStateOrError(null, 'address_line2', e.target.value)}
            value={this.state.fields.address_line2.value}
          />
        </div>

        <div id="vfcity">
          <BasicFormInput
            errors={this.state.errors.city}
            label="City"
            name="city"
            onChange={(e) => this._setStateOrError(null, 'city', e.target.value)}
            value={this.state.fields.city.value}
          />
        </div>

        <div id="vfstate">
          <BasicFormInput
            errors={this.state.errors.state}
            label="State"
            name="state"
            onChange={(e) => this._setStateOrError(null, 'state', e.target.value)}
            value={this.state.fields.state.value}
          />
        </div>

        <div id="vfzip">
          <BasicFormInput
            errors={this.state.errors.zip}
            label="Zip"
            name="zip"
            onChange={(e) => this._setStateOrError(null, 'zip', e.target.value)}
            value={this.state.fields.zip.value}
          />
        </div>

        <div id="vfphone">
          <BasicFormInput
            errors={this.state.errors.phone}
            label="Phone"
            name="phone"
            onChange={(e) => this._setStateOrError(null, 'phone', e.target.value.replace(/[^0-9+-]/g, ''))}
            value={this.state.fields.phone.value}
          />
        </div>

        {this.state.hiddenInputsConfig
        && React.Children.toArray(this.state.hiddenInputsConfig.components.map((c) => c(this)))}

        <div className={layout.marginTop30}>
          <Button
            disabled={this.props.isBusy}
            onClick={this.handleSubmit}
            size="lg"
          >
            {this.props.isBusy ? 'Saving address' : 'Save address'}
          </Button>
          <Button
            colorStyle="cancel"
            disabled={this.props.isBusy}
            onClick={() => this.props.dismiss()}
            size="lg"
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

AddressForm.propTypes = {
  addOrUpdateAddress: PropTypes.func.isRequired,
  dialogRef: PropTypes.string,
  dismiss: PropTypes.func,
  initData: PropTypes.shape({
    address_line1: PropTypes.string.isRequired,
    address_line2: PropTypes.string,
    city: PropTypes.string.isRequired,
    company: PropTypes.string,
    country: PropTypes.string.isRequired,
    country_iso2: PropTypes.string,
    cpf: PropTypes.string,
    default: PropTypes.boolean,
    id: PropTypes.number, // When editing an unknown address and returning from the verifyView, we wont have an id.
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }),
  isBusy: PropTypes.bool,
  view: PropTypes.oneOf(['default', 'edit']),
};

AddressForm.defaultProps = {
  dialogRef: null,
  dismiss: () => {},
  initData: null,
  isBusy: false,
  view: 'default',
};

export default AddressForm;
