import React from 'react';

import DummyCard from '../../cards/project_card/DummyCard';
import SmallDummyCard from '../../cards/small_project_card/SmallDummyCard';
import getGridClassNames from './getGridClassNames';

import layout from '../../../styles/global_ui/layout.css';

const typeMap = {
  regular: DummyCard,
  small: SmallDummyCard,
};

const DummyProjectsList = ({ className = '', cardBorder = true, grid = true, maxCols = 4, type = 'regular' }) => {
  const classNames = grid ? getGridClassNames(true, maxCols) : { grid: '', cell: '' };

  return (
    <div className={`${classNames.grid} ${className}`}>
      {[...new Array(maxCols)].map((e, i) => (
        <div key={i} className={`${classNames.cell} ${layout.flex}`}>
          {React.createElement(typeMap[type], { cardBorder: cardBorder })}
        </div>
      ))}
    </div>
  );
};

export default DummyProjectsList;
