import jwtDecode from 'jwt-decode';
import request from 'superagent';

import getPathPrefix from './getPathPrefix';
import { deleteItem, getItem, setItem } from '../local_storage';
import { setTimeoutPromise } from '../../utility/promises';

const TOKEN_KEY_PREFIX = 'hck.tkn';
const CLIENT_TOKEN = `${TOKEN_KEY_PREFIX}.client`;
const USER_TOKEN = `${TOKEN_KEY_PREFIX}.user`;

const BATCH_INTERVAL = 50;

export default class AccessTokens {
  constructor() {
    this.client_token = null;
    this.user_token = null;

    this.initialized = false;
    this.isFetching = false;

    this.batch = [];
  }

  _initialize() {
    this.client_token = getItem(CLIENT_TOKEN) || null;
    this.user_token = getItem(USER_TOKEN) || null;
    this.initialized = true;
  }

  _fetchTokensFromStorageOrServer() {
    return new Promise((resolve, reject) => {
      // If we have a null value (invalid token or forceFetch), we need to fetch new tokens.
      if (this.batch.every((promise) => promise.tokens !== null)) {
        return resolve(this.batch[0].tokens);
      }

      return this._fetchNewTokens()
        .then((res) => this._seedNewTokens(res.body))
        .then((tokens) => resolve(tokens))
        .catch((err) => reject(err));
    });
  }

  _fetchNewTokens() {
    return request
      .get(`${getPathPrefix()}/users/api_token`)
      .withCredentials();
  }

  _seedNewTokens(tokens) {
    return new Promise((resolve) => {
      this.deleteTokens();

      if (tokens.user_token) setItem(USER_TOKEN, tokens.user_token);
      setItem(CLIENT_TOKEN, tokens.client_token);
      resolve(tokens);
    });
  }

  _resolveFetchTokens(key, val) {
    this.batch = this.batch.filter((promise) => {
      promise[key](val);

      return false;
    });
    this.isFetching = false;
  }

  _getTokenFromStorage(userOnly) {
    if (userOnly) {
      return { token: getItem(USER_TOKEN), type: 'user' };
    } else {
      const token = getItem(USER_TOKEN);

      return token && token.length > 0 ? { token, type: 'user' } : { token: getItem(CLIENT_TOKEN), type: 'client' };
    }
  }

  _isTokenValid(token) {
    if (!token || !token.length) return false;
    const jwt = jwtDecode(token);

    // Protect against stolen jwts.
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    if (jwt.hasOwnProperty('user') && jwt.user.hasOwnProperty('id') && !this._doesJwtIdMatchBodyId(jwt.user.id)) {
      return false;
    }

    // Subtract 10s to create a buffer.
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return jwt.hasOwnProperty('exp') ? (new Date(new Date(jwt.exp).valueOf() - (10 * 1000)) > new Date()) : false;
  }

  _doesJwtIdMatchBodyId(id) {
    const bodyId = document.body.getAttribute('data-user-signed-in');
    if ((typeof bodyId === 'undefined') || (bodyId === null) || (typeof bodyId === 'string' && !bodyId.length)) {
      return false;
    }

    return parseInt(id) === parseInt(bodyId);
  }

  deleteTokens() {
    deleteItem(USER_TOKEN);
    deleteItem(CLIENT_TOKEN);
  }

  fetchTokens(userOnly, forceFetch = false) {
    return new Promise((resolve, reject) => {
      const tokenObj = this._getTokenFromStorage(userOnly);
      const tokenFromStorage = this._isTokenValid(tokenObj.token) && forceFetch === false
        ? { [`${tokenObj.type}_token`]: tokenObj.token }
        : null;

      this.batch.push({ resolve, reject, tokens: tokenFromStorage });

      //  We cut this promise short here, but it will be resolved once we have the tokens.
      if (this.isFetching) return;

      this.isFetching = true;

      return setTimeoutPromise(BATCH_INTERVAL)
        .then(() => this._fetchTokensFromStorageOrServer())
        .then((tokens) => this._resolveFetchTokens('resolve', tokens))
        .catch((err) => this._resolveFetchTokens('reject', err));
    });
  }

  getTokenType(userOnly) {
    return userOnly ? 'user' : 'client';
  }
}
