import React from 'react';

import DummyUserCard from '../../cards/user_card/DummyCard';

import cardStyles from '../../cards/cards.css';
import layout from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './post_card.css';

const DummyPostCard = () => (
  <div className={styles.root}>
    <div className={layout.marginBottom10}>
      <DummyUserCard avatarSize={40} titleSize="M" />
    </div>
    <div className={`${layout.paddingBottom15} ${utilStyles.borderBottom}`}>
      <div className={`${cardStyles.dummyTextM} ${layout.marginTop5}`} />
      <div className={`${cardStyles.dummyTextM} ${layout.marginTop5}`} />
      <div className={`${cardStyles.dummyTextM} ${layout.marginTop5}`} />
    </div>
    <div className={`${layout.marginTop15} ${cardStyles.dummyTextL}`} />
    <div className={cardStyles.dummyTextL} />
  </div>
);

export default DummyPostCard;
