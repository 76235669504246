import React from 'react';
import PropTypes from 'prop-types';

import ClickOutsideWrapper from '../click_outside_wrapper';

import utilStyles from '../../../styles/global_ui/util.css';
import styles from './dropdown.css';

const _getClassName = ({ alignRight, alignTop, className, elevation }) => {
  const hPos = alignRight ? styles.right : styles.left;
  const vPos = alignTop ? styles.top : styles.bottom;
  const shadow = elevation === 1 ? utilStyles.boxShadow : elevation === 2 ? utilStyles.boxShadow2 : '';

  return `${styles.dropdown} ${className} ${hPos} ${vPos} ${shadow}`;
};

const Dropdown = (props) => (
  <ClickOutsideWrapper
    className={_getClassName(props)}
    onClick={props.onClick}
    onClickOutside={props.dismiss}
    style={props.style}
    targetWillUnmount={props.targetWillUnmount}
  >
    {props.children}
  </ClickOutsideWrapper>
);

Dropdown.propTypes = {
  alignRight: PropTypes.bool,
  alignTop: PropTypes.bool,
  className: PropTypes.string,
  dismiss: PropTypes.func,
  elevation: PropTypes.oneOf([0, 1, 2]),
  onClick: PropTypes.func,
  style: PropTypes.object,
  targetWillUnmount: PropTypes.bool,
};

Dropdown.defaultProps = {
  alignRight: false,
  alignTop: false,
  className: '',
  elevation: 1,
  dismiss: () => {},
  onClick: () => {},
  style: null,
  targetWillUnmount: false,
};

export default Dropdown;
