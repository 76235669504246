import {EditorState, Modifier, RichUtils} from 'draft-js';
import {List, OrderedSet} from 'immutable';

import {AtomicBlocks} from '../constants/elements';
import {getCurrentBlock} from '../utils/draft/getters';
import {
  doesPreviousCharContainEntity,
  doesPreviousCharContainStyle,
  doesPreviousWordContainValidUrl,
  isCurrentBlockAType,
  isCursorAmidEntities,
} from '../utils/draft/conditions';
import {
  insertEmptySpace,
  linkifyPreviousWord
} from '../utils/draft/modifiers';

import {insertEmbedlyComponent, insertGistComponent, insertTweetComponent, insertVideoComponent} from './customBlockTransactions';

/**
 * Handlers
 */
const handleDefaultBehavior = (editorState, character, onChange, activeStyles=List()) => {
  if (!editorState.getSelection().isCollapsed()) return 'not-handled';
  if (isCursorAmidEntities(editorState)) return 'not-handled';

  // Apply all current active styles.
  if (activeStyles.size > 0) {
    const selection = editorState.getSelection();
    const offset = selection.getStartOffset() + 1;
    const newContent = Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), character, activeStyles.toOrderedSet(), null);
    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

    onChange(newEditorState, {styleAdded: true});
    return 'handled';
  }

  // Do not chain styles and entities.
  if (doesPreviousCharContainEntity(editorState) || doesPreviousCharContainStyle(editorState)) {
    const newContent = Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), character, OrderedSet(), null);
    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

    onChange(newEditorState);
    return 'handled';
  }

  return 'not-handled';
};

const handleEmptySpace = (editorState, onChange) => {
  if (!editorState.getSelection().isCollapsed()) return 'not-handled';
  if (isCursorAmidEntities(editorState)) return 'not-handled';

  const currentContentState = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();
  const currentBlock = currentContentState.getBlockForKey(currentSelection.getAnchorKey());

  // Embedly
  if (doesPreviousWordContainValidUrl(editorState, 'EMBEDLY')) {
    const {newEditorState, newSelectionState} = insertEmbedlyComponent({currentBlock, currentSelection, currentContentState, editorState});

    onChange(EditorState.forceSelection(newEditorState, newSelectionState));
    return 'handled';
  }

  // Gist
  if (doesPreviousWordContainValidUrl(editorState, 'GIST')) {
    const {newEditorState, newSelectionState} = insertGistComponent({currentBlock, currentSelection, currentContentState, editorState});

    onChange(EditorState.forceSelection(newEditorState, newSelectionState));
    return 'handled';
  }

  // Video
  if (doesPreviousWordContainValidUrl(editorState, 'VIDEO')) {
    const {newEditorState, newSelectionState} = insertVideoComponent({currentBlock, currentSelection, currentContentState, editorState});

    onChange(EditorState.forceSelection(newEditorState, newSelectionState));
    return 'handled';
  }

  // Tweet
  if (doesPreviousWordContainValidUrl(editorState, 'TWEET')) {
    const {newEditorState, newSelectionState} = insertTweetComponent({currentBlock, currentSelection, currentContentState, editorState});

    onChange(EditorState.forceSelection(newEditorState, newSelectionState));
    return 'handled';
  }

  // Url
  if (!doesPreviousCharContainEntity(editorState, ['LINK']) && doesPreviousWordContainValidUrl(editorState, 'LINK') && !isCurrentBlockAType(currentBlock, ['code-block'])) {
    onChange(insertEmptySpace(linkifyPreviousWord(editorState)));
    return 'handled';
  }

  // Insert clean space after style or entity
  if (doesPreviousCharContainEntity(editorState) || doesPreviousCharContainStyle(editorState)) {
    onChange(insertEmptySpace(editorState), {styleAdded: true});
    return 'handled';
  }

  return 'not-handled';
};

// Callback::Draft.onChange expects an updated EditorState.
export default function customBeforeInputHandler(editorState, character, onChange, activeStyles) {
  if (AtomicBlocks.includes(getCurrentBlock(editorState).getType())) {
    return 'handled';
  }

  switch(character) {
    case ' ':
      return handleEmptySpace(editorState, onChange);

    default:
      return handleDefaultBehavior(editorState, character, onChange, activeStyles);
  }
}
