import React from 'react';
import PropTypes from 'prop-types';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

const clickHandler = ({ category, e, onClick }) => {
  const analytics = { location: 'CategorySidebar' };
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (category.hasOwnProperty('onClick') && typeof category.onClick === 'function') {
    onClick(e, category.onClick(category), analytics);
  } else {
    onClick(e, category, analytics);
  }
};

const CategoryMenuBody = ({ basePath, categories, className, onClick, topAnchorId }) => (
  <div>
    <h5 className={`${typography.h5} ${layout.paddingBottom10} ${utilStyles.borderBottom}`}>
      Categories
    </h5>
    <div>
      {React.Children.toArray(categories.map((category) => (
        <div className={layout.marginTop10}>
          <div className={layout.flexCenterItems}>
            <div
              className={`${utilStyles.borderRadiusSmall} ${utilStyles[category.colorClass]}`}
              style={{ height: 8, width: 8 }}
            />
            <a
              className={`${typography.bodyM} ${typography.bold} ${typography.linkCharcoal} ${typography.bold} ${layout.marginLeft10}`}
              href={`${basePath}/${category.path}${topAnchorId ? `#${topAnchorId}` : ''}`}
              onClick={(e) => clickHandler({ category, e, onClick })}
            >
              {category.title}
            </a>
          </div>
          <p className={typography.bodyM}>{category.text}</p>
        </div>
      )))}
    </div>
  </div>
);

CategoryMenuBody.propTypes = {
  basePath: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  topAnchorId: PropTypes.string,
};

CategoryMenuBody.defaultProps = { topAnchorId: null };

export default CategoryMenuBody;
