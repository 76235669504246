import { getDocumentFunction, getDocumentProperty, windowLocationPathname } from '../window';

function buildProps({ path, pageType, title, ...additionalProps } = {}) {
  return {
    event: 'gtm.virtualPageview',
    pageType: pageType || getDocumentFunction('querySelector')('meta[name=pageType]').content,
    virtualPage: path || windowLocationPathname.get(),
    virtualTitle: title || getDocumentProperty('title'),
    ...additionalProps,
  };
}

export function createVirtualPageview(props = {}) {
  if (window && window.gglTagMngrDataLayer) {
    // trigger GTM virtualPageview tag
    window.gglTagMngrDataLayer.push(buildProps(props));
  }
}

export function addToDataLayer(props) {
  window.gglTagMngrDataLayer?.push(props);
}

export function addAnalyticsEventsToDataLayer(events) {
  if (!events) return;

  events.forEach((event) => {
    const props = event.customProps;
    switch (event.eventName) {
      case 'Created account':
        addToDataLayer({
          event: 'sign_up',
          method: props.provider || 'email',
        });
        break;
      case 'Entered feature gate':
        addToDataLayer({ [`fG_${props.feature_gate}`]: props.feature_enabled });
        break;
      case 'Logged in':
        addToDataLayer({ event: 'login', method: props.method });
        break;
      default:
        break;
    }
  });
}
