import { isBlank } from './types';

export function filterObject(obj, predicate = (val) => !isBlank(val), whitelist = []) {
  return Object.keys(obj).reduce((acc, key) => {
    const val = obj[key];

    if (whitelist.includes(key)) {
      acc[key] = val;
    } else if ((typeof predicate === 'function') && predicate(val, key)) {
      acc[key] = val;
    }

    return acc;
  }, {});
}

/**
 * Plucks a singular property from a list of objects.
 * @return {array}
 */
export function pluckAndReduce(list, key) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return list.reduce((acc, item) => item && item.hasOwnProperty(key) ? acc.concat(item[key]) : acc, []);
}

export function removeFromObject(obj, props) {
  return Object.keys(obj).reduce((acc, key) => {
    if ((typeof props === 'string' && key !== props)
      || (Array.isArray(props) && !props.includes(key))) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
}
