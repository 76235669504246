import { isAnEntireRowSelected, insertStrAtCaretPos } from './utils';
import { REGEX } from './constants';

const LINEBREAK_CHAR = String.fromCharCode(10);
const REQUIRED_BREAKS = 2;

/**
 * Create
 */
function createFormattedBlock({
  initialCaretPos,
  textAreaValue,
  selectedStr,
  selectionRange,
  toolLabel,
  mdSettings,
}) {
  const { start: caretStart, end: caretEnd } = initialCaretPos;
  const { start, end } = selectionRange;
  const { prependedChars } = mdSettings;

  const rows = selectedStr.split(REGEX.linebreak);
  const rowsCount = rows.length;
  const newStr = _insertMdToEveryRow(rows, prependedChars);
  const { linebreaksToPrepend, linebreaksToAppend } = getLinebreaks({ textAreaValue, start, end });

  const blockStr = linebreaksToPrepend + newStr + linebreaksToAppend;

  const newCaretPos = rows.length > 1
    ? {
        start: caretStart + linebreaksToPrepend.length,
        end: caretEnd + linebreaksToPrepend.length + (rowsCount * prependedChars.length) + (rowsCount - 1),
      }
    : {
        start: caretStart + linebreaksToPrepend.length + prependedChars.length,
        end: caretEnd + linebreaksToPrepend.length + prependedChars.length,
      };

  return {
    newCaretPos,
    newTextAreaValue: insertStrAtCaretPos(textAreaValue, start, end, blockStr),
  };
}

function _insertMdToEveryRow(rows, prependedChars) {
  return rows.map((row) => (prependedChars + row)).join(LINEBREAK_CHAR);
}

/**
 *  Get the required linebreaks to be inserted before and after, to ensure
 *  there is an empty line between the newly formatted string and the surrounding text
 */
function getLinebreaks(selectionData) {
  const breaksCountToPrepend = _getBreaksCountToPrepend(selectionData);
  const breaksCountToAppend = _getBreaksCountToAppend(selectionData);

  return {
    linebreaksToPrepend: (LINEBREAK_CHAR).repeat(breaksCountToPrepend),
    linebreaksToAppend: (LINEBREAK_CHAR).repeat(breaksCountToAppend),
  };
}

function _getBreaksCountToPrepend({ textAreaValue, start }) {
  if (start === 0) return 0; // no breaks needed in the first line
  const existingLinebreaksBefore = textAreaValue.slice(start - REQUIRED_BREAKS, start).split('').filter((char) => char === LINEBREAK_CHAR);

  return (REQUIRED_BREAKS - existingLinebreaksBefore.length);
}

function _getBreaksCountToAppend({ textAreaValue, end }) {
  if (end === textAreaValue.length) return 0; // no breaks needed in the last line
  const existingLinebreaksAfter = textAreaValue.slice(end, end + REQUIRED_BREAKS).split('').filter((char) => char === LINEBREAK_CHAR);

  return (REQUIRED_BREAKS - existingLinebreaksAfter.length);
}

/**
 * Remove
 */
function shouldRemoveMdFromRows({ textAreaValue, selectedStr, selectionRange, mdSettings }) {
  const { start, end } = selectionRange;
  const { prependedChars } = mdSettings;
  const rows = selectedStr.split(REGEX.linebreak);

  if (isAnEntireRowSelected(rows, textAreaValue, end)) {
    const charsAfterSelectionStart = textAreaValue.slice(start, start + prependedChars.length);
    const charsBeforeSelectionStart = textAreaValue.slice(start - prependedChars.length, start);

    return (charsAfterSelectionStart === prependedChars) || (charsBeforeSelectionStart === prependedChars);
  } else {
    const doesEveryRowStartWithMdChars = rows.every((row) => {
      const charsBefore = row.slice(0, prependedChars.length);

      return charsBefore === prependedChars;
    });

    return doesEveryRowStartWithMdChars;
  }
}

function removeMdFromRows({ textAreaValue, selectedStr, selectionRange, initialCaretPos, mdSettings }) {
  const { start: caretStart, end: caretEnd } = initialCaretPos;
  const { start, end } = selectionRange;
  const { prependedChars } = mdSettings;

  const rows = selectedStr.split(REGEX.linebreak);
  const charsBeforeSelectionStart = textAreaValue.slice(start - prependedChars.length, start);
  const isMdBeforeSelection = (rows.length === 1) && (charsBeforeSelectionStart === prependedChars);

  if (isMdBeforeSelection) {
    const selectedRow = rows[0];
    const newSelectionStart = start - prependedChars.length;

    return {
      newCaretPos: {
        start: caretStart - prependedChars.length,
        end: caretEnd - prependedChars.length,
      },
      newTextAreaValue: insertStrAtCaretPos(textAreaValue, newSelectionStart, end, selectedRow),
    };
  } else {
    const newStr = rows.map((row) => row.slice(prependedChars.length)).join(LINEBREAK_CHAR);

    return {
      newCaretPos: {
        start: caretStart,
        end: caretStart - newStr.length,
      },
      newTextAreaValue: insertStrAtCaretPos(textAreaValue, start, end, newStr),
    };
  }
}

export default function formatBlock(formattingData) {
  return shouldRemoveMdFromRows(formattingData)
    ? removeMdFromRows(formattingData)
    : createFormattedBlock(formattingData);
}
