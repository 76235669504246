import React from 'react';
import PropTypes from 'prop-types';

import typography from '../../../styles/global_ui/typography.css';
import styles from './view_all.css';

const ViewAllWrapper = ({ children, links, onClick, title }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <p className={styles.title}>{title}</p>
      <a
        className={`${typography.bodyS} ${typography.bold} ${typography.linkPebble} ${typography.link}`}
        href={links.view_all || links.external}
        onClick={(e) => onClick(e, links, { type: 'view_all_link' })}
      >
        View all
      </a>
    </div>
    {children}
  </div>
);

ViewAllWrapper.propTypes = {
  links: PropTypes.shape({
    external: PropTypes.string,
    internal: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

ViewAllWrapper.defaultProps = {
  links: {
    external: 'javascipt:void(0);',
    internal: '/',
  },
  onClick: () => {},
};

export default ViewAllWrapper;
