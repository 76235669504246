/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { getInObj } from './accessors';
import { isBlank, isObject } from './types';

export function doesObjectPropsMatch(objA, objB, property) {
  if (!objA || !objB) return false;
  if (!objA.hasOwnProperty(property) || !objB.hasOwnProperty(property)) return false;

  return objA[property] === objB[property];
}

export function doesRecordsMatch(a, b) {
  if (a.hasOwnProperty('id') && b.hasOwnProperty('id')) {
    return a.id === b.id;
  } else if (a.hasOwnProperty('uuid') && b.hasOwnProperty('uuid')) {
    return a.uuid === b.uuid;
  }

  return false;
}

export function doesStringsMatch(a, b, caseSensitive = false) {
  if (typeof a !== 'string' || typeof b !== 'string') {
    return false;
  } else if (caseSensitive) {
    return a === b;
  } else {
    return a.toLowerCase() === b.toLowerCase();
  }
}

export function doesValuesMatch(a, b) {
  if ((typeof a === 'number' || typeof a === 'string') && (typeof b === 'number' || typeof b === 'string')) {
    return doesStringsMatch(a.toString(), b.toString());
  } else {
    return a === b;
  }
}

export function objHasKeyPred(obj, path, pred = (v) => false) {
  const p = !Array.isArray(path) ? [path] : path;
  const v = getInObj(p, obj);

  return v === null ? false : pred(v);
}

export function objHasPropertyOfLength(obj, property) {
  return isObject(obj) && obj.hasOwnProperty(property) && !!obj[property] && obj[property].length > 0;
}

export function objHasPropertyWithValue(obj, property) {
  if (!obj) return false;

  return obj && obj.hasOwnProperty(property) && obj[property] && !isBlank(obj[property]);
}
