function doesEntityKeyMatchType(entityKey, entityType) {
  if (Array.isArray(entityType)) {
    return entityType.includes(entityKey);
  } else {
    return entityKey === entityType;
  }
}

export function characterBeforeCursorHasEntity(editorState, entityType) {
  const selectionState = editorState.getSelection();
  const currentBlock = getCurrentBlock(editorState);
  const entityKey = currentBlock.getEntityAt(selectionState.getAnchorOffset() - 1);

  return (
    entityKey !== null
    && doesEntityKeyMatchType(editorState.getCurrentContent().getEntity(entityKey).getType(), entityType)
  );
}

export function characterHasEntity(char, entityType, contentState) {
  const entityKey = char.getEntity();

  return (
    entityKey !== null
    && contentState.getEntity(entityKey).getType() === entityType
  );
}

export function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length, matchArr[0]);
  }
}

export function getCurrentBlock(editorState) {
  return editorState
    .getCurrentContent()
    .getBlockForKey(
      editorState
        .getSelection()
        .getAnchorKey(),
    );
}

export function getWordBeforeCursor(editorState) {
  const selectionState = editorState.getSelection();
  const currentBlock = getCurrentBlock(editorState);

  return currentBlock.getText().slice(0, selectionState.getAnchorOffset()).split(' ').pop();
}
