import React from 'react';
import PropTypes from 'prop-types';

import ActionableButton from '../../buttons/actionable';
import Button from '../../buttons/base';
import CategoryBadgeLabel from '../templates/CategoryBadgeLabel';
import SimpleSelect from '../../form_components/simple_select';

import { getErrorHelperView } from '../../form_components/templates';
import { isBlank } from '../../../utility/types';

import layout from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';

/**
 * Views
 */
const getErrorsView = (errors) => (
  <div>
    {React.Children.toArray(Object.values(errors).map((error) => getErrorHelperView(error)))}
  </div>
);

// Only for the PostCreator. This will remove the ability to select other categories when posting from one.
// In edit mode, we allow other categories to be selected.
const getSelectOrBadge = ({ categoryConfig, categorySelectOpts, currentPath, onCategorySelect }) => (!currentPath || currentPath === '/')
  ? getSelectView({ categorySelectOpts, onCategorySelect })
  : getBadgeView({ categoryConfig, categorySelectOpts, currentPath });

const getSelectView = ({ categorySelectOpts, onCategorySelect }) => (
  <SimpleSelect
    onSelection={onCategorySelect}
    options={categorySelectOpts}
  />
);

const getBadgeView = ({ categoryConfig, categorySelectOpts, currentPath }) => {
  const config = categoryConfig.find((c) => c.enum.toLowerCase() === currentPath.toLowerCase());

  return (
    <div className={utilStyles.categoryBadge}>
      <CategoryBadgeLabel categoryConfig={config} />
    </div>
  );
};

/**
 * Main Component
 */
const PostEditorActions = ({
  categoryConfig,
  categorySelectOpts,
  currentPath,
  errors,
  dismiss,
  isBusy,
  markdownBtn,
  mode,
  onCategorySelect,
  onPostOrSave,
}) => (
  <div className={`${layout.flexJustifySpaceBetween} ${mode === 'default' ? layout.padding15 : layout.marginTop10}`}>
    <div className={layout.flexColumn}>
      <div className={layout.flexRowWrap}>
        <div className={layout.marginRight10}>
          {getSelectOrBadge({ categoryConfig, categorySelectOpts, currentPath, onCategorySelect })}
        </div>
        {markdownBtn}
      </div>

      {!isBlank(errors) && getErrorsView(errors)}
    </div>

    <div className={layout.flexStartItems}>
      {mode === 'edit'
      && (
        <Button
          colorStyle="cancel"
          disabled={isBusy}
          onClick={dismiss}
          size="sm"
        >
          Cancel
        </Button>
      )}

      <ActionableButton
        isBusy={isBusy}
        onClick={onPostOrSave}
        size="sm"
        text={mode === 'default' ? 'Post' : 'Save'}
      />
    </div>
  </div>
);

PostEditorActions.propTypes = {
  categoryConfig: PropTypes.arrayOf(PropTypes.shape({ // Used in couple with currentPath to render a CategoryBadgeLabel
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  categorySelectOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    value: PropTypes.string,
  })).isRequired,
  currentPath: PropTypes.string, // If this is passed as a prop, we replace the SimpleSelect with a CategoryBadgeLabel.
  dismiss: PropTypes.func,
  errors: PropTypes.shape({
    category: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  isBusy: PropTypes.bool.isRequired,
  markdownBtn: PropTypes.element.isRequired,
  mode: PropTypes.oneOf(['default', 'edit']),
  onCategorySelect: PropTypes.func.isRequired,
  onPostOrSave: PropTypes.func.isRequired,
};

PostEditorActions.defaultProps = {
  currentPath: null,
  dismiss: () => {},
  mode: 'default',
};

export default PostEditorActions;
