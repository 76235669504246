import PropTypes from 'prop-types';

export const UserType = {
  bio: PropTypes.string,
  email: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  rank: PropTypes.number.isRequired,
  result: PropTypes.string.isRequired,
  user_name: PropTypes.string.isRequired,
};

export const FilterType = {
  start_date: PropTypes.string.isRequired,
  end_date: PropTypes.string.isRequired,
};
