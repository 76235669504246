import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../reusable_components/LazyImage';

import cardStyles from '../cards.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './part_card.css';

const PartCard = ({ classList, onClick, part }) => (
  <div className={classList.wrapper || cardStyles.wrapper}>
    <a className={`${cardStyles.cardWithBorder} ${styles.root}`} href={part.url} onClick={onClick}>
      <div className={styles.itemImage}>
        <LazyImage
          alt={part.name}
          className={styles.lazyImage}
          fit="fill"
          queryParams={{ bg: 'ffffff' }}
          ratio="3:2"
          setStyle={false}
          src={part.image_url}
          width={255}
        />
      </div>
      <div className={styles.info}>
        <p className={`${cardStyles.title} ${typography.bodyM} ${typography.linkCharcoal} ${layout.marginTop15} ${layout.marginBottom10}`}>{part.name}</p>
        {part.pitch && <p className={styles.pitch}>{part.pitch}</p>}
        <p className={styles.action}>view product</p>
      </div>
    </a>
  </div>
);

PartCard.propTypes = {
  classList: PropTypes.shape({ wrapper: PropTypes.string }),
  onClick: PropTypes.func.isRequired,
  part: PropTypes.shape({
    image_url: PropTypes.string,
    name: PropTypes.string,
    pitch: PropTypes.string,
  }),
};

PartCard.defaultProps = {
  classList: {},
  part: {
    image_url: '',
    name: '',
    pitch: '',
  },
};

export default PartCard;
