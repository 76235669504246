import { insertStrAtCaretPos } from './utils';
import { REGEX } from './constants';

const INSIDE = 'INSIDE_SELECTION_RANGE';
const OUTSIDE = 'OUTSIDE_SELECTION_RANGE';

function configureFormattingData(formattingData) {
  const adjustedSelection = _adjustSelection(formattingData);

  return {
    ...formattingData,
    ...adjustedSelection,
  };
}

function _adjustSelection({ selectedStr, selectionRange }) {
  const { start, end } = selectionRange;
  const isSelectionAllWs = (selectedStr.match(REGEX.nonWhitespace) === null);

  if (isSelectionAllWs) {
    return {
      selectionRange: {
        start: start + selectedStr.length,
        end,
      },
      selectedStr: selectedStr.trim(),
    };
  } else {
    const leadingWs = selectedStr.match(REGEX.leadingWhitespace);
    const trailingWs = selectedStr.match(REGEX.trailingWhitespace);
    const leadingWsCount = leadingWs ? leadingWs[0].length : 0;
    const trailingWsCount = trailingWs ? trailingWs[0].length : 0;

    return {
      selectionRange: {
        start: start + leadingWsCount,
        end: end - trailingWsCount,
      },
      selectedStr: selectedStr.trim(),
    };
  }
}

/**
 * Create
 */
function createFormattedString({ textAreaValue, selectedStr, selectionRange, initialCaretPos, mdSettings }) {
  const { start: caretStart, end: caretEnd } = initialCaretPos;
  const { appendedChars, prependedChars } = mdSettings;
  const newStr = prependedChars + selectedStr + appendedChars;

  return {
    newCaretPos: {
      start: caretStart + prependedChars.length,
      end: caretEnd + prependedChars.length,
    },
    newTextAreaValue: insertStrAtCaretPos(textAreaValue, selectionRange.start, selectionRange.end, newStr),
  };
}

/**
 * Remove
 */
function findMdCharsPosition({ textAreaValue, selectionRange, mdSettings }) {
  if (_areMdCharsInsideSelectionRange(textAreaValue, selectionRange, mdSettings)) {
    return INSIDE;
  } else if (_areMdCharsOutsideSelectionRange(textAreaValue, selectionRange, mdSettings)) {
    return OUTSIDE;
  } else {
    return null;
  }
}

// |**string**|
function _areMdCharsInsideSelectionRange(textAreaValue, selectionRange, mdSettings) {
  const { start, end } = selectionRange;
  const { appendedChars, prependedChars } = mdSettings;

  const charsInsideLeft = textAreaValue.slice(start, start + prependedChars.length);
  const charsInsideRight = textAreaValue.slice(end - appendedChars.length, end);

  return (charsInsideLeft === prependedChars) && (charsInsideRight === appendedChars);
}

//* *|string|**
function _areMdCharsOutsideSelectionRange(textAreaValue, selectionRange, mdSettings) {
  const { start, end } = selectionRange;
  const { appendedChars, prependedChars } = mdSettings;

  const charsOutsideLeft = textAreaValue.slice(start - prependedChars.length, start);
  const charsOutsideRight = textAreaValue.slice(end, end + appendedChars.length);

  return (charsOutsideLeft === prependedChars) && (charsOutsideRight === appendedChars);
}

function removeMd(mdCharsPosition, { textAreaValue, selectedStr, selectionRange, initialCaretPos, mdSettings }) {
  const { start: caretStart, end: caretEnd } = initialCaretPos;
  const { start, end } = selectionRange;
  const { appendedChars, prependedChars } = mdSettings;

  if (mdCharsPosition === OUTSIDE) {
    const mdCharsStart = start - prependedChars.length;
    const mdCharsEnd = end + appendedChars.length;

    return {
      newCaretPos: {
        start: caretStart - prependedChars.length,
        end: caretEnd - prependedChars.length,
      },
      newTextAreaValue: insertStrAtCaretPos(textAreaValue, mdCharsStart, mdCharsEnd, selectedStr),
    };
  } else { // INSIDE_SELECTION_RANGE
    const newStr = selectedStr.slice(prependedChars.length, selectedStr.length - appendedChars.length);

    return {
      newCaretPos: {
        start: caretStart - prependedChars.length,
        end: caretEnd - prependedChars.length,
      },
      newTextAreaValue: insertStrAtCaretPos(textAreaValue, start, end, newStr),
    };
  }
}

export default function formatAdjacent(formattingData) {
  const adjustedData = configureFormattingData(formattingData);
  const mdCharsPosition = findMdCharsPosition(adjustedData);

  return mdCharsPosition
    ? removeMd(mdCharsPosition, adjustedData)
    : createFormattedString(adjustedData);
}
