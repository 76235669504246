import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomCheckbox from '../custom';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

class AsyncCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      isChecked: props.initChecked,
      isCheckedAndSuccessful: props.initChecked,
    };

    this.onChange = this.onChange.bind(this);

    this._isMounted;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange(isChecked) {
    if (this.state.isBusy) return;

    this.setState({ isBusy: true, isChecked });

    return this.props.request(this.props.requestArgs(isChecked))
      .then(() => {
        if (this._isMounted) {
          this.props.propagateStatus(isChecked);
          this.setState({ isBusy: false, isCheckedAndSuccessful: isChecked, isChecked });
        }
      })
      .catch((err) => {
        if (this._isMounted) {
          this.props.propagateStatus(!isChecked);
          this.setState({ isBusy: false, isChecked: !isChecked });
        }
      });
  }

  _getLabel() {
    if (!this.props.successMsg) return this.props.label;

    return this.state.isCheckedAndSuccessful ? this.props.successMsg : this.props.label;
  }

  render() {
    return (
      <div>
        <CustomCheckbox
          checkboxPosition={this.props.checkboxPosition}
          classList={this.props.classList}
          disabled={this.props.disabled || this.state.isBusy}
          isChecked={this.state.isChecked}
          label={this.props.label}
          onChange={this.onChange}
        />
        {this.props.successMsg && this.state.isCheckedAndSuccessful
        && <p className={`${typography.bodyM} ${typography.bold} ${typography.textCenter} ${layout.marginTop15}`}>{this.props.successMsg}</p>}
      </div>
    );
  }
}

AsyncCheckbox.propTypes = {
  checkboxPosition: PropTypes.string,
  classList: PropTypes.shape({ label: PropTypes.string }),
  disabled: PropTypes.bool,
  initChecked: PropTypes.bool,
  label: PropTypes.string,
  propagateStatus: PropTypes.func,
  request: PropTypes.func.isRequired,
  requestArgs: PropTypes.func,
  successMsg: PropTypes.string,
};

AsyncCheckbox.defaultProps = {
  checkboxPosition: null,
  classList: { label: '' },
  disabled: false,
  initChecked: false,
  label: null,
  propagateStatus: () => {},
  requestArgs: () => {},
  successMsg: null,
};

export default AsyncCheckbox;
