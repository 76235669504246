import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DropZone extends Component {
  constructor(props) {
    super(props);

    this.state = { isDragActive: false };

    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  handleDragLeave(e) {
    this.setState({ isDragActive: false });
  }

  handleDragOver(e) {
    e.preventDefault();
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'copy';
      this.setState({ isDragActive: true });
    }
  }

  handleDrop(e) {
    e.preventDefault();
    this.setState({ isDragActive: false });
    this.props.onDrop(e);
  }

  render() {
    const { children, disabled, getClassName } = this.props;

    return (
      <div
        className={getClassName(!disabled && this.state.isDragActive)}
        onDragLeave={disabled ? null : this.handleDragLeave}
        onDragOver={disabled ? null : this.handleDragOver}
        onDrop={disabled ? null : this.handleDrop}
      >
        {children}
      </div>
    );
  }
}

DropZone.propTypes = {
  disabled: PropTypes.bool,
  getClassName: PropTypes.func,
  onDrop: PropTypes.func,
};

DropZone.defaultProps = {
  disabled: false,
  getClassName: () => '',
  onDrop: () => {},
};

export default DropZone;
