import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DropZone from './DropZone';
import ImageEditor from './ImageEditor';

import processImageFiles, {getImageDimensions} from '../utils/processImageFiles';
import {doesUrlHaveProtocol, isImageValid, isUrlValid} from '../utils/Helpers';

import styles from '../styles/ImageLinkForm.css';
import buttonStyles from '../styles/buttons.css';
import formStyles from '../styles/formElements.css';

class ImageLinkForm extends Component {
  constructor(props) {
    super(props);

    this.handleImageSelection = this.handleImageSelection.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.createImageLink = this.createImageLink.bind(this);

    this.state = {
      delayEditor: !!Object.keys(this.props.image).length,
      image: props.image,
      link: props.link,
      errors: ''
    };

    // Refs.
    this.imageEditor;
  }

  UNSAFE_componentWillMount() {
    if (Object.keys(this.props.image).length) {
      getImageDimensions(this.props.image.url)
        .then(dimensions => this.setState({
          image: {
            ...this.props.image,
            iWidth: dimensions.width,
            iHeight: dimensions.height
          },
          delayEditor: false
        }))
        .catch(err => console.error(err));
    }
  }

  handleImageSelection(e) {
    const files = e.dataTransfer
      ? e.dataTransfer.files
      : e.target.files;

    this.handleFileReader([].slice.call(files));
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 /*ENTER*/) {
      // Prevent form submissions.
      e.preventDefault();
    }
  }

  handleFileReader(files) {
    if (isImageValid(files[0].type)) {
      return processImageFiles(files)
        .then(imageArray => this.setState({image: imageArray[0]}))
        .catch(err => this.props.messenger({open: true, msg: `Error uploading ${files[0].name || 'image'}.`, type: 'error'}));
    } else {
      this.setState({errors: {image: 'Not a valid image!'}});
    }
  }

  createImageLink(e) {
    e.preventDefault();

    if (this.hasErrors()) return;

    if (Object.keys(this.state.image).length) {
      const dimensions = this.imageEditor._getDimensions();

      // The image handler in MainContainer expects the images argument as an array.
      this.props.create(
        [{...this.state.image, ...dimensions, link: this.state.link}],
        'IMAGE_LINK'
      );
      this.props.dismiss();
    }
  }

  hasErrors() {
    const link = this.state.link;

    if (link.length && !isUrlValid(link)) {
      this.setState({errors: 'Sorry, we need a valid link!'});
      return true;
    }

    if (link.length && !doesUrlHaveProtocol(link)) {
      this.setState({errors: 'Make sure to add a protocol to your link! (Example: https://)'});
      return true;
    }

    if (this.state.errors.length) {
      this.setState({errors: ''});
    }

    return false;
  }

  render() {
    const hasImage = Object.keys(this.state.image).length;
    const editingMode = Object.keys(this.props.image).length;

    return (
      <div>
        <section className={styles.linkWrapper}>
          <input
            className={`${formStyles.input} ${this.state.errors.length ? formStyles.borderError : ''}`}
            type="text"
            onChange={(e) => this.setState({link: e.target.value})}
            onKeyDown={this.handleKeyDown}
            placeholder="Enter a link"
            value={this.state.link}
          />
          {
            this.state.errors.length
              ? <small className={formStyles.colorError}>{this.state.errors}</small>
              : null
          }
        </section>

        <section>
          {
            hasImage && !this.state.delayEditor
              ? (
                  <ImageEditor
                    ref={(el) => this.imageEditor = el}
                    image={this.state.image}
                  />
                )
              : (
                  <DropZone
                    onDrop={(files) => this.handleFileReader(files)}
                  >
                    <div
                      className={styles.dropzoneBody}>
                      <span className="fa fa-cloud-upload fa-5x"></span>
                      <strong>Drag Photo Here</strong>
                      <p>or click to <strong>upload</strong></p>
                    </div>
                  </DropZone>
                )
          }
        </section>

        <section className={styles.actionsWrapper}>
          <fieldset>
            <button
              className={`${buttonStyles.btn} ${buttonStyles.btnLink}`}
              onClick={(e) => {
                e.preventDefault();
                this.props.dismiss(e);
              }}>
              Cancel
            </button>
            <button
              className={`${buttonStyles.btn} ${buttonStyles.btnPrimary}`}
              disabled={!hasImage}
              onClick={this.createImageLink}>
              {editingMode ? 'Save changes' : 'Create'}
            </button>
          </fieldset>
        </section>
      </div>
    );
  }
}

ImageLinkForm.propTypes = {
  create: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  image: PropTypes.object,
  link: PropTypes.string,
  messenger: PropTypes.func.isRequired
};

ImageLinkForm.defaultProps = {
  image: {},
  link: ''
};

export default ImageLinkForm;