import React from 'react';
import sanitizer from 'sanitizer';

import EmbedIframe from './EmbedIframe';
import ImageCarousel from '../../../../server/projects/image_carousel/index.js';
import LazyMP4 from './LazyMP4';
import LazyResponsiveIframe from '../../../wrappers/responsive_iframe/LazyResponsiveIframe';

import { extractItemContent, doesCENodeHaveContent } from '../storyJSONHelpers';
import { fireClickedLinkAnalyticsWithRedirect } from '../../../../services/keen/mainServiceOperators';
import { getEmbedConfig } from './embedConfig';
import { processURL } from '../../../image/helpers';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

const CAROUSEL_IMAGE_OPTS = { ratio: '4:3', fit: 'max' };
const HEADLINE_WIDTH = 740;
const LIGHTBOX_WIDTH = 1280;

const ATTR_PROP_MAP = {
  class: 'className',
  href: 'href',
  rel: 'rel',
};

const EMPTY_TAG_WHITELIST = { br: true, hr: true };
const allowEmptyTag = (tagName) => EMPTY_TAG_WHITELIST[tagName] || false;

// convert html attributes to react props.
const attrsToProps = (attrs = {}) => (
  Object.keys(attrs).reduce((acc, key) => {
    const propKey = ATTR_PROP_MAP[key];
    if (!propKey) {
      console.warn(`Warning: encountered unknown attribute ${key}: ${attrs[key]} in storyJSONHelpers attrsToProps. you should probably add it to the whitelist`);

      return acc;
    }

    return { ...acc, [propKey]: attrs[key] };
  }, {})
);

const getHtmlTagChildren = (item, buildHeaderID) => {
  const children = [
    sanitizer.unescapeEntities(item.content),
    ...buildHTML(item.children, buildHeaderID),
  ].filter((c) => c);

  return children.length ? children : null;
};

const buildHTMLTag = ({ buildHeaderID, item, key }) => {
  if (!allowEmptyTag(item.tag) && !doesCENodeHaveContent(item)) return null;
  if (item.tag === 'h3') return buildHeader(item, buildHeaderID);

  return React.createElement(
    item.tag,
    { key, ...attrsToProps(item.attribs), ...getCustomTagProps(item) },
    getHtmlTagChildren(item, buildHeaderID),
  );
};

const buildHeader = (item, buildHeaderID) => {
  const content = extractItemContent(item);
  if (!content) return null;

  const id = buildHeaderID(content);

  // TODO: no fontawesome, no classNames from rails
  return (
    <h3 key={id} className={`${typography.h3} title-with-anchor`} id={id}>
      <a className="anchor" href={`#${id}`}>
        <i className="fa fa-link" />
      </a>
      <span>{content}</span>
    </h3>
  );
};

const getCustomTagProps = (item) => {
  switch (item.tag) {
    case 'a':
      return {
        className: typography.linkBlue,
        onClick: (e) => fireClickedLinkAnalyticsWithRedirect({ location: 'story', type: 'story' }, null, e),
        rel: 'nofollow',
      };
    case 'p':
      return { className: typography.bodyL };
    case 'ul':
      return { className: typography.bodyL };
    default:
      return {};
  }
};

const getEmbedInner = (data) => {
  // MP4 videos go in video elements, not iframes
  if (data.service === 'mp4') return (<LazyMP4 data={data} />);

  // Iframe embeds with special configurations that send/receive messages to set their height
  const config = getEmbedConfig(data);
  if (config) return (<EmbedIframe config={config} data={data} />);

  // All other iframe embeds work fine without messaging at a 16:9 aspect ratio
  // TODO: we can get more specific with defferent embedly sources if we think it's worth it
  return (<LazyResponsiveIframe src={data.embed} verticalOffset={250} />);
};

const remapImageProps = (images) => (
  images.map((image) => ({
    ...image,
    caption: image.figcaption,
    image_urls: {
      headline_url: processURL({ ...CAROUSEL_IMAGE_OPTS, src: image.url, width: HEADLINE_WIDTH }).src,
      lightbox_url: processURL({ ...CAROUSEL_IMAGE_OPTS, src: image.url, width: LIGHTBOX_WIDTH }).src,
    },
  }))
);

/**
 * Exports
 */
export const buildCarousel = (images, uid) => <ImageCarousel images={remapImageProps(images)} uid={uid} />;

export const buildEmbed = (data) => (
  <figure className={`${layout.marginTop30} ${layout.marginBottom30} ${layout.fullWidth}`}>
    {getEmbedInner(data)}
    {!!data.figcaption
    && (
      <figcaption className={`${typography.textCenter} ${typography.pebble} ${typography.bodyS} ${layout.marginTop10}`}>
        {data.figcaption}
      </figcaption>
    )}
  </figure>
);

// based on app/decorators/story_json_decorator.rb#build_html
export const buildHTML = (ce, buildHeaderID) => (
  ce.map((item, i) => buildHTMLTag({ buildHeaderID, item, key: i }))
);
