import smoothScroll from '../../../../../utils/smoothScroll';
import { isBlank } from '../../../../../../utility/types';

const CATEGORY_VALIDATION_DISPLAY_ORDER = ['name', 'prizes', 'leaderboard_url', 'leaderboard_live_date'];
const PRIZE_VALIDATION_DISPLAY_ORDER = ['name', 'link', 'quantity'];

const findFirstFieldForErrorInCategoryPrizes = (prizesErrors) => {
  const firstPrizeKey = Object.keys(prizesErrors)[0];
  const firstPrizeError = prizesErrors[firstPrizeKey];
  const fieldName = Object.keys(firstPrizeError).reduce((acc, key) => {
    if (acc !== null) return acc;
    if (PRIZE_VALIDATION_DISPLAY_ORDER.includes(key)) return key;

    return acc;
  }, null);

  const el = document.querySelector(`[data-prize-${fieldName}="${firstPrizeKey}"]`);
  if (el) smoothScroll(el, 200, null);
};

const findFirstFieldForErrorInCategory = (categoryKey, errors) => {
  const error = errors[categoryKey];
  const fieldName = Object.keys(error).reduce((acc, key) => {
    if (acc !== null) return acc;
    if (CATEGORY_VALIDATION_DISPLAY_ORDER.includes(key)) return key;

    return acc;
  }, null);

  if (fieldName === 'prizes') {
    findFirstFieldForErrorInCategoryPrizes(error.prizes);
  } else {
    const el = document.querySelector(`[data-category-${fieldName}="${categoryKey}"]`);
    if (el) smoothScroll(el, 200, null);
  }
};

export default function scrollToFirstElement(errors, store) {
  const categoryKey = Object.keys(errors)[0];
  const category = store.find((c) => {
    if (!isBlank(c.id)) return c.id.toString() === categoryKey.toString();

    return c.uuid === categoryKey;
  });

  if (category.isCollapsed) {
    const el = document.querySelector(`[data-category-header="${categoryKey}"]`);
    if (el) smoothScroll(el, 200, null);
  } else {
    findFirstFieldForErrorInCategory(categoryKey, errors);
  }
}
