import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CSSTransition } from 'react-transition-group';

import { clickEventHasModifierKey } from '../../../utility/events';

import tranistions from './link_transitions.css';

class Link extends Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = { menuFocused: false, showChildren: false };
  }

  close(e) {
    // NOTE: When a nested dropdown Link is clicked, the event will bubble up through the parent Link.
    // We need to stop propagation here to prevent calling the parental Link's onClick handler.  Otherwise props.onClick
    // will fire two or three times with the parent Links props.  No bueno.
    e.stopPropagation();
    this.setState({ menuFocused: false, showChildren: false });
  }

  handleClick(e) {
    // if it is an external link or has been clicked with a modifier key, use default browser behavior
    if (!(this.props.redirect || clickEventHasModifierKey(e))) {
      e.preventDefault();
      this.props.onClick(this.props.links.internal || this.props.href);
    }
  }

  onMouseLeave(fromChild = false) {
    if (fromChild && this.state.menuFocused) {
      this.setState({ menuFocused: false });
    } else if (this.state.showChildren) {
      this.setState({ showChildren: false });
    }
  }

  render() {
    const { children, classList, href, links, name, zIndex } = this.props;
    const { external = href } = links;

    return (
      <div
        className={classList.root}
        onMouseEnter={() => {
          if (children && !this.state.showChildren) this.setState({ showChildren: true });
        }}
        onMouseLeave={() => {
          if (children && this.state.showChildren) this.onMouseLeave();
        }}
      >
        <a
          className={classList.anchor}
          href={external}
          onClick={this.handleClick}
        >
          {name}
        </a>
        {this.props.children
        && (
          <CSSTransition classNames={tranistions} timeout={{ enter: 500, exit: 300 }}>
            <div>
              {this.state.showChildren
              && (
                <div
                  onClick={(e) => this.close(e)}
                  onMouseEnter={() => this.setState({ menuFocused: true })}
                  onMouseLeave={() => this.onMouseLeave(true)}
                  style={{ position: 'absolute', zIndex: zIndex }}
                >
                  {children}
                </div>
              )}
            </div>
          </CSSTransition>
        )}
      </div>
    );
  }
}

Link.propTypes = {
  classList: PropTypes.shape({
    anchor: PropTypes.string,
    root: PropTypes.string,
  }),
  href: PropTypes.string.isRequired,
  links: PropTypes.shape({
    internal: PropTypes.string,
    external: PropTypes.string,
  }),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  redirect: PropTypes.bool,
  zIndex: PropTypes.number,
};

Link.defaultProps = {
  classList: {
    anchor: '',
    root: '',
  },
  links: {},
  redirect: false,
  zIndex: 1000,
};

export default Link;
