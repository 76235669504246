import Observer, { INTERSECTION_DATA_ATTR } from './Observer';
import { tryJSONStringify } from '../../utility/json';

/*
 * each observer instance is held in this._observers, using a stringified representation of
 * its options as a key. If no observer is available with the requested options, a new one
 * is created. Once no listeners remain on an observer, it destroys itself and is removed
 * from this._observers.
 */

// NOTE: currently this is not set up to work predictably with a root other than null/undefined in options.
class IntersectionObserverService {
  constructor() {
    this._observers = {};
  }

  _getObserver(options = {}) {
    const key = tryJSONStringify(options);
    if (this._observers[key]) return this._observers[key];

    this._observers[key] = new Observer(options, () => this._removeObserver(key));

    return this._observers[key];
  }

  _removeObserver(key) {
    delete this._observers[key];
  }

  observe({ id, target, callback, options }) {
    const observer = this._getObserver(options);

    return observer.observe(target, id, callback);
  }
}

const intersectionObserverService = new IntersectionObserverService();
export default intersectionObserverService;

export { INTERSECTION_DATA_ATTR };
