import request from 'superagent';
import OAuth from '../../../services/oauth';

export function generateCSV(url) {
  return OAuth.apiRequest(
    request(`${OAuth.getApiPath()}${url}`),
    true,
    false,
  );
}

export function getFileDetails(id) {
  return OAuth.apiRequest(
    request(`${OAuth.getApiPath()}/private/files/${id}`),
    true,
    false,
  );
}
