import React from 'react';

import animation from '../../../../styles/animation.css';
import cardStyles from '../../cards.css';
import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from '../news_card.css';

const NewsArticleSponsoredDummyCard = () => (
  <div className={styles.sponsoredCard}>
    <div className={`${styles.sponsoredImgWrapper} ${animation.loader} ${utilStyles.borderRadius}`} />
    <div className={styles.sponsoredDataWrapper}>
      <div className={`${cardStyles.dummyTextL} ${animation.loader}`} />
      <div className={`${cardStyles.dummyTextS} ${animation.loader} ${layout.marginTop5}`} style={{ width: '75%' }} />
    </div>
  </div>
);

export default NewsArticleSponsoredDummyCard;
