import { summonGlobalMessenger } from '../../../../../utility/dispatchers';

export const toggleProcessingOverlay = (apply, selector) => {
  const el = document.querySelector(selector);

  if (el) {
    const className = 'processing';

    if (apply && !el.classList.contains(className)) {
      el.classList.add(className);
    } else if (!apply && el.classList.contains(className)) {
      el.classList.remove(className);
    }
  }
};

export const toggleSubmitButton = (apply, selector) => {
  const panel = document.querySelector(window.location.hash);
  const button = panel ? panel.querySelector(selector) : null;

  if (button) {
    apply ? (button.innerHTML = '<i class="fa fa-circle-o-notch" />') : (button.innerHTML = 'Save changes');
  }
};

export const triggerMessenger = (msg = {}) => {
  summonGlobalMessenger(msg);
};
