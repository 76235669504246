/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { getInObj } from '../../utility/accessors';

/**
 * US
 */
const buildAddressLine2FromRecordUS = (record) => {
  const components = record.components;
  const designator = components.secondary_designator || components.extra_secondary_designator || null;
  const number = components.secondary_number || components.extra_secondary_number || null;

  return designator && number ? `${designator} ${number}` : '';
};

const buildDiffingObjectUS = (address, record) => {
  const primary_number = getInObj(['components', 'primary_number'], record);
  const street_name = getInObj(['components', 'street_name'], record);
  const street_suffix = getInObj(['components', 'street_suffix'], record);
  const address_line1 = primary_number && street_name && street_suffix ? `${primary_number} ${street_name} ${street_suffix}` : '';

  const address_line2 = buildAddressLine2FromRecordUS(record);

  const city = getInObj(['components', 'city_name'], record) || '';
  const state = getInObj(['components', 'state_abbreviation'], record) || '';
  const zip = getInObj(['components', 'zipcode'], record) || '';

  return {
    address_line1: {
      matchesInput: address.address_line1 === address_line1,
      value: address_line1,
    },
    address_line2: {
      matchesInput: address.address_line2 === address_line2,
      value: address_line2,
    },
    city: {
      matchesInput: address.city === city,
      value: city,
    },
    company: { matchesInput: true, value: address.company },
    country: { matchesInput: true, value: address.country },
    country_iso2: { matchesInput: true, value: address.country_iso2 },
    cpf: { matchesInput: true, value: address.cpf },
    default: { matchesInput: true, value: address.default },
    id: { matchesInput: true, value: address.id },
    first_name: { matchesInput: true, value: address.first_name },
    last_name: { matchesInput: true, value: address.last_name },
    phone: { matchesInput: true, value: address.phone },
    state: {
      matchesInput: address.state === state,
      value: state,
    },
    zip: {
      matchesInput: address.zip === zip,
      value: zip,
    },
  };
};

const isVerifiedUS = (record, strict) => {
  if (strict) {
    return (record.analysis.hasOwnProperty('active') && record.analysis.active === 'Y') && !record.analysis.hasOwnProperty('footnotes');
  } else {
    return (record.analysis.hasOwnProperty('active') && record.analysis.active === 'Y');
  }
};

/**
 * INTL
 */
const buildAddyForKeys = (lines, components, keys, ignoreKeys) => lines.reduce((acc, line) => {
  if (acc.length > 0) return acc;
  // Only pull data from one line of formatting at a time.
  line.split(' ').forEach((l) => {
    if (keys.includes(l) && !ignoreKeys.includes(l)) {
      acc = acc.concat(components[l]);
    }
  });

  return acc;
}, []).join(' ');

const buildDiffingObjectINTL = (address, record) => {
  const organization = record.hasOwnProperty('organization') ? { organization: record.organization } : {};
  const components = { ...record.components, organization };
  const addressFormat = record.metadata.address_format;
  const formatLines = addressFormat.replace(/,/g, '').split('|');
  const ignoreKeysForAddressLines = ['administrative_area', 'locality', 'postal_code'];

  const address_line1 = buildAddyForKeys(formatLines, components, formatLines[0], ignoreKeysForAddressLines);
  const address_line2 = buildAddyForKeys(formatLines, components, formatLines[1], ignoreKeysForAddressLines);

  const city = addressFormat.includes('locality') ? components.locality : '';
  const state = addressFormat.includes('administrative_area') ? components.administrative_area : '';
  const zip = components.postal_code || '';

  return {
    address_line1: {
      matchesInput: address.address_line1.toLowerCase() === address_line1.toLowerCase(),
      value: address_line1,
    },
    address_line2: {
      matchesInput: address.address_line2.toLowerCase() === address_line2.toLowerCase(),
      value: address_line2,
    },
    city: {
      matchesInput: address.city.toLowerCase() === city.toLowerCase(),
      value: city,
    },
    company: { matchesInput: true, value: address.company },
    country: { matchesInput: true, value: address.country },
    country_iso2: { matchesInput: true, value: address.country_iso2 },
    cpf: { matchesInput: true, value: address.cpf },
    default: { matchesInput: true, value: address.default },
    id: { matchesInput: true, value: address.id },
    first_name: { matchesInput: true, value: address.first_name },
    last_name: { matchesInput: true, value: address.last_name },
    phone: { matchesInput: true, value: address.phone },
    state: {
      matchesInput: address.state.toLowerCase() === state.toLowerCase(),
      value: state,
    },
    zip: {
      matchesInput: address.zip.toLowerCase() === zip.toLowerCase(),
      value: zip,
    },
  };
};

const doesINTLRecordMatchAddress = (record, address) => {
  const addressFormat = (record.metadata.address_format || []);
  const components = (record.components || {});

  const fields = {
    address_line1: ['premise', 'thoroughfare'].filter((l) => addressFormat.includes(l)).map((l) => components[l]).join(' '),
    address_line2: ['building', 'sub_building'].filter((l) => addressFormat.includes(l)).map((l) => components[l]).join(' '),
    city: addressFormat.includes('locality') ? components.locality : '',
    state: addressFormat.includes('administrative_area') ? components.administrative_area : '',
    zip: addressFormat.includes('postal_code') ? components.postal_code : '',
  };

  return Object.keys(fields).every((field) => fields[field] === address[field]);
};

const isVerifiedINTL = (record, strict, address) => {
  if (strict) {
    return (
      (record.analysis.hasOwnProperty('verification_status') && record.analysis.verification_status === 'Verified')
      && (doesINTLRecordMatchAddress(record, address))
    );
  } else {
    return record.analysis.hasOwnProperty('verification_status') && record.analysis.verification_status === 'Verified';
  }
};

/**
 * Returns the first verified address or the first response object otherwise.
 * @param  {array} apiRes - List of smarty streets api response addresses.
 * @return {object} - Smarty street response object.
 */
export function getBestRecommendation(apiRes, isUS) {
  if (apiRes.length === 1) return apiRes[0];

  const propVal = isUS ? ['active', 'Y'] : ['verification_status', 'Verified'];

  return apiRes.reduce((acc, res, i, list) => {
    if (acc !== null) return acc;

    if (acc === null && i === list.length - 1) {
      acc = list[0];
    } else if (res.analysis && res.analysis[propVal[0]] && res.analysis[propVal[0]] === propVal[1]) {
      acc = res;
    }

    return acc;
  }, null);
}

/**
 * Returns a diffing address used by the VerifyAddressView to render what changed between the user input and the
 * recommended address returned by the api.
 *
 * @param  {object}  address - User inputted address
 * @param  {object}  record - SmartyStreet record
 * @param  {boolean} isUS
 * @return {object}
 */
export function getDiffingAddress(address, record, isUS) {
  return isUS ? buildDiffingObjectUS(address, record) : buildDiffingObjectINTL(address, record);
}

/**
 * When strict is true we're asking the api if the address inputted by the user was a 100% verified address.
 * When strict is false we're asking if the address is deliverable. Its means theres recommended updates to the address.
 *
 * @param  {object}  record - SmartyStreet record
 * @param  {boolean} isUS
 * @param  {boolean} strict
 * @param  {boolean} address - User inputted address
 * @return {boolean}
 */
export function isAddressedVerified(record, isUS, strict, address) {
  if (!record) return false;

  return isUS ? isVerifiedUS(record, strict) : isVerifiedINTL(record, strict, address);
}
