import React from 'react';

import AlgoliaMultiSelect from '../../../../../client/algolia/multi_select';
import ImageUploader from '../../../../../client/form_components/image_uploaders/form_image_uploader';
import Select from '../../../../../client/form_components/select';

import styles from '../../cta_form/cta_form.css';

/**
 * Components using this file:
 * [videos/video_form, ads/ad_form, events/forms/*, news/admin_page/form]
 */
export const createImageUploader = ({ aspectRatio, attachmentType, classList, cropperTitle, dimensionMins, errors, helperText, imageData, label, nestedDialogLevel, propagateStatus, propagateUpload, reportError, showPreview }) => (
  <ImageUploader
    allowGifs={false}
    aspectRatio={aspectRatio}
    attachmentType={attachmentType}
    classList={classList}
    cropperTitle={cropperTitle}
    dimensionMins={dimensionMins}
    errors={errors}
    helperText={helperText}
    imageData={imageData}
    label={label}
    nestedDialogLevel={nestedDialogLevel}
    propagateStatus={propagateStatus}
    propagateUpload={propagateUpload}
    reportError={reportError}
    showPreview={showPreview}
  />
);

export const createCropperTitle = ({ subtitle, title }) => (
  <div className={styles.cropperTitle}>
    <h4>{title}</h4>
    {subtitle && <small>{subtitle}</small>}
  </div>
);

export const createMultiSelect = ({ algoliaParameters = null, algoliaService, errors, maxWidth = 500, onSelect, placeholder, recordsToOptions, selectionLimit = null, value }) => (
  <AlgoliaMultiSelect
    algoliaParameters={algoliaParameters || { hitsPerPage: 100, initFacet: [] }}
    algoliaRecordsToOptions={recordsToOptions}
    algoliaService={algoliaService}
    errors={Array.isArray(errors) ? errors : (typeof errors === 'string' && errors.length > 0) ? [errors] : errors}
    maxWidth={maxWidth}
    onSelect={onSelect}
    placeholder={placeholder || ' '}
    selectionLimit={selectionLimit}
    value={value}
  />
);

export const createSelect = ({ errors, key, maxWidth = 500, onChange, options, placeholder, value }) => (
  <Select
    hasErrors={errors && errors.length > 0}
    maxWidth={maxWidth}
    onSelectedChange={(option) => onChange(key, option)}
    options={options}
    placeholder={placeholder}
    value={value}
  />
);

export const recordsToOptions = (records) => records.map((record) => ({
  label: record.name,
  value: record.id,
}));
