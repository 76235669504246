import { isURL, unescape } from 'validator';

const MENTION_TOKEN = '@';
// eslint-disable-next-line no-useless-escape
const MARKDOWN_URL_REGEXP = /\[([^\[\]]+)\]\(([^)]+)\)?/;

function getStartIndexOfLastWordIfUrl(string) {
  const words = string.split('');
  let lastWord = '';
  let startIndex;

  for (let i = words.length - 1; i >= 0; i--) {
    lastWord = words[i] + lastWord;

    if (words[i] === ' ' || lastWord.includes('http') || lastWord.slice(0, 1) === MENTION_TOKEN || MARKDOWN_URL_REGEXP.test(lastWord)) {
      startIndex = i;
      break;
    }
  }

  return lastWord.length && typeof startIndex === 'number' ? startIndex : -1;
}

function getLengthOfStringUntilEmtpySpaceOrEnd(text, startIndex) {
  const words = text.split('').slice(startIndex, text.length);
  let index;

  for (let i = 0; i <= words.length - 1; i++) {
    if (words[i] === ' ') {
      index = i;
      break;
    } else if (i === words.length - 1) {
      index = words.length;
      break;
    }
  }

  return typeof index === 'number' ? index : -1;
}

export function replaceAllButNums(value, replaceWith = '') {
  return value.replace(/[^0-9]/g, replaceWith);
}

/**
 * We don't want to cut off things like urls for Markdown, (which creates auto links). So we need to parse the text to see if we would
 * be doing so and increase the limit if we are.
 */
export function sliceRichTextAt(textEscaped, limit) {
  const text = unescape(textEscaped);

  if (text.length < limit) return text;

  const fallback = text.slice(0, limit);
  const startIndex = getStartIndexOfLastWordIfUrl(text.slice(0, limit));
  if (startIndex < 0) return fallback;

  const endIndex = getLengthOfStringUntilEmtpySpaceOrEnd(text, startIndex);
  if (endIndex < 0) return fallback;

  const startPlusEndIndex = startIndex + endIndex;
  const lastWord = text.slice(startIndex, startPlusEndIndex);

  return (
    (isURL(lastWord, { require_protocol: true }) || lastWord.slice(0, 1) === MENTION_TOKEN || MARKDOWN_URL_REGEXP.test(lastWord))
    && (startPlusEndIndex > startIndex)
  )
    ? text.slice(0, startPlusEndIndex)
    : fallback;
}

// Equavelent to id_for_toc in app/helpers/project_page_helper.rb
export function toKebabCaseAlphaNumericOnly(string) {
  return string.toLowerCase().trim().replace(/[^a-z0-9]/g, '-');
}
