import React, { Component } from 'react';
import PropTypes from 'prop-types';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import inputStyles from '../../../../styles/global_ui/inputs.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './section_list.css';

class NameCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      title: props.title,
      titleForInput: null,
    };

    this.input;
  }

  _onEditClick() {
    const payload = { index: this.props.index, data: this.props.item };

    switch (this.props.item.key) {
      case 'cta':
        return this.props.toggleView('editCTA', payload);

      case 'promoted':
        return this.props.toggleView('editPromoted', payload);

      case 'video':
        return this.props.toggleView('editVideo', payload);

      default:
        return this.toggleEditingState(true);
    }
  }

  toggleEditingState(bool) {
    this.setState({
      isEditing: bool,
      titleForInput: bool ? this.state.title : null,
    }, () => {
      if (bool && this.input) this.input.focus();
    });
  }

  updateTitle() {
    const updatedTitle = this.state.titleForInput;

    this.props.updateTitle(updatedTitle);
    this.setState({
      isEditing: false,
      title: updatedTitle.length > 0 ? updatedTitle : this.state.title,
      titleForInput: null,
    });
  }

  _supressSubmitOnEnterAndSave(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.updateTitle();
    } else if (e.keyCode === 27) {
      e.preventDefault();
      this.toggleEditingState(false);
    }
  }

  /**
   * Views
   */
  _getTitleView() {
    return this.state.isEditing ? this._getTitleEditor() : this._getTitle();
  }

  _getTitleEditor() {
    return (
      <input
        ref={(el) => this.input = el}
        className={`${inputStyles.input} ${styles.titleInput}`}
        onChange={(e) => this.setState({ titleForInput: e.target.value })}
        onKeyDown={(e) => this._supressSubmitOnEnterAndSave(e)}
        type="text"
        value={this.state.titleForInput}
      />
    );
  }

  _getTitle() {
    return (
      <span className={styles.title}>
        <span>{this.state.title}</span>
        {(this.props.meta && this.props.meta.msg) && <span className={styles.metaMsg}>{this.props.meta.msg}</span>}
      </span>
    );
  }

  _getButtonView() {
    return this.state.isEditing ? this._getEditingButtons() : this._getEditButton();
  }

  _getEditingButtons() {
    return (
      <span className={styles.buttonCntr}>
        <button className={`${buttonStyles.sm} ${styles.saveBtn}`} onClick={() => this.updateTitle()} type="button">Save</button>
        <button className={`${buttonStyles.blank} ${typography.link} ${typography.bodyS} ${styles.saveBtn}`} onClick={() => this.toggleEditingState(false)} type="button">Cancel</button>
      </span>
    );
  }

  _getEditButton() {
    return (
      <span className={styles.buttonCntr}>
        <button className={buttonStyles.blank} onClick={() => this._onEditClick()} type="button">
          <span className={`${typography.bodyS} ${typography.linkBlue} ${typography.bold}`}>Edit</span>
        </button>
      </span>
    );
  }

  render() {
    return (
      <span className={styles.nameCell}>
        {this._getTitleView()}
        {this._getButtonView()}
      </span>
    );
  }
}

NameCell.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({ key: PropTypes.string }).isRequired,
  meta: PropTypes.object,
  title: PropTypes.string.isRequired,
  toggleView: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
};

NameCell.defaultProps = { meta: null };

export default NameCell;
