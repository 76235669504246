import SocialFooterView from './sticky_footer/SocialFooterView';

export default (id) => ({
  1: {
    arrowPosition: 30,
    containerStyle: { marginBottom: 6 },
    msg: 'Share cool stuff or ask for help by using our new Post feature. All posts are public. Click below to get started!',
    position: 'top',
    showIcon: true,
    title: 'Share with the community!',
  },
  2: {
    arrowPosition: 'calc(100% - 32px)',
    containerStyle: { marginTop: -6 },
    msg: 'Switch between your personal feed - posts from people and communities you follow - and global - posts from everyone in the Hackster community.',
    position: 'bottom',
  },
  3: {
    arrowPosition: 30,
    containerStyle: { marginBottom: 6 },
    msg: 'You can now share directly with members of community hubs! Try sharing something cool or asking a question below.',
    position: 'top',
    showIcon: true,
  },
  4: {
    closeMsg: 'Close',
    containerStyle: { marginBottom: 20 },
    msg: 'Would you like to become a beta tester for Hackster and preview new features before everyone else? Apply here: https://goo.gl/forms/tfOAkQFBiZVITT792',
  },
  5: {
    arrowPosition: 195,
    containerStyle: { marginBottom: 6 },
    position: 'top',
    showIcon: true,
    msg: "We've changed the tag system to make it more useful. We want tags to become a convenient way to browse projects that are related by topic. For this reason, we've started consolidating tags into broader categories as well as restricting which tags can be used. If you're a power contributor on Hackster.io with over 1,000 reputation points, you have the ability to create your own tags. Use this wisely!\n\nLearn more: http://help.hackster.io/knowledgebase/posting-a-project/learn-more-about-tags\n\nPlease let us know what you think on hackster.io/feedback!",
    title: 'Tags have changed!',
  },
  6: {
    containerStyle: { marginBottom: 20 },
    showIcon: true,
    msg: "It's never fun when your computer crashes and you lose hours of work. For that reason, we're now auto-saving your story as you type. Note that your updates go live immediately. If you'd prefer not to auto-save, for instance if you're updating a published project, you can toggle off auto-saving in the right sidebar.\n\nPlease let us know what you think on hackster.io/feedback!",
    title: "We've added auto-save to the editor!",
  },
  7: {/* reserved for feed tour */},
  8: {
    msg: SocialFooterView,
    view: 'StickyFooter',
  },
  9: {/* reserved for Arduino Newsletter */},
  10: { view: 'custom' /* One million users banner */ },
  11: {
    containerStyle: { margin: '15px auto 0', maxWidth: 1170, paddingTop: 0 },
    msg: "We've updated our Privacy Policy to include a description of additional rights granted to California residents under the California Consumer Privacy Act (CCPA). [More info](/privacy/ccpa)",
  },
  12: {/* reserved for welcome to your feed */},
  13: {/* reserved for welcome to your feed */},
  14: {/* reserved for welcome to your feed */},
  15: {/* reserved for welcome to your feed */},
  16: {/* reserved for welcome to your feed */},
  17: {/* reserved for welcome to your feed */},
}[id]);
