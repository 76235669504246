import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';

export default class Messenger extends Component {
  constructor(props) {
    super(props);

    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.handleDismiss();
    }, 5000);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  componentDidUpdate(nextProps) {
    if(nextProps.messenger !== this.props.messenger) {
      window.clearTimeout(this.timeout);
      this.timeout = setTimeout(() => { this.handleDismiss(); }, 5000);
    }
  }

  handleDismiss() {
    this.props.setMessenger({'open': false, 'msg': '', 'type': 'error'});
  }

  render() {
    const { messenger } = this.props;

    if(!messenger.get('open')) return null;

    return (
      <CSSTransition
        classNames="messenger"
        appear={true}
        timeout={{
          enter: 500,
          exit: 300
        }}
        >
        <div key={messenger.get('msg')} className="react-editor-messenger">
          <div className={messenger.get('type') === 'success' ? 'alert-success' : 'alert-danger'}>
            <div className="messenger-body">
              <span>{messenger.get('msg')}</span>
              <button className="messenger-close-button" onClick={this.handleDismiss}>×</button>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

Messenger.propTypes = {
  messenger: PropTypes.object.isRequired,
  setMessenger: PropTypes.func.isRequired
};