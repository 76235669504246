import React from 'react';
import PropTypes from 'prop-types';

import cardStyles from '../cards.css';
import layoutStyles from '../../../styles/global_ui/layout.css';
import styles from './video_card.css';

const DummyVideoCard = ({ classList }) => (
  <div className={`${cardStyles.wrapper} ${classList.wrapper}`}>
    <div className={cardStyles.cardWithBorder}>
      <div className={styles.dummyImage} />
      <div className={cardStyles.body}>
        <div className={styles.dummyText} />
        <div className={cardStyles.spacer} />
        <div className={`${layoutStyles.marginTop5} ${styles.dummyText}`} />
      </div>
    </div>
  </div>
);

DummyVideoCard.propTypes = { classList: PropTypes.shape({ wrapper: PropTypes.string }) };

DummyVideoCard.defaultProps = { classList: {} };

export default DummyVideoCard;
