import { CharacterMetadata, ContentBlock, ContentState, EditorState, genKey } from 'draft-js';
import DraftEntity from 'draft-js/lib/DraftEntity';
import { List, fromJS } from 'immutable';

import convertFromHTMLToContentBlocks from './convertFromHTMLToContentBlocks';
import decorator from '../modifiers/decorator';
import ParserUtils from '../parsers/utils';

/**
 * DraftEntity notes:
 * Its still a master Immutable Map. It''ll most likely be deprecated soon.
 * Everywhere the __create or __add method is called, it just appends it to a global Map.
 * Theres not __delete method, only the replace and merge options, but we should use the contentState methods for those.
 */

const CUSTOM_COMPONENT_TYPES = ['CAROUSEL', 'EMBED', 'IMAGE_LINK', 'LEGACY_WIDGET', 'TWEET'];

function createEmptyBlock() {
  return {
    contentBlocks: [new ContentBlock({ key: genKey(), text: '', type: 'unstyled', characterList: List() })],
    entityMap: DraftEntity
  };
}

function createCustomBlock(type, entityObject) {
  // TODO: T15530363 update this when we remove DraftEntity entirely
  const entity = DraftEntity.__create(
    'TOKEN',
    'IMMUTABLE',
    entityObject,
  );
  const charData = CharacterMetadata.create({ entity: entity });
  return {
    contentBlocks: [new ContentBlock({ key: genKey(), text: '', type: type, characterList: List([charData]) })],
    entityMap: DraftEntity
  };
}

export default function convertDataModelToDraftModel(dataModel, restrictions) {
  // Safety net if somehow an empty array is passed through.
  if (!dataModel.length) {
    dataModel = [{type: 'CE', json: []}];
  }

  const blockMap = dataModel.reduce((acc, item, index, list) => {

    if (index === 0 && item.type !== 'CE') {
      acc.push(createEmptyBlock());
    }

    switch(item.type.toUpperCase()) {
      case 'CE':
        return acc.concat(convertFromHTMLToContentBlocks(ParserUtils.toHtml(item.json), restrictions));

      case 'CAROUSEL':
        if(!item.images.length) return acc;
        // Important! Make sure to set the first image to show.
        item.images[0].show = true;
        return acc.concat(createCustomBlock('CAROUSEL', { images: fromJS(item.images) }));

      case 'EMBED':
        const key = item.hasOwnProperty('data') ? 'data' : 'embed';
        const data = item[key];
        return acc.concat(createCustomBlock('EMBED', { embedData: fromJS({ ...data, model: 'Embed', key: key }) }));

      case 'IMAGELINK':
        return acc.concat(createCustomBlock('IMAGE_LINK', { image: fromJS(item.image) }));

      case 'VIDEO':
        const embedData = Array.isArray(item.video) ? item.video[0] : item.video;
        return acc.concat(createCustomBlock('EMBED', { embedData: fromJS({ ...embedData, model: 'Video', key: 'video' }) }));

      case 'FILE':
        return acc.concat(createCustomBlock('LEGACY_WIDGET', { widgetData: fromJS({ ...item.data, model: 'File' }) }));

      case 'WIDGETPLACEHOLDER':
      case 'WIDGETPLACHOLDER':
        // Tweets are placed under WidgetPlaceholder. We route them here.
        return item.data.type === 'twitter'
          ? acc.concat(createCustomBlock('TWEET', { twitterData: fromJS({ ...item.data, model: 'WidgetPlaceholder', type: 'twitter' }) }))
          : acc.concat(createCustomBlock('LEGACY_WIDGET', { widgetData: fromJS({ ...item.data, model: 'WidgetPlaceholder' }) }));

      default:
        return acc;
    }
  }, []).filter(block => block.contentBlocks.length > 0);

  // Starts a fresh draft from scratch, convertFromHTMLToContentBlocks does not allow empty CE types.
  if (!blockMap.length) { blockMap.push(createEmptyBlock()); }

  // Make sure the first block in the map is a paragraph.
  if (CUSTOM_COMPONENT_TYPES.includes(blockMap[0].contentBlocks[0].get('type'))) {
    blockMap.unshift(createEmptyBlock());
  }

  // Make sure the last block in the map is a paragraph.
  if (CUSTOM_COMPONENT_TYPES.includes(blockMap[blockMap.length-1].contentBlocks[blockMap[blockMap.length-1].contentBlocks.length-1].get('type'))) {
    blockMap.push(createEmptyBlock());
  }

  const {contentBlocks, entityMap} = blockMap.reduce((acc, blockData) => {
    return {contentBlocks: acc.contentBlocks.concat(...blockData.contentBlocks), entityMap: acc.entityMap };
  }, {
    contentBlocks: [],
    entityMap: entityMap
  });

  const updatedState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(updatedState, decorator);
}
