import React from 'react';
import PropTypes from 'prop-types';

import { DummyMobileView } from './DummyViews';
import HorizontalScroll from '../../../../client/wrappers/horizontal_scroll';
import LazyImage from '../../../../client/reusable_components/LazyImage';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './promoted_content.css';

const MobileCard = ({ index, item, onClick }) => (
  <div className={styles.mobileCard}>
    <a className={styles.mobileImageWrapper} href={item.link} onClick={(e) => onClick(e, item.link, index)}>
      <LazyImage
        alt={item.image11.name}
        captureScroll={true}
        constrainSize={true}
        ratio="1:1"
        src={item.image11.url}
        width={82}
      />
    </a>
    <div className={styles.mobileCardInfo}>
      <p className={`${typography.h5} ${layout.marginBottom5}`}>{item.contentType.value}</p>
      <a className={`${typography.bodyS} ${typography.link} ${typography.bold} ${layout.block}`} href={item.link}>
        {item.title}
      </a>
    </div>
  </div>
);

const PromotedContentMobileView = ({ content, onClick }) => {
  if (!content.length) return (<DummyMobileView />);

  return (
    <HorizontalScroll
      classList={{ wrapper: styles.horizontalScrollWrapper }}
      recordsCount={1}
      totalRecordsCount={1}
    >
      <div className={styles.mobileView}>
        {content.map((item, i) => (<MobileCard key={i} index={i} item={item} onClick={onClick} />))}
      </div>
    </HorizontalScroll>
  );
};

PromotedContentMobileView.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func.isRequired,
};

export default PromotedContentMobileView;
