import React from 'react';
import PropTypes from 'prop-types';

import Badge from '../../reusable_components/Badge';
import UsersMenu from './UsersMenu';

import urlService from '../../../services/url_service';

import typography from '../../../styles/global_ui/typography.css';
import styles from './project_card.css';

const _getAuthorLink = (author) => (
  <span className={`${styles.author}`}>
    <a
      className={`${typography.bodyS} ${typography.link} `}
      href={urlService.url(author.url)}
    >
      {author.name}
    </a>
    <Badge size="small" user={author} />
  </span>
);

const _getGuestName = (name) => (
  <span className={`${typography.bodyS} ${styles.author}`}>{name}</span>
);

const _getTeamDropdown = (authors, teamName) => (
  <UsersMenu teamName={teamName} users={authors} />
);

const ProjectCardTeam = ({ project }) => {
  if (project.guest_name) return _getGuestName(project.guest_name);

  const authors = project.team.members;
  if (authors.length > 1) return _getTeamDropdown(authors, project.team.name);
  if (authors.length === 1) return _getAuthorLink(authors[0]);

  return null;
};

ProjectCardTeam.propTypes = {
  project: PropTypes.shape({
    content_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    cover_image_url: PropTypes.string,
    difficulty: PropTypes.string,
    edit_url: PropTypes.string,
    guest_name: PropTypes.string,
    hid: PropTypes.string,
    name: PropTypes.string,
    one_liner: PropTypes.string,
    published_state: PropTypes.string,
    respects: PropTypes.number,
    stats: PropTypes.object,
    team: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
};

export default ProjectCardTeam;
