import { cleanPreAndPostSlashes } from '../../utility/urls';

/**
 * When we're writing scenes for whitelabels, we can use this service to cache the rootPath instead of handing the pathHelpers down
 * through props. The setRootPath method must be called in the root parent of each scene to initialize the url helper.
 */
class UrlService {
  constructor() {
    this._rootPath = '/';
  }

  setRootPath(path) {
    this._rootPath = path;
  }

  url(url) {
    if (url === null || typeof url === 'undefined') return url;
    if (url === '/' || url === 'javascript:void(0);') return url;
    if (this._hasProtocol(url)) return url;
    if (this._rootPath === '/') return `/${cleanPreAndPostSlashes(url)}`;

    return `${this._rootPath}/${cleanPreAndPostSlashes(url)}`;
  }

  _hasProtocol(url) {
    return url.includes('http://') || url.includes('https://');
  }
}

const urlService = new UrlService();
export default urlService;
