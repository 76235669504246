import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CategoryMenuBody from './CategoryMenuBody';
import StickyWrapper from '../../wrappers/sticky_wrapper';

import smoothScroll from '../../utils/smoothScroll';
import { NOOP_HREF } from '../../../constants/links';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const scrollToTop = (topAnchorId) => {
  const target = topAnchorId ? document.getElementById(topAnchorId) : 0;
  smoothScroll(target);
};

class StickyCategoryMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { isStuck: false };
  }

  render() {
    const { basePath, categories, className, onClick, parentId, topAnchorId, topBuffer } = this.props;

    return (
      <StickyWrapper
        parentId={parentId}
        reportPosition={(position) => this.setState({ isStuck: position !== 'start' })}
        startBuffer={topBuffer}
        stickToEnd={true}
      >
        <div className={className}>
          <CategoryMenuBody basePath={basePath} categories={categories} onClick={onClick} topAnchorId={topAnchorId} />
          {this.state.isStuck
          && (
            <div className={layout.marginTop15}>
              <a
                className={`${typography.linkPebble} ${typography.bodyS} ${typography.bold}`}
                href={NOOP_HREF}
                onClick={() => scrollToTop(topAnchorId)}
              >
                Return to top
              </a>
            </div>
          )}
        </div>
      </StickyWrapper>
    );
  }
}

StickyCategoryMenu.propTypes = {
  basePath: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  topAnchorId: PropTypes.string,
  topBuffer: PropTypes.number,
};

StickyCategoryMenu.defaultProps = {
  className: '',
  topAnchorId: null,
  topBuffer: 0,
};

export default StickyCategoryMenu;
