import { getInObjWithPrototypeAccess } from '../../../../utility/accessors';
import { isObject } from '../../../../utility/types';

const EMBED_ORIGIN = 'https://embeds.hackster.io';

// Custom injectable to get an iframes height. Currently only used for gists.
const getIframeScript = (id) => `<script>
  window.addEventListener('message', function(event) {
    if (event.data.id === "${id}") {
      var body = document.body, html = document.documentElement;
      var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      window.parent.postMessage({ height: height, id: "${id}" }, "*");
    }
  }, false);
</script>`;

const baseConfig = {
  getIframeSrc: ({ embed }) => embed,
  getHeightFromIframeMessage: (e) => isObject(e.data) ? e.data.height : null,
  sendMessageToIframe: ({ contentWindow, uid }) => contentWindow.postMessage({ id: uid }, '*'),
  width: '100%',
};

const gistConfig = {
  ...baseConfig,
  getIframeSrc: ({ embed, uid }) => `data:text/html;charset=utf-8,<body><script src="${embed}.js"></script>${getIframeScript(uid)}</body>`,
};

const instagramConfig = {
  ...baseConfig,
  getHeightFromIframeMessage: (e) => {
    const data = JSON.parse(e.data);

    return (data && data.type === 'MEASURE') ? getInObjWithPrototypeAccess(['details', 'height'], data) : null;
  },
};

const twitterConfig = {
  ...baseConfig,
  getIframeSrc: ({ embed }) => `${EMBED_ORIGIN}/tweet?url=${embed}`,
  sendMessageToIframe: ({ contentWindow, data }) => {
    contentWindow.postMessage({ element: data.id, query: 'height' }, EMBED_ORIGIN);
  },
  width: 500,
};

export function getEmbedConfig(data) {
  if (data.type === 'twitter') return twitterConfig;
  if (data.service === 'gist') return gistConfig;
  if (data.service === 'instagram') return instagramConfig;

  return null;
}
