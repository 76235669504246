/**
 * Creates an array of OR facets [1 OR 2 OR 3]
 * queryMap: Object of url parameters
 * KNOWN_FACETS: Object of validation function by key and optional property (return true to bypass)
 * ALGOLIA_FACETS: Object of key mappers.  Example: 'part_id' from a url will map to 'parts.id'.
 */
export default function buildFacetsFromQueryMap(queryMap, KNOWN_FACETS = {}, ALGOLIA_FACETS = {}) {
  const keys = Object.keys(queryMap);
  if (!keys.length) return [];

  return keys.reduce((acc, key) => {
    const property = queryMap[key];

    if (KNOWN_FACETS[key] && KNOWN_FACETS[key](property) && property !== null) {
      const algoliaFacet = ALGOLIA_FACETS[key];

      if (typeof algoliaFacet === 'function') {
        acc.push(algoliaFacet(property));
      } else {
        acc.push([`${algoliaFacet}:${property}`]);
      }
    }

    return acc;
  }, []);
}
