import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {genKey} from 'draft-js';

import styles from './dialog.css'
import slideUpTransitions from '../../styles/slideUpTransitions.css';

class Dialog extends Component {
  constructor(props) {
    super(props);

    this.onKeyDown = this.onKeyDown.bind(this);

    // Unique id for this instance.
    this.dataRef = genKey();
  }

  componentDidMount() {
    if (window && document) {
      window.addEventListener('keydown', this.onKeyDown);

      if (this.props.open && !document.body.classList.contains('no-scroll')) document.body.classList.add('no-scroll');
    }
  }

  componentWillUnmount() {
    if (window && document) {
      window.removeEventListener('keydown', this.onKeyDown);
      // Remove the no-scroll class if this dialog is currently the last stacked dialog in the dom.
      if (document.querySelectorAll('[data-nested-dialog]').length === 1 && document.body.classList.contains('no-scroll')) {
        document.body.classList.remove('no-scroll');
      }
    }
  }

  onKeyDown(e) {
    if (!this.props.open) return;

    if (e.keyCode === 27 /*ESC*/) {
      const dialogs = document.querySelectorAll('[data-nested-dialog]');

      if (dialogs.length === 1) return this.dismissByDataRef(dialogs[0]);

      const deepestDialog = [].slice.call(dialogs).reduce((acc, dialog) => {
        const prev = JSON.parse(acc.getAttribute('data-nested-dialog'));
        const curr = JSON.parse(dialog.getAttribute('data-nested-dialog'));
        // If current depth is greater than previous depth.
        return ( parseInt(curr[0], 10) > parseInt(prev[0], 10) ) ? dialog : acc;
      }, [].slice.call(dialogs).shift());

      this.dismissByDataRef(deepestDialog);
    }
  }

  dismissByDataRef(dialog) {
    if (JSON.parse(dialog.getAttribute('data-nested-dialog'))[1] === this.dataRef) this.props.dismiss();
  }

  shouldDismiss(e) {
    if (e.target && e.target.getAttribute('data-ref') && e.target.getAttribute('data-ref') === this.dataRef) {
      this.props.dismiss(e);
    }
  }

  render() {
    const {
      actions,
      actionsContainerStyle,
      bodyClassName,
      bodyStyle,
      children,
      className,
      dismiss,
      dismissStyle,
      enableCloseButton,
      maskStyle,
      nestedDialogLevel,
      open,
      overlayClassName,
      overlayStyle,
      style,
      title,
      titleStyle,
      wrapperClassName,
      wrapperStyle
    } = this.props;

    const titleElement = title && React.isValidElement(title)
      ? title
      : title && typeof title === 'string'
      ? this._buildTitle(title, titleStyle)
      : null;

    const actionsElement = actions.length
      ? this._buildActions(actions, actionsContainerStyle)
      : null;

    return open
      ? (
          <div
            data-ref={this.dataRef}
            data-nested-dialog={JSON.stringify([nestedDialogLevel, this.dataRef])}
            style={style}
            className={`${styles.dialog} ${className}`}
            onClick={(e) => this.shouldDismiss(e)}
            >
            <div
              data-ref={this.dataRef}
              style={overlayStyle}
              className={`${styles.overlay} ${overlayClassName}`}
              >
              <div
                style={{...wrapperStyle, maxWidth: window ? (parseInt(window.innerWidth, 10) * 0.9) : '100%'}}
                className={`${styles.wrapper} ${wrapperClassName} ${slideUpTransitions.translateY}`}
                >
                {
                  enableCloseButton
                    ? <button
                        style={dismissStyle}
                        className={`${styles.dismiss}`}
                        onClick={(e) => {
                          e.preventDefault();
                          dismiss(e);
                        }}
                        >&times;
                      </button>
                    : null
                }
                {titleElement}
                <div style={bodyStyle} className={bodyClassName}>
                  {children}
                </div>
                {actionsElement}
              </div>
            </div>
          </div>
        )
      : (<div></div>);
  }

  _buildTitle(title, style) {
    return (
      <div>
        <h4 style={style} className={styles.title}>{title}</h4>
      </div>
    );
  }

  _buildActions(actions, style) {
    return (
      <div style={style} className={styles.actions}>
        {React.Children.toArray(actions)}
      </div>
    );
  }
}

Dialog.propTypes = {
  actions: PropTypes.array,
  actionsContainerStyle: PropTypes.object,
  bodyClassName: PropTypes.string,
  bodyStyle: PropTypes.object,
  className: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  enableCloseButton: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  overlayClassName: PropTypes.string,
  overlayStyle: PropTypes.object,
  nestedDialogLevel: PropTypes.number,
  style: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  wrapperClassName: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

Dialog.defaultProps = {
  actions: [],
  actionsContainerStyle: {},
  bodyClassName: '',
  bodyStyle: {},
  className: '',
  enableCloseButton: true,
  overlayClassName: '',
  overlayStyle: {},
  nestedDialogLevel: 0,
  style: {},
  wrapperClassName: '',
  wrapperStyle: {}
};

export default Dialog;