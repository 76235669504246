/**
 * Builds out a "negative facets" array for List sections.
 * For view all sections like "platforms" and "topics", we want to ignore certain records if they are already
 * a view all section that preceeds the it section.
 * Basically, "dedupe a platform from platforms because its already been shown as a section".
 */
export function negativeFacetsForList(section, allSections, i, key, algoliaKey) {
  const sectionsBefore = allSections.slice(0, i);

  return sectionsBefore.reduce((acc, section) => section.key === key ? acc.concat(`${algoliaKey}:-${section.value}`) : acc, []);
}
