import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ArticlePageSimpleAd from '../article_page/ArticlePageSimpleAd';
import ContentPageAd, { ContentPageAdPlaceholderView } from '../content_page/ContentPageAd';
import ProjectPageSimpleAd from '../project_page/ProjectPageSimpleAd';

import AdsService from '../../../services/ads';
import errorHandler from '../../../services/error_handler';

import { CONTENT_PAGE } from '../../../graphql/ads/enums';

/**
 * Hypernova mountable. Use when you need the ad to request itself. Meant for singular ads in odd placements.
 * If you need to render multiple ads for a single React scene, do not use this component.
 */
class ModularAd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ad: null,
      initialized: false,
    };

    // Services
    this.adsService = new AdsService(CONTENT_PAGE, props.relations);
  }

  /**
   * Lifecycle Methods
   */
  componentDidMount() {
    this._fetchAd();
  }

  /**
   * Requests
   */
  _fetchAd() {
    return this.adsService.getAdForPage('content')
      .then((ad) => (
        this.setState({
          ad,
          initialized: true,
        })
      ))
      .catch((err) => {
        this.setState({ initialized: true });
        errorHandler(`ModularAd _fetchAds: ${err}`);
      });
  }

  /**
   * Views
   */
  _getAdView() {
    return this.state.ad.ad_type === CONTENT_PAGE ? this._getContentPageView() : this._getBetaAdView();
  }

  _getBetaAdView() {
    return this.props.page_type === 'article_page' ? this._getBetaArticlePageView() : this._getBetaProjectPageView();
  }

  _getBetaArticlePageView() {
    return (
      <ArticlePageSimpleAd
        ad={this.state.ad}
        initialized={this.state.initialized}
      />
    );
  }

  _getBetaProjectPageView() {
    return (
      <ProjectPageSimpleAd
        ad={this.state.ad}
        initialized={this.state.initialized}
      />
    );
  }

  _getContentPageView() {
    return (
      <ContentPageAd
        ad={this.state.ad}
        initialized={this.state.initialized}
        pageType={this.props.page_type}
      />
    );
  }

  _getContentPagePlaceholderView() {
    if (this.state.initialzed) return null;

    return ContentPageAdPlaceholderView(this.props.page_type);
  }

  render() {
    if (!this.state.initialized && !this.state.ad) return this._getContentPagePlaceholderView(); // TODO: Useful until SimpleAds go away.
    if (this.state.initialized && !this.state.ad) return null;

    return this._getAdView();
  }
}

ModularAd.propTypes = {
  page_type: PropTypes.oneOf(['article_page', 'project_page']).isRequired,
  relations: PropTypes.shape({
    platform_ids: PropTypes.arrayOf(PropTypes.number),
    tag_ids: PropTypes.arrayOf(PropTypes.number),
  }),
};

ModularAd.defaultProps = {
  relations: {
    platform_ids: [],
    tag_ids: [],
  },
};

export default ModularAd;
