import { getNavigator } from '../services/window';

const PATTERN = /mobi|android|opera mini/i;
let _isMobile = null;

export default function isMobileBrowser() {
  if (_isMobile === null) {
    const navigatorObj = getNavigator();

    const userAgent = navigatorObj && navigatorObj.userAgent;
    _isMobile = PATTERN.test(userAgent);
  }

  return _isMobile;
}
