import React from 'react';
import PropTypes from 'prop-types';

// HOC
import adAnalyticsTracker from '../wrappers/adAnalyticsTracker';

import Image from '../../image';
import GenericPortal from '../../portals/GenericPortal';

import { adFAQLink } from '../../../constants/links';

import articlePageStyles from '../article_page/article_page_simple_ad.css';
import layout from '../../../styles/global_ui/layout.css';
import projectPageStyles from '../project_page/project_page.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './content_page_ad.css';

import { PORTAL_ID as ARTICLE_PORTAL_ID } from '../../../client/ads/article_page/ArticlePageSimpleAd';
import { PORTAL_ID as PROJECT_PORTAL_ID } from '../../../client/ads/project_page/ProjectPageSimpleAd';

// TODO:
// 1) Move projectPageStyles && projectPageStyles to content_page_ad.css
// 2) Move portal Ids here. The Simple ads will be deprecated

/**
 * Helpers
 */
const _isArticlePage = (pageType) => pageType === 'article_page';

/**
 * Views
 */
const AdView = ({ ad, forwardedRef, onClick, onImageLoad, pageType, initialized }) => {
  if (!initialized) return ContentPageAdPlaceholderView(pageType);
  if (initialized && ad === null) return null;

  return (
    <div ref={forwardedRef} className={layout.marginBottom15}>
      <div>
        <a
          href={ad.link}
          onClick={() => onClick(ad)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            className={utilStyles.borderRadius}
            lazy={true}
            onLoad={() => onImageLoad(ad)}
            ratio="4:3"
            setStyle={false}
            src={ad.cover_image.url}
            width={_isArticlePage(pageType) ? 280 : 270}
          />
        </a>
      </div>

      <div className={`${layout.flexJustifyEnd}`}>
        <a
          className={`${typography.bodyS}`}
          href={adFAQLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Ad
        </a>
      </div>
    </div>
  );
};

export const ContentPageAdPlaceholderView = (pageType) => (
  <div className={`${layout.marginBottom30} ${utilStyles.bgFog} ${utilStyles.borderRadius} ${_isArticlePage(pageType) ? styles.articlePlaceholder : styles.projectPlaceholder}`} />
);

const DefaultView = (props) => {
  if (!props.initialized) return ContentPageAdPlaceholderView(props.pageType);
  if (props.initialized && props.ad === null) return null;

  const isArticlePage = _isArticlePage(props.pageType);

  return (
    <div>
      <div className={layout.hiddenMedDown}>{AdView(props)}</div>

      <GenericPortal id={isArticlePage ? ARTICLE_PORTAL_ID : PROJECT_PORTAL_ID}>
        <div className={`${layout.hiddenMedUp} ${layout.flexCenterItems} ${layout.flexColumn} ${isArticlePage && layout.marginTop30}`}>
          {isArticlePage && DividerView()}

          <div className={`${layout.fullWidth} ${isArticlePage ? styles.articleWrapper : styles.projectWrapper}`}>
            {AdView(props)}
          </div>

          {isArticlePage && DividerView()}
        </div>

        {!isArticlePage && <div className={`project-section-break ${projectPageStyles.portal}`} />}
      </GenericPortal>
    </div>
  );
};

const DividerView = () => (<div className={`${articlePageStyles.divider} ${utilStyles.borderTop}`} />);

const NewsHomePageView = (props) => (
  <div className={layout.flex1}>
    <div className={layout.hiddenMedDown}>{AdView(props)}</div>
    <div className={`${layout.hiddenMedUp} ${layout.flexCenterItems} ${layout.flexColumn}`}>
      <div className={styles.projectWrapper}>
        {AdView(props)}
      </div>
    </div>
  </div>
);

const ContentPageAd = (props) => (
  props.pageType === 'news_home_page' ? NewsHomePageView(props) : DefaultView(props)
);

ContentPageAd.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ad_type: PropTypes.string.isRequired,
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
  }),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  initialized: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onImageLoad: PropTypes.func.isRequired,
  pageType: PropTypes.oneOf(['article_page', 'news_home_page', 'project_page']).isRequired,
};

ContentPageAd.defaultProps = { ad: null };

export default adAnalyticsTracker(ContentPageAd);
