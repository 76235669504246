import React from 'react';
import PropTypes from 'prop-types';

import TooltipTarget from '../../reusable_components/TooltipTarget';

import tooltipStyles from '../../reusable_components/TooltipTarget/tooltip_target.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './question_mark.css';

const QuestionMarkTooltip = ({ offsetX, offsetY, position, tooltip, theme }) => (
  <TooltipTarget
    classList={{ tooltipInner: styles.tooltipInner, wrapper: tooltipStyles.tooltipV2 }}
    multiline={true}
    offsetX={offsetX}
    offsetY={offsetY}
    position={position}
    tooltip={tooltip}
  >
    <span className={`${utilStyles.circle} ${styles[theme]}`}>?</span>
  </TooltipTarget>
);

QuestionMarkTooltip.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  position: PropTypes.string,
  theme: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

QuestionMarkTooltip.defaultProps = {
  offsetX: 0,
  offsetY: 0,
  position: 'right',
  theme: 'light',
};

export default QuestionMarkTooltip;
