import React from 'react';
import PropTypes from 'prop-types';

import typography from '../../styles/global_ui/typography.css';

const Link = ({
  bold,
  children,
  className,
  color,
  href,
  onClick,
  onKeyDown,
  rel,
  size,
  target,
}) => {
  const classNames = `${size ? typography[`body${size}`] : ''} ${typography[`link${color}`]} ${className} ${bold ? typography.bold : ''}`;

  return (
    <a
      className={classNames}
      href={href}
      onClick={onClick}
      onKeyDown={onKeyDown}
      rel={rel}
      target={target}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  bold: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['', 'Blue', 'Charcoal', 'Pebble', 'White']),
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  rel: PropTypes.string,
  size: PropTypes.oneOf(['', 'S', 'M', 'L']),
  target: PropTypes.string,
};

Link.defaultProps = {
  bold: false,
  className: '',
  color: '',
  onKeyDown: () => {},
  onClick: () => {},
  rel: '',
  size: 'M',
  target: null,
};

export default Link;
