import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';

import DismissButton from '../../buttons/dismiss_button';

import dialogTransitions from './transitions.css';
import themes from './themes';

class Dialog extends Component {
  constructor(props) {
    super(props);

    this.state = { open: props.open }; // TODO: just use props instead of state to determine openness?

    this.dismiss = this.dismiss.bind(this);
    this.dismissOnClick = this.dismissOnClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    // Unique id for this instance.
    this.dataRef = uuidv4();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
    if (this.state.open) this._noScrollClassHandler(true);
  }

  // TODO: figure out how to replace this
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open }, () => this._noScrollClassHandler(nextProps.open));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
    // Remove the no-scroll class if this dialog is currently the last stacked dialog in the dom.
    if (document.querySelectorAll('[data-nested-dialog]').length === 1 && document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll');
    }
  }

  onKeyDown(e) {
    // Protects us when parent components mount the Dialog but the instance is currently closed.
    if (!this.state.open) return;

    if (e.keyCode === 27 /* ESC */) {
      // Do not dismiss the dialog if ESC is pressed when an INPUT type is focused.
      if (document && document.activeElement) {
        const activeElement = e.target;
        const attrib = activeElement && activeElement.getAttribute('data-react-type');
        const isInputType = attrib && attrib === 'input';
        if ((activeElement.nodeName && ['input', 'textarea'].includes(activeElement.nodeName.toLowerCase())) || isInputType) {
          return;
        }
      }

      const dialogs = document.querySelectorAll('[data-nested-dialog]');

      if (dialogs.length === 1) return this._dismissByDataRef(dialogs[0]);

      const deepestDialog = [].slice.call(dialogs).reduce((acc, dialog) => {
        const prev = JSON.parse(acc.getAttribute('data-nested-dialog'));
        const curr = JSON.parse(dialog.getAttribute('data-nested-dialog'));
        // If current depth is greater than previous depth.
        return (parseInt(curr[0], 10) > parseInt(prev[0], 10)) ? dialog : acc;
      }, [].slice.call(dialogs).shift());

      this._dismissByDataRef(deepestDialog);
    }
  }

  dismiss() {
    this.props.dismiss();
    this._noScrollClassHandler(false);
    this.setState({ open: false });
  }

  dismissOnClick(e) {
    if (e.target && e.target.getAttribute('data-ref') && e.target.getAttribute('data-ref') === this.dataRef) {
      this.dismiss();
    }
  }

  _dismissByDataRef(dialog) {
    if (JSON.parse(dialog.getAttribute('data-nested-dialog'))[1] === this.dataRef) {
      this.dismiss();
    }
  }

  _noScrollClassHandler(open) {
    if (open && !document.body.classList.contains('no-scroll')) {
      document.body.classList.add('no-scroll');
    } else if (!open && document.querySelectorAll('[data-nested-dialog]').length <= 1 && document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll');
    }
  }

  /**
   * Views
   */
  _getTitle(title, titleStyle, styles) {
    return title && React.isValidElement(title)
      ? title
      : title && typeof title === 'string'
        ? this._buildTitle({ style: titleStyle, styles, title })
        : null;
  }

  _buildTitle({ style, styles, title }) {
    return (
      <div>
        <h4 className={styles.title} style={style}>{title}</h4>
      </div>
    );
  }

  _renderChildren({ children, dataRef }) {
    if (!children) return null;

    return typeof children.type === 'function'
      ? React.cloneElement(children, { dialogRef: dataRef })
      : children;
  }

  render() {
    const {
      actions,
      actionsContainerStyle,
      bodyClassName,
      bodyStyle,
      children,
      className,
      dismissClassName,
      dismissStyle,
      enableCloseButton,
      fullScreen,
      message,
      nestedDialogLevel,
      style,
      theme,
      title,
      titleStyle,
      transition,
      wrapperClassName,
      wrapperStyle,
    } = this.props;

    const styles = themes[theme];

    return (
      <TransitionGroup appear={true}>
        {this.state.open
        && (
          <CSSTransition classNames={transition} timeout={250}>
            <div
              className={`${styles.dialog} ${className}`}
              data-nested-dialog={JSON.stringify([nestedDialogLevel, this.dataRef])}
              data-ref={this.dataRef}
              onClick={this.dismissOnClick}
              style={style}
            >
              <div
                className={`${this.state.open ? styles.wrapper : ''} ${wrapperClassName} ${fullScreen ? styles.fullScreen : ''}`}
                style={wrapperStyle}
              >
                {enableCloseButton
                && (
                  <DismissButton
                    className={`${styles.dismiss} ${dismissClassName}`}
                    fullscreen={fullScreen}
                    onClick={this.dismiss}
                    style={dismissStyle}
                  />
                )}

                {this._getTitle(title, titleStyle, styles)}

                <div className={bodyClassName} style={bodyStyle}>
                  {this._renderChildren({ children, dataRef: this.dataRef })}
                </div>

                <div>
                  {actions && Array.isArray(actions) && actions.length > 0
                  && (
                    <div className={styles.actions} style={actionsContainerStyle}>
                      {React.Children.toArray(actions)}
                    </div>
                  )}
                  {message}
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
}

Dialog.propTypes = {
  actions: PropTypes.array,
  actionsContainerStyle: PropTypes.object,
  bodyClassName: PropTypes.string, // TODO: use a classList prop, and maybe get rid of the style props
  bodyStyle: PropTypes.object,
  className: PropTypes.string,
  dismiss: PropTypes.func,
  dismissClassName: PropTypes.string,
  enableCloseButton: PropTypes.bool,
  fullScreen: PropTypes.bool,
  message: PropTypes.element,
  nestedDialogLevel: PropTypes.number,
  open: PropTypes.bool,
  style: PropTypes.object,
  theme: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  transition: PropTypes.object,
  wrapperClassName: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

Dialog.defaultProps = {
  actions: [],
  actionsContainerStyle: {},
  bodyClassName: '',
  bodyStyle: {},
  className: '',
  dismiss: () => {},
  dismissClassName: '',
  enableCloseButton: true,
  fullScreen: false,
  message: null,
  open: false,
  nestedDialogLevel: 0,
  style: {},
  theme: 'light',
  transition: dialogTransitions,
  wrapperClassName: '',
  wrapperStyle: {},
};

export default Dialog;
