import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../icon';

import { DELETE, DOWN, LEFT, RIGHT, UP } from '../../../../../constants/keyCodes';
import { clamp } from '../../../../../utility/math';
import { documentActiveElement } from '../../../../../utility/document';
import { isAFunction, isBlank } from '../../../../../utility/types';
import { replaceAllButNums } from '../../../../../utility/strings';
import { transformObjValues } from '../../../../../utility/converters';
import { zeroPadValue } from '../datetimeInputHelpers';

import inputStyles from '../../../../../styles/global_ui/inputs.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import styles from '../datetime.css';

class DesktopInputWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      values: this._initValues(props),
      wrapperHasFocus: false,
    };

    this.handleArrowMouseDown = this.handleArrowMouseDown.bind(this);
    this.handleClearMouseDown = this.handleClearMouseDown.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleNumberBlur = this.handleNumberBlur.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleWrapperClick = this.handleWrapperClick.bind(this);

    // create an array of refs, one for each input. These are used to manage focus and selection
    this._inputRefs = Object.keys(props.inputConfig).map(() => React.createRef());
  }

  /**
   * Initializers
   */
  _initValues({ initValue, inputConfig, parseValueString }) {
    const parsedValues = parseValueString(initValue);

    return transformObjValues(parsedValues, (val, key) => (
      (isBlank(val) && inputConfig[key].defaultValue) ? inputConfig[key].defaultValue : val
    ));
  }

  /**
   * Methods
   */
  handleArrowMouseDown(e, keyCode) {
    e.preventDefault();

    const input = this._getOrFocusCurrentInput(this._inputRefs);
    this._stepInputValue(input, keyCode);
  }

  handleClearMouseDown(e) {
    e.preventDefault();
    this._setValues(transformObjValues(this.state.values, () => ''));
  }

  handleInputKeyDown(e) {
    if (e.keyCode === DELETE) {
      e.preventDefault();

      return this._setValues({ [e.target.name]: '' });
    }

    if ([LEFT, RIGHT].includes(e.keyCode)) {
      e.preventDefault();

      return this._focusNextOrPreviousInput(this._inputRefs, e.target, (e.keyCode === LEFT ? -1 : 1));
    }

    if ([UP, DOWN].includes(e.keyCode)) {
      e.preventDefault();

      return this._stepInputValue(e.target, e.keyCode);
    }

    const config = this.props.inputConfig[e.target.name];
    const customValue = isAFunction(config.getValueFromKeyDown) ? config.getValueFromKeyDown(e) : null;
    if (customValue !== null) this._setValues({ [e.target.name]: customValue });
  }

  handleNumberBlur(e) {
    const { name, value } = e.target;
    if (value === '') return;

    const { max, maxLength, min } = this.props.inputConfig[name];
    const num = this._processNumberInput(value);

    // TODO:the native chrome time input converts the hour field from 24hr time instead of clamping to min/max
    // .e.g. when you type 15, it will change to 03 and auto-advance. Do we want this?
    const clamped = clamp(min, num, max);

    if (num !== clamped) {
      this._setValues({ [name]: zeroPadValue(clamped, maxLength) });
    }
  }

  handleNumberChange(e) {
    const { name, value } = e.target;
    const { max, maxLength } = this.props.inputConfig[name];

    const num = this._processNumberInput(value);

    if (num === null) return this._setValues({ [name]: '' });

    this._setValues({ [name]: zeroPadValue(num, maxLength) });

    // auto-advance to next field because anything else typed into the input will be over the max
    if (num >= max / 10) this._focusNextOrPreviousInput(this._inputRefs, e.target, 1);
  }

  handleWrapperClick(e) {
    if ((e.target !== e.currentTarget) || this.state.wrapperHasFocus || this.props.disabled) return;
    this._inputRefs[0].current.focus();
  }

  /**
   * Helpers
   */
  _areAllInputsBlank() {
    return Object.keys(this.state.values).every((key) => this.state.values[key] === '');
  }

  _focusNextOrPreviousInput(inputRefs, currentFocusedInput, direction = 1) {
    const inputIndex = inputRefs.findIndex((ref) => ref.current === currentFocusedInput);
    const newIndex = inputIndex + direction;
    if (inputRefs[newIndex]) inputRefs[newIndex].current.focus();
  }

  _getOrFocusCurrentInput(inputRefs) {
    const inputRef = inputRefs.find((ref) => ref.current === documentActiveElement());

    // if an input is currently focused, return it
    if (inputRef) return inputRef.current;

    // otherwise, focus and return the first input in the component
    inputRefs[0].current.focus();

    return inputRefs[0].current;
  }

  _getWrapperClassName() {
    if (this.props.disabled) return styles.wrapperDisabled;
    if (this.props.errors) return inputStyles.inputError;
    if (this.state.wrapperHasFocus) return inputStyles.inputFocus;

    return '';
  }

  _processNumberInput(value) {
    const cleaned = replaceAllButNums(value);
    if (cleaned.length === 0) return null;

    return parseInt(cleaned);
  }

  _propagateValue(values, cb = () => {}) {
    this.props.onChange(this.props.formatOut(values));
    cb();
  }

  _setValues(newValues, cb) {
    this.setState({ values: { ...this.state.values, ...newValues } }, () => this._propagateValue(this.state.values, cb));
  }

  _stepInputValue(input, keyCode) {
    const config = this.props.inputConfig[input.name];
    const newVal = config.getSteppedValue(input.value, keyCode, config);

    this._setValues({ [input.name]: newVal }, () => input.select());
  }

  render() {
    return (
      <div
        className={`${styles.wrapperDesktop} ${inputStyles.input} ${this._getWrapperClassName()}`}
        onBlur={() => this.setState({ wrapperHasFocus: false })}
        onClick={this.handleWrapperClick}
        onFocus={() => this.setState({ wrapperHasFocus: true })}
      >
        <div className={`${layout.inlineFlex} ${layout.margin10}`}>
          {this.props.children({
            _inputRefs: this._inputRefs,
            handleInputKeyDown: this.handleInputKeyDown,
            handleNumberBlur: this.handleNumberBlur,
            handleNumberChange: this.handleNumberChange,
            values: this.state.values,
          })}
        </div>
        <div className={`${styles.buttons} ${layout.inlineFlex} ${layout.marginRight10}`}>
          <div
            className={`${typography.textWithIcon} ${styles.clear} ${layout.marginRight5} ${this._areAllInputsBlank() ? utilStyles.hidden : ''}`}
            onMouseDown={this.handleClearMouseDown}
          >
            <Icon name="close" size={12} />
          </div>
          <div className={styles.arrowButtons}>
            <div className={typography.textWithIcon} onMouseDown={(e) => this.handleArrowMouseDown(e, UP)}>
              <Icon className={styles.toggle} name="arrow-up" size={12} />
            </div>
            <div className={typography.textWithIcon} onMouseDown={(e) => this.handleArrowMouseDown(e, DOWN)}>
              <Icon className={styles.toggle} name="arrow-down" size={12} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DesktopInputWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  formatOut: PropTypes.func.isRequired,
  initValue: PropTypes.string,
  inputConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  parseValueString: PropTypes.func.isRequired,
};

DesktopInputWrapper.defaultProps = {
  disabled: false,
  errors: null,
  initValue: '',
  onChange: () => {},
};

export default DesktopInputWrapper;
