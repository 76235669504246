import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CustomIcon from '../../../../client/icon/custom';

import IconOptionsMenu from '../templates/IconOptionsMenu';
import LazyBackgroundImage from '../../../../client/image/LazyBackgroundImage';

import { DRAFT_STATUS, PUBLISHED_STATUS, SUBMITTED_STATUS } from '../../../../graphql/news/enums';
import { DATE_FORMAT, NEWS_SCHEDULED_FORMAT, timeIsFuture, timestampToPrettyDate, timestampToRelativeTime } from '../../../../utility/time';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './news_admin_list.css';

class NewsArticleListItem extends PureComponent {
  constructor(props) {
    super(props);
  }

  /**
   * Helpers
   */
  _getStatusData() {
    switch (this.props.article.status) {
      case DRAFT_STATUS:
        return { classColor: utilStyles.categoryGrayBG, text: `Draft saved ${timestampToRelativeTime(this.props.article.updated_at)}` };

      case PUBLISHED_STATUS: {
        const published_at = this.props.article.published_at;
        const isScheduled = timeIsFuture(published_at);

        return isScheduled
          ? { classColor: utilStyles.categoryGreenBG, text: `Scheduled for ${timestampToPrettyDate(published_at, NEWS_SCHEDULED_FORMAT)}` }
          : { classColor: null, text: `Published on ${timestampToPrettyDate(published_at, DATE_FORMAT)}` };
      }
      case SUBMITTED_STATUS:
        // NOTE: We dont have a submitted_at date, its slightly buggy since this date will be updated if the submitted article is updated past
        // the workflow state change. The sort is also effected by this.
        return { classColor: utilStyles.categoryOrangeBG, text: `Submitted on ${timestampToPrettyDate(this.props.article.updated_at, DATE_FORMAT)}` };

      default:
        return { classColor: null, text: 'Error' };
    }
  }

  /**
   * Views
   */
  _getStatusView() {
    const { classColor, text } = this._getStatusData();

    return (
      <div className={layout.flexCenterItems}>
        {classColor && <div className={`${utilStyles.categoryBadgeRectSmall} ${layout.marginRight10} ${classColor} ${styles.statusColorBlock}`} />}
        <span className={`${typography.bodyS}`}>{text}</span>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.listItem}>
        <div className={styles.listItemTitleCell}>
          {this.props.article.image
          && (
            <a className={styles.listItemCoverImageWrapper} href={this.props.article.url} rel="noopener noreferrer" target="_blank">
              <LazyBackgroundImage
                classList={{ image: utilStyles.borderRadius, root: styles.listItemCoverImage }}
                ratio="16:9"
                setStyle={false}
                src={this.props.article.image.url}
                width={96}
              />
            </a>
          )}
          <div className={styles.listItemData}>
            {this.props.article.sponsored && <span className={`${typography.bodyS} ${typography.pebble} ${layout.marginRight10}`}>Sponsored</span>}

            <a
              className={`${typography.link} ${typography.bodyM} ${typography.bold}`}
              href={this.props.article.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {this.props.article.title}
            </a>

            {this.props.isFeaturedOnNewsPage && <CustomIcon className={styles.star} name="star" size={16} />}

            <div className={styles.listItemMobileAuthorStatus}>
              {this.props.isAdminOrEditor
              && <p className={`${typography.bodyS} ${layout.marginBottom5}`}>{this.props.article.user.name}</p>}
              {this._getStatusView()}
            </div>
          </div>
        </div>

        {this.props.isAdminOrEditor
        && <div className={styles.listItemAuthorCell}><p className={typography.bodyS}>{this.props.article.user.name}</p></div>}
        <div className={styles.listItemDateCell}>
          {this._getStatusView()}
        </div>

        <IconOptionsMenu
          article={this.props.article}
          deleteArticle={this.props.deleteArticle}
          isAdminOrEditor={this.props.isAdminOrEditor}
          redirectToArticleEdit={this.props.redirectToArticleEdit}
          redirectToArticleStats={this.props.redirectToArticleStats}
          type="list"
          unpublishArticle={this.props.unpublishArticle}
        />
      </div>
    );
  }
}

NewsArticleListItem.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    }),
    published_at: PropTypes.string,
    sponsored: PropTypes.bool,
    status: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Variable for Date.now and Rails formats.
    url: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  deleteArticle: PropTypes.func.isRequired,
  isAdminOrEditor: PropTypes.bool.isRequired,
  isFeaturedOnNewsPage: PropTypes.bool.isRequired,
  redirectToArticleEdit: PropTypes.func.isRequired,
  redirectToArticleStats: PropTypes.func.isRequired,
  unpublishArticle: PropTypes.func.isRequired,
};

NewsArticleListItem.defaultProps = {};

export default NewsArticleListItem;
