import React from 'react';
import PropTypes from 'prop-types';

import LazyBackgroundImage from '../../../image/LazyBackgroundImage';

import { getInObj } from '../../../../utility/accessors';
import { getImgContainerStyles, getRootStyles, getTitleStyles } from './styleHelpers';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const getSummaryPlaceholder = () => (
  <div>
    <p className={typography.dummyS}></p>
    <p className={typography.dummyS} style={{ width: '50%' }}></p>
  </div>
);

const NewsArticleFeaturedCardPreview = ({ article, isHero }) => (
  <div className={getRootStyles(isHero)}>
    <div className={getImgContainerStyles(isHero)}>
      <LazyBackgroundImage
        classList={{ root: `${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}` }}
        ratio="16:9"
        setStyle={false}
        src={getInObj(['image', 'url'], article)}
        width={555}
      />
    </div>
    <div className={`${layout.flexColumn} ${layout.fullWidth}`}>
      <div className={getTitleStyles(isHero)}>
        {article.title}
      </div>
      {(article.summary && article.summary.length > 0)
        ? <p className={`${typography.bodyM} ${typography.charcoal} ${layout.marginBottom10}`}>{article.summary}</p>
        : getSummaryPlaceholder()}
      <p className={`${typography.charcoal} ${typography.bodyM}`}>
        {'by '}
        <span className={typography.linkBlue}>
          {article.user.name}
        </span>
      </p>
    </div>
  </div>
);

NewsArticleFeaturedCardPreview.propTypes = {
  article: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string }),
    summary: PropTypes.string,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  isHero: PropTypes.bool,
};

NewsArticleFeaturedCardPreview.defaultProps = { isHero: false };

export default NewsArticleFeaturedCardPreview;
