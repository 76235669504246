import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Editor, EditorState} from 'draft-js';
import {List} from 'immutable';

import customKeyBindings from '../modifiers/customKeyBindings';
import customKeyHandlers from '../modifiers/customKeyHandlers';
import customBlockRenderer from '../modifiers/customBlockRenderer';
import customBlockRenderMap from '../modifiers/customBlockRenderMap';
import customBlockStyleFn from '../modifiers/customBlockStyleFn';
import customBeforeInputHandler from '../modifiers/customBeforeInputHandlers';
import customReturnHandler from '../modifiers/customReturnHandler';
import customArrowKeysHandler from '../modifiers/customArrowKeysHandler';
import customPasteHandler from '../modifiers/customPasteHandler';
import styleMap from '../modifiers/styleMap';

import {currentCharContainsLink} from '../utils/draftUtils';
import {getInlineStylesAndEntity} from '../utils/draft/getters';

import LinkMenu from './LinkMenu';

class Draft extends Component {
  constructor(props) {
    super(props);

    this.focus = () => this.editor.focus();
    this.setDraftState = this.setDraftState.bind(this);
    this.onDraftChange = this.onDraftChange.bind(this);
    this.toggleReadOnly = this.toggleReadOnly.bind(this);

    // Draft config.
    this.customBlockRenderMap = customBlockRenderMap();

    // Refs.
    this.editor;
  }

  onDraftChange(editorState) {
    this.props.onDraftChange(editorState);
  }

  setDraftState(editorState, readOnly) {
    this.props.setDraft(editorState, readOnly);
  }

  toggleReadOnly(bool) {
    if (bool !== this.props.readOnly) {
      this.props.toggleReadOnly(bool);
    }
  }

  replaceEntityData(entityKey, data) {
    const currentState = this.props.draftState;
    const contentState = currentState.getCurrentContent().replaceEntityData(entityKey, data);
    this.setDraftState(EditorState.push(currentState, contentState));
  }

  render() {
    const {blockRestrictions, draftState, hasFocus, instanceId, isDialogOpen, readOnly, setMessenger} = this.props;
    const selection = draftState.getSelection();

    return (
      <div style={{ position: 'relative' }}>
        <Editor
          ref={(el) => this.editor = el}
          className="content-editable"
          editorState={draftState}
          customStyleMap={styleMap}
          blockRendererFn={customBlockRenderer.bind(this, this)}
          blockRenderMap={this.customBlockRenderMap}
          blockStyleFn={customBlockStyleFn}
          handleBeforeInput={char => customBeforeInputHandler.call(this, draftState, char, this.setDraftState, this.props.activeMetadata.get('activeStyles'))}
          handleDrop={() => true}
          handleDroppedFiles={(sel, files) => this.props.onImageUpload(files)}
          handleKeyCommand={cmd => customKeyHandlers.call(this, cmd, draftState, this.setDraftState, this.props.toggleActiveStyle)}
          handlePastedFiles={(files) => this.props.onImageUpload(files)}
          handlePastedText={customPasteHandler.bind(this, draftState, this.setDraftState, blockRestrictions)}
          handleReturn={customReturnHandler.bind(this, draftState, this.setDraftState)}
          keyBindingFn={customKeyBindings}
          onChange={this.onDraftChange}
          onDownArrow={customArrowKeysHandler.bind(this, 'DOWN', draftState, this.setDraftState)}
          onFocus={this.props.onDraftFocus}
          readOnly={readOnly}
          spellCheck={true}
          suppressContentEditableWarning={true}
        />
        {!!currentCharContainsLink(draftState) && selection.isCollapsed() && hasFocus && !isDialogOpen &&
          <LinkMenu
            editorState={draftState}
            instanceId={instanceId}
            onChange={this.setDraftState}
            onInvalidUrl={msg => setMessenger({open: true, msg: msg, type: 'error'})}
            readOnly={readOnly}
            toggleReadOnly={this.toggleReadOnly}
            />
        }
      </div>
    );
  }
}

Draft.propTypes = {
  blockRestrictions: PropTypes.object.isRequired,
  draftState: PropTypes.object.isRequired,
  hasFocus: PropTypes.bool.isRequired,
  instanceId: PropTypes.string.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  onDraftChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  processImage: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setDraft: PropTypes.func.isRequired,
  setMessenger: PropTypes.func.isRequired,
  setUnsavedChanges: PropTypes.func.isRequired,
  toggleActiveStyle: PropTypes.func.isRequired,
  toggleDialogStatus: PropTypes.func.isRequired,
  toggleReadOnly: PropTypes.func.isRequired,
  uploadImages: PropTypes.func.isRequired
};

Draft.defaultProps = {
  blockRestrictions: List()
};

export default Draft;