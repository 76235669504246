import React from 'react';
import PropTypes from 'prop-types';
import IFrame from '../../utility_components/IFrame';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

const ContestEmail = ({ body, subject }) => (
  <div>
    {subject
    && (
      <div className={`${layout.marginBottom15} ${layout.marginLeft15} ${utilStyles.borderBottom}`}>
        <p className={`${typography.bodyM} ${typography.bold}`}>
          <span>Subject: </span>
          <span>{subject}</span>
        </p>
      </div>
    )}

    <IFrame html={body} minWidth={600} />
  </div>
);

ContestEmail.propTypes = {
  body: PropTypes.string,
  subject: PropTypes.string,
};

ContestEmail.defaultProps = {
  body: '<div>You forgot to add a body prop!</div>',
  subject: '',
};

export default ContestEmail;
