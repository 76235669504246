import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import UserCard from '../../../../../client/cards/user_card';
import UserRelationButton from '../../../../../server/user/relation_button';

import joinComponents from '../../../../../client/utility_components/joinComponents';

import { getInObj } from '../../../../../utility/accessors';
import { isBlank } from '../../../../../utility/types';

import { timestampToPrettyDate, timestampToRelativeTime } from '../../../../../utility/time';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';

const _getSponsorAsUser = ({ sponsor_image, sponsor_link, sponsor_name }) => ({
  avatar_url: getInObj(['url'], sponsor_image),
  name: sponsor_name,
  url: sponsor_link,
});

const _getLink = ({ name, url }) => (
  <a className={typography.linkPebble} href={url}>{name}</a>
);

const _getTopics = (topics) => (
  <Fragment>
    {' • '}
    {joinComponents(topics.map(_getLink), ' / ')}
  </Fragment>
);

const _getFollowButton = (user) => (
  <span className={layout.marginLeftAuto}>
    <UserRelationButton
      id={user.id}
      type="followed_user_xs"
    />
  </span>
);

const ArticleHeader = ({ article }) => (
  <header>
    <h1 className={`${typography.h1Responsive} ${typography.breakWord}`}>{article.title}</h1>
    <h2 className={`${typography.h3} ${typography.fontWeightNormal} ${typography.pebble} ${layout.marginTop15}`}>{article.summary}</h2>
    <UserCard
      avatarMargin={15}
      avatarSize={56}
      className={layout.marginTop30}
      postTitle={article.sponsored ? null : _getFollowButton(article.user)}
      preTitle={article.sponsored ? <span className={`${typography.bodyL} ${typography.bold}`}>{'Sponsored by '}</span> : null}
      titleColor={article.sponsored ? 'Blue' : ''}
      titleSize="L"
      user={article.sponsored ? _getSponsorAsUser(article) : article.user}
    >
      <div className={`${typography.bodyS} ${typography.pebble}`}>
        <span title={timestampToPrettyDate(article.published_at || article.updated_at)}>{timestampToRelativeTime(article.published_at || article.updated_at)}</span>
        {!isBlank(article.topics) && _getTopics(article.topics)}
      </div>
    </UserCard>
  </header>
);

ArticleHeader.propTypes = {
  article: PropTypes.shape({
    published_at: PropTypes.string,
    sponsor_image: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
    sponsor_link: PropTypes.string,
    sponsor_name: PropTypes.string,
    sponsored: PropTypes.bool,
    title: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    updated_at: PropTypes.string,
    user: PropTypes.object,
  }),
};

export default ArticleHeader;
