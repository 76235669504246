const CONFIG = {
  '/': (list = {}) => ({
    entity_id: list.id,
    meta_desc: `Hardware projects in ${list.name}.`,
    pageType: 'bookmarks#show',
    title: `${list.name} - Hackster.io`,
  }),
};

export default (path) => CONFIG[path];
