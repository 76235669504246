import smoothScroll from '../../../client/utils/smoothScroll';
import { addQueryParamsToURL } from '../../../utility/urls';
import { IMG, VIDEO, canConvertToMP4 } from '../../../client/image/helpers';

const VIDEO_PARAMS = { fm: 'mp4', auto: 'compress' };

export function cycleIndex(index, limit) {
  return index > limit
    ? 0
    : index < 0
      ? limit
      : index;
}

export function getCycledArray(array, index) {
  if (!Array.isArray(array) || array.length === 0) {
    return [];
  } else if (array.length === 1) {
    return array;
  } else if (array.length === 2) {
    return index === 0 ? [array[1], array[0], array[1]] : [array[0], array[1], array[0]];
  } else {
    return index === array.length - 1
      ? [array[array.length - 2], array[array.length - 1], array[0]]
      : index === 0
        ? [array[array.length - 1], array[0], array[1]]
        : [array[index - 1], array[index], array[index + 1]];
  }
}

export function gifToVideo(image) {
  const { headline_url, lightbox_url } = image.image_urls;

  if (!canConvertToMP4(headline_url)) return { ...image, format: IMG };

  return {
    ...image,
    format: VIDEO,
    image_urls: {
      headline_url: addQueryParamsToURL(headline_url, VIDEO_PARAMS),
      lightbox_url: addQueryParamsToURL(lightbox_url, VIDEO_PARAMS),
    },
  };
}

export const updateActionsOnScroll = {
  left: (instance) => {
    const index = instance.props.activeIndex - 1;
    instance.preventScrollAndUpdate(() => instance.updateActiveIndex(index));
  },
  right: (instance) => {
    const index = instance.props.activeIndex + 1;
    instance.preventScrollAndUpdate(() => instance.updateActiveIndex(index));
  },
  snapLeft: (instance) => {
    instance.preventScrollAndUpdate(
      () => smoothScroll(0, 200, () => instance.updateActiveIndex(instance.props.activeIndex - 1), instance._scrollContainer, true),
    );
  },
  center: (instance) => {
    const width = Math.floor(instance._scrollContainer.clientWidth);
    instance.preventScrollAndUpdate(
      () => smoothScroll(width, 200, () => instance.updateState({ noScroll: false }), instance._scrollContainer, true),
    );
  },
  snapRight: (instance) => {
    const width = Math.floor(instance._scrollContainer.clientWidth);
    instance.preventScrollAndUpdate(
      () => smoothScroll(width * 2, 200, () => instance.updateActiveIndex(instance.props.activeIndex + 1), instance._scrollContainer, true),
    );
  },
  eagerUpdateRight: (instance) => {
    if (instance.state.activeIndex !== instance.props.activeIndex) return;
    const index = cycleIndex(instance.props.activeIndex + 1, instance.props.images.length - 1);
    instance.updateState({ activeIndex: index, loadedImageIndexes: { ...instance.state.loadedImageIndexes, [index]: true } });
  },
  eagerUpdateLeft: (instance) => {
    if (instance.state.activeIndex !== instance.props.activeIndex) return;
    const index = cycleIndex(instance.props.activeIndex - 1, instance.props.images.length - 1);
    instance.updateState({ activeIndex: index, loadedImageIndexes: { ...instance.state.loadedImageIndexes, [index]: true } });
  },
};
