import { A, P, UP } from '../../../../constants/keyCodes';
import { isADate } from '../../../../utility/types';

const EMPTY_DATE_VALUES = {
  day: '',
  month: '',
  year: '',
};
const EMPTY_TIME_VALUES = {
  ampm: '',
  hour: '',
  minute: '',
};
const TIME_INPUT_FORMAT = { hour: '2-digit', hourCycle: 'h23', minute: '2-digit' };

/**
 * Internal helpers
 */
function decrementWithRollover(val, min, max) {
  const decremented = val - 1;

  return decremented < min ? max : decremented;
}

function getAMPMValueFromKeyDown(e) {
  if (e.keyCode === A) return 'am';
  if (e.keyCode === P) return 'pm';

  return null;
}

function getNumberInputSteppedValue(currentValue, keyCode, { initStep, min, max, maxLength }) {
  if (initStep && currentValue === '') return zeroPadValue(initStep, maxLength);

  const num = parseInt(currentValue) || min;
  const newVal = incrementOrDecrement(num, min, max, keyCode);

  return zeroPadValue(newVal, maxLength);
}

function getAMPMSteppedValue(currentValue) {
  return currentValue.toLowerCase() === 'am' ? 'pm' : 'am';
}

function incrementOrDecrement(val, min, max, keyCode) {
  return (keyCode === UP) ? incrementWithRollover(val, min, max) : decrementWithRollover(val, min, max);
}

function incrementWithRollover(val, min, max) {
  const incremented = val + 1;

  return incremented > max ? min : incremented;
}

/**
 * Public Helpers
 */
export function formatDateOut({ day, month, year }, fallback = '') {
  const dateString = `${year}-${month}-${day}`;
  const dateObj = new Date(dateString);

  if (isADate(dateObj)) return dateString;

  console.warn("Invalid date parameter(s). day, month, and year should be strings in 'DD', 'MM', and 'YYYY' formats, respectively.");

  return fallback;
}

export function formatDatetimeOut({ ampm, day, hour, minute, month, year }) {
  const dateString = formatDateOut({ day, month, year });
  const timeString = formatTimeOut({ ampm, hour, minute });

  return (dateString && timeString) ? `${dateString}T${timeString}` : '';
}

export function formatTimeOut({ ampm, hour, minute }, fallback = '') {
  try {
    // Convert 12hr AM/PM to 0-23hr.
    // 12am -> 00 || 12pm -> 12 || 1pm -> 13 || etc.
    let hourDoubleDigitStr = hour;
    if (['PM', 'pm'].includes(ampm) && hour !== '12') {
      hourDoubleDigitStr = (parseInt(hour) + 12).toString();
    } else if (['AM', 'am'].includes(ampm) && hour === '12') {
      hourDoubleDigitStr = '00';
    }

    // No year/month/date is given here, but utilize a date object to set the time manually.
    // The date chosen is the epoch, but could be any date.
    const dateObj = new Date('1970', '0', '01', hourDoubleDigitStr, minute);
    const onlyTimeFormat = new Intl.DateTimeFormat('en', TIME_INPUT_FORMAT);

    return onlyTimeFormat.format(dateObj);
  } catch (err) {
    console.warn(err, "Invalid time parameter(s). ampm ('am', 'pm'), hour ('01'-'12'), and minute ('00'-'59') should be strings of length 2.");

    return fallback;
  }
}

export function parseDateString(dateString) {
  if (!dateString) return EMPTY_DATE_VALUES;
  // TODO: string format check?

  const [year, month, day] = dateString.split('-');

  return { day, month, year };
}

export function parseTimeString(timeString) {
  if (!timeString) return EMPTY_TIME_VALUES;
  // TODO: string format check?

  const [hour, minute] = timeString.split(':');
  const parsedhour = parseInt(hour);
  const ampm = parsedhour > 12 ? 'pm' : 'am';

  return {
    ampm,
    hour: ((parsedhour + 11) % 12 + 1).toString(),
    minute,
  };
}

export function parseDatetimeString(datetimeString) {
  if (!datetimeString) return { ...EMPTY_DATE_VALUES, ...EMPTY_TIME_VALUES };
  // TODO: string format check?

  const [dateString, timeString] = datetimeString.split('T');

  return { ...parseDateString(dateString), ...parseTimeString(timeString) };
}

export function zeroPadValue(value, length) {
  return value.toString().padStart(length, '0');
}

/**
 * Configs
 */
const ampm = { getSteppedValue: getAMPMSteppedValue, getValueFromKeyDown: getAMPMValueFromKeyDown, maxLength: 2, defaultValue: 'am' };
const hour = { getSteppedValue: getNumberInputSteppedValue, min: 1, max: 12, maxLength: 2 };
const minute = { getSteppedValue: getNumberInputSteppedValue, min: 0, max: 59, maxLength: 2 };

// TODO: do we want dymamic max days based on month/year? Chrome native input always has 31, but dynamic seems nice
const day = { getSteppedValue: getNumberInputSteppedValue, min: 1, max: 31, maxLength: 2 };
const month = { getSteppedValue: getNumberInputSteppedValue, min: 1, max: 12, maxLength: 2 };
const year = { getSteppedValue: getNumberInputSteppedValue, min: 1, max: 3000, maxLength: 4, initStep: new Date().getFullYear() };

export const dateConfig = { day, month, year };
export const timeConfig = { ampm, hour, minute };
export const datetimeConfig = { ...dateConfig, ...timeConfig };
