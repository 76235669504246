import Bold from '../icons/bold';
import Code from '../icons/code';
import Italic from '../icons/italic';
import HashTag from '../icons/hashtag';
import Header from '../icons/header';
import Link from '../icons/link';
import List from '../icons/list';
import Picture from '../icons/picture';
import Quote from '../icons/quote';
import Video from '../icons/video';

const optionToTagMap = {
  'bold': 'BOLD',
  'italic': 'ITALIC',
  'code': 'CODE',
  'link': 'LINK',
  'code-block': 'code-block',
  'header': 'header-three',
  'blockquote': 'blockquote',
  'list': 'unordered-list-item',
  'carousel': 'CAROUSEL',
  'linkedImage': 'CAROUSEL',
  'video': 'EMBED'
};

const buttonRelations = {
  carousel: 'linkedImage',
  linkedImage: 'carousel'
};

const getLabelForButton = (label, tag, settings) => {
  if (settings.tooltips && ((settings.tooltips.length === 1 && settings.tooltips[0] === '*') || settings.tooltips.includes(tag))) {
    return label;
  }
  return null;
};

const getDefaultButtons = (ctx, settings) => {
  return [
    { tag: 'BOLD', icon: Bold, onClick: ctx.handleInline, 'tooltipLabel': getLabelForButton('Bold', 'bold', settings) },
    { tag: 'ITALIC', icon: Italic, onClick: ctx.handleInline, 'tooltipLabel': getLabelForButton('Italic', 'italic', settings) },
    { tag: 'LINK', icon: Link, onClick: ctx.handleLink, 'tooltipLabel': getLabelForButton('Link', 'link', settings) },
    { tag: 'CODE', icon: HashTag, onClick: ctx.handleInline, 'tooltipLabel': getLabelForButton('Inline Code', 'code', settings) },
    { tag: 'code-block', icon: Code, onClick: ctx.handleCode, 'tooltipLabel': getLabelForButton('Block Code', 'code-block', settings) },
    { tag: 'header-three', icon: Header, onClick: ctx.handleBlock, 'tooltipLabel': getLabelForButton('Header', 'header', settings) },
    { tag: 'blockquote', icon: Quote, onClick: ctx.handleBlock, 'tooltipLabel': getLabelForButton('Blockquote', 'blockquote', settings) },
    { tag: 'unordered-list-item', icon: List, onClick: ctx.handleBlock, 'tooltipLabel': getLabelForButton('List', 'ul', settings) },
    { tag: 'CAROUSEL', icon: Picture, onClick: () => ctx.imageUploadInput.click(), 'tooltipLabel': getLabelForButton('Image', 'image', settings) },
    { tag: 'EMBED', icon: Video, onClick: () => ctx.togglePrompt(true, { show: true, type: 'VIDEO' }), 'tooltipLabel': getLabelForButton('Video', 'video', settings) }
  ];
}

// ATTN :: Revisit this logic, it can be vastly simplified.
function filterByOptions(buttons, options, ctx) {
  const keys = Object.keys(options);
  const replaceCarouselForLinkedImage = options['linkedImage'] === true && options['carousel'] === false;

  return keys.reduce((acc, key) => {
    if (options[key] === false && !buttonRelations[key]) {
      // Remove basic buttons with no relations.
      acc = acc.filter(button => button.tag !== optionToTagMap[key]);
    } else if (options[key] === false && (key === 'carousel' && !replaceCarouselForLinkedImage)) {
      // Remove image button.
      acc = acc.filter(button => button.tag !== optionToTagMap[key]);
    } else if (key === 'linkedImage' && options[key]) {
      // Extend image button with options or swap it to handle imageLinks.
      acc = acc.map((button, index) => {
        return button.tag === 'CAROUSEL'
          ? {
              ...button,
              options: replaceCarouselForLinkedImage ? [] : ['CAROUSEL', 'LINK'],
              onClick: replaceCarouselForLinkedImage ? ctx.handleImageLink : ctx.handleImageOption
            }
          : button;
      });
    }
    return acc;
  }, buttons);
}

export default function setupToolbarButtons(ctx, options, settings) {
  return options && Object.keys(options).length ? filterByOptions(getDefaultButtons(ctx, settings), options, ctx) : getDefaultButtons(ctx, settings);
}