import React from 'react';
import PropTypes from 'prop-types';

import getFormatForAddressLine3 from '../helpers/getFormatForAddressLine3';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

/**
 * Helpers
 */
const renderIfExists = (s, label = null) => s && s.length > 0 ? (<p className={typography.bodyM}>{label ? `${label}: ${s}` : s}</p>) : null;

const getHighlightedDiff = (key, address, diffingAddress, el = 'p') => {
  // If theres nothing to diff, return early.
  if (!diffingAddress || diffingAddress[key].matchesInput) {
    return React.createElement(el, { className: typography.bodyM }, address[key]);
  }

  // User input.
  const partsA = address[key].split(' ');
  // Api response.
  const partsB = diffingAddress[key].value.split(' ');
  // Iterate on the longer string to catch all the words.
  const iterator = partsA.length > partsB.length ? partsA : partsB;

  const isNotLast = (i, list) => (i !== list.length - 1);

  const words = iterator.reduce((acc, part, i, list) => {
    if (typeof partsA[i] === 'undefined') { // If partsB is larger than partsA and partsB is the iterator.
      return acc;
    } else if (partsA[i] !== partsB[i]) {
      return acc.concat(
        <span className={typography.error}>
          {partsA[i]}
          {isNotLast(i, list) ? ' ' : ''}
        </span>);
    } else {
      return acc.concat(
        <span>
          {partsA[i]}
          {isNotLast(i, list) ? ' ' : ''}
        </span>);
    }
  }, []);

  return React.createElement(el, { className: typography.bodyM }, React.Children.toArray(words));
};

const getAddressLine3 = (address) => {
  const format = getFormatForAddressLine3(address);

  return format.map((part) => part.includes('<>') ? part : address[part]).join('').replace(/<>/g, '');
};

const getAddressLine3ForDiffView = (address, diffingAddress) => {
  const format = getFormatForAddressLine3(address, true);

  return React.Children.toArray(format.map((part) => {
    if (part.includes('<>')) {
      return React.createElement('span', { className: typography.bodyM }, part.replace(/<>/g, ''));
    } else {
      return getHighlightedDiff(part, address, diffingAddress, 'span');
    }
  }));
};

/**
 * Components
 */
const BriefView = ({ address }) => (
  <div>
    <p className={typography.bodyM}>
      {address.first_name}
      {' '}
      {address.last_name}
    </p>
    <p className={typography.bodyM}>{address.address_line1}</p>
    {renderIfExists(address.address_line2)}
    <p className={typography.bodyM}>{getAddressLine3(address)}</p>
    {renderIfExists(address.cpf, 'CPF')}
  </div>
);

const FullView = ({ address }) => (
  <div>
    <p className={typography.bodyM}>
      {address.first_name}
      {' '}
      {address.last_name}
    </p>
    {renderIfExists(address.company, 'Company')}
    <p className={typography.bodyM}>{address.address_line1}</p>
    {renderIfExists(address.address_line2)}
    <p className={typography.bodyM}>{getAddressLine3(address)}</p>
    <p className={typography.bodyM}>{address.country}</p>
    {renderIfExists(address.cpf, 'CPF')}
    <p className={typography.bodyM}>
      Phone:
      {address.phone}
    </p>
    {address.default && <p className={`${typography.bodyS} ${typography.bold}`}>Default address</p>}
  </div>
);

const SelectView = ({ address }) => (
  <div>
    <p className={typography.bodyM}>
      {address.first_name}
      {' '}
      {address.last_name}
    </p>
    {renderIfExists(address.company, 'Company')}
    <p className={typography.bodyM}>{address.address_line1}</p>
    {renderIfExists(address.address_line2)}
    <p className={typography.bodyM}>{getAddressLine3(address)}</p>
    <p className={typography.bodyM}>{address.country}</p>
    {renderIfExists(address.cpf, 'CPF')}
    <p className={typography.bodyM}>
      Phone:
      {address.phone}
    </p>
  </div>
);

/**
 * Highlights fields that do not match the verification address.
 * @param  {object} address - Address shape from form
 * @param  {object} diffingAddress - {matchesInput: bool, value: string}
 */
const VerifiedDiffView = ({ address, diffingAddress }) => (
  <div className={layout.marginTop5}>
    <p className={`${typography.bodyM} ${typography.bold} ${layout.marginBottom5}`}>Address you entered</p>
    <p className={typography.bodyM}>
      {address.first_name}
      {' '}
      {address.last_name}
    </p>
    {renderIfExists(address.company, 'Company')}
    {getHighlightedDiff('address_line1', address, diffingAddress)}
    {renderIfExists(address.address_line2) && getHighlightedDiff('address_line2', address, diffingAddress)}
    <p className={typography.bodyM}>{getAddressLine3ForDiffView(address, diffingAddress)}</p>
    <p className={typography.bodyM}>{address.country}</p>
    {renderIfExists(address.cpf, 'CPF')}
    <p className={typography.bodyM}>
      Phone:
      {address.phone}
    </p>
  </div>
);

/**
 * @param  {object} address - Address shape from form
 */
const VerifiedView = ({ address }) => (
  <div className={layout.marginTop5}>
    <p className={`${typography.bodyM} ${typography.bold} ${typography.success} ${layout.marginBottom5}`}>Verified address</p>
    <p className={typography.bodyM}>
      {address.first_name}
      {' '}
      {address.last_name}
    </p>
    {renderIfExists(address.company, 'Company')}
    <p className={typography.bodyM}>{address.address_line1}</p>
    {renderIfExists(address.address_line2)}
    <p className={typography.bodyM}>{getAddressLine3(address)}</p>
    <p className={typography.bodyM}>{address.country}</p>
    {renderIfExists(address.cpf, 'CPF')}
    <p className={typography.bodyM}>
      Phone:
      {address.phone}
    </p>
  </div>
);

/**
 * Main Component
 */
const Address = ({ address, isDeliverable, diffingAddress, view }) => {
  switch (view) {
    case 'brief':
      return BriefView({ address });

    case 'select':
      return SelectView({ address });

    case 'verified':
      return VerifiedView({ address, isDeliverable });

    case 'verifiedDiff':
      return VerifiedDiffView({ address, diffingAddress });

    default:
      return FullView({ address });
  }
};

Address.propTypes = {
  address: PropTypes.shape({
    address_line1: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    address_line2: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    city: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    company: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    country: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    country_iso2: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    cpf: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    default: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.element]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.element]),
    first_name: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    last_name: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    phone: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    zip: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
  }).isRequired,
  diffingAddress: PropTypes.object,
  isDeliverable: PropTypes.bool,
  view: PropTypes.oneOf(['brief', 'full', 'select', 'verified', 'verifiedDiff']),
};

Address.defaultProps = {
  diffingAddress: null,
  isDeliverable: false,
  view: 'full',
};

export default Address;
