import React from 'react';
import PropTypes from 'prop-types';

import EventCardTextPreview from './EventCardTextPreview';
import LazyBackgroundImage from '../../../../../client/image/LazyBackgroundImage';

import { getInObj } from '../../../../../utility/accessors';

import layout from '../../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import baseCardStyles from '../../../../../client/cards/base_card/vertical/base_card_vertical.css';

const EventCardVerticalPreview = ({ className, item }) => {
  const imageUrl = getInObj(['image', 'url'], item);

  return (

    <div className={`${baseCardStyles.cardResponsive} ${layout.flexColumn} ${layout.fullWidth} ${className}`}>
      <div className={`${baseCardStyles.imageResponsive} ${layout.fullWidth} ${utilStyles.absolutePlaceholderParent} ${utilStyles.aspectRatioPadding3_2} ${utilStyles.borderRadius} ${utilStyles.overflowHidden} ${imageUrl ? '' : utilStyles.bgAsphalt}`}>
        {!!imageUrl
        && (
          <LazyBackgroundImage
            key={imageUrl}
            alt={item.title}
            classList={{ root: `${utilStyles.absolutePlaceholderChild}` }}
            ratio="3:2"
            setStyle={false}
            src={imageUrl} /* reInit LazyBackgroundImage when the source changes */
            width={204}
          />
        )}
      </div>
      <div className={`${baseCardStyles.textResponsive} ${layout.marginTop22}`}>
        <EventCardTextPreview item={item} />
      </div>
    </div>
  );
};

EventCardVerticalPreview.propTypes = {
  event: PropTypes.shape({
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    end_date: PropTypes.string,
    event_type: PropTypes.string,
    image: PropTypes.shape({ url: PropTypes.string }),
    location: PropTypes.shape({}),
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    start_date: PropTypes.string,
    state: PropTypes.string,
    title: PropTypes.string,
  }),
};

EventCardVerticalPreview.defaultProps = {
  event: {
    custom_sponsors: [],
    end_date: null,
    event_type: null,
    image: null,
    location: null,
    platforms: [],
    start_date: null,
    state: null,
    title: null,
  },
};

export default EventCardVerticalPreview;
