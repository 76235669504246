import { v4 as uuidv4 } from 'uuid';
import uuidDedupe from '../../../utility/uuid_dedupe';
import { getItem, setItem } from '../../local_storage';

const LS_KEY = 'hck.kn';
const SESSION_EXP_BY_HOURS = 1;

export default class SessionHandler {
  constructor(lsKey) {
    this.LS_KEY = lsKey || LS_KEY;
  }

  getSessionData() {
    const data = this._fetchFromCache();
    if (data) {
      // If session is expired, use the same distinct id and create a new session id and timestamp.
      // Otherwise, only update the timestamp.
      const createNewSession = this._isSessionExpired(data.t);

      const session = createNewSession
        ? this._createSession(data.dId, uuidDedupe(data.sId))
        : this._createSession(data.dId, data.sId);

      this._setInCache(session);

      return { createNewSession, data: this._translator(session) };
    } else {
      const session = this._createSession();
      this._setInCache(session);

      return { createNewSession: true, data: this._translator(session) };
    }
  }

  _createSession(distinctId = null, sessionId = null) {
    const dId = distinctId || uuidv4();
    const sId = sessionId || uuidv4();
    const t = this._getDatePlusHours(SESSION_EXP_BY_HOURS);

    return { dId, sId, t };
  }

  _getDatePlusHours(hours = 1) {
    return new Date(Date.now() + (hours * 3600 * 1000)).valueOf();
  }

  _fetchFromCache() {
    return getItem(this.LS_KEY);
  }

  _isSessionExpired(t) {
    return Date.now() > parseFloat(t);
  }

  _setInCache(record) {
    return setItem(this.LS_KEY, record);
  }

  _translator({ dId, sId }) {
    return {
      distinctId: dId,
      sessionId: sId,
    };
  }
}
