import buildFirstLevelAlgoliaParams from '../../query_builders/buildFirstLevelAlgoliaParams';
import buildFacetsFromQueryMap from '../../query_builders/buildFacetsFromQueryMap';

// Validation functions (part_id: property => isValid?)
const KNOWN_FACETS = {};
// Map key to algolia facet (part_id: 'parts.id')
const ALGOLIA_FACETS = {};

function composeQueryParameters(queryMap, initFacet) {
  return new Promise((resolve, reject) => {
    const facetsFromQuery = buildFacetsFromQueryMap(queryMap, KNOWN_FACETS, ALGOLIA_FACETS);
    const facetFilters = [...facetsFromQuery, ...initFacet].filter((n) => n.length > 0);
    resolve({ facetFilters, params: buildFirstLevelAlgoliaParams(queryMap), sort: '' });
  });
}

export default function queryBuilder(queryMap, initFacet = []) {
  return new Promise((resolve, reject) => composeQueryParameters(queryMap, initFacet)
    .then((queryParams) => resolve(queryParams))
    .catch((err) => reject(err)));
}
