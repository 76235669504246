import React, { Fragment } from 'react';

import { ParagraphPlaceholder } from '../article/templates';

import colors from '../../../../../styles/global_ui/colors.css';
import layout from '../../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../../styles/global_ui/util.css';

const _getSection = (numLines, animated) => (
  <Fragment>
    <ParagraphPlaceholder
      animated={animated}
      backgroundColor={colors['v-asphalt']}
      lastLineWidth="40%"
      numLines={numLines}
      textHeight={16}
      textSpacing={6}
    />
    <div className={`${layout.marginTop15} ${layout.marginBottom15} ${utilStyles.borderTop}`} />
  </Fragment>
);

const SidebarPlaceholder = ({ animated }) => (
  <div className={layout.fullWith}>
    {_getSection(3, animated)}
    {_getSection(4, animated)}
    {_getSection(3, animated)}
    {_getSection(4, animated)}
  </div>
);

export default SidebarPlaceholder;
