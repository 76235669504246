import { getItem as localStorageGetItem, setItem as localStorageSetItem } from '../../services/local_storage';
import { getCookie, setCookie } from '../../services/cookies';

// cookies
const COUNT_KEY = 'projCnt';
const HIDS_KEY = 'projHids';

// localStorage
const IMAGES_KEY = 'hck.viewed_proj_img';

/**
 * Helper Functions
 */
export function addViewedProject({ hid, cover_image_url }) {
  const oldValues = getViewedProjects();

  if (oldValues.count >= 3 || oldValues.hids.includes(hid)) return oldValues;

  const count = oldValues.count + 1;
  const hids = [...oldValues.hids, hid];
  const images = [...oldValues.images, cover_image_url];

  localStorageSetItem(IMAGES_KEY, images);

  setCookie(COUNT_KEY, count);
  setCookie(HIDS_KEY, hids);

  return { count, hids, images };
}

export function getViewedProjects() {
  const images = localStorageGetItem(IMAGES_KEY) || [];

  const count = getCookie(COUNT_KEY) || 0;
  const hids = getCookie(HIDS_KEY) || [];

  return { count, hids, images };
}
