import React from 'react';

import EventCardHorizontalLargePreview from './preview_cards/EventCardHorizontalLargePreview';
import EventCardHorizontalSmallPreview from './preview_cards/EventCardHorizontalSmallPreview';
import EventCardVerticalPreview from './preview_cards/EventCardVerticalPreview';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const PreviewLayoutDefault = ({ item }) => (
  <div>
    <div style={{ maxWidth: 524 }}>
      <EventCardHorizontalLargePreview item={item} />
    </div>

    <div className={`${layout.marginTop45} ${layout.marginBottom30} ${layout.paddingTop45} ${utilStyles.borderTop}`}>
      <div style={{ maxWidth: 350 }}>
        <EventCardHorizontalSmallPreview item={item} />
      </div>
    </div>

    <div className={`${layout.marginTop45} ${layout.marginBottom30} ${layout.paddingTop45} ${utilStyles.borderTop}`}>
      <div style={{ maxWidth: 270 }}>
        <EventCardVerticalPreview item={item} />
      </div>
    </div>
  </div>
);

export default PreviewLayoutDefault;
