import { isAFunction } from './types';

function addListener(listener) {
  document.addEventListener('click', listener);
}

function createListener(elemOrGetter, callback, targetWillUnmount) {
  return (e) => {
    const element = isAFunction(elemOrGetter) ? elemOrGetter() : elemOrGetter;
    if (element && isClickOutsideElement(e, element, targetWillUnmount)) callback(e);
  };
}

function isClickOutsideElement(e, element, targetWillUnmount) {
  if (targetWillUnmount && isAFunction(e.composedPath)) {
    return !isNodeInComposedPath(e, element);
  }

  return !element.contains(e.target);
}

// When a DOM node gets demounted and we still need determine whether the event's target sourced from the container node.
//
// Example: SimpleSelect's dropdown gets unmounted when a seleceted option is clicked. The dom node does not "contain" the element in that case, not anymore.
// However, here we can use the events paths array to backtrack if the target was indeed in the container node when the event happened.
function isNodeInComposedPath(e, nodeToMatch) {
  const paths = e.composedPath();

  for (const v of paths) {
    if (v === nodeToMatch) return true;
  }

  return false;
}

function removeListener(listener) {
  document.removeEventListener('click', listener);
}

/**
 * @param  {DOM node || DOM accessor fn} elemOrGetter
 * @param  {Function}                    callback          - Called when the current element does not contain the events target.
 * @param  {Boolean}                     targetWillUnmount - If the target node will unmount, we use events.paths.
 */
export default function clickOutsideListener(elemOrGetter, callback, targetWillUnmount = false) {
  const listener = createListener(elemOrGetter, callback, targetWillUnmount);
  addListener(listener);

  return { remove: () => removeListener(listener) };
}
