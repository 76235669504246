import selectDefault from './select/default.css';
import selectBootStrap from './select/bootstrap.css';
import selectSearch from './select/search.css';

import menuDefault from './menu/default.css';
import menuBootstrap from './menu/bootstrap.css';

const menuMap = {
  bootstrap: menuBootstrap,
  default: menuDefault,
  search: menuDefault,
};

const selectMap = {
  bootstrap: selectBootStrap,
  default: selectDefault,
  search: selectSearch,
};

const getTheme = (theme) => ({
  menu: theme === 'default' ? menuDefault : { ...menuDefault, ...menuMap[theme] },
  select: theme === 'default' ? selectDefault : { ...selectDefault, ...selectMap[theme] },
});

/**
 * To create a new theme, create the css file and import it here. Map it to the argument value expected.
 * The classNames will default to the defaultTheme classes unless overridden.
 */
export default function getStylesForTheme(theme) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!selectMap.hasOwnProperty(theme)) {
    console.warning(`Theme: ${theme} is not a valid theme!`);

    return getTheme('default');
  } else {
    return getTheme(theme);
  }
}
