import React from 'react';
import PropTypes from 'prop-types';

import NewsArticlePreviewCard from '../../../../client/cards/news_article/preview';
import NewsArticleFeaturedPreviewCard from '../../../../client/cards/news_article/featured_card/preview';
import NewsArticleSponsoredCardPreview from '../../../../client/cards/news_article/sponsored_card/preview';
import NewsArticleSponsoredCardLargePreview from '../../../../client/cards/news_article/sponsored_card_large/preview';

import ArticleLayout from '../../article_page/article_layout';
import StickyActionsBar from '../templates/StickyActionsBar';
import TopMenuBar from '../templates/TopMenuBar';

import layout from '../../../../styles/global_ui/layout.css';
import gridStyles from '../../../../styles/global_ui/grid.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './preview.css';

const getCardsPreviewSection = (article) => article.sponsored ? getSponsoredCardPreviewView(article) : getCardsPreviewView(article);

const getCardsPreviewView = (article) => (
  <div className={`${utilStyles.borderTop} ${layout.paddingTop45}`}>
    <div className={styles.wrapper}>
      <div className={`${layout.flex1} ${styles.defaultCardContainer}`}>
        <h4 className={`${typography.h4} ${layout.marginBottom15}`}>Default Card</h4>
        <div className={styles.defaultCardWrapper}>
          <NewsArticlePreviewCard article={article} />
        </div>
      </div>

      <div className={layout.flex1}>
        <h4 className={`${typography.h4} ${layout.marginBottom15}`}>Featured Card</h4>
        <div className={styles.featuredCardWrapper}>
          <NewsArticleFeaturedPreviewCard article={article} />
        </div>
      </div>
    </div>
  </div>
);

const getSponsoredCardPreviewView = (article) => (
  <div className={`${utilStyles.borderTop} ${layout.paddingTop45}`}>
    <div className={`${gridStyles.grid} ${gridStyles.guttersH30} ${gridStyles.guttersV30}`}>
      <div className={`${gridStyles.cell} ${gridStyles.cell_1} ${gridStyles.cell_1_2_md}`}>
        <h4 className={`${typography.h4} ${layout.marginBottom15}`}>Sponsored Card on Article Page</h4>
        <div className={styles.sponsoredCardWrapper}>
          <NewsArticleSponsoredCardPreview article={article} />
        </div>
      </div>
      <div className={`${gridStyles.cell} ${gridStyles.cell_1} ${gridStyles.cell_1_2_md}`}>
        <h4 className={`${typography.h4} ${layout.marginBottom15}`}>Sponsored Card on News Home Page</h4>
        <div style={{ maxWidth: 570 }}>
          <NewsArticleSponsoredCardLargePreview article={article} />
        </div>
      </div>
    </div>
  </div>
);

const NewsArticlePreview = ({ article, deleteArticle, isAdminOrEditor, toggleCurrentView, unpublishArticle }) => (
  <div>
    <TopMenuBar
      article={article}
      deleteArticle={deleteArticle}
      isAdminOrEditor={isAdminOrEditor}
      unpublishArticle={unpublishArticle}
    />

    <div className={layout.container}>
      <div className={layout.wrapper1170}>
        {article && <div className={layout.marginBottom60}><ArticleLayout article={article} isPreview={true} /></div>}
        {article && getCardsPreviewSection(article)}
      </div>
    </div>

    <StickyActionsBar isAdminOrEditor={isAdminOrEditor} toggleCurrentView={toggleCurrentView} view="preview" />
  </div>
);

NewsArticlePreview.propTypes = {
  article: PropTypes.object,
  deleteArticle: PropTypes.func.isRequired,
  isAdminOrEditor: PropTypes.bool.isRequired,
  toggleCurrentView: PropTypes.func.isRequired,
  unpublishArticle: PropTypes.func.isRequired,
};

NewsArticlePreview.defaultProps = { article: null };

export default NewsArticlePreview;
