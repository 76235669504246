import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DummyCard from '../../cards/project_card/DummyCard';
import EmptyViewAll from '../../wrappers/view_all/EmptyViewAll';
import HorizontalScroll from '../../wrappers/horizontal_scroll';
import ProjectCard from '../../cards/project_card';
import ViewAllWrapper from '../../wrappers/view_all';

import { buildPaginatedQuery } from '../../../services/algolia/view_all_builder/helpers';
import { setTimeoutPromise } from '../../../utility/promises';
import errorHandler from '../../../services/error_handler';

import viewAllStyles from '../../wrappers/view_all/view_all.css';

class AlgoliaPinnedItemViewAllList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPinnedItemQuery: {},
      currentProjectsQuery: {},
      initialized: false,
      isFetching: true,
      projects: [],
      viewAllLinks: {},
    };

    this.fetchMoreProjects = this.fetchMoreProjects.bind(this);
    this._isMounted;
  }

  componentDidMount() {
    this._isMounted = true;
    this._initialize();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _initialize() {
    return Promise.all([this._fetchItem(), this._fetchInitProjects()])
      .then((results) => setTimeoutPromise(250, null, null, results))
      .then(([currentPinnedItemQuery, currentProjectsQuery]) => {
        if (!this._isMounted) return;

        const item = currentPinnedItemQuery.hits[0];

        this.setState({
          currentPinnedItemQuery,
          currentProjectsQuery,
          initialized: true,
          isFetching: false,
          projects: currentProjectsQuery.hits,
          viewAllLinks: item && Object.keys(item).length ? this.props.viewAllLinksComposer(item) : { external: '', internal: '' },
        });
        this.props.reportProjectsCount(currentProjectsQuery.hits.length, this.props.index);
      })
      .catch((err) => errorHandler('AlgoliaPinnedItemViewAllList _initialize', err));
  }

  /**
   * Helpers
   */
  _fetchItem() {
    return this.props.algoliaPinnedItemService.searchWithNoEffects({}, this.props.initPinnedItemFacet);
  }

  _fetchInitProjects() {
    return this.props.algoliaProjectsService.searchWithNoEffects(this.props.queryMap, this.props.initProjectsFacet);
  }

  _shouldRender() {
    return this.state.initialized
      && (this.state.currentPinnedItemQuery.hits && this.state.currentPinnedItemQuery.hits.length > 0)
      && (this.state.currentProjectsQuery && this.state.currentProjectsQuery.hits.length > 0);
  }

  _shouldRenderLoader() {
    return !this.state.initialized && this.state.isFetching;
  }

  /**
   * Callbacks
   */
  fetchMoreProjects() {
    const query = buildPaginatedQuery({ queryMap: this.props.queryMap, pagination: this.props.pagination, currentQuery: this.state.currentProjectsQuery });

    return this.props.algoliaProjectsService.searchWithNoEffects(query, this.props.initProjectsFacet)
      .then((currentProjectsQuery) => {
        if (!this._isMounted) return;

        const projects = this.state.projects.concat(currentProjectsQuery.hits);
        this.setState({
          currentProjectsQuery,
          projects,
        });

        this.props.reportProjectsCount(projects.length, this.props.index);
      })
      .catch((err) => errorHandler(err));
  }

  /**
   * Views
   */
  _getLoaderView() {
    return (
      <ViewAllWrapper
        links={this.state.viewAllLinks}
        onClick={this.props.transition}
        title={this.props.title}
      >
        <DummyCard
          cardBorder={true}
          classList={{ card: viewAllStyles.dummyCard, wrapper: viewAllStyles.scrollerItemProject }}
        />
      </ViewAllWrapper>
    );
  }

  _getNoHitsView() {
    return this.props.userCanManage ? this._managerView() : null;
  }

  _getPinnedCardComponent() {
    return (
      <div className={viewAllStyles.scrollerItemProject}>
        {this.props.pinnedCardTemplateFn({
          item: this.state.currentPinnedItemQuery.hits ? this.state.currentPinnedItemQuery.hits[0] : null,
          onClick: (e) => this.props.transition(e, this.state.viewAllLinks, { type: 'pinned_card' }),
        })}
      </div>
    );
  }

  _managerView() {
    return <EmptyViewAll title={this.props.title} />;
  }

  render() {
    if (this._shouldRenderLoader()) return this._getLoaderView();
    if (!this._shouldRender()) return this._getNoHitsView();

    return (
      <ViewAllWrapper
        links={this.state.viewAllLinks}
        onClick={this.props.transition}
        title={this.props.title}
      >
        <HorizontalScroll
          fetchMore={this.fetchMoreProjects}
          recordsCount={this.state.projects.length}
          scrollDistance={this.props.scrollDistance}
          totalRecordsCount={Math.min(parseInt(this.state.currentProjectsQuery.nbHits) || 0, this.props.pagination.maxCardCount - 1)}
        >
          {this._getPinnedCardComponent()}
          {this.state.projects.map((project, i) => (
            <ProjectCard
              key={project.hid || project.id}
              cardBorder={true}
              classList={{ wrapper: viewAllStyles.scrollerItemProject }}
              imageProps={{ captureScroll: true }}
              itemIndex={i}
              overrideTracking={this.props.transition}
              project={project}
              usePortal={true}
            />
          ))}
        </HorizontalScroll>
      </ViewAllWrapper>
    );
  }
}

AlgoliaPinnedItemViewAllList.propTypes = {
  algoliaPinnedItemService: PropTypes.object.isRequired,
  algoliaProjectsService: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  initPinnedItemFacet: PropTypes.array,
  initProjectsFacet: PropTypes.array,
  pagination: PropTypes.shape({
    maxCardCount: PropTypes.number,
    type: PropTypes.string,
    length: PropTypes.number,
  }).isRequired,
  pinnedCardTemplateFn: PropTypes.func.isRequired,
  queryMap: PropTypes.object,
  reportProjectsCount: PropTypes.func.isRequired,
  scrollDistance: PropTypes.number,
  title: PropTypes.string.isRequired,
  transition: PropTypes.func,
  userCanManage: PropTypes.bool,
  viewAllLinksComposer: PropTypes.func.isRequired, // Return object: {external: '', internal: ''}
};

AlgoliaPinnedItemViewAllList.defaultProps = {
  queryMap: {},
  scrollDistance: 540,
  transition: () => {},
  userCanManage: null,
};

export default AlgoliaPinnedItemViewAllList;
