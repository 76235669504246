import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import FormImageUploader from '../form_image_uploader';

import { getInObj } from '../../../../utility/accessors';
import { isAFunction, isAString } from '../../../../utility/types';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';

// This is like the other RailsFormImageUploader, but with a couple extra hooks
// for interacting with the Project Editor app.

// NOTE: this extra functionality is currently only useful on projects/edit#basics.
// If we ever need it somewhere else (which I doubt will happen),
// we can make things less specific to that one panel
const DISCARD_EVENT = 'pe:discard';
const IGNORE_INPUT_CLASS = 'no-pe-change';
const PANEL_SELECTOR = '.pe-panel, #basics';
const FORM_SELECTOR = '.pe-panel form.remote';
const SAVE_EVENT = 'pe:saveChanges';

export default class ProjectEditorImageUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageData: props.initData,
      savedImageData: props.initData,
    };

    this.handleDiscardChanges = this.handleDiscardChanges.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const form = this._getProjectEditorForm();
    if (!form) return;

    form.addEventListener(DISCARD_EVENT, this.handleDiscardChanges, true);
    form.addEventListener(SAVE_EVENT, this.handleSave, true);

    // If this component mounts after pe has initialized,
    // pe will think there are unsaved changes unless we reserialize the form.
    if (this._isOnCorrectPanel() && window.pe) {
      window.pe.serializeForm();
    }
  }

  componentWillUnmount() {
    const form = this._getProjectEditorForm();
    if (!form) return;

    form.removeEventListener(DISCARD_EVENT, this.handleDiscardChanges, true);
    form.removeEventListener(SAVE_EVENT, this.handleSave, true);
  }

  componentDidUpdate(prevProps, prevState) {
    // When we're on the project editor instance, we want to alter the
    // $serializedForm so it will trigger tab navigation confirmation.
    if (this._shouldUpdateSerializedForm(prevState)) {
      window.$serializedForm += ' ';
      window.pe.showSavePanel();
    }
  }

  handleDiscardChanges() {
    this.setState({ imageData: { ...this.state.savedImageData } });
  }

  handleSave(e) {
    this.setState({ savedImageData: { ...this.state.imageData } });
  }

  _isOnCorrectPanel() {
    return !!document.querySelector(PANEL_SELECTOR);
  }

  _getProjectEditorForm() {
    return document.querySelector(FORM_SELECTOR);
  }

  _shouldUpdateSerializedForm(prevState) {
    return (
      prevState.imageData.id !== this.state.imageData.id
      && isAFunction(getInObj(['pe', 'showSavePanel'], window))
      && isAString(window.$serializedForm)
      && this._isOnCorrectPanel()
    );
  }

  render() {
    return (
      <Fragment>
        <FormImageUploader
          allowRemoteURL={this.props.allowRemoteURL}
          aspectRatio={this.props.aspectRatio}
          attachmentType={this.props.attachmentType}
          attachmentURLKey={this.props.attachmentURLKey}
          classList={{ formGroup: layout.marginBottom0, previewWrapper: utilStyles.border }}
          dimensionMins={this.props.dimensionMins}
          errors={this.props.errors[0] || null}
          helperText={this.props.helperText}
          ignoreCropper={this.props.ignoreCropper}
          imageData={this.state.imageData}
          imageVersion={this.props.imageVersion}
          inputClassName={IGNORE_INPUT_CLASS}
          placeholderUrl={this.props.placeholderUrl}
          propagateUpload={(imageData) => this.setState({ imageData })}
          stackedView={this.props.stackedView}
        />
        <input
          name={this.props.inputName}
          type="hidden"
          value={this.state.imageData.id ? this.state.imageData.id.toString() : ''}
        />
      </Fragment>
    );
  }
}

ProjectEditorImageUploader.propTypes = {
  aspectRatio: PropTypes.number,
  attachmentType: PropTypes.string,
  attachmentURLKey: PropTypes.oneOf(['file_url', 'imgix_url']),
  dimensionMins: PropTypes.shape({ width: PropTypes.number }),
  errors: PropTypes.array,
  helperText: PropTypes.string,
  imageVersion: PropTypes.string,
  initData: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  }),
  inputName: PropTypes.string,
  placeholderUrl: PropTypes.string,
  stackedView: PropTypes.bool,
};

ProjectEditorImageUploader.defaultProps = {
  aspectRatio: null,
  attachmentType: 'Image',
  attachmentURLKey: 'imgix_url',
  dimensionMins: {},
  errors: [],
  helperText: '',
  imageVersion: null,
  inputName: '',
  initData: {},
  placeholderUrl: null,
  stackedView: false,
};
