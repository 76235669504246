import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import { fromJS } from 'immutable';
import { CharacterMetadata, ContentState, RichUtils, EditorState, Modifier } from 'draft-js';
import throttle from 'lodash.throttle';

import ToolbarButton from '../ToolbarButton';
import Prompt from '../Prompt';
import LinkMenu from '../LinkMenu';
import Dialog from '../Dialog';
import ImageLinkForm from '../ImageLinkForm';

import setupToolbarButtons from '../../config/setupToolbarButtons';
import {forceSelection} from '../../utils/draft/modifiers';
import {getVideoData} from '../../utils/Helpers';
import {INVALID_URL_MSG, appendProtocolToUrlType, isValidLinkable, isUrlValid} from '../../utils/media/url';
import { removeInlineStylesFromRange, splitBlockAndInsertCustomBlock } from '../../utils/draftUtils';

import {getCurrentBlock} from '../../utils/draft/getters';
import {toggleInlineStyle} from '../../utils/draft/modifiers';

import styles from './toolbar.css';
import buttonStyle from '../../styles/buttons.css';
import slideUpTransitions from '../../styles/slideUpTransitions.css';

class Toolbar extends Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleBlock = this.handleBlock.bind(this);
    this.handleImageLink = this.handleImageLink.bind(this);
    this.handleImageOption = this.handleImageOption.bind(this);
    this.handleInline = this.handleInline.bind(this);
    this.handleCode = this.handleCode.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.togglePrompt = this.togglePrompt.bind(this);
    this.handlePromptValue = this.handlePromptValue.bind(this);
    this.debouncedScroll;

    this.state = {
      dialog: { show: false },
      fixed: false,
      prompt: { show: false, type: null }
    };

    this.buttonList = setupToolbarButtons(this, props.toolbarConfig, props.toolbarSettings);

    // Refs.
    this.imageUploadInput;
    this.toolbar;
    this.prompt;
    this.dialog;
    // Scroll event bound node.
    this.scrollBindingElement;
  }

  componentDidMount() {
    this.debouncedScroll = throttle(this.handleScroll, 10, true);
    this.scrollBindingElement = this.props.scrollBindingElement ? ReactDOM.findDOMNode(this.props.scrollBindingElement) : window;
    this.scrollBindingElement.addEventListener('scroll', this.debouncedScroll);
  }

  componentWillUnmount() {
    this.scrollBindingElement.removeEventListener('scroll', this.debouncedScroll);
  }

  handleScroll() {
    if (!this.toolbar) return;

    // Width will only be "100%" when the editor is initially hidden, then shown.
    if (this.props.editor.get('width') === '100%') {
      this.props.refetchEditorWidth();
    }

    const toolbarContainerPos = this.toolbar.parentNode.getBoundingClientRect();
    const draftsterContainerPos = this.toolbar.parentNode.parentNode.getBoundingClientRect();

    if (parseInt(toolbarContainerPos.top, 10) < 0 && parseInt(draftsterContainerPos.bottom, 10) >= 45) {
      // Nested if helps prevent over rendering when the toolbar is fixed and the above statement fires true.
      if (!this.state.fixed) this.setState({ fixed: true });
    } else {
      if (this.state.fixed) this.setState({ fixed: false });
    }
  }

  handleInline(tag) {
    const currentBlock = getCurrentBlock(this.props.draft);

    if (currentBlock.getType() === 'header-three') {
      return this.props.setMessenger({open: true, msg: 'Sorry, headers can\'t be styled', type: 'error'});
    }

    this.props.toggleActiveStyle(tag);
  }

  handleBlock(tag) {
    // Strip headers and pre blocks of any nested styles.
    const editorState = (tag === 'header-three'|| tag === 'code-block')
      ? removeInlineStylesFromRange(this.props.draft)
      : this.props.draft;

    this.props.setDraft(EditorState.forceSelection(
      RichUtils.toggleBlockType(editorState, tag),
      this.props.draft.getSelection().set('hasFocus', true)
    ));
  }

  handleCode(tag) {
    tag === 'code-block' ? this.handleBlock(tag) : this.handleInline(tag);
  }

  handleLink() {
    if (this.props.draft.getSelection().isCollapsed()) return this.props.setDraft(forceSelection(this.props.draft));

    const currentEditorState = this.props.draft;
    const currentContentState = currentEditorState.getCurrentContent();
    const selection = currentEditorState.getSelection();
    const currentBlock = getCurrentBlock(currentEditorState);

    if (currentBlock.getType() === 'header-three') {
      this.props.setMessenger({ open: true, msg: 'Sorry, headers can\'t be styled', type: 'error' });
      return;
    }

    const entityKey = currentContentState
      .getBlockForKey(selection.getStartKey())
      .getEntityAt(selection.getStartOffset());

    if (entityKey && currentContentState.getEntity(entityKey).getType() === 'LINK') {
      this._removeLinkEntitiesFromSelection(currentEditorState, currentContentState, selection);
    } else {
      this.togglePrompt(true, { show: true, type: 'LINK' });
    }
  }

  _removeLinkEntitiesFromSelection(editorState, contentState, selection) {
    if (selection.isCollapsed()) return;

    this.props.setDraft(
      EditorState.push(
        editorState,
        Modifier.applyEntity(
          contentState,
          selection.set('hasFocus', true),
          null
        )
      )
    );
  }

  togglePrompt(readOnly, promptState, resetSelection=false) {
    if (readOnly) {
      this.props.toggleReadOnly(true);
      this.setState({ prompt: promptState });
    } else {
      this.props.toggleReadOnly(false);
      this.setState({ prompt: { show: false, type: null } });

      // Puts the selection back to where it was.
      if (resetSelection) {
        const currentEditorState = this.props.draft;
        const currentSelection = currentEditorState.getSelection();
        this.props.setDraft(EditorState.forceSelection(
          currentEditorState,
          currentSelection
        ));
      }
    }
  }

  handlePromptValue() {
    const value = this.prompt.getInputValue();

    switch(this.state.prompt.type) {
      case 'LINK':
        this._handleLinkEmbed(value);
        break;

      case 'VIDEO':
        this._handleVideoEmbed(getVideoData(value));
        break;

      default:
        this.togglePrompt(false, false, true);
        return;
    }
  }

  _handleLinkEmbed(href) {
    href = href.trim();
    const {isValid, type} = isValidLinkable(href);

    if (!isValid) {
      this.props.setMessenger({ open: true, msg: INVALID_URL_MSG, type: 'error' });
      this.togglePrompt(false, false, true);
      return;
    }

    href = appendProtocolToUrlType(href, type);

    const currentEditorState = this.props.draft;
    const currentContentState = currentEditorState.getCurrentContent();
    const selection = currentEditorState.getSelection();

    const contentStateWithEntity = currentContentState.createEntity('LINK', 'MUTABLE', { href: href });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const updatedContentState = Modifier.applyEntity(
      contentStateWithEntity,
      selection.set('hasFocus', true),
      entityKey
    );

    this.props.setDraft(EditorState.push(
      currentEditorState,
      updatedContentState
    ));
    this.togglePrompt(false);
  }

  _handleVideoEmbed(data) {
    if (!data) {
      this.props.setMessenger({ open: true, msg: 'Sorry, that\'s not a valid video url.', type: 'error' });
      this.togglePrompt(false, false, true);
      return;
    }
    // TODO: Check to see if we can reuse customBlockTransactions insertVideo here.
    const editorState = this.props.draft;
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const currentContentWithEntity = currentContent.createEntity('TOKEN', 'IMMUTABLE', { embedData: fromJS({ ...data, model: 'Video', key: 'video' }) });
    const entityKey = currentContentWithEntity.getLastCreatedEntityKey();
    const charData = CharacterMetadata.create({ entity: entityKey });

    const { newEditorState, newSelectionState } = splitBlockAndInsertCustomBlock(editorState, currentContentWithEntity, selectionState, charData, 'EMBED');

    this.props.setDraft(EditorState.forceSelection(
      newEditorState,
      newSelectionState
    ));
    this.togglePrompt(false);
  }

  /* setupToolbarButtons.js */
  handleImageOption(tag, option) {
    option === 'CAROUSEL' ? this.imageUploadInput.click() : this.handleImageLink();
  }
  /* setupToolbarButtons.js */
  handleImageLink() {
    this.setState({dialog: {show: true}});
  }

  render() {
    const {activeMetadata, editor} = this.props;
    const width = editor.get('width');

    if (width === 0 || (width === '100%' && this.state.fixed)) return null;

    return (
      <div className={`${styles.container} react-editor-toolbar-container`}>
        <div
          ref={(el) => this.toolbar = el}
          style={{ width: editor.get('width'), zIndex: editor.get('isDialogOpen') ? '0' : '999' }}
          className={this.state.fixed
            ? `${styles.wrapper} ${styles.fixed} react-editor-toolbar-wrapper fixed-toolbar`
            : `${styles.wrapper} react-editor-toolbar-wrapper`}
          >
          <div className={`${styles.toolbar} react-editor-toolbar`}>
            {this.buttonList.map((button, index) => (
              <ToolbarButton
                key={index}
                isActive={activeMetadata.get('activeStyles').includes(button.tag)}
                onClick={button.onClick}
                {...button}
              />
            ))}
          </div>

          <input
            ref={(el) => this.imageUploadInput = el}
            style={{display: 'none'}}
            type="file"
            multiple={true}
            onChange={(e) => {
              this.props.onImageUpload(e.target.files);
              e.target.value = '';  // Delete, reupload bug.
            }}
            />

          {this.state.prompt.show &&
            <Prompt
              ref={(el) => this.prompt = el}
              actions={[
                <button
                  className={`${buttonStyle.btn} ${buttonStyle.btnLink} draftster-btn-secondary`}
                  onClick={(e) => {
                    e.preventDefault();
                    this.togglePrompt(false, false, true);
                  }}
                  type="button"
                  >
                  Cancel
                </button>,
                <button
                  className={`${buttonStyle.btn} ${buttonStyle.btnPrimary} draftster-btn-primary`}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handlePromptValue();
                  }}
                  type="button"
                >
                  Ok
                </button>
              ]}
              enableCloseButton={false}
              submit={this.handlePromptValue}
              dismiss={() => this.togglePrompt(false, false, true)}
              />
          }

          <CSSTransition classNames={slideUpTransitions} in={this.state.dialog.show} timeout={450}>
            <Dialog
              ref={(el) => this.dialog = el}
              dismiss={() => this.setState({dialog: {show: false}})}
              open={this.state.dialog.show}
              title="Create a linked image"
              >
              <ImageLinkForm
                create={this.props.uploadProcessedImage}
                dismiss={() => this.setState({dialog: {show: false}})}
                messenger={this.props.setMessenger}
              />
            </Dialog>
          </CSSTransition>
        </div>
      </div>
    );
  }
}

Toolbar.propTypes = {
  activeMetadata: PropTypes.object.isRequired,
  draft: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  refetchEditorWidth: PropTypes.func.isRequired,
  scrollBindingElement: PropTypes.object,
  setDraft: PropTypes.func.isRequired,
  setMessenger: PropTypes.func.isRequired,
  toggleReadOnly: PropTypes.func,
  toggleActiveStyle: PropTypes.func.isRequired,
  toolbarConfig: PropTypes.object,
  toolbarSettings: PropTypes.shape({
    tooltips: PropTypes.arrayOf(PropTypes.string)
  }),
  uploadProcessedImage: PropTypes.func.isRequired
};

Toolbar.defaultProps = {
  scrollBindingElement: null,
  toggleReadOnly: () => {},
  toolbarConfig: {},
  toolbarSettings: {
    tooltips: []
  }
};

export default Toolbar;
