import { CharacterMetadata, ContentBlock, ContentState, EditorState, genKey, Modifier, SelectionState } from 'draft-js';
import { List, OrderedSet } from 'immutable';
import Validator from 'validator';

import { getVideoData, isGistUrlValid, isTwitterUrlValid } from './Helpers';
import {isEmbedlyUrlValid} from './media/social';

export function getCurrentBlock(content, selection) {
  const blockMap = content.getBlockMap();
  return blockMap.get(selection.getStartKey());
}

export function updateBlock(content, block) {
  const blockMap = content
    .getCurrentContent()
    .getBlockMap();

  return ContentState.createFromBlockArray(
    blockMap
      .set(block.getKey(), block)
      .toArray(),
    content.getCurrentContent().getEntityMap()
  );
}

export function removeBlock(content, block) {
  const blockMap = content
    .getCurrentContent()
    .getBlockMap();

  return ContentState.createFromBlockArray(
    blockMap
      .delete(block.getKey())
      .toArray(),
    content.getCurrentContent().getEntityMap()
  );
}

export function isCursorAtEnd(block, selection) {
  return block.getText().length === selection.getFocusOffset();
}

export function splitBlockAndInsertCustomBlock(editor, content, selection, customBlockCharData, customBlockType) {
  const { cleanedContentState, updatedSelection } = removeSelectedText(content, selection);

  const splitState = Modifier.splitBlock(cleanedContentState, updatedSelection);

  const blockMap = splitState.getBlockMap().toList();
  const beforeSelection = splitState.getSelectionBefore();
  const beforeBlockIndex = blockMap.reduce((acc, block, index) => {
    if(block.get('key') === beforeSelection.getAnchorKey()) acc = index;
    return acc;
  }, 0);

  const start = blockMap.slice(0, beforeBlockIndex+1);
  const end = blockMap.slice(beforeBlockIndex+1);
  const newBlock = new ContentBlock({ key: genKey(), text: '', type: customBlockType, characterList: List([customBlockCharData]) });

  // If the last item is an empty string, add some char metadata so the Selection has something to grab onto.
  const endWithCharMeta = end.size === 1 && end.first().getText().trim().length === 0
    ? List([ end.first().set('text', '').set('characterList', List([CharacterMetadata.create({style: OrderedSet(), entity: null})])) ])
    : end;

  const constructedBlockMap = start.concat([newBlock]).concat(endWithCharMeta);
  const newContentState = ContentState.createFromBlockArray(constructedBlockMap.toArray(), cleanedContentState.getEntityMap());
  const newEditorState = EditorState.push(editor, newContentState, 'insert-fragment');
  const newSelectionState = SelectionState.createEmpty(endWithCharMeta.first().get('key'));

  return { newEditorState, newSelectionState, newBlock };
}

export function removeSelectedText(content, selection) {
  // Direction is important for removeRange, lets keep the Draft gods happy.
  const direction = selection.getIsBackward() ? 'backward' : 'forward';
  const cleanedContentState = !selection.isCollapsed()
                            ? Modifier.removeRange(content, selection, direction)
                            : content;
  const updatedSelection = selection.merge({
    anchorKey: direction === 'backward' ? selection.getFocusKey() : selection.getAnchorKey(),
    focusKey: direction === 'backward' ? selection.getFocusKey() : selection.getAnchorKey(),
    anchorOffset: direction === 'backward' ? selection.getFocusOffset() : selection.getAnchorOffset(),
    focusOffset: direction === 'backward' ? selection.getFocusOffset() : selection.getAnchorOffset()
  });

  return { cleanedContentState, updatedSelection };
}

// ATTN :: DOESNT WORK FOR SELECTED GROUPINGS
export function getInlineStyles(editorState) {
  const selection = editorState.getSelection();

  const styles = editorState.getCurrentContent()
    .getBlockForKey(selection.getAnchorKey())
    .getInlineStyleAt(selection.getStartOffset());

  const link = currentCharContainsLink(editorState);
  return link ? styles.concat('LINK') : styles;
}

// ATTN :: DOESNT WORK FOR SELECTED GROUPINGS
export function currentCharContainsLink(editorState) {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  if (!selection.isCollapsed()) return false;

  const entityKey = contentState
    .getBlockForKey(selection.getAnchorKey())
    .getEntityAt(selection.getStartOffset());

  return entityKey && contentState.getEntity(entityKey).getType() === 'LINK';
}

export function previousCharContainsEntityTypes(editorState, entityList) {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  if(!selection.isCollapsed) return false;

  const entityKey = editorState.getCurrentContent()
    .getBlockForKey(selection.getAnchorKey())
    .getEntityAt(parseInt(selection.getStartOffset(), 10)-1);

  return entityKey && entityList.indexOf(contentState.getEntity(entityKey).getType()) > -1;
}

export function doesPreviousWordContainValidUrl(block, selection, type) {
  const prevWord = block.getText().slice(0, selection.getAnchorOffset()).split(' ').pop();

  switch(type) {
    case 'EMBEDLY':
      return isEmbedlyUrlValid(prevWord);

    case 'GIST':
      return isGistUrlValid(prevWord);

    case 'LINK':
      return Validator.isURL(prevWord);

    case 'VIDEO':
      return getVideoData(prevWord);

    case 'TWEET':
      return isTwitterUrlValid(prevWord);

    default:
      return false;
  }
}

export function linkifyPreviousWord(block, selection, contentState) {
  const anchorOffset = selection.getAnchorOffset();
  const prevWord = block.getText().slice(0, anchorOffset).split(' ').pop();
  const prevWordWithProtocol = Validator.isURL(prevWord, { require_protocol: true })
    ? prevWord
    : `http://${prevWord}`;

  const wrappedSelection = selection.merge({
    anchorOffset: parseInt(anchorOffset, 10) - prevWord.length,
    focusOffset: parseInt(anchorOffset, 10)
  });

  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {href: prevWordWithProtocol});
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  return Modifier.applyEntity(
    contentStateWithEntity,
    wrappedSelection,
    entityKey
  );
}

export function getPreviousWord(block, selection) {
  return block.getText().slice(0, selection.getAnchorOffset()).split(' ').pop();
}

export function removePreviousWord(block, selection, contentState) {
  const anchorOffset = parseInt(selection.getAnchorOffset(), 10);
  const prevWord = block.getText().slice(0, anchorOffset).split(' ').pop();
  const startIndex = anchorOffset - prevWord.length;
  const updatedText = block.getText().slice(0, startIndex).concat(block.getText().slice(anchorOffset));
  const updatedBlock = block.set('text', updatedText);

  // Removes any character with entities that were mapped to the CharacterMeta.
  const updatedCharacterList = updatedBlock.getCharacterList().reduce((acc, char, i) => {
    if (i >= startIndex && i <= anchorOffset) return acc;
    return acc.push(char);
  }, List());

  const updatedBlockWithCharacterList = updatedBlock.set('characterList', updatedCharacterList);
  const updatedBlockMap = contentState.getBlockMap().set(block.getKey(), updatedBlockWithCharacterList);
  const updatedContentState = ContentState.createFromBlockArray(updatedBlockMap.toArray(), contentState.getEntityMap());
  const updatedSelection = selection.merge({
    anchorOffset: anchorOffset - prevWord.length,
    focusOffset: anchorOffset - prevWord.length
  });

  return { updatedContentState, updatedSelection };
}

export function getEntityDataByCursorPosition(editorState, callback) {
  // Expects selection to be collapsed.
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selection.getAnchorKey());

  block.findEntityRanges((character) => {
    return character.get('entity') === block.getEntityAt(selection.getStartOffset());
  }, (start, end) => {
    callback({
      content: block.get('text').slice(start, end),
      data: contentState.getEntity(block.getEntityAt(selection.getStartOffset())).get('data'),
      start,
      end
    });
  });
}

export function insertEmptySpace(contentState, selection, space) {
  space = space || ' ';
  const updatedContentState = Modifier.insertText(contentState, selection, space, null, null);
  const offset = parseInt(selection.getAnchorOffset(), 10) + space.length;

  const updatedSelection = selection.merge({
    anchorOffset: offset,
    focusOffset: offset
  });
  return { updatedContentState, updatedSelection };
}

export function insertNewOrSplitBlock(contentState, selection) {
  const { cleanedContentState, updatedSelection } = removeSelectedText(contentState, selection);
  const updatedContentState = Modifier.splitBlock(cleanedContentState, updatedSelection);
  const nextBlock = updatedContentState.getBlockAfter(updatedSelection.getAnchorKey());
  const newSelection = SelectionState.createEmpty(nextBlock.getKey());

  return { updatedContentState, updatedSelection: newSelection };
}

export function insertParagraph(block, contentState, selection) {
  const { cleanedContentState, updatedSelection } = removeSelectedText(contentState, selection);
  const blockMap = cleanedContentState.getBlockMap().toList();
  const blockIndex = blockMap.indexOf(block);

  const newBlock = new ContentBlock({ key: genKey(), text: ' ', type: 'unstyled', characterList: List([CharacterMetadata.create({})]) });
  const updatedBlockMap = blockMap.splice(blockIndex+1, 0, newBlock);
  const updatedContentState = ContentState.createFromBlockArray(updatedBlockMap.toArray(), contentState.getEntityMap());

  return {
    updatedContentState,
    updatedSelection: updatedSelection.merge({
      anchorKey: newBlock.get('key'),
      focusKey: newBlock.get('key'),
      anchorOffset: 0,
      focusOffset: 0,
      hasFocus: true,
      isBackward: false
    })
  };
}

export function removeInlineStylesFromBlock(block) {
  const characterList = block.getCharacterList();
  const cleanedCharList = characterList.map(char => {
    return CharacterMetadata.create({});
  });

  return block.set('characterList', cleanedCharList);
}

export function removeInlineStylesFromRange(editor) {
  const currentContentState = editor.getCurrentContent();
  const currentSelection = editor.getSelection();
  const keysToClean = getBlockKeysInSelection(currentContentState, currentSelection);

  const updatedContentState = keysToClean.reduce((acc, key) => {
    const updatedBlock = removeInlineStylesFromBlock(acc.getBlockForKey(key));
    const updatedBlockMap = acc.getBlockMap().set(updatedBlock.getKey(), updatedBlock);

    return acc.set('blockMap', updatedBlockMap);
  }, currentContentState);

  return EditorState.forceSelection(EditorState.push(editor, updatedContentState), currentSelection);
}

export function getBlockKeysInSelection(contentState, selection) {
  if(selection.isCollapsed()) return [ selection.getAnchorKey() ];

  let flag = false;
  const anchorKey = selection.get('isBackward') ? selection.getFocusKey() : selection.getAnchorKey();
  const focusKey = selection.get('isBackward') ? selection.getAnchorKey() : selection.getFocusKey();

  return contentState.getBlocksAsArray()
    .reduce((acc, block) => {
      if(block.getKey() === anchorKey) {
        flag = true;
        acc.push(block.getKey());
      } else if(flag) {
        acc.push(block.getKey());
      }
      flag = block.getKey() === focusKey ? false : flag;
      return acc;
    }, []);
}
