import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../wrappers/dropdown';

import layout from '../../../styles/global_ui/layout.css';
import menuStyles from '../../form_components/simple_select/simple_select.css';
import utilStyles from '../../../styles/global_ui/util.css';

const getOptions = ({ copyLinkText, extendedAdminOptions, isCurrentUserOriginModerator, isCurrentUserPostAuthorOrAdmin, isFlagged, onCopyLinkClick, onReportClick, isUserSignedIn, ...props }) => isCurrentUserPostAuthorOrAdmin
  ? getOptsForAuthor({ copyLinkText, extendedAdminOptions, isCurrentUserOriginModerator, isFlagged, onCopyLinkClick, ...props })
  : getOptsForNonAuthor({ copyLinkText, extendedAdminOptions, isCurrentUserOriginModerator, isFlagged, onCopyLinkClick, onReportClick, isUserSignedIn });

const getOptsForAuthor = ({ copyLinkText, extendedAdminOptions, isCurrentUserAnAdmin, isCurrentUserOriginModerator, isFlagged, onCopyLinkClick, onDeleteClick, onEditClick, onSpamClick }) => (
  <Fragment>
    <li className={menuStyles.option} onClick={onEditClick}>Edit</li>
    <li className={menuStyles.option} onClick={onDeleteClick}>Delete</li>
    <li className={menuStyles.option} onClick={onCopyLinkClick}>{copyLinkText}</li>

    {isCurrentUserAnAdmin && <hr className={`${utilStyles.hr} ${layout.marginTop10} ${layout.marginBottom10}`} />}
    {isCurrentUserAnAdmin
    && (
      <li
        className={isFlagged ? menuStyles.optionDisabled : menuStyles.option}
        onClick={() => !isFlagged ? onSpamClick() : null}
      >
        {!isFlagged ? 'Mark as spam' : 'Marked as spam'}
      </li>
    )}
    {(isCurrentUserAnAdmin || isCurrentUserOriginModerator) && extendedAdminOptions()}
  </Fragment>
);

const getOptsForNonAuthor = ({ copyLinkText, extendedAdminOptions, isCurrentUserOriginModerator, isFlagged, onCopyLinkClick, onReportClick, isUserSignedIn }) => (
  <Fragment>
    <li className={menuStyles.option} onClick={onCopyLinkClick}>{copyLinkText}</li>
    {isUserSignedIn
    && (
      <li
        className={isFlagged ? menuStyles.optionDisabled : menuStyles.option}
        onClick={() => !isFlagged ? onReportClick() : null}
      >
        {!isFlagged ? 'Report' : 'Reported'}
      </li>
    )}
    {isCurrentUserOriginModerator && extendedAdminOptions()}
  </Fragment>
);

const OptionsMenu = ({ toggleMenu, ...props }) => (
  <Dropdown
    key="menu"
    alignRight={true}
    alignTop={false}
    className={`${layout.paddingTop10} ${layout.paddingBottom10}`}
    dismiss={toggleMenu}
  >
    <ul className={utilStyles.ulReset}>
      {React.Children.toArray(getOptions(props))}
    </ul>
  </Dropdown>
);

OptionsMenu.propTypes = {
  copyLinkText: PropTypes.string,
  extendedAdminOptions: PropTypes.func,
  isCurrentUserAnAdmin: PropTypes.bool.isRequired,
  isCurrentUserOriginModerator: PropTypes.bool.isRequired,
  isCurrentUserPostAuthorOrAdmin: PropTypes.bool.isRequired,
  isFlagged: PropTypes.bool.isRequired,
  isUserSignedIn: PropTypes.bool.isRequired,
  onCopyLinkClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  onSpamClick: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

OptionsMenu.defaultProps = {
  copyLinkText: 'Copy link',
  extendedAdminOptions: () => {},
};

export default OptionsMenu;
