import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { replaceAllButNums } from '../../../../utility/strings';

import inputStyles from '../../../../styles/global_ui/inputs.css';

const MAX_LEN = 8; // 6 digits, 2 colons

const validation = (value) => {
  const parts = value.split(':');

  if (value.length !== MAX_LEN || parts.length !== 3) return 'please make sure the duration matches HH:MM:SS';
  if (parseInt(parts[1]) > 59 || parseInt(parts[2]) > 59) return 'input must be 0-59 for minutes and seconds';

  return null;
};

class DurationInput extends Component {
  constructor(props) {
    super(props);

    this.state = { prevValue: props.value };

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);

    // Refs
    this._input;
  }

  onBlur(e) {
    const value = this.props.value;
    const parts = value.split(':');

    // Invalid field, let the submit handler catch the validation error.
    if (parts.length !== 3) return;
    // All segments are filled out, we're done here.
    if (parts.every((p) => p.length === 2)) return;
    // Theres missing double digits, format them and update parent.
    const formatted = parts.map((p) => p.length === 1 ? `0${p}` : p).join(':');
    this.setState({ prevValue: formatted });
    this.props.onChange(e, formatted);
  }

  onChange(e) {
    const value = e.target.value;

    if (value.length >= MAX_LEN + 1) return;

    const { selectionStart, selectionEnd } = this._getCursorPosition();
    if (selectionStart !== selectionEnd) return this.props.onChange(value);

    const action = this.state.prevValue.length > value.length ? 'deleting' : 'adding';
    const formatted = action === 'adding' ? this._addColons(replaceAllButNums(value)) : value;

    this.setState({ prevValue: formatted });
    this.props.onChange(e, formatted);

    if (this._input && selectionStart !== formatted.length) {
      this._resetCursor({ formatted, selectionStart, value });
    }
  }

  _addColons(value) {
    const maxLengthMinusColons = MAX_LEN - 2;
    const everyNChar = 2;

    return [...value].reduce((acc, l, i) => ((i + 1) % everyNChar || (i + 1) === maxLengthMinusColons) ? acc.concat(l) : acc.concat(l + ':'), '');
  }

  _getCursorPosition(value) {
    if (!this._input) return { selectionStart: 0, selectionEnd: 0 };

    const selectionStart = this._input.selectionStart;
    const selectionEnd = this._input.selectionEnd;

    return { selectionStart, selectionEnd };
  }

  // React will reset any input that doesn't match its selection range.
  // Since we're formatting the string we need to reset the selection range.
  _resetCursor({ formatted, selectionStart, value }) {
    setTimeout(() => {
      const appendedColon = value.length < formatted.length;
      const pos = appendedColon ? (selectionStart + 1) : selectionStart;
      this._input.setSelectionRange(pos, pos);
    }, 10);
  }

  render() {
    return (
      <input
        ref={(el) => this._input = el}
        className={`${inputStyles.input} ${this.props.errors ? inputStyles.inputError : ''} ${this.props.classList.input}`}
        disabled={this.props.disabled}
        onBlur={this.onBlur}
        onChange={this.onChange}
        placeholder={this.props.placeholder}
        type="tel" // Nicer for mobile users
        value={this.props.value}
      />
    );
  }
}

DurationInput.propTypes = {
  classList: PropTypes.shape({ input: PropTypes.string }),
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
};

DurationInput.defaultProps = {
  classList: { input: '' },
  disabled: false,
  errors: null,
  placeholder: '00:00:00',
};

export { validation };
export default DurationInput;
