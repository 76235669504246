import React from 'react';
import PropTypes from 'prop-types';

const Link = (props) => {
  const { href } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <a data-offset-key={props.offsetKey} href={href}>
      {props.children}
    </a>
  );
};

Link.propTypes = {
  contentState: PropTypes.object.isRequired,
  decoratedText: PropTypes.string.isRequired,
  entityKey: PropTypes.string.isRequired,
  offsetKey: PropTypes.string.isRequired,
};

export default Link;
