/* eslint-disable react/jsx-key */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionableButton from '../../../client/buttons/actionable';
import Button from '../../../client/buttons/base';
import Dialog from '../../../client/reusable_components/Dialog';

import { graphMutate } from '../../../requests/graphql';
import errorHandler from '../../../services/error_handler';

import { getInObj } from '../../../utility/accessors';
import { summonGlobalMessenger } from '../../../utility/dispatchers';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const EMPTY_PROMPT_STATE = { prompt: { open: false } };
const PROMPT_COPY = {
  action: () => 'Request Approval',
  body: (type) => `The Hackster approval process is necessary for your ${type} hub to become fully public. Please ensure that your info is correct and complete before making this request. We will be notified once requested and conduct a review. If further changes are necessary, we will contact whomever made the request. You will receive an email shortly to verify this process.`,
  title: () => 'Request for approval',
};
// Add more states when rails form reactified.
const STATUS_MAP = {
  pending: { title: 'Pending approval', color: 'blank', disabled: true },
  request: { title: 'Request approval', color: 'outlineBlue', disabled: false },
};

class ChannelManageConfirmationButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: { open: false },
      isBusy: false,
      view: 'request', // ['request', 'pending', *add other states (i.e. pending) when we migrate to react]
    };

    this.createConfirmation = this.createConfirmation.bind(this);
  }

  /**
   * Methods
   */
  createConfirmation() {
    this.setState({ isBusy: true });

    return graphMutate({ t: 'create_channel_confirmation' }, { channel_id: this.props.channel.id })
      .then(() => {
        this.setState({
          ...EMPTY_PROMPT_STATE,
          isBusy: false,
          view: 'pending',
        }, () => summonGlobalMessenger(this._getToastMessageForConfirmationCreate(true)));
      })
      .catch((err) => {
        errorHandler('ChannelManageConfirmationButton createConfirmation', err);
        this.setState({
          ...EMPTY_PROMPT_STATE,
          isBusy: false,
        }, () => summonGlobalMessenger(this._getToastMessageForConfirmationCreate(false)));
      });
  }

  /**
   * Helpers
   */
  _getToastMessageForConfirmationCreate(isSuccessful) {
    return {
      msg: isSuccessful
        ? 'Successfully submitted request for channel approval.'
        : 'Sorry, we had an issue requesting channel approval.',
      type: isSuccessful ? 'success' : 'error',
    };
  }

  /**
   * Views
   */
  _getDialogActions() {
    return [
      <ActionableButton isBusy={this.state.isBusy} onClick={this.createConfirmation} text={PROMPT_COPY.action()} />,
      <Button colorStyle="cancel" disabled={this.state.isBusy} onClick={() => this.setState(EMPTY_PROMPT_STATE)}>Cancel</Button>,
    ];
  }

  render() {
    return (
      <div>
        <Button
          colorStyle={getInObj([this.state.view, 'color'], STATUS_MAP)}
          disabled={this.state.isBusy || getInObj([this.state.view, 'disabled'], STATUS_MAP)}
          onClick={() => this.setState({ prompt: { open: true } })}
          size="sm"
          style={{ lineHeight: '1.5' }}
        >
          {getInObj([this.state.view, 'title'], STATUS_MAP)}
        </Button>
        <Dialog
          actions={this._getDialogActions()}
          dismiss={() => this.setState(EMPTY_PROMPT_STATE)}
          open={this.state.prompt.open}
          title={<h2 className={`${typography.h2} ${layout.marginBottom30}`}>{PROMPT_COPY.title()}</h2>}
          wrapperStyle={{ width: 500 }}
        >
          {PROMPT_COPY.body(this.props.channel.type)}
        </Dialog>
      </div>
    );
  }
}

ChannelManageConfirmationButton.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default ChannelManageConfirmationButton;
