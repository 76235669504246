import React from 'react';
import PropTypes from 'prop-types';

import { getProjectLink, handleMainLinkClick } from './helpers';
import urlService from '../../../services/url_service';

const ProjectLink = ({ children, className, itemIndex, location, overrideTracking, project }) => (
  <a
    className={className}
    href={urlService.url(getProjectLink(project, 'url'))}
    onClick={(e) => handleMainLinkClick(e, project, location, overrideTracking, itemIndex)}
  >
    {children}
  </a>
);

ProjectLink.propTypes = {
  className: PropTypes.string,
  itemIndex: PropTypes.number,
  location: PropTypes.string,
  overrideTracking: PropTypes.func,
  project: PropTypes.shape({
    content_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    cover_image_url: PropTypes.string,
    difficulty: PropTypes.string,
    edit_url: PropTypes.string,
    guest_name: PropTypes.string,
    hid: PropTypes.string,
    name: PropTypes.string,
    one_liner: PropTypes.string,
    published_state: PropTypes.string,
    respects: PropTypes.number,
    stats: PropTypes.object,
    team: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
};

ProjectLink.defaultProps = {
  className: '',
  itemIndex: null,
  location: null,
  overrideTracking: null,
};

export default ProjectLink;
