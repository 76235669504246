import { fromJS, Map } from 'immutable';
import { graphQueryWithUserNoSigninDialog } from '../../requests/graphql';
import PromiseBatcher from '../../utility/promises/PromiseBatcher';
import postal from 'postal';

let _currentUser = new Map({});

class CurrentUser {
  constructor() {
    this.batch = new PromiseBatcher();
    this.initialized = false;
    this.isFetching = false;
    this.channel = postal.channel('currentUser');
  }

  // Do not call directly, use getAsync or getStoreAsync to access the request.  This is so that consecutive calls can be batched.
  __initialize() {
    this.isFetching = true;

    return graphQueryWithUserNoSigninDialog({ t: 'get_current_user' })
      .then((res) => {
        this.initialized = true;
        this.isFetching = false;

        if (res && res.user) {
          // fromJS needed to convert nested array (onboarding_progress).
          _currentUser = _currentUser.merge(fromJS(res.user));
        }

        return Promise.resolve({});
      })
      .catch((err) => Promise.reject(err));
  }

  /**
   * Arg permissions: [{ can_manage_platform: { key: id_of_permission, ql: {query: {t: 'template'}, variables: {id: id}} } }]
   * _currentUser permissions should look like: { can_manage_platform: { platform_id: bool } }
   *
   * ATTN: This will not handle cases where we need multiple of the same permissions per request.
   * Example: can_manage_platform can only ask for one platform by id, if we need multiple platform permissions
   * create a new GraphQL field that excepts a list of ids.
   */
  fetchPermissions(permissions) {
    return new Promise((resolve, reject) => {
      const { permissionsToFetch, permissionsToResolve } = permissions.reduce((acc, permission) => {
        const permissionName = Object.keys(permission)[0];

        if (this.hasIn([permissionName, permission[permissionName]['key']])) {
          acc.permissionsToResolve[permissionName] = { [permission[permissionName]['key']]: this.getIn([permissionName, permission[permissionName]['key']]) };
        } else {
          acc.permissionsToFetch.push(permission[permissionName]['ql']);
        }

        return acc;
      }, {
        permissionsToFetch: [],
        permissionsToResolve: {}, // We already have this resource, memoize it til we're done fetching.
      });

      if (!permissionsToFetch.length) { // Nothing to fetch, resolve what we have.
        return resolve(permissionsToResolve);
      }

      // 5-21-2021 - Platform permissions are currently the only permission being requested via whitelabel_page/index.js.
      permissionsToFetch.map((permission) => graphQueryWithUserNoSigninDialog(permission.query, permission.variables)
        .then(({ user }) => {
          if (!user) return resolve({});

          const permissionsFromServer = Object.keys(user).reduce((acc, permissionName) => {
            const permission = permissions.find((p) => Object.keys(p)[0] === permissionName);
            if (permission) {
              acc[permissionName] = { [permission[permissionName]['key']]: user[permissionName] };
              // Sets the value in the store. (user[permisssionName]: bool)
              this.setIn([permissionName, permission[permissionName]['key']], user[permissionName]);
            }

            return acc;
          }, {});

          resolve({ ...permissionsToResolve, ...permissionsFromServer });
        })
        .catch((err) => reject(err)));
    });
  }

  // 5-21-2021 - fetchProperties removed (no usage). fetchProperty still in use (address button/contest ideas).
  fetchProperty(property, query = null) {
    return new Promise((resolve, reject) => {
      if (this.has(property)) return resolve(this.get(property));

      return graphQueryWithUserNoSigninDialog(query)
        .then((res) => {
          if (res && res.user && res.user[property]) {
            this.set(property, res.user[property]);
            resolve(res.user[property]);
          } else {
            resolve({});
          }
        })
        .catch((err) => reject(err));
    });
  }

  get(key) {
    return _currentUser.get(key);
  }

  getProperties(properties) {
    return properties.reduce((acc, property) => this.has(property) ? { ...acc, [property]: this.get(property) } : acc, {});
  }

  getChannel() {
    return this.channel;
  }

  getId() {
    return this.get('id');
  }

  getIn(path) {
    return _currentUser.getIn(path);
  }

  getStore() {
    return _currentUser.toJS();
  }

  getAsync(key) {
    return new Promise((resolve, reject) => this.getStoreAsync()
      .then(() => this.has(key) ? resolve(this.get(key)) : resolve(null))
      .catch((err) => reject(err)));
  }

  getStoreAsync() {
    return new Promise((resolve, reject) => {
      if (this.has('id')) return resolve(this.getStore());

      this.batch.cachePromise({ resolve, reject });
      if (this.isFetching) return;

      return this.__initialize()
        .then(() => this.batch.resolveBatch(this.getStore()))
        .catch((err) => this.batch.rejectBatch(err));
    });
  }

  has(key) {
    return _currentUser.has(key);
  }

  hasIn(path) {
    return _currentUser.hasIn(path);
  }

  set(key, value) {
    _currentUser = _currentUser.set(key, value);
  }

  setIn(path, value) {
    _currentUser = _currentUser.setIn(path, value);
  }

  /**
   * Onboarding
   */
  _getOnboardingAsBool() {
    const completed = this.get('onboarding_complete');
    return typeof completed === 'boolean' ? completed : false;
  }

  getCurrentOnboardingStatus() {
    return new Promise((resolve, reject) => this.getStoreAsync()
      .then(() => resolve(this._getOnboardingAsBool()))
      .catch((err) => reject(err)));
  }

  /** Test helpers. */
  __refresh__() {
    _currentUser = new Map({});

    this.batch = new PromiseBatcher();
    this.initialized = false;
    this.isFetching = false;
    this.channel = postal.channel('currentUser');
  }

  __getCurrentUser__() {
    return _currentUser;
  }

  __setCurrentUser__(user) {
    _currentUser = user;
  }
}

const currentUser = new CurrentUser();
export default currentUser;
