import PropTypes from 'prop-types';
import React from 'react';

import FormGroup from '../../wrappers/form_group';

import { isBlank } from '../../../../utility/types';

import utilStyles from '../../../../styles/global_ui/util.css';
import inputStyles from '../../../../styles/global_ui/inputs.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

const _getRadioButton = ({ button, disabled, hasErrors, name, onChange, value }) => {
  const isChecked = (!isBlank(value) && value.toString() === button.value.toString());
  const isDisabled = !!(disabled || button.disabled);

  return (
    <label key={button.value} className={`${inputStyles.radioButtonContainer} ${disabled ? utilStyles.disabled : ''}`}>
      <div className={inputStyles.radioGroupWrapper}>
        <input
          checked={isChecked}
          className={`${inputStyles.radioButton} ${hasErrors ? inputStyles.radioButtonError : ''}`}
          disabled={isDisabled}
          name={name}
          onChange={onChange}
          type="radio"
          value={button.value}
        />
        <span />
        {' '}
        {/* The circle */}

        <span className={`${typography.bodyM} ${layout.marginLeft10}`}>
          {button.label}
        </span>
      </div>
    </label>
  );
};

const RadioGroup = ({ buttons, classList, disabled, errors, helperText, label, name, onChange, value }) => {
  const hasErrors = !isBlank(errors);

  return (
    <FormGroup
      classList={{ root: classList.root }}
      errors={errors}
      helperText={helperText}
      label={label}
      name={name}
      value=""
    >
      <div className={inputStyles.radioGroup}>
        {buttons.map((button) => _getRadioButton({ button, disabled, hasErrors, name, onChange, value }))}
      </div>
    </FormGroup>
  );
};

RadioGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.node,
    value: PropTypes.any,
  })),
  classList: PropTypes.shape({ root: PropTypes.string }),
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  helperText: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

RadioGroup.defaultProps = {
  buttons: [],
  classList: {},
  disabled: false,
  errors: null,
  helperText: null,
  label: null,
  name: null,
  onChange: () => {},
  value: '',
};

export default RadioGroup;
