import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import ActionableButton from '../../buttons/actionable';
import AuthPanelHeader from './AuthPanelHeader';
import BasicFormInput from '../../form_components/inputs/basic_form_input';
import CheckboxGroup from '../../form_components/inputs/checkbox_group';
import CTA from './cta';
import ReCAPTCHA from '../recaptcha';
import SignupTOS from './SignupTOS';
import SocialAuth from './social';
import Switcher from './Switcher';

import isMobileBrowser from '../../../utility/isMobileBrowser';
import keenService from '../../../services/keen/main';
import { authRequest } from '../../../requests/authentication';
import { filterObject } from '../../../utility/filters';
import { getErrorForField, getFieldValuesAsObject, initFields, setIsBusy, setStateOrError, validateFields } from '../../../utility/forms';
import { getFormErrorView } from '../../form_components/templates';
import { getInObj, getFirstString } from '../../../utility/accessors';
import { isBlank } from '../../../utility/types';
import { isEmail, isRequired, minLength, maxLength } from '../../../services/validation/validators';
import { mapifyStringQuery, mapToStringQuery, transformObjValues } from '../../../utility/converters';
import { windowLocationRedirect } from '../../../services/window';

import { DEFAULT } from '../constants';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './auth_panel.css';

const EMAIL_EXISTS_MSG = 'has already been taken';
const EMAIL_INVALID_MSG = 'is not allowed';
const GENERAL_ERROR = 'Something went wrong. Please try again.';
const GENERAL_ERROR_COUNT_LIMIT = 3;

const FIELDS_TEMPLATE = {
  email: { customRequired: (val) => isRequired(val, 'Please enter an email address.'), validate: (val) => isEmail(val, 'Please enter a valid email address'), value: '' },
  name: { customRequired: (val) => isRequired(val, 'Please enter your name or nickname.'), validate: (val) => minLength(3, val), value: '' },
  newsletter: { notRequired: true, validate: () => null, value: [], formatOut: (vals) => vals.includes('newsletter') },
  password: { customRequired: (val) => isRequired(val, 'Please enter a password.'), validate: (val) => (minLength(8, val) || maxLength(64, val)), value: '' },
};

const NO_VALIDATION = { notRequired: true, validate: () => null };
const ONCHANGE_STATE_SPREAD = { formError: '' };

const PANEL_FIELD_KEYS = {
  login: ['email', 'password'],
  signup: ['name', 'email', 'newsletter'],
};

const PANEL_RESET_STATE = {
  errors: {},
  formError: '',
  recaptchaPending: false,
};

const SERVER_ERROR_MAP = {
  EMAIL_NOT_REGISTERED: 'This email is not registered',
  PASSWORD_INVALID: 'Invalid password',
};

const translateError = (error) => {
  const errString = getFirstString(error);

  return SERVER_ERROR_MAP[errString] || errString;
};

class AuthPanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...PANEL_RESET_STATE,
      currentPanel: props.currentPanel,
      fields: initFields(FIELDS_TEMPLATE),
      generalErrorCount: 0,
      isBusy: false,
      recaptchaReady: false,
      showEmailMsg: false,
    };

    this.handleEmailExists = this.handleEmailExists.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaCallback = this.recaptchaCallback.bind(this);
    this.setCurrentPanel = this.setCurrentPanel.bind(this);

    // Form helpers
    this.getErrorForField = getErrorForField.bind(this);
    this.getFieldValuesAsObject = getFieldValuesAsObject.bind(this);
    this.setIsBusy = setIsBusy.bind(this);
    this.setStateOrError = setStateOrError.bind(this);
    this.validate = validateFields.bind(this);

    // refs
    this._recaptcha;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    if (!this.props.analyticsEventOnMount) return;

    const { eventName, customProps } = this.props.analyticsEventOnMount;
    keenService.reportEvent({ eventName }, customProps);
  }

  /**
   * Methods
   */
  handleEmailExists() {
    this.setState({
      ...PANEL_RESET_STATE,
      currentPanel: 'login',
      isBusy: false,
      showEmailMsg: true,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.isBusy) return;

    const validationOverrideMap = this._isSignupPanel() ? { password: NO_VALIDATION } : { name: NO_VALIDATION };
    const valid = this.validate({ doScroll: false, validationOverrideMap });

    this.setState({
      formError: '',
      isBusy: valid,
    });

    if (!valid) return;

    this.submitWithRecaptcha();
  }

  recaptchaCallback(recaptchaResponse) {
    if (this.state.recaptchaPending && recaptchaResponse) {
      this.setState({ recaptchaPending: false });
      this.submitForm(recaptchaResponse);
    } else {
      this.setState({
        formError: this._getGeneralError(),
        generalErrorCount: this.state.generalErrorCount + 1,
        recaptchaPending: false,
        isBusy: false,
      });
    }

    if (this._recaptcha && this._recaptcha.reset) this._recaptcha.reset();
  }

  setCurrentPanel(currentPanel) {
    this.setState({
      ...PANEL_RESET_STATE,
      currentPanel,
      showEmailMsg: false,
    });
  }

  submitForm(recaptchaResponse) {
    const url = this.props.formActions[this.state.currentPanel];
    const currentValues = filterObject(this.getFieldValuesAsObject(), null, PANEL_FIELD_KEYS[this.state.currentPanel]);
    const newsletter = this._isSignupPanel() ? { newsletter: currentValues.newsletter } : {};

    // Remove newsletter from the user object, devise will expect anything in this object to be accessable on the user model.
    delete currentValues.newsletter;

    const params = {
      'g-recaptcha-response': recaptchaResponse,
      'analytics': this.props.analytics,
      'location': this.props.location,
      'source': this.props.source,
      'user': currentValues,
      ...newsletter,
    };

    return authRequest(url, params)
      .then((res) => {
        this.props.onSubmit(res);
        windowLocationRedirect(this._getRedirectUrl());
      })
      .catch((err) => this._processServerErrors(err));
  }

  submitWithRecaptcha() {
    if (this._recaptcha && this.state.recaptchaReady) {
      this.setState({ recaptchaPending: true }, () => this._recaptcha.execute());
    } else {
      this.setState({
        formError: this._getGeneralError(),
        generalErrorCount: this.state.generalErrorCount + 1,
        isBusy: false,
      });
    }
  }

  /**
   * Helpers
   */

  _extractServerErrors(err) {
    const errors = getInObj(['response', 'body', 'errors'], err) || {};
    const formError = getInObj(['response', 'body', 'error'], err) || '';

    if (formError === 'PASSWORD_INVALID') {
      return {
        errors: { ...errors, password: formError },
        formError: '',
      };
    }

    return {
      errors,
      formError: (formError === 'PASSWORD_NOT_CREATED') ? this._getNoPasswordMsg() : formError,
    };
  }

  _getFormError(isGeneralError, errors, formError) {
    if (isGeneralError) {
      return this._getGeneralError();
    } else if (errors.email && errors.email.includes(EMAIL_INVALID_MSG)) {
      return this._getMailgunInvalidMsg();
    } else {
      return formError || '';
    }
  }

  _getRedirectUrl() {
    return this.props.redirectPaths[this.state.currentPanel];
  }

  _isSignupPanel() {
    return this.state.currentPanel === 'signup';
  }

  _processOmniauthUrl(url) {
    const urlParts = url.split('?');
    const queryMap = mapifyStringQuery(urlParts[1]);

    queryMap.location = this.props.location;
    queryMap.source = this.props.source || queryMap.source;
    queryMap.redirect_to = this.props.redirectPaths.login; // TODO: do we actually always want to redirect to the login path for omniauth links?
    const query = mapToStringQuery(queryMap);

    return `${urlParts[0]}${query.length ? `?${query}` : ''}`;
  }

  _processOmniauthUrls() {
    return transformObjValues(this.props.omniauthUrls, (url) => this._processOmniauthUrl(url));
  }

  _processServerErrors(err) {
    const { errors, formError } = this._extractServerErrors(err);

    if (this._isSignupPanel() && errors.email && errors.email.includes(EMAIL_EXISTS_MSG)) {
      // switch to login panel because this email already has an account
      this.handleEmailExists();
    } else {
      const isGeneralError = (!formError && isBlank(errors));

      this.setState({
        errors: { ...this.state.errors, ...errors },
        formError: this._getFormError(isGeneralError, errors, formError),
        generalErrorCount: isGeneralError ? this.state.generalErrorCount + 1 : this.state.generalErrorCount,
        isBusy: false,
      });
    }
  }

  _shouldAutoFocus(isFirst) {
    return isFirst && this.props.autoFocus && !isMobileBrowser();
  }

  _shouldShowCTA() {
    return this.props.showCTAConfig[this.state.currentPanel];
  }

  /**
   * Views
   */
  _getDivider() {
    return (
      <div className={`${layout.flexCenterItems} ${layout.marginBottom5}`}>
        <div className={`${layout.flex1} ${utilStyles.borderTop}`} />
        <div className={`${layout.padding5}`}>or</div>
        <div className={`${layout.flex1} ${utilStyles.borderTop}`} />
      </div>
    );
  }

  _getGeneralError() {
    if (this.state.generalErrorCount < GENERAL_ERROR_COUNT_LIMIT) return GENERAL_ERROR;

    return `We are having trouble ${this._isSignupPanel() ? 'signing you up' : 'logging you in'}. Please email us at help@hackster.io to resolve the issue.`;
  }

  _getMailgunInvalidMsg() {
    return (
      <Fragment>
        {'We are having trouble verifying your email address. '}
        <a className={typography.link} href="https://help.hackster.io/en/articles/5983103-why-can-t-my-email-be-verified">Read more</a>
        .
      </Fragment>
    );
  }

  _getNoPasswordMsg() {
    return (
      <Fragment>
        {"You haven't created a password yet! To do so, click the link in the email we've sent you. No email? "}
        <a className={`${typography.errorToWhite} ${typography.bold} ${typography.underline}`} href={`${this.props.confirmationPath}?user[email]=${this.state.fields.email.value}`}>Resend password email</a>
        .
      </Fragment>
    );
  }

  _getEmailHelperText(error) {
    if (this._isSignupPanel()) return 'We will never share your email with others.';
    if (error === SERVER_ERROR_MAP.EMAIL_NOT_REGISTERED) {
      return (
        <Switcher currentConfig="email" disabled={this.state.isBusy} onClick={this.setCurrentPanel} />
      );
    }

    return null;
  }

  _getEmailField(isFirst = false) {
    const errors = this.state.errors.email;

    return (
      <BasicFormInput
        autoFocus={this._shouldAutoFocus(isFirst)}
        classList={{ root: layout.marginBottom15 }}
        disabled={this.state.isBusy}
        errors={translateError(errors)}
        helperText={this._getEmailHelperText(errors)}
        label="Email address"
        name="email"
        onChange={(e) => this.setStateOrError(null, 'email', e.target.value, ONCHANGE_STATE_SPREAD)}
        placeholder="you@example.com"
        type="email"
        value={this.state.fields.email.value}
      />
    );
  }

  _getNameField(isFirst = false) {
    return (
      <BasicFormInput
        autoFocus={this._shouldAutoFocus(isFirst)}
        classList={{ root: layout.marginBottom15 }}
        disabled={this.state.isBusy}
        errors={translateError(this.state.errors.name)}
        label="Full name or nickname"
        name="name"
        onChange={(e) => this.setStateOrError(null, 'name', e.target.value, ONCHANGE_STATE_SPREAD)}
        placeholder="First and last name"
        value={this.state.fields.name.value}
      />
    );
  }

  _getNewsletterField() {
    return (
      <CheckboxGroup
        onChange={(values) => this.setStateOrError(null, 'newsletter', values, ONCHANGE_STATE_SPREAD)}
        options={[{
          label: (<strong>Sign up for the Hackster Newsletter</strong>),
          value: 'newsletter',
        }]}
        values={this.state.fields.newsletter.value}
      />
    );
  }

  _getPasswordField() {
    return (
      <BasicFormInput
        classList={{ root: layout.marginBottom15 }}
        disabled={this.state.isBusy}
        errors={translateError(this.state.errors.password)}
        helperText={<a className={typography.linkBlue} href={this.props.forgotPasswordUrl}>Forgot password?</a>}
        label="Password"
        name="password"
        onChange={(e) => this.setStateOrError(null, 'password', e.target.value, ONCHANGE_STATE_SPREAD)}
        placeholder="Enter your password"
        type="password"
        value={this.state.fields.password.value}
      />
    );
  }

  _getLoginFields() {
    return React.Children.toArray([this._getEmailField(true), this._getPasswordField()]);
  }

  _getSignupFields() {
    return React.Children.toArray([this._getNameField(true), this._getEmailField(false), this._getNewsletterField()]);
  }

  render() {
    const { currentPanel, formError, isBusy } = this.state;
    const isSignupPanel = this._isSignupPanel();

    const showCTA = this._shouldShowCTA();

    return (
      <div className={`${typography.bodyM} ${showCTA ? styles.panelWithCTA : ''}`}>
        {showCTA
        && (
          <CTA
            ctaVersion={this.props.ctaVersion}
            whitelabelName={this.props.whitelabelName}
          />
        )}
        <div className={styles.formPanelWrapper}>
          <div className={styles.formPanel}>
            <AuthPanelHeader
              blankHeader={this.props.blankHeader}
              currentPanel={this.state.currentPanel}
              showCTA={showCTA}
              showEmailMsg={this.state.showEmailMsg}
              simplified={this.props.simplified}
              whitelabelName={this.props.whitelabelName}
            />
            <SocialAuth
              currentPanel={this.state.currentPanel}
              omniauthUrls={this._processOmniauthUrls()}
            />
            {this._getDivider()}
            <form action="#" method="post" noValidate onSubmit={this.handleSubmit}>
              {isSignupPanel ? this._getSignupFields() : this._getLoginFields()}
              {getFormErrorView(formError)}
              {isSignupPanel && <SignupTOS rootPath={this.props.pathHelpers.rootPath} />}
              <ActionableButton
                buttonType="submit"
                isBusy={isBusy}
                size="lg"
                text={isSignupPanel ? 'Join Hackster' : 'Log in'}
                type="fullWidth"
              />
            </form>
            <ReCAPTCHA
              ref={(c) => this._recaptcha = c}
              callback={this.recaptchaCallback}
              className={layout.marginTop30}
              onLoad={() => this.setState({ recaptchaReady: true })}
              recaptchaSiteKey={this.props.recaptchaSiteKey}
            />
            <Switcher currentConfig={currentPanel} disabled={isBusy} onClick={this.setCurrentPanel} />
          </div>
        </div>
      </div>
    );
  }
}

AuthPanel.propTypes = {
  analytics: PropTypes.shape({
    page_type: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
  }).isRequired,
  autoFocus: PropTypes.bool,
  blankHeader: PropTypes.bool,
  confirmationPath: PropTypes.string.isRequired,
  ctaVersion: PropTypes.string,
  currentPanel: PropTypes.oneOf(['login', 'signup']),
  forgotPasswordUrl: PropTypes.string.isRequired,
  formActions: PropTypes.shape({
    login: PropTypes.string,
    signup: PropTypes.string,
  }).isRequired,
  location: PropTypes.string,
  omniauthUrls: PropTypes.shape({
    facebook: PropTypes.string,
    github: PropTypes.string,
    google_oauth2: PropTypes.string,
    twitter: PropTypes.string,
    windowslive: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func,
  pathHelpers: PropTypes.shape({ rootPath: PropTypes.string.isRequired }).isRequired,
  recaptchaSiteKey: PropTypes.string.isRequired,
  redirectPaths: PropTypes.shape({
    login: PropTypes.string.isRequired,
    signup: PropTypes.string.isRequired,
  }).isRequired,
  showCTAConfig: PropTypes.shape({
    login: PropTypes.bool,
    signup: PropTypes.bool,
  }),
  simplified: PropTypes.bool,
  source: PropTypes.string,
  whitelabelName: PropTypes.string,
};

AuthPanel.defaultProps = {
  autoFocus: true,
  blankHeader: false,
  ctaVersion: DEFAULT,
  currentPanel: 'signup',
  location: 'signin_dialog',
  onSubmit: () => {},
  showCTAConfig: {
    login: false,
    signup: true,
  },
  simplified: false,
  source: '',
  whitelabelName: null,
};

export default AuthPanel;
