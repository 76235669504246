/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React from 'react'; // Neccessary for tests.
import { v1 as uuidv1 } from 'uuid';

import AlgoliaAsyncSelect from '../../../client/algolia/async_select';
import CTAForm from './cta_form';
import PromotedContentForm from './promoted_content_form';
import Select from '../../../client/form_components/select';
import VideosForm from './videos_form';

import errorHandler from '../../../services/error_handler';
import { pluralize } from '../../../utility/formatters';

import inputStyles from '../../../styles/global_ui/inputs.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './home_sections.css';

/**
 * Components
 */
const NOOP_COMPONENT = () => (<div />);

const createAsyncSelect = (ctx, {
  algoliaParameters,
  algoliaRecordsToOptions,
  algoliaService,
  placeholder = 'Select an option from the dropdown or type to search',
  type,
}) => (
  <AlgoliaAsyncSelect
    key={type}
    algoliaParameters={algoliaParameters}
    algoliaProjectsService={ctx['algoliaProjectsService']}
    algoliaRecordsToOptions={algoliaRecordsToOptions}
    algoliaService={ctx[algoliaService]}
    errors={ctx.getErrorsForType(type)}
    maxWidth={500}
    onSelect={(option) => ctx.setCurrentSelectionForType(type, option)}
    placeholder={placeholder}
    value={ctx.getValueForType(type)}
  />
);

const createCTAForm = (ctx) => (
  <CTAForm
    ref={(el) => ctx['ctaForm'] = el}
    propagateStatus={ctx.toggleBusyStatus}
  />
);

const createPromotedContent = (ctx) => (
  <PromotedContentForm
    ref={(el) => ctx['promotedContent'] = el}
    algoliaPartsService={ctx['algoliaPartsService']}
    algoliaTagsService={ctx['algoliaTagsService']}
    propagateStatus={ctx.toggleBusyStatus}
  />
);

const createVideos = (ctx) => (
  <VideosForm
    ref={(el) => ctx['video'] = el}
    propogateStatus={ctx.toggleBusyStatus}
  />
);

const createSelect = (ctx, {
  placeholder = 'Select an option from the dropdown',
  type,
}) => (
  <Select
    key={type}
    errors={ctx.getErrorsForType(type)}
    maxWidth={500}
    onSelectedChange={(opt) => ctx.setCurrentSelectionForType(type, opt)}
    options={listToSelectOptions(ctx.getSelectionListForType(type))}
    placeholder={placeholder}
    value={ctx.getValueForType(type)}
  />
);

const createCTAComponentLabel = () => (
  <h2 className={`${typography.h2} ${layout.marginBottom30}`}>Create a call to action item</h2>
);

export const createOptionLabel = ({ name, projects_count }, projectCountThreshold) => (
  <span className={`${styles.option} ${projects_count < projectCountThreshold && styles.optionDisabled}`}>
    <span className={styles.optionName}>{name}</span>
    <span>
      (
      {projects_count}
      {' '}
      {pluralize('project', projects_count)}
      )
    </span>
  </span>
);

export const createSelectComponentLabel = (type) => (
  <label className={inputStyles.label}>
    {`Select a ${type}`}
  </label>
);

/**
 * Translators
 */
const ctaToSection = (cta) => ({
  key: 'cta',
  label: cta.name,
  meta: {
    image_name: cta.image.name,
    image_url: cta.image.url,
    link: cta.link,
  },
  title: cta.name,
  value: cta.image.id,
});

const promotedContentToSection = (content) => ({
  key: 'promoted',
  label: 'Promoted content',
  meta: { content: content.content },
  title: content.title,
  value: uuidv1(),
});

const videoContentToSection = (content) => ({
  key: 'video',
  label: 'Videos',
  meta: { content: content.content },
  title: content.title,
  value: uuidv1(),
});

const recordToSection = (record, key) => ({
  key,
  label: record.name,
  title: record.name,
  value: record.id,
});

const recordsToSelectOptions = (records = [], projectsCountByIds = null, projectCountThreshold = 1) => records.map((record) => {
  // 07-30-21
  // In the past, we used Algolia for all projects lists on home sections. That was deprecated for the CachedProject matview.
  // Before we used the Algolia facet for the projects count as a source of truth. The issue is that Algolia will
  // return only 100 facets at a time. If we're unlucky and the facet that we're looking for isn't included, fallback
  // to using the record's project_count column. The reason why don't just use the column is that the counters are
  // frequently off due to the mixin bug. Once the counters issue is fixed, we can clean all this up.
  const projects_count = projectsCountByIds && projectsCountByIds.hasOwnProperty(record.id)
    ? projectsCountByIds[record.id]
    : record.hasOwnProperty('projects_count')
      ? record.projects_count
      : 0;

  return {
    disabled: (projects_count < projectCountThreshold),
    label: record.name,
    labelView: createOptionLabel({ name: record.name, projects_count }, projectCountThreshold),
    value: record.id,
    record,
  };
});

const listToSelectOptions = (list = []) => list.map((item) => ({
  disabled: false,
  label: item.label,
  value: item.value,
  record: item,
}));

/**
 * Facet helpers
 */
const buildFacetsForAsyncSelect = (sections, initFacet, sectionKey, algoliaKey) => {
  const ids = sections.reduce((acc, section) => section.key === sectionKey ? acc.concat(`${algoliaKey}:-${section.value}`) : acc, []);

  return ids.length ? [...ids, ...initFacet] : initFacet;
};

/**
 * Main Config
 */
const SECTION_TYPES = {
  cta: () => ({
    component: (ctx) => createCTAForm(ctx),
    label: () => createCTAComponentLabel(),
    toSection: (cta) => ctaToSection(cta),
  }),
  collection: () => ({
    component: (ctx) => createSelect(ctx, { type: 'collection' }), // Expected in AddSectionView.props.collections
    label: () => createSelectComponentLabel('Collection'),
    toSection: (record) => record,
  }),
  contest: () => ({
    component: (ctx) => createAsyncSelect(ctx, {
      algoliaParameters: {
        hitsPerPage: 100,
        initFacet: buildFacetsForAsyncSelect(ctx.props.existingSections, [], 'contest_id', 'id'),
        projectsServiceFacetKey: 'contest_ids',
      },
      algoliaRecordsToOptions: (records, projectsCountByIds) => recordsToSelectOptions(records, projectsCountByIds, 3),
      algoliaService: 'algoliaContestsService',
      type: 'contest',
    }),
    label: () => createSelectComponentLabel('Contest'),
    toSection: (record) => recordToSection(record, 'contest_id'),
  }),
  platform: () => ({
    component: (ctx) => createAsyncSelect(ctx, {
      algoliaParameters: {
        hitsPerPage: 100,
        initFacet: buildFacetsForAsyncSelect(ctx.props.existingSections, ['model:Platform'], 'platform_id', 'id'),
        projectsServiceFacetKey: 'platforms.id',
      },
      algoliaRecordsToOptions: recordsToSelectOptions,
      algoliaService: 'algoliaPlatformsService',
      type: 'platform',
    }),
    label: () => createSelectComponentLabel('Platform'),
    toSection: (record) => recordToSection(record, 'platform_id'),
  }),
  promotedContent: () => ({
    actionLabel: () => (<p>You must add at least 4 items before publishing</p>),
    component: (ctx) => createPromotedContent(ctx),
    label: () => null,
    toSection: (content) => promotedContentToSection(content),
  }),
  projectCollection: () => ({
    component: (ctx) => createSelect(ctx, { type: 'projectCollection' }), // Expected in AddSectionView.props.collections
    label: () => createSelectComponentLabel('Project collection'),
    toSection: (record) => record,
  }),
  topic: () => ({
    component: (ctx) => createAsyncSelect(ctx, {
      algoliaParameters: {
        hitsPerPage: 100,
        initFacet: buildFacetsForAsyncSelect(ctx.props.existingSections, ['display_on_home:true'], 'topic_id', 'id'),
        projectsServiceFacetKey: 'platforms.id', // All channels are under the platforms object now (04/01/2020)
      },
      algoliaRecordsToOptions: recordsToSelectOptions,
      algoliaService: 'algoliaTopicsService',
      type: 'topic',
    }),
    label: () => createSelectComponentLabel('Topic'),
    toSection: (record) => recordToSection(record, 'topic_id'),
  }),
  video: () => ({
    component: (ctx) => createVideos(ctx),
    label: () => null,
    toSection: (content) => videoContentToSection(content),
  }),
  _failSafe: () => ({
    component: () => NOOP_COMPONENT,
    label: () => createSelectComponentLabel('...'),
    toSection: () => {},
  }),
};

export default function getConfigByType(type) {
  if (!SECTION_TYPES.hasOwnProperty(type)) {
    errorHandler(`AddSectionView getConfigByType expected type, ${type} to exist.  Known keys: ${Object.keys(SECTION_TYPES)}`);

    return SECTION_TYPES._failSafe();
  }

  return SECTION_TYPES[type]();
}
