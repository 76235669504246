import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NewsArticleAnalytics from './analytics';
import NewsArticleForm from './form';
import NewsArticlesList from './list';
import NewsArticlePreview from './preview';
import NotFoundPage from '../../not_found';
import PublishDialog from './templates/PublishDialog';
import Prompt from '../../../client/reusable_components/Dialog/Prompt';

import AlgoliaPartsService from '../../../services/algolia/parts_service';
import AlgoliaPlatformsService from '../../../services/algolia/platforms_service';
import AlgoliaTagsService from '../../../services/algolia/tags_service';
import AlgoliaTopicsService from '../../../services/algolia/topics_service';

import { graphQuery, graphMutate } from '../../../requests/graphql';
import { DRAFT_STATUS, PUBLISHED_STATUS, SUBMITTED_STATUS, getSortEnumForFilter, getStatusForFilterEnum } from '../../../graphql/news/enums';

import createHistory, { cleanPreAndPostSlashes } from '../../../client/reusable_components/Router/history';
import { initCurrentPath } from '../../../client/reusable_components/Router';

import { cleanUrlsFromCarousels } from '../../../client/draftster';

import errorHandler from '../../../services/error_handler';
import { windowScrollTo } from '../../../services/window';
import { getInObj } from '../../../utility/accessors';
import { mapifyStringQuery, mapToStringQuery } from '../../../utility/converters';
import { summonGlobalMessenger } from '../../../utility/dispatchers';
import { removeFromObject } from '../../../utility/filters';
import { getCurrentOrPreviousPagePostDeletion, getPageFromQueryMap } from '../../../utility/pagination';

import { SIMPLE_PAGINATION } from '../../../constants/pagination';

const LOCKED_ARTICLE_ERROR = 'This article cannot be unpublished or deleted while featured on the News Page.';
const CURRENT_EDITING_RECORD_EMPTY_STATE = { hasChanges: false, record: null };
const EMPTY_DIALOG_STATE = {
  open: false,
  props: null,
  type: null, // ['dialog', 'prompt']
};
const DELETE_PROMPT = {
  action: 'Delete',
  colorClass: 'danger',
  body: 'This action cannot be undone. Deleted articles are gone forever.',
  title: 'Are you sure you want to delete this article?',
};
const REDIRECT_PROMPT = {
  action: 'Leave page',
  body: 'You will lose any info you added or changed if you leave this page.',
  title: 'Are you sure you want to leave without saving?',
};
const UNSAVED_CHANGED_MSG = 'There are unsaved changes.';

class NewsAdminPage extends Component {
  constructor(props) {
    super(props);

    const currentPath = initCurrentPath(props.path_helpers.fullPath, props.path_helpers.basePath);

    this.history = createHistory(props.path_helpers.basePath);
    this.state = {
      currentEditingRecord: CURRENT_EDITING_RECORD_EMPTY_STATE,
      currentHistoryData: this.history.location,
      currentPath: currentPath,
      currentView: this._getCurrentViewForPath(currentPath), // ['default', 'form,', 'not_found', 'preview']
      dialog: EMPTY_DIALOG_STATE,
      isAdminOrEditor: this._isAdminOrEditor(),
      isBusy: false, // For crud requests
      isNavigating: true, // For page navigation/initial load
      offset: 0, // Increment / Decrement on create / delete for simple pagination
      pagination: SIMPLE_PAGINATION,
      records: [],
    };

    this.deleteArticle = this.deleteArticle.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSearchQueryUpdate = this.handleSearchQueryUpdate.bind(this);
    this.handleUnloadEvent = this.handleUnloadEvent.bind(this);
    this.postOrUpdateArticle = this.postOrUpdateArticle.bind(this);
    this.publishOrScheduleArticleViaDialog = this.publishOrScheduleArticleViaDialog.bind(this);
    this.submitOrPublishArticle = this.submitOrPublishArticle.bind(this);
    this.unpublishArticle = this.unpublishArticle.bind(this);

    // Algolia services
    this.algoliaPartsService = new AlgoliaPartsService();
    this.algoliaPlatformsService = new AlgoliaPlatformsService();
    this.algoliaTagsService = new AlgoliaTagsService();
    this.algoliaTopicsService = new AlgoliaTopicsService();
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this.unlisten = this.history.listen(this.handleLocationChange);
    window.addEventListener('beforeunload', this.handleUnloadEvent);
    this._init();
    this._scrollToTop();
  }

  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener('beforeunload', this.handleUnloadEvent);
  }

  /**
   * Initializers
   */
  _init() {
    this._fetchRecordsForCurrentView(this.state.currentView, this.state.currentPath);
  }

  /**
   * Methods
   */
  deleteArticle(record) {
    if (this.props.news_page_ids.includes(record.id)) return summonGlobalMessenger({ msg: LOCKED_ARTICLE_ERROR, type: 'error' });
    this._summonDialog('prompt', { record, type: 'delete' });
  }

  handleLocationChange(pathData, action) {
    const currentPath = cleanPreAndPostSlashes(pathData.pathname);
    const currentView = this._getCurrentViewForPath(currentPath);

    this.setState({
      currentPath,
      currentView,
      currentHistoryData: { ...pathData, action },
      dialog: EMPTY_DIALOG_STATE,
      isBusy: true,
      isNavigating: true,
      offset: 0,
    }, () => {
      this._fetchRecordsForCurrentView(currentView, currentPath);
    });
  }

  handleSearchQueryUpdate(queryMap) {
    const currentQueryMap = mapifyStringQuery(this.state.currentHistoryData.search);
    const search = mapToStringQuery({ ...currentQueryMap, ...queryMap });
    this.history.push({ search });
    this._scrollToTop();
  }

  handleUnloadEvent(e) {
    if (this.state.currentView !== 'default' && this.state.currentEditingRecord.hasChanges) {
      (e || window.event).returnValue = UNSAVED_CHANGED_MSG;

      return UNSAVED_CHANGED_MSG;
    }
  }

  postOrUpdateArticle(record, resolverFn) {
    this.setState({ isBusy: true });
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return record.hasOwnProperty('id') ? this._updateArticle(record, resolverFn) : this._createArticle(record, resolverFn);
  }

  publishOrScheduleArticleViaDialog(schedule_at = null) {
    this._publishOrScheduleArticle({ ...this.state.dialog.props, schedule_at });
  }

  submitOrPublishArticle(id, status, resolverFn) {
    return status === PUBLISHED_STATUS ? this._summonDialog('dialog', { id, status, resolverFn }) : this._publishOrScheduleArticle({ id, status, resolverFn });
  }

  unpublishArticle(record) {
    if (this.props.news_page_ids.includes(record.id)) return summonGlobalMessenger({ msg: LOCKED_ARTICLE_ERROR, type: 'error' });

    const status = ['admin', 'editor'].includes(getInObj(['user', 'news_role'], record)) ? DRAFT_STATUS : SUBMITTED_STATUS;

    return this._unpublishArticle(record, status);
  }

  /**
   * Helpers
   */
  _isAdminOrEditor() {
    return ['admin', 'editor'].includes(this.props.current_user.news_role);
  }

  _fetchRecordsForCurrentView(currentView, currentPath) {
    if (['analytics', 'new'].includes(currentPath)) return this._resolveBusyState();
    if (['form', 'preview', 'stats'].includes(currentView)) return this._getSingularArticle(currentView, currentPath);

    return this._getPaginatedArticles();
  }

  _getCurrentViewForPath(path) {
    if (path === '/') return 'default';
    if (path.includes('analytics')) return 'analytics';
    if (path.includes('new') || path.includes('edit')) return 'form';
    if (path.includes('preview')) return 'preview';
    if (path.includes('stats')) return 'stats';

    return 'default';
  }

  _getPathForView(view) {
    switch (view) {
      case 'analytics':
        return '/analytics';

      case 'form':
        return this.state.currentEditingRecord.record ? `/${this.state.currentEditingRecord.record.id}/edit` : '/new';

      case 'preview':
        return `/${this.state.currentEditingRecord.record.id}/preview`;

      case 'stats':
        return `/${this.state.currentEditingRecord.record.id}/stats`;

      default:
        return '/';
    }
  }

  _resolveBusyState() {
    this.setState({
      isBusy: false,
      isNavigating: false,
    });
  }

  // This will replace history state. Its meant to be used when a discarding changes from the form or preview views.
  _redirectFromDialog(view) {
    this.history.replace(this._getPathForView(view));
    this._scrollToTop();
    this.setState({ currentEditingRecord: CURRENT_EDITING_RECORD_EMPTY_STATE });
  }

  // When we need to fetch a record for editing.
  _redirectToEditViewForArticle(article) {
    this.history.push(`/${article.id}/edit`);
    this._scrollToTop();
  }

  _redirectToStatsViewForArticle(article) {
    this.history.push(`/${article.id}/stats`);
    this._scrollToTop();
  }

  _redirectToView(view, currentEditingRecordObject = null) {
    const path = this._getPathForView(view);
    if (path === '/' && this.state.currentEditingRecord.hasChanges) return this._summonDialog('prompt', { type: 'redirect', view });

    const resolverFn = () => {
      this.history.push(path);
      this._scrollToTop();
    };
    if (!currentEditingRecordObject) return resolverFn();

    this.setState({
      currentEditingRecord: {
        hasChanges: true,
        record: { ...this.state.currentEditingRecord.record, ...currentEditingRecordObject },
      },
    }, () => resolverFn());
  }

  _scrollToTop() {
    windowScrollTo(0, 0);
  }

  _summonDialog(type, props) {
    this.setState({ dialog: { open: true, type, props } });
  }

  _translateRecordForRequest(record) {
    const cleaned = removeFromObject(record, ['image', 'mobile_image', 'platforms', 'products', 'sponsor_image', 'tags', 'topics']);
    const content = cleanUrlsFromCarousels(record.content);
    const image_id = record.image ? { image_id: record.image.id } : {};
    const mobile_image_id = record.mobile_image ? { mobile_image_id: record.mobile_image.id } : {};
    const sponsor_image_id = record.sponsor_image ? { sponsor_image_id: record.sponsor_image.id } : {};

    return {
      ...cleaned,
      ...image_id,
      ...mobile_image_id,
      ...sponsor_image_id,
      content: JSON.stringify(content),
      part_ids: record.products.map((p) => p.id),
      platform_ids: record.platforms.map((p) => p.id),
      tag_ids: record.tags.map((t) => t.id),
      topic_ids: record.topics.map((t) => t.id),
    };
  }

  _translateRecordForNewState(record) {
    return {
      ...record,
      published_at: null,
      respects_count: 0,
      sponsored: record.sponsored || false,
      status: DRAFT_STATUS,
      updated_at: Date.now(),
      user: this.props.current_user,
    };
  }

  /**
   * Requests
   */
  _createArticle(record, resolverFn) {
    return graphMutate({ t: 'create_news_article' }, this._translateRecordForRequest(record))
      .then(({ article }) => {
        const decoratedRecord = this._translateRecordForNewState({ ...record, id: article.id, url: article.url });
        this.setState({
          currentEditingRecord: { hasChanges: false, record: decoratedRecord },
          isBusy: false,
          records: [decoratedRecord],
        }, () => this._createArticleSetStateCallbackResolver(article, resolverFn));
      })
      .catch((err) => {
        this.setState({ isBusy: false });
        resolverFn('Sorry there was a error creating your article. Please try again.');
        errorHandler('NewsAdminPage _createArticle', err);
      });
  }

  _createArticleSetStateCallbackResolver(article, resolverFn) {
    this.history.replace(`/${article.id}/edit`);
    resolverFn();
    summonGlobalMessenger({ msg: 'Article successfully saved.', type: 'success' });
  }

  _deleteArticle(record) {
    this.setState({ isBusy: true });

    return graphMutate({ t: 'delete_news_article' }, { id: record.id })
      .then(() => {
        summonGlobalMessenger({ msg: 'Article successfully deleted.', type: 'success' });
        this.state.currentView === 'default' ? this._deleteArticleDefaultResolver() : this._deleteArticleRedirectResolver();
      })
      .catch((err) => {
        const errorMsg = getInObj(['response', 'body', 'form_error'], err) || 'There was an issue deleting your article. Please try again.';
        this.setState({ isBusy: false });
        summonGlobalMessenger({ msg: errorMsg, type: 'error' });
        errorHandler('NewsAdminPage deleteArticle', err);
      });
  }

  _deleteArticleDefaultResolver() {
    this._getPaginatedArticles(getCurrentOrPreviousPagePostDeletion(this.state.pagination, 1));
  }

  _deleteArticleRedirectResolver() {
    this.setState({ currentEditingRecord: CURRENT_EDITING_RECORD_EMPTY_STATE, dialog: EMPTY_DIALOG_STATE });
    this.history.replace('/');
  }

  _getPaginatedArticles(page = null) {
    return graphQuery({ t: 'news_articles_admin_simple_pagination' }, this._getPaginatedArticlesArgs(page))
      .then(({ articles }) => {
        this.setState({
          currentEditingRecord: CURRENT_EDITING_RECORD_EMPTY_STATE,
          dialog: EMPTY_DIALOG_STATE,
          isBusy: false,
          isNavigating: false,
          pagination: articles.metadata,
          records: articles.records,
        });
      })
      .catch((err) => {
        this.setState({
          isBusy: false,
          isNavigating: false,
        });
        errorHandler('NewsAdminPage _getPaginatedArticles:', err);
      });
  }

  // pageOverride is for deleting records. If the current page runs out of records, we'll fetch the previous page.
  _getPaginatedArticlesArgs(pageOverride = null) {
    const queryMap = mapifyStringQuery(this.state.currentHistoryData.search);
    const page = pageOverride || getPageFromQueryMap(queryMap);
    const status = (queryMap.status || null);
    const search = (queryMap.q || null);

    const by_current_user = this.state.isAdminOrEditor ? {} : { by_current_user: true };
    const by_status_type = status ? { by_status_type: getStatusForFilterEnum(queryMap.status) } : {};
    const by_sponsored = queryMap.filter === 'sponsored' ? { by_sponsored: true } : {};
    const pageObj = page ? { page } : {};
    const searchObj = search ? { search: search } : {};

    return {
      ...by_current_user,
      ...by_sponsored,
      ...by_status_type,
      ...pageObj,
      ...searchObj,
      offset: this.state.offset,
      sort: getSortEnumForFilter(status),
    };
  }

  _getSingularArticle() {
    const id = parseInt(this.state.currentPath.split('/')[0]);
    // We already have the record, bail out.
    if (getInObj(['record', 'id'], this.state.currentEditingRecord) === id) return this._resolveBusyState();

    return graphQuery({ t: 'news_article' }, { id })
      .then(({ article }) => this._getSingularArticleResolver(article))
      .catch((err) => {
        this.setState({
          isBusy: false,
          isNavigating: false,
        });
        errorHandler('NewsAdminPage _getSingularArticle:', err);
      });
  }

  _getSingularArticleResolver(article) {
    this.setState({
      currentEditingRecord: { hasChanges: false, record: article },
      currentView: article ? this.state.currentView : 'not_found',
      isBusy: false,
      isNavigating: false,
      offset: 0,
      pagination: SIMPLE_PAGINATION,
      records: article ? [article] : [],
    });
  }

  _publishOrScheduleArticle({ id, status, resolverFn, schedule_at = null } = {}) {
    this.setState({ isBusy: true });

    return graphMutate({ t: 'update_news_article_status' }, { id, schedule_at, status })
      .then(() => {
        this.history.replace('/');
        this._scrollToTop();
        const statusMsg = schedule_at !== null ? 'SCHEDULED' : status;
        summonGlobalMessenger({ msg: `Article successfully ${statusMsg.toLowerCase()}.`, type: 'success' });
      })
      .catch((err) => {
        this.setState({ isBusy: false });
        resolverFn(`There was an issue ${schedule_at ? 'scheduling' : 'publishing'} your article. Please try again.`);
        errorHandler('NewsAdminPage _publishOrScheduleArticle', err);
      });
  }

  _updateArticle(record, resolverFn) {
    return graphMutate({ t: 'update_news_article' }, this._translateRecordForRequest(record))
      .then(({ article }) => {
        const updatedRecord = {
          ...this.state.records[0],
          ...record,
          updated_at: article.updated_at,
        };
        this.setState({
          currentEditingRecord: { hasChanges: false, record: updatedRecord },
          isBusy: false,
          records: [updatedRecord],
        });
        resolverFn();
        summonGlobalMessenger({ msg: 'Article successfully saved.', type: 'success' });
      })
      .catch((err) => {
        this.setState({ isBusy: false });
        resolverFn('There was an issue saving your article. Please try again.');
        errorHandler('NewsAdminPage _updateArticle', err);
      });
  }

  _unpublishArticle(record, status) {
    this.setState({ isBusy: true });

    return graphMutate({ t: 'update_news_article_status' }, { id: record.id, status })
      .then(({ article }) => {
        summonGlobalMessenger({ msg: 'Article successfully updated.', type: 'success' });
        this.state.currentView === 'default' ? this._unpublishArticleDefaultResolver() : this._unpublishArticleEditingResolver(article, record, status);
      })
      .catch((err) => {
        const errorMsg = getInObj(['response', 'body', 'form_error'], err) || 'There was an issue updating your article. Please try again.';
        this.setState({ isBusy: false });
        summonGlobalMessenger({ msg: errorMsg, type: 'error' });
        errorHandler('NewsAdminPage _unpublishArticle', err);
      });
  }

  _unpublishArticleDefaultResolver() {
    this.history.replace({
      pathname: '/',
      search: this.history.location.search,
    });
  }

  _unpublishArticleEditingResolver(serverRecord, stateRecord, status) {
    const updatedRecord = {
      ...stateRecord,
      status,
      published_at: serverRecord.published_at,
      updated_at: serverRecord.updated_at,
      url: serverRecord.url,
    };

    this.setState({
      currentEditingRecord: { ...this.state.currentEditingRecord, record: updatedRecord },
      isBusy: false,
      records: [updatedRecord],
    });
  }

  /**
   * Views
   */
  _getRenderedView() {
    switch (this.state.currentView) {
      case 'analytics':
        return this._getAnalyticsView();
      case 'default':
        return this._getDefaultView();
      case 'form':
        return this._getEditView();
      case 'not_found':
        return this._getNotFoundView();
      case 'preview':
        return this._getPreviewView();
      case 'stats':
        return this._getStatsView();
      default:
        return null;
    }
  }

  _getAnalyticsView() {
    return (
      <NewsArticleAnalytics
        analytics={this.props.analytics}
        currentHistoryData={this.state.currentHistoryData}
        currentRecord={this.state.currentEditingRecord}
        toggleCurrentView={() => this._redirectToView('default')}
        view="default"
      />
    );
  }

  _getDefaultView() {
    return (
      <NewsArticlesList
        adminNewsUrl={this.props.admin_news_url}
        currentHistoryData={this.state.currentHistoryData}
        deleteArticle={this.deleteArticle}
        isAdminOrEditor={this.state.isAdminOrEditor}
        isBusy={this.state.isBusy}
        isNavigating={this.state.isNavigating}
        news_page_ids={this.props.news_page_ids}
        pagination={this.state.pagination}
        propagateSearchQuery={this.handleSearchQueryUpdate}
        records={this.state.records}
        redirectToArticleEdit={(article) => this._redirectToEditViewForArticle(article)}
        redirectToArticleStats={(article) => this._redirectToStatsViewForArticle(article)}
        toggleCurrentView={(view) => this._redirectToView(view)}
        unpublishArticle={this.unpublishArticle}
      />
    );
  }

  _getEditView() {
    if (this.state.isNavigating) return null;

    return (
      <NewsArticleForm
        algoliaServices={{
          parts: this.algoliaPartsService,
          platforms: this.algoliaPlatformsService,
          tags: this.algoliaTagsService,
          topics: this.algoliaTopicsService,
        }}
        currentRecord={this.state.currentEditingRecord}
        deleteArticle={this.deleteArticle}
        isAdminOrEditor={this.state.isAdminOrEditor}
        isBusy={this.state.isBusy}
        isFetchingRecord={this.state.isNavigating}
        saveContent={this.postOrUpdateArticle}
        submitOrPublishArticle={this.submitOrPublishArticle}
        summonPrompt={(type, view) => this._summonDialog('prompt', { type, view })}
        toggleCurrentView={(view, currentEditingRecordObject) => this._redirectToView(view, currentEditingRecordObject)}
        unpublishArticle={this.unpublishArticle}
      />
    );
  }

  _getNotFoundView() {
    return (
      <NotFoundPage
        action={{
          href: '/news/admin',
          onClick: (e) => {
            e.preventDefault();
            this._redirectToView('default');
          },
          text: 'Return to the admin page.',
        }}
      />
    );
  }

  _getPreviewView() {
    if (this.state.isNavigating) return null;

    return (
      <NewsArticlePreview
        article={this.state.currentEditingRecord.record}
        deleteArticle={this.deleteArticle}
        isAdminOrEditor={this.state.isAdminOrEditor}
        toggleCurrentView={(view) => this._redirectToView(view)}
        unpublishArticle={this.unpublishArticle}
      />
    );
  }

  _getStatsView() {
    if (this.state.isNavigating) return null;

    return (
      <NewsArticleAnalytics
        currentHistoryData={this.state.currentHistoryData}
        currentRecord={this.state.currentEditingRecord}
        toggleCurrentView={() => this._redirectToView('default')}
        view="stats"
      />
    );
  }

  _getPromptProps() {
    if (!this.state.dialog.open || this.state.dialog.type !== 'prompt') return {};

    switch (this.state.dialog.props.type) {
      case 'delete':
        return {
          ...DELETE_PROMPT,
          okay: () => this._deleteArticle(this.state.dialog.props.record),
        };

      case 'redirect':
        return {
          ...REDIRECT_PROMPT,
          okay: () => this._redirectFromDialog(this.state.dialog.props.view),
        };

      default:
        return {};
    }
  }

  render() {
    return (
      <div>
        {this._getRenderedView()}

        <PublishDialog
          dismiss={() => !this.state.isBusy && this.setState({ dialog: EMPTY_DIALOG_STATE })}
          isBusy={this.state.isBusy}
          onPublish={this.publishOrScheduleArticleViaDialog}
          open={(this.state.dialog.open && this.state.dialog.type === 'dialog')}
        />

        <Prompt
          dismiss={() => !this.state.isBusy && this.setState({ dialog: EMPTY_DIALOG_STATE })}
          isBusy={this.state.isBusy}
          open={(this.state.dialog.open && this.state.dialog.type === 'prompt')}
          {...this._getPromptProps()}
        />
      </div>
    );
  }
}

NewsAdminPage.propTypes = {
  admin_news_url: PropTypes.string.isRequired,
  analytics: PropTypes.shape({
    metadata: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  current_user: PropTypes.shape({
    avatar_url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    news_role: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  news_page_ids: PropTypes.arrayOf(PropTypes.number).isRequired, // Current featured and sponsored articles.
  path_helpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
  }).isRequired,
};

export default NewsAdminPage;
