import request from 'superagent';
import OAuth from '../../services/oauth';

export function patchStory(projectId, story) {
  return OAuth.apiRequest(
    request
      .patch(`${OAuth.getApiPath()}/private/projects/${projectId}/update_story`)
      .send({ story_json: JSON.stringify(story) }),
    true,
    false,
  );
}
