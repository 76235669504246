import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroup from '../../wrappers/form_group';
import Select from '../../select';

import generateRandomKey from '../../../../utility/generateRandomKey';
import { pluralize } from '../../../../utility/formatters';

const LIMIT_WARNING = (limit, countOverLimit) => `Please unselect ${countOverLimit} ${pluralize('item', countOverLimit)}, the amount allowed is ${limit} choices.`;

/**
 * FormGroup normally expects a value prop to render charCount and pass the value to a custom msgView.
 * Im fore-going it because its not necessary right now and would require a callback, plus we'd need to host the selects value in
 * state here. No bueno. Its also a bit different than a normal inputs value, here we care about the selected items.
 */
class FormSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { warningText: null };

    this.onSelectedChange = this.onSelectedChange.bind(this);

    // uuid
    this._uuid = generateRandomKey();
  }

  /**
   * Methods
   */
  onSelectedChange(selected) {
    if (this.props.type === 'multi') this._setSelectedWarningText(selected);
    this.props.onSelectedChange(selected);
  }

  /**
   * Helpers
   */
  _setSelectedWarningText(selected) {
    const limit = this.props.searchOpts.multiLimit;

    if (typeof limit === 'number') {
      if (selected.length > limit) {
        this.setState({ warningText: LIMIT_WARNING(limit, selected.length - limit) });
      } else if (this.state.warningText !== null && selected.length <= limit) {
        this.setState({ warningText: null });
      }
    }
  }

  render() {
    return (
      <FormGroup
        errors={this.props.errors}
        helperText={this.props.helperText}
        label={this.props.label}
        name={this.props.name}
        uuid={this._uuid}
        value="" // Leave this empty
        warningText={this.state.warningText}
      >
        <Select
          {...this.props}
          hasErrors={this.props.errors && this.props.errors.length > 0}
          onSelectedChange={this.onSelectedChange}
          searchOpts={{ rule: this.props.searchOpts.rule }}
          uuid={`${this._uuid}`}
        />
      </FormGroup>
    );
  }
}

FormSelect.propTypes = {
  asyncOpts: PropTypes.shape({
    initOnMount: PropTypes.bool,
    onError: PropTypes.func,
    request: PropTypes.func,
    resolver: PropTypes.func,
  }),
  creatableOpts: PropTypes.shape({
    creatable: PropTypes.bool,
    onError: PropTypes.func,
    request: PropTypes.func,
    resolver: PropTypes.func,
  }),
  debugMode: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  helperText: PropTypes.node,
  label: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  menuOpts: PropTypes.shape({
    scrollBuffer: PropTypes.number,
    scrollToView: PropTypes.bool,
  }),
  name: PropTypes.string,
  onSelectedChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })),
  placeholder: PropTypes.string,
  searchOpts: PropTypes.shape({
    multiLimit: PropTypes.number,
    rule: PropTypes.oneOf(['absolute', 'default', 'norule', 'strict']),
  }),
  templates: PropTypes.shape({
    menuActionable: PropTypes.func,
    menuActionableText: PropTypes.func,
    menuOption: PropTypes.func,
  }),
  theme: PropTypes.oneOf(['default', 'bootstrap']),
  type: PropTypes.oneOf(['default', 'multi']),
  value: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.value })),
  ]),
};

FormSelect.defaultProps = {
  asyncOpts: {
    initOnMount: false,
    onError: () => {},
    request: null,
    resolver: (options) => Promise.resolve({ options }),
  },
  creatableOpts: {
    creatable: false,
    onError: () => {},
    request: null,
    resolver: null,
  },
  debugMode: false,
  disabled: false,
  errors: null,
  helperText: null,
  label: null,
  maxWidth: '100%',
  menuOpts: {
    scrollBuffer: 0,
    scrollToView: false,
  },
  name: null,
  onSelectedChange: () => {},
  options: [],
  placeholder: '',
  searchOpts: {
    multiLimit: null,
    rule: 'default',
  },
  templates: {
    menuActionable: null,
    menuActionableText: null,
    menuOption: null,
  },
  theme: 'default',
  type: 'default',
  value: '',
};

export default FormSelect;
