/* TODO: This can get refactored, the only NewFeatureTooltip is for sticky footer */

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DefaultView from './DefaultView';
import StickyFooterView from './sticky_footer';

import keenService from '../../../services/keen/main';
import store from './store';

import setTooltipAsViewed from '../../../requests/tooltips';
import errorHandler from '../../../services/error_handler';

class NewFeatureTooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
      config: this._initConfig(),
    };

    this.handleClick = this.handleClick.bind(this);
  }

  _initConfig() {
    const tooltipDef = store(this.props.id);

    return {
      arrowPosition: tooltipDef.arrowPosition,
      containerStyle: tooltipDef.containerStyle || {},
      closeMsg: tooltipDef.closeMsg || 'OK got it',
      msg: tooltipDef.msg || '',
      position: tooltipDef.position,
      showIcon: tooltipDef.showIcon,
      title: tooltipDef.title,
      view: tooltipDef.view || 'default',
    };
  }

  handleClick() {
    if (this.state.isVisible === false) return;

    this.setState({ isVisible: false });

    setTooltipAsViewed(this.props.id)
      .then((res) => keenService.recordEvent({ eventName: 'Dismissed tooltip' }))
      .catch((err) => errorHandler('setTooltipAsViewed Error: ', err))
      .finally(() => {
        if (this.state.config.view !== 'StickyFooter') return;
        const event = new Event('closeStickyBanner');
        document.dispatchEvent(event);
      });
  }

  /**
   * Views
   */
  _getViewForType() {
    switch (this.state.config.view) {
      case 'custom':
        return (React.createElement(this.props.customView, { handleClick: this.handleClick, isVisible: this.state.isVisible }));

      case 'StickyFooter':
        return this._renderStickyFooterView();

      default:
        return this._renderDefaultView();
    }
  }

  _renderStickyFooterView() {
    return (
      <StickyFooterView {...this.state.config} handleClick={this.handleClick} isVisible={this.state.isVisible} />
    );
  }

  _renderDefaultView() {
    return (
      <DefaultView {...this.state.config} handleClick={this.handleClick} isVisible={this.state.isVisible} />
    );
  }

  render() {
    return this._getViewForType();
  }
}

NewFeatureTooltip.propTypes = {
  customView: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  id: PropTypes.number.isRequired,
};

NewFeatureTooltip.defaultProps = { customView: null };

export default NewFeatureTooltip;
