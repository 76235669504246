import React from 'react';
import PropTypes from 'prop-types';

import Address from './Address';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import inputStyles from '../../../styles/global_ui/inputs.css';
import styles from './address.css';

const getDefaultView = (address, disabled, makeDefault) => address.default
  ? <span className={`${typography.bodyS} ${typography.pebble}`}>Default address</span>
  : <button className={styles.makeDefaultBtn} disabled={disabled} onClick={() => makeDefault(address)} type="button">Make default</button>;

const SelectableAddress = ({ address, addressView, diffingAddress, disabledEdit, disabledRemove, edit, remove, isChecked, makeDefault, onChange, showDefaultView }) => (
  <div className={styles.selectable}>
    <div className={styles.address} onClick={() => onChange(address)}>
      <div className={layout.marginRight10}>
        <label className={inputStyles.radioButtonContainer}>
          <input
            checked={isChecked}
            className={inputStyles.radioButton}
            disabled={disabledEdit}
            name={address.id}
            onChange={() => {}}
            type="radio"
            value={address.id}
          />
          <span className={styles.radio}></span>
        </label>
      </div>
      <Address address={address} diffingAddress={diffingAddress} view={addressView} />
    </div>

    <div className={styles.btnWrapper}>
      <button className={styles.editBtn} disabled={disabledEdit} onClick={() => edit(address)} type="button">Edit</button>
      {remove && <button className={styles.deleteBtn} disabled={disabledRemove} onClick={() => remove(address.id)} type="button">Remove</button>}
      {showDefaultView && getDefaultView(address, disabledEdit, makeDefault)}
    </div>
  </div>
);

SelectableAddress.propTypes = {
  address: PropTypes.shape({
    address_line1: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    address_line2: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    city: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    company: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    country: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    country_iso2: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    cpf: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    default: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.element]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.element]),
    first_name: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    last_name: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    phone: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
    zip: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]).isRequired,
  }).isRequired,
  addressView: PropTypes.string,
  diffingAddress: PropTypes.object, // {key: {matchesInput: bool, value: string}}
  disabledEdit: PropTypes.bool,
  disabledRemove: PropTypes.bool,
  edit: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDeliverable: PropTypes.bool,
  makeDefault: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  showDefaultView: PropTypes.bool,
};

SelectableAddress.defaultProps = {
  addressView: 'select',
  diffingAddress: null,
  disabled: false,
  makeDefault: () => {},
  showDefaultView: true,
};

export default SelectableAddress;
