import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LazyComponent from '../../../wrappers/lazy_component';

import generateRandomKey from '../../../../utility/generateRandomKey';
import { getInObjWithPrototypeAccess } from '../../../../utility/accessors';

import layout from '../../../../styles/global_ui/layout.css';
import lazyImageStyles from '../../../reusable_components/LazyImage/lazy_image.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const DEFAULT_PLACEHOLDER_HEIGHT = 250;
const LOAD_FALLBACK_TIMEOUT = 500;

class EmbedIframe extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      height: 'auto',
      isLoaded: false,
    };

    this.handleIframeLoad = this.handleIframeLoad.bind(this);
    this.handleIframeResponse = this.handleIframeResponse.bind(this);

    this.uid = generateRandomKey();

    this._iframeRef = React.createRef();
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    window.addEventListener('message', this.handleIframeResponse);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeResponse);
  }

  /**
   * Methods
   */
  handleIframeLoad() {
    const contentWindow = this._getIframeContentWindow();
    if (contentWindow) {
      this.props.config.sendMessageToIframe({ contentWindow, data: this.props.data, uid: this.uid });
      this._setLoadTimeout();
    }
  }

  handleIframeResponse(e) {
    // Boot early if this component's iframe is not the source of the event.
    if (this._getIframeContentWindow() !== e.source) return;

    const height = parseInt(this.props.config.getHeightFromIframeMessage(e), 10);

    if (height && height !== this.state.height) {
      this.setState({ height, isLoaded: true });
    }
  }

  /**
   * Helpers
   */
  _getIframeContentWindow() {
    return getInObjWithPrototypeAccess(['current', 'contentWindow'], this._iframeRef);
  }

  // For the smoothest load, The iframe will fade in and replace its placeholder in the normal document flow
  // after it has fully loaded and reported its height. In the case that the height report never happens,
  // this will trigger the placeholder replacement a bit after the initial iframe load as a fallback
  _setLoadTimeout() {
    window.setTimeout(() => {
      if (!this.state.isLoaded) this.setState({ isLoaded: true });
    }, LOAD_FALLBACK_TIMEOUT);
  }

  render() {
    const { embed, height } = this.props.data;

    return (
      <div className={`${utilStyles.posRelative} ${lazyImageStyles.fade} ${this.state.isLoaded ? lazyImageStyles.fadeIn : ''}`}>
        <LazyComponent className={`${layout.fullWidth} ${layout.flexJustifyCenter}`} style={{ position: this.state.isLoaded ? 'relative' : 'absolute' }} verticalOffset={250}>
          {() => (
            <iframe
              ref={this._iframeRef}
              frameBorder={0}
              height={this.state.height}
              onLoad={this.handleIframeLoad}
              src={this.props.config.getIframeSrc({ embed, uid: this.uid })}
              style={{ maxWidth: '100%' }}
              width={this.props.config.width}
            />
          )}
        </LazyComponent>
        {!this.state.isLoaded && <div style={{ height: height || DEFAULT_PLACEHOLDER_HEIGHT }} />}
      </div>
    );
  }
}

EmbedIframe.propTypes = {
  config: PropTypes.shape({
    getIframeSrc: PropTypes.func.isRequired,
    getHeightFromIframeMessage: PropTypes.func.isRequired,
    sendMessageToIframe: PropTypes.func.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    embed: PropTypes.string.isRequired,
    id: PropTypes.string,
    height: PropTypes.number,
    service: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default EmbedIframe;
