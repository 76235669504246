import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../../link/AnalyticsLink';
import LazyBackgroundImage from '../../../image/LazyBackgroundImage';

import { NEWS_ARTICLE_CARD } from '../../../../services/keen/events/linkConstants';

import { getInObj } from '../../../../utility/accessors';

import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from '../news_card.css';

const NewsArticleSponsoredCard = ({ article, location }) => (
  <div className={styles.sponsoredCard}>
    <AnalyticsLink
      className={styles.sponsoredImgWrapper}
      item={article}
      linkType={NEWS_ARTICLE_CARD}
      location={location}
    >
      <LazyBackgroundImage
        classList={{ root: `${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}` }}
        ratio={getInObj(['mobile_image', 'url'], article) !== null ? '1:1' : '16:9'}
        setStyle={false}
        src={getInObj(['mobile_image', 'url'], article) || getInObj(['image', 'url'], article)}
        width={158}
      />
    </AnalyticsLink>
    <div className={styles.sponsoredDataWrapper}>
      <AnalyticsLink
        className={typography.charcoal}
        item={article}
        linkType={NEWS_ARTICLE_CARD}
        location={location}
      >
        <div className={`${typography.bodyM} ${typography.bold}`}>
          {article.title}
        </div>
      </AnalyticsLink>
      <p className={typography.bodyS}>
        {'Sponsored by '}
        <AnalyticsLink
          className={typography.linkBlue}
          customAttributes={{ rel: 'noopener' }}
          isExternal={true}
          item={{ id: article.sponsor_name, url: article.sponsor_link }}
          linkType={NEWS_ARTICLE_CARD}
          location={location}
        >
          {article.sponsor_name}
        </AnalyticsLink>
      </p>
    </div>
  </div>
);

NewsArticleSponsoredCard.propTypes = {
  article: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    mobile_image: PropTypes.shape({ url: PropTypes.string }),
    sponsor_link: PropTypes.string.isRequired,
    sponsor_name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.string,
};

NewsArticleSponsoredCard.defaultProps = { location: null };

export default NewsArticleSponsoredCard;
