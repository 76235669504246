import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../form_components/checkboxes/custom';
import { getIdOrUuid } from '../../../../../utility/accessors';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import styles from './prize_editor.css';

const EnterableChecklist = ({ categories, updateCategory }) => {
  if (!categories.length) return null;

  return (
    <div className={styles.checklistContainer}>
      <div className={styles.sectionHeader}>
        <h2 className={`${typography.h2} ${layout.marginBottom10}`}>Submission requirements (optional)</h2>
        <p className={`${typography.bodyS} ${layout.marginBottom10}`}>To require users to submit their project to a specific category, check the category below.</p>
      </div>

      <div className={styles.checklist}>
        {categories.map((c) => (
          <div key={getIdOrUuid(c)} className={styles.checklistCheckboxWrapper}>
            <Checkbox
              isChecked={c.is_enterable}
              label={c.name}
              onChange={(checked) => updateCategory({ ...c, is_enterable: checked })}
              value={c.is_enterable}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

EnterableChecklist.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    is_enterable: PropTypes.bool.isRequired,
    name: PropTypes.string,
  })).isRequired,
  updateCategory: PropTypes.func.isRequired,
};

EnterableChecklist.defaultProps = {};

export default EnterableChecklist;
