import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Button from './Button';

export default class EmbedToolbar extends Component {

  constructor(props) {
    super(props);

    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.handleUpload = this.handleUpload.bind(this);

    // Persist state so the input exists when uploading files.
    this.state = {
      height: props.data.height || '100%',
      persistState: false
    };

    // Refs.
    this.input;
  }

  handleMouseOut(e) {
    const parent = ReactDOM.findDOMNode(this.props.parent);
    const relatedTarget = e.nativeEvent.relatedTarget;

    if ((!parent.contains(relatedTarget) ||
       relatedTarget.classList.contains('reit-controls-button') ||
       relatedTarget.classList.contains('react-editor-figcaption')) &&
       this.state.persistState === false) {
      this.props.unmountToolbar();
    }
  }

  handleInputClick(e) {
    this.setState({ persistState: true });
    this.input.click();
    // The click event will unfocus the editor. When we select another image Draft's editOnInput will fire and throw a error
    // because window.getSelection().anchorNode will be null.
    this.props.parent.props.blockProps.focus();
  }

  handleUpload(e) {
    this.props.upload(e);
    this.props.unmountToolbar();
  }

  getStyles(style) {
    style = style || {};
    const height = typeof this.state.height === 'number' && this.props.shouldRenderFigcaption ? (this.state.height - 28) : this.state.height;

    return {
      top: 0,
      left: 0,
      marginLeft: 0,
      width: '100%',
      height: height,
      borderRadius: 2
    };
  }

  render() {
    const { embedType, data, disableButtonsByType } = this.props;

    const buttons = this._getToolbarButtons(embedType).reduce((acc, button, idx) => {
      const {classList, icon, buttonStyle, tooltip, type, onClick} = button;
      if (disableButtonsByType.indexOf(type) === -1) {
        acc = acc.concat(
          <Button
            key={idx}
            buttonStyle={{...buttonStyle}}
            classList={`${classList} embed-toolbar-btn`}
            icon={icon}
            tooltip={tooltip}
            onClick={onClick}/>
        );
      }
      return acc;
    }, []);

    return (
      <div
        style={this.getStyles(data)}
        contentEditable={false}
        className="react-editor-image-overlay"
        onMouseOut={this.handleMouseOut}
        >
        <div className="reit-toolbar">
          {buttons}
        </div>
        <input
          ref={(el) => this.input = el}
          style={{display: 'none'}}
          type="file"
          multiple={true}
          onChange={this.handleUpload}
        />
      </div>
    );
  }

  _getToolbarButtons(type) {
    switch(type) {
      case 'carousel':
        return [
          { type: 'add', classList: 'btn btn-primary', icon: 'fa fa-plus', buttonStyle: { marginRight: 10 }, tooltip: 'Add an image', onClick: this.handleInputClick },
          { type: 'reorder', classList: 'btn btn-primary', icon: 'fa fa-exchange', buttonStyle: { marginRight: 10 }, tooltip: 'Reorder images', onClick: this.props.openImageEditor },
          { type: 'remove', classList: 'btn btn-danger', icon: 'fa fa-trash-o', tooltip: 'Delete this image', onClick: this.props.remove }
        ];
      case 'link':
        return [
          { type: 'add', classList: 'btn btn-primary', icon: 'fa fa-crop', buttonStyle: { marginRight: 10 }, tooltip: 'Edit image', onClick: this.props.openImageEditor },
          { type: 'remove', classList: 'btn btn-danger', icon: 'fa fa-trash-o', tooltip: 'Delete this embed', onClick: this.props.remove }
        ];
      default:
        return [
          { type: 'remove', classList: 'btn btn-danger', icon: 'fa fa-trash-o', tooltip: 'Delete this embed', onClick: this.props.remove }
        ];
    }
  }
}

EmbedToolbar.propTypes = {
  disableButtonsByType: PropTypes.array,
  embedType: PropTypes.string,
  remove: PropTypes.func.isRequired,
  openImageEditor: PropTypes.func,
  parent: PropTypes.object,
  parentRenderingFigcaption: PropTypes.bool,
  upload: PropTypes.func,
  unmountToolbar: PropTypes.func.isRequired
};

EmbedToolbar.defaultProps = {
  disableButtonsByType: [],
  embedType: 'default',
  shouldRenderFigcaption: false
};