import PromiseBatcher from '../../utility/promises/PromiseBatcher';
import { CONTENT_PAGE, HOME_PAGE, NEWS_HOME_PAGE } from '../../graphql/ads/enums';
import { getInObj } from '../../utility/accessors';
import { graphQuery } from '../../requests/graphql';

const TEMPLATE_MAP = {
  [CONTENT_PAGE]: 'get_ads_content_page',
  [HOME_PAGE]: 'get_ads_home_page',
  [NEWS_HOME_PAGE]: 'get_ads_news_home_page',
};

class AdsService {
  constructor(pageType, relations = {}) {
    this.pageType = pageType;
    this.relations = relations;

    // Private
    this._batcher = new PromiseBatcher();
    this._isFetching = false;
  }

  /**
   * Helpers
   */
  _cachePromise(promise) {
    this._batcher.cachePromise(promise);
  }

  _fetchAds() {
    this._isFetching = true;

    return graphQuery({ t: TEMPLATE_MAP[this.pageType] }, this.relations)
      .then(({ ads }) => {
        this._isFetching = false;
        this._batcher.resolveBatch(ads);
      })
      .catch((err) => {
        this._isFetching = false;
        this._batcher.rejectBatch(err);
      });
  }

  /**
   * Public
   */
  getAdForPage(key) {
    return new Promise((resolve, reject) => {
      this.getAdsForPage()
        .then((ads) => {
          /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
          /* eslint-disable-next-line no-prototype-builtins */
          if (!ads.hasOwnProperty(key)) return reject(`Response does not have a key for arg: ${key}`);
          resolve(getInObj([key], ads));
        })
        .catch((err) => reject(err));
    });
  }

  getAdsForPage() {
    return new Promise((resolve, reject) => {
      this._cachePromise({ resolve, reject });

      if (!this._isFetching) return this._fetchAds();
    });
  }
}

export default AdsService;
