import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ErrorPage from './ErrorPage';
import Link from './Link';
import Messenger from './messenger';
import Prompt from '../../reusable_components/Dialog/Prompt';
import Route from './Route';
import Store from './Store';

import createHistory, { arePathsEqual, cleanPreAndPostSlashes, getPath, transition } from './history';
import { canUseDOM } from '../../../utility/execEnvironment';

const MESSENGER_TOP = 108;
let history;

export function goTo(e, path) {
  e.preventDefault();
  transition(history, path);
}

function isCurrentPath(hist, route, initPath = '/') {
  const pathname = canUseDOM() ? getPath(hist) : initPath;

  if (route.href !== '/') {
    return pathname.split('/').filter((s) => s.length)[0] === cleanPreAndPostSlashes(route.href);
  } else {
    return pathname === '/' && route.href === '/';
  }
}

function initCurrentPath(fullPath, basePath) {
  const basePathParts = basePath.split('/').filter((p) => p.length > 0);
  let startToCare = false;

  return fullPath.split('?')[0].split('/').reduce((acc, part, i, list) => {
    // Ignore any prefix such as "/en-US/"; What we care about is anything following basePathParts.
    if (part.length > 0 && part === basePathParts[0]) {
      startToCare = true;
    }
    if (!basePathParts.includes(part) && startToCare) {
      acc = acc.concat(part);
    }

    return (i === list.length - 1 && !acc.length) ? ['/'] : acc;
  }, []).join('/');
}

class Router extends Component {
  constructor(props) {
    super(props);

    this.getStore = this.getStore.bind(this);
    this.preLocationChange = this.preLocationChange.bind(this);
    this.postLocationChange = this.postLocationChange.bind(this);

    this.state = {
      message: null,
      nextLocation: null,
      showPrompt: false,
    };

    history = createHistory(props.basePath);
    this.store = new Store();

    this._isMounted = false;
  }

  UNSAFE_componentWillMount() {
    if (this.props.initializeStoreFn) {
      this.props.initializeStoreFn(this);
    }
  }

  componentDidMount() {
    this.unblock = history.block(this.preLocationChange);
    this.unlisten = history.listen(this.postLocationChange);
  }

  componentWillUnmount() {
    this.unblock();
    this.unlisten();
  }

  _getRouteByInitPath(routes, initPath) {
    const route = [].slice.call(routes).find((route) => arePathsEqual(route.props.path, initPath));

    if (!this._isMounted) {
      this._isMounted = true;
    }

    return route;
  }

  _getRouteByHistoryPath(routes, hist) {
    return [].slice.call(routes).find((route) => arePathsEqual(route.props.path, getPath(hist)));
  }

  getStore() {
    return this.store;
  }

  preLocationChange(location, action) {
    if (location.state && location.state.prompt) {
      this.setState({ showPrompt: true, nextLocation: location.pathname });

      return false;
    }

    return true;
  }

  postLocationChange(location, action) {
    this.props.onUpdate(location, action);

    if (this.state.message) {
      this.setState({ message: null });
    } else {
      this.forceUpdate();
    }
  }

  _renderChildren(children) {
    if (children[0].type !== Route) {
      const firstChild = children[0];
      const routeToRender = this._getRouteToRender(children.slice(1));

      return React.Children.toArray([React.cloneElement(firstChild, { store: this.store }), routeToRender]);
    } else {
      return this._getRouteToRender(children);
    }
  }

  _getRouteToRender(routes) {
    // Below is for server rendering since history state does not exist there.
    const route = this._isMounted ? this._getRouteByHistoryPath(routes, history) : this._getRouteByInitPath(routes, this.props.initPath);

    return !route
      ? <ErrorPage basePath={this.props.basePath} history={history} transition={transition} />
      : React.cloneElement(route, {
        basePath: this.props.basePath,
        history: history,
        setMessage: (message) => this.setState({ message }),
        store: this.store,
      });
  }

  render() {
    return (
      <div className={this.props.classList}>
        {this._renderChildren(this.props.children)}
        <Prompt
          action="Leave"
          dismiss={() => this.setState({ showPrompt: false })}
          okay={() => {
            const nextLocation = this.state.nextLocation;
            this.setState({ showPrompt: false, nextLocation: null }, () => {
              this.store.set('showPrompt', false);
              transition(history, nextLocation);
            });
          }}
          open={this.state.showPrompt}
          title="Are you sure you want to leave without saving?"
        />
        {this.state.message
        && <Messenger dismiss={() => this.setState({ message: null })} message={this.state.message} top={MESSENGER_TOP} />}
      </div>
    );
  }
}

Router.propTypes = {
  basePath: PropTypes.string.isRequired,
  classList: PropTypes.string,
  initPath: PropTypes.string,
  initializeStoreFn: PropTypes.func,
  onUpdate: PropTypes.func,
};

Router.defaultProps = {
  classList: '',
  initPath: '/',
  initializeStoreFn: null,
  onUpdate: () => {},
};

export { getPath, history, initCurrentPath, isCurrentPath, Link, Route, transition };
export default Router;
