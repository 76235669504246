import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../client/buttons/base';
import BasicSearchInput from '../../../../client/form_components/inputs/basic_search_input';
import SelectableTable from '../../../../client/tables/selectable';

import styles from '../../videos/edit_videos.css';

const VideosTableView = ({
  disabledState,
  headers,
  isBusy,
  onPagination,
  onSearch,
  onSort,
  pagination,
  reportSelected,
  selectedIndexes,
  summonDialog,
  tableItems,
  tableRef,
}) => (
  <div>
    <div className={styles.tableActions}>
      <div>
        <Button
          disabled={isBusy}
          onClick={() => summonDialog('default')}
        >
          Add new video
        </Button>
      </div>
      <div>
        <BasicSearchInput
          onEnter={onSearch}
          placeholder="Search videos"
        />
      </div>
    </div>

    <SelectableTable
      ref={tableRef}
      disabledState={disabledState}
      headers={headers}
      isBusy={isBusy}
      onPagination={onPagination}
      onSort={onSort}
      pagination={pagination}
      reportSelected={reportSelected}
      selectedIndexes={selectedIndexes}
      tableItems={tableItems}
    />
  </div>
);

VideosTableView.propTypes = {
  disabledState: PropTypes.bool.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    orderable: PropTypes.bool,
    sort: PropTypes.string,
  })).isRequired,
  isBusy: PropTypes.bool.isRequired,
  onPagination: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    current_page: PropTypes.number,
    next_page: PropTypes.number,
    per_page: PropTypes.number,
    prev_page: PropTypes.number,
  }),
  reportSelected: PropTypes.func,
  selectedIndexes: PropTypes.array.isRequired,
  summonDialog: PropTypes.func.isRequired,
  tableItems: PropTypes.arrayOf(PropTypes.shape({
    columns: PropTypes.array.isRequired,
    record: PropTypes.object,
  })).isRequired,
  tableRef: PropTypes.func.isRequired,
};

export default VideosTableView;
