const IGNORE_FACETS_FOR = {
  14760: (index, sort) => (index === 'parts' && sort === 'name'), // 'make' index: parts_name, filterComponent: products
  18265: (index) => (index === 'parts'), // 'make' index: parts, filterComponent: onboarding toolbox
};

function ignoreFacetFor(index, whitelabelId, sort) {
  const fn = IGNORE_FACETS_FOR[whitelabelId.toString()];

  return typeof fn !== 'undefined' && typeof fn === 'function' ? fn(index, sort) : false;
}

export default function buildFacetFiltersForWhitelabel(index, whitelabelId, sort = null, facetFilters = []) {
  if (ignoreFacetFor(index, whitelabelId, sort)) return facetFilters;

  const map = {
    misc: [[`whitelabel_ids:${whitelabelId}`, 'whitelabel_ids:0']],
    parts: [`platforms.id:${whitelabelId}`],
    projects: [`platforms.id:${whitelabelId}`],
    users: [`channels.id:${whitelabelId}`],
  };
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return map.hasOwnProperty(index) ? map[index].concat(facetFilters) : facetFilters;
}
