import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { selectTargetContents } from '../../../../../utility/events';

import typography from '../../../../../styles/global_ui/typography.css';
import styles from '../datetime.css';

const TimeInputs = ({ _inputRefs, autoComplete, disabled, handleInputKeyDown, handleNumberBlur, handleNumberChange, values }) => (
  <Fragment>
    <input
      ref={_inputRefs[0]}
      autoComplete={autoComplete}
      className={styles.input}
      disabled={disabled}
      name="hour"
      onBlur={handleNumberBlur}
      onChange={handleNumberChange}
      onClick={selectTargetContents}
      onFocus={selectTargetContents}
      onKeyDown={handleInputKeyDown}
      placeholder="HH"
      value={values.hour}
    />
    <span>:</span>
    <input
      ref={_inputRefs[1]}
      autoComplete={autoComplete}
      className={styles.input}
      disabled={disabled}
      name="minute"
      onBlur={handleNumberBlur}
      onChange={handleNumberChange}
      onClick={selectTargetContents}
      onFocus={selectTargetContents}
      onKeyDown={handleInputKeyDown}
      placeholder="MM"
      value={values.minute}
    />
    <input
      ref={_inputRefs[2]}
      autoComplete={autoComplete}
      className={`${styles.input} ${typography.uppercase}`}
      disabled={disabled}
      name="ampm"
      onClick={selectTargetContents}
      onFocus={selectTargetContents}
      onKeyDown={handleInputKeyDown}
      placeholder="--"
      readOnly
      value={values.ampm}
    />
  </Fragment>
);

TimeInputs.propTypes = {
  _inputRefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  handleInputKeyDown: PropTypes.func.isRequired,
  handleNumberBlur: PropTypes.func.isRequired,
  handleNumberChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    ampm: PropTypes.string.isRequired,
    hour: PropTypes.string.isRequired,
    minute: PropTypes.string.isRequired,
  }).isRequired,
};

TimeInputs.defaultProps = {
  autoComplete: null,
  disabled: false,
};

export default TimeInputs;
