import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ConfirmEmailMessage from '../../reusable_components/ConfirmEmailMessage';
import Icon from '../../icon';
import PostCardComment from './PostCardComment';
import PostCardCommentInput from './PostCardCommentInput';
import RingSpinner from '../../spinners/ring';

import { getInObj } from '../../../utility/accessors';
import { scrollElementToMiddle } from '../../../utility/document';
import { summonGlobalDialog, summonLoginPanel } from '../../../utility/dispatchers';
import { windowLocationHash } from '../../../services/window';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './post_card_comments.css';

class PostCardComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: !props.renderAllComments,
      locationHash: null,
    };
  }

  componentDidMount() {
    if (this.props.config.allowScrollToCommentOnMount) this._getWindowLocationHashAndScrollTo();
  }

  /**
   * Initializers
   */
  _getWindowLocationHashAndScrollTo() {
    const hash = windowLocationHash.get();
    if (hash && hash.length > 0 && hash.includes('comment-')) {
      this.setState({ locationHash: hash.slice(1) }, () => this._scrollToComment());
    }
  }

  /**
   * Helpers
   */
  _getShowPrevWorkerKey() {
    return `post_${this.props.post.id}_prev_click`;
  }

  _scrollToComment() {
    scrollElementToMiddle(document.getElementById(this.state.locationHash));
  }

  _summonConfirmationEmailMsg() {
    summonGlobalDialog({ getChildren: () => (<ConfirmEmailMessage actionText="post a comment" />) });
  }

  _summonLoginPanel() {
    summonLoginPanel({
      detail: {
        id: this.props.post.id, // TODO: I don't think this is ever used
        state: { currentPanel: 'signup', simplified: true },
        source: 'feed_post_comment',
      },
    });
  }

  /**
   * Views
   */
  _getCommentView({ comment, children = null, parentIsDeleted = false } = {}) {
    return (
      <PostCardComment
        // TODO: refactor to send children as children
        // eslint-disable-next-line react/no-children-prop
        children={children}
        key={comment.id}
        comment={comment}
        currentUser={this.props.currentUser}
        historyLocation={{ hash: this.state.locationHash }}
        isBusy={this.props.isBusy}
        isFlagged={this.props.flaggedCommentIds.includes(comment.id)}
        markdownServices={this.props.markdownServices}
        onCopyLinkClick={this.props.onCopyLinkClick}
        onDelete={this.props.onDelete}
        onPost={this.props.onPost}
        onReportClick={this.props.onReportClick}
        onShowMoreCommentRepliesClick={this.props.onShowMoreCommentRepliesClick}
        onSpamClick={this.props.onSpamClick}
        origin={this.props.origin}
        parentIsDeleted={parentIsDeleted}
        postId={this.props.post.id}
        renderAllComments={this.props.renderAllComments}
        summonConfirmationEmailMsg={() => this._summonConfirmationEmailMsg()}
        summonLoginPanel={() => this._summonLoginPanel()}
        userCanComment={this.props.userCanComment}
        workers={this.props.workers}
      />
    );
  }

  _getCommentsView() {
    if (!this.props.comments.length) return null;

    return React.Children.toArray(this.props.comments.map((comment) => this._getCommentView({
      comment: comment,
      children: this._getCommentsChildrenView(comment),
    })));
  }

  _getCommentsChildrenView(comment) {
    if (!comment.children) return null;

    return React.Children.toArray(comment.children.map((child) => (
      this._getCommentView({
        comment: child,
        parentIsDeleted: comment.deleted,
      })
    )));
  }

  _getShowPreviousBtn() {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return this.props.workers.hasOwnProperty(this._getShowPrevWorkerKey())
      ? this._getShowPreviousBtnBusyView()
      : this._getShowPreviousBtnView();
  }

  _getShowPreviousBtnView() {
    const total = getInObj(['parent_count'], this.props.comments[0]);

    if (!total || total <= this.props.comments.length) return null;

    return (
      <p
        className={`${typography.linkGraphite} ${typography.bodyS} ${styles.showPreviousText}`}
        onClick={() => this.props.onShowPreviousCommentsClick(this.props.post, this._getShowPrevWorkerKey())}
      >
        {`Show ${(total - this.props.comments.length)} previous comments`}
        <Icon className={`${typography.bodyS} ${layout.marginLeft5}`} name="arrow-down" size={14} />
      </p>
    );
  }

  _getShowPreviousBtnBusyView() {
    return (
      <div className={styles.showPreviousText}>
        <RingSpinner size={16} />
      </div>
    );
  }

  render() {
    if (!this.props.userCanComment && !this.props.comments.length) return null;

    return (
      <div className={`${layout.marginTop15} ${utilStyles.borderTop}`}>
        {this._getShowPreviousBtn()}

        <div>
          {this._getCommentsView()}
        </div>

        {this.props.userCanComment
        && (
          <div className={layout.marginTop15}>
            <PostCardCommentInput
              currentUser={this.props.currentUser}
              isBusy={this.props.isBusy}
              markdownService={this.props.markdownServices.editor}
              onPost={this.props.onPost}
              postId={this.props.post.id}
              summonConfirmationEmailMsg={() => this._summonConfirmationEmailMsg()}
              summonLoginPanel={() => this._summonLoginPanel()}
            />
          </div>
        )}
      </div>
    );
  }
}

PostCardComments.propTypes = {
  children: PropTypes.object, // Same shape as a comment
  comments: PropTypes.arrayOf(PropTypes.shape({
    body_with_relations: PropTypes.string,
    child_ids: PropTypes.arrayOf(PropTypes.number), // Exists for feed comments. Gives us a count of children.
    children: PropTypes.array, // Recursive comments shape, this may be null
    created_at: PropTypes.string.isRequired,
    edited_at: PropTypes.string,
    deleted: PropTypes.bool,
    id: PropTypes.number.isRequired,
    liking_user_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    parent_count: PropTypes.number, // Exists for feed comments. Total root comment records.
    parent_id: PropTypes.number,
    raw_body: PropTypes.string.isRequired,
    relations: PropTypes.object,
    user: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
  })).isRequired,
  config: PropTypes.shape({ allowScrollToCommentOnMount: PropTypes.bool }).isRequired,
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  flaggedCommentIds: PropTypes.array.isRequired,
  isBusy: PropTypes.bool.isRequired,
  markdownServices: PropTypes.shape({
    editor: PropTypes.object.isRequired,
    postOrComment: PropTypes.object.isRequired,
  }).isRequired,
  onCopyLinkClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPost: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  onShowMoreCommentRepliesClick: PropTypes.func.isRequired,
  onShowPreviousCommentsClick: PropTypes.func.isRequired,
  onSpamClick: PropTypes.func.isRequired,
  origin: PropTypes.shape({
    admin_ids: PropTypes.arrayOf(PropTypes.number),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  post: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  renderAllComments: PropTypes.bool.isRequired,
  userCanComment: PropTypes.bool.isRequired,
  workers: PropTypes.object.isRequired,
};

PostCardComments.defaultProps = { children: null };

export default PostCardComments;
