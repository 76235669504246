import { isBlank, isObject } from './types';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
// single level array flatten. add a depth param if needed
export function flatten(arr) {
  return arr.reduce((acc, val) => acc.concat(val), []);
}

export function flattenArrayOfObjectsToObject(list, key, value, predicate = (val) => !isBlank(val)) {
  return list.reduce((acc, item) => {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    if (!item.hasOwnProperty(key) || !item.hasOwnProperty(value)) return acc;

    if ((typeof predicate === 'function') && predicate(item)) {
      acc[item[key]] = item[value];
    }

    return acc;
  }, {});
}

export function flattenObjToArray(obj = {}) {
  return Object.keys(obj).reduce((acc, key) => (
    isObject(obj[key])
      ? acc.concat(flattenObjToArray(obj[key]))
      : acc.concat(obj[key])
  ), []);
}

export function listToBoolMap(list) {
  if (!list || !list.length) return {};

  return list.reduce((acc, item) => ({ ...acc, [item]: true }), {});
}

export function listToMapByProperty(list, property = 'id') {
  if (!list || !list.length) return {};

  return list.reduce((acc, item) => ({ ...acc, [item[property]]: item }), {});
}

export function mapifyStringQuery(query) {
  if (!query || !query.length) return {};

  if (query.substring(0, 1) === '?') {
    query = query.substring(1);
  }

  return query.split('&').reduce((acc, sect) => {
    if (!sect || !sect.length) return acc;
    const parts = sect.split('=');
    acc[parts[0]] = decodeURIComponent(parts[1]);

    return acc;
  }, {});
}

export function mapToStringQuery(map) {
  return Object.keys(map).reduce((acc, key) => {
    if (!isBlank(map[key])) {
      return isObject(map[key])
        ? acc.concat(`${key}=${encodeURIComponent(JSON.stringify(map[key]))}`)
        : acc.concat(`${key}=${encodeURIComponent(map[key])}`);
    }

    return acc;
  }, []).join('&');
}

export function transformObjValues(original, transformFn) {
  return Object.keys(original).reduce((acc, key) => {
    acc[key] = transformFn(original[key], key);

    return acc;
  }, {});
}
