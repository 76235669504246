import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BasicFileUploader from './BasicFileUploader';
import FormGroup from '../wrappers/form_group';

const FormFileUploader = (props) => {
  const [warning, setWarning] = useState('');

  return (
    <FormGroup
      classList={{ root: props.classList.formGroup }}
      errors={props.errors}
      helperText={props.helperText}
      label={props.label}
      name={props.name}
      value=""
      warningText={warning}
    >
      <BasicFileUploader {...props} reportError={setWarning} />
    </FormGroup>
  );
};

FormFileUploader.propTypes = {
  classList: PropTypes.shape({ formGroup: PropTypes.string }),
  errors: PropTypes.string,
  helperText: PropTypes.string,
  imagePreview: PropTypes.bool,
};

FormFileUploader.defaultProps = {
  classList: { formGroup: '' },
  errors: '',
  helperText: '',
  imagePreview: false,
};

export default FormFileUploader;
