import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '../../checkboxes/custom';
import FormGroup from '../../wrappers/form_group';

import inputStyles from '../../../../styles/global_ui/inputs.css';

const _updateValues = (value, isChecked, oldValues) => (
  isChecked ? oldValues.concat([value]) : oldValues.filter((v) => v !== value)
);

const CheckboxGroup = ({
  classList,
  disabled,
  errors,
  helperText,
  label,
  name,
  onChange,
  options,
  values,
}) => (
  <FormGroup
    classList={{ root: classList.root }}
    errors={errors}
    helperText={helperText}
    label={label}
    name={name}
    value=""
  >
    <div className={inputStyles.radioGroup}>
      {options.map((option) => (
        <div key={option.value} className={inputStyles.radioButtonContainer}>
          <Checkbox
            disabled={disabled || option.disabled}
            error={errors}
            isChecked={values.includes(option.value)}
            label={option.label}
            name={name}
            onChange={(isChecked) => onChange(_updateValues(option.value, isChecked, values))}
            value={option.value}
          />
        </div>
      ))}
    </div>
  </FormGroup>
);

CheckboxGroup.propTypes = {
  classList: PropTypes.shape({ root: PropTypes.string }),
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  helperText: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.node,
    value: PropTypes.any,
  })),
  values: PropTypes.array,
};

CheckboxGroup.defaultProps = {
  classList: {},
  disabled: false,
  errors: null,
  helperText: null,
  label: null,
  name: null,
  onChange: () => {},
  options: [],
  values: [],
};

export default CheckboxGroup;
