import React from 'react';
import PropTypes from 'prop-types';

import DateInput from '../date';
import FormGroup from '../../../wrappers/form_group';

const FormDateInput = ({ autoComplete, disabled, errors, helperText, initValue, label, onChange }) => (
  <FormGroup errors={errors} helperText={helperText} label={label} value="">
    <DateInput
      autoComplete={autoComplete}
      disabled={disabled}
      errors={errors}
      initValue={initValue}
      onChange={onChange}
    />
  </FormGroup>
);
FormDateInput.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  helperText: PropTypes.string,
  initValue: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

FormDateInput.defaultProps = {
  autoComplete: null,
  disabled: false,
  errors: null,
  helperText: null,
  label: null,
  initValue: '',
};

export default FormDateInput;
