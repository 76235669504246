import request from 'superagent';
import { formatMapboxPoiRecordForState } from './formatters';

const MAPBOX_API_KEY = CLIENT_ENV.MAPBOX_API_KEY;
const MAPBOX_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const MAPBOX_BASE_OPTS = {
  access_token: MAPBOX_API_KEY,
  autocomplete: true,
  language: 'en',
  types: 'place',
};
const MAPBOX_NEARBY_SEARCH_OPTS = {
  access_token: MAPBOX_API_KEY,
  autocomplete: false,
  language: 'en',
  limit: 1,
  proximity: 'ip',
  types: 'poi',
};
// Note: This can change in the future. We're hopefully looking for the closest restaurant to the user's reverse IP lookup.
const MAPBOX_NEARBY_SEARCH_QUERY = 'restaurant';

/**
 * Helpers
 */

// Note: The search text should be expressed as a URL-encoded UTF-8 string, and must not contain the semicolon character (either raw or URL-encoded).
const queryToUrl = (query) => `${MAPBOX_BASE_URL}/${encodeURIComponent(query.replace(';', ''))}.json`;

const buildQueryForObject = (query) => {
  if (query === null || typeof query === 'string') return query;

  return `${query.city} ${query.state} ${query.countryCode}`;
};

/**
 * Exports
 */

// We query MAPBOX_NEARBY_SEARCH_QUERY, something common in every city to get the reverse IP lookup for free.
// Here, we are focused on fetching a Point Of Interest that is close to the user and building a location object.
export function fetchMapboxNearbyCity(formatFn = formatMapboxPoiRecordForState) {
  return new Promise((resolve, reject) => request
    .get(queryToUrl(MAPBOX_NEARBY_SEARCH_QUERY))
    .query(MAPBOX_NEARBY_SEARCH_OPTS)
    .then(({ body }) => resolve(body.features.length > 0 ? formatFn(body.features[0]) : null))
    .catch((err) => reject(err)));
}

export function fetchMapboxCountries(query) {
  if (!query) return Promise.resolve([]);

  return new Promise((resolve, reject) => request
    .get(queryToUrl(query))
    .query({ ...MAPBOX_BASE_OPTS, types: 'country' })
    .then(({ body }) => resolve(body))
    .catch((err) => reject(err)),
  );
}

// Targets a MapBox "place" type. These are cities, villages, municipalities, etc.
export function fetchMapboxPlaces(q) {
  return new Promise((resolve, reject) => {
    const query = buildQueryForObject(q);
    if (!query?.length) return resolve({});

    return request
      .get(queryToUrl(query))
      .query(MAPBOX_BASE_OPTS)
      .then(({ body }) => resolve(body))
      .catch((err) => reject(err));
  });
}
