/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionsSection from '../../../../../client/form_components/templates/ActionsSection';
import AlgoliaMultiSelect from '../../../../../client/algolia/multi_select';
import BasicFormInput from '../../../../../client/form_components/inputs/basic_form_input';
import FormSelect from '../../../../../client/form_components/selects/form_select';

import { createImageUploader, createCropperTitle, recordsToOptions } from './components';

import { isRequired, isUrlWithProtocol, maxLength } from '../../../../../services/validation/validators';
import smoothScroll from '../../../../../client/utils/smoothScroll';

import formStyles from '../../../../../styles/global_ui/forms.css';
import utilStyles from '../../../../../styles/global_ui/util.css';

const CONTENT_TYPES = ['News Article', 'Contest', 'Event', 'From Around The Web', 'Platform', 'Product', 'Project', 'Topic', 'Video', 'Webinar', 'Workshop'].map((s) => ({ label: s, value: s }));

class ContentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      isBusy: false,
      fields: this._initFields(props),
      workers: [],
    };

    this.addOrUpdateContent = this.addOrUpdateContent.bind(this);
  }

  /**
   * Initializers
   */
  _initFields(props) {
    const template = {
      contentType: { order: 1, validate: () => null, value: '' },
      description: { order: 2, validate: (value) => maxLength(90, value), value: '' },
      image11: { order: 7, validate: (value) => this._imageV(value), value: null },
      image43: { order: 6, validate: (value) => this._imageV(value), value: null },
      link: { order: 3, validate: (value) => isUrlWithProtocol(value), value: '' },
      products: { order: 5, validate: (value) => maxLength(3, value), value: [], notRequired: true },
      tags: { order: 4, validate: (value) => maxLength(3, value), value: [], notRequired: true },
      title: { order: 0, validate: (value) => maxLength(67, value), value: '' },
    };

    if (!props.initData || !props.initData.data) return template;

    const content = props.initData.data;
    const keys = Object.keys(template);

    return keys.reduce((acc, key) => {
      if (content.hasOwnProperty(key)) {
        acc[key] = { ...template[key], value: content[key] };
      } else {
        acc[key] = template[key];
      }

      return acc;
    }, {});
  }

  /**
   * Methods
   */
  addOrUpdateContent() {
    const validated = this._validate();

    if (validated) {
      this.props.saveContent(this._getObjectToPropagate());
    }
  }

  _getFieldValuesAsObject() {
    const keys = Object.keys(this.state.fields);

    return keys.reduce((acc, key) => {
      acc[key] = this.state.fields[key].value;

      return acc;
    }, {});
  }

  _getObjectToPropagate() {
    return {
      data: this._getFieldValuesAsObject(),
      index: this.props.initData && this.props.initData.index !== null ? this.props.initData.index : null,
    };
  }

  _imageV(image) {
    return ['id', 'name', 'url'].every((key) => image && image.hasOwnProperty(key))
      ? null
      : 'A valid image is required!';
  }

  _setIsBusy(status, worker) {
    if (status && this.state.workers.includes(worker)) return;

    const workers = status ? this.state.workers.concat(worker) : this.state.workers.filter((w) => w !== worker);
    this.setState({
      isBusy: workers.length > 0,
      workers,
    });
  }

  _setStateOrError(errorMsg, key, value) {
    if (errorMsg === null) {
      const { [key]: deleted, ...errors } = this.state.errors; // eslint-disable-line @typescript-eslint/no-unused-vars
      this.setState({
        errors,
        fields: { ...this.state.fields, [key]: { ...this.state.fields[key], value: value } },
      }, () => this.props.propagateContent(this._getObjectToPropagate()));
    } else {
      this.setState({ errors: { ...this.state.errors, [key]: errorMsg } });
    }
  }

  _scrollToError(errors) {
    const el = Object.keys(this.state.fields)
      .sort((a, b) => this.state.fields[a].order - this.state.fields[b].order)
      .reduce((acc, key) => {
        if (acc !== null) return acc;

        return errors.hasOwnProperty(key) ? document.getElementById(`pcf${key}`) : acc;
      }, null);

    if (el) {
      const container = document.querySelector(`[data-ref="${this.props.dialogRef}"]`);
      smoothScroll(el, 500, null, container);
    }
  }

  _validate() {
    const keys = Object.keys(this.state.fields);
    const errors = keys.reduce((acc, key) => {
      const field = this.state.fields[key];
      const validations = field.hasOwnProperty('notRequired') && field.notRequired === true ? [field.validate] : [isRequired, field.validate];
      const error = validations.reduce((a, fn) => {
        if (a !== null) return a;

        return fn(field.value);
      }, null);

      if (error && error.length) {
        acc[key] = error;
      }

      return acc;
    }, {});

    if (Object.keys(errors).length) {
      this.setState({ errors: { ...this.state.errors, ...errors } }, () => this._scrollToError(this.state.errors));

      return false;
    }

    return true;
  }

  render() {
    return (
      <form className={formStyles.container}>
        <div id="pcftitle">
          <BasicFormInput
            charCount={this.state.fields.title.value.length}
            errors={this.state.errors.title}
            label="Title"
            maxVal={67}
            name="title"
            onChange={(e) => this._setStateOrError(maxLength(67, e.target.value), 'title', e.target.value)}
            value={this.state.fields.title.value}
          />
        </div>

        <div id="pcfcontentType">
          <FormSelect
            errors={this.state.errors.contentType}
            helperText="Select which type of content this is"
            label="Content type"
            onSelectedChange={(value) => this._setStateOrError(null, 'contentType', value)}
            options={CONTENT_TYPES}
            placeholder="i.e. Contest"
            value={this.state.fields.contentType.value}
          />
        </div>

        <div id="pcfdescription">
          <BasicFormInput
            charCount={this.state.fields.description.value.length}
            errors={this.state.errors.description}
            helperText="A short description is displayed on large cards"
            label="Description"
            maxVal={90}
            name="description"
            onChange={(e) => this._setStateOrError(maxLength(90, e.target.value), 'description', e.target.value)}
            type="textarea"
            value={this.state.fields.description.value}
          />
        </div>

        <div id="pcflink">
          <BasicFormInput
            errors={this.state.errors.link}
            helperText="Insert link to content"
            label="Link"
            name="link"
            onChange={(e) => this._setStateOrError(null, 'link', e.target.value)}
            placeholder="http://www.example.com"
            value={this.state.fields.link.value}
          />
        </div>

        <div id="pcftags">
          <AlgoliaMultiSelect
            algoliaParameters={{ hitsPerPage: 100, initFacet: [] }}
            algoliaRecordsToOptions={recordsToOptions}
            algoliaService={this.props.algoliaTagsService}
            errors={this.state.errors.tags}
            helperText="Which categories does this relate to? (Select 3 max)"
            label="Tags (optional)"
            onSelect={(tags) => this._setStateOrError(null, 'tags', tags)}
            selectionLimit={3}
            value={this.state.fields.tags.value}
          />
        </div>

        <div id="pcfproducts">
          <AlgoliaMultiSelect
            algoliaParameters={{ hitsPerPage: 100, initFacet: [] }}
            algoliaRecordsToOptions={recordsToOptions}
            algoliaService={this.props.algoliaPartsService}
            errors={this.state.errors.products}
            helperText="Which specific products were involved? (Select 3 max)"
            label="Products (optional)"
            onSelect={(products) => this._setStateOrError(null, 'products', products)}
            selectionLimit={3}
            value={this.state.fields.products.value}
          />
        </div>

        <div id="pcfimage43">
          {createImageUploader({
            aspectRatio: (4 / 3),
            cropperTitle: createCropperTitle({
              subtitle: 'This image will be used if the content item is the first to be featured.',
              title: '4:3 Crop',
            }),
            errors: this.state.errors['image43'],
            label: 'Large thumbnail image (4:3 crop)',
            dimensionMins: { width: 370 },
            helperText: 'Make sure your image is 1110x831px (minimum 370x277px) for best quality',
            imageData: this.state.fields.image43.value,
            nestedDialogLevel: 1,
            propagateStatus: (isBusy) => this._setIsBusy(isBusy, 'image43'),
            propagateUpload: (image43) => this._setStateOrError(null, 'image43', image43),
          })}
        </div>

        <div className={formStyles.container} id="pcfimage11">
          {createImageUploader({
            aspectRatio: (1),
            cropperTitle: createCropperTitle({
              subtitle: 'This image will be used when the content item is not the first to be featured.',
              title: '1:1 Crop',
            }),
            errors: this.state.errors['image11'],
            label: 'Small thumbnail image (1:1 crop)',
            dimensionMins: { width: 100 },
            helperText: 'Make sure your image is atleast 300x300px (minimum 100x100px) for best quality',
            imageData: this.state.fields.image11.value,
            nestedDialogLevel: 1,
            propagateStatus: (isBusy) => this._setIsBusy(isBusy, 'image11'),
            propagateUpload: (image11) => this._setStateOrError(null, 'image11', image11),
          })}
        </div>

        <div className={utilStyles.borderTop}>
          <ActionsSection
            isBusy={this.state.isBusy}
            primaryBtnConfig={{
              onClick: this.addOrUpdateContent,
              text: this.props.mode === 'default' ? 'Add content' : 'Save',
            }}
            secondaryBtnConfig={{ onClick: this.props.dismiss }}
          />
        </div>
      </form>
    );
  }
}

ContentForm.propTypes = {
  algoliaPartsService: PropTypes.object.isRequired,
  algoliaTagsService: PropTypes.object.isRequired,
  dialogRef: PropTypes.string, /* Injected by Dialog */
  dismiss: PropTypes.func.isRequired,
  initData: PropTypes.shape({
    data: PropTypes.object,
    index: PropTypes.number,
  }),
  mode: PropTypes.string.isRequired,
  propagateContent: PropTypes.func.isRequired,
  saveContent: PropTypes.func.isRequired,
};

ContentForm.defaultProps = {
  dialogRef: null,
  initData: null,
};

export default ContentForm;
