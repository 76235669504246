import React from 'react';
import PropTypes from 'prop-types';

import DesktopInputWrapper from '../templates/DesktopInputWrapper';
import DateInputs from '../templates/DateInputs';

import { parseDateString, dateConfig, formatDateOut } from '../datetimeInputHelpers';

const DesktopDateInput = ({ autoComplete, disabled, errors, initValue, onChange }) => (
  <DesktopInputWrapper
    disabled={disabled}
    errors={errors}
    formatOut={formatDateOut}
    initValue={initValue}
    inputConfig={dateConfig}
    onChange={onChange}
    parseValueString={parseDateString}
  >
    {({ _inputRefs, handleInputKeyDown, handleNumberBlur, handleNumberChange, values }) => (
      <DateInputs
        _inputRefs={_inputRefs}
        autoComplete={autoComplete}
        disabled={disabled}
        handleInputKeyDown={handleInputKeyDown}
        handleNumberBlur={handleNumberBlur}
        handleNumberChange={handleNumberChange}
        values={values}
      />
    )}
  </DesktopInputWrapper>
);

DesktopDateInput.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  initValue: PropTypes.string, // format: HH:MM (no timezone info) for compatibility with native <input type="time">
  onChange: PropTypes.func.isRequired,
};

DesktopDateInput.defaultProps = {
  autoComplete: null,
  disabled: false,
  errors: null,
  initValue: '',
};

export default DesktopDateInput;
