import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ActionableButton from '../../../../client/buttons/actionable';
import Button from '../../../../client/buttons/base';
import Breadcrumb from '../../../../client/nav_components/breadcrumb';
import StickyWrapper from '../../../../client/wrappers/sticky_wrapper';

import { PUBLISHED_STATUS, SUBMITTED_STATUS } from '../../../../graphql/news/enums';
import { getInObj } from '../../../../utility/accessors';
import { DATE_AT_TIME_FORMAT, timestampToPrettyDate } from '../../../../utility/time';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './news_admin_templates.css';

const PUBLISH_ARTICLE = 'PUBLISH';
const SAVE_PROGRESS = 'SAVE';
const SUBMIT_ARTICLE = 'SUBMIT';
const UPDATE_ARTICLE = 'UPDATE';

const ACTIONABLE_MAP = {
  [PUBLISH_ARTICLE]: { action: (saveProgress) => saveProgress(1, PUBLISHED_STATUS), text: 'Publish' },
  [SAVE_PROGRESS]: { action: (saveProgress) => saveProgress(0), text: 'Save progress' },
  [SUBMIT_ARTICLE]: { action: (saveProgress) => saveProgress(1, SUBMITTED_STATUS), text: 'Submit article' },
  [UPDATE_ARTICLE]: { action: (saveProgress) => saveProgress(1), text: 'Update article' },
};

const STICKY_FOOTER_ID = 'newsAdminStickyFooter';

/**
 * Helpers
 */
const getActionableKey = (article, hasChanges, isAdminOrEditor) => {
  const status = getInObj(['status'], article);

  // When status is null, it means we're on the /new path.
  if (status === null) return SAVE_PROGRESS;

  if (isAdminOrEditor) {
    if (status === PUBLISHED_STATUS) return UPDATE_ARTICLE;

    return hasChanges ? SAVE_PROGRESS : PUBLISH_ARTICLE;
  } else {
    if ([PUBLISHED_STATUS, SUBMITTED_STATUS].includes(status)) return UPDATE_ARTICLE;

    return hasChanges ? SAVE_PROGRESS : SUBMIT_ARTICLE;
  }
};

/**
 * Helper Views
 */
const getBreadcrumb = ({ disabled, isBusy, toggleCurrentView }) => (
  <Breadcrumb
    classList={{ root: styles.actionsBreadcrumbRoot }}
    href="/news/admin"
    onClick={(e) => {
      e.preventDefault();
      if (!disabled && !isBusy) toggleCurrentView('default');
    }}
    size="M"
    text="Back to articles"
  />
);

const getTimeStamp = (article) => {
  if (!article) return null;

  return (
    <div className={`${typography.bodyS} ${typography.pebble} ${layout.hiddenMedDown}`}>
      {`Saved on ${timestampToPrettyDate(article.updated_at, DATE_AT_TIME_FORMAT, true)}`}
    </div>
  );
};

/**
 * Main Views
 */
const getFormView = ({ article, disabled, hasChanges, isAdminOrEditor, isBusy, saveProgress, toggleCurrentView, view }) => {
  const actionable = ACTIONABLE_MAP[getActionableKey(article, hasChanges, isAdminOrEditor)];

  return (
    <Fragment>
      {getBreadcrumb({ isBusy, toggleCurrentView })}
      {getTimeStamp(article)}
      <div className={`${layout.flex} ${styles.actionsFormViewButtons}`}>
        {(article !== null)
        && (
          <Button
            className={layout.marginRight10}
            colorStyle="secondary"
            disabled={disabled || isBusy}
            onClick={() => toggleCurrentView('preview')}
            size="md"
          >
            Preview
          </Button>
        )}
        <ActionableButton
          key={actionable.text} // Remount when text changes to reset "frozen" width
          color=""
          disabled={disabled}
          isBusy={isBusy}
          onClick={() => actionable.action(saveProgress)}
          size="md"
          text={actionable.text}
        />
      </div>
    </Fragment>
  );
};

const getPreviewView = ({ article, disabled, isAdminOrEditor, isBusy, toggleCurrentView }) => (
  <Fragment>
    {getBreadcrumb({ disabled, isBusy, toggleCurrentView })}
    {getTimeStamp(article)}
    <Button
      className={layout.marginRight10}
      colorStyle="secondary"
      disabled={isBusy || disabled}
      onClick={() => toggleCurrentView('form')}
      size="md"
    >
      Continue editing
    </Button>
  </Fragment>
);

const getViewForType = (props) => {
  switch (props.view) {
    case 'form':
      return getFormView(props);
    case 'preview':
      return getPreviewView(props);
    default:
      return null;
  }
};

/**
 * Component
 */
const StickyActionsBar = (props) => (
  <div className={`${styles.actionsBarRoot} ${utilStyles.posRelative}`} id={STICKY_FOOTER_ID}>
    <StickyWrapper
      className={`${styles.actionsBar} ${utilStyles.borderTop} ${layout.flexJustifyCenter}`}
      parentId={STICKY_FOOTER_ID}
      startPos="bottom"
      stickToEnd={false}
    >
      <div className={`${layout.wrapper1170} ${styles.actionsBarInner}`}>
        {getViewForType(props)}
      </div>
    </StickyWrapper>
  </div>
);

StickyActionsBar.propTypes = {
  article: PropTypes.shape({
    status: PropTypes.string,
    updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  disabled: PropTypes.bool,
  hasChanges: PropTypes.bool,
  isAdminOrEditor: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool,
  saveProgress: PropTypes.func,
  toggleCurrentView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired, // ['form', 'preview']
};

StickyActionsBar.defaultProps = {
  article: null,
  disabled: false,
  hasChanges: false,
  isBusy: false,
  saveProgress: null,
};

export default StickyActionsBar;
