import PropTypes from 'prop-types';
import React from 'react';

import getPlaceholderText from './placeholders.js';
import urlService from '../../../services/url_service';
import defaultStyles from './content_placeholder.css';

const ContentPlaceholder = ({ classList, content, label, type, isProfileOwner, templateArgs }) => {
  const styles = { ...defaultStyles, ...classList };

  function assembleSegments(segments, type) {
    return segments.map((segment, i) => (
      getSegment(segment, getClassNames(type, i), `${type}-segment-${i}`, i)
    ));
  }

  function getClassNames(type, idx) {
    const { labelSegments, placeholderSegments } = styles;

    return type === 'label'
      ? {
          link: styles.labelLink,
          text: styles.label,
          bySegmentIdx: (labelSegments && labelSegments[idx]) || '',
        }
      : {
          link: styles.placeholderLink,
          text: styles.placeholderText,
          bySegmentIdx: (placeholderSegments && labelSegments[idx]) || '',
        };
  }

  function getSegment(segment, classNames, key, idx) {
    const { link, text, bySegmentIdx } = classNames;

    return typeof segment === 'object' && segment !== null
      ? (<a key={key} className={`${link} ${bySegmentIdx}`} href={urlService.url(segment.href)}>{segment.text}</a>)
      : (<span key={key} className={`${text} ${bySegmentIdx}`}>{segment}</span>);
  }

  return (
    <div className={styles.container}>
      {assembleSegments(label, 'label')}
      <div className={styles.content}>
        {assembleSegments(getPlaceholderText(isProfileOwner, type, templateArgs) || content, 'placeholder')}
      </div>
    </div>
  );
};

ContentPlaceholder.propTypes = {
  classList: PropTypes.shape({
    container: PropTypes.string,
    content: PropTypes.string,
    label: PropTypes.string,
    labelLink: PropTypes.string,
    placeholderText: PropTypes.string,
    placeholderLink: PropTypes.string,
    labelSegments: PropTypes.object,
    placeholderSegments: PropTypes.object,
  }),
  content: PropTypes.array,
  isProfileOwner: PropTypes.bool,
  label: PropTypes.array,
  templateArgs: PropTypes.object,
  type: PropTypes.string,
};

ContentPlaceholder.defaultProps = {
  classList: {},
  content: [],
  isProfileOwner: false,
  label: [],
  templateArgs: {},
  type: '',
};

export default ContentPlaceholder;
