import React from 'react';
import PropTypes from 'prop-types';

import { cleanPreAndPostSlashes } from '../../../utility/urls';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const appendToRootPath = (rootPath, path) => `/${cleanPreAndPostSlashes(`${rootPath}/${path}`)}`;

const SignupTOS = ({ rootPath }) => (
  <div className={`${typography.bodyS} ${typography.pebble} ${layout.marginBottom10}`}>
    {'By signing up, you agree to our '}
    <a href={appendToRootPath(rootPath, 'terms')} rel="noreferrer" target="_blank">Terms</a>
    {', '}
    <a href={appendToRootPath(rootPath, 'privacy')} rel="noreferrer" target="_blank">Privacy Policy</a>
    {', and '}
    <a href={appendToRootPath(rootPath, 'cookies')} rel="noreferrer" target="_blank">Cookie Policy</a>
    .
  </div>
);

SignupTOS.propTypes = { rootPath: PropTypes.string.isRequired };

export default SignupTOS;
