import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../buttons/base';
import DropZone from '../../wrappers/drop_zone';
import ProgressBar from '../image_uploaders/basic_image_uploader/progress_bar';

import errorHandler from '../../../services/error_handler';
import uploadFile from '../../../services/file_upload';

import { getAWSResources } from '../../../utility/images';
import { getFilesFromEvent } from '../../../utility/events';
import { getFileNameFromUrl } from '../../../utility/links';
import { pollForAttachment } from '../../../requests/attachments';

import { GENERIC_ERROR } from '../../../constants/alerts';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

class BasicFileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = { isBusy: false };

    this.handleFileEvent = this.handleFileEvent.bind(this);
    this.handleUploadBtnClick = this.handleUploadBtnClick.bind(this);

    // refs
    this._fileInput;
  }

  /**
   * Methods
   */
  handleFileEvent(e) {
    e.preventDefault();

    const file = getFilesFromEvent(e)[0];
    if (this._fileInput) this._fileInput.value = '';

    this._uploadFile(file);
  }

  handleUploadBtnClick(e) {
    if (this._fileInput) {
      e.preventDefault();
      this._fileInput.click();
    }
  }

  /**
   * Helpers
   */

  _reportError(err) {
    this.props.reportError(GENERIC_ERROR);
    this.props.propagateStatus(false);
    this.setState({ isBusy: false });
    errorHandler(err);
  }

  _reportUpload(fileData) {
    this.props.propagateUpload(fileData);
    this.props.propagateStatus(false);
    this.setState({ isBusy: false });
  }

  _uploadFile(file) {
    this.setState({ isBusy: true });

    const { S3BucketURL } = getAWSResources();

    return uploadFile(file, { S3BucketURL })
      .then((id) => pollForAttachment(id))
      .then((attachment) => this._reportUpload({ id: attachment.id, name: file.name, url: attachment.file.url }))
      .catch((err) => this._reportError(err));
  }

  _getDisplayName() {
    return this.props.fileData.name || getFileNameFromUrl(this.props.fileData.url);
  }

  /**
   * Views
   */
  _getButtonView() {
    const hasFile = !!this.props.fileData.id;

    return (
      <div className={layout.flexStartItems}>
        <Button className={layout.flex00Auto} disabled={this.state.isBusy} onClick={this.handleUploadBtnClick} size="lg">
          {hasFile ? 'Change file' : 'Select file'}
        </Button>
        {hasFile
        && (
          <div className={`${layout.flex1} ${layout.marginLeft15} ${typography.breakWord}`}>
            <a className={`${typography.link} ${typography.bodyM}`} href={this.props.fileData.url}>
              {this._getDisplayName()}
            </a>
          </div>
        )}
      </div>
    );
  }

  _getImagePreview() {
    if (!(this.props.imagePreview && this.props.fileData.url)) return null;

    return (
      <div className={layout.marginBottom15}>
        <img alt={this._getDisplayName()} src={this.props.fileData.url} />
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this._getImagePreview()}
        <DropZone disabled={this.state.isBusy} onDrop={this.handleFileEvent}>
          {' '}
          {/* TODO: visual dragover indication */}
          <input
            ref={(el) => this._fileInput = el}
            accept={this.props.accept}
            onChange={this.handleFileEvent}
            style={{ display: 'none' }}
            type="file"
          />
          {this.state.isBusy ? (<ProgressBar />) : this._getButtonView()}
        </DropZone>
      </Fragment>
    );
  }
}

BasicFileUploader.propTypes = {
  accept: PropTypes.string,
  fileData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  imagePreview: PropTypes.bool,
  propagateStatus: PropTypes.func,
  propagateUpload: PropTypes.func.isRequired,
  reportError: PropTypes.func,
};

BasicFileUploader.defaultProps = {
  accept: null,
  fileData: {},
  imagePreview: false,
  propagateStatus: () => {},
  reportError: () => {},
};

export default BasicFileUploader;
