/** Respectable Types */
export const COMMENT = 'COMMENT';
export const FEED_POST = 'FEED_POST';
export const NEWS_ARTICLE = 'NEWS_ARTICLE';
export const PROJECT = 'PROJECT';

const TYPE_TO_LABEL_MAP = {
  COMMENT: 'comment',
  FEED_POST: 'feed_post',
  NEWS_ARTICLE: 'news_article',
  PROJECT: 'project',
};

export const respectableTypeEnumToLabel = (type) => TYPE_TO_LABEL_MAP[type];
