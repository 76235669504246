import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../form_components/checkboxes/custom';

import typography from '../../../styles/global_ui/typography.css';
import styles from '../../../styles/global_ui/table.css';

const SelectableCell = ({ body, className, disabled, isSelected, onCheckboxChange }) => (
  <td className={className}>
    <span className={`${isSelected ? styles.selectableCellHighlight : styles.selectableCellPlaceholder}`} />
    <span className={styles.selectableCellInner}>
      <Checkbox disabled={disabled} isChecked={isSelected} onChange={onCheckboxChange} />
      <span className={`${typography.bodyM} ${styles.selectableCellBody} ${disabled ? styles.disabled : ''}`}>
        {body}
      </span>
    </span>
  </td>
);

SelectableCell.propTypes = {
  body: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func,
};

SelectableCell.defaultProps = {
  className: '',
  disabled: false,
  onCheckboxChange: () => {},
};

export default SelectableCell;
