import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';

class Route extends Component {
  constructor(props) {
    super(props);

    this.fetchState = this.fetchState.bind(this);
    this.state = { working: true };
  }

  componentDidMount() {
    this.fetchState(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.path !== this.props.path) {
      // Allow fetchState to work in the background while user navigates.
      if (this.state.working) this.setState({ working: false });
      this.fetchState(nextProps);
    }
  }

  fetchState(props) {
    const { store, path, request } = props;

    if (request && typeof request === 'function' && !store.has(path)) {
      this.setState({ working: true });

      return store.dispatch(request, path)
        .then(() => this.setState({ working: false }))
        .catch(() => this.setState({ working: false })); // TODO: this is failing silently, we should do something.
    } else {
      this.setState({ working: false });
    }
  }

  render() {
    const storeData = this.props.store.has(this.props.path) ? this.props.store.get(this.props.path) : {};
    const waitForProps = this.props.request && typeof this.props.request === 'function' && !this.props.store.has(this.props.path);

    return (
      <div>
        {waitForProps || this.state.working
          ? <Loader />
          : React.createElement(this.props.component, {
            ...this.props.routerProps,
            ...storeData,
            history: this.props.history,
            key: this.props.path,
            path: this.props.path,
            setMessage: this.props.setMessage,
            store: this.props.store,
          })}
      </div>
    );
  }
}

Route.propTypes = {
  component: PropTypes.func.isRequired,
  history: PropTypes.object, // isRequired, but since we inject via cloneElement, it will render once without.
  path: PropTypes.string.isRequired,
  request: PropTypes.func,
  routerProps: PropTypes.object,
  setMessage: PropTypes.func,
  store: PropTypes.object, // isRequired, but since we inject via cloneElement, it will render once without.
};

Route.defaultProps = {
  history: {},
  request: null,
  routerProps: {},
  setMessage: () => {},
  store: {},
};

export default Route;
