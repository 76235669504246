/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AddSectionView from './AddSectionView';
import EditCTAView from '../../home_edit/home_sections/EditCTAView';
import EditPromotedContentView from '../../home_edit/home_sections/EditPromotedContentView';
import PreviewView from './PreviewView';
import TableView from './TableView';

import { graphQuery } from '../../../requests/graphql';
import { getInObj } from '../../../utility/accessors';
import errorHandler from '../../../services/error_handler';

const KNOWN_SORTS_FOR_CATEGORY_SELECTION_LIST = ['popular', 'published'];
const MOST_RECENT_SECTION_DEFAULTED = {
  key: 'sort',
  label: 'Most recent',
  meta: { msg: 'Default section when sections are emptied.' },
  title: 'Most recent',
  value: 'published',
};
const PROJECT_COUNTS_TO_SECTION_KEY_MAP = {
  category_id: 'categories',
  part_id: 'parts',
  sort: 'all_projects_count',
  tag_id: 'tags',
  topic_id: 'topics',
};

// NOTE: Currently this uses Rails forms to save the sections. When moving to the Dashboard, remove the hidden input and deps.
class ChannelManageHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categorySelectionList: {},
      categorySelectionListMaster: this.props.channel.category_selection_list,
      currentSectionData: null, // {index, data}
      hiddenInputValue: '',
      partIdsFilterFacet: [],
      projectRelationsCounts: {},
      sections: null,
      tagSelectionList: [],
      tagSelectionListMaster: this.props.channel.tag_selection_list || [], // TopicChannels only
      view: 'table', // ['addSection', 'preview', 'table']
    };

    this.appendSection = this.appendSection.bind(this);
    this.removeSection = this.removeSection.bind(this);
    this.updateSection = this.updateSection.bind(this);
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._initializeProjectsCountForSections();
    window.addEventListener('submit:ChannelSettingsForm', (e) => e.detail.callback(['addSection', 'editCTA', 'editPromoted'].includes(this.state.view)));
  }

  /**
   * Initializers
   */
  _initializeProjectsCountForSections() {
    return this._fetchProjectRelationsCounts()
      .then((projectRelationsCounts) => {
        const sections = this.props.channel.home_sections;
        const categorySelectionListWithCounts = this._mergeProjectCountsToSelectionList(projectRelationsCounts);
        const tagSelectListWithCounts = this._mergeProjectCountsToTagSelectionList(projectRelationsCounts);

        this.setState({
          categorySelectionList: this._dedupeCategorySelectionList(sections, categorySelectionListWithCounts),
          categorySelectionListMaster: categorySelectionListWithCounts,
          hiddenInputValue: this._composeInputJsonValue(sections),
          partIdsFilterFacet: this._getPartIdsFilterFacet(sections),
          projectRelationsCounts: projectRelationsCounts,
          sections: this._mergeProjectCountsToSections(sections, projectRelationsCounts),
          tagSelectionList: this._dedupeTagSelectionList(sections, tagSelectListWithCounts),
          tagSelectionListMaster: tagSelectListWithCounts,
        });
      })
      .catch((err) => errorHandler('_initializeProjectsCountForSections', err));
  }

  _mergeProjectCountsToSections(sections, projectRelationsCounts) {
    return sections.reduce((acc, section) => {
      if ((['category_id', 'topic_id'].includes(section.key)) && section.value === 'featured') {
        acc = acc.concat({ ...section, record: { projects_count: projectRelationsCounts.featured_count } });
      } else if (PROJECT_COUNTS_TO_SECTION_KEY_MAP.hasOwnProperty(section.key)) {
        const column = projectRelationsCounts[PROJECT_COUNTS_TO_SECTION_KEY_MAP[section.key]];

        if (typeof column === 'number') {
          acc = acc.concat({ ...section, record: { projects_count: column } });
        } else {
          const count = column && column.hasOwnProperty(section.value) ? column[section.value] : 0;
          acc = acc.concat({ ...section, record: { projects_count: count } });
        }
      } else {
        acc = acc.concat({ ...section, record: { projects_count: 0 } });
      }

      return acc;
    }, []);
  }

  _mergeProjectCountsToTagSelectionList(projectRelationsCounts) {
    const tagsBucket = projectRelationsCounts.tags || {};

    return this.state.tagSelectionListMaster.map((opt) => ({ ...opt, record: { projects_count: tagsBucket[opt.value] || 0 } }));
  }

  _mergeProjectCountsToSelectionList(projectRelationsCounts) {
    const categoryListKey = this.state.categorySelectionListMaster.key;
    const optionsWithProjectsCount = this.state.categorySelectionListMaster.options.map((opt) => {
      const column = projectRelationsCounts[PROJECT_COUNTS_TO_SECTION_KEY_MAP[categoryListKey]];

      if (opt.value === 'featured') {
        return { ...opt, record: { projects_count: projectRelationsCounts.featured_count } };
      } else if (column && column.hasOwnProperty(opt.value)) {
        // catergories or topics
        return { ...opt, record: { projects_count: (column[opt.value] || 0) } };
      } else if (KNOWN_SORTS_FOR_CATEGORY_SELECTION_LIST.includes(opt.value)) {
        // sorts
        return { ...opt, record: { projects_count: (projectRelationsCounts.all_projects_count || 0) } };
      } else {
        // fallback
        return { ...opt, record: { projects_count: 0 } };
      }
    }).sort((a, b) => {
      if (a.record.projects_count > b.record.projects_count) return -1;
      if (a.record.projects_count < b.record.projects_count) return 1;

      return 0;
    });

    return { key: categoryListKey, options: optionsWithProjectsCount };
  }

  /**
   * Methods
   */
  appendSection(section, type) {
    switch (type) {
      case 'category':
        return this._addCategoryOrTagToSections(section);

      case 'cta':
        return this._addSection(section);

      case 'product':
        return this._addPartToSections(section);

      case 'promotedContent':
        return this._addSection(section);

      case 'tag':
        return this._addTagToSections(section);

      default:
        return null;
    }
  }

  removeSection(section) {
    const updatedSections = this.state.sections.filter((sect, i) => section.index !== i);

    if (!updatedSections.length) {
      updatedSections.push(MOST_RECENT_SECTION_DEFAULTED);
    }

    this.setState({
      categorySelectionList: this._dedupeCategorySelectionList(updatedSections, this.state.categorySelectionListMaster),
      hiddenInputValue: this._composeInputJsonValue(updatedSections),
      partIdsFilterFacet: this._getPartIdsFilterFacet(updatedSections),
      sections: updatedSections,
      tagSelectionList: this._dedupeTagSelectionList(updatedSections, this.state.tagSelectionListMaster),
    });
  }

  updateSection(section) {
    const updatedSections = this.state.sections.map((sect, i) => (section.index === i) ? section.data : sect);
    this.setState({
      hiddenInputValue: this._composeInputJsonValue(updatedSections),
      sections: updatedSections,
    });
  }

  /**
   * Requests
   */
  _fetchProjectRelationsCounts() {
    return new Promise((resolve, reject) => graphQuery({ t: 'get_channel_relations_projects_count' }, { id: this.props.channel.id })
    // Protect against nulls when the record doesnt exist in the matview which can happen for newly created platforms.
      .then((res) => resolve(getInObj(['channel', 'relations_projects_counts'], res) || {}))
      .catch((err) => reject(err)));
  }

  /**
   * Helpers
   */
  _addSection(section) {
    const updatedSections = this.state.sections.concat(section);
    this.setState({
      hiddenInputValue: this._composeInputJsonValue(updatedSections),
      sections: updatedSections,
      view: 'table',
    });
  }

  _addCategoryOrTagToSections(section) {
    const updatedSections = this.state.sections.concat(section);
    this.setState({
      categorySelectionList: this._dedupeCategorySelectionList(updatedSections, this.state.categorySelectionListMaster),
      hiddenInputValue: this._composeInputJsonValue(updatedSections),
      sections: updatedSections,
      view: 'table',
    });
  }

  _addPartToSections(section) {
    const updatedSections = this.state.sections.concat(section);
    this.setState({
      partIdsFilterFacet: section.key === 'part_id' ? this._getPartIdsFilterFacet(updatedSections) : this.state.partIdsFilterFacet,
      hiddenInputValue: this._composeInputJsonValue(updatedSections),
      sections: updatedSections,
      view: 'table',
    });
  }

  _addTagToSections(section) {
    const updatedSections = this.state.sections.concat(section);
    this.setState({
      hiddenInputValue: this._composeInputJsonValue(updatedSections),
      sections: updatedSections,
      tagSelectionList: this._dedupeTagSelectionList(updatedSections, this.state.tagSelectionListMaster),
      view: 'table',
    });
  }

  _composeInputJsonValue(sections) {
    return JSON.stringify(sections);
  }

  _dedupeCategorySelectionList(sections, categoryList) {
    const updatedOptions = categoryList.options.filter((option) => sections.findIndex((section) => ((section.key === categoryList.key || section.key === 'sort') && section.value === option.value),
    ) === -1);

    return { ...categoryList, options: updatedOptions };
  }

  _dedupeTagSelectionList(sections, tagList) {
    return tagList.filter((opt) => sections.findIndex((section) => section.key === 'tag_id' && section.value === opt.value) === -1);
  }

  _getPartIdsFilterFacet(sections) {
    return sections.reduce((acc, section) => (section.key === 'part_id') ? acc.concat(section.value) : acc, []);
  }

  _toggleView(view, sectionData = null) {
    this.setState({
      view,
      currentSectionData: sectionData,
    }, () => document.getElementById('main').scrollIntoView());
  }

  /**
   * Views
   */
  _getMainView() {
    switch (this.state.view) {
      case 'addSection':
        return this._getAddSectionView();

      case 'editCTA':
        return this._getEditCTAView();

      case 'editPromoted':
        return this._getEditPromotedContentView();

      case 'preview':
        return this._getPreviewView();

      case 'table':
      default:
        return this._getTableView();
    }
  }

  _getAddSectionView() {
    return (
      <AddSectionView
        appendSection={this.appendSection}
        categorySelectionList={this.state.categorySelectionList}
        channel={{ id: this.props.channel.id, type: this.props.channel.type }}
        knownSorts={KNOWN_SORTS_FOR_CATEGORY_SELECTION_LIST}
        partIdsFilterFacet={this.state.partIdsFilterFacet}
        projectRelationsCounts={this.state.projectRelationsCounts}
        tagSelectionList={this.state.tagSelectionList}
        toggleView={() => this._toggleView('table')}
      />
    );
  }

  _getEditCTAView() {
    return (
      <EditCTAView
        ctaSection={this.state.currentSectionData}
        toggleView={(view) => this._toggleView(view)}
        updateSection={this.updateSection}
      />
    );
  }

  _getEditPromotedContentView() {
    return (
      <EditPromotedContentView
        section={this.state.currentSectionData}
        toggleView={(view) => this._toggleView(view)}
        updateSection={this.updateSection}
      />
    );
  }

  _getPreviewView() {
    return (
      <PreviewView
        channel={this.props.channel}
        sections={this.state.sections}
        toggleView={(view) => this._toggleView(view)}
      />
    );
  }

  _getTableView() {
    return (
      <TableView
        removeSection={this.removeSection}
        sections={this.state.sections}
        toggleView={(view, sectionData) => this._toggleView(view, sectionData)}
        updateSection={this.updateSection}
        updateSectionsOrder={(sections) => this.setState({ sections, hiddenInputValue: this._composeInputJsonValue(sections) })}
      />
    );
  }

  render() {
    return (
      <div>
        <input id="group_home_sections" name="group[home_sections]" type="hidden" value={this.state.hiddenInputValue} />
        {this._getMainView()}
      </div>
    );
  }
}

ChannelManageHome.propTypes = {
  channel: PropTypes.shape({
    category_selection_list: PropTypes.shape({
      key: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      })),
    }).isRequired,
    home_sections: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    tag_selection_list: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })),
    type: PropTypes.string.isRequired,
    user_name: PropTypes.string.isRequired, // For Algolia ViewAllBuilder configs
  }).isRequired,
};

ChannelManageHome.defaultProps = { channel: { tag_selection_list: [] } };

export default ChannelManageHome;
