import React from 'react';
import PropTypes from 'prop-types';

import buttonStyles from '../../../../../styles/global_ui/buttons.css';
import typography from '../../../../../styles/global_ui/typography.css';
import styles from '../../section_list/section_list.css';

const DragHandle = ({ attributes, listeners }) => (
  <span {...attributes} {...listeners} className={styles.dragHandle}>
    <span className={styles.dragHandleIcon}>: :</span>
  </span>
);

const ContentCard = ({ attributes, item, itemIndex, listeners, removeItem, updateItem }) => {
  const { contentType, title } = item;

  return (
    <div className={itemIndex < 4 ? styles.rowHighlight : styles.row}>
      <DragHandle attributes={attributes} listeners={listeners} />
      <span className={styles.contentCardTitle}>
        <span className={styles.title}>
          <span>{title}</span>
        </span>

        <span className={styles.buttonCntr}>
          <button className={buttonStyles.blank} onClick={() => updateItem({ index: itemIndex, data: item })} type="button">
            <span className={`${typography.bodyS} ${typography.linkBlue} ${typography.bold}`}>Edit</span>
          </button>
        </span>
      </span>

      <span className={`${styles.cell} ${styles.cell20}`}>{contentType.value}</span>

      <span className={`${styles.cell} ${styles.cell20}`}>
        <button className={buttonStyles.blank} onClick={() => removeItem({ index: itemIndex, data: item })} type="button">
          <span className={`${typography.bodyS} ${typography.linkBlue} ${typography.bold}`}>Remove</span>
        </button>
      </span>
    </div>
  );
};

ContentCard.propTypes = {
  item: PropTypes.shape({
    contentType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    description: PropTypes.string,
    image11: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    image43: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    link: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    })),
    tags: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    })),
    title: PropTypes.string,
  }).isRequired,
  itemIndex: PropTypes.number.isRequired,
  removeItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

export default ContentCard;
