import React from 'react';
import PropTypes from 'prop-types';

import shapes from './shapes';
import typography from '../../../styles/global_ui/typography.css';

/* SVG Icons that are not part of the core icon set */
const CustomIcon = ({ className, name, size, style }) => {
  const icon = shapes[name];
  const sizeClass = typography[`icon${size}`];

  return (
    <svg className={`${typography.icon} ${sizeClass} ${className}`} style={style} {...icon.svgProps}>
      {icon.shape}
    </svg>
  );
};

CustomIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
};

CustomIcon.defaultProps = {
  className: typography.iconfill,
  style: {},
};

export default CustomIcon;
