import React from 'react';
import PropTypes from 'prop-types';

// HOC
import adAnalyticsTracker from '../wrappers/adAnalyticsTracker';

import SimpleAd, { getPlaceholderView } from '../simple_ad';
import GenericPortal from '../../portals/GenericPortal';

import layout from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './article_page_simple_ad.css';

export const PORTAL_ID = 'article_page_simple_ad_portal';

const getAdView = ({ ad, initialized, onClick }) => {
  if (!initialized || ad === null) return getPlaceholderView();

  return (<SimpleAd ad={ad} initialized={initialized} onClick={onClick} />);
};

const getDivider = () => (<div className={`${styles.divider} ${utilStyles.borderTop}`} />);

const ArticlePageSimpleAd = (props) => (
  <div ref={props.forwardedRef}>
    <div className={layout.hiddenMedDown}>{getAdView(props)}</div>
    <GenericPortal id={PORTAL_ID}>
      <div className={`${layout.hiddenMedUp} ${layout.flexColumn} ${layout.flexCenterItems} ${layout.marginTop30}`}>
        {getDivider()}
        <div className={`${layout.paddingTop30} ${layout.paddingBottom30}`}>
          {getAdView(props)}
        </div>
        {getDivider()}
      </div>
    </GenericPortal>
  </div>
);

ArticlePageSimpleAd.displayName = 'ArticlePageSimpleAd';
ArticlePageSimpleAd.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  initialized: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ArticlePageSimpleAd.defaultTypes = { ad: null };

export default adAnalyticsTracker(ArticlePageSimpleAd);
