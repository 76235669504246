/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { escape } from 'validator';

import createBoundingBox from './createBoundingBox';
import { timeNowUnixSeconds } from '../../../utility/time';
import { validateLocationObj } from './location';

const filterWhitelist = {
  events: {
    attendance_type: (val) => keyValueFilter(val, 'attendance_type'),
    date: (val) => pastOrUpcoming(val, 'filter_timestamp'),
    event_type: (val) => keyValueFilter(val, 'event_type'),
    location: 'location',
  },
  projects: {
    difficulty: (types = [], key = 'difficulty') => arrayToSQL(types, key, 'OR'),
    language: (languages = [], key = 'programming_languages') => arrayToSQL(languages, key, 'OR'),
    parts: (parts = [], key = 'parts.full_name') => arrayToSQL(parts, key, 'AND'),
    project_type: (types = [], key = 'content_type') => arrayToSQL(types, key, 'OR'),
  },
  users: {
    available_for_hire: (bool, key = 'available_for_hire') => fillIfTrue(bool, key),
    location: 'location',
    skills: (types = [], key = 'skills') => arrayToSQL(types, key, 'AND'),
  },
  videos: { category: (types = [], key = 'category') => arrayToSQL(types, key, 'OR') },
};

function arrayToSQL(array, property, SQLseperator) {
  return `(${array.map((item) => `${property}:"${escape(item)}"`).join(` ${SQLseperator} `)})`;
}

function fillIfTrue(bool, key) {
  return bool ? `(${key}:true)` : '';
}

function keyValueFilter(val, key) {
  return val ? `(${key}:${val})` : '';
}

function pastOrUpcoming(val, key) {
  if (!val) return '';

  const symbol = val === 'past' ? '<' : '>';

  return `(${key} ${symbol} ${timeNowUnixSeconds()})`;
}

// Some filters from the UI need to be in seperate fields for the request, such as location data.
// This will ignore them from the filters string.
function filtersForFilterString(index, key) {
  const ingorePropertiesForFilterString = { location: true };

  return (
    filterWhitelist.hasOwnProperty(index)
    && filterWhitelist[index].hasOwnProperty(key)
    && ingorePropertiesForFilterString.hasOwnProperty(key) === false
  );
}

function buildBoundingBox(filters) {
  if (!filters.hasOwnProperty('location')) return {};
  if (validateLocationObj(filters.location) === false) return {};
  const boundingBox = createBoundingBox(filters.location.geoloc, filters.location.distance);

  if (!Array.isArray(boundingBox)) return {};

  return { insideBoundingBox: [boundingBox] };
}

// Creates a "mirror-key" object of all filters above.  I.E.({difficulty: 'difficulty'})
export const FILTER_WHITELIST = (function(whitelist) {
  return Object.keys(whitelist).reduce((acc, type) => {
    Object.keys(whitelist[type]).forEach((key) => {
      acc[key] = key;
    });

    return acc;
  }, {});
}(filterWhitelist));

export default function buildParamsFromFilters(index, filters = {}) {
  const keys = Object.keys(filters);
  if (!keys.length) return '';
  if (!filterWhitelist.hasOwnProperty(index)) return '';

  const filterString = keys.reduce((acc, key) => {
    if (filtersForFilterString(index, key)) {
      const filter = filterWhitelist[index][key](filters[key]);

      return filter.length ? acc.concat(filter) : acc;
    }

    return acc;
  }, []).join(' AND ');

  return {
    filters: filterString,
    ...buildBoundingBox(filters),
  };
}
