import React from 'react';
import PropTypes from 'prop-types';

import GridList from '../../../../client/wrappers/grid_list';
import VideoCard from '../../../../client/cards/video_card';

import typography from '../../../../styles/global_ui/typography.css';

const DefaultView = () => (
  <div className={`${typography.bodyM} ${typography.bold} ${typography.textCenter}`}>Preview will populate once 4 videos are selected</div>
);

const PreviewView = ({ records }) => (
  <GridList
    ItemComponent={VideoCard}
    horizontal={true}
    itemProps={{ onClick: (e) => e.preventDefault() }}
    placeholder={DefaultView()}
    records={records.map((r) => ({ ...r, image_url: r.image.url }))}
    validationFn={({ records }) => records.length >= 4}
  />
);

PreviewView.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    description: PropTypes.string.isRequired,
    duration: PropTypes.integer,
    id: PropTypes.number,
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

export default PreviewView;
