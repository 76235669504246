import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import MediaElement from './MediaElement';

import { processURL } from './helpers';

import layout from '../../styles/global_ui/layout.css';
import styles from '../reusable_components/LazyImage/lazy_image.css';

// behaves like LazyImage but without the lazy part
// TODO: move LazyImage to this directory
class BasicImage extends PureComponent {
  _processProps({ fit, queryParams, ratio, setStyle, src, width }) {
    const processed = processURL({ fit, queryParams, ratio, src, width, useSrcSet: true });

    const style = setStyle ? { height: processed.height, width } : null;

    return {
      format: processed.format,
      src: processed.src,
      srcSet: processed.srcSet,
      style,
    };
  }

  render() {
    const { alt, className, onClick, onMouseLeave, onMouseOver } = this.props;
    const { format, src, srcSet, style } = this._processProps(this.props);

    return (
      <div
        className={`${className} ${styles.root}`}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        style={style}
      >
        <MediaElement
          alt={alt}
          className={layout.fullWidth}
          format={format}
          src={src}
          srcSet={srcSet}
        />
      </div>
    );
  }
}

BasicImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  fit: PropTypes.string,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  queryParams: PropTypes.object,
  ratio: PropTypes.string.isRequired,
  setStyle: PropTypes.bool, // Set inline width/height styles to calculated dimensions
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

BasicImage.defaultProps = {
  alt: '',
  className: '',
  fit: 'min',
  onClick: () => {},
  onMouseLeave: () => {},
  onMouseOver: () => {},
  queryParams: {},
  setStyle: true,
};

export default BasicImage;
