import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';

import intersectionObserverService, { INTERSECTION_DATA_ATTR } from '../../../services/intersection_observer';
import { isAFunction } from '../../../utility/types';
import { isElementInView } from '../../../utility/document';

/**
 * For components that are below the fold, we lazy instantiate til its in the viewport.
 */
class LazyComponent extends Component {
  constructor(props) {
    super(props);

    this.addEventListeners = this.addEventListeners.bind(this);
    this.removeEventListeners = this.removeEventListeners.bind(this);
    this.handleIntersection = this.handleIntersection.bind(this);
    this.handleReveal = this.handleReveal.bind(this);

    this.state = {
      dataRef: null,
      isRevealed: false,
    };

    this.unobserve;

    // ref
    this._container;
  }

  componentDidMount() {
    this._revealOrListen();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  _getRootMargin() {
    return `${this.props.verticalOffset}px ${this.props.horizontalOffset}px`;
  }

  _isInView() {
    return isElementInView(this._container, { bufferH: this.props.horizontalOffset, bufferV: this.props.verticalOffset });
  }

  _revealOrListen() {
    return this._isInView() ? this.handleReveal() : this.addEventListeners();
  }

  addEventListeners() {
    if (this._container) {
      this.setState({ dataRef: uuidv4() }, () => {
        this.unobserve = intersectionObserverService.observe({
          callback: this.handleIntersection,
          id: this.state.dataRef,
          options: { rootMargin: this._getRootMargin() },
          target: this._container,
        });
      });
    }
  }

  removeEventListeners() {
    if (this.unobserve) {
      this.unobserve();
      this.unobserve = undefined;
    }
  }

  handleIntersection(entry) {
    if (entry.isIntersecting && !this.state.isRevealed && this._container) {
      this.handleReveal();
    }
  }

  handleReveal() {
    this.setState({ isRevealed: true }, () => {
      this.props.onReveal();
      this.removeEventListeners();
    });
  }

  _renderChildren() {
    return isAFunction(this.props.children) ? this.props.children(this.state.isRevealed) : this.props.children;
  }

  render() {
    const props = {
      ref: (el) => this._container = el,
      [INTERSECTION_DATA_ATTR]: this.state.dataRef,
      className: this.props.className,
      onClick: this.props.onClick,
      onMouseLeave: this.props.onMouseLeave,
      onMouseOver: this.props.onMouseOver,
      style: this.props.style,
    };

    const children = (this.props.alwaysRenderChildren || this.state.isRevealed) ? this._renderChildren() : null;

    return React.createElement(this.props.component, props, children);
  }
}

LazyComponent.propTypes = {
  alwaysRenderChildren: PropTypes.bool, // Let parent decide what to display based on "reveal"
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  horizontalOffset: PropTypes.number,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  onReveal: PropTypes.func,
  style: PropTypes.object,
  verticalOffset: PropTypes.number,
};

LazyComponent.defaultProps = {
  alwaysRenderChildren: false,
  className: '',
  component: 'div',
  horizontalOffset: 0,
  onMouseLeave: null,
  onMouseOver: null,
  onReveal: () => {},
  style: {},
  verticalOffset: 100,
};

export default LazyComponent;
