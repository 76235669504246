import { EditorState, SelectionState } from 'draft-js';

import { insertParagraph } from '../utils/draftUtils';

const ATOMIC_BLOCKS = ['LEGACY_WIDGET', 'CAROUSEL', 'EMBED', 'TWEET', 'IMAGE_LINK'];

/**
 * IMPORTANT: In v0.11 these raise a deprecated flag. However, moving them to a customKeyBinding will swallow the event and break cursor navigation.
 * Until Draft fixes the issue, keep this logic.
 */

// function handleUpArrow(editorState, callback, e) {
  // const currentContentState = editorState.getCurrentContent();
  // const currentSelection = editorState.getSelection();
  // const currentBlock = currentContentState.getBlockForKey(currentSelection.getAnchorKey());
  // const prevBlock = currentContentState.getBlockBefore(currentSelection.getAnchorKey());

  // if(prevBlock && ATOMIC_BLOCKS.indexOf(prevBlock.getType()) !== -1) {
  //   e.preventDefault();

  //   this.props.toggleReadOnly(true);
  //   callback(EditorState.forceSelection(editorState, SelectionState.createEmpty(prevBlock.get('key'))));
  // } else if(ATOMIC_BLOCKS.indexOf(currentBlock.getType()) !== -1) {
  //   e.preventDefault();

  //   this.props.toggleReadOnly(false);
  //   callback(EditorState.forceSelection(editorState, SelectionState.createEmpty(prevBlock.get('key'))));
  // }
// }

function handleDownArrow(editorState, callback, e) {
  const currentContentState = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();
  const currentBlock = currentContentState.getBlockForKey(currentSelection.getAnchorKey());
  const nextBlock = currentContentState.getBlockAfter(currentSelection.getAnchorKey());

  // Appends a paragraph if the last block is a pre/code-block.
  if (currentBlock.getType() === 'code-block' && !nextBlock) {
    e.preventDefault();

    const { updatedContentState, updatedSelection } = insertParagraph(currentBlock, currentContentState, currentSelection);
    callback(EditorState.forceSelection(EditorState.push(editorState, updatedContentState), updatedSelection));
  }

  // if(nextBlock && ATOMIC_BLOCKS.indexOf(nextBlock.getType()) !== -1) {
  //   e.preventDefault();

  //   this.props.toggleReadOnly(true);
  //   callback(EditorState.forceSelection(editorState, SelectionState.createEmpty(nextBlock.get('key'))));
  // } else if(ATOMIC_BLOCKS.indexOf(currentBlock.getType()) !== -1) {
  //   e.preventDefault();

  //   this.props.toggleReadOnly(false);
  //   callback(EditorState.forceSelection(editorState, SelectionState.createEmpty(nextBlock.get('key'))));
  // }
}


export default function customArrowKeysHandler(direction, editor, callback, e) {
  switch(direction) {
    // case 'UP':
    //   handleUpArrow.call(this, editor, callback, e);
    //   break;

    case 'DOWN':
      handleDownArrow.call(this, editor, callback, e);
      break;

    default:
      break;
  }
}