import errorHandler from '../error_handler';
import { isObject } from '../../utility/types';

import { areCookiesSupported, deleteCookie, getCookie, setCookie } from '../cookies';

const LS = {
  delete: (k) => window.localStorage.removeItem(k),
  get: (k) => tryParse(window.localStorage.getItem(k)),
  set: (k, v) => window.localStorage.setItem(k, stringifyIfValid(v)),
};

const COOKIE = {
  delete: (k) => deleteCookie(k),
  get: (k) => getCookie(k),
  set: (k, v) => setCookie(k, v),
};

const NOOP = {
  delete: () => errorHandler('Local Storage and Cookies disabled!'),
  get: () => errorHandler('Local Storage and Cookies disabled!'),
  set: () => errorHandler('Local Storage and Cookies disabled!'),
};

/**
 * Helpers
 */
function stringifyIfValid(val) {
  if (isObject(val) || Array.isArray(val)) {
    return JSON.stringify(val);
  }

  return val;
}

// Attempt to parse an object or array that was stringified.
// Otherwise its likely a token or likewise string that we can return.
function tryParse(val) {
  try {
    return JSON.parse(val);
  } catch (err) {
    return val;
  }
}

// Sniffing for localStorage will throw an error when permissions are off in some browsers.  Try catch to the, "rescue".
function getLocalStorage() {
  try {
    return window.localStorage;
  } catch (err) {
    return null;
  }
}

function getStrategy() {
  return isLocalStorageSupported()
    ? LS
    : areCookiesSupported()
      ? COOKIE
      : NOOP;
}

export function isLocalStorageSupported() {
  const localStorage = getLocalStorage();

  if (!localStorage) return false;
  try {
    localStorage.setItem('testKey', '1');
    localStorage.removeItem('testKey');

    return true;
  } catch (error) {
    errorHandler('Local Storage is not supported', error);

    return false;
  }
}

export function deleteItem(key) {
  return getStrategy().delete(key);
}

export function getItem(key) {
  return getStrategy().get(key);
}

export function setItem(key, value) {
  return getStrategy().set(key, value);
}
