import React from 'react';
import PropTypes from 'prop-types';

import CategoryBadgeLabel from './CategoryBadgeLabel';

import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

const CategoryBadge = ({ basePath, categoryConfig, onCategoryTagClick }) => (
  <a
    className={`${utilStyles.categoryBadge} ${typography.anchorNoUnderlineOnHover}`}
    href={`${basePath}/${categoryConfig.path}`}
    onClick={(e) => onCategoryTagClick(e, categoryConfig)}
  >
    <CategoryBadgeLabel categoryConfig={categoryConfig} />
  </a>
);

CategoryBadge.propTypes = {
  basePath: PropTypes.string.isRequired,
  categoryConfig: PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onCategoryTagClick: PropTypes.func.isRequired,
};

export default CategoryBadge;
