export const isADate = (val) => val instanceof Date && !isNaN(val.valueOf());
export const isAFunction = (val) => typeof val === 'function';
export const isAString = (val) => typeof val === 'string';

export const isBlank = (val) => (
  typeof val === 'undefined'
  || val === null
  || (typeof val === 'string' && val.trim() === '')
  || (Array.isArray(val) && val.length === 0)
  || (typeof val === 'object' && Object.keys(val).length === 0)
);

export const isNumber = (val) => {
  if (isBlank(val)) return false;

  return typeof val === 'number';
};

export const isObject = (val) => {
  if (val === null) return false;

  return !Array.isArray(val) && typeof val === 'object';
};

export const isObjectWithLength = (val) => {
  if (!isObject(val)) return false;

  return Object.keys(val).length > 0;
};
