import request from 'superagent';

import OAuth from '../services/oauth';
import { pollWithBackoff } from '../utility/polling';

export function getAttachment(attachmentId) {
  return OAuth.apiRequest(request(`${OAuth.getApiPath()}/private/files/${attachmentId}`));
}

export function getRemoteUploadJobStatus(jobId) {
  return new Promise((resolve, reject) => {
    OAuth.apiRequest(request(`${OAuth.getApiPath()}/private/files/remote_upload?job_id=${jobId}`))
      .then((res) => res.status === 'failed' ? reject(new Error('Remote upload failed')) : resolve(res))
      .catch((err) => reject(err));
  });
}

export function pollForAttachment(id) {
  return pollWithBackoff((resolve, reject, retry) => (
    getAttachment(id)
      .then((res) => res.processed === true ? resolve(res) : retry())
      .catch((err) => reject(err))
  ));
}

export function pollJob(jobId) {
  return pollWithBackoff((resolve, reject, retry) => (
    getRemoteUploadJobStatus(jobId)
      .then((res) => res.status === 'complete' ? resolve(res) : retry())
      .catch((err) => reject(err))
  ));
}

// NOTE: fileType param is deprecated and does not do anything
export function postRemoteURL(url, fileType, progressCallback = null, options = {}) {
  return new Promise((resolve, reject) => {
    const data = { ...options, file_type: fileType, file_url: url };

    return OAuth.apiRequest(
      request
        .post(`${OAuth.getApiPath()}/private/files/remote_upload`)
        .send(data)
        .on('progress', (e) => {
          if (progressCallback) progressCallback(e);
        }),
    )
      .then((body) => (
        /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
        /* eslint-disable-next-line no-prototype-builtins */
        body.hasOwnProperty('id')
          ? resolve(body)
          : reject(new Error(`Problem uploading remote file ${url}`))
      ))
      .catch((err) => reject(err));
  });
}
