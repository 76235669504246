import React, { Component } from 'react';

import Step from './components/Step';

import OAuth from '../../services/oauth';
import errorHandler from '../../services/error_handler';

import { postForm, getOptions } from './requests';
import { Countries } from '../utils/Countries';

const STEPS = [
  {
    name: 'University',
    type: 'University',
    form: {
      inputs: ['Name', 'City'],
      selections: [{ label: 'Country', options: Countries }],
      image: {
        aspect_ratio: 1,
        attribute_type: 'avatar',
        file_type: 'Avatar',
        human_file_type: 'Logo',
        model: 'group',
      },
      model_key: 'group',
      keys: { Name: 'full_name', City: 'city', Country: 'country', Logo: 'avatar_id' },
      endpoint: `${OAuth.getApiPath()}/private/groups`,
    },
    search: true,
  },
  {
    name: 'Course',
    type: 'Course',
    form: {
      inputs: ['Name', 'Course #'],
      textareas: [{ label: 'Pitch', rows: 3 }],
      model_key: 'group',
      keys: { Name: 'full_name', ['Course #']: 'course_number', Pitch: 'mini_resume' },
      endpoint: `${OAuth.getApiPath()}/private/groups`,
      member_role: 'professor',
    },
    search: true,
  },
  {
    name: 'Class',
    type: 'Promotion',
    form: {
      inputs: ['Year or semester'],
      image: null,
      model_key: 'group',
      keys: { 'Year or semester': 'full_name' },
      endpoint: `${OAuth.getApiPath()}/private/groups`,
      member_role: 'professor',
    },
    search: false,
  },
];

class UniversityCourseWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      university: null,
      course: null,
      promotion: null,
    };

    this.changeSelection = this.changeSelection.bind(this);
    this.getOptionsHandler = this.getOptionsHandler.bind(this);
    this.postFormHandler = this.postFormHandler.bind(this);
    this.setSelectionValue = this.setSelectionValue.bind(this);
  }

  /**
   * Methods
   */
  changeSelection(storeName) {
    if (storeName === 'university') {
      this.setState({
        university: null,
        course: null,
        promotion: null,
      });
    } else if (storeName === 'course') {
      this.setState({
        university: this.state.university,
        course: null,
        promotion: null,
      });
    } else {
      this.setState({ [storeName]: null });
    }
  }

  getOptionsHandler(query) {
    return new Promise((resolve, reject) => getOptions(query)
      .then((res) => resolve(res))
      .catch((err) => {
        errorHandler('UniversityCourseWizard getOptionsHandler:', err);
        reject(err);
      }));
  }

  postFormHandler(form, endpoint, imageData) {
    return postForm(form, endpoint)
      .then((response) => {
        const store = imageData !== null ? { ...response, imageData } : response;

        this.setState({ [form.model_data.type.toLowerCase()]: store });
      })
      .catch((err) => {
        errorHandler('UniversityCourseWizard postFormHandler: ', err);
      });
  }

  setSelectionValue(store, storeName) {
    this.setState({ [storeName]: store });
  }

  /**
 * Helpers
 */
  _getActions() {
    return {
      getOptionsHandler: this.getOptionsHandler,
      postFormHandler: this.postFormHandler,
      setSelectionValue: this.setSelectionValue,
      changeSelection: this.changeSelection,
    };
  }

  /**
   * Views
   */
  _getCompletedBtn() {
    if (Object.keys(this.state).findIndex((key) => this.state[key] === null) > -1) return;

    return (
      <div className="completed-button-wrapper">
        <a className="btn btn-success" href={`/groups/${this.state.promotion.id}`}>Complete</a>
      </div>
    );
  }

  _getSteps() {
    return STEPS.map((step, index) => {
      const { name, type, form, search } = step;
      const prev = STEPS[index - 1];
      if (index === 0 || this.state[prev.name.toLowerCase()]) {
        return (
          <Step
            key={index}
            actions={this._getActions()}
            form={index > 0 ? { ...form, parentId: this.state[prev.name.toLowerCase()].id } : form}
            name={name}
            search={search}
            store={this.state}
            type={type}
          />
        );
      } else {
        return null;
      }
    }).filter((step) => step !== null);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
          <div className="course-wizard-wrapper box allow-overflow">
            <div className="box-title">
              <h2>Create a new course page</h2>
            </div>
            <div className="box-content">
              <div className="course-wizard">
                {this._getSteps()}
                {this._getCompletedBtn()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UniversityCourseWizard;
