const SCENE_WHITELIST = [
  'communities#react',
  'community#show',
  'platforms#react',
  'platforms#show',
  'projects#index',
  'topic_channels#react',
  'users#preferences',
  'users#dashboard',
  'users#show',
  'topic_channels#show',
  'videos#index',
];

export default SCENE_WHITELIST;
