function _isEndOfLine(textAreaValue, selectionEnd) {
  return (selectionEnd === textAreaValue.length) || (textAreaValue.slice(selectionEnd, (selectionEnd + 1)) === '\n');
}

export function insertStrAtCaretPos(textAreaValue, start, end, str) {
  return textAreaValue.slice(0, start) + str + textAreaValue.slice(end);
}

export function isAnEntireRowSelected(rows, textAreaValue, selectionEnd) {
  return (rows.length === 1) && _isEndOfLine(textAreaValue, selectionEnd);
}
