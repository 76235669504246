import React, { Component } from 'react';

// Freeze the width of a component after it mounts for the first time.
// Works with any component that takes a "style" prop
const freezeWidth = (PassedComponent) => {
  class FreezeWidth extends Component {
    constructor(props) {
      super(props);

      this.state = { style: {} };

      this._wrapper;
    }

    componentDidMount() {
      if (this._wrapper) {
        this.setState({ style: { width: Math.ceil(this._wrapper.getBoundingClientRect().width) } });
      }
    }

    render() {
      // TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call
      // eslint-disable-next-line no-prototype-builtins
      const style = this.props.hasOwnProperty(style) ? { ...this.props.style, ...this.state.style } : this.state.style;
      const newProps = { ...this.props, style };

      return <span ref={(el) => this._wrapper = el}><PassedComponent {...newProps} /></span>;
    }
  }

  FreezeWidth.propTypes = PassedComponent.propTypes;
  FreezeWidth.defaultProps = PassedComponent.defaultProps;

  return FreezeWidth;
};

export default freezeWidth;
