import { EditorState, Modifier } from 'draft-js';

export function insertTextAtCursor(editorState, text = ' ') {
  const selectionState = editorState.getSelection();
  const updatedContentState = Modifier.insertText(editorState.getCurrentContent(), selectionState, text, null, null);

  const offset = selectionState.getAnchorOffset() + text.length;
  const updatedSelection = selectionState.merge({
    anchorOffset: offset,
    focusOffset: offset,
  });

  return EditorState.forceSelection(
    EditorState.set(editorState, { currentContent: updatedContentState }),
    updatedSelection,
  );
}

export function insertMentionSymbolAtCursor(editorState) {
  const contentStateWithEntity = editorState.getCurrentContent().createEntity(
    'MENTION',
    'MUTABLE',
    {},
  );
  const insertionMethod = editorState.getSelection().isCollapsed() ? 'insertText' : 'replaceText';
  const contentStateWithMention = Modifier[insertionMethod](
    contentStateWithEntity,
    editorState.getSelection(),
    '@',
    null,
    contentStateWithEntity.getLastCreatedEntityKey(),
  );

  return EditorState.forceSelection(
    EditorState.set(editorState, { currentContent: contentStateWithMention }),
    editorState.getSelection().merge({
      anchorOffset: editorState.getSelection().getAnchorOffset() + 1,
      focusOffset: editorState.getSelection().getAnchorOffset() + 1,
    }),
  );
}
