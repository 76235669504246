import React from 'react';
import PropTypes from 'prop-types';

import typography from '../../../styles/global_ui/typography.css';

const ConfirmEmailMessage = ({ actionText }) => (
  <div className={typography.bodyM} style={{ maxWidth: 600 }}>
    {`To ${actionText}, you first need to confirm your email address by clicking the link in the email we sent you. Haven't received it? `}
    <a className={typography.linkBlue} href="/users/confirmation/new">
      Resend confirmation email
    </a>
  </div>
);

ConfirmEmailMessage.propTypes = { actionText: PropTypes.string };

ConfirmEmailMessage.defaultProps = { actionText: 'do this' };

export default ConfirmEmailMessage;
