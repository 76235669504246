import React, { Component } from 'react';
import PropTypes from 'prop-types';

import clickOutsideListener from '../../../utility/clickOutsideListener';

class ClickOutsideWrapper extends Component {
  constructor(props) {
    super(props);

    this._listener;
    this._wrapper = React.createRef();
  }

  componentDidMount() {
    if (this._wrapper) {
      // Gives the initial click event time to flush so the listener doesn't get bound too early and toggled.
      setTimeout(() => this._addListener(), 10);
    }
  }

  componentWillUnmount() {
    if (this._listener) this._listener.remove();
  }

  /**
   * Helpers
   */
  _addListener() {
    this._listener = clickOutsideListener(
      () => this._wrapper.current,
      (e) => this.props.onClickOutside(e),
      this.props.targetWillUnmount,
    );
  }

  render() {
    return (
      <div
        ref={this._wrapper}
        className={this.props.className}
        onClick={this.props.onClick}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

ClickOutsideWrapper.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onClickOutside: PropTypes.func,
  style: PropTypes.object,
  targetWillUnmount: PropTypes.bool,
};

ClickOutsideWrapper.defaultProps = {
  className: '',
  onClick: () => {},
  onClickOutside: () => {},
  style: null,
  targetWillUnmount: false,
};

export default ClickOutsideWrapper;
