import request from 'superagent';
import OAuth from '../../services/oauth';

// TODO: April 7 2021 - Only getComments remains in temp use by private_doorkeeper. Remove this file + the routes when GraphQL comments_for_project is added.
export function getComments(commentable, cacheVersion) {
  return OAuth.apiRequest(
    request(`${OAuth.getApiPath()}/private/projects/${commentable.id}/comments`)
      .query(cacheVersion && cacheVersion.length ? { v: cacheVersion } : {})
      .query({ only_path: true }),
  )
    .then((body) => Promise.resolve(body.records))
    .catch((err) => Promise.reject(err));
}
