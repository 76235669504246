import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DraftEditor from './draft_editor';
import PostEmbed from './post_embed';

import { getInObj } from '../../../utility/accessors';
import { isBlank } from '../../../utility/types';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './post_editor.css';

const CONFIG = {
  mentions: true,
  tags: true,
};

const DEFAULT_PLACEHOLDER = 'Share something cool or ask a question.';

class PostEditorBody extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { view: 'default' };
  }

  _isUnfocusedCreator() {
    return this.props.mode === 'default' && this.props.isEditorFocused === false;
  }

  _getBodyClassName() {
    if (this._isUnfocusedCreator()) return '';

    return `${styles.postBody} ${(this.state.view === 'markdown') ? styles.transparentBorder : utilStyles.border}`;
  }

  render() {
    const { deleteEmbed, embed, getEditorRef, markdownService, mode, post, propogateToolbar, scrapePageForLink } = this.props;

    return (
      <div className={`${layout.fullWidth} ${this._getBodyClassName()}`}>
        <DraftEditor
          ref={getEditorRef}
          classList={{
            root: '',
            markdownBtn: `${buttonStyles.cancel} ${typography.bodyS} ${styles.markdownBtn}`,
          }}
          config={CONFIG}
          handleEmbed={scrapePageForLink}
          initText={getInObj(['body'], post) || ''}
          markdownService={markdownService}
          mode={mode}
          onBlur={() => {}}
          onFocus={() => {}}
          placeholder={DEFAULT_PLACEHOLDER}
          propagateToolbar={propogateToolbar}
          propagateViewState={(view) => this.setState({ view })}
        />
        {!isBlank(embed)
        && (
          <PostEmbed
            embed={embed}
            killEmbed={deleteEmbed}
            mode="edit"
          />
        )}
      </div>
    );
  }
}

PostEditorBody.propTypes = {
  deleteEmbed: PropTypes.func.isRequired,
  embed: PropTypes.shape({}),
  getEditorRef: PropTypes.func.isRequired,
  isEditorFocused: PropTypes.bool.isRequired,
  markdownService: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['default', 'edit']),
  post: PropTypes.shape({ body: PropTypes.string }),
  propogateToolbar: PropTypes.func,
  scrapePageForLink: PropTypes.func.isRequired,
  value: PropTypes.string,
};

PostEditorBody.defaultProps = {
  embed: {},
  mode: 'default',
  post: null,
  propogateToolbar: () => {},
  value: '',
};

export default PostEditorBody;
