import { ContentState } from 'draft-js';
import { unescape } from 'validator';
import entifyContent from '../transactions/entifyContent';

// eslint-disable-next-line no-useless-escape
const MENTION_TOKEN_REGEX = /@[\[[a-zA-Z0-9_\-]+\](?=\()\((?=\{)\{.+?(?:\}\))/g;

function getUserNameFromToken(token) {
  return token.slice(0, 2) === '@[' ? extractStringFromBrackets(token) : token;
}

function extractStringFromBrackets(token) {
  let name = '@', start;
  // Faster with for loop since we only want the first section.
  for (let i = 0; i < token.length - 1; i++) {
    if (!start && token[i] === '[') {
      start = true;
      continue;
    } else if (start && token[i] !== ']') {
      name += token[i];
    } else if (token[i] === ']') {
      break;
    }
  }

  return name;
}

/**
 * @param  {[string]} text
 * @return {[ContentState]}
 */
export default function plaintextToDraft(text) {
  const contentState = ContentState.createFromText(unescape(text));

  return entifyContent(contentState, MENTION_TOKEN_REGEX, 'MENTION', getUserNameFromToken);
}
