import React from 'react';
import PropTypes from 'prop-types';

import EventCardTextPreview from './EventCardTextPreview';
import LazyBackgroundImage from '../../../../../client/image/LazyBackgroundImage';

import { getInObj } from '../../../../../utility/accessors';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import baseCardStyles from '../../../../../client/cards/base_card/horizontal/base_card_horizontal_lg.css';

const EventCardHorizontalLargePreview = ({ className, item }) => {
  const imageUrl = getInObj(['image', 'url'], item);

  return (
    <div className={`${baseCardStyles.card} ${layout.fullWidth} ${typography.breakWord} ${className}`}>
      <div className={`${baseCardStyles.image} ${utilStyles.posRelative} ${utilStyles.borderRadius} ${imageUrl ? '' : utilStyles.bgAsphalt}`}>
        {!!imageUrl
        && (
          <LazyBackgroundImage
            key={imageUrl}
            alt={item.title}
            classList={{ image: utilStyles.borderRadius }}
            ratio="3:2"
            setStyle={false}
            src={imageUrl} /* reInit LazyBackgroundImage when the source changes */
            width={204}
          />
        )}
      </div>
      <EventCardTextPreview
        className={baseCardStyles.text}
        item={item}
        size="L"
      />
    </div>
  );
};

EventCardHorizontalLargePreview.propTypes = {
  event: PropTypes.shape({
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    end_date: PropTypes.string,
    event_type: PropTypes.string,
    image: PropTypes.shape({ url: PropTypes.string }),
    location: PropTypes.shape({}),
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    start_date: PropTypes.string,
    state: PropTypes.string,
    title: PropTypes.string,
  }),
};

EventCardHorizontalLargePreview.defaultProps = {
  event: {
    custom_sponsors: [],
    end_date: null,
    event_type: null,
    image: null,
    location: null,
    platforms: [],
    start_date: null,
    state: null,
    title: null,
  },
};

export default EventCardHorizontalLargePreview;
