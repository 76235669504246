import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';
import typography from '../../../styles/global_ui/typography.css';

const MultiSelected = (props) => {
  const styles = props.styles;

  return (
    <Fragment>
      {props.selected.map((s) => (
        <div key={s.label} className={styles.multiOpt}>
          <span className={`${typography.bodyS} ${typography.bold} ${styles.multiText}`}>{s.label}</span>
          <span className={styles.multiOptXWrapper} onClick={() => props.onUnselect(s)}>
            <Icon className={styles.multiOptX} name="close" />
          </span>
        </div>
      ))}
    </Fragment>
  );
};

MultiSelected.propTypes = {
  onUnselect: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  styles: PropTypes.object.isRequired,
};

export default MultiSelected;
