import React from 'react';
import BasicFormInput from '../../form_components/inputs/basic_form_input';

import { maxLength } from '../../../services/validation/validators';
import layout from '../../../styles/global_ui/layout.css';

/**
 * Components
 */
const CPFField = (ctx) => (
  <div id="vfcpf">
    <BasicFormInput
      classList={{ root: layout.marginBottom15 }}
      errors={ctx.state.errors.cpf}
      label="Cpf"
      name="cpf"
      onChange={(e) => ctx._setStateForHiddenField('cpf', e.target.value)}
      value={ctx.state.hiddenInputsConfig.fields.cpf.value}
    />
  </div>
);

/**
 * ISO2 to config map
 * @param {Integer} order - Position of field in the form. Its used to scroll to the field on error.
 *
 * Each country may have hidden required fields like Brazil requires a "CPF" number. Add the configs below to
 * dynamically render them in once their country has been selected. The "order" argument is used to scroll to the field
 * when it has an error. Since we append hidden fields to the end of the form, the order argument is always the last position and needs
 * to be incremented for each additional field added. The order rendered is dictated by the array index in the components field.
 */
const COUNTRY_MAP = {
  BR: ({ order }) => ({
    components: [CPFField],
    fields: { cpf: { order, validate: (value) => maxLength(35, value), value: '' } },
    iso2: 'BR',
    name: 'Brazil',
  }),
};

/**
 * @param  {[object]} country - country-data object
 * @return {[object]} - component to render and field for state.fields
 */
const getHiddenFieldsConfig = (country) => {
  if (Object.hasOwn(COUNTRY_MAP, country.alpha2)) {
    return COUNTRY_MAP[country.alpha2];
  }

  return null;
};

export default getHiddenFieldsConfig;
