import React from 'react';

import styles from './section_list.css';
import homeStyles from '../home.css';

const CardLoader = () => (
  <li className={styles.row}>
    <span className={homeStyles.ghostDragBox}></span>
    <span className={`${homeStyles.ghostCell} ${styles.cell30}`}>
      <span className={homeStyles.greyNothing}></span>
    </span>

    <span className={`${homeStyles.ghostCell} ${styles.cell20}`}>
      <span className={homeStyles.greyNothing}></span>
    </span>

    <span className={`${homeStyles.ghostCell} ${styles.cell20}`}>
      <span className={homeStyles.greyNothing}></span>
    </span>

    <span className={`${homeStyles.ghostCell} ${styles.cell10}`}>
      <span className={homeStyles.greyNothing}></span>
    </span>

    <span className={`${homeStyles.ghostCell} ${styles.cell10}`}>
      <span className={homeStyles.greyNothing}></span>
    </span>
  </li>
);

const SectionListLoader = () => (
  <ul className={styles.list}>
    <CardLoader />
  </ul>
);

export default SectionListLoader;
