import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isImageValid} from '../utils/Helpers';

class Dropzone extends Component {
  constructor(props) {
    super(props);

    this.state = {dragActive: false};
    // Refs.
    this.input;
  }

  onDragOver(e) {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";

    this.setState({dragActive: true});
  }

  onDrop(e) {
    e.preventDefault();

    this.setState({dragActive: false});

    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

    if (this.props.onDrop) {
      this.props.onDrop(
        [].slice.call(files).filter(file =>  isImageValid(file.type))
      );
    }
  }

  render() {
    const {classList, multiple, styles} = this.props;

    return (
      <div
        style={styles}
        className={`${classList} ${this.state.dragActive ? 'active' : ''}`}
        onClick={() => this.input.click()}
        onDragLeave={() => this.setState({dragActive: false})}
        onDragOver={(e) => this.onDragOver(e)}
        onDrop={(e) => this.onDrop(e)}
        >
        <input
          ref={(el) => this.input = el}
          style={{display: 'none'}}
          onChange={(e) => this.onDrop(e)}
          multiple={multiple}
          type="file"
        />
        {this.props.children}
      </div>
    );
  }
}

Dropzone.propTypes = {
  classList: PropTypes.string,
  multiple: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
  styles: PropTypes.object
};

Dropzone.defaultProps = {
  classList: 'dropzone',
  multiple: false,
  styles: {}
};

export default Dropzone;