import { searchIndexWithFacets } from '../searchIndex';
import { appendFilterStringToQueryMap, mapifySearchQuery, stringifyQueryMap } from '../converters';

export default class BaseAlgoliaService {
  constructor(history = {}, index = '', queryBuilder) {
    this.algoliaIndex = index;
    this.history = history;
    this.mapifySearchQuery = mapifySearchQuery;
    this.queryBuilder = queryBuilder;
    this.queryHistory = { currentQuery: '', nextQuery: '' };
    this.stringifyQueryMap = stringifyQueryMap;
  }

  /**
   * Initializers
   */
  initializeFromUrl(initFacet, shouldPushToHistory) {
    return new Promise((resolve, reject) => this.mapifySearchQuery()
      .then((map) => this.buildAndStoreNextHistory(map))
      .then((map) => this.queryBuilder(map, initFacet))
      .then((params) => this.search(params, shouldPushToHistory))
      .then((algoliaRes) => resolve(algoliaRes))
      .catch((err) => reject(err)));
  }

  initializeFromUrlWithOpts(initFacet, opts, shouldPushToHistory) {
    return new Promise((resolve, reject) => this.mapifySearchQuery()
      .then((map) => this.buildAndStoreNextHistory({ ...opts, ...map }))
      .then((map) => this.queryBuilder(map, initFacet))
      .then((params) => this.search(params, shouldPushToHistory))
      .then((algoliaRes) => resolve(algoliaRes))
      .catch((err) => reject(err)));
  }

  /**
   * History
   */
  buildAndStoreNextHistory(queryMap) {
    return new Promise((resolve, reject) => this.stringifyQueryMap(queryMap)
      .then((queryString) => {
        this.queryHistory.nextQuery = queryString;
        resolve(queryMap);
      })
      .catch((err) => reject(err)));
  }

  setCurrentHistoryAndClearNext(algoliaRes, shouldPushToHistory = true) {
    if (this.history.location.search !== this.queryHistory.nextQuery) {
      this.queryHistory.currentQuery = this.queryHistory.nextQuery;
      this.queryHistory.nextQuery = '';

      if (shouldPushToHistory) this.history.push({ search: this.queryHistory.currentQuery });
    }

    return Promise.resolve(algoliaRes);
  }

  /**
   * Search
   */
  search({ facets = '*', facetFilters = [], params = {}, query = '', sort = null } = {}, shouldPushToHistory = true) {
    // Alogolia pages start at a 0 index.  We want to display 1 for page 0 for pretty urls and paginator.
    if (params && params.page) {
      params = { ...params, page: Math.max(((parseInt(params.page) || 0) - 1), 0).toString() };
    }

    return new Promise((resolve, reject) => searchIndexWithFacets({ index: this.algoliaIndex, facets, facetFilters, params, query, sort })
      .then((algoliaRes) => this.setCurrentHistoryAndClearNext(algoliaRes, shouldPushToHistory))
      .then((algoliaRes) => resolve(algoliaRes))
      .catch((err) => reject(err)));
  }

  /**
   * Usually for a filter's onSelect callback.  Mutates history state.
   */
  searchWithFilterString(filterString, initFacet, opts = {}) {
    return new Promise((resolve, reject) => this.mapifySearchQuery()
      .then((map) => appendFilterStringToQueryMap({ ...opts, ...map }, filterString))
      .then((updatedMap) => this.buildAndStoreNextHistory(updatedMap))
      .then((updatedMap) => this.queryBuilder(updatedMap, initFacet))
      .then((params) => this.search(params))
      .then((algoliaRes) => resolve(algoliaRes))
      .catch((err) => reject(err)));
  }

  /**
   * When we don't want to update the url via history state.
   */
  searchWithNoEffects(queryMap, initFacet) {
    return new Promise((resolve, reject) => this.queryBuilder(queryMap, initFacet)
      .then((params) => searchIndexWithFacets({ index: this.algoliaIndex, ...params }))
      .then((algoliaRes) => resolve(algoliaRes))
      .catch((err) => reject(err)));
  }
}
