import request from 'superagent';
import UAParser from 'ua-parser-js';
import OAuth from '../oauth';
import { getInObj } from '../../utility/accessors';

const uaParser = new UAParser();

const createDebugTableRecord = (data, collectionName, err) => {
  const blacklist = ['distinct_id', 'ip', 'keen', 'referrer', 'user_id', 'whitelabel'];
  const other_props = Object.keys(data).reduce((acc, key) => {
    if (!blacklist.includes(key)) {
      acc[key] = data[key];
    }

    return acc;
  }, {});

  const errorHasContent = typeof err.status !== 'undefined';
  const error = {
    status: errorHasContent ? err.status : 0,
    reason: errorHasContent ? getInObj(['response', 'body', 'message'], err) : 'net::ERR_BLOCKED_BY_CLIENT',
  };

  const browser_info = {
    ...uaParser.getResult(),
    referrer: data.referrer,
    error,
  };

  return {
    browser_info,
    other_props,
    distinct_id: data.distinct_id,
    table: collectionName,
    user_id: data.user_id,
    whitelabel: data.whitelabel,
  };
};

const postToDebugWhenKeenFailed = (record) => OAuth.apiRequest(
  request
    .post(`${OAuth.getApiPath()}/private/debug`)
    .send(record),
  true,
  false,
  false,
);

const postToOhm = (data, collectionName) => new Promise((resolve, reject) => request
  .post(`https://${window.jsk.oht}/v1/events/${collectionName}`)
  .query({ key: window.jsk.oak })
  .set('Content-Type', 'application/json')
  .send(data)
  .end((err, res) => resolve({ err, res })));

export function postSingleEvent(data, collectionName) {
  return new Promise((resolve, reject) => postToOhm(data, collectionName)
    .then(({ err, res }) => err ? postToDebugWhenKeenFailed(createDebugTableRecord(data, collectionName, err)) : Promise.resolve(res))
    .then((res) => resolve(res))
    .catch((err) => reject(err)));
}
