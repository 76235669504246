import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import AdminUserModerationRow from './Row';

import { graphMutate } from '../../../requests/graphql';
import { UserType, FilterType } from './types';

import layout from '../../../styles/global_ui/layout.css';
import inputStyles from '../../../styles/global_ui/inputs.css';
import buttons from '../../../styles/global_ui/buttons.css';
import style from './style.css';

export const c = {
  container: style.container,
  filterForm: `${style.filterForm} ${layout.flexCenterCenter} ${layout.gutter10}`,
  tableContainer: `${layout.flexColumn} ${layout.gutter10}`,
  actions: `${layout.flex} ${layout.gutter5} ${style.actions}`,
  pageHeader: `${style.pageHeader}`,
};

const AdminUserModeration = ({ userRanks: defaultUserRanks, filters }) => {
  const ref = useRef();
  const [userRanks, setUserRanks] = useState(defaultUserRanks);

  const handleSelectAll = (checked) => {
    if (!ref.current) return;
    const form = ref.current;

    form.querySelectorAll('input:not([disabled])').forEach((input) => {
      input.checked = checked;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target, e.nativeEvent.submitter);
    const updates = Array.from(formData).map(([id, del]) => ({ id: Number(id), delete: del === 'delete' }));

    const confirmed = window.confirm(`Are you sure you want to delete ${updates.length} spam users? This will delete all of their data and cannot be undone.`);

    if (!confirmed) return;

    await graphMutate(`
      mutation ($updates: [UserUpdate!]!) {
        updateUser(updates: $updates) { ids }
      }
      `,
    { updates },
    );

    setUserRanks(userRanks.map((ur) => updates.find(({ id }) => id === ur.id) ? { ...ur, is_deleted_spam: true } : ur));
  };

  return (
    <div>
      <div className={c.pageHeader}>
        <h1>User Review Queue</h1>
        <form className={c.filterForm} method="GET">
          <div className={style.inlineCell}>
            <input
              defaultChecked={filters.with_roles === 'suspected_bot'}
              id="suspected_bot"
              name="with_roles"
              type="checkbox"
              value="suspected_bot"
            />
            <label className={`${inputStyles.label}`} htmlFor="suspected_bot">Flagged users</label>
          </div>

          <input className={`${inputStyles.input} ${inputStyles.sm}`} data-1p-ignore defaultValue={filters.user_id} name="user_id" placeholder="User ID" />
          <div className={style.inlineCell}>
            <input
              className={`${inputStyles.input} ${inputStyles.sm}`}
              defaultValue={filters.start_date}
              max="9999-12-31"
              name="start_date"
              type="date"
            />
            <span>-</span>
            <input
              className={`${inputStyles.input} ${inputStyles.sm}`}
              defaultValue={filters.end_date}
              max="9999-12-31"
              name="end_date"
              type="date"
            />
          </div>
          <button className={buttons.sm}>Filter</button>
        </form>
      </div>

      <form ref={ref} className={c.tableContainer} onSubmit={handleSubmit}>
        <div className={c.actions}>
          <button className={buttons.sm} onClick={() => handleSelectAll(true)} type="button">Select all</button>
          <button className={buttons.sm} onClick={() => handleSelectAll(false)} type="button">Deselect all</button>
          <button className={`${layout.marginLeftAuto} ${buttons.sm} ${buttons.danger}`}>Delete selected spam</button>
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>Username</th>
              <th>Created</th>
              <th>Email</th>
              <th>Website</th>
              <th>Bio</th>
              <th>Rank</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userRanks.map((userRank) => (
              <AdminUserModerationRow key={userRank.id} userRank={userRank} />
            ))}
          </tbody>
        </table>
      </form>
    </div>
  );
};

AdminUserModeration.propTypes = {
  filters: FilterType,
  userRanks: PropTypes.arrayOf(PropTypes.shape(UserType)),
};

export default AdminUserModeration;
