import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ActionableButton from '../../../../client/buttons/actionable';
import Dialog from '../../../../client/reusable_components/Dialog';
import DatetimeLocalInput from '../../../../client/form_components/inputs/datetime/datetime_local';

import { getErrorHelperView } from '../../../../client/form_components/templates';
import { datetimeLocalValueToUTCTimestamp } from '../../../../utility/time';
import { isDatetimeLocalValueValid, isDatetimeLocalValueInFuture } from '../../../../services/validation/validators';
import { initFields, setStateOrError, validateFields } from '../../../../utility/forms';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './news_admin_templates.css';

const FIELDS_TEMPLATE = { datetime: { validate: isDatetimeLocalValueInFuture, customRequired: isDatetimeLocalValueValid, value: '' } };
const SCHEDULER_HELPER_TXT = 'The date-time selected will be scheduled in your local timezone.';

class PublishDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      fields: initFields(FIELDS_TEMPLATE, {}),
    };

    this.onScheduleClick = this.onScheduleClick.bind(this);

    // Form helpers
    this.setStateOrError = setStateOrError.bind(this);
    this.validate = validateFields.bind(this);
  }

  /**
   * Methods
   */
  onScheduleClick() {
    const validated = this.validate();
    if (!validated) return;

    this.props.onPublish(datetimeLocalValueToUTCTimestamp(this.state.fields.datetime.value));
  }

  /**
   * Views
   */
  render() {
    return (
      <Dialog
        className={layout.flexJustifyCenter}
        dismiss={this.props.dismiss}
        open={this.props.open}
        wrapperClassName={styles.dialogWrapper}
      >
        <div className={styles.dialogBody}>
          <div className={styles.box}>
            <p className={`${typography.bodyM} ${typography.bold} ${layout.marginBottom10}`}>Publish now</p>
            <p className={`${typography.bodyM} ${typography.textCenter} ${layout.marginBottom10}`}>The article will be immediately available to read.</p>
            <ActionableButton isBusy={this.props.isBusy} onClick={() => this.props.onPublish()} text="Publish" type="fullWidth" />
          </div>

          <div className={`${styles.box} ${styles.boxBorder}`}>
            <p className={`${typography.bodyM} ${typography.bold} ${layout.marginBottom10}`}>Schedule for later</p>
            <div className={layout.marginBottom10}>
              <DatetimeLocalInput autoComplete="off" onChange={(value) => this.setStateOrError(null, 'datetime', value)} />
              {getErrorHelperView(this.state.errors.datetime, SCHEDULER_HELPER_TXT)}
            </div>
            <ActionableButton isBusy={this.props.isBusy} onClick={this.onScheduleClick} text="Schedule" type="fullWidth" />
          </div>
        </div>
      </Dialog>
    );
  }
}

PublishDialog.propTypes = {
  dismiss: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  onPublish: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PublishDialog;
