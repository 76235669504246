import clearApiTokensIfUserSignedOut from './clearApiTokensIfUserSignedOut';
import initCurrentUser from './initCurrentUser';
import initSentry from './initSentry';

// Order of initialization can be critical.
(function criticalLoadInit() {
  clearApiTokensIfUserSignedOut();
  initCurrentUser();
  initSentry();
}());
