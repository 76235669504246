import { RANKED_DEFAULT } from './constants';
import { isRequired, isUrlIfDefined, isValidLeaderboardUrl, maxLength, minValue } from '../../../../../../services/validation/validators';
import { getIdOrUuid } from '../../../../../../utility/accessors';

const PRIZE_ATTR_MAP = {
  name: [isRequired, maxLength.bind(null, 255)],
  link: [isUrlIfDefined, maxLength.bind(null, 255)],
  quantity: [minValue.bind(null, 1)],
};

const PRIZE_ATTR_KEYS = Object.keys(PRIZE_ATTR_MAP);

/**
 * @returns:
 * {
 *   [category_id]: {
 *     [name]: msg,
 *     [prizes]: validatePrizes
 *   }
 * }
 */
const validateCategory = (category) => {
  const catNameErr = category.type === RANKED_DEFAULT ? null : (isRequired(category.name) || maxLength(255, category.name));
  const name = catNameErr ? { name: catNameErr } : {};

  const leaderboardErr = isValidLeaderboardUrl(category.leaderboard_url);
  const leaderboard = leaderboardErr ? { leaderboard_url: leaderboardErr } : { };

  const leaderboardDateErr = category.leaderboard_url ? !category.leaderboard_live_date : null;
  const leaderboardDate = leaderboardDateErr ? { leaderboard_live_date: 'If you add a leader, you must select a date for the it to go live.' } : {};

  const prizes = validatePrizes(category.prizes);
  const allErrors = { ...name, ...prizes, ...leaderboard, ...leaderboardDate };

  return Object.keys(allErrors).length > 0
    ? { [getIdOrUuid(category)]: allErrors }
    : {};
};

/**
 * @returns:
 *  {
 *    [name]: msg,
 *    [link]: msg
 *  }
 */
const validatePrize = (prize) => PRIZE_ATTR_KEYS.reduce((acc, attr) => {
  const msg = PRIZE_ATTR_MAP[attr].reduce((acc, fn) => {
    if (acc !== null) return acc;
    const val = fn(prize[attr]);

    return val ? val : acc;
  }, null);

  if (msg) acc[attr] = msg;

  return acc;
}, {});

/**
 * @returns:
 * {
 *   prizes: {
 *     [prize_id]: validatePrize
 *     [prize_uuid]: {...}
 *   }
 * }
 */
const validatePrizes = (prizes) => {
  const prizesErrors = prizes.reduce((acc, prize) => {
    const prizeErrors = validatePrize(prize);
    if (Object.keys(prizeErrors).length > 0) acc[getIdOrUuid(prize)] = prizeErrors;

    return acc;
  }, {});

  return Object.keys(prizesErrors).length > 0 ? { prizes: prizesErrors } : {};
};

export default function validations(categories) {
  const errors = categories.reduce((acc, cat) => {
    const catErrors = validateCategory(cat);

    return (Object.keys(catErrors).length > 0) ? { ...acc, ...catErrors } : acc;
  }, {});
  const hasErrors = Object.keys(errors).length > 0;

  return { errors, hasErrors };
}
