import CurrentUserService from '../../services/current_user';
import { doesBodyHaveUserId } from '../../services/window';
import errorHandler from '../../services/error_handler';

function fetchCurrentUser() {
  return CurrentUserService.getStoreAsync()
    .catch((err) => errorHandler('initCurrentUser fetchCurrentUser: ', err));
}

export default function initCurrentUser() {
  if (doesBodyHaveUserId()) {
    fetchCurrentUser();
  }
}
