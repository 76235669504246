"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useSkipFirstRender = ({ callback, dependencies }) => {
    const firstRenderRef = (0, react_1.useRef)(true);
    (0, react_1.useEffect)(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        }
        else {
            callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback, ...dependencies]);
};
exports.default = useSkipFirstRender;
