import React from 'react';
import PropTypes from 'prop-types';

import { aspectRatioPadding } from '../../../utility/math';

import utilStyles from '../../../styles/global_ui/util.css';

const ResponsiveIframe = ({ allowFullScreen, aspectRatio, className, src }) => (
  <div
    className={`${utilStyles.absolutePlaceholderParent} ${className}`}
    style={{ paddingBottom: aspectRatioPadding({ aspectRatio }) }}
  >
    <iframe
      allowFullScreen={allowFullScreen}
      className={`${utilStyles.absolutePlaceholderChild} ${utilStyles.overflowHidden}`}
      frameBorder={0}
      height="100%"
      src={src}
      width="100%"
    />
  </div>
);

ResponsiveIframe.propTypes = {
  allowFullScreen: PropTypes.bool,
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
  src: PropTypes.string,
};

ResponsiveIframe.defaultProps = {
  allowFullScreen: true,
  aspectRatio: (16 / 9),
  className: '',
  src: '',
};

export default ResponsiveIframe;
