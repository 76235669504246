import React from 'react';
import PropTypes from 'prop-types';

import animation from '../../../../styles/animation.css';
import styles from './news_admin_list.css';

const NewsArticleListItemDummy = ({ isAdminOrEditor }) => (
  <div className={styles.listItem}>
    <div className={styles.listItemTitleCell}>
      <div className={styles.listItemCoverImageWrapper}>
        <div className={`${styles.listItemCoverImage} ${animation.loader}`} style={{ borderRadius: 2 }}></div>
      </div>

      <div className={styles.listItemData} style={{ width: '100%' }}>
        <div className={animation.loader} style={{ height: 18, marginBottom: 5, width: '100%' }} />

        <div className={styles.listItemMobileAuthorStatus}>
          {isAdminOrEditor && <div className={animation.loader} style={{ height: 16, marginBottom: 5, width: 100 }} />}

          <div className={animation.loader} style={{ height: 16, width: 100 }} />
        </div>
      </div>
    </div>

    {isAdminOrEditor && <div className={`${styles.listItemAuthorCell} ${animation.loader}`} style={{ height: 18, width: 200 }} />}

    <div className={styles.listItemDateCell}>
      <div className={animation.loader} style={{ height: 18, width: isAdminOrEditor ? 100 : 200 }} />
    </div>
  </div>
);

const NewsArticleListLoader = ({ isAdminOrEditor }) => (
  <div>
    {[...Array(6).keys()].map((n) => (<NewsArticleListItemDummy key={n} isAdminOrEditor={isAdminOrEditor} />))}
  </div>
);

NewsArticleListLoader.propTypes = { isAdminOrEditor: PropTypes.bool.isRequired };

export default NewsArticleListLoader;
