import React from 'react';
import PropTypes from 'prop-types';

import SimpleSelect from '../../../client/form_components/simple_select';

import layout from '../../../styles/global_ui/layout.css';

const doesLocactionSearchHaveCurrentUser = (currentHistoryData) => currentHistoryData.search.includes('by_current_user=true');

const getOpts = (currentHistoryData) => [
  { active: !doesLocactionSearchHaveCurrentUser(currentHistoryData), label: 'All posts', value: '' },
  { active: doesLocactionSearchHaveCurrentUser(currentHistoryData), label: 'My activity', value: 'by_current_user=true' },
];

const NavBar = ({ currentHistoryData, onClick }) => (
  <div className={`${layout.fullWidth} ${layout.marginBottom10}`}>
    <SimpleSelect
      onSelection={onClick}
      options={getOpts(currentHistoryData)}
      view="tabbed"
    />
  </div>
);

NavBar.propTypes = {
  currentHistoryData: PropTypes.shape({ search: PropTypes.string }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NavBar;
