import React from 'react';
import PropTypes from 'prop-types';

import ContentCard from './content_list/ContentCard';
import DragAndDropList from '../../../../client/reusable_components/DnDKit/DragAndDropList';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';

import sectionListStyles from '../section_list/section_list.css';
import styles from '../home_sections.css';

const noContentMsg = () => (
  <div className={sectionListStyles.row}>
    <span className={sectionListStyles.noContentMsg}>No content items</span>
  </div>
);

const PromotedContentTableView = (props) => (
  <div>
    <header className={styles.header}>
      <div>
        <button
          className={buttonStyles.lg}
          onClick={() => props.openDialog()}
          type="button"
        >
          Add new item
        </button>
      </div>
    </header>

    <div className={styles.tableContainer}>
      <div className={layout.flex}>
        <div className={`${styles.ghostDragBox} ${utilStyles.bgFog}`} />
        <div className={`${styles.headerCellName} ${sectionListStyles.cell60}`}>Title</div>
        <div className={`${styles.headerCell} ${sectionListStyles.cell20}`}>Type</div>
        <div className={`${styles.headerCell} ${sectionListStyles.cell20}`}>Action</div>
      </div>
      {(props.content && props.content.length > 0)
        ? (
          <DragAndDropList
            ItemComponent={ContentCard}
            dragEndCallback={props.updateSectionsOrder}
            hasDragHandle={true}
            itemProps={{
              removeItem: props.removeSection,
              updateItem: props.updateSection,
            }}
            items={props.content.map((c) => ({ ...c, id: c.title }))}
          />
          )
        : noContentMsg()}
    </div>
  </div>
);

PromotedContentTableView.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openDialog: PropTypes.func.isRequired,
  removeSection: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
  updateSectionsOrder: PropTypes.func.isRequired,
};

PromotedContentTableView.defaultProps = {};

export default PromotedContentTableView;
