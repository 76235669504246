/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { getInObj } from '../accessors';
import smoothScroll from '../../client/utils/smoothScroll';
import { resolveInputType } from '../../graphql/challenges/enums';
import { isRequired } from '../../services/validation/validators';

/*********************
 * Helpers
 *********************/

/**
 * Creates a template object for custom fields.
 * @param  {array}  customFields       Fields props from server.
 *   customFields = [
 *     { id: <int>, label: <string>, required: <bool> }
 *   ]
 * @param  {string} key:               Fields property and name.
 * @param  {int}    insertOptsAtIndex: Index to insert the customFields into the base template by its order property.
 * @param  {object} customProps:       Map of custom fields props.
 * @return {object}                    Map of field configs.
 */
const buildOptionalFields = (customFields, key = 'x_field', insertOptsAtIndex = 0, customProps = null) => customFields.reduce((acc, o, i) => {
  const { validation, ...restCustomProps } = getCustomPropsForOptionalField(o.id, customProps);
  const isRequired = o.hasOwnProperty('required') && o.required;
  const label = o.label || '';
  const name = `${key}_${o.id}`;

  acc[name] = {
    id: o.id,
    label: isRequired ? label : `${label} (optional)`.trim(),
    notRequired: isRequired === false,
    order: i + insertOptsAtIndex,
    validate: validation,
    value: '',
    name,
    type: resolveInputType(o.input_type?.toUpperCase()),
    ...restCustomProps,
  };

  return acc;
}, {});

/**
 * Merge two template objects together and updates each fields order property depending on where
 * the custom fields are placed within the original templates positions.
 *
 * @param  {object} template  Forms base template object.
 * @param  {object} optionals Custom fields converted into a template object. Usually via buildOptionalFields.
 * @return {object}           Template for a form.
 */
const combineAndReorderTemplateWithOptionals = (template, optionals) => {
  if (!Object.keys(optionals).length) return template;

  const keys = Object.keys(template).sort((a, b) => template[a].order - template[b].order);
  const optKeys = Object.keys(optionals).sort((a, b) => optionals[a].order - optionals[b].order);
  const orderedKeys = keys.slice(0, optionals[optKeys[0]].order).concat(optKeys).concat(keys.slice(optionals[optKeys[0]].order));

  return orderedKeys.reduce((acc, key, i) => {
    const f = template.hasOwnProperty(key) ? template[key] : optionals[key];
    f.order = i;
    acc[key] = f;

    return acc;
  }, {});
};

/**
 * Builds a customProps object to spread into a field object.
 * Creates the default validation function if none is defined.
 *
 * @param  {int|string} id       Field id.
 * @param  {object} customProps  Map of field id to custom properties.
 * @return {object}
 */
const getCustomPropsForOptionalField = (id, customProps) => {
  const noop = () => null;

  if (!customProps) {
    return { validation: noop };
  }

  const validationFn = getInObj([id, 'validation'], customProps);
  const validation = validationFn !== null && typeof validationFn === 'function' ? validationFn : noop;
  const restCustomProps = customProps.hasOwnProperty(id) ? customProps[id] : {};

  return {
    validation,
    ...restCustomProps,
  };
};

const getValidationFns = (key, field, isRequiredFn, validationOverrideMap) => {
  if (validationOverrideMap.hasOwnProperty(key)) {
    return validationOverrideMap[key].hasOwnProperty('notRequired') && validationOverrideMap[key].notRequired === true
      ? [validationOverrideMap[key].validate]
      : [isRequiredFn, validationOverrideMap[key].validate];
  }

  return field.notRequired ? [field.validate] : [isRequiredFn, field.validate];
};

/**
 * Scrolls to the first error on the form dictated by the fields order property.
 * Make sure the fields in the React view have a wrapping div with an id that matches the `vf${key}` format.
 * Make sure to not call this fn in the constructor due to the document and window properties here. SSR protection.
 *
 * @param {object} errors -      Map of field keys to error messages.
 * @param {object} fields -      Map of field configs. Expects fields to have an order property.
 * @param {DOM node} container - Container in which the form is nested. (instance.__CONTAINER_EL, Defaults to window).
 * @param {string} idPrefix -    Id prefix for each fields wrapper element.
 */
export function scrollToError(errors, fields, container = null, idPrefix = 'vf') {
  const el = Object.keys(fields)
    .sort((a, b) => fields[a].order - fields[b].order)
    .reduce((acc, key) => {
      if (acc !== null) return acc;

      return errors.hasOwnProperty(key) ? document.getElementById(`${idPrefix}${key}`) : acc;
    }, null);

  if (el) {
    const scrollContainer = container || window;
    smoothScroll(el, 200, null, scrollContainer);
  }
}

/*********************************
 * Stateless functions
 *********************************/

/**
 * Creates a fields object from a template and formats the value properties from a init record.
 * Expects the template key to match the initData key.
 *
 * @param  {object} template - Map of field configs. preferred key order: order, validate, value, formatIn, formatOut, (notRequired|customRequired)
 * @param  {object} initData - Previously saved record.
 * @return {object} -          Fields state object.
 */
export function initFields(template, initData) {
  if (!initData) return template;

  return Object.keys(template).reduce((acc, key) => {
    if (initData.hasOwnProperty(key) && initData[key] !== null) {
      acc[key] = {
        ...template[key],
        value: template[key].hasOwnProperty('formatIn') ? template[key].formatIn(initData[key]) : initData[key],
      };
    } else {
      acc[key] = template[key];
    }

    return acc;
  }, {});
}

/**
 * Useful when a form needs to merge two templates together conditionally and reorder its fields.
 *
 * @param  {object} template - Map of field configs.
 * @param  {object} template2 - Map of field configs.
 * @param  {object} initData - Previously saved record.
 * @return {object} -          Fields state object.
 */
export function initFieldsForOrderableTemplates(template, template2, initData) {
  return initFields(
    combineAndReorderTemplateWithOptionals(
      template,
      template2,
    ),
    initData,
  );
}

/**
 * Creates a fields object when we optionally need to merge custom fields onto a forms template.
 *
 * @param  {object} template     Map of input configs.
 * @param  {object} initData     Previously saved record.
 * @param  {array}  customFields Array of fields from the server. [{id, label, required}]
 * @param  {object} config       Props for the the custom fields.
 *   config = {
 *     key[string]:              Used to create the property name of the field.
 *     insertOptsAtIndex[int]:   Where to insert the custom fields by the fields order prop.
 *     customProps[object]:      Map of any additional properties to add to a field. (validation, formatIn, formatOut)
 *                               {customFieldId: {validation: function, formatIn: function, ...etc}}
 *   }
 * @return {object}              Fields state object.
 */
export function initFieldsWithOptionalFields(template, initData, customFields = [], config = {}) {
  // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
  // eslint-disable-next-line @stylistic/no-mixed-operators
  if (!customFields || customFields && !customFields.length) return initFields(template, initData);

  const key = config.key || 'x_field';
  const insertOptsAtIndex = config.insertOptsAtIndex || 0;
  const customProps = config.customProps || null;

  return initFields(
    combineAndReorderTemplateWithOptionals(
      template,
      buildOptionalFields(customFields, key, insertOptsAtIndex, customProps),
    ),
    initData,
  );
}

/*********************************************
 * Stateful functions
 * To be bound to a React instance
 *********************************************/

/**
  * Used to check if all required fields have data. Useful for disabling the submit button.
  * @return {boolean}
  */
export function disableForRequiredFields() {
  const keys = Object.keys(this.state.fields);

  return keys.reduce((acc, key) => {
    if (acc) return acc;
    const field = this.state.fields[key];
    const error = field.hasOwnProperty('notRequired') ? null : isRequired(this.state.fields[key].value);

    return error !== null && error.length > 0;
  }, false);
}

export function getErrorForField(field = '') {
  return this.state.errors.hasOwnProperty(field) ? this.state.errors[field] : null;
}

export function getFieldValuesAsObject(fieldsOverride = null) {
  const fields = fieldsOverride || this.state.fields;
  const keys = Object.keys(fields);

  return keys.reduce((acc, key) => {
    const field = fields[key];

    if (field.hasOwnProperty('formatOut')) {
      acc = { ...acc, [key]: field.formatOut(field.value, fields) };
    } else {
      acc[key] = field.value;
    }

    return acc;
  }, {});
}

/**
 * When a input has a propagateStatus callback, like ImageUploader, we can use this method to toggle
 * the isBusy status by a worker type. Be sure to add a workers property in state, which is an array.
 * This is useful if alot of async ops can happen at the same time and we need to make sure all are flushed
 * before allowing the form to be submitted.
 *
 * @param {bool}   status
 * @param {string} worker - 'name of operation'
 */
export function setIsBusy(status, worker) {
  if (status && this.state.workers.includes(worker)) return;

  const workers = status ? this.state.workers.concat(worker) : this.state.workers.filter((w) => w !== worker);
  this.setState({
    isBusy: workers.length > 0,
    workers,
  });
}

/**
 * Sets or clears state.errors, updates a fields value. This should be used a fields onChange callback.
 * The errorMsg here is for on the fly errors like character counts and such.
 *
 * @param {string} errorMsg
 * @param {string} key - Field's property name.
 * @param {variable} value - Field's value.
 * @param {object} stateSpread - Additional state to spread in.
 */
export function setStateOrError(errorMsg, key, value, stateSpread = {}) {
  if (errorMsg === null) {
    const { [key]: deleted, ...errors } = this.state.errors; // eslint-disable-line @typescript-eslint/no-unused-vars
    this.setState({
      errors,
      fields: { ...this.state.fields, [key]: { ...this.state.fields[key], value: value } },
      ...stateSpread,
    });
  } else {
    this.setState({
      errors: { ...this.state.errors, [key]: errorMsg },
      fields: { ...this.state.fields, [key]: { ...this.state.fields[key], value: value } },
      ...stateSpread,
    });
  }
}

/**
 * Runs all the field values through validation functions.
 * Every field isRequired by default unless notRequired is a property on the field.
 * isRequired may be overridden by a customRequired property.
 *
 * @param {object} validationOverrideMap - Secondary validations when we need stricter ruleset (i.e. Submission vs Draft).
 *   If validation overrides are present, we assume that the field isRequired and it will ignore the notRequired flag.
 *
 * @return {boolean} - Returns truthy when there are no errors.
 */
export function validateFields({ doScroll = true, validationOverrideMap = {} } = {}) {
  const fields = this.state.fields;
  const keys = Object.keys(fields);

  const errors = keys.reduce((acc, key) => {
    const field = fields[key];
    const isRequiredFn = field.hasOwnProperty('customRequired') ? field.customRequired : isRequired;
    const validations = getValidationFns(key, field, isRequiredFn, validationOverrideMap);

    const error = validations.reduce((a, fn) => {
      if (a !== null) return a;

      return fn(field.value);
    }, null);

    if (error && error.length) {
      acc[key] = error;
    }

    return acc;
  }, {});

  if (Object.keys(errors).length) {
    const containerEl = this.hasOwnProperty('__CONTAINER_EL') ? this.__CONTAINER_EL : null;

    const scrollFn = doScroll
      ? () => scrollToError(this.state.errors, this.state.fields, containerEl, (this._idPrefix || 'vf'))
      : () => {};

    this.setState({ errors: { ...this.state.errors, ...errors } }, scrollFn);

    return false;
  }

  return true;
}
