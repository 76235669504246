import React from 'react';
import PropTypes from 'prop-types';

import Filters from './Filters';
import Paginator from '../../nav_components/paginator';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import styles from './paginated_list.css';

const shouldShowDefaultPaginator = ({ currentPage, totalPages }) => (
  (totalPages > 1) && (currentPage <= totalPages)
);

const shouldShowSimplePaginator = ({ nextPage, prevPage }) => !!prevPage || !!nextPage;

const shouldShowPaginator = (paginator) => (
  shouldShowDefaultPaginator(paginator) || shouldShowSimplePaginator(paginator)
);

const getPaginatorViewForType = (props) => props.type === 'paginator' ? getPaginatorView(props) : getButtonView(props);

const getButtonView = ({ buttonText, disabled, onPaginatorClick, paginator }) => {
  if (!paginator.nextPage) return null;

  return (
    <div className={`${layout.flexJustifyCenter} ${layout.fullWidth} ${layout.marginTop45}`}>
      <button className={buttonStyles.md} disabled={disabled} onClick={onPaginatorClick}>
        {buttonText}
      </button>
    </div>
  );
};

const getPaginatorView = ({ currentQuery, onPaginatorClick, paginator }) => (
  shouldShowPaginator(paginator)
  && (
    <Paginator
      classList={{ root: styles.paginatorRoot }}
      currentQuery={currentQuery}
      onClick={onPaginatorClick}
      {...paginator}
    />
  )
);

const getFilterView = ({ filters, onFilterSelect, renderFilters }) => {
  if (!filters) return null;
  if (typeof renderFilters === 'function') return renderFilters(filters, onFilterSelect);

  return (<Filters filters={filters} onSelection={onFilterSelect} />);
};

const PaginatedList = (props) => (
  <div>
    {getFilterView(props)}
    {props.children}
    {getPaginatorViewForType(props)}
  </div>
);

PaginatedList.propTypes = {
  buttonText: PropTypes.string,
  currentQuery: PropTypes.string,
  disabled: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  onFilterSelect: PropTypes.func,
  onPaginatorClick: PropTypes.func.isRequired,
  paginator: PropTypes.shape({
    currentPage: PropTypes.number,
    nextPage: PropTypes.number,
    prevPage: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  renderFilters: PropTypes.func,
  type: PropTypes.oneOf(['button', 'paginator']),
};

PaginatedList.defaultProps = {
  buttonText: '', // for button view
  currentQuery: '',
  disabled: false,
  filters: null,
  onFilterSelect: () => {},
  renderFilters: null,
  type: 'paginator',
};

export default PaginatedList;
