import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AddOrEditVideoView from '../../videos/AddOrEditVideoView';
import BasicFormInput from '../../../../client/form_components/inputs/basic_form_input';
import Dialog from '../../../../client/reusable_components/Dialog';
import DragAndDropList from '../../../../client/reusable_components/DnDKit/DragAndDropList';
import PreviewView from './PreviewView';
import SectionCard from './selected_videos_table/SectionCard';
import VideosTableView from './VideosTableView';

import AlgoliaPartsService from '../../../../services/algolia/parts_service';
import AlgoliaPlatformsService from '../../../../services/algolia/platforms_service';
import AlgoliaTagsService from '../../../../services/algolia/tags_service';
import AlgoliaTopicsService from '../../../../services/algolia/topics_service';

import errorHandler from '../../../../services/error_handler';
import { timestampToPrettyDate } from '../../../../utility/time';
import { windowScrollTo } from '../../../../services/window';

import { graphQuery } from '../../../../requests/graphql';
import { getSortEnum } from '../../../../graphql/videos/enums';
import smoothScroll from '../../../../client/utils/smoothScroll';

import formStyles from '../../../../styles/global_ui/forms.css';
import layout from '../../../../styles/global_ui/layout.css';
import tableStyles from '../../../../styles/global_ui/table.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import videosStyles from '../../videos/edit_videos.css';

const HEADERS = [
  { cell: 'title', title: 'title', orderable: true, sort: 'title' },
  { cell: 'category', title: 'category', orderable: false, sort: null },
  { cell: 'created_at', title: 'date added', orderable: true, sort: 'most_recent', default: true },
  { cell: 'actionFn', title: 'actions', orderable: false, sort: null },
];
const SELECTED_VIDEO_LIMIT = 4;
const TITLE_ID = 'vf_title';

class VideosForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSearch: null,
      currentSort: {
        sort: 'most_recent',
        status: 0,
      },
      dialog: {
        mode: 'default',
        open: false,
        record: null,
        scroll: 0,
      },
      errors: {},
      isBusy: false,
      mode: props.mode, // (default || edit)
      pagination: {
        current_page: 1,
        next_page: 2,
        per_page: 20,
        prev_page: null,
      },
      records: [],
      selectedIndexes: [],
      selectedRecords: [],
      title: props.initTitle,
    };

    this.algoliaPartsService = new AlgoliaPartsService();
    this.algoliaPlatformsService = new AlgoliaPlatformsService();
    this.algoliaTagsService = new AlgoliaTagsService();
    this.algoliaTopicsService = new AlgoliaTopicsService();

    this.addNewRecord = this.addNewRecord.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleReportedSelectedIndexes = this.handleReportedSelectedIndexes.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.updateRecord = this.updateRecord.bind(this);

    // Refs
    this._tableRef;
  }

  /**
   * Accessors
   */
  __getContent() {
    return {
      content: this.state.selectedRecords.map((r) => r.id),
      title: this.state.title,
    };
  }

  __validate() {
    if (!this.state.title.length) {
      this.setState({ errors: { title: 'A title is required' } }, () => this._safeScroll(TITLE_ID));

      return false;
    }

    return this.state.selectedRecords.length >= SELECTED_VIDEO_LIMIT;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._fetchRecords();
  }

  /**
   * Initializers
   */
  _fetchRecords() {
    this._toggleIsBusy(true);

    return Promise.all([this._handlePaginationRequest(1), this._fetchInitRecords(this.props.initRecords)])
      .then(([{ videos }, { initRecords }]) => {
        this.setState({
          isBusy: false,
          pagination: videos.metadata,
          records: this._formatRecords(videos.records),
          selectedIndexes: this._getSelectedIndexesForIds(videos.records, initRecords),
          selectedRecords: initRecords,
        }, () => this._forceUpdateTableView());
      })
      .catch((err) => {
        this._toggleIsBusy(false);
        errorHandler('EditVideos _fetchRecords:', err);
      });
  }

  _fetchInitRecords(ids = []) {
    return new Promise((resolve, reject) => {
      if (!ids.length) return resolve({ initRecords: [] });

      return graphQuery({ t: 'videos_by_id_admin' }, { ids })
        .then(({ records }) => resolve({ initRecords: this._sortRecordsByOrder(ids, records) }))
        .catch((err) => reject(err));
    });
  }

  _formatRecords(records) {
    return records.map((record, i) => ({
      record,
      columns: [
        record.title,
        record.category,
        timestampToPrettyDate(record.created_at),
        this._createActionsColumn.bind(this, record, i),
      ],
    }));
  }

  /**
   * Methods
   */
  addNewRecord(record) {
    const sliceLastIfWillOverflow = this.state.records.length === this.state.pagination.per_page ? -1 : this.state.records.length;
    // Increment indexes by one; ignore if index goes out of bounds and autoselect only when we have space in selectedRecords.
    const incrementedIndexes = this.state.selectedIndexes.reduce((acc, si) => (si + 1) <= (this.state.pagination.per_page - 1) ? acc.concat(si + 1) : acc, this.state.selectedRecords.length === SELECTED_VIDEO_LIMIT ? [] : [0]);

    this.setState({
      records: this._formatRecords([record]).concat(this.state.records.slice(0, sliceLastIfWillOverflow)),
      selectedIndexes: incrementedIndexes,
      selectedRecords: this.state.selectedRecords.length === SELECTED_VIDEO_LIMIT ? this.state.selectedRecords : this.state.selectedRecords.concat(record),
    }, () => this._dismissDialog());
  }

  handlePagination(page) {
    this._toggleIsBusy(true);

    return this._handlePaginationRequest(page)
      .then(({ videos }) => {
        this.setState({
          isBusy: false,
          pagination: videos.metadata,
          records: this._formatRecords(videos.records),
          selectedIndexes: this._getSelectedIndexesForIds(videos.records),
        }, () => this._forceUpdateTableView());
      })
      .catch((err) => {
        this._toggleIsBusy(false);
        errorHandler('EditVideos handlePagination:', err);
      });
  }

  handleReportedSelectedIndexes(selectedIndexes, wasSelected, record) {
    const selectedRecords = wasSelected ? this.state.selectedRecords.concat(record) : this.state.selectedRecords.filter((r) => r.id !== record.id);

    this.setState({
      selectedIndexes,
      selectedRecords,
    });
  }

  handleSearch(val) {
    this._toggleIsBusy(true);

    return graphQuery({ t: 'videos_admin' }, {
      page: 1,
      per_page: this.state.pagination.per_page,
      search: val,
      sort: getSortEnum(this.state.currentSort.sort, this.state.currentSort.status),
    })
      .then(({ errors, videos }) => {
        if (errors) return Promise.reject(errors);

        this.setState({
          currentSearch: val,
          isBusy: false,
          pagination: videos.metadata,
          records: this._formatRecords(videos.records),
          selectedIndexes: this._getSelectedIndexesForIds(videos.records),
        }, () => this._forceUpdateTableView());
      })
      .catch((err) => {
        this._toggleIsBusy(false);
        errorHandler('EditVideos handlePagination:', err);
      });
  }

  handleSort({ sort, status }) {
    this._toggleIsBusy(true);

    return graphQuery({ t: 'videos_admin' }, {
      page: 1,
      per_page: this.state.pagination.per_page,
      search: this.state.currentSearch,
      sort: getSortEnum(sort, status),
    })
      .then(({ errors, videos }) => {
        if (errors) return Promise.reject(errors);

        this.setState({
          currentSort: { sort, status },
          isBusy: false,
          pagination: videos.metadata,
          records: this._formatRecords(videos.records),
          selectedIndexes: this._getSelectedIndexesForIds(videos.records),
        }, () => this._forceUpdateTableView());
      })
      .catch((err) => {
        this._toggleIsBusy(false);
        errorHandler('EditVideos handlePagination:', err);
      });
  }

  updateRecord(record) {
    this.setState({
      records: this.state.records.map((r) => r.record.id === record.id ? this._formatRecords([{ ...r.record, ...record }])[0] : r),
      selectedRecords: this.state.selectedRecords.map((r) => r.id === record.id ? record : r),
    }, () => this._dismissDialog());
  }

  /**
   * Selected Records API (DragAndDropList)
   */
  _removeFromSelectedRecords(record) {
    const index = this.state.records.findIndex((r) => r.record.id === record.id);

    this.setState({
      records: this._formatRecords(this.state.records.map((r) => r.record)),
      selectedIndexes: this.state.selectedIndexes.filter((si) => si !== index),
      selectedRecords: this.state.selectedRecords.filter((r) => r.id !== record.id),
    });
  }

  _sortRecordsByOrder(ids, records) {
    return ids.map((id) => records.find((r) => r.id === id));
  }

  _updateSelectedRecords(selectedRecords) {
    this.setState({ selectedRecords });
  }

  /**
   * Helpers
   */
  _disableRow(record) {
    return this._disableUnselectedRows() && this.state.selectedRecords.findIndex((r) => r.id === record.id) === -1;
  }

  _disableUnselectedRows() {
    return this.state.selectedRecords.length >= SELECTED_VIDEO_LIMIT;
  }

  _dismissDialog() {
    if (this.state.isBusy) return;

    this.setState({ dialog: { open: false, mode: 'default', record: null, scroll: 0 } });
  }

  // Note: React is having issues updating a 2n rendered list via props.  It stops short of the second loop and componentWillRecieveProps
  // doesn't get called.  This will force it to update the isSelected state in SelectableCells.  Not the prettiest.
  _forceUpdateTableView() {
    if (this._tableRef) {
      this._tableRef.forceUpdate();
    }
  }

  _getSelectedIndexesForIds(records, selectedRecords = this.state.selectedRecords) {
    if (!selectedRecords.length) return [];

    return records.reduce((acc, record, i) => (selectedRecords.findIndex((r) => r.id === record.id) > -1) ? acc.concat(i) : acc, []);
  }

  _handlePaginationRequest(page) {
    return new Promise((resolve, reject) => graphQuery({ t: 'videos_admin' }, {
      page,
      per_page: this.state.pagination.per_page,
      search: this.state.currentSearch,
      sort: getSortEnum(this.state.currentSort.sort, this.state.currentSort.status),
    })
      .then(({ errors, videos }) => {
        if (errors) return Promise.reject(errors);
        resolve({ videos });
      })
      .catch((err) => reject(err)));
  }

  _safeScroll(id) {
    const el = document.getElementById(id);
    if (el) {
      smoothScroll(el, 200);
    }
  }

  // Note: Opening the dialog scrolls to the top (probably something to do with the noscroll class being added).
  // Its annoying to have to scroll back down after dismissing the dialog, so we reset the scrollY here and all is happy.
  _summonDialog(mode = 'default', record = null) {
    this.setState({ dialog: { open: true, scroll: window.scrollY, mode, record } }, () => windowScrollTo(0, this.state.dialog.scroll));
  }

  _toggleIsBusy(isBusy) {
    this.setState({ isBusy });
  }

  /**
   * Views
   */
  _createActionsColumn(record, i) {
    const disableRow = this._disableRow(record);

    return (
      <span>
        <button
          className={`${tableStyles.editBtn} ${disableRow ? tableStyles.disabled : ''}`}
          disabled={disableRow}
          onClick={this._summonDialog.bind(this, 'edit', record)}
        >
          Edit
        </button>
      </span>
    );
  }

  render() {
    return (
      <div>
        <div id={TITLE_ID}>
          <BasicFormInput
            classList={{ root: formStyles.container }}
            errors={this.state.errors.title || null}
            label="Section title"
            onChange={(e) => this.setState({ title: e.target.value, errors: { ...this.state.errors, title: null } })}
            placeholder="Enter your title"
            value={this.state.title}
          />
        </div>

        <div className={layout.marginBottom30}>
          <h2 className={typography.h2}>{`Select ${SELECTED_VIDEO_LIMIT} videos`}</h2>
          <p className={typography.bodyM}>Select the videos you would like to feature in this section.</p>
        </div>

        <div className={layout.marginBottom60}>
          <VideosTableView
            disabledState={this._disableUnselectedRows()}
            headers={HEADERS}
            isBusy={this.state.isBusy}
            onDelete={this.handleMassDeletion}
            onPagination={this.handlePagination}
            onSearch={this.handleSearch}
            onSort={this.handleSort}
            pagination={this.state.pagination}
            reportSelected={this.handleReportedSelectedIndexes}
            selectedIndexes={this.state.selectedIndexes}
            summonDialog={(mode) => this._summonDialog(mode)}
            tableItems={this.state.records}
            tableRef={(el) => this._tableRef = el}
          />
        </div>

        <div className={layout.marginBottom30}>
          <h2 className={`${typography.h2} ${layout.marginBottom5}`}>Selected videos</h2>
          <p className={typography.bodyM}>You must add 4 content items before publishing. Drag a video to a new position to reorder it.</p>
        </div>

        <div className={`${layout.marginBottom60} ${utilStyles.border}`} style={{ borderTop: 'none' }}>
          {
            this.state.selectedRecords.length > 0
              ? (
                <DragAndDropList
                  ItemComponent={SectionCard}
                  dragEndCallback={(records) => this._updateSelectedRecords(records)}
                  hasDragHandle={true}
                  itemProps={{
                    removeItem: (data) => this._removeFromSelectedRecords(data),
                    updateItem: (data) => this._summonDialog('edit', data),
                  }}
                  items={this.state.selectedRecords}
                />
                )
              : <div className={`${videosStyles.selectedPlaceholder}`}>No videos selected</div>
          }
        </div>

        <div className={layout.marginBottom30}>
          <h2 className={`${typography.h2} ${layout.marginBottom5}`}>Preview</h2>
          <p className={typography.bodyM}>This is how the section will appear on the homepage.</p>
        </div>

        <div className={layout.paddingBottom30}>
          <PreviewView records={this.state.selectedRecords} />
        </div>

        <Dialog
          dismiss={() => this._dismissDialog()}
          dismissStyle={{ fontWeight: 100, opacity: 1 }}
          open={this.state.dialog.open}
          wrapperStyle={{ marginTop: 100, width: 870 }}
        >
          <AddOrEditVideoView
            algoliaPartsService={this.algoliaPartsService}
            algoliaPlatformsService={this.algoliaPlatformsService}
            algoliaTagsService={this.algoliaTagsService}
            algoliaTopicsService={this.algoliaTopicsService}
            dismiss={() => this._dismissDialog()}
            initRecord={this.state.dialog.record}
            mode={this.state.dialog.mode}
            propagateBusyStatus={(isBusy) => this._toggleIsBusy(isBusy)}
            propagateEditedRecord={this.updateRecord}
            propagateNewRecord={this.addNewRecord}
          />
        </Dialog>
      </div>
    );
  }
}

VideosForm.propTypes = {
  initRecords: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  initTitle: PropTypes.string,
  mode: PropTypes.string,
  propogateStatus: PropTypes.func,
};

VideosForm.defaultProps = {
  initRecords: [],
  initTitle: '',
  mode: 'default',
  propogateStatus: () => {},
};

export default VideosForm;
