import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';

import { timestampToPrettyDate } from '../../../utility/time';

import typography from '../../../styles/global_ui/typography.css';

const EventCardDate = ({ dateString, classList }) => {
  const dayOfMonth = timestampToPrettyDate(dateString, { day: 'numeric' }, false, null);
  const month = timestampToPrettyDate(dateString, { month: 'short' }, false, null);

  return (
    <div className={typography.textCenter}>
      <div className={classList.day}>{dayOfMonth || <Icon name="calendar" />}</div>
      <div className={classList.month}>{month || 'tba'}</div>
    </div>
  );
};

EventCardDate.propTypes = {
  classList: PropTypes.shape({
    day: PropTypes.string,
    month: PropTypes.string,
  }),
  dateString: PropTypes.string,
};

EventCardDate.defaultProps = {
  classList: {},
  dateString: '',
};

export default EventCardDate;
