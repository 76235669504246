import convertFromHTMLToContentBlocks from '../encoding/convertFromHTMLToContentBlocks';
import {domWalk} from '../utils/Traversal';
import {ElementWhiteList} from '../constants/elements';

const blockElementTransformMap = {
  'ol': 'ul',
  'h1': 'h3',
  'h2': 'h3',
  'h4': 'h3',
  'h5': 'h3',
  'h6': 'h3'
};

const headers = {
  H1: true,
  H2: true,
  H3: true,
  H4: true,
  H5: true
};

const elementsToIgnore = {
  'meta': true,
  'style': true,
  'header': true,
  'footer': true,
  'title': true,
  '#comment': true // NOTE: Keep an eye on this one.
};

function swapParentNode(oldNode, newNodeName) {
  const newNode = document.createElement(newNodeName);
  newNode.innerHTML = oldNode.innerHTML ? oldNode.innerHTML : oldNode.textContent;
  return newNode.outerHTML;
}

function hasTable(html) {
  let hasChild = false;

  domWalk(html, (child, root) => {
    if((root.nodeName === 'TABLE') || (child.nodeName === 'TABLE')) {
      hasChild = true;
      return;
    }
  });

  return hasChild;
}

// ONLY HANDLES HEADERS
function hasNestedBlock(html) {
  let bool = false;

  domWalk(html, (child, root) => {
    if (headers.hasOwnProperty(child.nodeName)) {
      bool = true;
      return;
    }
  });

  return bool;
}

// ONLY HANDLES HEADERS
function mutateNestedBlock(html) {
  const nodes = [];

  domWalk(html, (child, root) => {
    if (headers.hasOwnProperty(child.nodeName) && child.innerText.length > 0) {
      const h3 = document.createElement('H3');
      h3.innerHTML = child.innerHTML;
      child.parentNode.replaceChild(h3, child);
    }
  });
}

function cleanTable(table) {
  let container = document.createElement('div');

  // Instead of trying to clean all the html, just clean the table here.
  // We need to create new lines for each of these new paragraphs, should we return an array instead?

  domWalk(table, (child, root) => {
    if(child.textContent.length) {
      switch(child.nodeName) {
        case 'TR':
          if(!child.children) container.appendChild(createNewNodeWithTextContent(child, 'div'));
          break;
        case 'TD':
        case 'TH':
        case 'CAPTION':
          container.appendChild(createNewNodeWithTextContent(child, 'div'));
          break;
        default:
          break;
      }
    }
  });

  return container.innerHTML;
}

function createNewNodeWithTextContent(oldNode, newNodeName) {
  const newNode = document.createElement(newNodeName || 'div');
  newNode.textContent = oldNode.textContent;
  return newNode;
}

// Mutative!
function removeAttributes(node) {
  node.setAttribute ? node.setAttribute('style', '') : void(0);

  if (node.attributes && node.attributes.length) {
    [].slice.call(node.attributes).forEach(attrib => {
      node.removeAttribute(attrib);
    });
  }
}

// Takes an html fragment, stringifies it, then converts and returns an array of ContentBlocks.
export default function parseHTML(html, restrictions) {
  const stringifiedHtml = [].slice.call(html.childNodes).reduce((acc, child) => {

    if(elementsToIgnore[child.nodeName.toLowerCase()]) return acc;

    removeAttributes(child);

    if (hasNestedBlock(child)) mutateNestedBlock(child);

    if (hasTable(child)) {
      acc += cleanTable(child);
    } else if (ElementWhiteList[child.nodeName.toLowerCase()]) {
      acc += child.outerHTML;
    } else if (blockElementTransformMap[child.nodeName.toLowerCase()]) {
      acc += swapParentNode(child, blockElementTransformMap[child.nodeName.toLowerCase()]);
    } else {
      acc += swapParentNode(child, 'div');
    }

    return acc;
  }, '');

  return convertFromHTMLToContentBlocks(stringifiedHtml, restrictions);
}