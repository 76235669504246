import keenService from './main';

function followedConfig(entity_type) {
  return (followed) => ({ entity_type, eventName: followed ? 'Followed entity' : 'Unfollowed entity' });
}

function respectedConfig(entity_type) {
  return (respected) => ({ entity_type, eventName: respected ? 'Respected entity' : 'Unrespected entity' });
}

const config = {
  community_ids: followedConfig('Community'),
  followed_user_ids: followedConfig('User'),
  part_ids: followedConfig('Part'),
  platform_ids: followedConfig('Platform'),
  respected_comment_ids: respectedConfig('Comment'),
  respected_feed_post_ids: respectedConfig('FeedPost'),
  respected_news_article_ids: respectedConfig('NewsArticle'),
  respected_project_ids: respectedConfig('Project'),
  topic_ids: followedConfig('Topic'),
};

// TODO: We can move trackRelation into main keen and make this a config file it uses to construct the objects.
export default function trackRelation({ key, createOrDeleteBool, id, source }) {
  const translator = config[key];
  if (!translator) return Promise.resolve();

  const { eventName, entity_type } = translator(createOrDeleteBool);

  return keenService.reportEvent({ eventName }, { entity_type, source, entity_id: id });
}
