export function capitalize(str) {
  if (!str || str.length === 0) return '';

  return `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`;
}

export function escapeDoubleQuotes(str) {
  return str.replace(/"/g, '\\"');
}

export function firstCharUpperCase(string) {
  if (!string || !string.length) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
}

// works up to ~= 999 Trillion
export function formatNumberLimit4Chars(number) {
  const numString = parseInt(number).toString();
  if (number < 1000) return numString;

  const cutoff = Math.floor((numString.length - 1) / 3);
  const divided = number / Math.pow(10, cutoff * 3);

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const suffix = suffixes[cutoff];

  if (divided < 10) {
    if (divided % 1 === 0) {
      return `${divided}${suffix}`;
    } else {
      return `${divided.toString().slice(0, 3)}${suffix}`;
    }
  } else {
    return `${parseInt(divided)}${suffix}`;
  }
}

export function formatNumberWithCommas(number) {
  return parseInt(number).toString().split('').reverse().reduce((memo, n, i) => (
    i % 3 === 0 && i !== 0 ? [n, ',', ...memo] : [n, ...memo]
  ), []).join('');
}

export function pluralize(word, count = 0, apostrophe = false) {
  if (!word || !word.length) return '';

  return count === 1 ? word : `${word}${apostrophe ? "'" : ''}s`;
}

export function stripExcessWhiteSpace(string) {
  if (!string || !string.length) return '';

  return string.trim().replace(/\s+/g, ' ');
}

export function truncateString(string, limit, ellipse = true) {
  // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
  // eslint-disable-next-line @stylistic/no-mixed-operators
  if (!string || string && !string.length) return '';

  return `${string.substring(0, limit)}${ellipse && string.length > limit ? '...' : ''}`;
}

export function unsnakeString(string, decoratorFn = null) {
  const unsnaked = string.split('_').join(' ');

  return decoratorFn ? decoratorFn(unsnaked) : unsnaked;
}

export function round(number, decimals = 2) {
  return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}
