import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { getInObj } from '../../../utility/accessors';
import { timestampToPrettyDate, timestampToRelativeTime } from '../../../utility/time';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

import baseCardStyles from '../base_card/vertical/base_card_vertical.css';
import LazyBackgroundImage from '../../image/LazyBackgroundImage';

const NewsArticlePreviewCard = ({ article, className }) => (
  <div className={`${baseCardStyles.cardResponsive} ${layout.flexColumn} ${layout.fullWidth} ${className}`}>
    <div className={`${baseCardStyles.imageResponsive} ${layout.fullWidth} ${utilStyles.absolutePlaceholderParent} ${utilStyles.aspectRatioPadding16_9}`}>
      <LazyBackgroundImage
        classList={{ root: `${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}` }}
        ratio="16:9"
        setStyle={false}
        src={getInObj(['image', 'url'], article)}
        width={285}
      />
    </div>

    <div className={`${baseCardStyles.textResponsive} ${layout.marginTop22}`}>
      <div className={`${typography.breakWord} ${layout.flexColumn}`}>
        <p className={`${typography.bodyM} ${typography.bold}`}>{article.title}</p>
        <p className={typography.bodyS}>
          <span className={typography.link}>{article.user.name}</span>
          {article.published_at
          && (
            <Fragment>
              <span className={`${layout.marginLeft5} ${layout.marginRight5}`}>•</span>
              <span title={timestampToPrettyDate(article.published_at)}>{timestampToRelativeTime(article.published_at)}</span>
            </Fragment>
          )}
        </p>
      </div>
    </div>
  </div>
);

NewsArticlePreviewCard.propTypes = {
  article: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string }),
    published_at: PropTypes.string,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({ name: PropTypes.string.isRequired }),
  }).isRequired,
};

export default NewsArticlePreviewCard;
