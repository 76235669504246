import React from 'react';
import PropTypes from 'prop-types';

import TabbedHeader from '../../nav_components/tabbed_header';

const TabbedSimpleSelectView = ({ options, selectedValueIndex, selectOptionByIndex, underline }) => (
  <TabbedHeader
    activeIndex={selectedValueIndex}
    centered={false}
    onClick={(i) => selectOptionByIndex({ target: { value: i } })}
    tabs={options}
    underline={underline}
  />
);

TabbedSimpleSelectView.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]), // Can be null
    default: PropTypes.bool,
  })).isRequired,
  selectOptionByIndex: PropTypes.func.isRequired,
  selectedValueIndex: PropTypes.number.isRequired,
  underline: PropTypes.bool,
};

TabbedSimpleSelectView.defaultProps = { underline: false };

export default TabbedSimpleSelectView;
