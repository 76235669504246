import React from 'react';
import PropTypes from 'prop-types';

import LatestArticlesSide from '../../templates/latest_articles/LatestArticlesSide';
import ModularAd from '../../../../../client/ads/modular_ad/ModularAd';
import NewsSignupCTA from '../../../shared/signup_cta';
import RelatedArticlesSide from '../../templates/related_articles/RelatedArticlesSide';
import SponsoredArticles from './SponsoredArticles';

import layout from '../../../../../styles/global_ui/layout.css';
import styles from '../article_layout.css';

const adRelations = (article) => ({
  platform_ids: article.platforms.map((p) => p.id),
  tag_ids: article.tags.map((t) => t.id),
});

const ArticleSidebar = ({ article, latestArticles, relatedArticles, sponsoredArticles }) => (
  <div className={styles.sidebarInner}>
    <ModularAd page_type="article_page" relations={adRelations(article)} />

    <div className={layout.hiddenMedDown}>
      <LatestArticlesSide records={latestArticles} />
    </div>

    {!article.sponsored && <SponsoredArticles articles={sponsoredArticles} />}

    <div className={layout.hiddenMedDown}>
      <RelatedArticlesSide records={relatedArticles} />
    </div>

    <NewsSignupCTA className={styles.signupCTA} />
  </div>
);

ArticleSidebar.propTypes = {
  article: PropTypes.shape({ sponsored: PropTypes.bool }).isRequired,
  latestArticles: PropTypes.array,
  relatedArticles: PropTypes.array,
  sponsoredArticles: PropTypes.array,
};

ArticleSidebar.defaultProps = {
  latestArticles: null,
  relatedArticles: null,
  sponsoredArticles: null,
};

export default ArticleSidebar;
