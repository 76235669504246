import { getEventTargetHref, getEventTargetText } from '../../../utility/events';

const createReturnShape = (customProps = {}, eventName = '') => ({ customProps, event: { eventName } });

export const getClickedFilterArgs = (key, value) => ({
  customProps: { filter_name: key, filter_value: value },
  event: { eventName: 'Clicked filter value' },
});

export const getClickedButtonArgs = ({ entity_type, entity_id, location, type, value }, e) => {
  const newValue = value || getEventTargetText(e);

  return {
    customProps: { entity_type, entity_id, location, type, value: newValue },
    event: { eventName: 'Clicked button' },
  };
};

export const getClickedLinkArgs = ({ entity_type, entity_id, href, location, type, value }, e) => {
  const newHref = href || getEventTargetHref(e);
  const newValue = value || getEventTargetText(e);

  return {
    customProps: { entity_type, entity_id, href: newHref, location, type, value: newValue },
    event: { eventName: 'Clicked link' },
  };
};

export const getClickedMenuItemArgs = ({ entity_id, entity_type, value }) => ({
  customProps: { entity_id, entity_type, value },
  event: { eventName: 'Clicked menu item' },
});

export const getClickedViewMoreArgs = ({ entity_id, entity_type }) => ({
  customProps: { entity_id, entity_type },
  event: { eventName: 'Clicked view more' },
});

export const getClickedViewAllSectionArgs = (section, index, item = {}, pageType = null) => ({
  customProps: { item, section: { key: section.key, index, value: section.value } },
  event: { eventName: 'Clicked view all section', pageType },
});

/**
 * Ads
 */
export const getClickedAdArgs = ({ id }) => createReturnShape({ id }, 'Ad clicked');

export const getViewedAdArgs = ({ blocked, id }) => createReturnShape({ blocked, id }, 'Ad viewed');

/**
 * Bookmarks
 */
export const getCreateBookmarkArgs = ({ id, widget_src }) => createReturnShape({ id, widget_src }, 'Created bookmark');

export const getDeleteBookmarkArgs = ({ id, widget_src }) => createReturnShape({ id, widget_src }, 'Deleted bookmark');

export const getCreateBookmarkListArgs = ({ id, widget_src }) => createReturnShape({ id, widget_src }, 'Created bookmark list');

export const getDeleteBookmarkListArgs = ({ id, widget_src }) => createReturnShape({ id, widget_src }, 'Deleted bookmark list');

export const getOpenedBookmarkMenuArgs = ({ project_id, widget_src }) => createReturnShape({ project_id, widget_src }, 'Opened bookmark menu');

/**
 * Comments
 * NOTE: Theres events called 'Posted comment' which are for project comments. These are for new Discussion boards (2019).
 */
export const getCreateCommentArgs = ({ id, is_reply }) => createReturnShape({ comment_id: id, is_reply }, 'Created comment');
export const getDeleteCommentArgs = ({ id, is_reply }) => createReturnShape({ comment_id: id, is_reply }, 'Deleted comment');
export const getUpdateCommentArgs = ({ id, is_reply }) => createReturnShape({ comment_id: id, is_reply }, 'Updated comment');

/**
 * Contests
 */
export const getCreateContestEntryArgs = ({ id, project_id }) => createReturnShape({ entity_id: id, entity_type: 'Challenge', project_id }, 'Submitted project to contest');
export const getCreateContestIdeaArgs = ({ id, idea_id }) => createReturnShape({ entity_id: id, entity_type: 'Challenge', idea_id }, 'Submitted idea to contest');
export const getCreateContestRegistrationArgs = ({ id }) => createReturnShape({ entity_id: id, entity_type: 'Challenge' }, 'Registered for contest');
export const getDeleteContestRegistrationArgs = ({ id }) => createReturnShape({ entity_id: id, entity_type: 'Challenge' }, 'Unregistered for contest');

/**
 * Events (Community / Sponsored)
 */
export const getCreateEventArgs = (id, event_type) => ({
  customProps: { id, event_type },
  event: { eventName: 'Created event' },
});

export const getDeleteEventArgs = (id, event_type) => ({
  customProps: { id, event_type },
  event: { eventName: 'Deleted event' },
});

/**
 * Feed Posts // Discussion Posts
 */
export const getCreatePostArgs = (id, category) => createReturnShape({ post_id: id, category }, 'Created post');
export const getDeletePostArgs = (id, category) => createReturnShape({ post_id: id, category }, 'Deleted post');
export const getUpdatePostArgs = (id, category) => createReturnShape({ post_id: id, category }, 'Updated post');

/**
 * Search inputs
 */
export const getSearchFilterArgs = (query) => ({
  customProps: { query },
  event: { eventName: 'Search filter' },
});

export const getSearchLocationFilterArgs = (location = {}) => ({
  customProps: location,
  event: { eventName: 'Search location filter' },
});
