import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../../../client/wrappers/dropdown';
import Icon from '../../../../client/icon';

import { PUBLISHED_STATUS } from '../../../../graphql/news/enums';
import { timeIsFuture, timeIsPast } from '../../../../utility/time';
import { copyStringToClipboard } from '../../../../services/clipboard';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import menuStyles from '../../../../client/form_components/simple_select/simple_select.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';

class IconOptionsMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { openMenu: false };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  /**
   * Methods
   */
  toggleMenu() {
    this.setState({ openMenu: !this.state.openMenu });
  }

  /**
   * Helpers
   */
  _copyUrlToClipboard() {
    copyStringToClipboard(this.props.article.url);
    this.toggleMenu();
  }

  _editArticle() {
    this.props.redirectToArticleEdit(this.props.article);
    this.toggleMenu();
  }

  _deleteArticle() {
    this.props.deleteArticle(this.props.article);
    this.toggleMenu();
  }

  _openInNewWindow() {
    window.open(this.props.article.url);
    this.toggleMenu();
  }

  _unpublishArticle() {
    this.props.unpublishArticle(this.props.article);
    this.toggleMenu();
  }

  _viewStats() {
    this.props.redirectToArticleStats(this.props.article);
    this.toggleMenu();
  }

  /**
   * Views
   */
  _getDropdownOptions() {
    return this.props.isAdminOrEditor ? this._getMenuOptsForAdminOrEditor() : this._getMenuOptsForAuthor();
  }

  _getMenuOptsForAdminOrEditor() {
    const status = this.props.article.status;
    const isLive = status === PUBLISHED_STATUS && timeIsPast(this.props.article.published_at);
    const isScheduled = status === PUBLISHED_STATUS && timeIsFuture(this.props.article.published_at);

    return (
      <Fragment>
        {isLive && <li className={menuStyles.option} onClick={() => this._unpublishArticle()}>Unpublish</li>}
        {isLive && <li className={menuStyles.option} onClick={() => this._openInNewWindow()}>View live article</li>}
        {isLive && <li className={menuStyles.option} onClick={() => this._viewStats()}>View stats</li>}
        {isScheduled && <li className={menuStyles.option} onClick={() => this._unpublishArticle()}>Unschedule</li>}
        {!isLive && <li className={menuStyles.option} onClick={() => this._copyUrlToClipboard()}>Copy draft link</li>}
        <hr className={`${utilStyles.hr} ${layout.marginTop10} ${layout.marginBottom10}`} />
        {this.props.type === 'list' && <li className={menuStyles.option} onClick={() => this._editArticle()}>Edit article</li>}
        <li className={`${menuStyles.option} ${typography.error}`} onClick={() => this._deleteArticle()}>Delete article</li>
      </Fragment>
    );
  }

  _getMenuOptsForAuthor() {
    const status = this.props.article.status;
    const isLive = status === PUBLISHED_STATUS && timeIsPast(this.props.article.published_at);

    return (
      <Fragment>
        {isLive && <li className={menuStyles.option} onClick={() => this._openInNewWindow()}>View live article</li>}
        {isLive && <li className={menuStyles.option} onClick={() => this._viewStats()}>View stats</li>}
        {!isLive && <li className={menuStyles.option} onClick={() => this._copyUrlToClipboard()}>Copy draft link</li>}
        {(status !== PUBLISHED_STATUS || this.props.type === 'list') && <hr className={`${utilStyles.hr} ${layout.marginTop10} ${layout.marginBottom10}`} />}
        {this.props.type === 'list' && <li className={menuStyles.option} onClick={() => this._editArticle()}>Edit article</li>}
        {(status !== PUBLISHED_STATUS) && <li className={`${menuStyles.option} ${typography.error}`} onClick={() => this._deleteArticle()}>Delete article</li>}
      </Fragment>
    );
  }

  render() {
    return (
      <div className={`${utilStyles.posRelative} ${utilStyles.height100P}`}>
        <button className={buttonStyles.blank} onClick={this.toggleMenu} type="button">
          <Icon name="view-more" size={16} />
        </button>

        {this.state.openMenu
        && (
          <Dropdown
            key="menu"
            alignRight={true}
            alignTop={false}
            className={this.props.classList.dropdown}
            dismiss={this.toggleMenu}
          >
            <ul className={utilStyles.ulReset}>
              {React.Children.toArray(this._getDropdownOptions())}
            </ul>
          </Dropdown>
        )}
      </div>
    );
  }
}

IconOptionsMenu.propTypes = {
  article: PropTypes.shape({
    status: PropTypes.string.isRequired,
    published_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string, // Not required when draft
  }).isRequired,
  classList: PropTypes.shape({ dropdown: PropTypes.string }),
  deleteArticle: PropTypes.func.isRequired,
  isAdminOrEditor: PropTypes.bool.isRequired,
  redirectToArticleEdit: PropTypes.func,
  redirectToArticleStats: PropTypes.func,
  type: PropTypes.oneOf(['list', 'topbar']),
  unpublishArticle: PropTypes.func.isRequired,
};

IconOptionsMenu.defaultProps = {
  classList: { dropdown: '' },
  redirectToArticleEdit: null,
};

export default IconOptionsMenu;
