import defaultStyles from './default.css';
import projectPageStyles from './project_page.css';

import articleStyles from '../../../../scenes/news/article_page/templates/sticky_actions/sticky_actions.css';
import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

const buttonBase = `${buttonStyles.icon} ${buttonStyles.outlineBlue}`;

const articleBottomButton = `${buttonBase} ${buttonStyles.md}`;
const articleSideButton = `${buttonBase} ${buttonStyles.md} ${articleStyles.stickyButton}`;
const postCardButton = `${buttonBase} ${buttonStyles.sm} ${layout.padding10}`;
const postCardCommentButton = `${buttonStyles.blank} ${typography.hackster} ${typography.bodyS}`;
const projectCardButton = `${buttonBase} ${buttonStyles.sm} ${layout.marginRight10}`;

const defaultTheme = {
  respectButton: buttonBase,
  respectedButton: buttonBase,
  wrapper: defaultStyles.wrapper,
};

const themeOverrides = {
  articleBottom: {
    respectButton: articleBottomButton,
    respectedButton: articleBottomButton,
  },
  articleSide: {
    respectButton: articleSideButton,
    respectedButton: articleSideButton,
  },
  postCard: {
    respectButton: postCardButton,
    respectedButton: postCardButton,
  },
  postCardComment: {
    respectButton: postCardCommentButton,
    respectedButton: postCardCommentButton,
  },
  project_card: {
    respectButton: projectCardButton,
    respectedButton: projectCardButton,
  },
  project_page: {
    respectButton: `${buttonBase} ${buttonStyles.md} ${projectPageStyles.respectButton}`,
    respectedButton: `${buttonBase} ${buttonStyles.md} ${projectPageStyles.respectedButton}`,
  },
};

// apply overrides to default theme to get full themes
const themes = Object.keys(themeOverrides).reduce((acc, key) => (
  { ...acc, [key]: { ...defaultTheme, ...themeOverrides[key] } }
), { default: defaultTheme });

export default themes;
