import React from 'react';
import PropTypes from 'prop-types';

import ActionableButton from '../../buttons/actionable';
import Button from '../../buttons/base';

import layout from '../../../styles/global_ui/layout.css';

const ActionsSection = ({ classNames, disabled, isBusy, mode, primaryBtnConfig, secondaryBtnConfig }) => (
  <div className={classNames.root}>
    <ActionableButton
      color={primaryBtnConfig.color || ''}
      disabled={disabled}
      isBusy={isBusy}
      onClick={primaryBtnConfig.onClick || null}
      size={primaryBtnConfig.size || 'lg'}
      text={mode === 'default' ? primaryBtnConfig.text : (primaryBtnConfig.editModeText || 'Save changes')}
    />
    <Button
      colorStyle="cancel"
      disabled={disabled || isBusy}
      onClick={secondaryBtnConfig.onClick || null}
      size={secondaryBtnConfig.size || 'lg'}
    >
      {secondaryBtnConfig.text || 'Cancel'}
    </Button>
  </div>
);

ActionsSection.propTypes = {
  classNames: PropTypes.shape({ root: PropTypes.string }),
  disabled: PropTypes.bool,
  isBusy: PropTypes.bool,
  mode: PropTypes.string,
  primaryBtnConfig: PropTypes.shape({
    color: PropTypes.string,
    editModeText: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    text: PropTypes.string.isRequired,
  }),
  secondaryBtnConfig: PropTypes.shape({
    color: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    text: PropTypes.string,
  }),
};

ActionsSection.defaultProps = {
  classNames: { root: layout.marginTop60 },
  disabled: false,
  isBusy: false,
  mode: 'default', // ['default', 'edit']
  primaryBtnConfig: {
    color: '',
    editModeText: 'Save changes',
    onClick: null,
    size: 'lg',
  },
  secondaryBtnConfig: {
    color: 'cancel',
    onClick: null,
    size: 'lg',
    text: 'Cancel',
  },
};

export default ActionsSection;
