/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { countries, lookup } from 'country-data';
import { States, Canada, Australia, Mexico } from '../../client/utils/Countries';

const STATES_MAP = {
  AU: Australia,
  CA: Canada,
  MX: Mexico,
  US: States,
};

export const COUNTRY_ISO_2_HAS_VALID_STATES = Object.keys(STATES_MAP);

/**
 * Fallback in case lookup can't map to a value.
 * @param  {object} address - Hackster address model
 * @return {object}
 */
const countryDataObjectForAddress = (address) => ({
  alpha2: address.country_iso2,
  alpha3: null,
  countryCallingCodes: [],
  currencies: [],
  ioc: null,
  languages: ['eng'],
  name: address.country,
  status: 'assigned',
});

/**
 * Formatters
 */
const DEFAULT_LOCATION_FORMATTER = ({ city, country_iso2, state }) => (
  (country_iso2 === 'US') ? getLocationString(city, state) : buildLocation(city, state, country_iso2)
);

const getLocationString = (city = '', state = '', country = '') => (
  [city, state, country].filter((string) => string && string.length > 0).join(', ')
);

export function getFormattedLocation({ city, country_iso2, state } = {}, formatter = DEFAULT_LOCATION_FORMATTER) {
  return formatter({ city, country_iso2, state });
}

export function buildLocation(city = '', state = '', country_iso2 = '', countryField = 'name') {
  const country = lookup.countries({ alpha2: country_iso2 })[0];
  const countryAttr = country && country.hasOwnProperty(countryField) ? country[countryField] : '';

  return getLocationString(city, state, countryAttr);
}

/**
 * Country Data Helpers
 */
export function getCountryData(country) {
  const countryKey = country.length === 2 ? 'alpha2' : country.length === 3 ? 'alpha3' : 'name';

  return lookup.countries({ [countryKey]: country })[0];
}

export function getCountryNameFromCountryCode(countryCode) {
  if (!countryCode) return null;
  const names = new Intl.DisplayNames(['en'], { type: 'region' });
  return names.of(countryCode.toUpperCase());
}

/**
 * @param  {boolean} usFirst - should we append US as the first list item
 * @return {array}
 */
export function getCountryListForSelect(usFirst = true) {
  return countries.all.reduce((acc, c) => {
    if (c.status === 'assigned') {
      acc = usFirst && c.alpha2 === 'US'
        ? [{ data: c, label: c.name, value: c.alpha2 }].concat(acc)
        : acc.concat({ data: c, label: c.name, value: c.alpha2 });
    }

    return acc;
  }, []).sort((a, b) => {
    if (usFirst && a.value === 'US') return -1;
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;

    return 0;
  });
}

/**
 * Returns country object for a Hackster's address model.
 * @param  {object} address - Address from Hackster {..., country: '', country_iso2: '', ...}
 * @return {object} - country-data object
 */
export function getCountryDataForAddress(address) {
  let country;
  if (address.country_iso2 && address.country_iso2.length > 0) {
    country = lookup.countries({ alpha2: address.country_iso2 })[0];
  } else {
    country = lookup.countries({ name: address.country })[0];
  }

  return country ? country : countryDataObjectForAddress(address);
}

/**
 * State name to abbreviation for a country.
 * @param  {string}              state
 * @param  {country_data object} countryData
 * @return {string}
 */
export function getStateForCountry(state, countryData) {
  if (!STATES_MAP.hasOwnProperty(countryData.alpha2)) return state;

  const countryStates = STATES_MAP[countryData.alpha2];
  const stateObj = countryStates.find((cs) => cs.short.toLowerCase() === state.toLowerCase() || cs.name.toLowerCase() === state.toLowerCase());

  return stateObj ? stateObj.short : state;
}

/**
 * Abbreviated state to state name for a country.
 * @param  {string} abbrState    [description]
 * @param  {string} country_iso2 [description]
 * @return {string}
 */
export function getStateFromAbbr(abbrState, country_iso2) {
  if (!STATES_MAP.hasOwnProperty(country_iso2)) return abbrState;

  const statesForCountry = STATES_MAP[country_iso2];
  const state = statesForCountry.find((cs) => cs.short.toLowerCase() === abbrState.toLowerCase());

  return state ? state.name : abbrState;
}

/**
 * Maps an AlgoliaPlaces state/province/administrative field to an abbreviated state.
 * @param {object} location - Location Select's format output.
 *   {
 *     city: string,
 *     countryCode: string,
 *     state: string
 *   }
 */
export function getStateForLocationSelectOutput(location) {
  const countryCode = location.countryCode.toUpperCase();

  if (!STATES_MAP.hasOwnProperty(countryCode)) return null;

  const statesForCountry = STATES_MAP[countryCode];
  const state = statesForCountry.find((cs) => cs.name.toLowerCase() === location.state.toLowerCase());

  return state ? state.short : null;
}
