import { IntersectionObserver } from '../../utility/observers';

export const INTERSECTION_DATA_ATTR = 'data-intersect-callback-id';

function newIntersectionObserver(cb, opts) {
  return new IntersectionObserver(cb, opts);
}

class Observer {
  constructor(opts = {}, onDestroy = () => {}) {
    this._observerCallback = this._observerCallback.bind(this);
    this.onDestroy = onDestroy;

    this.observer = newIntersectionObserver(this._observerCallback, opts);
    this.targetCallbacks = {};
  }

  _destroy() {
    this.observer.disconnect();
    this.onDestroy();
  }

  _observerCallback(entries, observer) {
    entries.forEach((entry) => this._processEntry(entry));
  }

  _processEntry(entry) {
    const callbackID = entry.target.getAttribute(INTERSECTION_DATA_ATTR);
    const callback = this.targetCallbacks[callbackID];
    if (typeof callback === 'function') callback(entry);
  }

  observe(target, id, callback) {
    this.targetCallbacks[id] = callback;
    this.observer.observe(target);

    return () => this.unobserve(target, id);
  }

  unobserve(target, id) {
    delete this.targetCallbacks[id];
    this.observer.unobserve(target);

    if (Object.keys(this.targetCallbacks).length === 0) this._destroy();
  }
}

export default Observer;
