import React from 'react';
import PropTypes from 'prop-types';

import DesktopDatetimeLocalInput from './desktop';
import DesktopNativeInput from '../DesktopNativeInput';
import MobileDatetimeLocalInput from './mobile';

import isDateInputSupported from '../../../../../utility/isDateInputSupported';
import isMobileBrowser from '../../../../../utility/isMobileBrowser';
import { isWindowDefined } from '../../../../../services/window';

// NOTE: DesktopDatetimeLocalInput is meant to be a cross-browser replacement for native date inputs
// on desktop machines. However, it does not currently have a calendar picker feature.
// For now, we will fall back to a native datetime-local input where it is supported,
// so that those users will have a calendar picker.
// TODO: custom calendar picker for DesktopDatetimeLocalInput for a consistent cross-browser experience

const DatetimeLocalInput = (props) => {
  if (!isWindowDefined) return null; // dont server render these inputs
  if (isMobileBrowser()) return <MobileDatetimeLocalInput {...props} />;
  if (isDateInputSupported()) {
    return (
      <DesktopNativeInput
        {...props}
        max="9999-12-31T23:59"
        type="datetime-local"
      />
    );
  }

  return <DesktopDatetimeLocalInput {...props} />;
};

DatetimeLocalInput.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

DatetimeLocalInput.defaultProps = {
  autoComplete: null,
  disabled: false,
  errors: null,
  initValue: '',
};

export default DatetimeLocalInput;
