import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Store from '../../reusable_components/Router/Store.js';

import { graphQuery } from '../../../requests/graphql';

import CommunityCard from '../../../server/user/profile/popovers/CommunityCard';
import UserCardHorizontal from '../../cards/user_card_horizontal';

import { closeGlobalPopover, summonGlobalPopover } from '../../../utility/dispatchers';
import errorHandler from '../../../services/error_handler';
import generateRandomKey from '../../../utility/generateRandomKey';
import { sliceRichTextAt } from '../../../utility/strings';

import typography from '../../../styles/global_ui/typography.css';
import styles from './markdown_viewer.css';

const ELLIPSES = ' ...';
const store = new Store();

const resourceToRequestMap = {
  community: (id) => graphQuery({ t: 'get_community_hover_data_for_global' }, { id }),
  user: (id) => graphQuery({ t: 'get_user_hover_data_for_global' }, { id }),
};

const resourceToComponentMap = {
  community: CommunityCard,
  user: UserCardHorizontal,
};

class MarkdownViewer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      content: this._initContent(props),
      uuid: generateRandomKey(),
    };

    this.handlePopoverEnter = this.handlePopoverEnter.bind(this);
    this.handlePopoverLeave = this.handlePopoverLeave.bind(this);

    this._popovers = [];
    this._hoveredPopovers = [];
    this._currentTarget = null;
  }

  _compoundKey(data) {
    return `${data.type}_${data.id}`;
  }

  componentDidMount() {
    this._bindEventListeners();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.content !== this.props.content || prevState.content !== this.state.content) {
      this._unbindEventListeners(() => this._bindEventListeners());
    }
  }

  componentWillUnmount() {
    this._unbindEventListeners();
  }

  _initContent(props) {
    if (!props.truncateHtml.truncate || (props.truncateHtml.limit > props.content.length)) return props.content;

    return sliceRichTextAt(props.content, props.truncateHtml.limit).concat(ELLIPSES);
  }

  _bindEventListeners() {
    const popovers = Array.from(this.root.querySelectorAll(`a[data-mv-key="${this.state.uuid}"]`));

    if (popovers.length) {
      popovers.forEach((popover) => {
        popover.addEventListener('mouseenter', this.handlePopoverEnter.bind(this, popover));
        popover.addEventListener('mouseleave', this.handlePopoverLeave.bind(this, popover));
        this._popovers.push(popover);
      });
    }
  }

  _unbindEventListeners(done) {
    if (this._popovers.length) {
      this._popovers.forEach((popover) => {
        popover.removeEventListener('mouseenter', this.handlePopoverEnter);
        popover.removeEventListener('mouseleave', this.handlePopoverLeave);
      });
      this._popovers = [];
    }

    if (done && typeof done === 'function') done();
  }

  handlePopoverEnter(popover, e) {
    this._currentTarget = e.target;
    this._hoveredPopovers = this._hoveredPopovers || [];
    this._hoveredPopovers.push(popover);

    // TODO: This can be done better.  Theres still a bleed-through when mentions are back to back and the
    // mouse is quickly scanning between them.
    return this._delay(popover)
      .then((delayed) => this._parseJSON(delayed))
      .then((json) => Promise.all([this._fetchResource(json), Promise.resolve(json)]))
      .then((res) => {
        this._hoveredPopovers = [];

        if (this._currentTarget !== null) {
          return summonGlobalPopover(this._getEventDetails(res[0], res[1]));
        }
      })
      .catch((err) => {
        if (err.message && err.message === 'early reject') return;

        return errorHandler('PopoverPortal onMouseEnter: ', err);
      });
  }

  _delay(popover) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (popover === this._currentTarget) {
          resolve(popover);
        } else {
          reject(new Error('early reject'));
        }
      }, 250);
    });
  }

  _parseJSON(popover) {
    return new Promise((resolve, reject) => {
      try {
        const data = JSON.parse(popover.getAttribute('data-react-popover'));
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  }

  handlePopoverLeave(popover, e) {
    this._hoveredPopovers = this._hoveredPopovers.filter((p) => p !== popover);

    if (this._currentTarget !== null) {
      this._currentTarget = null;
      closeGlobalPopover();
    }
  }

  _fetchResource(data) {
    return new Promise((resolve, reject) => {
      const compoundKey = this._compoundKey(data);

      setTimeout(() => {
        if (store.has(compoundKey)) {
          return resolve(store.get(compoundKey));
        }

        return resourceToRequestMap[data['type']](data.id)
          .then((res) => {
            const record = res[Object.keys(res)[0]];
            const resource = { ...record, id: data.id };
            store.set(compoundKey, resource);
            resolve(resource);
          })
          .catch((err) => reject(err));
      }, 500);
    });
  }

  _getEventDetails(componentProps, data) {
    const props = {
      ...componentProps,
      updateResource: (id, type, path, createOrDeleteBool) => {},
    };

    const adjustmentsForResource = {
      community: { arrowOffset: 0, popoverOffsetVertical: -120, targetOffset: 15 },
      user: { arrowOffset: 0, targetOffset: 10 },
    };

    const adjustments = adjustmentsForResource[data['type']] || {};

    return {
      adjustments: { ...adjustments },
      component: React.createElement(resourceToComponentMap[data['type']], props),
      position: 'bottom',
      target: this._currentTarget,
      uuid: this._compoundKey(data),
    };
  }

  _renderMarkdownSafely() {
    try {
      return this.props.markdownService.render(this.state.content, { uuid: this.state.uuid });
    } catch (err) {
      errorHandler('Markdown renderer error', err);

      return 'There was an error parsing into markdown.';
    }
  }

  /**
   * Views
   */
  _getMoreButton() {
    if (this.state.content === this.props.content) return null;

    return (
      <p
        className={`${typography.linkBlue} ${typography.bodyS}`}
        onClick={() => this.setState({ content: this.props.content }, () => this.props.viewMoreClicked())}
      >
        More
      </p>
    );
  }

  render() {
    return (
      <Fragment>
        <div
          dangerouslySetInnerHTML={{ __html: this._renderMarkdownSafely() }}
          ref={(el) => this.root = el}
          className={`${styles.root} ${this.props.collapse ? styles.collapse : ''}`}
        />
        {this._getMoreButton()}
      </Fragment>
    );
  }
}

MarkdownViewer.propTypes = {
  content: PropTypes.string.isRequired,
  markdownService: PropTypes.object.isRequired,
  truncateHtml: PropTypes.shape({
    truncate: PropTypes.bool,
    limit: PropTypes.number,
  }),
  viewMoreClicked: PropTypes.func,
};

MarkdownViewer.defaultProps = {
  truncateHtml: {
    truncate: false,
    limit: 0,
  },
  viewMoreClicked: () => {},
};

export default MarkdownViewer;
