import PropTypes from 'prop-types';
import React from 'react';
import Dialog from '../../../client/reusable_components/Dialog';
import styles from './image_carousel.css';

const Viewer = ({ dialogProps, children, carouselProps }) => (
  <Dialog
    bodyClassName={styles.dialogBody}
    className={styles.dialog}
    dismissClassName={styles.dismiss}
    fullScreen={true}
    overlayClassName={styles.overlay}
    wrapperClassName={styles.dialogWrapper}
    {...dialogProps}
  >
    {children}
  </Dialog>
);

Viewer.propTypes = {
  dialogProps: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    dismiss: PropTypes.func.isRequired,
  }),
};

Viewer.defaultProps = {};

export default Viewer;
