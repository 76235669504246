import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '../../form_components/select';
import { getInObj } from '../../../utility/accessors';

/**
 * Note:
 * Might merge this with AlgoliaMultiSelect. Left a note there on how to do so. This is just a Select where that one is a FormSelect.
 */
class AlgoliaAsyncSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { projectsCountByIds: null }; // {project_id: projects_count (<INT>)}

    this.fetchOptions = this.fetchOptions.bind(this);
    this._isMounted;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchOptions(queryString) {
    const { hitsPerPage, initFacet } = this.props.algoliaParameters;

    return new Promise((resolve, reject) => Promise.all([
      this._fetchProjectsCountByFacetKey(),
      this.props.algoliaService.searchWithQueryString(queryString, { hitsPerPage }, initFacet),
    ])
      .then(([projectsCountById, algoliaServiceQuery]) => {
        this.props.propagateCurrentQuery(algoliaServiceQuery);
        resolve({ options: this.props.algoliaRecordsToOptions(algoliaServiceQuery.hits, projectsCountById) });
      })
      .catch((err) => reject(err)));
  }

  _fetchProjectsCountByFacetKey() {
    return new Promise((resolve, reject) => {
      if (this.state.projectsCountByIds) return resolve(this.state.projectsCountByIds);

      const facetKey = this.props.algoliaParameters.projectsServiceFacetKey;

      return this.props.algoliaProjectsService.searchWithNoEffects({ facets: [facetKey], hitsPerPage: 0, sort: 'popular' }, [])
        .then((query) => {
          if (this._isMounted) {
            const projectsCountByIds = getInObj(['facets', facetKey], query);
            this.setState({ projectsCountByIds });

            return resolve(projectsCountByIds);
          }

          return resolve({});
        })
        .catch((err) => reject(err));
    });
  }

  render() {
    return (
      <Select
        asyncOpts={{
          initOnMount: true,
          request: this.fetchOptions,
        }}
        hasErrors={this.props.errors && this.props.errors.length > 0}
        maxWidth={this.props.maxWidth}
        onSelectedChange={this.props.onSelect}
        placeholder={this.props.placeholder}
        value={this.props.value}
      />
    );
  }
}

AlgoliaAsyncSelect.propTypes = {
  algoliaParameters: PropTypes.shape({
    hitsPerPage: PropTypes.number,
    initFacet: PropTypes.array,
    projectsServiceFacetKey: PropTypes.string,
  }),
  algoliaProjectsService: PropTypes.object.isRequired,
  algoliaRecordsToOptions: PropTypes.func.isRequired,
  algoliaService: PropTypes.object.isRequired,
  errors: PropTypes.array,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  propagateCurrentQuery: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
};

AlgoliaAsyncSelect.defaultProps = {
  algoliaParameters: {
    hitsPerPage: 100,
    initFacet: [],
    projectsServiceFacetKey: '*',
  },
  maxWidth: '100%',
  placeholder: 'Select an option from the dropdown or type to search',
  propagateCurrentQuery: () => {},
};

export default AlgoliaAsyncSelect;
