import { canUseDOM } from '../../utility/execEnvironment';
import { createVirtualPageview } from '../analytics/google';
import { prependLeadingSlash } from '../../utility/urls';

import keenService from '../keen/main';

export default class SeoHandler {
  constructor({ config }) {
    if (typeof config !== 'function') {
      throw new Error('Invalid config passed to SeoHandler');
    }

    this.config = config;
  }

  reportView({ config = this.config, data = {}, path, referrer }) {
    if (!canUseDOM()) return;

    const pathConfig = config(prependLeadingSlash(path));

    if (typeof pathConfig !== 'function') return;

    const { title, meta_desc, pageType, entity_id, canonical } = pathConfig(data);

    if (title) {
      document.title = title;
    }

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription && meta_desc) {
      metaDescription.content = meta_desc;
    }

    const pageTypeTag = document.querySelector('meta[name="pageType"]');
    if (pageTypeTag && pageType) {
      pageTypeTag.content = pageType;
    }

    if (canonical) {
      const canonicalTag = document.querySelector('link[rel="canonical"]') || document.createElement('link');
      if (!canonicalTag.rel) {
        // The tag is new and needs to be initialized in the DOM.
        canonicalTag.rel = 'canonical';
        document.head.appendChild(canonicalTag);
      }
      canonicalTag.href = canonical;
    }

    keenService.reportVirtualPageView({ pageType, entity_id, referrer });
    createVirtualPageview({ pageType, title });

    return true;
  }
}
