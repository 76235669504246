/** Ad types */
export const BETA = 'BETA';
export const CONTENT_PAGE = 'CONTENT_PAGE';
export const HOME_PAGE_BANNER = 'HOME_PAGE_BANNER';
export const NEWS_GALLERY = 'NEWS_GALLERY';
export const NEWS_PAGE_HERO = 'NEWS_PAGE_HERO';

/** Ad page types */
export const HOME_PAGE = 'HOME_PAGE';
export const NEWS_HOME_PAGE = 'NEWS_HOME_PAGE';

const REGIONS_TO_LABEL_MAP = {
  AFRICA: 'Africa',
  ASIA: 'Asia',
  AUSTRALIA: 'Australia',
  EUROPE: 'Europe',
  NORTH_AMERICA: 'North America',
  SOUTH_AMERICA: 'South America',
  UNITED_STATES: 'United States',
};

export const REGIONS = Object.keys(REGIONS_TO_LABEL_MAP).map((key) => ({
  label: REGIONS_TO_LABEL_MAP[key],
  value: key,
}));

export const adRegionEnumToLabel = (value) => {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!REGIONS_TO_LABEL_MAP.hasOwnProperty(value)) {
    console.warn(`Ad enumToLabel recieved an unknown enum: ${value}.`);

    return null;
  }

  return REGIONS_TO_LABEL_MAP[value];
};

export const adRegionEnumToHumanAbbr = (region) => {
  switch (region) {
    case 'AFRICA':
      return 'AF';
    case 'ASIA':
      return 'AS';
    case 'AUSTRALIA':
      return 'AU';
    case 'EUROPE':
      return 'EU';
    case 'NORTH_AMERICA':
      return 'NA';
    case 'SOUTH_AMERICA':
      return 'SA';
    case 'UNITED_STATES':
      return 'US';
    default:
      return '';
  }
};

export const adTypeEnumToHuman = (adEnum) => {
  switch (adEnum) {
    case BETA:
      return 'Beta';
    case CONTENT_PAGE:
      return 'Content page';
    case HOME_PAGE_BANNER:
      return 'Home page banner';
    case NEWS_GALLERY:
      return 'News gallery';
    case NEWS_PAGE_HERO:
      return 'News page hero';
    default:
      return 'Unknown, contact dev!';
  }
};
