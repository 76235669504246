import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {suppressContentEditableWarning} from 'react-dom';

import Button from './Button';

export default class Tweet extends Component {
  constructor(props) {
    super(props);

    this.handleIFrameLoad = this.handleIFrameLoad.bind(this);
    this.handlePostMessage = this.handlePostMessage.bind(this);

    this.state = { hovered: false, height: 150 };

    this._iframe;
  }

  componentDidMount() {
    window.addEventListener('message', this.handlePostMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handlePostMessage);
  }

  handleIFrameLoad(e) {
    if (this._iframe) {
      const data = this.props.contentState.getEntity(this.props.block.getEntityAt(0)).getData().twitterData;
      this._iframe.contentWindow.postMessage({ element: data.get('id'), query: 'height' }, 'https://embeds.hackster.io');
    }
  }

  handlePostMessage(e) {
    const data = this.props.contentState.getEntity(this.props.block.getEntityAt(0)).getData().twitterData;

    if (e.origin === 'https://embeds.hackster.io' && parseInt(e.data.element, 10) === parseInt(data.get('id'), 10)) {
      const height = parseInt(e.data.height, 10);
      const width = this._iframe.getBoundingClientRect().width;

      this.setState({height: height});

      const twitterData = this.props.contentState.getEntity(this.props.block.getEntityAt(0)).getData().twitterData;

      this.props.blockProps.updateContentState(
        this.props.contentState.mergeEntityData(this.props.block.getEntityAt(0), {twitterData: twitterData.merge({height, width})})
      );
    }
  }

  render() {
    const data = this.props.contentState.getEntity(this.props.block.getEntityAt(0)).getData().twitterData;
    return (
      <div
        className="react-editor-widget-placeholder twitter"
        suppressContentEditableWarning
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        >
        <iframe
          ref={el => this._iframe = el}
          id={data.get('id')}
          frameBorder="0"
          height={this.state.height}
          width="500"
          src={`https://embeds.hackster.io/tweet?url=${data.get('embed')}`}
          onLoad={this.handleIFrameLoad}>
        </iframe>
        {this.state.hovered &&
          (<div style={{ height: this.state.height, width: '100%', position: 'absolute', left: 0 }} className="widget-placeholder-overlay">
            <Button
              buttonStyle={{ height: 48, width: 48 }}
              classList="btn btn-danger"
              icon="fa fa-trash-o"
              tooltip="Delete this tweet"
              onClick={() => this.props.blockProps.remove(this.props.block)}
              />
          </div>)
        }
      </div>
    );
  }
}

Tweet.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: PropTypes.object.isRequired
};