import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import hljs from 'highlight.js';

import ErrorBoundary from '../../wrappers/error_boundary';
import StoryJSONBlock from './StoryJSONBlock';

import { toKebabCaseAlphaNumericOnly } from '../../../utility/strings';

class StoryJSON extends PureComponent {
  constructor(props) {
    super(props);

    this.buildHeaderID = this.buildHeaderID.bind(this);
    this.headerCounter = 0;
  }

  componentDidMount() {
    if (this._root) this._highlightCodeBlocks(this._root);
  }

  /**
   * Methods
   */
  buildHeaderID(title) {
    const id = `toc-${toKebabCaseAlphaNumericOnly(title)}-${this.headerCounter}`;
    this.headerCounter++;

    return id;
  }

  /**
   * Helpers
   */
  // Uses hljs to highlight code in "pre" blocks. since that content is static after rendering,
  // React isn't complaining about hljs messing with the DOM nodes.
  // If we run into problems, we can stringify the text content of the pre blocks,
  // then reparse into html using hljs.highlightAuto.
  // Or we can generate html strings for the code blocks, set it as dangerouslySetInnerHTML,
  // and then run hljs.highlightBlock on that.
  _highlightCodeBlocks(el) {
    [].slice.call(el.querySelectorAll('pre code')).forEach((block) => hljs.highlightBlock(block));
  }

  render() {
    // reset headerCounter so header ids will start at 0 each render. Side effect in render is not the best, but it works
    this.headerCounter = 0;

    const { className, story } = this.props;

    return (
      // TODO: non-rails css?
      <div ref={(el) => this._root = el} className={`${className} project-story hljs-monokai`}>
        {story.map((item, index) => (
          <ErrorBoundary key={index}>
            <StoryJSONBlock buildHeaderID={this.buildHeaderID} index={index} item={item} />
          </ErrorBoundary>
        ))}
      </div>
    );
  }
}

StoryJSON.propTypes = {
  className: PropTypes.string,
  story: PropTypes.array.isRequired,
};

StoryJSON.defaultProps = { className: '' };

export default StoryJSON;
