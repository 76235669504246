const MEDIA_ID_REGEX = {
  autodesk360: /myhub\.autodesk360\.com\/([a-z0-9]+\/shares\/public\/[a-zA-Z0-9]+)/,
  bitbucket: /bitbucket\.org\/([0-9a-zA-Z_\-]+\/[0-9a-zA-Z_\-]+)/,
  circuits: /123d\.circuits\.io\/circuits\/([a-z0-9\-]+)/,
  channel9: /channel9\.msdn\.com\/([0-9a-zA-Z_\-\/]+)/,
  codebender: /codebender\.cc\/sketch:([0-9]+)/,
  embedly: /cdn\.embedly\.com\/widgets\/media\.html\?src=([0-9a-zA-Z_\-%.]+)/,
  fritzing: /fritzing\.org\/projects\/([0-9a-z-]+)/,
  gfycat: /gfycat\.com\/(?:ifr\/)?([a-zA-Z]+)/,
  giphy: /giphy.com\/(?:embed|gifs|media|clips)\/(?:[a-z\-]*\-)?([a-zA-Z0-9]+)/,
  gist: /gist\.github\.com\/(?:[0-9a-zA-Z_\-]+\/)?([0-9a-zA-Z_\-]+)/,
  github: /github\.com\/(?:downloads\/)?([0-9a-zA-Z_\-\.]+\/[0-9a-zA-Z_\-\.]+)/,
  instagram: /instagram\.com\/p\/([a-zA-Z\-0-9]+)/,
  kickstarter: /kickstarter\.com\/projects\/([0-9a-z\-]+\/[0-9a-z\-]+)/,
  oshpark: /oshpark\.com\/shared_projects\/([a-zA-Z0-9]+)/,
  sketchfab: /sketchfab\.com\/models\/([a-z0-9]+)/,
  snip2code: /snip2code\.com\/Snippet\/([0-9]+\/[0-9a-zA-Z]+)/,
  twitter : /twitter.com\/([a-zA-Z0-9_@]+\/status\/[0-9]+)/,
  upverter: /upverter\.com\/[^\/]+\/(?:embed\/)?(?:\#designId\=)?([a-z0-9]+)(?:\/)?(?:[^\/])*/,
  ustream: /ustream\.tv\/([a-z]+\/[0-9]+(\/[a-z]+\/[0-9]+)?)/,
  vimeo: /(?:player\.)?vimeo\.com\/(?:video\/)?([0-9]+)/,
  vine: /vine\.co\/v\/([a-zA-Z0-9]+)/,
  youtube: /(?:youtube\.com|youtu\.be)\/(?:watch\?v=|v\/|embed\/)?([a-zA-Z0-9\-_]+)/,
  youmagine: /youmagine\.com\/designs\/([a-zA-Z0-9\-]+)/,
  mp4: /(.+\.(?:mp4)(?:\?.*)?)$/i
};

export {MEDIA_ID_REGEX};