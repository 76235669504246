import { isAString } from './types';

// use if babel polyfill may not have loaded yet, e.g. in analytics bundle
export function findIndex(arr, pred) {
  if (!Array.isArray(arr) || typeof pred !== 'function') return -1;

  for (let i = 0; i < arr.length; i++) {
    if (pred(arr[i])) return i;
  }

  return -1;
}

// Mostly useful for form elements where errors can come as a string or array of strings
// e.g. ['error1', 'error2'] OR 'error3'
export function getFirstString(arrayOrString) {
  if (isAString(arrayOrString)) return arrayOrString;
  if (Array.isArray(arrayOrString) && isAString(arrayOrString[0])) return arrayOrString[0];

  return null;
}

export function getInObj(path, obj) {
  if (!obj) return null;
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return path.reduce((acc, x) => (acc && acc.hasOwnProperty(x)) ? acc[x] : null, obj);
}

export function getInObjWithPrototypeAccess(path, obj) {
  if (!obj) return null;

  return path.reduce((acc, x) => (acc && (x in acc)) ? acc[x] : null, obj);
}

export function getIdOrUuid(record) {
  return record.id || record.uuid || null;
}

/**
 * Plucks a property from a list of objects.
 * key: the returned value from list[item[key]]
 * a: list[item[a]]
 * b: a value to match the result of a.
 */
export function pluckIfMatch(list, key, a, b) {
  return list.reduce((acc, item) => (item[a] === b) ? acc.concat(item[key]) : acc, []);
}
