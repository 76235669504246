import React from 'react';
import PropTypes from 'prop-types';

import DesktopDateInput from './desktop';
import DesktopNativeInput from '../DesktopNativeInput';
import MobileDateInput from './mobile';

import isDateInputSupported from '../../../../../utility/isDateInputSupported';
import isMobileBrowser from '../../../../../utility/isMobileBrowser';
import { isWindowDefined } from '../../../../../services/window';

// NOTE: DesktopDateInput is meant to be a cross-browser replacement for native date inputs
// on desktop machines. However, it does not currently have a calendar picker feature.
// For now, we will fall back to a native date input where it is supported,
// so that those users will have a calendar picker.
// TODO: custom calendar picker for DesktopDateInput for a consistent cross-browser experience

const DateInput = (props) => {
  if (!isWindowDefined) return null; // dont server render these inputs
  if (isMobileBrowser()) return <MobileDateInput key="date-mobile" {...props} />;
  if (isDateInputSupported()) {
    return (
      <DesktopNativeInput
        key="date-native"
        {...props}
        max="9999-12-31"
        type="date"
      />
    );
  }

  return <DesktopDateInput key="date-desktop" {...props} />;
};

DateInput.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

DateInput.defaultProps = {
  autoComplete: null,
  disabled: false,
  errors: null,
  initValue: '',
};

export default DateInput;
