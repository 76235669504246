import React, { Component } from 'react';
import PropTypes from 'prop-types';

import VideoForm from './video_form';

import { graphMutate } from '../../../requests/graphql';
import { getCategoryEnum } from '../../../graphql/videos/enums';
import { durationToSeconds, secondsToDuration } from '../../../utility/time';
import errorHandler from '../../../services/error_handler';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

class AddOrEditVideoView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      mode: props.mode, // (default || edit)
    };

    this.saveContent = this.saveContent.bind(this);
  }

  /**
   * Methods
   */
  saveContent({ data }) {
    const video = {
      category: getCategoryEnum(data.category),
      description: data.description,
      duration: durationToSeconds(data.duration),
      image_id: data.image.id,
      part_ids: data.products.map((p) => p.value),
      platform_ids: data.platforms.map((p) => p.value),
      tag_ids: data.tags.map((t) => t.value),
      title: data.title,
      topic_ids: data.topics.map((t) => t.value),
      url: data.url,
    };

    this._setIsBusy(true);

    return this.state.mode === 'default' ? this._postVideo(video, data) : this._updateVideo(video, data);
  }

  _postVideo(video, data) {
    return graphMutate({ t: 'create_video' }, video)
      .then(({ record }) => {
        this._setIsBusy(false);
        this.props.propagateNewRecord({
          ...data,
          category: data.category,
          created_at: Date.now(),
          duration: video.duration,
          id: parseInt(record.id),
        });
      })
      .catch((err) => {
        this._setIsBusy(false);
        errorHandler('AddOrEditVideoView _postVideo: ', err);
      });
  }

  _updateVideo(video, data) {
    return graphMutate({ t: 'update_video' }, { ...video, id: this.props.initRecord.id })
      .then(({ record }) => {
        this._setIsBusy(false);
        this.props.propagateEditedRecord({
          ...data,
          created_at: this.props.initRecord.created_at,
          duration: video.duration,
          id: parseInt(record.id),
        });
      })
      .catch((err) => {
        this._setIsBusy(false);
        errorHandler('AddOrEditVideoView _updateVideo: ', err);
      });
  }

  /**
   * Helpers
   */
  _getInitDataForForm() {
    if (this.props.mode === 'default') return null;

    return {
      data: {
        ...this.props.initRecord,
        duration: secondsToDuration(this.props.initRecord.duration),
      },
      index: null,
    };
  }

  _setIsBusy(isBusy) {
    this.props.propagateBusyStatus(isBusy);
    this.setState({ isBusy });
  }

  /**
   * Views
   */
  _getTitleForMode() {
    return this.props.mode === 'default' ? 'Add video' : 'Edit video';
  }

  render() {
    return (
      <div>
        <h1 className={`${typography.h1} ${layout.marginBottom60}`}>{this._getTitleForMode()}</h1>

        <VideoForm
          algoliaPartsService={this.props.algoliaPartsService}
          algoliaPlatformsService={this.props.algoliaPlatformsService}
          algoliaTagsService={this.props.algoliaTagsService}
          algoliaTopicsService={this.props.algoliaTopicsService}
          dismiss={this.props.dismiss}
          initData={this._getInitDataForForm()}
          isBusy={this.state.isBusy}
          mode={this.state.mode}
          saveContent={this.saveContent}
        />
      </div>
    );
  }
}

AddOrEditVideoView.propTypes = {
  algoliaPartsService: PropTypes.object.isRequired,
  algoliaPlatformsService: PropTypes.object.isRequired,
  algoliaTagsService: PropTypes.object.isRequired,
  algoliaTopicsService: PropTypes.object.isRequired,
  dismiss: PropTypes.func.isRequired,
  initRecord: PropTypes.shape({
    category: PropTypes.string.isRequired,
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    description: PropTypes.string.isRequired,
    duration: PropTypes.integer,
    id: PropTypes.number,
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })),
    tag_ids: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })),
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  mode: PropTypes.string,
  propagateBusyStatus: PropTypes.func,
  propagateEditedRecord: PropTypes.func.isRequired,
  propagateNewRecord: PropTypes.func.isRequired,
};

AddOrEditVideoView.defaultProps = {
  mode: 'default',
  propagateBusyStatus: () => {},
};

export default AddOrEditVideoView;
