import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Discussion from '../../../client/discussion';
import { getFlaggedIds, setFlaggedIds } from '../../../client/discussion/transactions/flags';

import createHistory from '../../../client/reusable_components/Router/history';

import keenService from '../../../services/keen/main';

import { createCategoryConfigs } from './categoryConfig';

const NAVBAR_ID = 'challengeNav';
const STICKY_BUFFER = 100;

class ContestDiscussion extends PureComponent {
  constructor(props) {
    super(props);

    const flaggedIds = getFlaggedIds((key) => []);

    this.history = createHistory(props.pathHelpers.basePath);
    this.state = {
      ...flaggedIds,
      currentHistoryData: this.history.location,
    };

    this.handleLocationChange = this.handleLocationChange.bind(this);

    // bound transaciton
    this.setFlaggedIds = setFlaggedIds.bind(this);

    // Category opts
    const { CATEGORY_CONFIGS, SELECT_CATEGORIES } = createCategoryConfigs(props.origin);
    this._CATEGORY_CONFIGS = CATEGORY_CONFIGS;
    this._SELECT_CATEGORIES = SELECT_CATEGORIES;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this.unlisten = this.history.listen(this.handleLocationChange);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleLocationChange(pathData, action) {
    this.setState({ currentHistoryData: { ...pathData, action } }, () => this._firePageViewAnalytics());
  }

  _firePageViewAnalytics() {
    keenService.reportVirtualPageView({ entity_id: this.props.origin.id });
  }

  render() {
    const flaggedIds = getFlaggedIds((key) => this.state[key]);

    return (
      <Discussion
        key={this.history.location.key} // Important: this remounts the component on navigation, forcing reinitialization
        categoryConfigs={this._CATEGORY_CONFIGS}
        currentHistoryData={this.state.currentHistoryData}
        currentUser={this.props.currentUser}
        isMember={this.props.currentUser.is_registered_to_challenge}
        origin={this.props.origin}
        pathHelpers={this.props.pathHelpers}
        selectCategories={this._SELECT_CATEGORIES}
        setFlaggedIds={this.setFlaggedIds}
        stickyBuffer={STICKY_BUFFER}
        topAnchorId={NAVBAR_ID}
        transition={(historyData) => this.history.push(historyData)}
        {...flaggedIds}
      />
    );
  }
}

ContestDiscussion.propTypes = {
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string.isRequired,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  origin: PropTypes.shape({ // Challenge
    admin_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    activate_free_hardware: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  pathHelpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContestDiscussion;
