import viewAllStyles from '../../../../client/wrappers/view_all/view_all.css';

export const projectProps = {
  cardBorder: true,
  classList: { wrapper: viewAllStyles.scrollerItemProject },
  usePortal: true,
};

export const dummyProjectProps = {
  cardBorder: true,
  classList: {
    card: viewAllStyles.dummyProjectCard,
    wrapper: viewAllStyles.scrollerItemProject,
  },
};
