import { getLazyFilterPromise } from '../../lazy_filters';
import { reflectPromise } from '../../../../utility/promises';

function buildToolbox(ids = []) {
  if (!ids.length) return ['parts_for_channels:[]'];

  return ids.map((id) => `parts_for_channels:${id}`);
}

const KNOWN_LAZY_PARAMS = {
  by: (property) => property === 'toolbox',
  part_id: (property) => property === 'me',
};

const PARAM_TO_LAZY_FILTER_MAP = {
  by: { user: 'part_ids' },
  part_id: { user: 'part_ids' },
};

const FACET_FILTER_BUILDERS = {
  by: buildToolbox,
  part_id: buildToolbox,
};

function getPromises(queryMap, keys) {
  return keys.reduce((acc, key, i) => {
    if (KNOWN_LAZY_PARAMS[key] && KNOWN_LAZY_PARAMS[key](queryMap[key])) {
      acc.promiseIndexToPropertyMap.push(key);
      acc.promises.push(
        getLazyFilterPromise(PARAM_TO_LAZY_FILTER_MAP[key])(), // We want to invoke the wrapper fn for the promise.
      );
    }

    return acc;
  }, { promises: [], promiseIndexToPropertyMap: [] });
}

export default function fetchDataForLazyFilterParameters(queryMap = {}) {
  return new Promise((resolve, reject) => {
    const keys = Object.keys(queryMap);
    if (!keys.length) return resolve([]);

    const { promises, promiseIndexToPropertyMap } = getPromises(queryMap, keys);

    return Promise.all(promises.map(reflectPromise))
      .then((results) => {
        resolve(
          results.reduce((acc, result, i) => {
            if (result.status === 'resolved' && result.data) {
              const property = promiseIndexToPropertyMap[i];
              acc = [...acc, ...FACET_FILTER_BUILDERS[property](result.data)];
            }

            return acc;
          }, []),
        );
      })
      .catch((err) => reject(err));
  });
}
