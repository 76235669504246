import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../buttons/base';
import Dialog from '../../../reusable_components/Dialog';
import ProgressBar from './progress_bar';

import { ENTER } from '../../../../constants/keyCodes';
import { getInObj } from '../../../../utility/accessors';
import { isNumber } from '../../../../utility/types';
import { objHasPropertyOfLength } from '../../../../utility/predicates';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import inputStyles from '../../../../styles/global_ui/inputs.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './basic_image_uploader.css';

/**
 * Notes:
 * If you're using this in a form following the standard 2018 UIKit guidelines
 * (https://projects.invisionapp.com/d/main#/console/11627398/298944989/preview),
 * use image_uploaders/form_image_uploader.
 */
class BasicUploaderView extends Component {
  constructor(props) {
    super(props);

    this.state = { showDialog: false };

    this.dismissDialog = this.dismissDialog.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleUploadBtnClick = this.handleUploadBtnClick.bind(this);
    this.handleURLSubmit = this.handleURLSubmit.bind(this);
  }

  /* Methods */
  dismissDialog() {
    if (this.state.showDialog) this.setState({ showDialog: false });
  }

  handleInputKeyDown(e) {
    if (e.keyCode === ENTER) {
      e.preventDefault();
      e.stopPropagation();
      this.handleURLSubmit(e);
    }
  }

  handleUploadBtnClick(e) {
    this.dismissDialog();
    this.props.handleUploadBtnClick(e);
  }

  handleURLSubmit(e) {
    this.dismissDialog();
    this.props.handleURLSubmit(e);
  }

  /**
   * Helpers
   */
  _hasImageURL() {
    return objHasPropertyOfLength(this.props.imageData, 'url');
  }

  /**
   * Views
   */
  _getAfterUploadView() {
    return (
      <div>
        <Button
          className={layout.marginRight15}
          onClick={() => this.setState({ showDialog: true })}
          size="lg"
        >
          Change
        </Button>
        <Button onClick={this.props.handleDelete} size="lg">Remove</Button>
      </div>
    );
  }

  _getBeforeUploadView() {
    return this.props.isBusy ? <ProgressBar /> : this._getUploadBtns();
  }

  _getBtnView() {
    return this._hasImageURL() ? this._getAfterUploadView() : this._getBeforeUploadView();
  }

  _getURLInputView() {
    return (
      <Fragment>
        <div className={`${typography.bodyM} ${styles.or}`}>or</div>
        <div className={`${styles.urlInputWrapper} ${layout.flex1}`}>
          <input
            className={`${inputStyles.input} ${inputStyles.openRight} ${this.props.inputClassName}`}
            onChange={this.props.handleURLChange}
            onKeyDown={this.handleInputKeyDown}
            type="text"
            value={this.props.remoteURL}
          />
          <Button
            className={`${layout.flex10Auto} ${buttonStyles.flatLeft}`}
            disabled={this.props.remoteURL.length === 0}
            onClick={this.handleURLSubmit}
            size="lg"
          >
            Grab from URL
          </Button>
        </div>
      </Fragment>
    );
  }

  _getDisplayWidth() {
    const { dimensionMins, imageData } = this.props;
    const imageWidth = getInObj(['dimensions', 'width'], imageData);

    if (isNumber(imageWidth) && isNumber(dimensionMins.width)) return Math.min(imageWidth, dimensionMins.width);

    return imageWidth || dimensionMins.width || '100%';
  }

  _getPreview() {
    const { aspectRatio, classList, imageData, placeholderUrl } = this.props;
    const src = this._hasImageURL() ? imageData.url : placeholderUrl;
    if (!src) return null;

    const alt = imageData.name || '';

    return (
      <div
        key={src} // so state change from placeholder to image background is displayed, making it clear that something is happening
        className={`${styles.previewWrapper} ${classList.previewWrapper}`}
        style={{ width: this._getDisplayWidth() }}
      >
        {aspectRatio ? this._getPreviewWithAspectRatio(src, alt) : this._getPreviewWithoutAspectRatio(src, alt)}
      </div>
    );
  }

  _getPreviewWithAspectRatio(src, alt) {
    return (
      <div style={{ paddingTop: `${(1 / this.props.aspectRatio) * 100}%` }}>
        <img alt={alt} className={utilStyles.absolutePlaceholderChild} src={src} />
      </div>
    );
  }

  _getPreviewWithoutAspectRatio(src, alt) {
    return (<img alt={alt} className={layout.maxWidth100P} src={src} />);
  }

  _getUploadBtns(inDialog = false) {
    return (
      <div className={(!inDialog && this.props.stackedView) ? null : styles.responsive}>
        <div className={`${layout.flexCenterItems} ${styles.buttons} ${this.props.classList?.inputButtonsWrapper ? this.props.classList?.inputButtonsWrapper : ''}`}>
          <Button className={styles.fileButton} onClick={this.handleUploadBtnClick} size="lg">
            Choose file
          </Button>
          {this.props.allowRemoteURL && this._getURLInputView()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.showPreview && this._getPreview()}
        {this._getBtnView()}
        <Dialog
          dismiss={this.dismissDialog}
          nestedDialogLevel={this.props.nestedDialogLevel}
          open={this.state.showDialog}
          title={<h2 className={`${typography.h2} ${typography.textCenter} ${layout.marginBottom30}`}>Change image</h2>}
        >
          {this.state.showDialog && this._getUploadBtns(true)}
        </Dialog>
      </div>
    );
  }
}

BasicUploaderView.propTypes = {
  allowRemoteURL: PropTypes.bool,
  aspectRatio: PropTypes.number,
  classList: PropTypes.shape({
    button: PropTypes.string,
    cancelButton: PropTypes.string,
    previewWrapper: PropTypes.string,
  }),
  dimensionsMins: PropTypes.shape({ width: PropTypes.number }),
  handleURLChange: PropTypes.func.isRequired,
  handleURLSubmit: PropTypes.func.isRequired,
  handleUploadBtnClick: PropTypes.func.isRequired,
  imageData: PropTypes.shape({
    dimensions: PropTypes.shape({ width: PropTypes.number }),
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  inputClassName: PropTypes.string, // if outside code (e.g. project editor) is looking for form element changes, setting this className can serve as a flag for that code to ignore these elements, since a changing them does not necessarily mean a change in the uploaded image
  nestedDialogLevel: PropTypes.number,
  placeholderUrl: PropTypes.string,
  remoteURL: PropTypes.string,
  showPreview: PropTypes.bool,
  stackedView: PropTypes.bool,
};

BasicUploaderView.defaultProps = {
  allowRemoteURL: true,
  aspectRatio: null,
  classList: {
    button: '',
    cancelButton: '',
    previewWrapper: '',
  },
  dimensionMins: {},
  imageData: {},
  inputClassName: '',
  nestedDialogLevel: 0,
  placeholderUrl: null,
  remoteURL: '',
  showPreview: true,
  stackedView: false,
};

export default BasicUploaderView;
