import React from 'react';
import PropTypes from 'prop-types';

import DummyBaseCardText from '../DummyBaseCardText';

import { paddingClassForRatio } from '../baseCardHelpers';

import layout from '../../../../styles/global_ui/layout.css';
import animation from '../../../../styles/animation.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './base_card_vertical.css';

const DummyBaseCardVertical = ({ className, imageAspectRatio, responsive }) => (
  <div className={`${responsive ? styles.cardResponsive : ''} ${layout.flexColumn} ${layout.fullWidth} ${className}`}>
    <div className={`${responsive ? styles.imageResponsive : ''} ${layout.fullWidth} ${utilStyles.absolutePlaceholderParent} ${paddingClassForRatio(imageAspectRatio)}`}>
      <div className={`${animation.loader} ${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}`} />
    </div>
    <div className={`${responsive ? styles.textResponsive : ''} ${layout.marginTop22}`}>
      <DummyBaseCardText />
    </div>
  </div>
);

DummyBaseCardVertical.propTypes = {
  className: PropTypes.string,
  imageAspectRatio: PropTypes.string,
  responsive: PropTypes.bool,
};

DummyBaseCardVertical.defaultProps = {
  className: '',
  imageAspectRatio: '4:3',
  responsive: false,
};

export default DummyBaseCardVertical;
