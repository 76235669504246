import React, { Component } from 'react';
import PropTypes from 'prop-types';

import throttle from 'lodash.throttle';

import RingSpinner from '../../spinners/ring';

import { windowInnerHeight } from '../../../services/window';
import errorHandler from '../../../services/error_handler';

import initStyles from './infinite_scroll.css';

class InfiniteScroll extends Component {
  constructor(props) {
    super(props);

    this.checkConditionsAndFetch = this.checkConditionsAndFetch.bind(this);
    this.fetchMore = this.fetchMore.bind(this);

    this.state = { loading: false };

    // refs
    this._containerMount;
    this._itemsContainer;

    // Protects against a potential SO in fetchMore's catch block.
    this._errorCounter = 0;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._containerMount = this.props.mountInContainer ? (document.querySelector(this.props.mountInContainer.querySelector) || window) : window;
    this.throttledScroll = throttle(this.checkConditionsAndFetch, 100);
    this._containerMount.addEventListener('scroll', this.throttledScroll);

    this.checkConditionsAndFetch();
  }

  componentWillUnmount() {
    this._containerMount.removeEventListener('scroll', this.throttledScroll);
  }

  /**
   * Methods
   */
  checkConditionsAndFetch() {
    return this._shouldLoadMore() ? this.fetchMore() : Promise.resolve();
  }

  fetchMore() {
    this.setState({ loading: true });

    return this.props.fetchMore()
      .then(() => {
        this.setState({ loading: false }, () => this._errorCounter = 0);
        this.checkConditionsAndFetch(); // if there's still room on the page, fill it
      })
      .catch((err) => {
        errorHandler('Scroll Error', err);
        this.setState({ loading: false });
        this._errorCounter += 1;
      });
  }

  /**
   * Helpers
   */
  _isThereRoomOnScreen() {
    if (!this._itemsContainer) return false;
    const containerBottom = this._itemsContainer.getBoundingClientRect().bottom;

    return (windowInnerHeight() + this.props.buffer) > containerBottom;
  }

  _shouldLoadMore() {
    return (
      !this.state.loading
      && this._errorCounter <= 5
      && this.props.recordsCount < this.props.totalRecordsCount
      && this._isThereRoomOnScreen()
    );
  }

  /**
   * Views
   */
  _getDefaultLoader(styles) {
    return (
      <div className={styles.loader}>
        <RingSpinner size="" />
        {' '}
        {/* blank size prop sets to 1em */}
      </div>
    );
  }

  _getLoader(styles) {
    return this.props.renderLoader ? this.props.renderLoader(styles) : this._getDefaultLoader(styles);
  }

  render() {
    const styles = { ...initStyles, ...this.props.classList };

    return (
      <div ref={(el) => this._itemsContainer = el} className={styles.container}>
        {this.props.children}
        {this.state.loading && this._getLoader(styles)}
      </div>
    );
  }
}

InfiniteScroll.propTypes = {
  buffer: PropTypes.number,
  classList: PropTypes.shape({
    container: PropTypes.string,
    loader: PropTypes.string,
  }),
  fetchMore: PropTypes.func.isRequired,
  mountInContainer: PropTypes.shape({ querySelector: PropTypes.string.isRequired }),
  recordsCount: PropTypes.number.isRequired,
  renderLoader: PropTypes.func,
  totalRecordsCount: PropTypes.number.isRequired,
};

InfiniteScroll.defaultProps = {
  buffer: 200,
  classList: {},
  mountInContainer: null,
  renderLoader: null,
};

export default InfiniteScroll;
