import React from 'react';
import {DefaultDraftBlockRenderMap} from 'draft-js';
import {Map} from 'immutable';

const customBlockMap = Map({
  CAROUSEL: {
    element: 'div'
  },
  IMAGE_LINK: {
    element: 'div'
  },
  EMBED: {
    element: 'div'
  },
  LEGACY_WIDGET: {
    element: 'div'
  },
  TWEET: {
    element: 'div'
  },
 'code-block': {
    element: 'code',
    wrapper: <pre className='public-DraftStyleDefault-pre' />
 },
 'paragraph': {
    element: 'div'
  }
});

export default function customBlockRenderMap() {
  return DefaultDraftBlockRenderMap.merge(customBlockMap);
}