import React from 'react';
import PropTypes from 'prop-types';

import UsersMenu from '../../project_card/UsersMenu';

import arrayToSentence from '../../../../client/utility_components/arrayToSentence';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

/**
 * Views
 */
const SponsorsMenuView = ({ classList, sponsors, sponsorsConfig }) => (
  <div className={`${classList.typography} ${layout.flex}`}>
    <span style={{ marginRight: 5 }}>With</span>
    <UsersMenu classList={{ label: typography.linkBlue }} teamName="Sponsors" users={sponsors} {...sponsorsConfig.menuPos} />
  </div>
);

const SponsorLinkView = (sponsor) => (
  <a className={typography.linkBlue} href={sponsor.url}>
    {sponsor.name}
  </a>
);

/**
 * Main component
 */
const ContestSponsorsList = ({ classList, sponsors, sponsorsConfig }) => {
  if (!sponsors.length) return null;
  if (sponsors.length > sponsorsConfig.limit) {
    return (<SponsorsMenuView classList={classList} sponsors={sponsors} sponsorsConfig={sponsorsConfig} />);
  }

  return (
    <div className={`${classList.root} ${classList.typography}`}>
      <span>{'With '}</span>
      <span>{arrayToSentence(sponsors.map(SponsorLinkView))}</span>
    </div>
  );
};

ContestSponsorsList.propTypes = {
  classList: PropTypes.shape({
    root: PropTypes.string,
    typography: PropTypes.string,
  }),
  sponsors: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
  sponsorsConfig: PropTypes.shape({
    limit: PropTypes.number,
    menuPos: PropTypes.shape({
      alignRight: PropTypes.bool,
      alignTop: PropTypes.bool,
    }),
  }),
};

ContestSponsorsList.defaultProps = {
  classList: {
    root: '',
    typography: typography.bodyS,
  },
  sponsorsConfig: {
    limit: 5,
    menuPos: {
      alignRight: false,
      alignTop: true,
    },
  },
};

export default ContestSponsorsList;
