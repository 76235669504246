import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../icon';
import { isBlank } from '../../../../utility/types';

import inputStyles from '../../../../styles/global_ui/inputs.css';
import styles from './basic_search_input.css';

class BasicSearchInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      recentlyEmptied: false,
      value: !isBlank(props.initVal) ? props.initVal : '',
    };

    this.handleKeydown = this.handleKeydown.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.enableHistory) {
      this._resetValue(prevProps);
    }
  }

  onChange(e) {
    const value = e.target.value;
    const recentlyEmptied = this.state.value.length > 0 && value.length === 0;

    this.setState({
      recentlyEmptied,
      value,
    });
  }

  onClear() {
    this.setState({ value: '' }, () => this.props.onEnter(''));
  }

  onEnter() {
    if ((this.state.recentlyEmptied && !this.state.value.length) || this.state.value.length > 0) {
      this.props.onEnter(this.state.value);

      if (this.state.recentlyEmptied) this.setState({ recentlyEmptied: false });
    }
  }

  handleKeydown(e) {
    if (e.keyCode === 13 /* ENTER */) {
      this.onEnter();
    }
  }

  // To keep search value consistent with URL when going back/forth in history
  // The conditions are to prevent wasteful renders if search value has not changed
  _resetValue(prevProps) {
    if ((this.props.initVal !== prevProps.initVal) && (this.props.initVal !== this.state.value)) {
      this.setState({ value: this.props.initVal || '' });
    }
  }

  _shouldRenderX() {
    return this.props.enableX && this.state.value.length > 0;
  }

  render() {
    return (
      <div className={`${this.props.classList.wrapper} ${styles.wrapper} ${inputStyles.input} ${this.state.focused ? styles.focused : ''}`}>
        <Icon name="search" onClick={this.dispatchSearch} />
        <input
          className={styles.input}
          onBlur={() => this.setState({ focused: false })}
          onChange={this.onChange}
          onFocus={() => this.setState({ focused: true })}
          onKeyDown={this.handleKeydown}
          placeholder={this.props.placeholder}
          type="text"
          value={this.state.value}
        />
        {this._shouldRenderX() && <Icon name="close" onClick={this.onClear} size={12} />}
      </div>
    );
  }
}

BasicSearchInput.propTypes = {
  classList: PropTypes.shape({ wrapper: PropTypes.string }),
  enableHistory: PropTypes.bool,
  enableX: PropTypes.bool,
  initVal: PropTypes.string,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
};

BasicSearchInput.defaultProps = {
  classList: { wrapper: '' },
  enableHistory: false,
  enableX: false,
  initVal: null,
  onEnter: () => {},
  placeholder: '',
};

export default BasicSearchInput;
