import React, { Component } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash.debounce';
import deepEqual from 'deep-equal';

import GlobalPortal from '../../portals/global_portal';

import { windowPageXOffset, windowPageYOffset } from '../../../services/window';
import { isDomElement } from '../../../utility/document';

// This is used to "break out" of a scrollable container while still following
// the position of a child of that container. Only use this if it is completely
// necessary, and will not have many instances mounted at once.
class GlobalPortalPositioner extends Component {
  constructor(props) {
    super(props);

    this.state = { position: this._getPosition() };

    this.handleChange = this.handleChange.bind(this);
    this.handleResize = debounce(this.handleChange, 200);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleChange, true);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleChange, true);
    window.removeEventListener('resize', this.handleResize);
  }

  handleChange(e) {
    const position = this._getPosition();
    if (!deepEqual(position, this.state.position)) this.setState({ position });
  }

  /**
   * Helpers
   */

  // For now, this uses a static wrapper width, and only positions to the left or right
  // Extend as necessary with dynamic calculations or top/bottom positioning
  _getPosition() {
    const target = this.props.getTarget();
    if (!isDomElement(target)) return null;

    const rect = target.getBoundingClientRect();

    const top = rect.top + (rect.height / 2) + windowPageYOffset();
    const tryLeft = rect.left + windowPageXOffset() - this.props.width;
    const left = tryLeft > 0 ? tryLeft : rect.right + windowPageXOffset();

    return { left, top };
  }

  _getStyle() {
    if (!this.state.position) return { position: 'absolute', visibility: 'hidden' };

    return { position: 'absolute', width: this.props.width, ...this.state.position };
  }

  render() {
    return (
      <GlobalPortal>
        <div style={this._getStyle()}>
          {this.props.children}
        </div>
      </GlobalPortal>
    );
  }
}

GlobalPortalPositioner.propTypes = {
  getTarget: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
};

GlobalPortalPositioner.defaultProps = {};

export default GlobalPortalPositioner;
