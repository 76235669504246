import currentUserService from '../../current_user';
import userRelationsStore from '../../user_relations_store';

const noop = () => Promise.resolve();

/**
 * Promises
 */
const fetchCurrentUserParts = () => userRelationsStore.fetchResource('part_ids');

const promises = { user: { part_ids: fetchCurrentUserParts } };

export function getLazyFilterPromise(filterTypeObject) {
  const key = Object.keys(filterTypeObject)[0];

  return promises[key] && promises[key][filterTypeObject[key]] ? promises[key][filterTypeObject[key]] : noop;
}

/**
 * Dependencies
 */

const dependencies = { user: () => currentUserService.getAsync('id') };

export function hasDependency(filterTypeObject) {
  const key = Object.keys(filterTypeObject)[0];
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return dependencies.hasOwnProperty(key);
}

export function getDependency(filterTypeObject) {
  const key = Object.keys(filterTypeObject)[0];

  return dependencies[key] ? dependencies[key] : noop;
}
