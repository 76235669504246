import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MediaElement from '../../../client/image/MediaElement';
import { IMG, VIDEO } from '../../../client/image/helpers';

import animation from '../../../styles/animation.css';
import styles from './image_carousel.css';

class CarouselImage extends Component {
  constructor(props) {
    super(props);

    this.onImageLoad = this.onImageLoad.bind(this);
    this.onError = this.onError.bind(this);

    this.state = {
      hasLoaded: props.hasLoaded,
      format: props.format,
    };

    this._isMounted;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onError(err) {
    // try falling back to img tag
    if (this._isMounted && this.state.format === VIDEO) {
      this.setState({ format: IMG });
    }
  }

  onImageLoad() {
    if (!this._isMounted) return;

    this.setState({ hasLoaded: true }, () => {
      if (this.props.shouldHandleImgLoad) this.props.handleImgLoad();
    });
  }

  render() {
    return (
      <div className={styles.imageContainer}>
        <div className={styles.imageWrapper}>
          {!this.state.hasLoaded
          && (
            <div className={`${animation.delayAppear} ${styles.loader}`}>
              <i className="fa fa-circle-o-notch fa-spin"></i>
            </div>
          )}
          {!!this.props.shouldLoad
          && (
            <MediaElement
              alt={this.props.alt}
              className={`${styles.image} ${this.state.hasLoaded ? '' : styles.imageLoading} ${this.props.className}`}
              format={this.state.format}
              onClick={this.props.onClick}
              onError={this.onError}
              onLoad={this.onImageLoad}
              src={this.props.src}
            />
          )}
        </div>
      </div>
    );
  }
}

CarouselImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  format: PropTypes.string.isRequired,
  handleImgLoad: PropTypes.func,
  hasLoaded: PropTypes.bool,
  onClick: PropTypes.func,
  shouldHandleImgLoad: PropTypes.bool,
  shouldLoad: PropTypes.bool,
  src: PropTypes.string.isRequired,
};

CarouselImage.defaultProps = {
  alt: '',
  className: '',
  handleImgLoad: () => {},
  hasLoaded: false,
  onClick: () => {},
  shouldHandleImgLoad: false,
  shouldLoad: false,
};

export default CarouselImage;
