import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './toolbar_button.css';

class ToolbarButton extends Component {
  constructor(props) {
    super(props);

    this.state = {showOptions: false};

    this.closeOptionsOnWindowClick = this.closeOptionsOnWindowClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);

    // Refs
    this.root;
  }

  componentWillUnmount() {
    if (window && this.props.options.length) {
      window.removeEventListener('click', this.closeOptionsOnWindowClick);
    }
  }

  closeOptionsOnWindowClick(e) {
    if (this.root && this.root.contains(e.target)) return;
    this.handleClick(e);
  }

  handleClick(e) {
    e.preventDefault();

    if (this.props.options.length > 0) {
      this._handleGlobalClickEvents();
      this.setState({showOptions: !this.state.showOptions});
    } else {
      this.props.onClick(this.props.tag);
    }
  }

  _handleGlobalClickEvents() {
    if (!this.state.showOptions) {
      window.addEventListener('click', this.closeOptionsOnWindowClick);
    } else {
      window.removeEventListener('click', this.closeOptionsOnWindowClick);
    }
  }

  handleOptionClick(e, option) {
    this.handleClick(e);
    this.props.onClick(this.props.tag, option);
  }

  /**
   * Helpers
   */
  _shouldRenderTooltip() {
    return this.props.tooltipLabel && this.props.tooltipLabel.length > 0;
  }

  render() {
    const {icon, isActive, options} = this.props;

    return (
      <button
        ref={el => this.root = el}
        className={isActive ? `${styles.button} ${styles.active} toolbar-btn` : `${styles.button} toolbar-btn`}
        onClick={this.handleClick}
        >
        {React.createElement(icon)}
        {options.length > 0 && this.state.showOptions &&
          <div className={styles.menu}>
            {options.map((option, index) => (
              <div
                key={index}
                className={styles.option}
                onClick={e => this.handleOptionClick(e, option)}
                >
                {option}
              </div>
            ))}
          </div>
        }
        {this._shouldRenderTooltip() &&
          <span className={`${styles.tooltip} toolbar-btn-tooltip`}>
            <span className={`${styles.caret} toolbar-btn-tooltip-caret`}></span>
            <span className={`${styles.label} toolbar-btn-tooltip-label`}>{this.props.tooltipLabel}</span>
          </span>
        }
      </button>
    );
  }
}

ToolbarButton.propTypes = {
  icon: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.array, // List of tags (e.g. 'Carousel', 'Link')
  tag: PropTypes.string.isRequired,
  tooltipLabel: PropTypes.string
};

ToolbarButton.defaultProps = {
  options: [],
  tooltipLabel: null
};

export default ToolbarButton;