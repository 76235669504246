import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import generateRandomKey from '../../../../utility/generateRandomKey';

const MIN_HEIGHT = 93;

class AutosizeTextarea extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      height: '100%',
      id: props.id ? props.id : generateRandomKey(),
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    this.__focusTextAreaHook = this.__focusTextAreaHook.bind(this);

    // Ref
    this._textarea;
  }

  componentDidMount() {
    this._resize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) this._resize();
  }

  /**
   * Hooks
   */
  __focusTextAreaHook(start, end) {
    if (start && end) {
      this._resize();
      this._textarea.setSelectionRange(start, end);
    }
    this._textarea.focus();
  }

  /**
   * Methods
   */
  onChange(e) {
    this.props.onChange(e);
    // Setting the height to an empty string lets the next calculation grab the actual height of the newly rendered
    // textarea. This lets us resize when decreasing content.
    this.setState({ height: '' }, () => this._resize());
  }

  onKeyDown(e) {
    const undoHotkeys = (e.ctrlKey && e.keyCode === 90) || (e.metaKey && e.keyCode === 90);
    if (this.props.disableUndo && undoHotkeys) e.preventDefault();
  }

  /**
   * Helpers
   */
  _resize() {
    if (this._textarea) {
      const textarea = this._textarea;
      const computed = window.getComputedStyle(textarea);
      // When box-sizing is content-box, we need to subtract the padding from scrollHeight.
      const heightOffset = computed.boxSizing === 'content-box'
        ? -(parseFloat(computed.paddingTop) + parseFloat(computed.paddingBottom))
        : 0;
      const toHeight = (heightOffset + textarea.scrollHeight);
      const height = (toHeight === 0 && this.props.enforceMinHeight) ? MIN_HEIGHT : toHeight;
      this.setState({ height });
    }
  }

  render() {
    return (
      <textarea
        ref={(el) => this._textarea = el}
        autoFocus={this.props.autoFocus}
        className={this.props.className}
        disabled={this.props.disabled}
        id={this.state.id}
        name={this.props.name}
        onBlur={this.props.onBlur}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        placeholder={this.props.placeholder}
        rows={this.props.minRows}
        style={{ height: this.state.height }}
        value={this.props.value}
      />
    );
  }
}

AutosizeTextarea.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disableUndo: PropTypes.bool,
  disabled: PropTypes.bool,
  enforceMinHeight: PropTypes.bool,
  errors: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minRows: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
};

AutosizeTextarea.defaultProps = {
  autoFocus: false,
  className: '',
  disableUndo: false,
  disabled: false,
  enforceMinHeight: true,
  errors: null,
  id: null,
  minRows: '3',
  name: null,
  onBlur: () => {},
  placeholder: '',
};

export default AutosizeTextarea;
