import isCrawler from '../utility/isCrawler';
import request from 'superagent';
import OAuth from '../services/oauth';

function getReferrer() {
  if (typeof document === 'undefined') return null;

  return document.referrer || document.origin; // mirroring insert_stats in application_helper.rb
}

function createImpression({
  a = null,
  c = null,
  id = null,
  referrer = getReferrer(),
  type = null,
} = {}) {
  if (isCrawler()) return Promise.resolve();

  const data = { a, c, id, referrer, type };

  return OAuth.apiRequest(
    request.post(`${OAuth.getApiPath()}/private/stats`).send(data),
    false,
    true,
    false,
  );
}

export default createImpression;
