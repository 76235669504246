const customBlockMap = {
  'carousel': 'CAROUSEL',
  'code': 'code-block',
  'linkedImage': 'IMAGE_LINK',
  'header': 'header'
};

const defaultOptions = {
  'carousel': true,
  'code': true,
  'linkedImage': false,
  'header': true
};

export default function setupBlockRestrictions(options) {
  const list = {...defaultOptions, ...options};

  return Object.keys(list).reduce((acc, key) => {
    return list[key] === false ? acc.concat(customBlockMap[key] || key) : acc;
  }, []);
}