import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ActionableButton from '../../buttons/actionable';
import Button from '../../buttons/base';
import DraftEditor from '../post_editor/draft_editor';

import { maxLength } from '../../../services/validation/validators';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import inputStyles from '../../../styles/global_ui/inputs.css';
import layout from '../../../styles/global_ui/layout.css';
import postEditorStyles from '../post_editor/post_editor.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './post_card_comments.css';

const COMMENT_MAX_LEN = 1600;

class PostCardCommentInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editorText: '',
      editorToolbar: (<div></div>),
      renderEditor: props.mode !== 'default',
    };

    this.activateEditor = this.activateEditor.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetState = this.resetState.bind(this);

    // Ref
    this._editor;
  }

  activateEditor() {
    const currentUser = this.props.currentUser;

    if (!currentUser.id || currentUser.id < 0) {
      this.props.summonLoginPanel();
    } else if (!currentUser.confirmed) {
      this.props.summonConfirmationEmailMsg();
    } else {
      this._toggleRenderEditor(true);
    }
  }

  handleSubmit() {
    const id = this.props.comment.id ? { id: this.props.comment.id } : {};
    const parent_id = this.props.comment.parent_id ? { parent_id: this.props.comment.parent_id } : {};

    if (this._getTextAreaErrors() !== null) return;
    this.props.onPost({
      comment: {
        ...id,
        ...parent_id,
        commentable_id: this.props.postId,
        commentable_type: 'FeedPost',
        raw_body: this.state.editorText.trim(),
      },
      resolverFn: this.props.mode !== 'default' ? this.props.dismiss : this.resetState,
    });
  }

  resetState() {
    if (this._editor) {
      this._editor.__reset();
    }
  }

  /**
   * Helpers
   */
  _getTextAreaErrors() {
    return maxLength(COMMENT_MAX_LEN, this.state.editorText.trim());
  }

  _toggleRenderEditor(bool) {
    if (bool !== this.state.renderEditor) {
      this.setState({ renderEditor: bool }, () => {
        if (!bool) this.props.dismiss();
      });
    }
  }

  /**
   * Views
   */
  _getEditor() {
    return this.state.renderEditor ? this._getEditorView() : this._getDummyInputView();
  }

  _getEditorView() {
    return (
      <div className={`${inputStyles.input} ${styles.input}`}>
        <DraftEditor
          ref={(el) => this._editor = el}
          classList={{
            root: styles.richTextEditorRoot,
            markdownBtn: `${buttonStyles.cancel} ${typography.bodyS} ${postEditorStyles.markdownBtn}`,
          }}
          config={{ mentions: true, tags: true }}
          focusOnMount={true}
          initText={this.props.comment.raw_body}
          markdownService={this.props.markdownService}
          onBlur={() => this._toggleRenderEditor(false)}
          onFocus={() => this._toggleRenderEditor(true)}
          placeholder="Add comment"
          propagateText={(text) => this.setState({ editorText: text })}
          propagateToolbar={(editorToolbar) => this.setState({ editorToolbar })}
        />
      </div>
    );
  }

  _getDummyInputView() {
    return (
      <input
        className={`${inputStyles.input} ${styles.inputDummy}`}
        onClick={this.activateEditor}
        placeholder="Add comment"
        readOnly={true}
        value=""
      />
    );
  }

  _getEditorActionsView() {
    if (!this.state.renderEditor || (this.state.renderEditor && !this.state.editorText.length)) return null;

    return (
      <div className={`${layout.flexJustifySpaceBetween} ${layout.marginTop5}`}>
        {this.state.editorToolbar}
        {this._getEditorActionsBtnsView()}
      </div>
    );
  }

  _getEditorActionsBtnsView() {
    const isEditMode = this.props.mode === 'edit';

    return (
      <div>
        {isEditMode
        && (
          <Button
            colorStyle="cancel"
            disabled={this.props.isBusy}
            onClick={this.props.dismiss}
            size="sm"
          >
            Cancel
          </Button>
        )}
        <ActionableButton
          isBusy={this.props.isBusy}
          onClick={this.handleSubmit}
          size="sm"
          text={isEditMode ? 'Save' : 'Post'}
        />
      </div>
    );
  }

  _getErrorView() {
    const errors = this._getTextAreaErrors();
    if (errors === null) return;

    return (<div className={`${inputStyles.error} ${layout.margin0}`}>{errors}</div>);
  }

  render() {
    return (
      <div className={layout.flex}>
        {this.props.mode !== 'edit'
        && (
          <img
            className={`${this.props.comment.parent_id ? styles.avatarSm : styles.avatar} ${layout.marginRight10}`}
            src={this.props.currentUser.avatar_url}
          />
        )}

        <div className={`${layout.flexColumn} ${layout.fullWidth}`}>
          {this._getEditor()}
          {this._getEditorActionsView()}
          {this._getErrorView()}
        </div>
      </div>
    );
  }
}

PostCardCommentInput.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
    parent_id: PropTypes.number,
    raw_body: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  dismiss: PropTypes.func,
  isBusy: PropTypes.bool.isRequired,
  markdownService: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['default', 'edit', 'reply']),
  postId: PropTypes.number.isRequired,
  summonConfirmationEmailMsg: PropTypes.func,
  summonLoginPanel: PropTypes.func,
};

PostCardCommentInput.defaultProps = {
  comment: {
    id: null,
    parent_id: null,
    raw_body: '',
  },
  dismiss: () => {},
  mode: 'default',
  summonConfirmationEmailMsg: () => {},
  summonLoginPanel: () => {},
};

export default PostCardCommentInput;
