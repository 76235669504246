import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import ClientRectReporter from '../client_rect_reporter';

import { windowPageYOffset } from '../../../services/window';

const BACKWARD = 'BACKWARD';
const FORWARD = 'FORWARD';

/**
 * CHILDREN:
 * This wrapper expects a function or Component to call, not a element.
 * This wrapper will pass back the record as a prop argument to be decorated elsewhere.
 *.
 * IMPORTANT:
 * If a parent component fetches records from different sources, be sure to call __reset or unmount/remount the instance (usually done with a loader)
 * to clear the cached metadata.
 *
 * Records must have a unique id for CRUD actions.
 */
class Window extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      bufferBottom: 0,
      bufferTop: 0,
      end: (this.props.renderCount - 1),
      start: 0,
    };

    this.handleScroll = this.handleScroll.bind(this);

    // Instance vars
    this._childMetadata = [];
    this._prevPageYOffset = 0;

    // Ref
    this._root;
  }

  componentDidMount() {
    this.throttledScroll = throttle(this.handleScroll, 30, { trailing: true, leading: true });
    window.addEventListener('scroll', this.throttledScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledScroll);
  }

  /**
   * Accessors
   */
  __reset() {
    this._childMetadata = [];
    this.setState({
      bufferBottom: 0,
      bufferTop: 0,
      end: (this.props.renderCount - 1),
      start: 0,
    });
  }

  __deleteFromMeta(id) {
    this._childMetadata = this._childMetadata.filter((m) => m.id !== id);
    this.setState({ ...this._getBuffers(this.state.start, this.state.end) });
  }

  /**
   * Methods
   */
  handleScroll() {
    if (!this._root || !this.props.children.length) return;

    const pageYOffset = windowPageYOffset();

    if (pageYOffset > this._prevPageYOffset) {
      this._handleScrollingDown();
    } else {
      this._handleScrollingUp();
    }

    // Memo-ize last offset so we can determine scroll direction.
    this._prevPageYOffset = pageYOffset;
  }

  /**
   * Helpers
   */
  _getBuffers(start, end) {
    const bufferTop = this._childMetadata.slice(0, start).reduce((acc, meta) => acc += meta.dims.height, 0);

    const bufferBottom = this._childMetadata.slice(end).reduce((acc, meta) => acc += meta.dims.height, 0);

    return { bufferBottom, bufferTop };
  }

  _getRangeToRender(direction) {
    const yOffset = windowPageYOffset();
    const childMetadata = this._childMetadata;

    let startIndex = null;
    for (let i = 0; i <= childMetadata.length - 1; i++) {
      if (startIndex !== null) break;

      const bucket = this._childMetadata[i];
      const prevBucket = this._childMetadata[Math.max((i - 1), 0)];

      if (direction === FORWARD && i === childMetadata.length - 1 && yOffset >= bucket.offset) { // yOffset is past the last item.
        startIndex = i;
      } else if (direction === BACKWARD && i === 0 && bucket.offset >= yOffset) { // First item below the yOffset.
        startIndex = i;
      } else if (yOffset >= prevBucket.offset && yOffset <= bucket.offset) {
        startIndex = i;
      }
    }

    if (startIndex === null) return [this.state.start, this.state.end];

    const start = Math.max(startIndex - ((this.props.renderCount / 2) + 1), 0);
    const end = start + (this.props.renderCount - 1);

    return [start, end];
  }

  _getRootDims(attr = null) {
    return this._root.getBoundingClientRect();
  }

  _handleScrollingDown() {
    if (
      !this.props.isBusy
      && (this.state.end < this.props.records.length)
      && this._isAtBottom(this._getRootDims().bottom, this.props.scrollForwardBuffer)
    ) {
      this._setRangeAndBuffers(FORWARD);
    }
  }

  _handleScrollingUp() {
    if (this.state.start > 0 && this._isAtTop(this._getRootDims().top)) {
      this._setRangeAndBuffers(BACKWARD);
    }
  }

  _isAtBottom(bottom, buffer = 0) {
    if (this.state.bufferBottom > 0) {
      return Math.abs(parseInt(bottom, 10) - buffer) <= Math.abs(parseInt(this.state.bufferBottom, 10));
    } else {
      return (parseInt(bottom, 10) - buffer) <= window.innerHeight;
    }
  }

  _isAtTop(top) {
    return Math.abs(parseInt(top, 10) + 1000) <= Math.abs(parseInt(this.state.bufferTop, 10));
  }

  _setRangeAndBuffers(direction) {
    const [nextStart, nextEnd] = this._getRangeToRender(direction);
    const { bufferBottom, bufferTop } = this._getBuffers(nextStart, nextEnd);

    this.setState({
      bufferBottom,
      bufferTop,
      end: nextEnd,
      start: nextStart,
    });
  }

  /**
   * Views
   */
  _getVisibleRange() {
    const start = this.state.start;

    return this.props.records.slice(start, this.state.end).map((record, i) => (
      <ClientRectReporter
        key={record.id}
        reportDims={(dims) => this._childMetadata[i + start] = { ...dims, id: record.id }}
      >
        {this.props.children({ record })}
      </ClientRectReporter>
    ));
  }

  render() {
    return (
      <div ref={(el) => this._root = el}>
        <div style={{ height: this.state.bufferTop }} />
        {this._getVisibleRange()}
        <div style={{ height: this.state.bufferBottom }} />
      </div>
    );
  }
}

Window.propTypes = {
  isBusy: PropTypes.bool,
  records: PropTypes.array,
  renderCount: PropTypes.number,
  scrollForwardBuffer: PropTypes.number, // How much pixels to look forward to render a fresh range.
};

Window.defaultProps = {
  isBusy: false,
  records: [],
  renderCount: 20,
  scrollForwardBuffer: 500,
};

export default Window;
