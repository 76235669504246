import React from 'react';
import PropTypes from 'prop-types';

import TooltipBody from './TooltipBody';

import styles from './tooltip_target.css';

const TooltipTarget = ({ children, classList, multiline, offsetX, offsetY, position, tooltip, tooltipWidth }) => (
  <span className={`${styles.wrapper} ${classList.wrapper}`}>
    {children}
    {tooltip !== null
    && (
      <TooltipBody
        classList={classList}
        innerWidth={tooltipWidth}
        multiline={multiline}
        offsetX={offsetX}
        offsetY={offsetY}
        position={position}
      >
        {tooltip}
      </TooltipBody>
    )}
  </span>
);

TooltipTarget.propTypes = {
  classList: PropTypes.shape({
    tooltip: PropTypes.string,
    tooltipInner: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  multiline: PropTypes.bool,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  position: PropTypes.string,
  tooltip: PropTypes.node,
  tooltipWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TooltipTarget.defaultProps = {
  classList: {},
  multiline: false,
  offsetX: 0,
  offsetY: 0,
  position: 'bottom',
  tooltip: null,
  tooltipWidth: null,
};

export default TooltipTarget;
