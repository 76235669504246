import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CTABanner from '../../home_page/sections/cta_banner';
import InfiniteScroll from '../../../client/reusable_components/InfiniteScroll';
import PromotedContent from '../../home_page/sections/promoted_content';
import ViewAllBuilder from '../../../services/algolia/view_all_builder';

import keenService from '../../../services/keen/main';
import { fireViewAllSectionClickAnalytics } from '../../../services/keen/mainServiceOperators';
import { clickEventHasModifierKey } from '../../../utility/events';

import layout from '../../../styles/global_ui/layout.css';

const TRACKING_PAGE_TYPE = 'channels#home'; // TODO: Double check if this is kosher;
const MOST_RECENT_DEFAULT_SECTION = {
  key: 'sort',
  label: 'Most recent',
  title: 'Most recent',
  value: 'published',
};

class ChannelHome extends Component {
  constructor(props) {
    super(props);

    this.state = this._initState(props.homeSections, props.renderForPreview);

    this.loadMoreSections = this.loadMoreSections.bind(this);
    this.maintainProjectsCountForSections = this.maintainProjectsCountForSections.bind(this);
    this.transition = this.transition.bind(this);

    this.viewAllBuilder = new ViewAllBuilder(props.viewAllBuilderArg());
    this.sectionsProjectCount = {};
  }

  /**
   * Initializers
   */
  _initState(homeSections, renderForPreview = false) {
    if (renderForPreview) return { defaultUsed: false, sections: homeSections, totalRecordsCount: homeSections.length };

    return homeSections.length > 0
      ? { defaultUsed: false, sections: homeSections.slice(0, 2), totalRecordsCount: homeSections.length }
      : { defaultUsed: true, sections: [MOST_RECENT_DEFAULT_SECTION], totalRecordsCount: 1 };
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this.props.seoHandler(this.props.path);
  }

  /**
   * Methods
   */
  loadMoreSections() {
    return new Promise((resolve) => {
      this.setState({ sections: this.props.homeSections.slice(0, this.state.sections.length + 2) }, () => resolve());
    });
  }

  maintainProjectsCountForSections(count, index) {
    if (this.state.defaultUsed) {
      this._handleDefaultCount(count);
    } else {
      this.sectionsProjectCount[index] = count;

      if (this.props.canManage === false && index === this.props.homeSections.length - 1) {
        this._pushDefaultIfNoProjects();
      } else if (count === 0 && index === this.state.sections.length - 1 && index < this.props.homeSections.length - 1) {
        // If the last index of sections has no projects, we want to go ahead and fetch more.  The reason for this is bypassing the scroll event
        // in InfinityScroll. We want to keep loading sections so that we can potentially push a default section if need be.
        this.setState({ sections: this.props.homeSections.slice(0, this.state.sections.length + 2) });
      }
    }
  }

  transition(e, { internal, external }, item, events) {
    if (this.props.renderForWhitelabel) {
      this.props.transition(e, external);
    } else if (internal && !clickEventHasModifierKey(e)) {
      e.preventDefault();
      this.props.transition(internal);
    } else if (external && events) {
      // NOTE: this is re-overriding for "overrideTracking" in Project Cards.
      // TODO: refactor so that HomeSections overrideTracking workflow is not
      // the default for all ViewAllLists
      keenService.recordEventsWithDelayedRedirect(events, external, e);
    }
  }

  /**
   * Helpers
   */
  _handleDefaultCount(count) {
    this.sectionsProjectCount['default'] = count;
    if (count === 0) this.forceUpdate();
  }

  _pushDefaultIfNoProjects() {
    if (Object.values(this.sectionsProjectCount).every((n) => n === 0)) {
      this.setState({
        defaultUsed: true,
        sections: [MOST_RECENT_DEFAULT_SECTION],
        totalRecordsCount: 1,
      });
    }
  }

  _shouldShowPlaceHolder() {
    return this.state.defaultUsed && this.sectionsProjectCount['default'] === 0 && !this.props.canManage;
  }

  /**
   * Views
   */

  _getCTABanner(section, i) {
    return (
      <CTABanner
        key={`${section.key}_${section.value}`}
        id={section.value}
        image_url={section.meta.image_url}
        link={section.meta.link}
        onClick={(e) => {
          const item = { type: 'cta_banner' };
          fireViewAllSectionClickAnalytics({ delayRedirect: true, e, index: i, item, pageType: TRACKING_PAGE_TYPE, section, url: section.meta.link });
        }}
        title={section.title}
      />
    );
  }

  _getPromotedContent(section, i) {
    return (
      <PromotedContent
        key={`${section.key}_${section.value}`}
        content={section.meta.content}
        onClick={(e, link, itemIndex) => {
          const item = { index: itemIndex, type: 'cta_banner' };
          fireViewAllSectionClickAnalytics({ delayRedirect: true, e, index: i, item, pageType: TRACKING_PAGE_TYPE, section, url: link });
        }}
        title={section.title}
      />
    );
  }

  _getSections() {
    if (this._shouldShowPlaceHolder()) return (<span>There are no projects</span>);

    return this.state.sections.map((section, i) => {
      switch (section.key) {
        case 'cta':
          return this._getCTABanner(section, i);

        case 'promoted':
          return this._getPromotedContent(section, i);

        default:
          return this._getViewAllList(section, i, this.state.sections);
      }
    });
  }

  _getViewAllList(section, i, sections) {
    return this.viewAllBuilder.getComponent(section, i, {
      reportProjectsCount: this.maintainProjectsCountForSections,
      transition: this.transition,
      userCanManage: this.props.canManage,
    }, sections);
  }

  render() {
    return (
      <InfiniteScroll
        buffer={50}
        classList={{ container: `${layout.container}` }}
        fetchMore={this.loadMoreSections}
        recordsCount={this.state.sections.length}
        totalRecordsCount={this.state.totalRecordsCount}
      >
        <div className={layout.wrapper1170}>
          {this._getSections()}
        </div>
      </InfiniteScroll>
    );
  }
}

ChannelHome.propTypes = {
  canManage: PropTypes.bool,
  homeSections: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  })).isRequired,
  path: PropTypes.string.isRequired,
  renderForPreview: PropTypes.bool,
  renderForWhitelabel: PropTypes.bool,
  seoHandler: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
  viewAllBuilderArg: PropTypes.func.isRequired,
};

ChannelHome.defaultProps = {
  canManage: false,
  renderForPreview: false,
  renderForWhiteLabel: false,
};

export default ChannelHome;
