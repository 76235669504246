import { mapifyStringQuery } from '../../utility/converters';

const CONFIG = {
  '/': (location) => {
    const queryMap = mapifyStringQuery(location.search);

    return {
      title: `Explore videos - Page ${queryMap.page || 1} - Hackster.io`,
      pageType: 'videos#index',
    };
  },
  '/*': (video = {}) => ({
    title: `Watch video - ${video.title} - Hackster.io`,
    pageType: 'videos#modal',
    entity_id: video.id,
  }),
};

export default (path) => CONFIG[path];
