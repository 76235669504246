import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../../../../client/link/AnalyticsLink';
import SidebarPlaceholder from '../../article_layout/sidebar/SidebarPlaceholder';

import { NEWS_ARTICLE_CARD } from '../../../../../services/keen/events/linkConstants';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';

const _getRecordsView = (records) => (
  records.map((record, i) => (
    <div key={record.id} className={`${layout.paddingTop15} ${layout.paddingBottom15} ${utilStyles.borderTop}`}>
      <AnalyticsLink
        className={`${typography.link} ${typography.bodyM} ${typography.bold}`}
        item={record}
        linkType={NEWS_ARTICLE_CARD}
        location="related_articles_side"
      >
        {record.title}
      </AnalyticsLink>
    </div>
  ))
);

const RelatedArticlesSide = ({ records }) => {
  if (records && records.length === 0) return null; // loaded but no results, don't display anything

  return (
    <div className={layout.marginTop45}>
      <h5 className={`${typography.h5} ${layout.marginBottom15}`}>Related articles</h5>
      {records ? _getRecordsView(records) : <SidebarPlaceholder animated={true} />}
    </div>
  );
};

RelatedArticlesSide.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

RelatedArticlesSide.defaultProps = { records: null };

export default RelatedArticlesSide;
