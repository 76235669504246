/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
class EventPubSubProxy {
  constructor() {
    this.events = {};
  }

  sub(event, callback, eventKey) {
    if (!this.events.hasOwnProperty(event)) {
      this.events[event] = [];
    }

    this.events[event].push({ key: eventKey, callback });
  }

  pub(event, data) {
    if (this.events.hasOwnProperty(event)) {
      this.events[event].forEach((sub) => sub.callback(data));
    }
  }

  unsub(event, eventKey) {
    if (this.events[event]) {
      this.events[event] = this.events[event].filter((sub) => sub.key !== eventKey);
    }
  }
}

const draftEventProxy = new EventPubSubProxy();
export default draftEventProxy;
