import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IFrame extends Component {
  constructor(props) {
    super(props);
    this.onload = this.onload.bind(this);
    this._iframe;
  }

  componentDidMount() {
    if (this._iframe) {
      this._iframe.contentWindow.document.write(this.props.html);
      this._iframe.contentWindow.document.close();
      // Theres a race condition with setting the height here. The document isn't completely done downloading images and such.
      // We let the iframe tell us when its ready via the onload event and reset the height again when everything is complete.
      // If the onload event takes a long time, the user will see a scrollbar until its complete.
      this._iframe.height = this._iframe.contentWindow.document.body.scrollHeight;
    }
  }

  onload() {
    if (this._iframe) {
      this._iframe.height = this._iframe.contentWindow.document.body.scrollHeight;
    }
  }

  render() {
    return (
      <iframe
        ref={(el) => this._iframe = el}
        allowFullScreen
        frameBorder="0"
        onLoad={this.onload}
        style={{ minWidth: this.props.minWidth }}
        width="100%"
      />
    );
  }
}

IFrame.propTypes = {
  html: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IFrame.defaultProps = {
  html: '<html><body>You forgot to seed the iframe!</body></html>',
  minWidth: '100%',
};

export default IFrame;
