import React, { useState } from 'react';
import PropTypes from 'prop-types';

import inputStyles from '../../../../styles/global_ui/inputs.css';

const DesktopNativeInput = ({ autoComplete, disabled, errors, initValue, max, onChange, type }) => {
  const [value, setValue] = useState(initValue);

  return (
    <input
      autoComplete={autoComplete}
      className={`${inputStyles.input} ${errors ? inputStyles.inputError : ''}`}
      disabled={disabled}
      max={max}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      type={type}
      value={value}
    />
  );
};

DesktopNativeInput.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  initValue: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

DesktopNativeInput.defaultProps = {
  autoComplete: null,
  disabled: false,
  errors: null,
  initValue: '',
  onChange: () => {},
};

export default DesktopNativeInput;
