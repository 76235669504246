import convertRawBlockToHtml from './convertRawBlockToHtml';

export default function convertRawToDataModel(raw, blockMap) {
  const entityMap = raw.entityMap;

  return raw.blocks.map(block => {
    switch(block.type) {
      case 'CAROUSEL':
        return getCarouselData(block, entityMap);

      case 'EMBED':
        return getEmbedData(block, entityMap);

      case 'LEGACY_WIDGET':
        return getLegacyWidgetData(block, entityMap);

      case 'TWEET':
        return getTweetData(block, entityMap);

      case 'FILE':
        return getLegacyWidgetData(block, entityMap);

      case 'IMAGE_LINK':
        return getImageLinkData(block, entityMap);

      default:
        return {
          type: 'CE',
          html: convertRawBlockToHtml(block, blockMap, entityMap)
        };
    }
  })
  .reduce((acc, block) => {
    // Merge adjacent CE blocks.
    if (acc.length && (acc[acc.length-1].type === 'CE' && block.type === 'CE')) {
      acc[acc.length-1].html += block.html;
    } else {
      acc.push(block);
    }
    return acc;
  }, []);
}

function getCarouselData(block, entityMap) {
  const images = entityMap[block.entityRanges[0].key].data.images.toJS();
  return { type: 'Carousel', images };
}

function getEmbedData(block, entityMap) {
  const embedData = entityMap[block.entityRanges[0].key].data.embedData.toJS();
  return { type: embedData.model, [embedData.key]: embedData };
}

function getImageLinkData(block, entityMap) {
  const image = entityMap[block.entityRanges[0].key].data.image.toJS();
  return { type: 'ImageLink', image };
}

function getLegacyWidgetData(block, entityMap) {
  const widgetData = entityMap[block.entityRanges[0].key].data.widgetData.toJS();
  return { type: widgetData.model, data: widgetData };
}

function getTweetData(block, entityMap) {
  const twitterData = entityMap[block.entityRanges[0].key].data.twitterData.toJS();
  return { type: twitterData.model, data: twitterData };
}
