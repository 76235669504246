/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import errorHandler from '../../../services/error_handler';
import { capitalize } from '../../../utility/formatters';
import { flagContent, markAsSpam } from '../../../requests/flags';
import { getErrorMsg } from '../../global_components/messenger/messages';
import { summonGlobalMessenger } from '../../../utility/dispatchers';
import { removeFromObject } from '../../../utility/filters';

/**
 * Helpers
 */
const typeToHuman = (type) => type === 'feed_post' ? 'post' : type;

/**
 * Stateful Methods
 */
export function addWorker(key) {
  if (key && !this.state.workers.hasOwnProperty(key)) {
    this._safelySetState({ workers: { ...this.state.workers, [key]: true } });
  }
}

export function removeFromWorkersState(key) {
  if (key && this.state.workers.hasOwnProperty(key)) {
    return removeFromObject(this.state.workers, key);
  } else {
    return this.state.workers;
  }
}

/**
 * Non-Stateful Methods
 */
export const flagPostOrComment = (type, recordId, userId) => new Promise((resolve, reject) => flagContent({ type, id: recordId }, userId)
  .then(() => {
    summonGlobalMessenger({ msg: `Reported ${typeToHuman(type)}.`, type: 'success' });
    resolve();
  })
  .catch((err) => {
    summonGlobalMessenger({ msg: getErrorMsg(`reporting the ${typeToHuman(type)}`), type: 'error' });
    errorHandler('Discussion flagPostOrComment:', err);
    reject(err);
  }));

export const markPostOrCommentAsSpam = (type, recordId) => new Promise((resolve, reject) => markAsSpam({ type, id: recordId })
  .then(() => {
    summonGlobalMessenger({ msg: `${capitalize(typeToHuman(type))} marked as spam.`, type: 'success' });
    resolve();
  })
  .catch((err) => {
    summonGlobalMessenger({ msg: getErrorMsg(`marking the ${typeToHuman(type)} as spam`), type: 'error' });
    errorHandler('Discussion markPostOrCommentAsSpam:', err);
    reject(err);
  }));
