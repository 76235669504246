const CATEGORIES = {
  'Hackster in the Wild': 'HACKSTER',
  'How-to': 'HOW_TO',
  'Interviews': 'INTERVIEWS',
  'Tech Highlight': 'TECH_HIGHLIGHT',
  'Virtual Events': 'VIRTUAL',
};

export const VIDEO_CATEGORIES = Object.keys(CATEGORIES);
export const VIDEO_CATEGORIES_FILTER = {
  key: 'by_category',
  options: [{ default: true, label: 'Any category', value: null }].concat(Object.keys(CATEGORIES).map((key) => ({ label: key, value: CATEGORIES[key] }))),
};

export function getCategoryEnum(val) {
  return CATEGORIES[val] || null;
}

/**
 * Arg "order" is used when we need to inverse a filter like in table headers.
 * Add fallthrough case statements if/when multiple apps have different filter names for a particular sort.
 * See app/graphql/enums/video_sort_enum.
 */
export function getSortEnum(val, order = 0) {
  const key = `${val}_${order}`;

  switch (key) {
    case 'most_recent_0':
      return 'DATE_DESC';

    case 'most_recent_1':
      return 'DATE_ASC';

    case 'most_popular_0':
      return 'POPULARITY';

    case 'title_0':
      return 'TITLE_ASC';

    case 'title_1':
      return 'TITLE_DESC';

    default:
      return null;
  }
}
