import GraphQLBaseService from '../base_service';
import queryBuilder from './query_builder';
import { graphQuery } from '../../../requests/graphql';

const VIDEOS_BY_ID_TEMPLATE = () => ({ t: 'videos_by_id' });
const VIDEOS_QUERY_TEMPLATE = () => ({ t: 'videos_with_simple_pagination' });

export default class GraphQLVideosService extends GraphQLBaseService {
  constructor({ history } = {}) {
    super({ history, queryBuilder, queryString: VIDEOS_QUERY_TEMPLATE });
  }

  // NOTE: Use this only when the resolver expects an Aloglia shape as its response, for now.
  // Be mindful of the hard-coded query we use for this method.
  // Apps using this method: [ViewAllBuilder::HomeConfig]
  searchWithNoEffects(queryMap, params, recordsCount = 0) {
    return new Promise((resolve, reject) => this._translateQueryMap(queryMap, params)
      .then((vars) => graphQuery(VIDEOS_BY_ID_TEMPLATE(), vars))
      .then(({ videos }) => {
        resolve({
          hits: videos,
          nbHits: videos.length,
        });
      })
      .catch((err) => reject(err)));
  }

  _translateQueryMap(queryMap, params) {
    return new Promise((resolve, reject) => {
      const vars = {};
      const qlVars = queryMap.qlVars || {};

      if (queryMap.hitsPerPage) {
        vars.per_page = queryMap.hitsPerPage;
      }

      return queryBuilder(vars)
        .then((query) => resolve({ ...query, ...qlVars }))
        .catch((err) => reject(err));
    });
  }
}
