import React from 'react';
import PropTypes from 'prop-types';

import layoutStyles from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';
import animation from '../../../styles/animation.css';
import cardStyles from '../cards.css';

import styles from './user_card.css';

const DummyUserCard = ({ className, avatarBorder, avatarMargin, avatarSize, children, titleSize }) => (
  <div className={`${className} ${layoutStyles.flexCenterItems} ${layoutStyles.fullWidth}`}>
    <div
      className={`${animation.loader} ${utilStyles.circle} ${layoutStyles[`marginRight${avatarMargin}`]} ${avatarBorder ? utilStyles.border : ''}`}
      style={{ height: avatarSize, width: avatarSize }}
    />
    <div className={`${styles.content} ${layoutStyles.fullWidth}`}>
      <div className={cardStyles[`dummyText${titleSize}`]} />
      {children}
    </div>
  </div>
);

DummyUserCard.propTypes = {
  avatarBorder: PropTypes.bool,
  avatarMargin: PropTypes.number,
  avatarSize: PropTypes.number,
  className: PropTypes.string,
  titleSize: PropTypes.oneOf(['S', 'M', 'L']),
};

DummyUserCard.defaultProps = {
  avatarBorder: true,
  avatarMargin: 10,
  avatarSize: 40,
  className: '',
  titleSize: 'M',
};

export default DummyUserCard;
