import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {EditorState, Modifier} from 'draft-js';

import Prompt from '../Prompt';

import getVisibleSelectionRect from 'draft-js/lib/getVisibleSelectionRect';
import {getEntityDataByCursorPosition, getInlineStyles} from '../../utils/draftUtils';
import {INVALID_URL_MSG, appendProtocolToUrlType, doesUrlHaveProtocol, isValidLinkable} from '../../utils/media/url';

import styles from './linkmenu.css';
import buttonStyles from '../../styles/buttons.css';

class LinkMenu extends Component {
  constructor(props) {
    super(props);

    this.setMenuContents = this.setMenuContents.bind(this);
    this.resetMenuContents = this.resetMenuContents.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.updateEntityData = this.updateEntityData.bind(this);

    this.state = {
      content: '',
      href: '',
      initialRangeBoundaries: {},
      left: 0,
      top: 0,
      version: 'default',
      cache: { content: '', href: '' }
    };

    // Refs
    this.rootRef;
    this.contentInputRef;
    this.linkInputRef;
  }

  UNSAFE_componentWillMount() {
    this.setMenuContents(this.props.editorState);
  }

  componentDidMount() {
    this.setState(this.getStyles());
  }

  componentDidUpdate(prevProps, prevState) {
    // Updates content state relative to editorContent && updates positioning of the default menu.
    if (prevProps.editorState.getCurrentContent() !== this.props.editorState.getCurrentContent() ||
       prevProps.editorState.getSelection().getAnchorOffset() !== this.props.editorState.getSelection().getAnchorOffset()) {
      this.setMenuContents(this.props.editorState, this.getStyles());
    }
    // AutoFocus
    if (prevState.version === 'default' && this.state.version === 'editing') {
      this.contentInputRef.focus();
    }
  }

  componentWillUnmount() {
    if (this.props.readOnly) {
      this.props.toggleReadOnly(false);
    }
  }

  getStyles() {
    if (!window || !document) return;
    const rangeBoundingRect = getVisibleSelectionRect(window) || this.state.initialRangeBoundaries;
    const editorBoundingRect = document.getElementById(this.props.instanceId).getBoundingClientRect();

    if (!Object.keys(rangeBoundingRect).length) return {};

    const top = ((rangeBoundingRect.top - editorBoundingRect.top) + 40); // Toolbar === 48
    const left = ((rangeBoundingRect.left - editorBoundingRect.left) - ((this.rootRef.offsetWidth/2)));
    // When transitioning from versions, the selection range is lost since we're disabling Draft to readOnly.
    // We need to keep track of the initial range boundaries in state.
    if (rangeBoundingRect !== this.state.initialRangeBoundaries) {
      this.setState({ initialRangeBoundaries: rangeBoundingRect });
    }

    return { top: top - 150, left: left };
  }

  setMenuContents(editorState, positions={}) {
    getEntityDataByCursorPosition(editorState, data => {
      const content = data.content;
      const href = data.data.href;
      this.setState({
        content: content,
        href: href,
        cache: {
          content: content,
          href: href
        },
        ...positions
      });
    });
  }

  resetMenuContents(e) {
    e.stopPropagation();

    this.setState({
      version: 'default',
      content: this.state.cache.content,
      href: this.state.cache.href
    });

    this.props.onChange(
      EditorState.forceSelection(
        this.props.editorState,
        this.props.editorState.getSelection().merge({ hasFocus: true })
      )
    );
    this.props.toggleReadOnly(false);
  }

  handleKeyDown(e, ref) {
    switch(e.keyCode) {
      case 13: // ENTER
        e.preventDefault();
        this._handleLinkValidation(this.state.href);
        break;

      case 27: // Esc
        if(this.state.version === 'editing') {
          this.resetMenuContents(e);
        }
        break;

      default:
        break;
    }
  }

  handleRemoveClick(e) {
    e.stopPropagation();
    const currentState = this.props.editorState;
    getEntityDataByCursorPosition(currentState, data => {
      const currentContent = currentState.getCurrentContent();
      const selection = currentState.getSelection();
      // Wraps LINK with a selection.
      const updatedSelection = selection.merge({
        anchorOffset: data.start,
        focusOffset: data.end
      });
      // Removes entities by Selection range.
      const newContentState = Modifier.applyEntity(
        currentContent,
        updatedSelection,
        null
      );
      // Creates a new Editor state and forces the Selection to its original state to place the cursor back where it was.
      this.props.onChange(
        EditorState.forceSelection(
          EditorState.push(currentState, newContentState),
          selection
        )
      );
      this.props.toggleReadOnly(false);
    });
  }

  updateEntityData(urlType) {
    const currentEditorState = this.props.editorState;
    const selection = currentEditorState.getSelection();
    const contentState = currentEditorState.getCurrentContent();
    const entityKey = currentEditorState
      .getCurrentContent()
      .getBlockForKey(selection.getAnchorKey())
      .getEntityAt(selection.getStartOffset());

    const entity = contentState.getEntity(entityKey);
    const href = appendProtocolToUrlType(this.state.href, urlType);
    const contentStateWithEntity = entity.getData().href !== href ? contentState.mergeEntityData(entityKey, {href}): contentState;

    getEntityDataByCursorPosition(this.props.editorState, data => {
      const currentContent = contentStateWithEntity;

      // Wrap current link by charMetaData.
      const updatedSelection = selection.merge({
        anchorOffset: data.start,
        focusOffset: data.end
      });

      const inlineStyles = getInlineStyles(this.props.editorState);

      // Replace the text and tag characters with entityKey.
      const replacedTextContentState = data.content !== this.state.content
        ? Modifier.replaceText(
          currentContent,
          updatedSelection,
          this.state.content,
          inlineStyles || null,
          entityKey
        )
        : currentContent;

      // Set selection to the offset of the input caret, else leave the cursor where it was.
      const contentInputSelectionOffset = ReactDOM.findDOMNode(this.contentInputRef).selectionStart || this.state.content.length;
      const finalSelection = data.content !== this.state.content
        ? selection.merge({
          'anchorOffset': parseInt(data.start, 10) + parseInt(contentInputSelectionOffset, 10),
          'focusOffset': parseInt(data.start, 10) + parseInt(contentInputSelectionOffset, 10),
          'hasFocus': false
        })
        : selection;
      const updatedEditorState = EditorState.forceSelection(EditorState.push(currentEditorState, replacedTextContentState), finalSelection);

      // Set version to default to prevent toggling readOnly again.  This is when the cursor is still in an anchor after change.
      this.setState({
        version: 'default',
        cache: {
          content: this.state.content,
          href: href
        }
      });
      this.props.toggleReadOnly(false);
      this.props.onChange(updatedEditorState);
    });
  }

  /**
   * Helpers
   */
  _handleLinkValidation(href) {
    href = href.trim();
    const {isValid, type} = isValidLinkable(href);
    isValid ? this.updateEntityData(type) : this.props.onInvalidUrl(INVALID_URL_MSG);
  }

  /**
   * Views
   */
  _composeDefaultMenu() {
    if (this.props.readOnly) return null;

    const href = this.state.href;
    const shortenedLink = href.length >= 40 ? `${href.substring(0, 40)}...` : href;
    return (
      <div className={`${styles.defaultMenu} link-popover-default-container`}>
        <a href={href} onClick={e => { e.preventDefault(); window.open(href, '_blank'); }}>{shortenedLink}</a>
        <a
          href="javascript:void(0);"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({version: 'editing'});
          }}
          >
          Change
        </a>
        <a href="javascript:void(0);" onClick={this.handleRemoveClick}>Remove</a>
      </div>
    );
  }

  _composePromptMenu() {
    return (
      <Prompt
        actions={[
          <button
            className={`${buttonStyles.btn} ${buttonStyles.btnLink}`}
            onClick={this.resetMenuContents}
            type="button"
            >
            Cancel
          </button>,
          <button
            className={`${buttonStyles.btn} ${buttonStyles.btnPrimary}`}
            onClick={(e) => {
              e.stopPropagation();
              this._handleLinkValidation(this.state.href);
            }}
            type="button"
            >
            Ok
          </button>
        ]}
        dismiss={this.resetMenuContents}
        inputs={[
          <input
            ref={el => this.contentInputRef = el}
            style={{ marginBottom: 10 }}
            className={`${styles.input}`}
            onChange={(e) => this.setState({content: e.target.value})}
            onKeyDown={(e) => this.handleKeyDown(e, 'contentInput')}
            placeholder="Change Text"
            type="text"
            value={this.state.content}
          />,
          <input
            ref={el => this.linkInputRef = el}
            className={`${styles.input}`}
            onChange={(e) => this.setState({href: e.target.value})}
            onKeyDown={(e) => this.handleKeyDown(e, 'linkInput')}
            placeholder="Enter a link"
            type="text"
            value={this.state.href}
          />
        ]}
      />
    );
  }

  render() {
    return (
      <div ref={el => this.rootRef = el} style={{ top: this.state.top, left: this.state.left }} className={`${styles.root} react-link-popover-wrapper`}>
        <div className={`${styles.popover} react-link-popover`}>
          {this.state.version === 'editing' ? this._composePromptMenu() : this._composeDefaultMenu()}
        </div>
        {this.state.version === 'default' && this.props.readOnly === false &&
          <div className={`${styles.arrow} link-popover-arrow`} />
        }
      </div>
    );
  }
}

LinkMenu.propTypes = {
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  instanceId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onInvalidUrl: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  toggleReadOnly: PropTypes.func.isRequired
};

export default LinkMenu;