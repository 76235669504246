/**
 * For SimplePagination shapes.
 * Is there are enough records on the current page or the next page to fill out the current page. If not, paginate back one.
 */
export function getCurrentOrPreviousPagePostDeletion(pagination, deletionCount) {
  const recordsRemainingOnPage = (parseInt(pagination.per_page) - deletionCount);

  return ((recordsRemainingOnPage > 0) || (pagination.next_page !== null))
    ? pagination.current_page
    : Math.max(parseInt(pagination.current_page) - 1, 1);
}

export function getPageFromQueryMap(queryMap) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!queryMap.hasOwnProperty('page')) return null;
  const page = parseInt(queryMap.page);

  return !isNaN(page) && typeof page === 'number' ? page : null;
}
