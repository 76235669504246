import React from 'react';
import PropTypes from 'prop-types';

import { getInObj } from '../../../utility/accessors';
import { capitalize, unsnakeString } from '../../../utility/formatters';
import { isBlank } from '../../../utility/types';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import tableStyles from '../../../styles/global_ui/table.css';
import styles from './style.css';

const DataValueView = ({ dataKey, data, formatters }) => {
  const formatter = getInObj([dataKey], formatters);
  const value = getInObj([dataKey], data);
  const formattedValue = (formatter && value) ? formatter(value) : value;
  return (<p className={`${typography.bodyM} ${typography.breakWord} ${typography.textRight}`}>{isBlank(formattedValue) ? '-' : formattedValue}</p>);
};

const DataView = ({ keys, data, formatters }) => (
  keys.map((key) => (
    <div key={key} className={layout.flexJustifySpaceBetween}>
      <p className={typography.bodyM}>
        {unsnakeString(key, capitalize)}
        :
      </p>
      <DataValueView data={data} dataKey={key} formatters={formatters} />
    </div>
  ))
);

const LogRow = ({ cellBuilder, log, logRowNormalizerFn }) => {
  const newLog = logRowNormalizerFn ? logRowNormalizerFn(log) : log;

  return (
    <div className={`${layout.flexJustifyCenter} ${tableStyles.row} ${typography.bodyM}`}>
      {Object.keys(newLog).map((cellKey) => {
        const { formatter, dataView, size, dataSource, dataFormatters } = cellBuilder[cellKey];
        const formattedCell = formatter ? formatter(newLog[cellKey]) : newLog[cellKey];

        return (
          <div
            key={cellKey}
            className={`${size === 'large' ? styles.historyColLarge : styles.historyCol} ${tableStyles.cell}`}
          >
            {dataView
              ? (
                <DataView
                  data={newLog[dataSource]}
                  formatters={dataFormatters}
                  keys={formattedCell}
                />
                )
              : formattedCell }
          </div>
        );
      })}
    </div>
  );
};

LogRow.propTypes = {
  cellBuilder: PropTypes.objectOf(
    PropTypes.shape({
      size: PropTypes.oneOf(['normal', 'large']).isRequired,
      formatter: PropTypes.func,
      dataView: PropTypes.bool,
      dataSource: PropTypes.string,
      dataFormatters: PropTypes.objectOf(PropTypes.func),
    }),
  ).isRequired,
  log: PropTypes.shape({
    assigner: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    log_data: PropTypes.shape({
      changes: PropTypes.arrayOf(PropTypes.string),
      process: PropTypes.string,
    }),
    tier_data: PropTypes.shape({
      affidavit: PropTypes.string,
      citizenship: PropTypes.string,
      country_of_residence: PropTypes.string,
      tier: PropTypes.string,
    }),
    user_id: PropTypes.number,
    id: PropTypes.number,
  }),
  logRowNormalizerFn: PropTypes.func,
};

export default LogRow;
