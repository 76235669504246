import React from 'react';
import DateRange from '../../../../../client/cards/event_card/DateRange';
import EventTypeOrLocation from '../../../../../client/cards/event_card/EventTypeOrLocation';
import SponsorList from '../../../../../client/cards/event_card/SponsorList';

import layout from '../../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import styles from './preview_cards.css';

/**
 * Helpers
 */
//
export const TextPlaceholder = ({ backgroundColor = 'var(--fog)', height = 'inherit', marginTop = '0', width = 'inherit' }) => (
  <div style={{ backgroundColor, borderRadius: 2, height, marginTop, width }} />
);

/**
 * Date
 */
export const getDate = (event) => event.start_date ? getDateView(event) : getDatePreview();
const getDatePreview = () => (<div className={styles.datePlaceholder} />);
const getDateView = (event) => (<DateRange endDate={event.end_date} large={true} startDate={event.start_date} />);

export const getDateSmall = (event) => event.start_date ? getDateSmallView(event) : getDateSmallPreview();
const getDateSmallPreview = () => (<div className={styles.datePlaceholder} />);
const getDateSmallView = (event) => (
  <div className={`${layout.fullWidth} ${utilStyles.absolutePlaceholderChild}`}>
    <DateRange endDate={event.end_date} startDate={event.start_date} />
  </div>
);

/**
 * Location
 */
export const getLocation = (event) => (event.event_type && event.location ? getLocationView(event) : getLocationPreview());
const getLocationPreview = () => (<TextPlaceholder height={20} width={200} />);
const getLocationView = (event) => (<EventTypeOrLocation item={event} />);

/**
 * Sponsors
 */
export const getSponsorsList = (event, prependSlash = true) => event.event_type ? getSponsorsListView(event, prependSlash) : null;
const getSponsorsListView = (event, prependSlash) => (<SponsorList item={event} prependSlash={prependSlash} />);
