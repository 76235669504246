/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { escape } from 'validator';

import PostCreator from './PostCreator';
import PostEditorActions from './PostEditorActions';
import PostEditorBody from './PostEditorBody';

import fetchScrapedPage from '../../../requests/scraper';

import draftEventProxy from './draft_editor/events/draftEventProxy';

import clickOutsideListener from '../../../utility/clickOutsideListener';
import generateRandomKey from '../../../utility/generateRandomKey';
import errorHandler from '../../../services/error_handler';
import { getInObj } from '../../../utility/accessors';
import { removeFromObject } from '../../../utility/filters';
import { isBlank } from '../../../utility/types';

import typography from '../../../styles/global_ui/typography.css';

const BLANK_VALUE = 'NOOP';
const DEFAULT_PLACEHOLDER = 'Share something cool or ask a question.';
const CATEGORY_ERROR = 'Please select a category.';
const CONTENT_ERROR = 'Posts cannot be empty.';
const SERVER_ERROR = 'Sorry, try posting again!';

class PostEditor extends PureComponent {
  constructor(props) {
    super(props);

    const initCategoryOpt = this._initCategoryOpt(props);
    this.state = {
      categoryOpt: initCategoryOpt,
      categorySelectOpts: this._initCategorySelectOpts(props, initCategoryOpt),
      embed: getInObj(['entities', 'embed'], props.post) || {},
      embedCache: {},
      errors: {},
      isBusy: false,
      isFocused: this.props.mode === 'edit',
      markdownBtn: (<div />),
      placeholder: DEFAULT_PLACEHOLDER,
    };

    this.clickOutsideListenerCallback = this.clickOutsideListenerCallback.bind(this);
    this.handleCategorySelection = this.handleCategorySelection.bind(this);
    this.handleDraftEditorUpated = this.handleDraftEditorUpated.bind(this);
    this.handleOnPostOrSave = this.handleOnPostOrSave.bind(this);
    this.resetEditorState = this.resetEditorState.bind(this);
    this.setServerError = this.setServerError.bind(this);
    this.scrapePageForLink = this.scrapePageForLink.bind(this);

    // Draft pub/sub key
    this._draftSubKey = generateRandomKey();
    // Window listener
    this._clickOutsideListener;
    // Ref
    this._editor;
    this._root;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    if (this.props.mode === 'edit' && this._editor) {
      this._editor.__focus();
    }
    draftEventProxy.sub('stateUpdated', this.handleDraftEditorUpated, this._draftSubKey);
  }

  componentWillUnmount() {
    this._cleanupOutsideClickListener(); // Shouldn't be needed, but just in case.
    draftEventProxy.unsub('stateUpdated', this._draftSubKey);
  }

  /**
   * Initializers
   */
  _initCategoryOpt(props) {
    if (!props.post) return null;

    return props.categorySelectOpts.find((opt) => opt.value === props.post.category);
  }

  _initCategorySelectOpts(props, initCategoryOpt) {
    return props.mode === 'default' ? this._initCategorySelectOptsDefault(props) : this._initCategorySelectOptsEdit(props, initCategoryOpt);
  }

  // TODO: This will add another option to the select dropdown. Ideally we would want this default label for the PostCreator and only when
  // the currentPath is at '/' (root). We want this to act like a placeholder and not render as an option. The issue is the way SimpleSelect
  // uses indexes to determine selection. So if we hide the option then choose the first option (which is really the second option) we'll get the wrong selected index.
  // Perhaps if we add a special key to the option object like "placeholder" or "hidden" then update the logic in SimpleSelect, something trickery could work.
  _initCategorySelectOptsDefault(props) {
    const blankOpt = { disabled: true, hidden: true, label: this._getBlankLabelForCategoryOpts(), labelText: 'Choose category', value: BLANK_VALUE };
    const categorySelectOpts = [blankOpt].concat(props.categorySelectOpts);
    const currentPathValue = props.currentPath === '/' ? { value: BLANK_VALUE } : { value: props.currentPath };

    return this._getUpdatedCategorySelectOpts(currentPathValue, categorySelectOpts);
  }

  _initCategorySelectOptsEdit(props, initCategoryOpt) {
    return this._getUpdatedCategorySelectOpts(initCategoryOpt, props.categorySelectOpts);
  }

  /**
   * Methods
   */
  clickOutsideListenerCallback() {
    this._cleanupOutsideClickListener();
    this._unFocusEditorConditonally();
  }

  handleCategorySelection(opt) {
    this.setState({
      categoryOpt: opt,
      categorySelectOpts: this._getUpdatedCategorySelectOpts(opt),
      errors: removeFromObject(this.state.errors, 'category'),
    });
  }

  // This is a really expensive method. It gets fired on every keyDown event in the DraftEditor.
  // Protect any expensive ops with conditionals.
  handleDraftEditorUpated(editorStateImmutable) {
    if (this.state.errors.hasOwnProperty('content')) {
      const text = editorStateImmutable.getCurrentContent().getPlainText();
      if (text.length > 0) this.setState({ errors: removeFromObject(this.state.errors, 'content') });
    }
  }

  handleOnPostOrSave() {
    const category = this._getCategoryForPost();
    const text = this._editor.__getText();
    const errors = this._validate(text, category);

    if (!isBlank(errors)) return this.setState({ errors });

    const id = this.props.post ? { id: this.props.post.id } : {};
    const entities = !isBlank(this.state.embed) ? { entities: JSON.stringify({ embed: this.state.embed }) } : {};
    const post = {
      ...id,
      ...entities,
      category,
      body: escape(text),
    };

    this.props.onPostOrUpdate({
      post,
      oldPost: this.props.post,
      failureFn: this.setServerError,
      resolverFn: this.props.mode === 'default' ? this.resetEditorState : this.props.dismiss,
    });
    this._editor.__setReadOnly(true);
  }

  resetEditorState() {
    this._editor.__reset();
    this._editor.__setReadOnly(false);

    this.setState({
      categoryOpt: null,
      categorySelectOpts: this._initCategorySelectOpts(this.props, null),
      embed: {},
      embedCache: {},
      errors: {},
      isFocused: false,
    });
  }

  setServerError() {
    this._editor.__setReadOnly(false);
    this.setState({ errors: { server: SERVER_ERROR } });
  }

  scrapePageForLink(url) {
    if (!isBlank(this.state.embed)) return;

    if (this.state.embedCache.hasOwnProperty(url)) {
      return this.setState({ embed: this.state.embedCache[url] });
    }

    return this._fetchScrapedPage(url);
  }

  /**
   * Helpers
   */
  _fetchScrapedPage(url) {
    this.setState({ isBusy: true });

    return fetchScrapedPage(url)
      .then((scrapedPage) => {
        // When embed has a link property, the scraper successfully got something, we set the data and cache it.
        // Otherwise we cache the url with an empty object so we dont have to fetch a known dead url again.
        const link = getInObj(['link'], scrapedPage);
        const cacheKey = (link || url);
        const embed = link ? scrapedPage : {};

        this.setState({
          embed,
          embedCache: { ...this.state.embedCache, [cacheKey]: embed },
          isBusy: false,
        });
      })
      .catch((err) => {
        errorHandler('PostEditor _fetchScrapedPage', err);
        this.setState({ isBusy: false });
      });
  }

  _getCategoryForPost() {
    if (this.props.mode === 'edit' || this.props.currentPath === '/') return getInObj(['value'], this.state.categoryOpt);

    // When in default mode and in a sub-category, we allow posting to that specific category only. Since categories are mapped
    // immutably to endpoints and protecting via the Rails router, matching the currentPath to a category value should always return a value.
    const opt = this.state.categorySelectOpts.filter((opt) => opt.value.toLowerCase() === this.props.currentPath.toLowerCase());

    return opt[0].value;
  }

  _getCategorySelectOptsForCurrentPath() {
    switch (this.props.currentPath.toLowerCase()) {
      case '/':
        return this.state.categorySelectOpts;

      default:
        // Only allow a specific category when on that category path.
        // TODO: Refactor this. Disabling for linter clean-up
        // eslint-disable-next-line no-case-declarations
        const opts = this.state.categorySelectOpts.filter((opt) => {
          opt.value.toLowerCase() === this.props.currentPath.toLowerCase();
        });

        return [{ ...opts[0], active: true }];
    }
  }

  _getUpdatedCategorySelectOpts(selectedOpt, opts = this.state.categorySelectOpts) {
    if (!selectedOpt) return opts;

    return opts.map((opt) => opt.value.toLowerCase() === selectedOpt.value.toLowerCase() ? { ...opt, active: true } : { ...opt, active: false });
  }

  _setIsFocused() {
    if (this.props.mode === 'default') this._bindOutsideClickListener();

    if (!this.state.isFocused) this.setState({ isFocused: true });

    if (!this.state.isFocused && this._editor) this._editor.__focus();
  }

  _validate(text, category) {
    const categoryError = (category && category !== BLANK_VALUE) ? {} : { category: CATEGORY_ERROR };
    const contentError = ((text.length > 0) || !isBlank(this.state.embed)) ? {} : { content: CONTENT_ERROR };

    return { ...categoryError, ...contentError };
  }

  /**
   * PostCreator Helpers (mode === "default")
   */
  _bindOutsideClickListener() {
    if (this.props.mode === 'default' && this._root && (typeof this._clickOutsideListener === 'undefined')) {
      this._clickOutsideListener = clickOutsideListener(this._root, this.clickOutsideListenerCallback, true);
    }
  }

  _cleanupOutsideClickListener() {
    if (this._clickOutsideListener && this._clickOutsideListener.hasOwnProperty('remove')) {
      this._clickOutsideListener.remove();
      this._clickOutsideListener = undefined;
    }
  }

  _unFocusEditorConditonally() {
    const text = this._editor.__getText();

    if (!text.length && !Object.keys(this.state.embed).length) {
      this.setState({
        categoryOpt: null,
        categorySelectOpts: this._getUpdatedCategorySelectOpts({ value: BLANK_VALUE }),
        errors: {},
        isFocused: false,
      });
    }
  }

  /**
   * Views
   */
  _getBlankLabelForCategoryOpts() {
    return (
      <div className={`${typography.bodyS} ${typography.asphalt}`}>Choose category</div>
    );
  }

  _getBodyView() {
    return (
      <PostEditorBody
        deleteEmbed={() => this.setState({ embed: {} })}
        embed={this.state.embed}
        getEditorRef={(el) => this._editor = el}
        isEditorFocused={this.state.isFocused}
        markdownService={this.props.markdownService}
        mode={this.props.mode}
        post={this.props.post}
        propogateToolbar={(markdownBtn) => this.setState({ markdownBtn })}
        scrapePageForLink={this.scrapePageForLink}
      />
    );
  }

  _getDefaultView() {
    return (
      <PostCreator
        categoryConfig={this.props.categoryConfig}
        categorySelectOpts={this._getCategorySelectOptsForCurrentPath()}
        currentPath={this.props.currentPath}
        currentUser={this.props.currentUser}
        errors={this.state.errors}
        isBusy={this.props.isBusy || this.state.isBusy}
        isEditorFocused={this.state.isFocused}
        markdownBtn={this.state.markdownBtn}
        onCategorySelect={this.handleCategorySelection}
        onPost={this.handleOnPostOrSave}
        origin={this.props.origin}
      >
        {this._getBodyView()}
      </PostCreator>
    );
  }

  _getEditView() {
    return (
      <div>
        {this._getBodyView()}
        <PostEditorActions
          categorySelectOpts={this.state.categorySelectOpts}
          dismiss={this.props.dismiss}
          errors={this.state.errors}
          isBusy={(this.props.isBusy || this.state.isBusy)}
          markdownBtn={this.state.markdownBtn}
          mode={this.props.mode}
          onCategorySelect={this.handleCategorySelection}
          onPostOrSave={this.handleOnPostOrSave}
        />
      </div>
    );
  }

  render() {
    return (
      <div
        ref={(el) => this._root = el}
        onClick={() => this._setIsFocused()}
      >
        {this.props.mode === 'default' ? this._getDefaultView() : this._getEditView()}
      </div>
    );
  }
}

PostEditor.propTypes = {
  categoryConfig: PropTypes.arrayOf(PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  categorySelectOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    value: PropTypes.string,
  })).isRequired,
  currentPath: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  dismiss: PropTypes.func,
  isBusy: PropTypes.bool.isRequired,
  markdownService: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['default', 'edit']),
  onPostOrUpdate: PropTypes.func.isRequired,
  origin: PropTypes.shape({ admin_ids: PropTypes.arrayOf(PropTypes.number) }).isRequired,
  post: PropTypes.shape({
    body: PropTypes.string,
    id: PropTypes.number.isRequired,
    entities: PropTypes.shape({ embed: PropTypes.object }),
  }),
};

PostEditor.defaultProps = {
  categoryConfig: null,
  dismiss: () => {},
  mode: 'default',
  post: null,
};

export default PostEditor;
