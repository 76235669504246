/**
 * Text Selection Helpers
 */
function shouldSelectWordAtCaret(textAreaValue, selectionStart, selectionEnd) {
  return (selectionStart === selectionEnd);
}

function getWordAtCaret(textAreaValue, caretPos) {
  const beforeCaret = textAreaValue.slice(0, caretPos).split(/\s+/).pop();
  const afterCaret = textAreaValue.slice(caretPos).split(/\s+/).shift();

  return {
    initialCaretPos: { start: caretPos, end: caretPos },
    selectionRange: {
      start: (caretPos - beforeCaret.length),
      end: (caretPos + afterCaret.length),
    },
    selectedStr: beforeCaret + afterCaret,
    textAreaValue,
  };
}

function getSelectedBlock(textAreaValue, selectionStart, selectionEnd) {
  const selectedBlock = textAreaValue.slice(selectionStart, selectionEnd);

  return {
    initialCaretPos: { start: selectionStart, end: selectionEnd },
    selectionRange: { start: selectionStart, end: selectionEnd },
    selectedStr: selectedBlock,
    textAreaValue,
  };
}

export default function getSelection({ textAreaValue, selectionStart, selectionEnd }) {
  return shouldSelectWordAtCaret(textAreaValue, selectionStart, selectionEnd)
    ? getWordAtCaret(textAreaValue, selectionStart)
    : getSelectedBlock(textAreaValue, selectionStart, selectionEnd);
}
