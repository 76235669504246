// modified from https://raw.githubusercontent.com/valeriangalliat/markdown-it-highlightjs/master/index.js
// Adds "unescape". The feed post editor calls "escape" as part of processing mentions,
// so we need to unescape that in code blocks so the code will display without escaped chars

import hljs from 'highlight.js';
import { unescape } from 'validator';

const maybe = (f) => {
  try {
    return f();
  } catch (e) {
    return false;
  }
};

const highlightAuto = (code) => (
  maybe(() => hljs.highlightAuto(unescape(code)).value) || ''
);

// Highlight with given language.
const highlightWithLang = (code, lang) => (
  maybe(() => hljs.highlight(lang, unescape(code), true).value) || ''
);

// Highlight with given language or automatically.
const highlightWithLangOrAuto = (code, lang) => lang ? highlightWithLang(code, lang) : highlightAuto(code);

// Wrap a render function to add `hljs` class to code blocks.
const wrap = (render) => function(...args) {
  return render.apply(this, args)
    .replace('<code class="', '<code class="hljs hljs-monokai ')
    .replace('<code>', '<code class="hljs hljs-monokai">');
};

const highlightjs = (md, opts) => {
  opts = Object.assign({}, highlightjs.defaults, opts);

  md.options.highlight = opts.auto ? highlightWithLangOrAuto : highlightWithLang;
  md.renderer.rules.fence = wrap(md.renderer.rules.fence);

  if (opts.code) {
    md.renderer.rules.code_block = wrap(md.renderer.rules.code_block);
  }
};

highlightjs.defaults = {
  auto: true,
  code: true,
};

export default highlightjs;
