import React from 'react';
import PropTypes from 'prop-types';

import IconOptionsMenu from './IconOptionsMenu';

import { DRAFT_STATUS, PUBLISHED_STATUS, SUBMITTED_STATUS } from '../../../../graphql/news/enums';

import { getInObj } from '../../../../utility/accessors';
import { DATE_AT_TIME_FORMAT, timeIsFuture, timestampToPrettyDate } from '../../../../utility/time';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './news_admin_templates.css';

/**
 * Helpers
 */
const getStatusData = (article) => {
  switch (article.status) {
    case DRAFT_STATUS:
      return { classColor: utilStyles.categoryGrayBG, text: 'Draft' };

    case PUBLISHED_STATUS: {
      const published_at = article.published_at;
      const prettyTime = timestampToPrettyDate(published_at, DATE_AT_TIME_FORMAT);
      const isScheduled = timeIsFuture(published_at);

      return isScheduled
        ? { classColor: utilStyles.categoryGreenBG, text: `Scheduled for ${prettyTime}` }
        : { classColor: null, text: `Published on ${prettyTime}` };
    }
    case SUBMITTED_STATUS:
      return { classColor: utilStyles.categoryOrangeBG, text: 'Submitted' };

    default:
      return { classColor: null, text: 'Error' };
  }
};

/**
 * Views
 */
const getArticleUserView = ({ article, isAdminOrEditor }) => {
  if (!isAdminOrEditor) return null;

  const avatar = getInObj(['user', 'avatar_url'], article);
  const name = getInObj(['user', 'name'], article);

  return (
    <div className={layout.flexCenterItems}>
      <span className={typography.asphalt} style={{ fontSize: 22, padding: '0 15px' }}>|</span>
      {avatar && <img className={utilStyles.circle} src={avatar} style={{ height: 20, width: 20 }} />}
      {name && <p className={`${typography.bodyM} ${typography.breakWord} ${layout.paddingLeft10}`}>{name}</p>}
    </div>
  );
};

const getStatusView = (article) => {
  const { classColor, text } = getStatusData(article);

  return (
    <div className={`${layout.flexCenterItems} ${styles.topMenuBarStatus}`}>
      {classColor && <div className={`${utilStyles.categoryBadgeRectSmall} ${layout.marginRight10} ${classColor}`} />}
      <span className={`${typography.bodyM} ${typography.bold}`}>{text}</span>
    </div>
  );
};

/**
 * Component
 */
const TopMenuBar = ({ article, deleteArticle, isAdminOrEditor, unpublishArticle }) => {
  if (!article) return null;

  return (
    <div className={`${utilStyles.borderBottom} ${styles.topMenuBarRoot}`}>
      <div className={`${layout.wrapper1170} ${layout.flexCenterItems}`}>
        <div className={`${layout.flex1} ${layout.flexJustifyCenter}`}>
          {getStatusView(article)}
          {getArticleUserView({ article, isAdminOrEditor })}
        </div>

        <IconOptionsMenu
          article={article}
          deleteArticle={deleteArticle}
          isAdminOrEditor={isAdminOrEditor}
          type="topbar"
          unpublishArticle={unpublishArticle}
        />
      </div>
    </div>
  );
};

TopMenuBar.propTypes = {
  article: PropTypes.shape({
    status: PropTypes.string,
    published_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string, // Not required when draft
  }),
  deleteArticle: PropTypes.func.isRequired,
  isAdminOrEditor: PropTypes.bool.isRequired,
  unpublishArticle: PropTypes.func.isRequired,
};

TopMenuBar.defaultProps = { article: null };

export default TopMenuBar;
