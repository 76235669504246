import generateRandomKey from '../../../../../../utility/generateRandomKey';
import { CATEGORY_RANKED, CATEGORY_CUSTOM, RANKED_DEFAULT, RUNNER_UPS } from './constants';

// NOTE: These map to challenge_decorator::VALUE_THRESHOLDS
const CASH_THRESHOLDS = {
  10: 'tens',
  100: 'hundreds',
  1000: 'thousands',
  10000: 'tens of thousands',
};

export const getCategoryType = (category) => {
  if (category.name === null) return RANKED_DEFAULT;
  if (category.is_runner_up) return RUNNER_UPS;
  if (category.is_ranked) return CATEGORY_RANKED;

  return CATEGORY_CUSTOM;
};

export const getCategoryTypeForHeader = (type, name) => {
  if (!name.length) return '';

  switch (type) {
    case CATEGORY_CUSTOM:
      return '(custom)';

    case CATEGORY_RANKED:
      return '(ranked)';

    case RUNNER_UPS:
      return '(runner ups)';

    default:
      return '';
  }
};

export const getDefaultTitleForHeader = (type) => {
  switch (type) {
    case RANKED_DEFAULT:
      return 'Ranked category';

    case RUNNER_UPS:
      return 'Runner ups';

    default:
      return '';
  }
};

// NOTE: The language here should map to challenge_decorator.rb.
export const getDefaultIntroduction = ({ totalCash, totalPrizes }) => {
  if (totalPrizes === 0) return '(empty because there are no prizes)';

  const cashLabel = Object.keys(CASH_THRESHOLDS).reduce((acc, key) => totalCash >= parseInt(key) ? CASH_THRESHOLDS[key] : acc, null);
  const prizesLabel = cashLabel ? `${cashLabel} of dollars in prizes` : 'a great prize';
  const winnersLabel = totalPrizes === 1 ? 'project' : `${totalPrizes} projects`;
  const projectsLabel = totalPrizes === 1 ? 'project' : 'projects';

  return `We are giving away ${prizesLabel} to the top ${winnersLabel}! Our judges are going to pick the best qualifying ${projectsLabel} based on the judging criteria outlined in the rules section.`;
};

export const getCustomTitleForHeader = (type) => {
  switch (type) {
    case CATEGORY_CUSTOM:
      return 'Custom category';

    case CATEGORY_RANKED:
      return 'Ranked category';

    case RUNNER_UPS:
      return 'Runner ups';

    default:
      return '';
  }
};

export const createPrize = (i) => ({
  cash_value: 0,
  description: '',
  image: null,
  link: '',
  name: '',
  position: i,
  quantity: 1,
  requires_shipping: false,
  uuid: generateRandomKey(),
});

export const createSinglePrizeCategory = (isRunnerUp, position = 0) => ({
  description: '',
  isCollapsed: false,
  is_enterable: false,
  is_ranked: false,
  is_runner_up: isRunnerUp,
  name: '',
  position: position,
  prizes: [createPrize(0)],
  type: isRunnerUp ? RUNNER_UPS : CATEGORY_CUSTOM,
  uuid: generateRandomKey(),
});

export const createRankedCategory = (type, position = 0) => ({
  description: '',
  isCollapsed: false,
  is_enterable: false,
  is_ranked: true,
  is_runner_up: false,
  name: '',
  position: position,
  prizes: Array.from(new Array(3), (x, i) => createPrize(i)),
  type: type,
  uuid: generateRandomKey(),
});

const initPrizeFromProps = (prize, position) => ({
  cash_value: prize.cash_value || 0,
  description: prize.description || '',
  id: prize.id,
  image: prize.image || null,
  link: prize.link || '',
  name: prize.name,
  position: position,
  quantity: prize.quantity || 1,
  requires_shipping: prize.requires_shipping || false,
  uuid: null,
});

export const initCategoryFromProps = (category) => ({
  description: category.description || '',
  id: category.id,
  isCollapsed: true,
  is_enterable: category.is_enterable || false,
  is_ranked: category.is_ranked,
  is_runner_up: category.is_runner_up,
  leaderboard_url: category.leaderboard_url || '',
  leaderboard_live_date: category.leaderboard_live_date,
  name: category.name || '',
  position: category.position,
  prizes: category.prizes.map((p, i) => initPrizeFromProps(p, i)),
  type: getCategoryType(category),
  uuid: null,
});
