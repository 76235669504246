import { CharacterMetadata, ContentBlock, ContentState, genKey } from 'draft-js';

const elementTypeMap = {
  'blockquote': 'block',
  'code-block': 'block',
  'header-three': 'block',
  'LEGACY_WIDGET': 'block',
  'VIDEO': 'block',
  'CAROUSEL': 'block',
  'TWEET': 'block',
  'BOLD': 'inline',
  'CODE': 'inline',
  'ITALIC': 'inline',
  'LINK': 'inline',
  'unordered-list-item': 'inline',
  'unstyled': 'inline'
};

// When copying and pasting in the same editor, we need to recreate entites to give them their own instances.
// Returns ContentBlock
function dedupeContentBlock(block, {contentState, fragmentAsBlockMap}) {
  let updatedContentState = contentState;
  const charList = block.getCharacterList();
  const entityMap = charList.reduce((acc, char, index, list) => {
    const prev = list.get(index-1) || null;
    // 1. Open Entity range.
    // 2. Close range if current char has a different or null Entity.
    // 3. Close range if we're at the end of the list.
    // 4. Custom blocks usually have an a single Char && Entity.
    if ((prev && prev.getEntity() === null) && char.getEntity()) {
      const currentEntity = contentState.getEntity(char.getEntity());
      updatedContentState = contentState.createEntity(currentEntity.get('type'), currentEntity.get('mutability'), currentEntity.get('data'));
      const entityKey = contentState.getLastCreatedEntityKey();
      acc.push({ entity: entityKey, start: index, end: index });
    } else if ((prev && prev.getEntity()) && (char.getEntity() !== prev.getEntity() || char.getEntity() === null) && acc.length) {
      acc[acc.length-1].end = index;
    } else if (list.size-1 === index && char.getEntity() && acc.length) {
      acc[acc.length-1].end = index;
    } else if (list.size === 1 && char.getEntity()) {
      const currentEntity = contentState.getEntity(char.getEntity());
      updatedContentState = contentState.createEntity(currentEntity.get('type'), currentEntity.get('mutability'), currentEntity.get('data'));
      const entityKey = contentState.getLastCreatedEntityKey();
      acc.push({ entity: entityKey, start: index, end: index });
    }
    return acc;
  }, []);

  let updatedCharList = charList;
  entityMap.forEach(range => {
    updatedCharList.forEach((char, index) => {
      if (index >= range.start && index <= range.end) {
        updatedCharList = updatedCharList.set(
          index,
          CharacterMetadata.applyEntity(updatedCharList.get(index), range.entity)
        );
      }
    });
  });

  const updatedBlock = block.merge({
    key: genKey(),
    characterList: updatedCharList
  });

  return {block: updatedBlock, contentState: updatedContentState};
}

// Returns ContentState | SelectionState
function appendBlocksToMap(blocks, endBlockType, endText, endCharList, contentState, startIndex, selection, removeLastInStart) {
  const blockArray = contentState.getBlocksAsArray();
  const startBlocks = removeLastInStart ? blockArray.slice(0, startIndex) : blockArray.slice(0, startIndex+1);
  const endBlocks = blockArray.slice(startIndex+1);

  // Chain pre/code-blocks if the fragment is pasted in one (endBlockType === startBlockType).
  const blocksToAppend = endBlockType === 'code-block'
    ? blocks.map(block => block.set('type', 'code-block'))
    : blocks;

  const lastBlockInMap = blocksToAppend[blocksToAppend.length-1];

  const lastBlockToConcat = elementTypeMap[lastBlockInMap.getType()] === 'inline' || endBlockType === 'code-block'
    ? mergeEndToBlock(blocksToAppend.pop(), endText, endCharList)
    : endText.length
    ? new ContentBlock({ key: genKey(), text: endText, type: endBlockType, characterList: endCharList })
    : [];

  const concatedBlockMap = startBlocks.concat(blocksToAppend).concat(lastBlockToConcat).concat(endBlocks);
  const insertedContentState = ContentState.createFromBlockArray(concatedBlockMap, contentState.getEntityMap());
  const insertedSelection = selection.merge({
    anchorKey: lastBlockInMap.getKey(),
    focusKey: lastBlockInMap.getKey(),
    anchorOffset: lastBlockInMap.getText().length,
    focusOffset: lastBlockInMap.getText().length
  });
  return { insertedContentState, insertedSelection };
}

// Returns ContentState | SelectionState
function mergeEndToContentState(contentBlock, endText, endCharList, contentState, selection) {
  const updatedBlockText = contentBlock.set('text', contentBlock.getText().concat(endText));
  const updatedBlockChars = updatedBlockText.set('characterList', contentBlock.getCharacterList().concat(endCharList));
  const insertedContentState = contentState.set('blockMap', contentState.getBlockMap().set(updatedBlockChars.getKey(), updatedBlockChars));
  const insertedSelection = selection.merge({
    anchorKey: updatedBlockChars.getKey(),
    focusKey: updatedBlockChars.getKey(),
    anchorOffset: contentBlock.getText().length,
    focusOffset: contentBlock.getText().length
  });
  return { insertedContentState, insertedSelection };
}

// Returns ContentBlock
function mergeEndToBlock(contentBlock, endText, endCharList) {
  const updatedBlockText = contentBlock.set('text', contentBlock.getText().concat(endText));
  return updatedBlockText.set('characterList', contentBlock.getCharacterList().concat(endCharList));
}

// Returns ContentState | SelectionState : Expects selection to be collapsed
export default function insertFragmentAtCursor(contentStateInit, selection, fragment) {
  // const fragmentAsBlockMap = fragment.toArray().map(block => dedupeContentBlock(block));
  const {contentState, fragmentAsBlockMap} = fragment.toArray().reduce((acc, blockToProcess) => {
    const {block, contentState} = dedupeContentBlock(blockToProcess, acc);
    return {contentState, fragmentAsBlockMap: acc.fragmentAsBlockMap.concat(block)};
  }, {contentState: contentStateInit, fragmentAsBlockMap: []});
  const firstBlockToConcat = fragmentAsBlockMap[0];

  const startBlock = contentState.getBlockForKey(selection.getAnchorKey());
  const startBlockMetaCharacterList = startBlock.getCharacterList();

  const startText = startBlock.getText().slice(0, selection.getStartOffset());
  const startChars = startBlockMetaCharacterList.slice(0, selection.getStartOffset());

  const endText = startBlock.getText().slice(selection.getStartOffset());
  const endChars = startBlockMetaCharacterList.slice(selection.getStartOffset());

  // Inline or block.
  const firstBlockToConcatType = elementTypeMap[firstBlockToConcat.getType()];

  // Determining on the firstBlockToConcatType we want to either merge the text or create a fresh block after the cursor.
  if (firstBlockToConcatType === 'inline' || firstBlockToConcat.getType() === 'code-block') {
    const updatedBlockText = startText.length > 0
      ? startBlock.set('text', startText.concat(firstBlockToConcat.getText()))
      : startBlock.merge({
          'text': startText.concat(firstBlockToConcat.getText()),
          'type': startBlock.getType() === 'code-block' ? 'code-block' : firstBlockToConcat.getType()
        });
    const updatedBlockChars = updatedBlockText.set('characterList', startChars.concat(firstBlockToConcat.getCharacterList()));

    const { insertedContentState, insertedSelection } = fragmentAsBlockMap.length > 1 // Ignore the first entry.
      ? appendBlocksToMap(
          fragmentAsBlockMap.slice(1), // Remove the first block, it was already concated.
          startBlock.getType(),
          endText,
          endChars,
          contentState.set('blockMap', contentState.getBlockMap().set(updatedBlockChars.getKey(), updatedBlockChars)),
          contentState.getBlocksAsArray().reduce((acc, block, index) => {
            if(block.getKey() === startBlock.getKey()) acc = index;
            return acc;
          }, 0),
          selection
        )
      : mergeEndToContentState(updatedBlockChars, endText, endChars, contentState, selection);

    return { insertedContentState, insertedSelection };
  } else {
    const updatedBlockText = startBlock.set('text', startText);
    const updatedBlockChars = updatedBlockText.set('characterList', startChars);
    const updatedContentState = startText.length > 0
      ? contentState.set('blockMap', contentState.getBlockMap().set(updatedBlockChars.getKey(), updatedBlockChars))
      : contentState;

    const { insertedContentState, insertedSelection } = appendBlocksToMap(
      fragmentAsBlockMap,
      startBlock.getType(),
      endText,
      endChars,
      updatedContentState,
      contentState.getBlocksAsArray().reduce((acc, block, index) => {
        if(block.getKey() === startBlock.getKey()) acc = index;
        return acc;
      }, 0),
      selection,
      startText.length < 1
    );

    return { insertedContentState, insertedSelection };
  }
}