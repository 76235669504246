export function cancelablePromise(promise) {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => promise.then((res) => {
    res = res || {};
    hasCanceled_ ? resolve({ ...res, promiseCanceled: true }) : resolve(res);
  })
    .catch((error) => {
      error = error || {};
      hasCanceled_ ? reject({ ...error, promiseCanceled: true }) : reject(error);
    }));

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

// Useful when we don't want Promise.all to throw when one Promise fails.
export function reflectPromise(promise) {
  return promise
    .then((data) => ({ data, status: 'resolved' }))
    .catch((err) => ({ err, status: 'rejected' }));
}

export function setTimeoutPromise(time = 10, predicate = null, errMsg = null, value = {}) {
  return new Promise((resolve, reject) => setTimeout(() => {
    if (!predicate) {
      return resolve(value);
    } else if (typeof predicate === 'function' && predicate()) {
      resolve(value);
    } else {
      reject(errMsg);
    }
  }, time));
}
