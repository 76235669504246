import { prepareModelForDraftsterInstance } from '../../../../draftster';

export default function convertFieldToDraftsterConsumable(field) {
  if (field.json && field.json.length) {
    return prepareModelForDraftsterInstance(field.json);
  } else if (field.html && field.html.length) {
    // Let Draftser parse the html string.  It will upload images to Hackster for us.
    // It will not handle any special widgets however, so handle accordingly.
    return field.html;
  } else {
    return '';
  }
}
