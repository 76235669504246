import React, { Component } from 'react';
import adAnalyticsService from '../../../services/ad_analytics';

const adAnalyticsTracker = (ChildComponent) => {
  class AdsAnalyticsWrapper extends Component {
    constructor(props) {
      super(props);

      this.handleClick = this.handleClick.bind(this);
      this.handleImageLoad = this.handleImageLoad.bind(this);

      // Refs
      this._adRootRef = React.createRef();
    }

    /**
     * Methods
     */
    handleClick() {
      adAnalyticsService.reportClick(this.props.ad);
    }

    handleImageLoad() {
      this._reportEventAsViewed();
    }

    /**
     * Helpers
     */
    /**

   * Reports if a ad was viewed or if it was blocked.
   * We give the half second breathing room for any mutations to flush, then
   * shallowly look to see if either the whole ad or its children still exist.
   */
    _reportEventAsViewed() {
      setTimeout(() => {
        const wrapper = this._adRootRef.current;
        const nodeAndChildrenExists = wrapper && wrapper.children && wrapper.children.length > 0;

        if (nodeAndChildrenExists) {
          const styles = window.getComputedStyle(wrapper.firstChild);
          const blockedProp = styles.display && styles.display === 'none' ? { blocked: true } : {};
          adAnalyticsService.reportView(this.props.ad, { ...blockedProp });
        } else {
          adAnalyticsService.reportView(this.props.ad, { blocked: true });
        }
      }, 500);
    }

    render() {
      return (
        <ChildComponent {...this.props} forwardedRef={this._adRootRef} onClick={this.handleClick} onImageLoad={this.handleImageLoad} />
      );
    }
  }

  // eslint-disable-next-line react/display-name
  return (props) => (<AdsAnalyticsWrapper {...props} />);
};

export default adAnalyticsTracker;
